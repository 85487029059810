import ResetPassword from "./Other Components/ResetPassword.vue";
import NoData from "./Other Components/No Data.vue";
import NoDataCus from "./Other Components/NoDataCus.vue";
import LoadMoreData from "./Other Components/LoadMoreData.vue";
import QrcodeVue from "qrcode.vue";
import QrLarge from "./Other Components/Qr Large.vue";
import QrRow from "./Other Components/Qr Row.vue";
import Slider from "./Other Components/Slider.vue";

import ResetPasswordConfirmModal from "./Confirmation Modals/ResetPasswordConfirmModal.vue";
import ArchiveConfirmModal from "./Confirmation Modals/ArchiveConfirmModal.vue";
import AddConfirmModal from "./Confirmation Modals/AddConfirmModal.vue";
import RestoreConfirmModal from "./Confirmation Modals/RestoreConfirmModal.vue";
import DeleteConfirmModal from "./Confirmation Modals/DeleteConfirmModal.vue";
import CleanConfirmModal from "./Confirmation Modals/CleanConfirmModal.vue";
import DeactiveConfirmModal from "./Confirmation Modals/DeactiveConfirmModal.vue";
import ActiveConfirmModal from "./Confirmation Modals/ActiveConfirmModal.vue";
import DefaultContactConfirmModal from "./Confirmation Modals/DefaultContactConfirmModal.vue";
import DefaultWarehouseConfirmModal from "./Confirmation Modals/DefaultWarehouseConfirmModal.vue";
import MoveableModal from "./Other Components/MoveableModal.vue";

import AddStorageType from "./Add Form Modals/AddStorageType.vue";
import AddWarehouse from "./Add Form Modals/AddWarehouse.vue";
import AddWarehouseUser from "./Add Form Modals/AddWarehouseUser.vue";
import PrintConfirm from "./Confirmation Modals/PrintConfirmModal.vue";
import LogoutConfirm from "./Confirmation Modals/LogoutConfirmModal.vue";
import LicenceConfirm from "./Confirmation Modals/LicenceConfirmModal.vue";
import ResetConfirm from "./Confirmation Modals/ResetConfirmModal.vue";
import LoadingScreen from "./Other Components/LoadingScreen.vue";
import EditItem from "./Action Buttons/EditItem.vue";
import AttributeItem from "./Action Buttons/AttributeItem.vue";
import Add from "./Action Buttons/Add.vue";
import DropOrder from "./Action Buttons/DropOrder.vue";
import Verify from "./Action Buttons/Verify.vue";
import Hold from "./Action Buttons/Hold.vue";
import Release from "./Action Buttons/Release.vue";
import AddItem from "./Action Buttons/AddItem.vue";
import ResetPsd from "./Action Buttons/ResetPassword.vue";
import ArchiveItem from "./Action Buttons/ArchiveItem.vue";
import DeleteItem from "./Action Buttons/DeleteItem.vue";
import RestoreItem from "./Action Buttons/RestoreItem.vue";
import InfoItem from "./Action Buttons/InfoItem.vue";
import ClearItem from "./Action Buttons/ClearItem.vue";
import ProductCount from "./Action Buttons/ProductCount.vue";

import ActionButton from "./Action Buttons/ActionButton.vue";
import ActionBtn from "./Action Buttons/ActionBtn.vue";

import AddSubItem from "./Action Buttons/AddSubItem.vue";
import ViewContacts from "./Action Buttons/ViewContacts.vue";
import ViewUsers from "./Action Buttons/ViewUsers.vue";
import ViewWarehouse from "./Action Buttons/ViewWarehouse.vue";
import Export from "./Other Components/Export.vue";
import ViewArchiveItem from "./Action Buttons/ViewArchiveItem.vue";
import PrintLabels from "./Action Buttons/PrintLabels.vue";
import SelectLabels from "./Action Buttons/SelectLabels.vue";
import CheckItem from "./Action Buttons/CheckItem.vue";
import SelectLabelsForPickType from "./Action Buttons/SelectLabelsForPickType.vue";
import viewThreeD from "./Action Buttons/ViewLocations.vue";
import PrintButton from "./Action Buttons/PrintButton.vue";
import AddItemPick from "./Action Buttons/AddItemPick.vue";

import PickingConfirmModal from "./Confirmation Modals/PickingConfirmModal.vue";
import DropConfirmModal from "./Confirmation Modals/DropConfirmModal.vue";
import VerifyConfirmModal from "./Confirmation Modals/VerifyConfirmModal.vue";
import HoldConfirmModal from "./Confirmation Modals/HoldConfirmModal.vue";
import ReleaseConfirmModal from "./Confirmation Modals/ReleaseConfirmModal.vue";
import PermissionConfirmModal from "./Confirmation Modals/PermissionConfirmModal.vue";
import PermissonCard from "./User Permisson/PermissonCard.vue";
import PermissonCardView from "./User Permisson/PermissonCardView.vue";

import ConfirmModal from "./Confirmation Modals/ConfirmModal.vue";
import ConfirmAlert from "./Confirmation Modals/ConfirmAlert.vue";

import AllPermission from "./User Permisson/AllPermission.vue";
import FilterItems from "./Other Components/FilterItems.vue";
import SearchBar from "./Other Components/SearchBar.vue";
import PaginationBar from "./Other Components/PaginationBar.vue";
import PaginationBarLocal from "./Other Components/PaginationBarLocal.vue";
import UploadFile from "./Action Buttons/UploadFile.vue";
import UploadFileForBin from "./Action Buttons/UploadFileForBin.vue";
import SearchableDropdown from "./Other Components/SearchableDropdown.vue";
import MyAutocomplete from "./Auto Complete/MyAutocomplete.vue";
import AutocompleteWrapper from "./Auto Complete/AutocompleteWrapper.vue";

import html2canvas from "html2canvas";

import ArrangeCsv from "./CsvComponents/ArrangeCsv.vue";

import Selection from "./Other Components/Selection.vue";
import SelectionWithDetails from "./Other Components/SelectionWithDetails.vue";
import MySelect from "./Other Components/MySelect.vue";

export default (app) => {
  app.component("PrintConfirm", PrintConfirm);

  app.component("Selection", Selection);
  app.component("SelectionWithDetails", SelectionWithDetails);
  app.component("MSelect", MySelect);
  app.component("AddWarehouse", AddWarehouse);
  app.component("AddWarehouseUser", AddWarehouseUser);
  app.component("ActionButton", ActionButton);
  app.component("ActionBtn", ActionBtn);
  app.component("ConfirmAlert", ConfirmAlert);
  app.component("ConfirmModal", ConfirmModal);
  app.component("CleanConfirmModal", CleanConfirmModal);
  app.component("ResetPasswordConfirmModal", ResetPasswordConfirmModal);
  app.component("AddConfirmModal", AddConfirmModal);
  app.component("ResetPassword", ResetPassword);
  app.component("ArrangeCsv", ArrangeCsv);
  app.component("Slider", Slider);
  app.component("DefaultWarehouseConfirmModal", DefaultWarehouseConfirmModal);
  app.component("ResetPsd", ResetPsd);
  app.component("UploadFileForBin", UploadFileForBin);
  app.component("ViewUsers", ViewUsers);
  app.component("ViewWarehouse", ViewWarehouse);
  app.component("MyAutocomplete", MyAutocomplete);
  app.component("AutocompleteWrapper", AutocompleteWrapper);
  app.component("CheckItem", CheckItem);
  app.component("ProductCount", ProductCount);
  app.component("ReleaseConfirmModal", ReleaseConfirmModal);
  app.component("HoldConfirmModal", HoldConfirmModal);
  app.component("Hold", Hold);
  app.component("Release", Release);
  app.component("Verify", Verify);
  app.component("VerifyConfirmModal", VerifyConfirmModal);
  app.component("DropConfirmModal", DropConfirmModal);
  app.component("DropOrder", DropOrder);
  app.component("AddItemPick", AddItemPick);
  app.component("PermissonCardView", PermissonCardView);
  app.component("PrintButton", PrintButton);
  app.component("SelectLabels", SelectLabels);
  app.component("SelectLabelsForPickType", SelectLabelsForPickType);
  app.component("viewThreeD", viewThreeD);
  app.component("PrintLabels", PrintLabels);
  app.component("NoDataCus", NoDataCus);
  app.component("PaginationBar", PaginationBar);
  app.component("PaginationBarLocal", PaginationBarLocal);
  app.component("html2canvas", html2canvas);
  app.component("SearchableDropdown", SearchableDropdown);
  app.component("UploadFile", UploadFile);
  app.component("SearchBar", SearchBar);
  app.component("PermissonCard", PermissonCard);
  app.component("AllPermission", AllPermission);
  app.component("PickingConfirmModal", PickingConfirmModal);
  app.component("PermissionConfirmModal", PermissionConfirmModal);
  app.component("FilterItems", FilterItems);
  app.component("ViewArchiveItem", ViewArchiveItem);
  app.component("Export", Export);
  app.component("ViewContacts", ViewContacts);
  app.component("AddItem", AddItem);
  app.component("EditItem", EditItem);
  app.component("Add", Add);
  app.component("AttributeItem", AttributeItem);
  app.component("ArchiveItem", ArchiveItem);
  app.component("DeleteItem", DeleteItem);
  app.component("RestoreItem", RestoreItem);
  app.component("InfoItem", InfoItem);
  app.component("ClearItem", ClearItem);
  app.component("AddSubItem", AddSubItem);
  app.component("LoadingScreen", LoadingScreen);
  app.component("ResetConfirm", ResetConfirm);
  app.component("LogoutConfirm", LogoutConfirm);
  app.component("LicenceConfirm", LicenceConfirm);
  app.component("AddStorageType", AddStorageType);
  app.component("DeleteConfirmModal", DeleteConfirmModal);
  app.component("DeactiveConfirmModal", DeactiveConfirmModal);
  app.component("ActiveConfirmModal", ActiveConfirmModal);
  app.component("RestoreConfirmModal", RestoreConfirmModal);
  app.component("ArchiveConfirmModal", ArchiveConfirmModal);
  app.component("DefaultContactConfirmModal", DefaultContactConfirmModal);
  app.component("QrLarge", QrLarge);
  app.component("QrcodeVue", QrcodeVue);
  app.component("QrRow", QrRow);
  app.component("LoadMoreData", LoadMoreData);
  app.component("NoData", NoData);
  app.component("MoveableModal", MoveableModal);
};
