<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:justify-between md:flex md:flex-row md:items-center md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Product" />

        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pagination.pageNumber"
          :selected="checkedData.length"
          @printSelected="printSelected"
          @csvExportSelected="csvExportSelected"
          @pdfExportSelected="pdfExportSelected"
        />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>
      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th>QR</th>
            <!-- <th>
              <Tippy
                tag="a"
                href="javascript:;"
                class="tooltip w-full"
                content="Select All"
              >
                <a
                  v-if="!allSelected"
                  @click="selectAll()"
                  class="btn shadow-md text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0"
                >
                  All
                  <span
                    class="ml-2 lg:w-5 lg:h-5 w-4 h-4 flex items-center justify-center"
                  >
                    <SquareIcon class="lg:w-6 lg:h-6 lg:-mt-1 w-4 h-4 mt-0" />
                  </span>
                </a>
              </Tippy>
              <Tippy
                tag="a"
                href="javascript:;"
                class="tooltip w-full"
                content="Unselect All"
              >
                <a
                  v-if="allSelected"
                  @click="unSelectAll()"
                  class="btn shadow-md text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0"
                >
                  All
                  <span
                    class="ml-2 lg:w-5 lg:h-5 w-4 h-4 flex items-center justify-center"
                  >
                    <CheckSquareIcon
                      class="lg:w-6 lg:h-6 lg:-mt-1 w-4 h-4 mt-0"
                    />
                  </span> </a
              ></Tippy>
            </th> -->
            <th>Abbreviation</th>
            <th>Name</th>
            <th>Price</th>
            <th>Product Type</th>
            <th>Department</th>
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>DESC</th>
            <th>Visible</th>
            <th>In Stock</th>
            <th>Discount</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td>
              <Tippy tag="a" href="javascript:;" class="tooltip w-full" :content="item.abbreviation + ' : ' + item.name">
                <qrcode-vue
                  :style="item.selected ? 'border:2px solid; border-color: white;' : ''"
                  v-if="item.productId"
                  render-as="svg"
                  :value="item.abbreviation"
                  size="50"
                  level="H"
              /></Tippy>
            </td>
            <!-- <td>
              <a class="flex items-center mr-3" href="javascript:;">
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Select"
                  :options="{
                    zIndex: 99999,
                  }"
                >
                  <input
                    id="vertical-form-3"
                    class="form-check-input w-5 h-5 mr-1 bg-slate-100 ml-2"
                    type="checkbox"
                    :value="item"
                    v-model="checkedData"
                  />
                </Tippy>
              </a>
            </td> -->
            <td>
              {{ item.abbreviation }}
            </td>
            <td
              @click="
                viewProductAttribute(item);
                item.selected = true;
              "
              class="cursor-pointer"
              :style="'color: #1a73e8'"
            >
              {{ item.name }}
            </td>
            <td>
              {{ currencyFormat(item.price) }}
            </td>
            <td>
              {{ findProductType(item.productType) }}
            </td>
            <td>
              {{ item.departmentName }}
            </td>
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>

            <td>
              <div class="text-left line-clamp-2">
                <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                  {{ item.description }}
                </Tippy>
              </div>
            </td>
            <td>
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.visible" class="form-check-input" disabled />
              </div>
            </td>
            <td>
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.inStock" class="form-check-input" disabled />
              </div>
            </td>
            <td>
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.isDiscount" class="form-check-input" disabled />
              </div>
            </td>

            <td class="table-report__action w-28">
              <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <!-- <Add
                  :activeAccess="$h.addItemAccess(policyAccess)"
                  @parentMethod="showAttributeModal($event)"
                  :item="item"
                  name="Add New Attribute Value"
                /> -->
                <AttributeItem @parentMethod="viewProductAttribute($event)" :item="item" name="ADD" />
                <!-- <a
                  class="flex items-center mr-3 font-bold"
                  href="javascript:;"
                  @click="viewItemInfo(item)"
                >
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    content="INFO"
                    ><InfoIcon
                      class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                    />
                  </Tippy>
                </a> -->

                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)" :item="item" name="Archive" />

                <!-- <a
                  class="flex items-center mr-3 font-bold"
                  href="javascript:;"
                  @click="viewVariants(item)"
                >
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    content="Product Variants"
                    ><PlusSquareIcon
                      class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                    />
                  </Tippy>
                </a> -->

                <!-- <a class="flex items-center mr-3" href="javascript:;">
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    content="Select"
                    :options="{
                      zIndex: 99999,
                    }"
                  >
                    <input
                      id="vertical-form-3"
                      class="form-check-input w-5 h-5 mr-1 bg-slate-100 ml-2"
                      type="checkbox"
                      :value="item"
                      v-model="checkedData"
                    />
                  </Tippy>
                </a> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />

    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: ViewProductAttribute -->
  <MoveableModal
    :show="viewProductAttributeModal"
    @hidden="viewProductAttributeModal = false"
    backdrop="static"
    size="modal-sl-85"
    :title="addAttributeModalTitle"
    @close="closeViewProductAttributeModal()"
  >
    <ModalBody class="p-4">
      <div class="flex justify-end">
        <button @click="openAttribute()" type="submit" class="btn btn-primary w-36 mr-6 mt-3">Add New Attribute</button>

        <button @click="openCreateSku()" type="submit" class="btn btn-primary w-36 mr-6 mt-3">Create New SKU</button>
      </div>
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-6">
        <!-- BEGIN: Data List -->

        <div class="intro-y col-span-12 overflow-auto md:mt-4 mt-2">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th class="whitespace-nowrap" v-for="(header, i) in productAttributeHeaders" :key="i">
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in attributeItemsLocal" :key="i" class="intro-x">
                <td>
                  {{ getAttributeName(item.attributeId) }}
                </td>
                <td>
                  <div class="form-switch ml-3">
                    <input id="booleanSwitch" type="checkbox" v-model="item.isActive" class="form-check-input" disabled />
                  </div>
                </td>
                <td>
                  <div class="form-switch ml-3">
                    <input id="booleanSwitch" type="checkbox" v-model="item.isVisible" class="form-check-input" disabled />
                  </div>
                </td>

                <td class="table-report__action w-28">
                  <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                    <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editAttribute($event)" :item="item" name="Edit Attribute" />
                    <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteAttributeItem($event)" :item="item" name="Delete" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <NoData :itemsData="itemsDataAttribute" />
        <!-- BEGIN: Pagination -->
        <PaginationBarLocal
          @changePage="changePageProAtbt($event)"
          @pageSizeChange="pageSizeChangeProAtbt($event)"
          :itemsData="itemsDataAttribute"
          :pageCount="pageCountProAtbt"
          :pageRange="pageRowCountProAtbt"
          :pageSize="pageSizeProAtbt"
        />
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <!-- BEGIN: ViewProductAttribute -->
  <MoveableModal :show="createSkuModal" @hidden="createSkuModal = false" backdrop="static" size="modal-sl-85" :title="createSkuModalTitle" @close="confirmExit()">
    <ModalBody class="p-4">
      <!-- BEGIN: INFO -->

      <div class="flex flex-col">
        <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-2">
          <!-- BEGIN: Data List -->

          <div class="intro-y col-span-12 overflow-auto">
            <table class="table table-report -mt-2 table-auto">
              <thead>
                <tr class="uppercase whitespace-nowrap">
                  <th>Combination Of Attribute Values</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in combinationLocal" :key="i" class="intro-x">
                  <td class="flex space-x-3">
                    <div v-for="(a, idx) in item" :key="idx" class="bg-gray-200 rounded-lg px-4 py-2 whitespace-nowrap text-black">
                      {{ a.value }}
                    </div>
                  </td>

                  <!-- <td>{{ item.toString().replace(/[\[\]']+/g,'') }}</td> -->

                  <!-- <td>{{ item.forEach(x=> x.value) }}</td> -->

                  <td class="table-report__action w-28">
                    <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                      <AddSubItem
                        :activeAccess="$h.addProductVariantsAccess(policyAccess)"
                        @parentMethod="AddProductVariants($event)"
                        :item="{ item: item, index: i }"
                        name="Add PLU/SKU"
                      />

                      <EditItem
                        v-if="storeSkus.find((x) => x.index === i)"
                        :activeAccess="$h.editItemAccess(policyAccess)"
                        @parentMethod="editProductVariants($event)"
                        :item="{ item: item, index: i }"
                        name="Edit PLU/SKU"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- END: Data List -->
          <NoData :itemsData="itemsDataCombinations" />
          <!-- BEGIN: Pagination -->
          <PaginationBarLocal
            @changePage="changePageCombinations($event)"
            @pageSizeChange="pageSizeChangeCombinations($event)"
            :itemsData="itemsDataCombinations"
            :pageCount="pageCountCombinations"
            :pageRange="pageRowCountCombinations"
            :pageSize="pageSizeCombinations"
          />
        </div>
      </div>

      <!-- END: INFO -->
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="confirmExit()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button v-if="storeSkus.length > 0" @click="saveStoredSkus()" type="button" class="btn btn-primary w-24 self-end">Save</button>
    </ModalFooter>
  </MoveableModal>
  <!-- BEGIN: Add Product Attribute Modal -->
  <MoveableModal :show="addAttribute" @hidden="addAttribute = false" backdrop="static" :title="formAttributeTitle" @close="closeAttributeValues()">
    <ModalBody class="flex justify-center space-y-4 p-8">
      <div class="flex flex-col space-y-4 w-full">
        <div class="flex justify-left space-x-16">
          <div>
            <label for="regular-form-1" class="form-label">Active</label>

            <div class="form-switch ml-0">
              <input type="checkbox" v-model="formDataAttribute.isActive" class="form-check-input" />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">Visible</label>

            <div class="form-switch ml-0">
              <input type="checkbox" v-model="formDataAttribute.isVisible" class="form-check-input" />
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full" v-if="titleAttributeChange === -1">
          <label for="regular-form-1" class="form-label">Attribute</label>

          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formDataAttribute.attributeId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenAttribute"
              @close="onCloseAttribute"
              @search="searchAttribute"
              :key="name"
              :get-option-label="(option) => option.name"
              :options="attributes"
              :reduce="(name) => name.attributeId"
              label="name"
            >
              <template #list-footer>
                <li v-show="hasNextPageAttribute" ref="loadAttribute" class="loader">Loading more...</li>
              </template>
            </v-select>
          </div>
        </div>
        <div v-if="titleAttributeChange === 0">
          <div class="flex flex-col w-full">
            <label for="regular-form-1" class="form-label">Attribute Values</label>
            <div class="flex flex-col space-y-1 w-48">
              <v-select
                multiple
                v-model="formDataAttribute.attributeValueId"
                placeholder="SELECT"
                class="style-chooser"
                :filterable="false"
                @open="onOpenAV"
                @close="onCloseAV"
                @search="searchAV"
                :key="name"
                :get-option-label="(option) => option.name"
                :options="attributeValues"
                :reduce="(name) => name.attributeValueId"
                label="name"
              >
                <template #list-footer>
                  <li v-show="hasNextPageAV" ref="loadAV" class="loader">Loading more...</li>
                </template>
              </v-select>
            </div>

            {{ formDataAttribute.attributeValueIds }}
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeAttributeValues()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="saveAttributeForm()" type="submit" class="btn btn-primary w-24">
        {{ btnAttributeTitle }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Product Attribute Modal -->

  <!--BEGIN: Add product attribute value modal-->
  <!-- <MoveableModal
    :show="addAttributeValue"
    @hidden="addAttributeValue = false"
    backdrop="static"
    title="Add Product Attribute Value"
    @close="addAttributeValue = false"
  >
    <ModalBody class="flex justify-center space-y-4 p-8">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label"
            >Attribute Value
          </label>

          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formDataAttribute.attributeValueId"
              placeholder="SELECT"
              class="style-chooser"
              @search="
                (search) => {
                  getAttributeValues(search);
                }
              "
              :options="attributeValues"
              :reduce="(name) => name.attributeValueId"
              label="name"
            >
            </v-select>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="
          formAttributeValueTitle == -1
            ? closeAttributeValueForm()
            : closeEditAttributeValueForm()
        "
        class="btn btn-outline-secondary w-24 mr-3"
      >
        Cancel
      </button>
      <button
        @click="saveAttributeValueForm()"
        type="submit"
        class="btn btn-primary w-24"
      >
        ADD
      </button>
    </ModalFooter>
  </MoveableModal> -->
  <!-- END: Add Product Attribute value Modal -->

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addProduct" @hidden="addProduct = false"  size="modal-sl-60" backdrop="static" :title="formTitle" @close="closeForm()">
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div class="flex justify-center space-x-4">
          <!-- Product Name field -->
          <div class="flex flex-col w-3/5">
            <label for="regular-form-1" class="form-label">Product Name</label>

            <div class="flex flex-col">
              <input id="vertical-form-1" v-model="formDataProduct.name" type="text" class="form-control" name="name" placeholder="Product Name" />
            </div>
          </div>
          <!-- Product ABB field -->
          <div class="flex flex-col w-3/5">
            <label for="regular-form-1" class="form-label">Abbreviation</label>

            <div class="flex flex-col">
              <input id="vertical-form-1" v-model="formDataProduct.abbreviation" type="text" class="form-control" name="name" placeholder="Abbreviation" />
            </div>
          </div>

          <!-- Product price field -->
          <div class="flex flex-col w-3/5">
            <label for="regular-form-1" class="form-label">Product Type</label>
            <v-select
              v-model="formDataProduct.productType"
              placeholder="SELECT"
              class="style-chooser"
              :options="productTypeItems"
              :reduce="(displayName) => displayName.number"
              label="displayName"
            />
          </div>
        </div>

        <!-- Product Description field -->
        <div>
          <label for="regular-form-1" class="form-label">Description</label>

          <div class="flex flex-col">
            <textarea
              id="vertical-form-1"
              v-model="formDataProduct.description"
              type="text"
              class="form-control"
              name="description"
              placeholder="Type here about product..."
            ></textarea>
          </div>
        </div>

        <div class="flex flex-col w-full">
          <label for="regular-form-1" class="form-label">Image URL</label>
          <input id="vertical-form-1" v-model="formDataProduct.image" type="text" class="form-control" placeholder="Image URL" />
        </div>

        <!-- unit, Category, brand ids -->
        <div>
          <div class="flex justify-center space-x-4">
            <div class="flex flex-col w-full space-y-1">
              <label for="regular-form-1" class="form-label">Unit</label>

              <div class="flex flex-col space-y-1">
                <v-select
                  v-model="formDataProduct.unitId"
                  placeholder="SELECT"
                  class="style-chooser"
                  :filterable="false"
                  @open="onOpenUnit"
                  @close="onCloseUnit"
                  @search="searchUnit"
                  :key="unitName"
                  :get-option-label="(option) => option.unitName"
                  :options="units"
                  :reduce="(unitName) => unitName.unitId"
                  label="unitName"
                >
                  <template #list-footer>
                    <li v-show="hasNextPageUnit" ref="loadUnit" class="loader">Loading more...</li>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="flex flex-col w-full space-y-1">
              <label for="regular-form-1" class="form-label">Department</label>
              <div class="flex flex-col space-y-1">
                <v-select
                  v-model="formDataProduct.departmentId"
                  placeholder="SELECT"
                  class="style-chooser"
                  :filterable="false"
                  @open="onOpenDep"
                  @close="onCloseDep"
                  @search="searchDep"
                  :key="name"
                  :get-option-label="(option) => option.name"
                  :options="departmentList"
                  :reduce="(name) => name.departmentId"
                  label="name"
                >
                  <template #list-footer>
                    <li v-show="hasNextPageDep" ref="loadDep" class="loader">Loading more...</li>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-center space-x-4">
            <div class="flex flex-col w-3/5">
              <label for="regular-form-1" class="form-label">In Stock ?</label>
              <div class="form-switch ml-3">
                <input type="checkbox" v-model="formDataProduct.inStock" class="form-check-input" />
              </div>
            </div>

            <!-- Product vat field -->

            <div class="flex flex-col w-3/5">
              <label for="regular-form-1" class="form-label">Visible ?</label>
              <div class="form-switch ml-3">
                <input type="checkbox" v-model="formDataProduct.visible" class="form-check-input" />
              </div>
            </div>

            <!-- BEGIN: Basic Select -->
            <div class="flex flex-col w-3/5 space-y-1">
              <label for="regular-form-1" class="form-label">Discount ?</label>
              <div class="form-switch ml-3">
                <input type="checkbox" v-model="formDataProduct.isDiscount" class="form-check-input" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex justify-center space-x-4">
            <!-- Product Price field -->

            <div class="flex flex-col w-full">
              <label for="regular-form-1" class="form-label">Price</label>
              <input id="vertical-form-1" v-model="formDataProduct.price" type="number" class="form-control" name="price" placeholder="Price" />
            </div>

            <!-- Product vat field -->

            <div class="flex flex-col w-full">
              <label for="regular-form-1" class="form-label">VAT</label>
              <input id="vertical-form-1" v-model="formDataProduct.vatPercentage" type="number" class="form-control" name="vatPercentage" placeholder="Vat Percentage" />
            </div>

            <div v-if="formDataProduct.isDiscount" class="flex flex-col w-full">
              <label for="regular-form-1" class="form-label">Discounted Price</label>

              <div class="flex flex-col">
                <input
                  isDiscountid="vertical-form-1"
                  v-model="formDataProduct.discountedPrice"
                  type="text"
                  class="form-control"
                  name="discountedPrice"
                  placeholder="Discounted Price"
                />
              </div>
            </div>

            <div v-if="formDataProduct.isDiscount" class="flex flex-col w-full">
              <label for="regular-form-1" class="form-label">Discount Reason</label>

              <input id="vertical-form-1" v-model="formDataProduct.discountReason" type="text" class="form-control" name="discountReason" placeholder="Discount Reason" />
            </div>
          </div>
          <!-- <div class="w-full pt-4">
            <Dropzone
              ref-key="dropzoneValidationRef"
              :options="{
                url: 'http://https://mywarehouse.myweb.net.au//products',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                acceptedFiles: 'image/jpeg|image/png|image/jpg',
                headers: { 'My-Awesome-Header': 'header value' },
              }"
              v-model="formDataProduct.image"
              class="dropzone"
            >
              <div class="text-lg font-medium">
                Drop files here or click to upload.
              </div>
              <div class="text-gray-600">
                This is image upload section, Selected images are actually
                uploaded.
              </div>
            </Dropzone>
          </div> -->
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnTitle }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Add sku Modal -->

  <MoveableModal :show="AddProductVariantsModal" @hidden="AddProductVariantsModal = false" backdrop="static" size="modal-sl-90" :title="formSkuTitle" @close="closeSkuForm()">
    <ModalBody class="">
      <div class="grid grid-cols-6 lg:gap-4 md:gap-4 gap-2 p-2">
        <div class="w-full">
          <label for="regular-form-1" class="form-label">PLU/SKU</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddVariants.skuName" type="text" class="form-control" name="skuName" placeholder="SKU" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Description</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddVariants.description" type="text" class="form-control h-10" placeholder="Description" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">BARCODE/EAN</label>

          <div class="flex flex-col space-y-1">
            <input
              :style="validateEan(formDataAddVariants.ean) == true && formDataAddVariants.ean != '' ? 'border-color: red' : 'border-color: none'"
              id="vertical-form-1"
              v-model="formDataAddVariants.ean"
              type="text"
              class="form-control"
              name="ean"
              placeholder="BARCODE/EAN"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Min Stock</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddVariants.minStock" type="text" class="form-control" placeholder="Min Stock" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">VAT Percentage</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataAddVariants.vatPercentage"
              type="text"
              class="form-control"
              name="vatPercentage"
              placeholder="VAT Percentage"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Minimum Margin</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataAddVariants.minMargin"
              type="number"
              class="form-control"
              name="minMargin"
              placeholder="Minimum Margin"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="relative col-span-6 pt-2">
          <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Price & Discount Details</h3>
          <div class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
            <div>
              <label for="regular-form-1" class="form-label">Price</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="formDataAddVariants.price" type="text" class="form-control" name="price" placeholder="Price" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Discount</label>

              <div class="flex flex-col space-y-1">
                <div class="form-switch ml-3">
                  <input type="checkbox" v-model="formDataAddVariants.isDiscount" class="form-check-input" />
                </div>
              </div>
            </div>

            <div>
              <div v-if="formDataAddVariants.isDiscount">
                <label for="regular-form-1" class="form-label">Discounted Price</label>

                <div class="flex flex-col space-y-1">
                  <input
                    id="vertical-form-1"
                    v-model="formDataAddVariants.discountedPrice"
                    type="text"
                    class="form-control"
                    name="discountedPrice"
                    placeholder="Discounted Price"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div>
              <div v-if="formDataAddVariants.isDiscount">
                <label for="regular-form-1" class="form-label">Discount Reason</label>

                <div class="flex flex-col space-y-1">
                  <input
                    id="vertical-form-1"
                    v-model="formDataAddVariants.discountReason"
                    type="text"
                    class="form-control"
                    name="discountReason"
                    placeholder="Discount Reason"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label capitalize">Std cost</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  type="text"
                  class="form-control capitalize"
                  name="discountReason"
                  placeholder="Std cost"
                  v-model="formDataAddVariants.stdCost"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label capitalize">Reprice markup</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  type="text"
                  class="form-control capitalize"
                  name="discountReason"
                  placeholder="Reprice markup"
                  v-model="formDataAddVariants.rePriceMarkup"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label capitalize">Reprice method</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  type="text"
                  class="form-control capitalize"
                  name="discountReason"
                  placeholder="Reprice method"
                  v-model="formDataAddVariants.rePriceMethod"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Head office note</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="discountReason"
              placeholder="Head office note"
              v-model="formDataAddVariants.headOfficeNote"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Manufacturer</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control"
              name="discountReason"
              placeholder="Manufacturer"
              v-model="formDataAddVariants.manufacturer"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Manf. product code</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="discountReason"
              placeholder="Manf. product code"
              v-model="formDataAddVariants.manfProCode"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Buying mult</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="discountReason"
              placeholder="Buying mult"
              v-model="formDataAddVariants.buyingMult"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">RRP</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="form-control" name="discountReason" placeholder="RRP" v-model="formDataAddVariants.rrp" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">RRP adjustment</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="discountReason"
              placeholder="RRP adjustment"
              v-model="formDataAddVariants.rrpAdjustment"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Note</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="form-control" name="discountReason" placeholder="Note" v-model="formDataAddVariants.note" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Shelf descr</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="discountReason"
              placeholder="Shelf descr"
              v-model="formDataAddVariants.shelfDescription"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Shelf label</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="discountReason"
              placeholder="Shelf label"
              v-model="formDataAddVariants.shelfLabel"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="w-full">
          <label for="regular-form-1" class="form-label">Temperature</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddVariants.temperature" type="number" class="form-control" placeholder="Temperature" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Dimension</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddVariants.dimension" type="number" class="form-control" placeholder="Dimension" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Weight</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddVariants.weight" type="number" class="form-control" placeholder="Weight" autocomplete="off" />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeSkuForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveSku()" type="submit" class="btn btn-primary w-24">
        {{ skuBtn }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add sku Modal -->

  <!-- BEGIN: Archive Modal -->
  <MoveableModal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static" title="Archived Product" @close="archiveModal = false">
    <ModalBody class="p-0">
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            clearable
            autocomplete="off"
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Abbreviation</th>
              <th>Name</th>
              <th>Price</th>
              <th>Product Type</th>
              <th>Department</th>
              <th>DESC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <td>
                {{ item.abbreviation }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ currencyFormat(item.price) }}
              </td>
              <td>
                {{ findProductType(item.productType) }}
              </td>
              <td>
                {{ item.departmentName }}
              </td>

              <td>
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                    {{ item.description }}
                  </Tippy>
                </div>
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->
      <!-- BEGIN: INFO modal -->
      <MoveableModal :show="viewProductInfoModal" @hidden="viewProductInfoModal = false" backdrop="static" size="modal-sl-85" title="INFO" @close="viewProductInfoModal = false">
        <ModalBody class="p-0">
          <!-- BEGIN: INFO -->
          <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-6">
            <!-- BEGIN: Data List -->

            <div class="intro-y col-span-12 overflow-auto md:mt-4 mt-2">
              <table class="table table-report -mt-2 table-auto">
                <thead>
                  <tr class="uppercase whitespace-nowrap">
                    <th>AttributeName</th>
                    <th>ValueName</th>
                    <th>Abbreviation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in attributeData" :key="i" class="intro-x">
                    <td>
                      {{ getAttributeName(item.attributeId) }}
                      {{ item.attributeName }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.abbreviation }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </MoveableModal>
      <!-- END: INFO modal -->

      <!-- BEGIN: Pagination -->

      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataProduct.name" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataProduct.name" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataProduct.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <DeleteConfirmModal
    :name="formDataAttribute.attributeName"
    :modalStatus="deleteConfirmationAttributeModal"
    :modalConfirm="deleteItemConfirmAttribute"
    :modalClose="closeAttributeDelete"
  />

  <CleanConfirmModal :name="formDataAttribute.name" :modalStatus="confirmSkuExist" :modalConfirm="closeSkuModal" :modalClose="closeConfirmExit" />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";
import validationMixins from "../../../mixins/validation";
// import { permissionHelper as $h } from "@/utils/permissionHelper";

export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Products");
    this.observerUnit = new IntersectionObserver(this.infiniteScrollUnit);
    this.observerDep = new IntersectionObserver(this.infiniteScrollDep);
    this.observerAttribute = new IntersectionObserver(this.infiniteScrollAttribute);
    this.observerSup = new IntersectionObserver(this.infiniteScrollSup);
    this.observerProduct = new IntersectionObserver(this.infiniteScrollProduct);
    this.observerAV = new IntersectionObserver(this.infiniteScrollAV);
    this.observerWarehouse = new IntersectionObserver(this.infiniteScrollWarehouse);
    this.observerUsersWarehouse = new IntersectionObserver(this.infiniteScrollUsersWarehouse);
  },
  components: {},
  data() {
    return {
      deleteConfirmationAttributeModal: false,
      confirmSkuExist: false,
      units: [],
      productList: [],
      tempArr: [],
      combArray: [],
      combinationResult: [],
      skuValues: {},
      storeSkus: [],
      formDataAddVariants: {
        skuName: "",
        ean: "",
        description: "",
        productId: 0,
        price: 0,
        vatPercentage: 0,
        isDiscount: true,
        discountedPrice: 0,
        minMargin: 0,
        discountReason: "",
        temperature: 0,
        dimension: "",
        weight: 0,
        department: "",
        unitId: 0,
        minStock: 0,
        headOfficeNote: "",
        manufacturer: "",
        manfProCode: "",
        buyingMult: "",
        rrp: "",
        rrpAdjustment: "",
        rePriceMethod: "",
        rePriceMarkup: "",
        note: "",
        shelfLabel: "",
        shelfDescription: "",
        stdCost: 0,
        productType: 0,
        categoryId: null,
        warehouseId: 0,
      },
      formDataAddVariantsDefault: {
        skuName: "",
        ean: "",
        description: "",
        productId: 0,
        price: 0,
        vatPercentage: 0,
        isDiscount: true,
        discountedPrice: 0,
        minMargin: 0,
        discountReason: "",
        temperature: 0,
        dimension: "",
        weight: 0,
        department: "",
        unitId: 0,
        minStock: 0,
        headOfficeNote: "",
        manufacturer: "",
        manfProCode: "",
        buyingMult: "",
        rrp: "",
        rrpAdjustment: "",
        rePriceMethod: "",
        rePriceMarkup: "",
        note: "",
        shelfLabel: "",
        shelfDescription: "",
        stdCost: 0,
        productType: 0,
        categoryId: null,
        warehouseId: 0,
      },
      createSkuModalTitle: "",
      uploadModal: false,
      formDataUploadWarehouseId: "",
      storeUserRole: store.state.authUser.userRole,
      warehousesearch: "",
      observerUsersWarehouse: null,
      limitUsersWarehouse: 5,

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,

      warehousesList: [
        {
          id: 0,
          abbreviation: store.state.authUser.warehouse.abbreviation,
          warehouseId: store.state.authUser.warehouse.warehouseId,
        },
      ],
      productTypeItems: store.state.authUser.ProductTypes,
      departmentList: [],
      attributeData: [],
      viewProductInfoModal: false,
      addAttributeModalTitle: "",
      productAttributeValues: [],
      attributeValues: [],
      tempArray: [],
      itemsDataAttribute: [],
      attributeItems: [],
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      productAttributeHeaders: [
        { text: "Attribute", value: "attributeId" },
        { text: "Active", value: "isActive" },
        { text: "Visible", value: "isVisible" },
      ],
      viewProductAttributeModal: false,
      attributes: [],
      addAttribute: false,
      addAttributeValue: false,
      storeWarehouseId: store.state.authUser.warehouse.warehouseId,
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addProduct: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataProduct: {
        name: "",
        abbreviation: "",
        description: "",
        productType: 0,
        image: "",
        visible: true,
        inStock: true,
        price: 0,
        vatPercentage: 0,
        isDiscount: false,
        discountedPrice: 0,
        discountReason: "",
        categoryId: null,
        brandId: null,
        unitId: "",
        departmentId: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },
      formDataProductDefault: {
        name: "",
        abbreviation: "",
        description: "",
        productType: 0,
        image: "",
        visible: true,
        inStock: true,
        price: 0,
        vatPercentage: 0,
        isDiscount: false,
        discountedPrice: 0,
        discountReason: "",
        categoryId: null,
        brandId: null,
        unitId: undefined,
        departmentId: undefined,
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },
      formDataAttribute: {
        isActive: false,
        isVisible: false,
        attributeId: 0,
        productId: 0,
        attributeValueId: 0,
        attributeValueIds: [],
      },
      productAttributeName: "",

      formDataAttributeDefault: {
        isActive: false,
        isVisible: false,
        attributeId: undefined,
        productId: 0,
        attributeValueId: undefined,
        attributeValueIds: [],
      },
      // formDataAttributeValue: {
      //   isActive: false,
      //   isVisible: false,
      //   attributeId: 0,
      //   productId: 0,
      //   attributeValueIds: [],
      // },

      // formDataAttributeValueDefault: {
      //   isActive: false,
      //   isVisible: false,
      //   attributeId: 0,
      //   productId: 0,
      //   attributeValueIds: [],
      // },

      headers: [
        // { text: "ID", value: "productId" },
        // { text: "Name", value: "name" },
        // { text: "Abbreviation", value: "abbreviation" },
        // { text: "Price", value: "price" },
        // { text: "Unit", value: "unit" },
        // { text: "Category", value: "category" },
        // { text: "Brand", value: "brand" },
      ],

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "unitName",
        // sortDirection: "asc",
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      allItems: [],
      allItemsData: [],

      isActive: 0,
      docs: null,

      allSelected: false,
      checkedData: [],
      units: [],
      categories: [],
      brands: [],
      policyAccess: "Products",
      titleAttributeChange: -1,

      observerUnit: null,
      limitUnit: 5,
      hasNextPageUnit: false,
      totalUnitCount: 0,
      isUnitSearchResult: false,
      unitPageNumber: 1,
      unitSearchTimer: undefined,

      observerDep: null,
      limitDep: 5,
      hasNextPageDep: false,
      totalDepCount: 0,
      isDepSearchResult: false,
      depPageNumber: 1,
      depSearchTimer: undefined,

      observerAttribute: null,
      limitAttribute: 5,
      hasNextPageAttribute: false,
      totalAtbtCount: 0,
      isAtbtSearchResult: false,
      atbtPageNumber: 1,
      atbtSearchTimer: undefined,

      observerAV: null,
      limitAV: 5,
      hasNextPageAV: false,
      totalAVCount: 0,
      isAVSearchResult: false,
      avPageNumber: 1,
      avSearchTimer: undefined,

      createSkuModal: false,
      AddProductVariantsModal: false,

      warehousesearch: "",
      observerUsersWarehouse: null,
      limitUsersWarehouse: 5,

      observerSup: null,
      limitSup: 5,
      hasNextPageSup: false,
      totalSupCount: 0,
      isSupSearchResult: false,
      supPageNumber: 1,
      supSearchTimer: undefined,

      observerProduct: null,
      limitProduct: 5,
      hasNextPageProduct: false,
      totalProductCount: 0,
      isProductSearchResult: false,
      productPageNumber: 1,
      productSearchTimer: undefined,

      combinationsEnd: 5,
      combinationsStart: 0,
      combinationLocal: [],
      itemsDataCombinations: [],
      pageCountCombinations: 0,
      pageRowCountCombinations: 5,
      pageSizeCombinations: 0,

      ProAtbtEnd: 5,
      ProAtbtStart: 0,
      attributeItemsLocal: [],
      pageCountProAtbt: 0,
      pageRowCountProAtbt: 5,
      pageSizeProAtbt: 0,
      titleSkuChange: -1,
    };
  },
  computed: {
    formSkuTitle() {
      return this.titleSkuChange === -1 ? "Add PLU/SKU" : "Edit PLU/SKU";
    },
    formAttributeTitle() {
      return this.titleAttributeChange === -1 ? "Add New Attribute " : "Add Attribute Value";
    },
    btnAttributeTitle() {
      return this.titleAttributeChange === -1 ? "Save" : "Save";
    },

    formTitle() {
      return this.titleChange === -1 ? "Add New Product" : "Edit Product";
    },
    btnTitle() {
      return this.titleChange === -1 ? "Save" : "Edit";
    },
    skuBtn() {
      return this.titleSkuChange === -1 ? "Save" : "Edit";
    },

    checkLimtUnit() {
      return this.unitPageNumber;
    },
    checkLimtDep() {
      return this.depPageNumber;
    },
    checkLimtAttribute() {
      return this.atbtPageNumber;
    },
    checkLimtAV() {
      return this.avPageNumber;
    },
    viewModalUnit() {
      return this.formDataProduct.unitId;
    },
    viewModalDep() {
      return this.formDataProduct.departmentId;
    },
    viewModalAttribute() {
      return this.formDataAttribute.attributeId;
    },
    viewModalAV() {
      return this.formDataAttribute.attributeValueId;
    },
  },
  watch: {
    formDataSkuSupplier() {
      this.viewSupplierCode();
    },
    checkLimtProduct() {
      this.getProductData();
    },
    checkLimtWarehouse() {
      this.getWarehouses();
    },
    checkLimtUnit() {
      this.getUnits();
    },
    checkLimtDep() {
      this.getDepartmentData();
    },
    checkLimtSup() {
      this.getSuppliers();
    },
    checkLimtAttribute() {
      this.getAttributes();
    },
    checkLimtAV() {
      this.getAttributeValues();
    },
    viewModalUnit() {
      if (this.formDataProduct.unitId === null) {
        this.clearUnit();
      }
    },
    viewModalDep() {
      if (this.formDataProduct.departmentId === null) {
        this.clearDep();
      }
    },
    viewModalAttribute() {
      if (this.formDataAttribute.attributeId === null) {
        this.clearAttribute();
      }
    },
    viewModalAV() {
      if (this.formDataAttribute.attributeValueId === null) {
        this.clearAV();
      }
    },
  },
  created() {
    this.getDataFromApi();
    //this.getWarehouses();
    this.getUnitWithoutl();
    this.getUnits();
  },
  methods: {
    changePageCombinations(pageNum) {
      // this.pageNumberCombinations = pageNum;

      this.combinationsEnd = this.pageRowCountCombinations * pageNum;

      this.combinationsStart = this.combinationsEnd - this.pageRowCountCombinations;

      this.combinationLocal = this.combinationResult.slice(this.combinationsStart, this.combinationsEnd);
    },
    setLocalPagination() {
      if (this.itemsDataCombinations) {
        this.itemsDataCombinations.totalCount = this.combinationResult.length;
      }

      var pageCount = this.combinationResult.length / this.pageRowCountCombinations;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountCombinations = pageCountRound;
      } else {
        this.pageCountCombinations = pageCountRound;
      }
      if (this.pageRowCountCombinations > this.itemsDataCombinations.totalCount) {
        this.pageSizeCombinations = this.itemsDataCombinations.totalCount;
      } else {
        this.pageSizeCombinations = this.pageRowCountCombinations;
      }

      //pageCountProduct
      this.combinationLocal = this.combinationResult.slice(this.combinationsStart, this.combinationsEnd);
    },
    getUnit(value) {
      try {
        return this.units.find((x) => x.unitId === parseInt(value)).unitName;
      } catch {
        return "Null";
      }
    },
    getUnitWithoutl(value) {
      try {
        return this.units.find((x) => x.unitId === parseInt(value)).unitName;
      } catch {
        return "";
      }
    },
    clearSupplier() {
      this.suppliers = [];
      this.supPageNumber = 1;
      this.limitSup = 5;
      this.getSuppliers("");
    },

    searchSupplier(search) {
      clearInterval(this.supSearchTimer);
      this.supSearchTimer = setTimeout(() => {
        if (search) {
          this.suppliers = [];
          this.supPageNumber = 1;
          this.limitSup = this.totalSupCount;
          this.getSuppliers(search.trim());
        }
      }, 2000);
    },
    async getSuppliers(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.supPageNumber,
        pageSize: this.limitSup,
        search: search,
      };
      await axios
        .post("Suppliers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isSupSearchResult = !!search;
            this.suppliers =
              this.isSupSearchResult !== true
                ? Array.from(new Set([...this.suppliers, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSup = response.data.data.hasNextPage;
            this.totalSupCount = response.data.data.totalCount;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    async onOpenSup() {
      if (this.isSupSearchResult) {
        this.supPageNumber = 1;
      }
      if (this.hasNextPageSup) {
        await this.$nextTick();
        this.observerSup.observe(this.$refs.loadSup);
      }
    },
    onCloseSup() {
      this.observerSup.disconnect();
    },
    async infiniteScrollSup([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supPageNumber++;
        this.limitSup;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    clearProduct() {
      this.productList = [];
      this.productPageNumber = 1;
      this.limitProduct = 5;
      this.getProductData("");
    },

    searchProduct(search) {
      clearInterval(this.productSearchTimer);
      this.productSearchTimer = setTimeout(() => {
        if (search) {
          this.productList = [];
          this.productPageNumber = 1;
          this.limitProduct = this.totalProductCount;
          this.getProductData(search.trim());
        }
      }, 2000);
    },

    async getProductData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.productPageNumber,
        pageSize: this.limitProduct,
        search: search,
      };
      await axios
        .post("Products/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isProductSearchResult = !!search;
            this.productList =
              this.isProductSearchResult !== true
                ? Array.from(new Set([...this.productList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageProduct = response.data.data.hasNextPage;
            this.totalProductCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    async onOpenProduct() {
      if (this.isProductSearchResult) {
        this.productPageNumber = 1;
      }
      if (this.hasNextPageProduct) {
        await this.$nextTick();
        this.observerProduct.observe(this.$refs.loadProduct);
      }
    },
    onCloseProduct() {
      this.observerProduct.disconnect();
    },
    async infiniteScrollProduct([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.productPageNumber++;
        this.limitProduct;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    getProduct(value) {
      try {
        return this.productList.find((x) => x.productId == parseInt(value)).name;
      } catch {
        return "";
      }
    },

    findProductType(value) {
      return this.productTypeItems.find((x) => x.number === value).displayName;
    },

    confirmExit() {
      if (this.storeSkus.length > 0) {
        this.confirmSkuExist = true;
      } else {
        this.closeSkuModal();
      }
    },

    closeSkuModal() {
      this.createSkuModal = false;
      this.combArray = [];
      this.storeSkus = [];
      this.combinationResult = [];
      this.combinationLocal = [];
      this.confirmSkuExist = false;
    },

    saveSku() {
      if (this.titleSkuChange === -1) {
        var isDone = false;
        if (this.formDataAddVariants.skuName == "" || this.formDataAddVariants.ean == "") {
          if (this.formDataAddVariants.skuName == "") {
            warningToast("PLU/SKU Required");
          }
          if (this.formDataAddVariants.ean == "") {
            warningToast("BARCODE/EAN Required");
          }
        } else {
          this.formDataAddVariants.productId = this.formDataAttribute.productId;
          this.formDataAddVariants.department = this.formDataAttribute.departmentName;
          this.formDataAddVariants.unitId = this.formDataAttribute.unitId;
          this.formDataAddVariants.productType = this.formDataAttribute.productType;
          this.formDataAddVariants.warehouseId = this.formDataAttribute.warehouseId;

          var formData = {
            skuValues: this.skuValues.item.map((x) => {
              return {
                productId: x.productId,
                attributeId: x.attributeId,
                attributeValueId: x.attributeValueId,
              };
            }),
            skuDetail: this.formDataAddVariants,
            index: this.skuValues.index,
          };

          this.storeSkus.push(formData);

          isDone = true;
          if (isDone) {
            successToast("PLU/SKU added successfully");
            this.closeSkuForm();
          } else {
            failedToast("Something went wrong");
          }
        }
      } else {
        var objIndex = this.storeSkus.findIndex((x) => x.index === this.skuValues.index);
        if (objIndex > -1) {
          this.storeSkus[objIndex].skuDetail = this.formDataAddVariants;
          successToast("PLU/SKU updated successfully");
          this.closeSkuForm();
        } else {
          failedToast("Something went wrong");
        }
      }
    },
    saveStoredSkus() {
      if (this.storeSkus.length > 0) {
        var filterSkus = this.storeSkus.map((x) => {
          return {
            skuDetail: x.skuDetail,
            skuValues: x.skuValues,
          };
        });

        // this.isLoading = true;
        var formData = {
          productId: this.formDataAttribute.productId,
          skus: filterSkus,
        };

        axios
          .post("ProductVariants", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.isLoading = false;
              successToast(response.data.message);
              this.closeSkuModal();
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      } else {
        failedToast("No PLU/SKU found");
      }
    },
    generateCombinations() {
      var combinationList = this.combArray;
      var result = [];
      if (combinationList.length > 0) {
        result = combinationList.reduce(
          (acc, curr) => {
            if (!Array.isArray(curr)) {
              curr = [curr];
            }
            return acc.flatMap((combinationList) => {
              return curr.map((value) => {
                return [...combinationList, value];
              });
            });
          },
          [[]]
        );

        return result;
      } else {
        result = [];
        return result;
      }
    },

    async getCombinations() {
      this.combinationResult = await this.generateCombinations();
      this.setLocalPagination();
    },
    closeAttributeValues() {
      this.attributeValues = [];
      this.productAttributeValues = [];
      this.addAttribute = false;
    },

    closeSkuForm() {
      this.AddProductVariantsModal = false;
      this.skuValues = {};
      this.setDefaultSku();
    },
    AddProductVariants(item) {
      this.titleSkuChange = -1;
      this.skuValues = item;
      this.activateProductVariantsModal();
    },

    activateProductVariantsModal() {
      // this.getProductData();
      // this.getSuppliers();
      // this.getDepartmentData();
      this.AddProductVariantsModal = true;
      this.formDataAddVariants.skuName = this.skuValues.item.map((x) => x.value).join("-");
      // if (this.storeUserRole == "SuperUser") {
      //   this.getWarehouses();
      // } else {
      //   this.getUsersWarehouses();
      // }
    },
    editProductVariants(item) {
      this.titleSkuChange = 0;
      this.skuValues = item;
      this.AddProductVariantsModal = true;
      var findObj = this.storeSkus.find((x) => x.index === item.index).skuDetail;
      this.formDataAddVariants = Object.assign({}, findObj);
    },
    getUsersWarehouses() {
      this.isLoading = true;
      let storedArrayData = store.state.authUser.licensee.warehouses;
      if (storedArrayData.length > 0) {
        this.isLoading = false;
        this.warehousesList = storedArrayData;
        return this.warehousesList.filter((x) => x.abbreviation.toLowerCase().includes(this.warehousesearch.toLowerCase()));
      } else {
        this.isLoading = false;
        warningToast("There are no Warehouses found");
      }
    },
    paginatedUserWarehouse() {
      return this.getUsersWarehouses.slice(0, this.limitUsersWarehouse);
    },
    hasNextPageUsersWarehouse() {
      return this.paginatedUserWarehouse.length < this.getUsersWarehouses.length;
    },

    openCreateSku() {
      this.createSkuModalTitle = "Add PLU/SKU";
      this.getAllAttributeValues();
      this.createSkuModal = true;
    },
    clearWarehouse() {
      this.warehousesList = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehousesList = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },

    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehousesList =
            this.isWhSearchResult !== true
              ? Array.from(new Set([...this.warehousesList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    onUserWarehouseSearch(query) {
      this.warehousesearch = query;
    },

    async onOpenUsersWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.hasNextPageUsersWarehouse) {
        await this.$nextTick();
        this.observerUsersWarehouse.observe(this.$refs.loadUsersWarehouse);
      }
    },
    onCloseUsersWarehouse() {
      this.observerUsersWarehouse.disconnect();
      this.limitUsersWarehouse = 5;
    },
    async infiniteScrollUsersWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitUsersWarehouse = this.limitUsersWarehouse + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async onOpenUnit() {
      if (this.isUnitSearchResult) {
        this.unitPageNumber = 1;
      }
      if (this.hasNextPageUnit) {
        await this.$nextTick();
        this.observerUnit.observe(this.$refs.loadUnit);
      }
    },
    onCloseUnit() {
      this.observerUnit.disconnect();
    },
    async infiniteScrollUnit([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.unitPageNumber++;
        this.limitUnit;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenDep() {
      if (this.isDepSearchResult) {
        this.depPageNumber = 1;
      }
      if (this.hasNextPageDep) {
        await this.$nextTick();
        this.observerDep.observe(this.$refs.loadDep);
      }
    },
    onCloseDep() {
      this.observerDep.disconnect();
    },
    async infiniteScrollDep([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.depPageNumber++;
        this.limitDep;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenAttribute() {
      if (this.isAtbtSearchResult) {
        this.atbtPageNumber = 1;
      }
      if (this.hasNextPageAttribute) {
        await this.$nextTick();
        this.observerAttribute.observe(this.$refs.loadAttribute);
      }
    },
    onCloseAttribute() {
      this.observerAttribute.disconnect();
    },
    async infiniteScrollAttribute([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.atbtPageNumber++;
        this.limitAttribute;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenAV() {
      if (this.isAVSearchResult) {
        this.avPageNumber = 1;
      }
      if (this.hasNextPageAV) {
        await this.$nextTick();
        this.observerAV.observe(this.$refs.loadAV);
      }
    },
    onCloseAV() {
      this.observerAV.disconnect();
    },
    async infiniteScrollAV([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.avPageNumber++;
        this.limitAV;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white";
    },
    viewItemInfo(item) {
      removeNullValue(item);
      this.formDataAttribute = Object.assign({}, item);
      // this.productId = item.productId;
      this.getProductInfo(item.productAttributeId);
      this.viewProductInfoModal = true;
    },
    getProductInfo(productAttributeId) {
      var itemId = this.formDataAttribute.productId;
      this.isLoading = true;
      axios
        .get("ProductAttributes/" + productAttributeId)
        .then((response) => {
          if (response.data.success === true) {
            this.attributeData = response.data.data.attribute;
            response.data.data.values.forEach((x) => {
              this.productAttributeValues.push(x.value);
            });

            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    closeAttributeValueForm() {
      this.setAttributeValueDefault();
      this.addAttributeValue = false;
    },
    setAttributeValueDefault() {
      this.formDataAttribute = Object.assign({}, this.formDataAttributeDefault);
    },
    showAttributeValuesModal(item) {
      this.setAttributeValueDefault();
      this.formDataAttribute.attributeId = item.attributeId;
      // this.getAttributeValues();
      this.addAttributeValue = true;
    },
    getAttributeName(value) {
      try {
        return this.attributeItems.find((attribute) => attribute.attributeId == value).attributeName;
      } catch {
        return "";
      }
    },
    openAttribute() {
      this.addAttribute = true;
      // this.getAttributeValues();
      this.titleAttributeChange = -1;
    },

    editAttribute(item) {
      console.log("Product: editAttribute: item", item);
      this.formDataAttribute = Object.assign({}, item);
      // var fromData = {
      //   isActive: item.isActive,
      //   isVisible: item.isVisible,
      //   attributeId: item.attributeId,
      //   productId: item.productId,
      //   attributeValueId: item.attributeValueId.map((x,i)=> { return {i:x.attributeValueId}}),
      // }
      // this.formDataAttribute = fromData;
      console.log("Product: editAttribute: formDataAttribute", this.formDataAttribute);
      this.getAttributeValues();
      this.getAttributeValuesData(item.productAttributeId);
      this.titleAttributeChange = 0;
      this.addAttribute = true;
    },
    clearAV() {
      this.attributeValues = [];
      this.avPageNumber = 1;
      this.limitAV = 5;
      this.getAttributeValues("");
    },

    searchAV(search) {
      clearInterval(this.avSearchTimer);
      this.avSearchTimer = setTimeout(() => {
        if (search) {
          this.attributeValues = [];
          this.avPageNumber = 1;
          this.limitAV = this.totalAVCount;
          this.getAttributeValues(search.trim());
        }
      }, 2000);
    },
    getAttributeValues(search) {
      var attributeId = this.formDataAttribute.attributeId;

      this.isLoading = true;
      var pagination = {
        pageNumber: this.avPageNumber,
        pageSize: this.limitAV,
        search: search,
      };
      axios
        .post("AttributeValues/" + attributeId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isAVSearchResult = !!search;
            this.attributeValues =
              this.isAVSearchResult !== true
                ? Array.from(new Set([...this.attributeValues, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageAV = response.data.data.hasNextPage;
            this.totalAVCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getAttributeValuesData(productAttributeId) {
      this.isLoading = true;
      axios
        .get("ProductAttributes/" + productAttributeId)
        .then((response) => {
          if (response.data.success === true) {
            response.data.data.values.forEach((x) => {
              this.productAttributeValues.push(x.value);
            });
            if (this.productAttributeValues.length > 0) {
              this.formDataAttribute.attributeValueId = this.productAttributeValues;
            } else {
              this.formDataAttribute.attributeValueId = [];
            }

            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getAllAttributeValues() {
      this.attributeItems.map((x) => {
        var itemId = x.productAttributeId;
        this.isLoading = true;
        axios
          .get("ProductAttributes/" + itemId)
          .then(async (response) => {
            if (response.data.success === true) {
              var arr = [];
              response.data.data.values.forEach((y) => {
                arr.push({
                  productId: y.productId,
                  attributeId: y.value.attributeId,
                  attributeValueId: y.value.attributeValueId,
                  value: y.value.abbreviation,
                });
              });
              await this.combArray.push(arr);
              //[['RED','BLUE','GREEN'], ['L','M','S'] , [],....,[].]
              this.getCombinations();
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      });
    },
    getDataFromProductAttribute() {
      this.isLoading = true;
      var proId = this.formDataAttribute.productId;
      axios

        .get("ProductAttributes/Product/" + proId)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsDataAttribute = response.data.data;
            this.attributeItems = response.data.data;
            this.isLoading = false;
            this.pageCountSku = this.itemsDataAttribute.totalPages;
            this.setLocalPaginationProAtbt();
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageProAtbt(pageNum) {
      this.ProAtbtEnd = this.pageRowCountProAtbt * pageNum;
      this.ProAtbtStart = this.ProAtbtEnd - this.pageRowCountProAtbt;
      this.attributeItemsLocal = this.attributeItems.slice(this.ProAtbtStart, this.ProAtbtEnd);
    },
    setLocalPaginationProAtbt() {
      if (this.itemsDataAttribute) {
        this.itemsDataAttribute.totalCount = this.attributeItems.length;
      }

      var pageCount = this.attributeItems.length / this.pageRowCountProAtbt;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountProAtbt = pageCountRound;
      } else {
        this.pageCountProAtbt = pageCountRound;
      }
      if (this.pageRowCountProAtbt > this.itemsDataAttribute.totalCount) {
        this.pageSizeProAtbt = this.itemsDataAttribute.totalCount;
      } else {
        this.pageSizeProAtbt = this.pageRowCountProAtbt;
      }

      //pageCountProduct
      this.attributeItemsLocal = this.attributeItems.slice(this.ProAtbtStart, this.ProAtbtEnd);
    },

    closeViewProductAttributeModal() {
      this.viewProductAttributeModal = false;
      this.skuValues = {};
    },
    viewProductAttribute(item) {
      this.addAttributeModalTitle = "Product : " + item.name;
      this.formDataAttribute = Object.assign({}, item);
      this.viewProductAttributeModal = true;
      this.getAttributes();
      this.getDataFromProductAttribute();
    },
    closeDepartmentProductForm() {
      this.viewDepartmentProductModal = false;
    },

    // showAttributeModal(item) {
    //   this.setAttributeDefault();
    //   this.formDataAttribute.attributeId = item.attributeId;
    //   this.titleAttributeChange = -1;
    //   this.addAttribute = true;
    // },
    saveAttributeForm() {
      if (this.titleAttributeChange === -1) {
        this.isLoading = true;

        axios
          .post("ProductAttributes", this.formDataAttribute)
          .then((response) => {
            if (response.data.success === true) {
              // this.itemsData = response.data.data;
              // this.items = response.data.data.items;
              this.addAttribute = false;
              this.getDataFromProductAttribute();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      } else {
        var itemId = this.formDataAttribute.productAttributeId;

        this.isLoading = true;

        var arr = [];
        this.formDataAttribute.attributeValueId.forEach((x) => {
          Object.keys(x).length <= 1 ? arr.push(x) : arr.push(x.attributeValueId);
        });

        var formEditData = {
          isActive: this.formDataAttribute.isActive,
          isVisible: this.formDataAttribute.isVisible,
          attributeValueIds: arr,
        };

        axios
          .put("ProductAttributes/" + itemId, formEditData)
          .then((response) => {
            if (response.data.success === true) {
              // this.itemsData = response.data.data;
              // this.items = response.data.data.items;
              this.viewAttribute = false;
              this.addAttribute = false;

              this.getDataFromProductAttribute();
              this.closeAttributeValues();
              console.log("productAttributeValues", this.productAttributeValues);
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    closeAttributeForm() {
      this.setAttributeDefault();
      this.addAttribute = false;
      this.productAttributeValues = [];
    },
    closeEditAttributeForm() {
      this.addAttribute = false;
      this.productAttributeValues = [];
    },

    setAttributeDefault() {
      this.formDataAttribute = Object.assign({}, this.formDataAttributeDefault);
    },

    clearUnit() {
      this.units = [];
      this.unitPageNumber = 1;
      this.limitUnit = 5;
      this.getUnits("");
    },

    searchUnit(search) {
      clearInterval(this.unitSearchTimer);
      this.unitSearchTimer = setTimeout(() => {
        if (search) {
          this.units = [];
          this.unitPageNumber = 1;
          this.limitUnit = this.totalUnitCount;
          this.getUnits(search.trim());
        }
      }, 2000);
    },
    getUnits(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.unitPageNumber,
        pageSize: this.limitUnit,
        search: search,
      };
      axios
        .post("Units/" + this.storeWarehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isUnitSearchResult = !!search;
            this.units =
              this.isUnitSearchResult !== true ? Array.from(new Set([...this.units, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse) : response.data.data.items;
            this.hasNextPageUnit = response.data.data.hasNextPage;
            this.totalUnitCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearAttribute() {
      this.attributes = [];
      this.atbtPageNumber = 1;
      this.limitAttribute = 5;
      this.getAttributes("");
    },

    searchAttribute(search) {
      clearInterval(this.atbtSearchTimer);
      this.atbtSearchTimer = setTimeout(() => {
        if (search) {
          this.attributes = [];
          this.atbtPageNumber = 1;
          this.limitAttribute = this.totalAtbtCount;
          this.getAttributes(search.trim());
        }
      }, 2000);
    },

    getAttributes(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.atbtPageNumber,
        pageSize: this.limitAttribute,
        search: search,
      };
      axios
        .post("Attributes/" + this.storeWarehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isAtbtSearchResult = !!search;
            this.attributes =
              this.isAtbtSearchResult !== true
                ? Array.from(new Set([...this.attributes, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageAttribute = response.data.data.hasNextPage;
            this.totalAtbtCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getCategories(search) {
      this.isLoading = true;
      var pagination = { pageNumber: 1, pageSize: 5, search: search };
      axios
        .post("Categories/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.categories = response.data.data.items;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearDep() {
      this.departmentList = [];
      this.depPageNumber = 1;
      this.limitDep = 5;
      this.getDepartmentData("");
    },

    searchDep(search) {
      clearInterval(this.depSearchTimer);
      this.depSearchTimer = setTimeout(() => {
        if (search) {
          this.departmentList = [];
          this.depPageNumber = 1;
          this.limitDep = this.totalDepCount;
          this.getDepartmentData(search.trim());
        }
      }, 2000);
    },
    async getDepartmentData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.depPageNumber,
        pageSize: this.limitDep,
        search: search,
      };
      await axios
        .post("Departments/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isDepSearchResult = !!search;
            this.departmentList =
              this.isDepSearchResult !== true
                ? Array.from(new Set([...this.departmentList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageDep = response.data.data.hasNextPage;
            this.totalDepCount = response.data.data.totalCount;
            if (this.departmentList.length == 0) {
              warningToast("Please Add Department");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getBrands(search) {
      this.isLoading = true;
      var pagination = { pageNumber: 1, pageSize: 5, search: search };
      axios
        .post("Brands/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.brands = response.data.data.items;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Unit Name", dataKey: "unitName" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                unitName: el.unitName,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Products " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Products " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "ID",
          2: "Abbreviation",
          3: "Name",
          4: "Price",
          5: "Desc",
          6: "Visible",
          7: "InStock",
          8: "IsDiscount",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.productId,
            2: item.abbreviation,
            3: item.name,
            4: item.price,
            5: item.description,
            6: item.visible,
            7: item.inStock,
            8: item.isDiscount,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Products/" + this.storeWarehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData; //newly added line
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataProduct = Object.assign({}, this.formDataProductDefault);
    },
    setDefaultSku() {
      this.formDataAddVariants = Object.assign({}, this.formDataAddVariantsDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Products/" + this.storeWarehouseId + "/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.activateModal();
    },
    activateModal() {
      this.addProduct = true;
      this.getUnits();
      // this.getCategories();
      // this.getBrands();
      this.getDepartmentData();
    },
    closeForm() {
      this.AddProductVariantsModal = false;
      this.addProduct = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataProduct = Object.assign({}, item);
      this.activateModal();
    },

    saveForm() {
      if (this.formDataProduct.name == "" || this.formDataProduct.abbreviation == "") {
        if (this.formDataProduct.name == "") {
          warningToast("Name Required");
        }

        if (this.formDataProduct.abbreviation == "") {
          warningToast("Abbreviation Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Products", this.formDataProduct)
            .then((response) => {
              if (response.data.success === true) {
                // this.itemsData = response.data.data;
                // this.items = response.data.data.items;
                this.addProduct = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataProduct.productId;
          this.isLoading = true;
          axios
            .put("Products/Id?Id=" + itemId, this.formDataProduct)
            .then((response) => {
              if (response.data.success === true) {
                this.addProduct = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataProduct = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataProduct.productId;
      this.isLoading = true;
      axios
        .get("Products/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Products/" + this.storeWarehouseId + "/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataProduct = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataProduct.productId;
      this.isLoading = true;
      axios
        .get("Products/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataProduct = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    closeAttributeDelete() {
      this.deleteConfirmationAttributeModal = false;
    },
    closeConfirmExit() {
      this.confirmSkuExist = false;
    },
    deleteAttributeItem(item) {
      this.formDataAttribute = Object.assign({}, item);
      this.deleteConfirmationAttributeModal = true;
    },
    deleteItemConfirmAttribute() {
      var itemId = this.formDataAttribute.productAttributeId;
      this.isLoading = true;
      axios
        .delete("ProductAttributes/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationAttributeModal = false;
            this.getDataFromProductAttribute();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItemConfirm() {
      var itemId = this.formDataProduct.productId;
      this.isLoading = true;
      axios
        .delete("Products/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
