<template>
  <div class="flex items-center text-blue-900">
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      content="Select"
      :options="{
        zIndex: 99999,
      }"
    >
      <input
        @click="buttonClick"
        id="vertical-form-3"
        class="form-check-input w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 bg-slate-100"
        type="checkbox"
        :value="item"
        v-model="items"
      />
    </Tippy>
  </div>
</template>
<script>
export default {
  props: { checkedData: [], item: {} },
  name: "CheckItem",
  computed:{
    items:{
      get(){
        return this.checkedData
      }
    }
  },
  methods: {
    buttonClick() {
      console.log("parentMethod", this.item);
      this.$emit("parentMethod", this.item);
    },
  },
};
</script>
