<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="printAllPages"
          />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />

          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th></th>
              <th>Order#</th>
              <!-- <th>priority</th> -->
              <th>status</th>
              <th>type</th>
              <th>created</th>
              <th>amount</th>
              <th>currency</th>
              <th>booking#</th>
              <th>discount %</th>
              <th>discount</th>
              <th>note</th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
              </th>
            </tr>
          </thead>

          <tbody v-for="(item, i) in items" :key="i">
            <tr class="intro-x">
              <td class="cursor-pointer" :style="opened.includes(item.orderId) ? getSelectedColor() : 'color: #1a73e8'">
                <svg
                  v-if="!opened.includes(item.orderId)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-caret-down-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-primary text-bold"
                  @click="toggle(item)"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>

                <svg
                  v-if="opened.includes(item.orderId)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-caret-up-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-white text-bold"
                  viewBox="0 0 16 16"
                  @click="closeToggle(item)"
                >
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.orderNumber != null ? item.orderNumber : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                <div
                  class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center whitespace-nowrap"
                  :style="{
                    'background-color': getStatusColor(item.status),
                  }"
                >
                  {{ item.status != null ? getStatus(item.status) : "" }}
                </div>
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.type != null ? getType(item.type) : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.time === null || item.time === undefined ? "" : momentLocalWithTime(item.time) }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ currencyFormat(item.amount) }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.currency != null ? item.currency : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.despatchBookingNumber != null ? item.despatchBookingNumber : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ percentageFormat(item.discountPercentage) }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ currencyFormat(item.discountTotal) }}
              </td>

              <td class="w-24" :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.note">
                    {{ item.note != null ? item.note : "" }}
                  </Tippy>
                </div>
              </td>
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />

                  <!-- <div v-if="item.despatchBookingNumber != null"> -->
                  <Verify v-if="item.status < 4" :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="verifyItem($event)" :item="item" name="Verify Order" />
                  <!-- </div> -->
                  <div v-if="item.status === 9">
                    <Hold :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="holdItem($event)" :item="item" name="Hold Order" />
                  </div>
                  <div v-if="item.status === 10">
                    <Release :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="releaseItem($event)" :item="item" name="Release Order" />
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="opened.includes(item.orderId)">
              <td colspan="12">
                <table class="table table-report -mt-2 table-auto">
                  <thead>
                    <tr class="uppercase whitespace-nowrap">
                      <th></th>
                      <th>LPN</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Added By</th>
                      <th>Despatched</th>
                      <th>Despatch</th>
                      <th>Verified By</th>
                      <th>Verified At</th>
                    </tr>
                  </thead>
                  <tbody v-for="(item, index) in itemsLpnLocal" :key="index">
                    <tr v-show="itemsLpn.length <= 0">
                      <td colspan="12">
                        <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_DATA }}</span>
                      </td>
                    </tr>
                    <tr class="intro-x">
                      <td class="cursor-pointer" :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : 'color: #1a73e8'">
                        <svg
                          v-if="!openedDespatch.includes(item.lpnId)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="bi bi-caret-down-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-primary text-bold"
                          @click="toggleDespatch(item)"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>

                        <svg
                          v-if="openedDespatch.includes(item.lpnId)"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="bi bi-caret-up-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-white text-bold"
                          viewBox="0 0 16 16"
                          @click="closeToggleDespatch(item)"
                        >
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg>
                      </td>

                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        {{ item.lpn != null ? item.lpn : "" }}
                      </td>
                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        {{ item.date === null || item.date === undefined ? "" : momentLocal(item.date) }}
                      </td>

                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        <div class="flex justify-center space-x-1 rounded-2xl p-1 w-24" :class="item.verifiedAt !== null ? 'bg-yellow-700 text-white' : 'bg-blue-700 text-white'">
                          {{ getVerifiedStatus(item.verifiedAt) }}
                        </div>
                      </td>
                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        {{ item.addedBy != null ? item.addedBy : "" }}
                      </td>

                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        <div class="flex justify-center space-x-1 rounded-2xl p-1 w-24" :class="item.isDespatched ? 'bg-green-700 text-white' : 'bg-red-700 text-white'">
                          {{ item.isDespatched != null ? getBooleanStatus(item.isDespatched) : "" }}
                        </div>
                      </td>
                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        {{ item.despatch != null ? item.despatch : "" }}
                      </td>
                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        {{ item.verifiedBy != null ? item.verifiedBy : "" }}
                      </td>
                      <td :style="openedDespatch.includes(item.lpnId) ? getSelectedColor() : ''">
                        {{ item.verifiedAt != null ? momentLocal(item.verifiedAt) : "" }}
                      </td>
                    </tr>
                    <tr v-if="openedDespatch.includes(item.lpnId)">
                      <td colspan="12">
                        <table class="table table-report -mt-2 table-auto">
                          <thead>
                            <tr class="uppercase whitespace-nowrap">
                              <th>PLU/SKU Name</th>
                              <th>barcode/ean</th>
                              <th>Status</th>
                              <th>quantity</th>
                              <th>picked</th>
                              <th>not Picked</th>

                              <th>Reserved QTY</th>
                              <th>Dropped QTY</th>
                              <th>Processed QTY</th>
                              <th>Despatched QTY</th>

                              <th>warehouse</th>
                              <th>storage Section</th>
                              <th>storage Type</th>
                              <th>storage Bin</th>
                              <th>expiryDate</th>
                              <th>description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-show="itemsDispatch.length < 0">
                              <td colspan="12">
                                <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_ORDER_LINE }}</span>
                              </td>
                            </tr>
                            <tr v-for="(item, i) in itemsDispatchLocal" :key="i" class="intro-x">
                              <td>
                                {{ item.sku != null ? item.sku : "" }}
                              </td>

                              <td>
                                {{ item.ean != null ? item.ean : "" }}
                              </td>

                              <td>
                    <div
                      class="flex items-center justify-center p-1 pl-2 pr-2 text-white rounded-2xl"
                      :style="{
                        'background-color': getOrderLineStatusColors(item.status),
                      }"
                    >
                      {{ item.status != null ? getOrderLineStatus(item.status) : "" }}
                    </div>
                  </td>

                              <td>
                                {{ item.quantity != null ? item.quantity : "" }}
                              </td>

                              <td>
                                {{ item.picked != null ? item.picked : "" }}
                              </td>

                              <td>
                                {{ item.notPicked != null ? item.notPicked : "" }}
                              </td>

                              <td>
                                {{ item.reservedQuantity != null ? item.reservedQuantity : "" }}
                              </td>

                              <td>
                                {{ item.droppedQuantity != null ? item.droppedQuantity : "" }}
                              </td>

                              <td>
                                {{ item.processedQuantity != null ? item.processedQuantity : "" }}
                              </td>

                              <td>
                                {{ item.despatchedQuantity != null ? item.despatchedQuantity : "" }}
                              </td>

                              <td>
                                {{ item.warehouse != null ? item.warehouse : "" }}
                              </td>

                              <td>
                                {{ item.storageSection != null ? item.storageSection : "" }}
                              </td>

                              <td>
                                {{ item.storageType != null ? item.storageType : "" }}
                              </td>

                              <td>
                                {{ item.storageBin != null ? item.storageBin : "" }}
                              </td>

                              <td>
                                {{ item.expiryDate === null || item.expiryDate === undefined ? "" : momentLocal(item.expiryDate) }}
                              </td>

                              <td class="w-24">
                                <div class="text-left line-clamp-2">
                                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                                    {{ item.description != null ? item.description : "" }}
                                  </Tippy>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <PaginationBarLocal
                          @changePage="changePageDispatch($event)"
                          :itemsData="itemsDispatchData"
                          :pageCount="pageCountDispatch"
                          :pageRange="pageRowCountDispatch"
                          :pageSize="pageSizeDispatch"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <PaginationBarLocal
                  @changePage="changePageLpns($event)"
                  :itemsData="itemsLpnData"
                  :pageCount="pageCountLpns"
                  :pageRange="pageRowCountLpns"
                  :pageSize="pageSizeLpns"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePage($event)"
        @pageSizeChange="pageSizeChange($event)"
        :itemsData="itemsData"
        :pageCount="pageCount"
        :pageSize="pageSize"
        :pageNumber="pagination.pageNumber"
      />

      <!-- END: Pagination -->
    </div>
  </div>

  <MoveableModal :show="despatchModal" @hidden="despatchModal = false" backdrop="static" size="modal-sl-70" :title="'LPN : ' + lpnNo" @close="closeDespatch">
    <ModalBody>
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>order#</th>
              <th>PLU/SKU Name</th>
              <th>barcode/ean</th>
              <th>quantity</th>
              <th>picked</th>
              <th>not Picked</th>
              <th>warehouse</th>
              <th>storage Section</th>
              <th>storage Type</th>
              <th>storage Bin</th>
              <th>expiryDate</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="itemsDispatch.length < 0">
              <td colspan="6">
                <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_ORDER_LINE }}</span>
              </td>
            </tr>
            <tr v-for="(item, i) in itemsDispatch" :key="i" class="intro-x">
              <td>
                {{ item.orderNumber != null ? item.orderNumber : "" }}
              </td>
              <td>
                {{ item.sku != null ? item.sku : "" }}
              </td>

              <td>
                {{ item.ean != null ? item.ean : "" }}
              </td>

              <td>
                {{ item.quantity != null ? item.quantity : "" }}
              </td>

              <td>
                {{ item.picked != null ? item.picked : "" }}
              </td>

              <td>
                {{ item.notPicked != null ? item.notPicked : "" }}
              </td>

              <td>
                {{ item.warehouse != null ? item.warehouse : "" }}
              </td>

              <td>
                {{ item.storageSection != null ? item.storageSection : "" }}
              </td>

              <td>
                {{ item.storageType != null ? item.storageType : "" }}
              </td>

              <td>
                {{ item.storageBin != null ? item.storageBin : "" }}
              </td>

              <td>
                {{ item.expiryDate === null || item.expiryDate === undefined ? "" : momentLocal(item.expiryDate) }}
              </td>

              <td class="w-24">
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                    {{ item.description != null ? item.description : "" }}
                  </Tippy>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->

      <div
        v-if="itemsData.totalCount > 0 && itemsData.totalPages > 1"
        class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
        style="background-color: #164e63; border-radius: 10px"
      >
        <div class="w-full sm:w-auto sm:mr-auto" style="background-color: #e1e7ef; border: 2px solid #164e63; border-radius: 10px">
          <paginate
            class="text-white"
            :page-count="pageCount"
            :page-range="5"
            :margin-pages="2"
            :click-handler="changePage"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :first-last-button="true"
            :initial-page="initialPage"
            :prev-text="'<'"
            :next-text="'>'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
          >
          </paginate>
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select v-model="pageSize" class="w-20 form-select box cursor-pointer" @change="pageSizeChange">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option :value="pageSize">{{ pageSize }}</option>
        </select>
      </div>
    </ModalBody>
  </MoveableModal>

  <PickingConfirmModal :name="formDataDrop.orderNumber" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <VerifyConfirmModal :name="formDataDrop.orderNumber" :modalStatus="verifyConfirmationModal" :modalConfirm="verifyItemConfirm" :modalClose="closeVerify" />
  <HoldConfirmModal :name="formDataDrop.orderNumber" :modalStatus="holdConfirmationModal" :modalConfirm="holdItemConfirm" :modalClose="closeHold" />
  <ReleaseConfirmModal :name="formDataDrop.orderNumber" :modalStatus="releaseConfirmationModal" :modalConfirm="releaseItemConfirm" :modalClose="closeRelease" />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import countriesJSON from "@/assets/json/countries.json";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import moment from "moment";
import { customerOrderStatus, customerOrderStatusColor } from "../../../global-functions/status";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import Moveable from "vue3-moveable";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Dropped Orders");
  },
  components: {
    Moveable,

    autoTable,
  },
  data() {
    return {
      headersFilter: [
        { text: "Order", value: "orderId" },
        { text: "priority", value: "priority" },
        { text: "status", value: "status" },
        { text: "Order Created", value: "time" },
        { text: "Booking", value: "despatchBookingNumber" },
        { text: "type", value: "type" },
      ],
      const_txt: const_txt,
      doc: null,

      searchData: "",
      isLoading: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      titleChange: -1,

      formDataDrop: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        statusList: "",
        despatchBookingNumber: "",
        currency: "",
        priority: 0,
        priorityNumber: 0,
        customer: {},
        deliveryAddress: {},
        despatch: {
          despatchId: 0,
          bookingNumber: "",
          courier: "",
          date: "",
          vehicleNumber: "",
          driverName: "",
          driverPhoneNumber: "",
          lpns: [],
        },

        orderLines: [
          {
            quantity: 0,
            price: 0,
            retailPrice: 0,
            discountedPrice: 0,
            discountedReason: "",
            status: 1,
            skuName: "",
            ean: "",
            description: "",
            skuId: 0,
            sku: "",
            orderId: 0,
          },
        ],
        // pickedOrders: [
        //   {
        //     orderNumber: "",
        //     orderLineId: 0,
        //     quantity: 0,
        //     picked: 0,
        //     stockKey: "",
        //     bin: "",
        //     section: "",
        //     warehouse: "",
        //     blockType: "",
        //     movementId: 0,
        //     lot: "",
        //     sku: "",
        //     ean: "",
        //     description: "",
        //     expiryDate: "",
        //     status: 0,
        //     priority: 1,
        //     statusList: [],
        //     userId: "",
        //     isPicked: false
        //   }
        // ]
      },
      formDataDropDefault: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        statusList: "",
        despatchBookingNumber: "",
        currency: "",
        priority: 0,
        priorityNumber: 0,
        customer: {},
        deliveryAddress: {},
        despatch: {
          despatchId: 0,
          bookingNumber: "",
          courier: "",
          date: "",
          vehicleNumber: "",
          driverName: "",
          driverPhoneNumber: "",
          lpns: [],
        },
        orderLines: [
          {
            quantity: 0,
            price: 0,
            retailPrice: 0,
            discountedPrice: 0,
            discountedReason: "",
            status: 1,
            skuName: "",
            ean: "",
            description: "",
            skuId: 0,
            sku: "",
            orderId: 0,
          },
        ],
      },

      formDataLpn: {
        lpn: "",
        date: "",
        addedBy: "",
        isDespatched: false,
        verifiedBy: "",
        verifiedAt: "",
        orderId: 0,
        despatch: "",
        pickedProducts: [],
      },

      formDataLpnDefault: {
        lpn: "",
        date: "",
        addedBy: "",
        isDespatched: false,
        verifiedBy: "",
        verifiedAt: "",
        orderId: 0,
        despatch: "",
        pickedProducts: [],
      },

      formDataDispatchDefault: {
        pickedProducts: [
          {
            orderLineId: 0,
            sortOrder: 0,
            quantity: 0,
            picked: 0,
            notPicked: 0,
            sku: "",
            ean: "",
            description: "",
            expiryDate: "",
            warehouse: "",
            storageSection: "",
            storageType: "",
            storageBin: "",
            movementId: 0,
            orderNumber: "",
          },
        ],
      },

      formDataDispatch: {
        pickedProducts: [
          {
            orderLineId: 0,
            sortOrder: 0,
            quantity: 0,
            picked: 0,
            notPicked: 0,
            sku: "",
            ean: "",
            description: "",
            expiryDate: "",
            warehouse: "",
            storageSection: "",
            storageType: "",
            storageBin: "",
            movementId: 0,
            orderNumber: "",
          },
        ],
      },

      pageCountLpns: 0,
      pageRowCountLpns: 5,
      productStartLpns: 0,
      productEndLpns: 5,

      pageCountDispatch: 0,
      pageRowCountDispatch: 5,
      productStartDispatch: 0,
      productEndDispatch: 5,

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        // sortColumnName: "status",
        sortDirection: "ASC",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],

      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      allItems: store.state.authUser.OrderTypes,
      allItemsData: [],
      isActive: 0,
      docs: null,

      typeItems: store.state.authUser.OrderTypes,
      statusItems: store.state.authUser.PickingOrderStatus,
      priorityItems: [],

      orderLines: [],
      orderLinesLocal: [],
      despatchModal: false,
      lpnNo: "",
      isRow: false,
      opened: [],
      openedDespatch: [],
      indexToggle: 0,
      indexToggleDespatch: 0,
      itemsNew: [],
      orderLineStatusItems: store.state.authUser.OrderLineStatuses,
      splitData: false,
      allSelected: false,
      checkedData: [],
      pickingConfirmationModal: false,

      policyAccess: "DroppedOrders",

      itemsLpn: [],
      itemsLpnData: [],
      itemsLpnLocal: [],

      itemsDispatch: [],
      itemsDispatchData: [],
      itemsDispatchLocal: [],
      verifyLpn: [],
      getOrderId: 0,

      orderLineStatusItem: store.state.authUser.OrderLineStatuses,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Picking" : "Edit Picking";
    },
  },
  watch: {},
  created() {
    this.getDataFromApi();
  },
  methods: {
    getOrderLineStatus(value) {
      try {
        return this.orderLineStatusItem.find((x) => x.number == value).displayName;
      } catch (e) {
        return "";
      }
    },
    getOrderLineStatusColors(value) {
      try {
        return this.orderLineStatusItem.find((x) => x.number == value).color;
      } catch (e) {
        return "";
      }
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    showDespatchInfo(item) {
      this.lpnNo = item.lpn;
      this.getDataFromDespatch(item.lpn);
      // this.despatchModal = true;
    },
    closeDespatch() {
      // this.despatchModal = false;
    },
    getVerifyFromLpns(orderNumber) {
      this.isLoading = true;
      axios
        .post("Lpns/GetByOrderNumber/" + orderNumber)
        .then((response) => {
          if (response.data.success) {
            let result = response.data.data;
            this.verifyLpn =
              result?.map((x) => {
                return x["lpn"];
              }) || [];
            this.isLoading = false;
          } else {
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast("No LPN found");
          this.isLoading = false;
        });
    },
    verifyItem(item) {
      this.formDataDrop = Object.assign({}, item);
      this.verifyConfirmationModal = true;
      this.getVerifyFromLpns(item.orderNumber);
    },
    closeVerify() {
      this.verifyConfirmationModal = false;
      this.setDefault();
    },
    verifyItemConfirm() {
      this.isLoading = true;
      var formData = {
        orderNumber: this.formDataDrop?.orderNumber,
        bookingNumber: this.formDataDrop?.despatchBookingNumber ? this.formDataDrop.despatchBookingNumber : null,
        warehouse: this.warehouseAbbreviation,
        lpns: this.verifyLpn ? (this.verifyLpn.length > 0 ? this.verifyLpn : null) : null,
      };

      console.log("verifyItemConfirm", formData, this.formDataDrop);
      axios
        .post("OrderProcess/ProcessOrder", formData)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            this.getDataFromApi();
            this.closeVerify();
            successToast(response.data.message);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    holdItem(item) {
      this.formDataDrop = Object.assign({}, item);
      this.holdConfirmationModal = true;
    },
    closeHold() {
      this.holdConfirmationModal = false;
      this.setDefault();
    },
    holdItemConfirm() {
      this.isLoading = true;
      axios
        .post("OrderProcess/Status/Hold?warehouse=" + this.warehouseAbbreviation + "&orderNumber=" + this.formDataDrop.orderNumber)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            this.getDataFromApi();
            this.closeHold();
            successToast(response.data.message);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    releaseItem(item) {
      this.formDataDrop = Object.assign({}, item);
      this.releaseConfirmationModal = true;
    },
    closeRelease() {
      this.releaseConfirmationModal = false;
      this.setDefault();
    },
    releaseItemConfirm() {
      this.isLoading = true;
      axios
        .post("OrderProcess/Status/Release?warehouse=" + this.warehouseAbbreviation + "&orderNumber=" + this.formDataDrop.orderNumber)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            this.getDataFromApi();
            this.closeRelease();
            successToast(response.data.message);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    toggle(item) {
      // this.orderLines = [];
      this.opened.splice(this.indexToggle, 1);
      this.indexToggle = this.opened.indexOf(item.orderId);
      this.getOrderId = item.orderId;
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.getDataFromLpns(item.orderNumber);
        this.opened.push(item.orderId);
      }
    },
    closeToggle(item) {
      this.itemsLpn = [];
      this.getOrderId = 0;
      this.indexToggle = this.opened.indexOf(item.orderId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.getDataFromLpns(item.orderNumber);
        this.opened.push(item.orderId);
      }
    },

    toggleDespatch(item) {
      // this.orderLines = [];
      this.openedDespatch.splice(this.indexToggleDespatch, 1);
      this.indexToggleDespatch = this.openedDespatch.indexOf(item.lpnId);
      if (this.indexToggleDespatch > -1) {
        this.openedDespatch.splice(this.indexToggleDespatch, 1);
      } else {
        this.getDataFromDespatch(item.lpn);
        this.openedDespatch.push(item.lpnId);
      }
    },
    closeToggleDespatch(item) {
      this.itemsDispatch = [];
      this.indexToggleDespatch = this.openedDespatch.indexOf(item.lpnId);
      if (this.indexToggleDespatch > -1) {
        this.openedDespatch.splice(this.indexToggleDespatch, 1);
      } else {
        this.getDataFromDespatch(item.lpn);
        this.openedDespatch.push(item.lpnId);
      }
    },
    // viewOrderLines(orderId) {
    //   try {
    //     var newArray = [];
    //     console.log(this.items.find((x) => x.orderId === orderId));
    //     newArray = this.items.find((x) => x.orderId === orderId).orderLines;
    //     this.orderLines = newArray;
    //     console.log(this.orderLines);
    //     this.setLocalPaginationLpns();
    //   } catch {}
    // },

    getOrderLineStatus(value) {
      try {
        return this.orderLineStatusItems?.find((x) => x.number === parseInt(value))?.displayName || "";
      } catch (e) {
        return "";
      }
    },
    getOrderLineStatusColor(value) {
      try {
        return this.orderLineStatusItems?.find((x) => x.number === parseInt(value))?.color || "";
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getStatus(value) {
      try {
        return this.statusItems?.find((x) => x.number === value)?.displayName || "";
      } catch (e) {
        return "";
      }
    },
    getStatusColor(value) {
      try {
        return this.statusItems?.find((x) => x.number === value)?.color || "";
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getType(value) {
      try {
        return this.typeItems?.find((type) => type.number === parseInt(value))?.name || "";
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Order#", dataKey: "orderNumber" },
              { title: "Amount", dataKey: "amount" },
              { title: "Order Created", dataKey: "time" },
              { title: "Booking#", dataKey: "despatchBookingNumber" },
              { title: "Customer", dataKey: "customer" },
              { title: "Address", dataKey: "deliveryAddress" },
              { title: "Note", dataKey: "note" },
              { title: "Status", dataKey: "status" },
              { title: "Type", dataKey: "type" },
              { title: "Discount Percentage", dataKey: "discountPercentage" },
              { title: "Discount Total", dataKey: "discountTotal" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                orderNumber: el.orderNumber,
                amount: el.amount,
                time: this.momentLocal(el.time),
                despatchBookingNumber: el.despatchBookingNumber,
                customer: el.customer.length <= 0 ? "" : el.customer.firstName + " " + el.customer.lastName,
                deliveryAddress:
                  el.deliveryAddress == null
                    ? ""
                    : el.deliveryAddress.line +
                      "\n" +
                      el.deliveryAddress.postcode +
                      "\n" +
                      el.deliveryAddress.state +
                      "\n" +
                      el.deliveryAddress.city +
                      "\n" +
                      el.deliveryAddress.country,
                note: el.note,
                status: this.getStatus(el.status),
                type: this.getType(el.type),
                discountPercentage: el.discountPercentage,
                discountTotal: el.discountTotal,
              };
              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post("DroppedOrders/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Dropped Orders " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Dropped Orders " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Order#",
          2: "Amount",
          3: "Order Created",
          4: "Booking#",
          5: "Customer",
          6: "Address Line",
          7: "Postcode",
          8: "State",
          9: "City",
          10: "Country",
          11: "Note",
          12: "Status",
          13: "Type",
          14: "Discount Percentage",
          15: "Discount Total",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.orderNumber,
            2: item.amount,
            3: this.momentLocalCSV(item.time),
            4: item.despatchBookingNumber,
            5: item.customer.length <= 0 ? "" : item.customer.firstName + " " + item.customer.lastName,
            6: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.line,
            7: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.postcode,
            8: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.state,
            9: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.city,
            10: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.country,
            11: item.note,
            12: this.getStatus(item.status),
            13: this.getType(item.type),
            14: item.discountPercentage,
            15: item.discountTotal,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("DroppedOrders/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },
    customerOrderStatus(status) {
      return customerOrderStatus(status);
    },
    customerOrderStatusColor(status) {
      return customerOrderStatusColor(status);
    },

    getToday() {
      const today = new Date();

      this.formDataDrop.time = moment(today).format("DD MMMM, YYYY");
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.pagination.pageNumber = 1;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;

      this.getDataFromApi();
    },

    changePageLpns(pageNum) {
      // this.pageNumber = pageNum;
      this.productEndLpns = this.pageRowCountLpns * pageNum;
      this.productStartLpns = this.productEndLpns - this.pageRowCountLpns;
      this.itemsLpnLocal = this.itemsLpn.slice(this.productStartLpns, this.productEndLpns);
    },
    changePageDispatch(pageNum) {
      // this.pageNumber = pageNum;
      this.productEndDispatch = this.pageRowCountDispatch * pageNum;
      this.productStartDispatch = this.productEndDispatch - this.pageRowCountDispatch;
      this.itemsDispatchLocal = this.itemsDispatch.slice(this.productStartDispatch, this.productEndDispatch);
    },
    setDefault() {
      this.formDataDrop = Object.assign({}, this.formDataDropDefault);
      this.getToday();
    },

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("DroppedOrders/" + this.warehouseAbbreviation + "/Paginated", this.pagination)
        .then((response) => {
          console.log(response);
          this.itemsData = response.data.data;
          this.items = response.data.data.items;
          this.pageCount = this.itemsData.totalPages;

          if (this.pagination.pageNumber == this.itemsData.totalPages) {
            this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
          } else {
            this.pageSizeView = this.pagination.pageSize;
          }

          for (var i = 0; i < this.items.length; i++) {
            this.items[i].selected = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast("No Data Available");
          this.isLoading = false;
        });
    },

    getDataFromDespatch(lpn) {
      this.isLoading = true;
      axios
        .post("Despatches/Lpns/" + lpn)
        .then((response) => {
          this.itemsDispatchData = response.data;

          let product = Object.values(
            response.data.data.pickedProducts.reduce((acc, item) => {
              acc[item.orderLineId] = acc[item.orderLineId]
                ? {
                    ...item,
                    quantity: item.quantity + acc[item.orderLineId].quantity,
                    picked: item.picked + acc[item.orderLineId].picked,
                    notPicked: item.notPicked + acc[item.orderLineId].notPicked,
                  }
                : item;
              return acc;
            }, {})
          );

          let getOrderLines = this.items.find((i) => i.orderId === this.getOrderId);
          if (getOrderLines) {
            product = product.map((i) => {

              getOrderLines.orderLines.forEach((el) => {
                if (i.orderLineId === el.orderLineId) {
                  i.status = el.status
                  i.reservedQuantity = el.reservedQuantity
                  i.droppedQuantity = el.droppedQuantity
                  i.processedQuantity = el.processedQuantity
                  i.despatchedQuantity = el.despatchedQuantity
                }
              });

              return i;
            });
      
          }

          console.log("getDataFromDespatch : product", product);
          this.itemsDispatch = product;
          this.setLocalPaginationDispatch();
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast("No Data Available");
          this.isLoading = false;
        });
    },
    setLocalPaginationDispatch() {
      if (this.itemsDispatchData) {
        this.itemsDispatchData.totalCount = this.itemsDispatch.length;
      }
      var pageCount = this.itemsDispatch.length / this.pageRowCountDispatch;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountDispatch = pageCountRound;
      } else {
        this.pageCountDispatch = pageCountRound;
      }
      if (this.pageRowCountDispatch > this.itemsDispatch.totalCount) {
        this.pageSizeDispatch = this.itemsDispatch.totalCount;
      } else {
        this.pageSizeDispatch = this.pageRowCountDispatch;
      }
      this.itemsDispatchLocal = this.itemsDispatch.slice(this.productStartDispatch, this.productEndDispatch);
    },
    getDataFromLpns(orderNumber) {
      this.isLoading = true;
      axios
        .post("Lpns/GetByOrderNumber/" + orderNumber)
        .then((response) => {
          this.itemsLpnData = response.data;
          this.itemsLpn = response.data.data ? response.data.data.filter((x)=> x.isDespatched === false) : []
          this.setLocalPaginationLpns();
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast("No Data Available");
          this.isLoading = false;
        });
    },

    setLocalPaginationLpns() {
      if (this.itemsLpnData) {
        this.itemsLpnData.totalCount = this.itemsLpn.length;
      }
      var pageCount = this.itemsLpn.length / this.pageRowCountLpns;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountLpns = pageCountRound;
      } else {
        this.pageCountLpns = pageCountRound;
      }
      if (this.pageRowCountLpns > this.itemsLpn.totalCount) {
        this.pageSizeLpns = this.itemsLpn.totalCount;
      } else {
        this.pageSizeLpns = this.pageRowCountLpns;
      }
      this.itemsLpnLocal = this.itemsLpn.slice(this.productStartLpns, this.productEndLpns);
    },
    connectTimetoDate() {
      var currentTime = moment(new Date()).format();
      var splitTime = currentTime.split("T");

      var time = moment(this.formDataDrop.time).format("YYYY-MM-DD");
      time = time.concat("T").concat(splitTime[1]);
      this.formDataDrop.time = time;

      var deliveryDate = moment(this.formDataDrop.deliveryDate).format("YYYY-MM-DD");
      deliveryDate = deliveryDate.concat("T").concat(splitTime[1]);
      this.formDataDrop.deliveryDate = deliveryDate;
    },
    archiveItem(item) {
      this.formDataDrop = Object.assign({}, item);
      this.archiveConfirmationModal = true;
      console.log(item);
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },
    closePick() {
      this.pickingConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataDrop.orderId;

      axios
        .get("CustomerOrders/" + itemId + "/Archive")
        .then((response) => {
          this.archiveConfirmationModal = false;
          this.getDataFromApi();
          successToast(response.data.message);
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("CustomerOrders/Archived", this.paginationArchive)
        .then((response) => {
          this.archiveData = response.data.data;
          this.archiveItems = response.data.data.items;
          this.pageCountArchive = this.archiveData.totalPages;

          this.archiveModal = true;
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive() {
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },

    restoreItem(item) {
      this.formDataDrop = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataDrop.orderId;

      axios
        .get("CustomerOrders/" + itemId + "/Restore")
        .then((response) => {
          this.restoreConfirmationModal = false;
          this.viewArchives();
          this.getDataFromApi();
          if (this.archiveData.totalCount == 0) {
            this.archiveModal = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    deleteItem(item) {
      this.formDataDrop = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var orderNumber = this.formDataDrop.orderNumber;
      axios
        .get(`OrderPickingQueue/Remove?orderNumber=${orderNumber}&warehouse=${this.warehouseAbbreviation}`)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white;";
    },
    getBooleanStatus(status) {
      switch (status) {
        case true:
          return "YES";

        case false:
          return "NO";
      }
    },
    getVerifiedStatus(status) {
      if (status) {
        return "Verified";
      } else {
        return "Dropped";
      }
    },
  },
};
</script>
