const PermissionAdminIdentifier = function (value) {
  const index = value.lastIndexOf(".");
  value = value.slice(index + 1);
  var icon = "SettingsIcon";

  switch (value) {
    case "All":
      icon = "SettingsIcon";
      return icon;

    case "View":
      icon = "EyeIcon";
      return icon;

    case "AdminView":
      icon = "ViewIcon";
      return icon;

    case "Create":
      icon = "PlusCircleIcon";
      return icon;

    case "Modify":
      icon = "EditIcon";
      return icon;

    case "Trash":
      icon = "ArchiveIcon";
      return icon;

    case "Restore":
      icon = "RotateCcwIcon";
      return icon;

    case "Delete":
      icon = "Trash2Icon";
      return icon;

    default:
      return icon;
  }
};

export { PermissionAdminIdentifier };
