import Icon from "../views/Components/icon/Main.vue";
import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import ErrorPage from "../views/Components/error-page/Main.vue";
import Warehouses from "../views/Components-New/warehouse-management/Warehouses.vue";
import BlockType from "../views/Components-New/warehouse-management/BlockType.vue";
import Licensee from "../views/Components-New/warehouse-management/licensee.vue";
import licenceeWarehouse from "../views/Components-New/warehouse-management/licenseeWarehouses.vue";
import StorageSections from "../views/Components-New/warehouse-management/Storage Sections.vue";
import StorageBins from "../views/Components-New/warehouse-management/Storage Bins.vue";
import StorageTypes from "../views/Components-New/warehouse-management/Storage Types.vue";
import PalletTypes from "../views/Components-New/warehouse-management/Pallet Types.vue";
import PalletSizes from "../views/Components-New/warehouse-management/Pallet Sizes.vue";
import ContainerTypes from "../views/Components-New/warehouse-management/Container Types.vue";
import ContainerSizes from "../views/Components-New/warehouse-management/Container Sizes.vue";
//import Locations from "../views/Components-New/warehouse-management/Locations.vue";

import Departments from "../views/Components-New/product-management/Departments.vue";
import Brands from "../views/Components-New/product-management/Brands.vue";
import Categories from "../views/Components-New/product-management/Categories.vue";
import Variants from "../views/Components-New/product-management/Variants.vue";
import VariantsType from "../views/Components-New/product-management/Variants Type.vue";
import Units from "../views/Components-New/product-management/Units.vue";
import Skus from "../views/Components-New/product-management/Sku.vue";
import Batches from "../views/Components-New/product-management/Batches.vue";
import Attributes from "../views/Components-New/product-management/attributes.vue";
import Products from "../views/Components-New/product-management/Products.vue";
import Supplier from "../views/Components-New/stock-management/Supplier.vue";
//import PurchaseInvoices from "../views/Components-New/stock-management/SupplierInvoices.vue";
import PurchaseStocks from "../views/Components-New/stock-management/Reorder.vue";
import PurchaseOrders from "../views/Components-New/stock-management/PurchaseOrders.vue";
import SortingOrders from "../views/Components-New/stock-management/Sorting Orders.vue";
import checkStock from "../views/Components-New/stock-management/Check Stocks.vue";

import Customers from "../views/Components-New/sale-management/Customer.vue";
import preparePicking from "../views/Components-New/sale-management/Picking Prepare.vue";
//import Orders from "../views/Components-New/sale-management/CustomerOrders.vue";
import customerInvoices from "../views/Components-New/sale-management/CustomerInvoices.vue";

import QueueOrders from "../views/Components-New/sale-management/QueueOrders.vue";
import PickingOrders from "../views/Components-New/sale-management/PickingOrders.vue";

import Dispatch from "../views/Components-New/outbound/Despatch.vue";
import Receival from "../views/Components-New/inbound/Receival.vue";
import Return from "../views/Components-New/inbound/Return.vue";
import Replenish from "../views/Components-New/inbound/Replenish.vue";

import AddStocks from "../views/Components-New/inbound/addStock.vue";
import Lots from "../views/Components-New/inbound/Lots.vue";
import Lpns from "../views/Components-New/outbound/Lpns.vue";
import DroppedOrders from "../views/Components-New/outbound/DroppedOrders.vue";

import ServerDown from "../views/Components-New/error-page/Server Down.vue";
import AccessDenied from "../views/Components-New/error-page/Access Denied.vue";

import OrderReport from "../views/Components-New/reports/CustomerOrderReport.vue";
import Report from "../views/Components-New/reports/Report.vue";
import ProductExpiration from "../views/Components-New/reports/Product Expiration.vue";
import StockSummary from "../views/Components-New/reports/Stock Summary.vue";
import EmptyBins from "../views/Components-New/reports/Empty Bins.vue";
import ProductPurchase from "../views/Components-New/reports/Product Purchase.vue";
import ProductSales from "../views/Components-New/reports/Product Sales.vue";

import Revenue from "../views/Components-New/reports/Revenue.vue";
import ProductSkuLocation from "../views/Components-New/reports/Stock Location.vue";
import StockTakeVariance from "../views/Components-New/reports/Stock Take Variance.vue";
import ProductSectionLocation from "../views/Components-New/reports/Section Stock.vue";
import Users from "../views/Components-New/user-management/Users.vue";
import Pickers from "../views/Components-New/user-management/Pickers.vue";
import Branches from "../views/Components-New/user-management/Branches.vue";
import UserPermission from "../views/Components-New/user-management/UserPermission.vue";

import Dashboard from "../views/Components-New/dashboard/Dashboard.vue";

import Login from "../views/Auth/Sign In.vue";
import ResetPassword from "../views/Auth/Reset Password.vue";

import ChangePassword from "../views/Auth/Change Password.vue";
import PickingPrepare from "../views/Components-New/sale-management/Picking Prepare.vue";
import Stocktakes from "../views/Components-New/product-management/Stocktakes.vue";
import CycleCounts from "../views/Components-New/product-management/CycleCounts.vue";
import test from "../views/Components-New/test/invoice.vue";
import logs from "../views/Components-New/logs/index.vue";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "",
    redirect: (to) => {
      return { path: "/wms/customer-orders" };
    },
  },
  {
    path: "/testi",
    name: "test",
    component: test,
  },
  {
    path: "/icon",
    name: "simple-menu-icon",
    component: Icon,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { requiresAuth: true },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: "/demo",
    name: "demo",
    component: PickingPrepare,
  },
  {
    path: "/wms",
    component: SideMenu,
    meta: { requiresAuth: true },
    children: [
      {
        path: "logs",
        name: "Logs",
        component: logs,
      },
      {
        path: "users",
        name: "Users",
        component: Users,
      },
      {
        path: "pickers",
        name: "Pickers",
        component: Pickers,
      },
      {
        path: "branches",
        name: "Branches",
        component: Branches,
      },
      {
        path: "warehouses",
        name: "Warehouses",
        component: Warehouses,
      },
      {
        path: "BlockType",
        name: "BlockType",
        component: BlockType,
      },
      {
        path: "licensee",
        name: "Licensee",
        component: Licensee,
      },
      {
        path: "licenceeWarehouse",
        name: "licenceeWarehouse",
        component: licenceeWarehouse,
      },
      {
        path: "storage-types",
        name: "StorageTypes",
        component: StorageTypes,
      },
      {
        path: "storage-sections",
        name: "StorageSections",
        component: StorageSections,
      },
      {
        path: "storage-bins",
        name: "StorageBins",
        component: StorageBins,
      },
      {
        path: "container-sizes",
        name: "ContainerSizes",
        component: ContainerSizes,
      },
      {
        path: "locations/:id?",
        name: "Locations",
        component: () =>
          import("../views/Components-New/warehouse-management/Locations.vue"),
      },
      {
        path: "container-types",
        name: "ContainerTypes",
        component: ContainerTypes,
      },
      {
        path: "pallet-sizes",
        name: "PalletSizes",
        component: PalletSizes,
      },
      {
        path: "pallet-types",
        name: "PalletTypes",
        component: PalletTypes,
      },
      {
        path: "category",
        name: "Category",
        component: Categories,
      },
      {
        path: "brand",
        name: "Brand",
        component: Brands,
      },
      {
        path: "variants-type/",
        name: "VariantType",
        component: VariantsType,
      },
      {
        path: "variant/:id",
        name: "Variant",
        component: Variants,
      },
      {
        path: "units",
        name: "Units",
        component: Units,
      },
      {
        path: "skus",
        name: "Skus",
        component: Skus,
      },
      {
        path: "batches",
        name: "Batches",
        component: Batches,
      },
      {
        path: "attributes",
        name: "Attributes",
        component: Attributes,
      },
      {
        path: "stocktakes",
        name: "Stocktakes",
        component: Stocktakes,
      },
      {
        path: "cycle-count",
        name: "CycleCounts",
        component: CycleCounts,
      },
      {
        path: "products",
        name: "Products",
        component: Products,
      },
      {
        path: "suppliers",
        name: "Suppliers",
        component: Supplier,
      },
      {
        path: "supplier-invoices",
        name: "SupplierInvoices",
        component: () =>
          import(
            "../views/Components-New/stock-management/SupplierInvoices.vue"
          ),
      },
      {
        path: "purchase-orders",
        name: "PurchaseOrders",
        component: PurchaseOrders,
      },
      {
        path: "reorder-stocks",
        name: "ReorderStocks",
        component: PurchaseStocks,
      },
      {
        path: "departments",
        name: "Departments",
        component: Departments,
      },
      {
        path: "customer-quotations",
        name: "CustomerQuotations",
        component: () =>
          import(
            "../views/Components-New/sale-management/CustomerQuotation.vue"
          ),
      },
      {
        path: "customer-orders",
        name: "CustomerOrders",
        component: () =>
          import("../views/Components-New/sale-management/CustomerOrders.vue"),
      },
      {
        path: "oos-order-lines",
        name: "OutOfStockOrderLines",
        component: () =>
          import(
            "../views/Components-New/sale-management/OutOfStockOrderLines.vue"
          ),
      },
      {
        path: "check-stocks",
        name: "CheckStocks",
        component: checkStock,
      },
      {
        path: "sorting-orders",
        name: "SortingOrders",
        component: SortingOrders,
      },
      {
        path: "prepare-picking",
        name: "PreparePicking",
        component: preparePicking,
      },

      {
        path: "dispatch",
        name: "Dispatch",
        component: Dispatch,
      },
      {
        path: "receival",
        name: "Receival",
        component: Receival,
      },
      {
        path: "replenish",
        name: "Replenish",
        component: Replenish,
      },
      {
        path: "lots",
        name: "Lots",
        component: Lots,
      },
      {
        path: "addstocks",
        name: "AddStocks",
        component: AddStocks,
      },
      {
        path: "return",
        name: "Return",
        component: Return,
      },

      {
        path: "lpns",
        name: "Lpns",
        component: Lpns,
      },
      {
        path: "dropped-orders",
        name: "DroppedOrders",
        component: DroppedOrders,
      },
      {
        path: "customers",
        name: "Customers",
        component: Customers,
      },
      {
        path: "picking-orders",
        name: "PickingOrders",
        component: PickingOrders,
      },

      {
        path: "customer-invoices",
        name: "CustomerInvoices",
        component: customerInvoices,
      },
      {
        path: "order-reports",
        name: "OrderReports",
        component: OrderReport,
      },
      {
        path: "product-sales-reports",
        name: "product-sales-report",
        component: Report,
      },
      {
        path: "revenue-reports",
        name: "RevenueReports",
        component: Revenue,
      },

      {
        path: "product-sku-reports",
        name: "ProductSkuReports",
        component: ProductSkuLocation,
      },
      {
        path: "Product-Purchase",
        name: "ProductPurchase",
        component: ProductPurchase,
      },
      {
        path: "Empty-Bins",
        name: "EmptyBins",
        component: EmptyBins,
      },
      {
        path: "Stock-Summary",
        name: "StockSummary",
        component: StockSummary,
      },
      {
        path: "Stock-Take-Variance",
        name: "StockTakeVariance",
        component: StockTakeVariance,
      },
      {
        path: "Product-Expiration",
        name: "ProductExpiration",
        component: ProductExpiration,
      },
      {
        path: "product-section-reports",
        name: "ProductSectionReports",
        component: ProductSectionLocation,
      },

      {
        path: "product-sales-reports",
        name: "ProductSalesReports",
        component: ProductSales,
      },

      {
        path: "queue-orders",
        name: "QueueOrders",
        component: QueueOrders,
      },
      {
        path: "user-permission",
        name: "UserPermissions",
        component: UserPermission,
      },

      // {
      //   path: "/",
      //   name: "side-menu-dashboard",
      //   component: Locations,
      // },
    ],
  },

  {
    path: "/page-down",
    name: "PageDown",
    component: ServerDown,
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: AccessDenied,
  },

  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
  {
    path: "/logout",
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      console.log("logout");
      store
        .dispatch("authUser/logout")
        .then(() => {
          next("/login");
        })
        .catch((error) => {
          console.error(error);
          next("/login");
        });
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from) => {
  console.log("beforEach", to, from);
  if (to.meta.requiresAuth && !store.getters["authUser/isLoggedIn"]) {
    return {
      path: "/login",
      // query: { redirect: to.fullPath },
    };
  }
  if (store.getters["authUser/isLoggedIn"]) {
    // console.log("Logged In:", store.state.authUser);
    // store.getters["authUser/getPermission"]("Permissions.Users.Create");
  }

  //next();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["authUser/isLoggedIn"]) {
      if (hasAccess(to.name)) {
      } else if (to.fullPath === "/logout") {
        console.log("logout");
      } else {
        return {
          path: "/access-denied",
          //path: "/login",
          query: { redirect: to.fullPath },
        };
      }
    } else {
      return {
        path: "/login",
        query: { redirect: to.fullPath },
      };
    }
  }
});
function hasAccess(name) {
  var permissions = store.state.authUser.permissions;
  switch (name) {
    case "BlockType":
      return permissions.includes("Permissions.Batches.View");
    case "Batches":
      return permissions.includes("Permissions.Batches.View");
    case "Attributes":
      return permissions.includes("Permissions.Attributes.View");
    case "Products":
      return permissions.includes("Permissions.Products.View");
    case "StorageBins":
      return permissions.includes("Permissions.Locations.View");
    case "Locations":
      return permissions.includes("Permissions.Locations.View");

    case "Warehouses":
      return permissions.includes("Permissions.Warehouses.View");
    case "Licensee":
      return permissions.includes("Permissions.Licensees.View");

    case "StorageTypes":
      return permissions.includes("Permissions.StorageTypes.View");
    case "StorageSections":
      return permissions.includes("Permissions.StorageSections.View");
    // case "ContainerSizes":
    //   return permissions.includes("Permissions.Locations.View");
    // case "ContainerTypes":
    //   return permissions.includes("Permissions.Locations.View");
    // case "PalletSizes":
    //   return permissions.includes("Permissions.Locations.View");
    // case "PalletTypes":
    //   return permissions.includes("Permissions.Locations.View");

    case "Logs":
      return true;
    case "Users":
      return permissions.includes("Permissions.Users.View");
    case "Pickers":
      return permissions.includes("Permissions.Branches.View");
    case "Branches":
      return permissions.includes("Permissions.Pickers.View");
    case "UserPermissions":
      return permissions.includes("Permissions.UserPermissions.View");
    case "Units":
      return permissions.includes("Permissions.Units.View");
    case "Stocktakes":
      return permissions.includes("Permissions.Stocktake.View");
    case "CycleCounts":
      return permissions.includes("Permissions.CycleCounts.View");
    case "Skus":
      return permissions.includes("Permissions.SKUs.View");
    case "Suppliers":
      return permissions.includes("Permissions.Suppliers.View");
    case "SupplierInvoices":
      return permissions.includes("Permissions.SupplierInvoices.View");
    case "PurchaseOrders":
      return permissions.includes("Permissions.PurchaseOrders.View");
    case "ReorderStocks":
      return permissions.includes("Permissions.ReorderStocks.View");
    case "Departments":
      return permissions.includes("Permissions.Departments.View");
    case "Receival":
      return permissions.includes("Permissions.Receival.View");
    case "Return":
      return permissions.includes("Permissions.ProductReturns.View"); //
    case "Replenish":
      return permissions.includes("Permissions.Replenish.View");
    case "Lots":
      return permissions.includes("Permissions.LOTS.View");
    case "AddStocks":
      return permissions.includes("Permissions.LOTS.View");
    case "Customers":
      return permissions.includes("Permissions.Customers.View");
    case "CustomerOrders":
      return permissions.includes("Permissions.CustomerOrders.View");
    case "OutOfStockOrderLines":
      return permissions.includes("Permissions.CustomerOrders.View"); // TODO - Change permission
    case "CustomerQuotations":
      return permissions.includes("Permissions.CustomerOrders.View");
    case "CustomerInvoices":
      return permissions.includes("Permissions.CustomerInvoices.View");
    case "QueueOrders":
      return permissions.includes("Permissions.QueueOrders.View");
    case "PickingOrders":
      return permissions.includes("Permissions.PickingOrders.View");
    case "Dispatch":
      return permissions.includes("Permissions.Dispatch.View");
    case "Lpns":
      return permissions.includes("Permissions.LPNS.View");
    case "DroppedOrders":
      return permissions.includes("Permissions.DroppedOrders.View");
    case "RevenueReports":
      return permissions.includes("Permissions.RevenueReports.View");
    case "OrderReports":
      return permissions.includes("Permissions.ProductSalesReports.View");
    case "ProductSalesReports":
      return permissions.includes("Permissions.ProductSalesReports.View");
    case "ProductPurchase":
      return permissions.includes(
        "Permissions.ProductSectionLocationsReports.View"
      );
    case "EmptyBins":
      return permissions.includes(
        "Permissions.ProductSectionLocationsReports.View"
      );
    case "StockSummary":
      return permissions.includes(
        "Permissions.ProductSectionLocationsReports.View"
      );
    case "StockTakeVariance":
      return permissions.includes(
        "Permissions.ProductSectionLocationsReports.View"
      );
    case "ProductSkuReports":
      return permissions.includes(
        "Permissions.ProductSKULocationsReports.View"
      );
    case "ProductExpiration":
      return permissions.includes(
        "Permissions.ProductSKULocationsReports.View"
      );
    case "ProductSectionReports":
      return permissions.includes(
        "Permissions.ProductSectionLocationsReports.View"
      );
    case "ChangePassword":
      return permissions.includes("Permissions.Settings.View");
    case "ResetPassword":
      return permissions.includes("Permissions.Settings.View");
    default:
      return false;
  }
}

export default router;
