<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label"
            >Storage Sections</label
          >
          <div class="w-72">
            <v-select
              v-model="formDataStorageabb"
              placeholder="SELECT"
              :filterable="false"
              @open="onOpenSS"
              @close="onCloseSS"
              class="style-chooser cursor-pointer"
              @search="searchSS"
              :key="abbreviation"
              :get-option-label="(option) => option.abbreviation"
              :options="storageSections"
              :reduce="(abbreviation) => abbreviation.abbreviation"
              label="abbreviation"
            >
              <template #list-footer>
                <li v-show="hasNextPageSS" ref="loadSS" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>
        <!-- 
        <div>
          <label for="regular-form-1" class="form-label">Filter </label>

          <Dropdown>
            <DropdownToggle class="btn px-2 box">
              <span class="w-5 h-5 flex items-center justify-center">
                <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4" />
              </span>
            </DropdownToggle>
            <DropdownMenu class="w-40">
              <DropdownContent>
                <DropdownItem
                  @click="setMonth(item)"
                  v-for="(item, i) in monthsList"
                  :key="i"
                >
                  <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4 mr-2" />
                  {{ item.name }}
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div> -->

        <div>
          <a
            href="javascript:;"
            @click="getReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            GET REPORT
          </a>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="clearReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            CLEAR
          </a>
        </div>
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pageNumber"
        />
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table
        class="table table-report -mt-2 table-auto"
        v-if="reportsLocal.length > 0"
      >
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div
      v-if="reports.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-components/toast/toast";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Empty Bins Reports");
    this.observerSS = new IntersectionObserver(this.infiniteScrollSS);
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      storageSections: [],
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,
      formDataStorageabb: "",
      headers: [
        {
          text: "Storage BinId",
          value: "storageBinId",
        },
        { text: "Name", value: "name" },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Description", value: "description" },
        { text: "Max Weight", value: "maxWeight" },
        { text: "Max Dimension", value: "maxDimension" },
        { text: "LocationX", value: "locationX" },
        { text: "LocationY", value: "locationY" },
        { text: "LocationZ", value: "locationZ" },
        { text: "Storage Type", value: "storageTypeId" },
        { text: "Storage Type Name", value: "storageTypeName" },
        { text: "StorageTypeAbbreviation", value: "storageTypeAbbreviation" },
        { text: "BlockTypeName", value: "blockTypeName" },
        { text: "Section Name", value: "sectionName" },
        { text: "Section Abbreviation", value: "sectionAbbreviation" },
        { text: "Warehouse Name", value: "warehouseName" },
        { text: "Warehouse Abbreviation", value: "warehouseAbbreviation" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      customers: [],
      itemsData: {},

      observerSS: null,
      limitSS: 5,
      hasNextPageSS: false,
      totalSSCount: 0,
      isSSSearchResult: false,
      ssPageNumber: 1,
      ssSearchTimer: undefined,

      reports: [],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
      switchOption: 0,
    };
  },
  computed: {
    checkLimtSS() {
      return this.ssPageNumber;
    },

    getReportData() {
      return this.formDataStorageabb;
    },
  },
  created() {
    this.getCurrentMonth();
    this.getStorageSections();
  },
  watch: {
    checkLimtSS() {
      this.getStorageSections();
    },
    getReportData() {
      if (this.formDataStorageabb === null) {
        this.clearSS();
      }
      if (this.formDataStorageabb != "") {
        if (this.formDataStorageabb === "ALL") {
          this.switchOption = 0;
        } else {
          this.switchOption = 1;
        }
        // this.getReports();
      } else {
        warningToast(const_txt.NO_DATA);
      }
    },
    // getWarehouseSection() {
    //   this.getReports();
    // },
  },
  methods: {
    async onOpenSS() {
      if (this.isSSSearchResult) {
        this.ssPageNumber = 1;
      }
      if (this.hasNextPageSS) {
        await this.$nextTick();
        this.observerSS.observe(this.$refs.loadSS);
      }
    },
    onCloseSS() {
      this.observerSS.disconnect();
    },
    async infiniteScrollSS([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.ssPageNumber++;
        this.limitSS;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Storage BinId", dataKey: "storageBinId" },
          { title: "Name", dataKey: "name" },
          { title: "Abbreviation", dataKey: "abbreviation" },
          { title: "Description", dataKey: "description" },
          { title: "Max Weight", dataKey: "maxWeight" },
          { title: "LocationX", dataKey: "locationX" },
          { title: "LocationY", dataKey: "locationY" },
          { title: "LocationZ", dataKey: "locationZ" },
          { title: "Storage Type", dataKey: "storageTypeId" },
          { title: "Storage Type Name", dataKey: "storageTypeName" },
          {
            title: "StorageTypeAbbreviation",
            dataKey: "storageTypeAbbreviation",
          },
          { title: "BlockTypeName", dataKey: "blockTypeName" },
          { title: "Section Name", dataKey: "sectionName" },
          { title: "Section Abbreviation", dataKey: "sectionAbbreviation" },
          { title: "Warehouse Name", dataKey: "warehouseName" },
          { title: "Warehouse Abbreviation", dataKey: "warehouseAbbreviation" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            storageBinId: el.storageBinId,
            name: el.name,
            abbreviation: el.abbreviation,
            description: el.description,
            maxWeight: el.maxWeight,
            maxDimension: el.maxDimension,
            locationX: el.locationX,
            locationY: el.locationY,
            locationZ: el.locationZ,
            storageTypeId: el.storageTypeId,
            storageTypeName: el.storageTypeName,
            storageTypeAbbreviation: el.storageTypeAbbreviation,
            blockTypeName: el.blockTypeName,
            sectionName: el.sectionName,
            sectionAbbreviation: el.sectionAbbreviation,
            warehouseName: el.warehouseName,
            warehouseAbbreviation: el.warehouseAbbreviation,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(
            footer,
            pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
            pageHeight - 0.2,
            { baseline: "bottom" }
          );
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Empty Bins Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Empty Bins Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "Storage BinId",
          2: "Name",
          3: "Abbreviation",
          4: "Description",
          5: "Max Weight",
          6: "Max Dimension",
          7: "LocationX",
          8: "LocationY",
          9: "LocationZ",
          10: "Storage Type",
          11: "Storage Type Name",
          12: "StorageTypeAbbreviation",
          13: "BlockTypeName",
          14: "Section Name",
          15: "Section Abbreviation",
          16: "Warehouse Name",
          17: "Warehouse Abbreviation",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.storageBinId,
            2: item.name,
            3: item.abbreviation,
            4: item.description,
            5: item.maxWeight,
            6: item.maxDimension,
            7: item.locationX,
            8: item.locationY,
            9: item.locationZ,
            10: item.storageTypeId,
            11: item.storageTypeName,
            12: item.storageTypeAbbreviation,
            13: item.blockTypeName,
            14: item.sectionName,
            15: item.sectionAbbreviation,
            16: item.warehouseName,
            17: item.warehouseAbbreviation,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    getCurrentMonth() {
      const today = new Date();
      this.selectedMonth = today.getMonth() - 1;
      this.getMonthRange();
    },

    setMonth(item) {
      this.selectedMonth = item.value - 1;
      this.getMonthRange();
    },
    getMonthRange() {
      var d = new Date();
      var start = new Date(d.getFullYear(), this.selectedMonth, 1);
      var end = new Date(d.getFullYear(), this.selectedMonth + 1, 0);

      var startdate =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getDate();
      var enddate =
        end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate();
    },
    clearSS() {
      this.storageSections = [];
      this.ssPageNumber = 1;
      this.limitSS = 5;
      this.getStorageSections("");
    },

    searchSS(search) {
      clearInterval(this.ssSearchTimer);
      this.ssSearchTimer = setTimeout(() => {
        if (search) {
          this.storageSections = [];
          this.ssPageNumber = 1;
          this.limitSS = this.totalSSCount;
          this.getStorageSections(search.trim());
        }
      }, 2000);
    },
    async getStorageSections(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.ssPageNumber,
        pageSize: this.limitSS,
        search: search,
      };
      await axios
        .post(
          "StorageSections/" + this.warehouseAbbreviation + "/Paginated",
          pagination
        )
        .then((response) => {
          this.isSSSearchResult = !!search;
          this.storageSections.unshift({
            storageSectionId: null,
            abbreviation: "ALL",
            warehouseId: this.warehouseStoreId,
          });

          this.storageSections =
            this.isSSSearchResult !== true
              ? Array.from(
                  new Set(
                    [...this.storageSections, ...response.data.data.items].map(
                      JSON.stringify
                    )
                  )
                ).map(JSON.parse)
              : response.data.data.items;

          this.hasNextPageSS = response.data.data.hasNextPage;
          this.totalSSCount = response.data.data.totalCount;
          if (this.storageSections.length == 0) {
            warningToast("Please Add Items in Storage Section");
          } else {
            //  console.log("WH", store.state.authUser.warehouse.warehouseId);
            this.storageSections = this.storageSections.filter(function (x) {
              return (
                x.warehouseId == store.state.authUser.warehouse.warehouseId
              );
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          //   this.addBin = false;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearReports() {
      this.reports = [];
      this.reportsLocal = [];
      this.formDataStorageabb = null;
      this.getCurrentMonth();
    },

    getReports() {
      this.isLoading = true;

      console.log(this.formDataStorageabb);

      axios
        .get(
          this.switchOption > 0
            ? "Reports/" +
                this.warehouseAbbreviation +
                "/" +
                this.formDataStorageabb +
                "/Bins/Empty"
            : "Reports/" + this.warehouseAbbreviation + "/Bins/Empty"
        )
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.isLoading = false;
            this.itemsData = response.data;
            if (response.data) {
              this.itemsData.totalCount = this.itemsData.data.length;
            }
            this.reports = response.data.data;
            this.setLocalReport();
            if (this.reports.length == 0) {
              this.isLoading = false;
              warningToast("NO DATA ", response.data.message);
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        });

      this.switchOption = 0;
    },

    searchOnchange() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getReports();
      this.searchActive = false;
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getReports();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
