<template>
  <div class="flex mt-5 justify-center items-center space-x-2 mr-4">
    <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="print">
      <PrinterIcon class="w-4 h-4 mr-2" /> Invoice Print 
    </button>

    <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="download">
      <FileTextIcon class="w-4 h-4 mr-2" /> Invoice Download PDF
    </button>

     <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="billPrint">
      <PrinterIcon class="w-4 h-4 mr-2" /> Bill Print 
    </button>

    <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="billDownload">
      <FileTextIcon class="w-4 h-4 mr-2" /> Bill Download PDF
    </button>

  </div>

  <div class="w-full p-32">
    <div ref="content">
      <div class="flex flex-col space-y-2 w-full bg-white">
        <div class="w-full h-10 bg-black flex justify-center items-center">
          <div class="text-white font-bold text-xl">Tax Invoice/ Receipt</div>
        </div>
        <div class="flex space-x-2 w-full">
          <div class="w-1/2">
            <img src="https://cdn.logo.com/hotlink-ok/logo-social.png" width="700" height="300" />
          </div>
          <div class="flex flex-col space-y-2 w-1/2">
            <div class="flex flex-col justify-center items-center space-y-2">
              <div class="font-bold text-lg">RIVERVIEW PRODUCE PTY LTD</div>
              <div>198 YARRAMUNDI LANE</div>
              <div>RICHMOND, NSW 2753</div>
              <div>Ph 02 45885318</div>
              <div>www.riverviewproduce.com.au</div>
              <div>riverviewproduce@hotmail.com</div>
              <div>ABN 22619944375</div>
            </div>

            <div class="grid grid-cols-2 gap-2 self-end">
              <div class="col-span-2">Invoice No. I0000009492</div>
              <div>18/08/2022 </div>
              <div>1:32:07 PM </div>
            </div>

          </div>
        </div>
        <div class="flex space-x-2 w-full">
          <div class="flex flex-col justify-start items-start border border-black p-2 w-1/2">
            <div class="font-bold">Customer Details:</div>
            <div>KEL CAMPBELL PTY LTD</div>
            <div>KEL CAMPBELL PTY LTD</div>
            <div>#1698</div>
            <div>1141 CASTKEREAGH Road</div>
            <div>CASTLEREAGH NSW 2749</div>
            <div>P: 0247262700 M: 0410586584</div>
          </div>

          <div class="flex space-x-4 border border-black p-2 w-1/2">
            <div class="flex flex-col justify-start items-start">
              <div class="font-bold">Delivert to:</div>
              <div class="font-bold">KEL CAMPBELL PTY LTD</div>
              <div class="font-bold">KEL CAMPBELL PTY LTD</div>
              <div class="font-bold">#1698</div>
              <div class="font-bold">1141 CASTKEREAGH Road</div>
              <div class="font-bold">CASTLEREAGH NSW 2749</div>
              <div class="font-bold">P: 0247262700 M: 0410586584</div>
              <div class="font-bold">Mobile 0247262700</div>
            </div>
            <div>Order No: O12345</div>

          </div>
        </div>
        <div class="w-full">
          <table class="w-full">
            <thead class="bg-black">
              <tr>

                <th class="px-4 py-2 text-xs text-white ">
                  Description
                </th>
                <th class="px-4 py-2 text-xs text-white ">
                  QTY
                </th>
                <th class="px-4 py-2 text-xs text-white ">
                  GST
                </th>
                <th class="px-4 py-2 text-xs text-white ">
                  Unit Price
                </th>
                <th class="px-4 py-2 text-xs text-white ">
                  Total
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr class="whitespace-nowrap">

                <td class="px-6 py-4">
                  <div class="text-sm text-center text-gray-900">
                    LETTUCE
                  </div>
                </td>
                <td class="px-6 text-center py-4">
                  <div class="text-sm text-center text-gray-500">1</div>
                </td>
                <td class="px-6 py-4 text-center text-sm text-gray-500">

                </td>
                <td class="px-6 py-4 text-center text-sm text-gray-500">
                  $27.80
                </td>
                <td class="px-6 text-center py-4">
                  $27.80
                </td>
              </tr>
              <tr class="whitespace-nowrap">

                <td class="px-6 py-4">
                  <div class="text-sm text-center text-gray-900">
                    MISC
                  </div>
                </td>
                <td class="px-6 py-4">
                  <div class="text-sm text-center text-gray-500">1</div>
                </td>
                <td class="px-6 py-4 text-center text-sm text-gray-500">

                </td>
                <td class="px-6 py-4 text-center text-sm text-gray-500">
                  $60
                </td>
                <td class="px-6 text-center py-4">
                  $12
                </td>
              </tr>
              <tr class="border-b-2 whitespace-nowrap">

                <td class="px-6 py-4">
                  <div class="text-sm text-center text-gray-900">
                    MISC
                  </div>
                </td>
                <td class="px-6 py-4">
                  <div class="text-sm text-center text-gray-500">1</div>
                </td>
                <td class="px-6 py-4 text-center text-sm text-gray-500">

                </td>
                <td class="px-6 py-4 text-center text-sm text-gray-500">
                  $10
                </td>
                <td class="px-6 text-center py-4">
                  $13
                </td>
              </tr>
              <tr class="">
                <td colspan="3">
                  <div class="p-2 flex space-x-1">
                    <div>Signature:</div>
                    <p class="border-solid border-b w-full border-black"></p>
                  </div>
                </td>
                <td class="text-sm">Sub Total</td>
                <td class="text-sm tracking-wider">$950</td>
              </tr>
              <tr class="">
                <td colspan="3">
                  <div class="p-2 flex flex-col items-center justify-center text-sm text-black">
                    <div>Thank you for Shopping at</div>
                    <div>Riverview Produce</div>

                  </div>
                </td>
                <td class="text-sm">Rounding</td>
                <td class="text-sm tracking-wider">$0.00</td>
              </tr>
              <!--end tr-->

              <!--end tr-->
              <tr class="">
                <th colspan="3"></th>
                <td class="font-bold text-lg">Total</td>
                <td class="font-bold text-lg">$999.0</td>
              </tr>
              <!--end tr-->
              <tr>
                <th colspan="3"></th>
                <td class="text-sm">^GST Total</td>
                <td class="text-sm">$0.00</td>
              </tr>
              <tr>
                <th colspan="3"></th>
                <td class="text-sm">Invoice Balance</td>
                <td class="text-sm">$105.00</td>
              </tr>
              <tr>
                <th colspan="3"></th>
                <td class="text-sm">Customer Balance</td>
                <td class="text-sm">$117.00</td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="w-full pb-8 pt-4">
          <div class="flex flex-col justify-center items-center">
            <div class="font-bold text-lg text-danger">CHANGE OF BANK DETAILS</div>
            <div class="font-bold text-sm ">Account Details</div>
            <div class="font-bold text-sm ">Name: Riverview Produce PTY LTD</div>
            <div class="font-bold text-sm ">BSB: 032 272</div>
            <div class="font-bold text-sm ">Account Number: 378 556</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-20 bg-white w-1/2">
    <div ref="billContent" class="flex flex-col justify-center items-center">
      <div class="font-bold text-2xl pb-1">RIVERVIEW</div>
      <div class="text-lg">AB 54 228 001 456</div>
      <div class="text-lg">198 YARRAMUNDI LANE</div>
      <div class="text-lg">AGNES BANKS NSW 2753</div>
      <div class="text-lg">MOBILE 0417695845</div>
      <div class="text-lg">DATE 19/08/2022 FRI TIME 09.46</div>
<table class="table-auto pt-1">
  <thead>
    <tr>
      <th class="text-lg">MDSE RETURN</th>
      <th class="text-lg">*******</th>
      <th class="text-lg">*******</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="text-lg">10</td>
      <td class="text-lg">$2.50</td>
      <td class="text-lg"></td>
    </tr>
    <tr class="">
      <td class="text-lg">MISC</td>
      <td class="text-lg"></td>
      <td class="text-lg">-25.00</td>
    </tr>
    <tr>
      <td class="text-lg">TOTAL</td>
      <td class="text-lg"></td>
      <td class="text-lg">-25.00</td>
    </tr>
     <tr>
      <td class="text-lg">CASH</td>
      <td class="text-lg"></td>
      <td class="text-lg">-25.00</td>
    </tr>
  </tbody>
</table>
 <div class="font-bold text-xl pt-1">THANK YOU</div>
      <div class="font-bold text-xl pb-1">TRADING HOURS</div>
      <div class="text-lg">MON-FRI 8.30 - 5.30</div>
      <div class="text-lg">SATURDAY 8.30 - 4.00</div>
      <div class="text-lg">SUNDAY 8.30 - 4.00</div>
      <div class="text-lg">CLERK 1 225363 0000</div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
import Tabulator from "tabulator-tables";
import VueHtml2pdf from "vue-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import html2canvas from "html2canvas"
//import Vue from "vue";
//Vue.component("qrcode-vue", QrcodeVue);

import router from "../../../router";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "test");
  },
  components: {
    paginate: Paginate,
    html2canvas,
    QrcodeVue,
    autoTable,
  },
  data() {
    return {
      // doc: null,
      // pdf: null,

    };
  },

  filters: {

  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {
    download() {
      var doc = new jsPDF('p', 'pt', 'a4');
      doc.html(this.$refs.content, {
        callback: function (doc) {
          doc.save("invoice.pdf");
        },
        margin: [5, 5, 5, 5],
        x: 20,
        y: 10,
        html2canvas: {
          scale: 0.5, 
          width: 1000 
        },
      });
    },

    print() {
      // this.report();
      var doc = new jsPDF('p', 'pt', 'a4');
      doc.html(this.$refs.content, {
        callback: function (doc) {
          doc.autoPrint();
          doc.output("dataurlnewwindow");
        },
        margin: [5, 5, 5, 5],
        x: 20,
        y: 10,
        html2canvas: {
          scale: 0.5, 
          width: 1000 
        },
      });

   

    // report() {
    //   this.pdf = new jsPDF({
    //     orientation: "portrait",
    //     unit: "in",
    //     format: "a4",
    //   });




    //   // this.pdf.setFillColor(255,255,200);
    //   this.pdf.setLineWidth(1).line(0.5, 0, 8.0, 0);
    //   // this.pdf.rect(0.5, 0, 10, 0.5, 'F');
    //   this.pdf.setTextColor(255, 255, 255);
    //   this.pdf.setFontSize(18).setFont(undefined, 'bold').text("Tax Invoice / Receipt", 3, 0.3);

    //   this.pdf.setTextColor(0, 0, 0);

    //   var imgData = "https://cdn.logo.com/hotlink-ok/logo-social.png";
    //   this.pdf.addImage(imgData, "JPEG", 0.7, 0.7, 2.5, 1.5);
    //   this.pdf
    //     .setFontSize(18)
    //     .setFont(undefined, 'bold')
    //     .text(
    //       "RIVERVIEW PRODUCE PTY",
    //       4,
    //       1.2
    //     );
    //   this.pdf.setFont(undefined, 'normal')
    //   this.pdf
    //     .setFontSize(16)
    //     .text(
    //       "198 YARRAMUNDI LANE",
    //       4,
    //       1.5
    //     );
    //   this.pdf
    //     .setFontSize(16)
    //     .text(
    //       "RICHMOND, NSW 2753",
    //       4,
    //       1.8
    //     );
    //   this.pdf
    //     .setFontSize(16)
    //     .text(
    //       "Ph 02 45885318",
    //       4,
    //       2.1
    //     );
    //   this.pdf
    //     .setFontSize(16)
    //     .text(
    //       "www.riverviewproduct.com.au",
    //       4,
    //       2.4
    //     );
    //   this.pdf
    //     .setFontSize(16)
    //     .text(
    //       "riverviewproduct@hotmail.com",
    //       4,
    //       2.7
    //     );
    //   this.pdf
    //     .setFontSize(16)
    //     .text(
    //       "ABN 22619944375",
    //       4,
    //       3.0
    //     );

    //   //
    //   this.pdf
    //     .setFontSize(14)
    //     .text(
    //       "Invoice No. I0000009492",
    //       5.5,
    //       3.3
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "18/08/2022",
    //       5.5,
    //       3.6
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "1:32:07 PM",
    //       7,
    //       3.6
    //     );

    //   this.pdf.setFont(undefined, 'normal')
    //   this.pdf.setLineWidth(0.05);
    //   this.pdf
    //     .setFontSize(12)
    //     .setFont(undefined, 'bold').text(
    //       "Customer Details:",
    //       0.8,
    //       4.2
    //     );
    //   this.pdf.setFont(undefined, 'normal')
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "KEL CAMPBELL PTY LTD",
    //       0.8,
    //       4.5
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "KEL CAMPBELL PTY LTD",
    //       0.8,
    //       4.8
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "#1698",
    //       0.8,
    //       5.1
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "1141 CASTKEREAGH Road",
    //       0.8,
    //       5.3
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "CASTLEREAGH NSW 2749",
    //       0.8,
    //       5.5
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "P: 0247262700 M: 0410586584",
    //       0.8,
    //       5.8
    //     );
    //   this.pdf.rect(0.7, 3.9, 3.2, 2.3);

    //   //
    //   this.pdf.setFont(undefined, 'bold')
    //   this.pdf.setLineWidth(0.05);
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "Deliver To:",
    //       4.3,
    //       4.2
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .setFont(undefined, 'normal')
    //     .text(
    //       "Order No:",
    //       6.5,
    //       4.2
    //     );

    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "KEL CAMPBELL PTY LTD",
    //       4.3,
    //       4.5
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "KEL CAMPBELL PTY LTD",
    //       4.3,
    //       4.8
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "#1698",
    //       4.3,
    //       5.1
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "1141 CASTKEREAGH Road",
    //       4.3,
    //       5.3
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "CASTLEREAGH NSW 2749",
    //       4.3,
    //       5.5
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "P: 0247262700 M: 0410586584",
    //       4.3,
    //       5.8
    //     );
    //   this.pdf
    //     .setFontSize(12)
    //     .text(
    //       "Mobile 0247262700",
    //       4.3,
    //       6.1
    //     );
    //   this.pdf.rect(4.1, 3.9, 3.8, 2.3);


    //   // this.pdf.setFont(undefined, 'normal')
    //   //       this.pdf.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

    //   //       this.pdf.setFontSize(14).text("Deliver To :", 0.5, 2.5);

    //   //       this.pdf.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
    //   //       this.pdf.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
    //   //       this.pdf.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
    //   //       this.pdf.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
    //   //       this.pdf.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
    //   //       this.pdf.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
    //   //       this.pdf.setFontSize(12).text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

    //   //       //trading partner
    //   //       this.pdf.setFontSize(14).text("Trading Partner :", 5, 2.5);

    //   //       this.pdf.setFontSize(12).text("WAREHOUSE PTE LTD", 5.2, 2.7);
    //   //       this.pdf.setFontSize(12).text("63 Bank St,", 5.2, 2.9);
    //   //       this.pdf.setFontSize(12).text("P.O.Box 451,Ba,", 5.2, 3.1);
    //   //       this.pdf.setFontSize(12).text("Fiji Islands", 5.2, 3.3);
    //   //       this.pdf.setFontSize(12).text("Ph: 6674923", 5.2, 3.5);
    //   //       this.pdf.setFontSize(12).text("Fax: 6670128", 5.2, 3.7);
    //   //       this.pdf.setFontSize(12).text("Email: clint.com.fj", 5.2, 3.9);

    //   //       //lpn heading
    //   //       this.pdf.setLineWidth(0.01).line(0.5, 4.1, 8.0, 4.1);
    //   //       this.pdf.setFontSize(14).text("LPN Info", 0.5, 4.3);

    //   //       this.pdf.setFontSize(12).text("LPN", 0.5, 4.5);
    //   //       this.pdf.setFontSize(12).text("j_lpn", 1.6, 4.5);

    //   //       this.pdf.setFontSize(12).text("Order", 0.5, 4.7);
    //   //       this.pdf.setFontSize(12).text("j_orderId".toString(), 1.6, 4.7);

    //   //       this.pdf.setFontSize(12).text("Despatch", 0.5, 4.9);
    //   //       this.pdf.setFontSize(12).text("j_despatch", 1.6, 4.9);

    //   //       this.pdf.setFontSize(12).text("Despatched", 0.5, 5.1);
    //   //       this.pdf.setFontSize(12).text("j_isDespatched".toString(), 1.6, 5.1);

    //   //       this.pdf.setFontSize(12).text("Added By", 5, 4.5);
    //   //       this.pdf.setFontSize(12).text('j_addedBy', 6.1, 4.5);

    //   //       this.pdf.setFontSize(12).text("Verified By", 5, 4.7);
    //   //       this.pdf.setFontSize(12).text('j_verifiedBy', 6.1, 4.7);

    //   //       this.pdf.setFontSize(12).text("Verified At", 5, 4.9);
    //   //       this.pdf.setFontSize(12).text("j_verifiedAt", 6.1, 4.9);

    //   //       this.pdf.setFontSize(12).text("Date", 5, 5.1);
    //   //       this.pdf
    //   //         .setFontSize(12)
    //   //         .text("this.momentLocal(j_date).toString()", 6.1, 5.1);

    //   //       //Product heading
    //   //       this.pdf.setLineWidth(0.01).line(0.5, 5.5, 8.0, 5.5);
    //   //       this.pdf.setFontSize(14).text("PRODUCT", 0.5, 5.8);

    //   // autoTable(this.pdf, {
    //   //   // columns,
    //   //   // body: data[i],

    //   //   head: [["Item", "Product Name", "Description", "Quantity"]],
    //   //   body: [
    //   //     ["1", "Product-1", "ABHTFD", "100"],
    //   //     ["2", "Product-2", "GGRVD", "120"],
    //   //     ["3", "Product-2", "OOFRGS", "400"],
    //   //   ],

    //   //   margin: { left: 0.5, top: 6.0 },
    //   //   headStyles: { fillColor: [22, 78, 99] },
    //   // });

    //   // autoTable(this.pdf, {
    //   //   head: [
    //   //     [
    //   //       "LPN",
    //   //       "Order",
    //   //       "Despatch",
    //   //       "Added By",
    //   //       "Is Despatched",
    //   //       "Verified By",
    //   //       "Verified At",
    //   //       "Date",
    //   //     ],
    //   //   ],
    //   //   body: [
    //   //     [
    //   //       item.lpn,
    //   //       item.orderId,
    //   //       item.despatch,
    //   //       item.addedBy,
    //   //       item.isDespatched,
    //   //       item.verifiedBy,
    //   //       item.verifiedAt,
    //   //       this.momentLocal(item.date),
    //   //     ],
    //   //   ],
    //   //   margin: { left: 0.5, top: 4.4 },
    //   //   headStyles: { fillColor: [22, 78, 99] },
    //   // });
    // },


    //single-page download
    // generateReport() {
    //   this.report();
    //   var generateDate = new Date().toLocaleString();
    //   this.pdf.save(
    //     `${generateDate
    //     }.pdf`
    //   );
    // },

    //multi-page print

    

    },

   billDownload() {
      var doc = new jsPDF('p', 'pt', 'a4');
      doc.html(this.$refs.billContent, {
        callback: function (doc) {
          doc.save("bill.pdf");
        },
        margin: [5, 5, 5, 5],
        x: 20,
        y: 10,
        html2canvas: {
          scale: 0.5, 
          width: 1000 
        },
      });
    },

    billPrint() {
      // this.report();
      var doc = new jsPDF('p', 'pt', 'a4');
      doc.html(this.$refs.billContent, {
        callback: function (doc) {
          doc.autoPrint();
          doc.output("dataurlnewwindow");
        },
        margin: [5, 5, 5, 5],
        x: 20,
        y: 10,
        html2canvas: {
          scale: 0.5, 
          width: 1000 
        },
      });
    },

  },
};
</script>
