<template>
  <a
    v-if="activeAccess"
    @click="buttonClick()"
    class="flex items-center text-red-900 mr-3"
    href="javascript:;"
  >
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      :content="name"
      :options="{
        zIndex: 99999,
      }"
      ><HandIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
    </Tippy>
  </a>
  <a
    v-else
    class="flex items-center text-red-900 mr-3 cursor-not-allowed"
    href="javascript:;"
  >
    <HandIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
  </a>
</template>
<script>
export default {
  props: { item: {}, name: "", activeAccess: false },
  name: "Hold",
  methods: {
    buttonClick() {
      this.$emit("parentMethod", this.item);
    },
  },
};
</script>
