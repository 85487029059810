<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Create Lot Numbers" />

          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
          />
          <PrintLabels @parentMethod="printLabels($event)" />
          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="whitespace-nowrap uppercase">
              <th class="whitespace-nowrap uppercase" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Created Date</th>
              <!-- <th>Exp Date</th>
              <th>Qty</th> -->
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
                <!-- <ArchiveItem
                  class="ml-3 -mt-1"
                  :activeAccess="$h.archiveItemAccess(policyAccess)"
                  v-if="checkedData.length > 0"
                  @parentMethod="archiveMultipleItem($event)"
                  name="Archive Selected"
                /> -->
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] != null || item[header.value] != undefined ? item[header.value] : "" }}
              </td>
              <td>
                {{ item.createdAt === null || item.createdAt === undefined ? "" : momentLocal(item.createdAt) }}
              </td>

              <!-- <td>
                {{
                  item.expirationDate != null ||
                  item.expirationDate != undefined
                    ? momentLocal(item.expirationDate)
                    : ""
                }}
              </td>
              <td>
                {{
                  item.quantity != null || item.quantity != undefined
                    ? item.quantity
                    : ""
                }}
              </td> -->
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>
  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addMovement" @hidden="addMovement = false" backdrop="static" size="modal-sl-80" :title="formTitle" @close="closeForm()">
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <div class="flex items-center space-x-2 bg-slate-200 text-black px-2 py-2">
            <div>Last Created LOT :</div>

            <div v-if="itemsMovement != 'LOT0'" class="text-success">
              {{ itemsMovement }}
            </div>

            <div v-if="itemsMovement == 'LOT0'" class="text-success">
              {{ itemsMovement }}
            </div>
          </div>
          <label for="regular-form-1" class="form-label pt-4">Number of LOT</label>

          <div class="flex space-x-2 justify-center items-center pt-2">
            <input id="vertical-form-1" v-model="count" type="number" class="form-control" placeholder="LOT Number" autocomplete="off" />

            <svg
              v-if="count != '' && !countSelected"
              @click="countField(formDataMovement.lots, count)"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi bi-plus-circle w-8 h-8 text-success"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>

            <svg
              v-show="formDataMovement.lots.length >= 1 && count > 0"
              @click="removeCountField(index, formDataMovement.lots, count)"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi bi-x-circle w-8 h-8 text-red-400"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>

        <div class="intro-y col-span-12 overflow-auto" v-if="count > 0 && countSelected == true">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>QR Code</th>
                <th>LOT</th>
                <th class="flex justify-start">
                  <SelectLabels :allSelected="allSelectedLot" @selectAll="selectAllLot($event)" @unSelectAll="unSelectAllLot($event)" />
                </th>
              </tr>
            </thead>
            <tr class="intro-x" v-for="(input, index) in formDataMovement.lots" :key="`phoneInput-${index}`">
              <td>
                <qrcode-vue render-as="canvas" :value="input.fieldNo" size="45" level="H" :id="`fieldNoQR-${input.fieldNo}`" />
              </td>

              <td>
                <input id="vertical-form-1" v-model="input.fieldNo" type="text" class="form-control" placeholder="LOT Number" autocomplete="off" />
              </td>
              <td class="table-report__action w-auto">
                <div class="flex justify-center items-center">
                  <CheckItem :item="input" :checkedData="checkedDataLot" @parentMethod="checkItemLot($event)" />
                  <!-- <a class="flex items-center mr-3" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Select LPN"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <input
                        id="vertical-form-3"
                        class="form-check-input w-6 h-6 mr-1 bg-slate-100"
                        type="checkbox"
                        :value="input.fieldNo"
                        v-model="checkedDataField"
                      />
                    </Tippy>
                  </a> -->
                  <!-- <a
                    @click="print(input)"
                    class="flex items-center mr-3"
                    href="javascript:;"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Print"
                      :options="{
                        zIndex: 99999,
                      }"
                      ><PrinterIcon
                        class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                      />
                    </Tippy>
                  </a>
                  <a
                    class="flex items-center text-blue-900"
                    href="javascript:;"
                    @click="generate(input)"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Download"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <FileTextIcon
                        class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                      />
                    </Tippy>
                  </a> -->
                </div>
              </td>
            </tr>
          </table>

          <div class="flex items-center justify-center">
            <PrintLabels @parentMethod="printLabelsLot($event)" />

            <a href="javascript:;" @click="onPrint()" class="mr-2 btn btn-outline-secondary shadow-md text-xs hover:text-black sm:w-auto mr-2 mt-1 mb-1">
              Print
              <span class="ml-2">
                <PrinterIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>

            <a href="javascript:;" @click="generateReport()" class="mr-2 btn btn-outline-secondary shadow-md text-xs hover:text-black sm:w-auto mr-2 mt-1 mb-1">
              Download PDF
              <span class="ml-2">
                <FileTextIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button :disabled="!countSelected" @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>

  <!-- BEGIN: print  Modal -->

  <MoveableModal
    :show="addCheckedPrintModal"
    @hidden="addCheckedPrintModal = false"
    backdrop="static"
    title="Do you want to print the selected label(s) ?"
    @close="addCheckedPrintModal = false"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div class="relative col-span-3 pt-2">
          <div class="absolute -top-2.5 left-4 form-switch flex justify-center items-center space-x-2">
            <div class="flex space-x-2">
              <input type="radio" id="localip" :value="true" v-model="choosePrint" />

              <label for="localip">Internal Print</label>
            </div>
            <div class="flex space-x-2">
              <input type="radio" id="publicip" :value="false" v-model="choosePrint" />
              <label for="publicip">Print from web</label>
            </div>
          </div>
          <div class="border rounded-md border-slate-200 border-solid p-4 h-full w-full">
            <div class="flex flex-col space-y-2 w-full">
              <div>
                <h2>{{ this.changePrintType }} : {{ this.defaultIp }}</h2>
              </div>
              <div>
                <label for="regular-form-1" class="form-label">Label Printer IP</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="formDataPrint.ip" type="text" autocomplete="off" class="form-control" placeholder="IP" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Port</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.port" autocomplete="off" type="text" class="form-control" placeholder="Port.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Number of Copies</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.nbrOfCopies" type="number" autocomplete="off" class="form-control" placeholder="Number of copies.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Selected Label(s)</label>

          <div id="labelsScroll" class="flex flex-col space-y-1 overflow-scroll h-36">
            <table>
              <tr class="text-center">
                <th>No</th>
                <th>LOT</th>
                <th>Bin</th>
                <th>IN#</th>
                <th>ON#</th>
                <th>SKU</th>
                <th>SPN</th>
                <th>QTY</th>
              </tr>
              <tr class="text-center" v-for="(item, i) in formDataPrint.lots" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.lot }}</td>
                <td>{{ item.bin }}</td>

                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.orderNumber }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.supplierProductNumber }}</td>
                <td>{{ item.quantity }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <!-- <button
        @click="confirmClientPrint()"
        type="submit"
        class="btn whitespace-nowrap btn-primary w-auto px-1 mr-1"
      >
        Internal Print
      </button>
      <button
        @click="confirmPrint()"
        type="submit"
        class="btn whitespace-nowrap btn-primary w-auto px-1 mr-1"
      >
        Print from web
      </button> -->
      <button @click="confirmPrint()" type="submit" class="btn whitespace-nowrap btn-primary px-1 w-auto mr-1">Print Labels</button>

      <button type="button" @click="addCheckedPrintModal = false" class="btn btn-outline-secondary w-auto mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: print Modal -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import QRCode from "qrcode";
import router from "../../../router";
import QrcodeVue from "qrcode.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";
import moment from "moment";
import store from "../../../store";
import axiosReal from "axios";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "NEW LOTS");
  },
  components: {
    QrcodeVue,
  },

  data() {
    return {
      isLotPrint: false,
      choosePrint: true,
      ipType: "Local IP",
      defaultIp: "",
      allSelectedLot: false,
      checkedDataLot: [],
      isLoading: false,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      searchData: "",
      searchActive: false,

      formDataLOTS: {
        warehouse: "",
        lots: [],
      },
      formDataLOTSDefault: {
        warehouse: "",
        lots: [],
      },

      headers: [
        { text: "LOT", value: "lot" },
        // { text: "W House", value: "warehouse" },
        // { text: "Section", value: "storageSection" },
        // { text: "Type", value: "storageType" },
        // { text: "Bin", value: "bin" },
        // { text: "Inv No", value: "invoiceNumber" },
        // { text: "Purch Ord No", value: "orderNumber" },
        // { text: "PLU/SKU", value: "sku" },
        // { text: "Supp Prod No", value: "supplierProductNumber" },
        // { text: "Barcode", value: "barcode" },
        // { text: "QTY", value: "quantity" },
      ],

      headersFilter: [
        { text: "LOT", value: "lot" },
        { text: "W House", value: "warehouse" },
        { text: "Stor Section", value: "storageSection" },
        { text: "Stor Type", value: "storageType" },
        { text: "Bin", value: "bin" },
        { text: "Inv No", value: "invoiceNumber" },
        { text: "Purch Ord No", value: "orderNumber" },
        { text: "PLU/SKU", value: "sku" },
        { text: "Supp Prod No", value: "supplierProductNumber" },
        { text: "Barcode", value: "barcode" },
        // { text: "QTY", value: "quantity" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        // sortColumnName: "movementId",
        // sortDirection: "desc",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 20,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      doc: null,
      pdf: null,
      splitData: false,
      allSelected: false,
      checkedData: [],

      policyAccess: "LOTS",

      formDataMovement: {
        warehouse: "",
        lots: [],
      },
      formDataMovementDefault: {
        warehouse: "",
        lots: [],
      },

      titleChange: -1,
      addMovement: false,
      count: "",
      itemsMovement: "",
      countSelected: false,
      const_txt: const_txt,
      checkedDataField: [],

      addCheckedPrintModal: false,

      formDataPrint: {
        lots: [],
        ip: "192.0.0.0",
        port: "8100",
        nbrOfCopies: 1,
      },
      itemsTemp: [],
    };
  },
  watch: {
    choosePrint() {
      this.defaultIp = "";
      return this.setIp();
    },
  },
  computed: {
    changePrintType() {
      return this.choosePrint ? "Local IP" : "Public IP";
    },

    formTitle() {
      return this.titleChange === -1 ? "Add New Receival" : "Edit Receival";
    },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    checkItemLot(item) {
      var include = this.checkedDataLot.includes(item);
      if (include) {
        const index = this.checkedDataLot.indexOf(item);
        if (index > -1) {
          this.checkedDataLot.splice(index, 1);
        }
      } else {
        this.checkedDataLot.push(item);
      }
      this.isLotPrint = true;
    },
    unSelectAllLot() {
      this.allSelectedLot = false;
      this.isLotPrint = true;
      this.checkedDataLot = [];
    },
    selectAllLot() {
      this.allSelectedLot = true;
      this.isLotPrint = true;
      this.checkedDataLot = [];
      for (var i = 0; i < this.itemsTemp.length; i++) {
        this.checkedDataLot[i] = this.itemsTemp[i];
      }
    },
    confirmPrint() {
      if (this.choosePrint) {
        this.confirmClientPrint();
      } else {
        if (this.formDataPrint.ip == "" || this.formDataPrint.port == "" || this.formDataPrint.nbrOfCopies == "") {
          if (this.formDataPrint.ip == "") {
            warningToast("IP Required");
          }
          if (this.formDataPrint.port == "") {
            warningToast("Port Required");
          }
          if (this.formDataPrint.nbrOfCopies == "") {
            warningToast("Num of Copies Required");
          }
        } else {
          this.isLoading = true;
          axios
            .post("Movements/PrintLots", this.formDataPrint)
            .then((response) => {
              if (response.data.success === true) {
                successToast(response.data.message);

                this.isLoading = false;
              } else {
                failedToast(response.data.message);
                this.isLoading = false;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
          this.addCheckedPrintModal = false;
          this.checkedData = [];
        }
      }
    },

    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
      this.isLotPrint = false;
    },

    confirmClientPrint() {
      this.$printerHub.connect({
        host: this.formDataPrint.ip,
        port: this.formDataPrint.port,
        timeout: 3000,
      });

      let lastValues = this.formDataPrint.ip.split(".")[3];
      if (this.formDataPrint !== null) {
        store.dispatch("authUser/setlocalIp", lastValues);
      }

      if (this.isLotPrint === false) {
        let selectedLables = this.checkedData;
        let maxLables = 3;
        let totalLables = selectedLables.length;
        let totalBatches = Math.ceil(totalLables / maxLables);
        let positions = [
          {
            l1: "FT53",
            l2: "FT82",
          },
          {
            l1: "FT330",
            l2: "FT360",
          },
          {
            l1: "FT602",
            l2: "FT632",
          },
        ];
        for (let batch = 1; batch <= totalBatches; batch++) {
          let start = (batch - 1) * maxLables;
          let end = batch * maxLables;
          let batchLabels = selectedLables.slice(start, end);

          let query = `^XA^LS0,^LT8`;
          var data;
          setTimeout(() => {
            for (let i = 0; i < this.formDataPrint.nbrOfCopies; i++) {
              for (let position = 0; position < batchLabels.length; position++) {
                data = query += `^${positions[position].l1},192^BQN,2,8
^FH\^FDLA,${batchLabels[position].lot}^FS
^${positions[position].l2},198^CFA,30^FD${batchLabels[position].lot}^FS
^PQ${this.formDataPrint.nbrOfCopies},0,1,Y`;

                this.$printerHub.write(data + "^XZ");
              }
            }
            console.log("LOT : ", data + "^XZ");
          }, 2000);

          this.addCheckedPrintModal = false;
          this.checkedData = [];
        }
      } else {
        let selectedLables = this.checkedDataLot;
        let maxLables = 3;
        let totalLables = selectedLables.length;
        let totalBatches = Math.ceil(totalLables / maxLables);
        let positions = [
          {
            l1: "FT53",
            l2: "FT82",
          },
          {
            l1: "FT330",
            l2: "FT360",
          },
          {
            l1: "FT602",
            l2: "FT632",
          },
        ];
        for (let batch = 1; batch <= totalBatches; batch++) {
          let start = (batch - 1) * maxLables;
          let end = batch * maxLables;
          let batchLabels = selectedLables.slice(start, end);
          let query = `^XA^LS0,^LT8`;
          var data;
          setTimeout(() => {
            for (let i = 0; i < this.formDataPrint.nbrOfCopies; i++) {
              for (let position = 0; position < batchLabels.length; position++) {
                data = query += `^${positions[position].l1},192^BQN,2,8
^FH\^FDLA,${batchLabels[position].fieldNo}^FS
^${positions[position].l2},198^CFA,30^FD${batchLabels[position].fieldNo}^FS
^PQ${this.formDataPrint.nbrOfCopies},0,1,Y`;

                this.$printerHub.write(data + "^XZ");
              }
            }
            console.log("LOT : ", data + "^XZ");
          }, 2000);

          this.addCheckedPrintModal = false;
          this.checkedDataLot = [];
        }
      }
    },

    setIp() {
      if (this.choosePrint) {
        let config = this.$printerHub.getConfig();
        if (config.host) {
          var lastValue = localStorage.getItem("localIp") !== null ? JSON.parse(localStorage.getItem("localIp")) : "";
          var host = config.host;
          var ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          if (ipRegex.test(host)) {
            var split = host.split(/\.(?=[^\.]+$)/);
            this.formDataPrint.ip = split[0] + "." + lastValue;
          } else {
            this.formDataPrint.ip = host + lastValue;
          }
          this.formDataPrint.port = config.port;
          this.defaultIp = config.host;
          successToast("Internal print configuration selected");
        } else {
          failedToast("Local IP is not conneced, please check your configuration");
        }
      } else {
        this.getDefaultIP();
        this.formDataPrint.port = 8100;
        successToast("Print for web configuration selected");
      }
    },

    printLabelsLot() {
      this.setIp();
      this.formDataPrint.lots = [];
      if (this.checkedDataLot.length > 0) {
        this.addCheckedPrintModal = true;
        // for (var i = 0; i < this.checkedDataLot.length; i++) {
        //   this.formDataPrint.lots[i] = this.checkedDataLot[i];
        // }
        this.formDataPrint.lots = this.checkedDataLot.map((x) => {
          return { lot: x.fieldNo };
        });
      } else {
        warningToast("Please Select Lots");
      }
    },

    printLabels() {
      this.setIp();
      this.formDataPrint.lots = [];
      if (this.checkedData.length > 0) {
        this.addCheckedPrintModal = true;
        for (var i = 0; i < this.checkedData.length; i++) {
          this.formDataPrint.lots[i] = this.checkedData[i];
        }
        console.log("formDataPrint", this.formDataPrint);
      } else {
        warningToast("Please Select Lots");
      }
    },

    getDefaultIP() {
      this.isLoading = true;
      axiosReal
        .get("https://api.ipify.org/")
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.defaultIp = response.data;
            this.formDataPrint.ip = response.data;
          } else {
            this.isLoading = false;
            failedToast("something went wrong in getting public IP");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    setDefaultMovement() {
      this.formDataMovement = Object.assign({}, this.formDataMovementDefault);
      this.removeCountField("", this.formDataMovement.lots, this.count);
    },
    showModal() {
      this.setDefaultMovement();
      this.titleChange = -1;
      // this.getWarehouseData();
      this.getDataFromMovement();
      this.addMovement = true;
    },

    getDataFromMovement() {
      this.isLoading = true;
      axios
        .post("Movements/Lots/LastCreated/" + this.warehouseAbbreviation)
        .then((response) => {
          if (response.data.success === true) {
            var lastValue = response.data.data;
            if (lastValue == "" || lastValue == null) {
              this.itemsMovement = "LOT0";
            } else {
              this.itemsMovement = lastValue;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    countField(fieldType, count) {
      var split_string = this.itemsMovement.split(/(\d+)/);

      var str_c = split_string.slice(0, split_string.length - 2).join("");
      var str = parseInt(split_string[split_string.length - 2]);

      for (let val = 1; val <= count; val++) {
        fieldType.push({ fieldNo: str_c + parseInt(val + str) });
        fieldType.splice(count, count);
      }
      this.itemsTemp = fieldType;
      this.countSelected = true;
    },
    removeCountField(index, fieldType, count) {
      this.countSelected = false;
      this.count = "";
      fieldType.splice(index, count);
      this.itemsTemp = [];
      this.unSelectAllLot();
    },

    setSelectReport(qrGenerate) {
      var newArray = [];
      for (var x = 0; x < this.checkedDataField.length; x++) {
        for (var y = 0; y < qrGenerate.length; y++) {
          var result = qrGenerate.filter((obj) => {
            return obj.fieldNo === this.checkedDataField[x];
          });

          newArray.push(result[0]);
        }
      }

      newArray = newArray.filter((value, index, self) => index === self.findIndex((t) => t.fieldNo == value.fieldNo));
      this.selectedItemsData = newArray;
    },

    // setReport(qrGenerate) {
    //   if (this.checkedDataField.length > 0) {
    //     this.setSelectReport(qrGenerate);

    //     var qrGenerate = this.selectedItemsData;
    //   } else {
    //     var qrGenerate = qrGenerate;
    //   }

    //   this.doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "in",
    //     format: "a4",
    //   });

    //   let data = [];
    //   let i = 0;
    //   let obj;

    //   qrGenerate.forEach((el) => {
    //     obj = {
    //       fieldNo: el.fieldNo,
    //     };

    //     data.push(obj);
    //   });

    //   for (var j = 0; j < data.length; j++) {
    //     var imgData = "../../../assets/images/pl_logo2.png";
    //     this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
    //     // this.doc
    //     //   .setFontSize(16)
    //     //   .text("Order Number : ", 5, 1.2);

    //     this.doc.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

    //     this.doc.setFontSize(14).text("Deliver To :", 0.5, 2.5);

    //     this.doc.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
    //     this.doc.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
    //     this.doc.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
    //     this.doc.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
    //     this.doc.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
    //     this.doc.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
    //     this.doc
    //       .setFontSize(12)
    //       .text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

    //     //trading partner
    //     this.doc.setFontSize(14).text("Trading Partner :", 5, 2.5);

    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text(

    //     //       " fname" ,
    //     //     5.2,
    //     //     2.7
    //     //   );
    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text("this.formDataOrder.customer.company", 5.2, 2.9);
    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text("this.formDataOrder.customer.mobile", 5.2, 3.1);
    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text("this.formDataOrder.customer.email", 5.2, 3.3);
    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text("Date: " + "this.momentLocal(this.formDataOrder.time)", 5.2, 3.5);
    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text(
    //     //     "Total : " + 'this.currencyFormat(this.formDataOrder.amount)',
    //     //     5.2,
    //     //     3.7
    //     //   );
    //     // this.doc
    //     //   .setFontSize(12)
    //     //   .text(
    //     //     "Booking No: ",
    //     //     5.2,
    //     //     3.9
    //     //   );
    //     var qrCanvas = document.getElementById(`fieldNoQR-${data[j].fieldNo}`);
    //     this.doc.setFontSize(12).text("QR Code", 0.7, 4.5);
    //     if (qrCanvas) {
    //       this.doc.addImage(qrCanvas.toDataURL(), "JPEG", 0.7, 4.7, 0.75, 0.75);
    //     }

    //     this.doc.setFontSize(12).text("Number: " + data[j].fieldNo, 4.0, 4.5);
    //     if (j < data.length - 1) {
    //       this.doc.addPage();
    //     }

    //     const pageCount = this.doc.internal.getNumberOfPages();
    //     for (var z = 1; z <= pageCount; z++) {
    //       this.doc.setPage(z);
    //       // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

    //       const pageSize = this.doc.internal.pageSize;
    //       const pageWidth = pageSize.width
    //         ? pageSize.width
    //         : pageSize.getWidth();
    //       const pageHeight = pageSize.height
    //         ? pageSize.height
    //         : pageSize.getHeight();
    //       // const header = 'Report 2014';
    //       const footer = `Page ${z} of ${pageCount}`;
    //       this.doc.setFontSize(10);
    //       this.doc.text(
    //         footer,
    //         pageWidth / 2 - this.doc.getTextWidth(footer) / 2,
    //         pageHeight - 0.2,
    //         { baseline: "bottom" }
    //       );
    //     }
    //   }
    // },

    setReport() {
      return new Promise(async (resolve, reject) => {
        if (this.checkedDataLot.length > 0) {
          var items = this.checkedDataLot;
        } else {
          var items = this.itemsTemp;
        }

        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "QR", dataKey: "qr" },
              { title: "LOT#", dataKey: "fieldNo" },
            ];
            this.doc = new jsPDF({
              orientation: "portrait",
              unit: "in",
              format: "a4",
            });

            let data = await this.getItemsWithQRLot(items);

            autoTable(this.doc, {
              theme: "grid",
              columns,
              body: data,
              headStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              columnStyles: {
                1: {
                  minCellHeight: 1,
                  cellWidth: 1,
                },
              },
              margin: { left: 0.5, top: 0.5 },
              didDrawCell: this.didDrawCell,
            });

            const pageCount = this.doc.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
              this.doc.setPage(i);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.doc.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${i} of ${pageCount}`;
              this.doc.setFontSize(10);
              this.doc.text(footer, pageWidth / 2 - this.doc.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    getItemsWithQRLot(list) {
      return new Promise((resolve, reject) => {
        try {
          var data = [];
          let i = 0;
          list.forEach(async (el) => {
            i += 1;
            var obj = {
              fieldNo: el.fieldNo,
            };
            obj.qrData = await QRCode.toDataURL(JSON.stringify(el.fieldNo));
            data.push(obj);
          });
          resolve(data);
        } catch (error) {
          reject([]);
        }
      });
    },

    didDrawCell(data) {
      if (data.column.dataKey === "qr" && data.cell.section === "body") {
        var dim = data.cell.height - data.cell.padding("vertical");
        var textPos = data.cell;

        if (data.row && data.row.raw && data.row.raw.qrData) {
          this.doc.addImage(data.row.raw.qrData, textPos.x + 0.05, textPos.y + 0.04, dim, dim);
        }
      }
    },

    onPrint() {
      this.setReport().then((res) => {
        this.doc.autoPrint();
        this.doc.output("dataurlnewwindow");
      });
    },

    generateReport() {
      this.setReport().then((res) => {
        var generateDate = new Date().toLocaleString();
        this.doc.save(`${"QRCode" + "_" + generateDate}.pdf`);
      });
    },

    report(input) {
      const qrCanvas = document.getElementById(`fieldNoQR-${input.fieldNo}`);
      this.pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "a4",
      });

      var imgData = "/src/assets/images/pl_logo2.png";
      this.pdf.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
      // this.pdf
      //   .setFontSize(16)
      //   .text("Order Number : ", 5, 1.2);

      this.pdf.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

      this.pdf.setFontSize(14).text("Deliver To :", 0.5, 2.5);

      this.pdf.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
      this.pdf.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
      this.pdf.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
      this.pdf.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
      this.pdf.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
      this.pdf.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
      this.pdf.setFontSize(12).text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

      //trading partner
      this.pdf.setFontSize(14).text("Trading Partner :", 5, 2.5);

      // this.doc
      //   .setFontSize(12)
      //   .text(

      //       " fname" ,
      //     5.2,
      //     2.7
      //   );
      // this.doc
      //   .setFontSize(12)
      //   .text("this.formDataOrder.customer.company", 5.2, 2.9);
      // this.doc
      //   .setFontSize(12)
      //   .text("this.formDataOrder.customer.mobile", 5.2, 3.1);
      // this.doc
      //   .setFontSize(12)
      //   .text("this.formDataOrder.customer.email", 5.2, 3.3);
      // this.doc
      //   .setFontSize(12)
      //   .text("Date: " + "this.momentLocal(this.formDataOrder.time)", 5.2, 3.5);
      // this.doc
      //   .setFontSize(12)
      //   .text(
      //     "Total : " + 'this.currencyFormat(this.formDataOrder.amount)',
      //     5.2,
      //     3.7
      //   );
      // this.doc
      //   .setFontSize(12)
      //   .text(
      //     "Booking No: ",
      //     5.2,
      //     3.9
      //   );

      this.pdf.setFontSize(12).text("QR Code: ", 0.7, 4.5);
      if (qrCanvas) {
        this.pdf.addImage(qrCanvas.toDataURL(), "JPEG", 0.7, 4.7, 0.75, 0.75);
      }

      this.pdf.setFontSize(12).text("Number: " + input.fieldNo, 4.0, 4.5);

      const pageCount = this.pdf.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        this.pdf.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = this.pdf.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;
        this.pdf.setFontSize(10);
        this.pdf.text(footer, pageWidth / 2 - this.pdf.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
      }
    },

    print(input) {
      this.report(input);
      this.pdf.autoPrint();
      this.pdf.output("dataurlnewwindow");
    },

    generate(input) {
      this.report(input);
      var generateDate = new Date().toLocaleString();
      this.pdf.save(`${"Number" + input.fieldNo + "_" + generateDate}.pdf`);
    },

    saveForm() {
      if (this.titleChange === -1) {
        var lotsArray = [];
        for (var i = 0; i < this.formDataMovement.lots.length; i++) {
          var lotNo = this.formDataMovement.lots[i].fieldNo;
          lotsArray.push(lotNo);
        }

        var formDataMovement = {};
        formDataMovement.warehouse = this.warehouseAbbreviation;
        formDataMovement.lots = lotsArray;

        this.isLoading = true;
        axios
          .post("Movements/Lots/Create", formDataMovement)
          .then((response) => {
            if (response.data.success === true) {
              this.addMovement = false;
              this.getDataFromApi();
              successToast(response.data.message);
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
      // else {
      // var itemId = this.formDataMovement.warehouseId;
      // this.isLoading = true;
      // axios
      //   .put("Warehouses/" + itemId, this.formDataMovement)
      //   .then((response) => {
      //     if (response.data.success === true) {
      //       this.addMovement = false;
      //       this.getDataFromApi();
      //       this.apiMessage = response.data.message;
      //       successToast(this.apiMessage);
      //     } else {
      //       this.isLoading = false;
      //       failedToast(response.data.message);
      //     }
      //   })
      //   .catch((error) => {
      //     this.isLoading = false;
      //     warningToast(statusMessage(error.response.status));
      //   });
      // }
    },

    //qr print end
    closeForm() {
      this.addMovement = false;
      this.setDefaultMovement();
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "LOT", dataKey: "lot" },
              { title: "Created Date", dataKey: "createdAt" },
              // { title: "Warehouse", dataKey: "warehouse" },
              // { title: "Storage Type", dataKey: "storageType" },
              // { title: "Storage Section", dataKey: "storageSection" },
              // { title: "Bin", dataKey: "bin" },
              // { title: "Invoice No", dataKey: "invoiceNumber" },
              // { title: "Purchase Order No", dataKey: "orderNumber" },
              // { title: "Quantity", dataKey: "quantity" },
              // { title: "PLU/SKU", dataKey: "sku" },
              // { title: "Expiration Date", dataKey: "expirationDate" },
              // { title: "Barcode", dataKey: "barcode" },
              // {
              //   title: "Supplier Product No",
              //   dataKey: "supplierProductNumber",
              // },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                // warehouse: el.warehouse,
                lot: el.lot,
                createdAt: this.momentLocal(el.createdAt),
                // storageType: el.storageType,
                // storageSection: el.storageSection,
                // bin: el.bin,
                // invoiceNumber: el.invoiceNumber,
                // orderNumber: el.orderNumber,
                // quantity: el.quantity,
                // sku: el.sku,
                // expirationDate: this.momentLocal(el.expirationDate),
                // barcode: el.barcode,
                // supplierProductNumber: el.supplierProductNumber,
              };
              //i++;
              data.push(obj);
              //   console.log(obj);
            });
            //console.log("DATA", data);

            // var imgData = "/src/assets/images/pl_logo2.png";
            // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
            // this.docs
            //   .setFontSize(16)
            //   .text("Storage Bin", 5, 1.2);

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
      this.isLotPrint = false;
    },
    selectAll() {
      this.allSelected = true;
      this.isLotPrint = false;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Lots " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Lots " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Warehouse",
          2: "LOT",
          3: "Created Date",
          // 3: "Storage Type",
          // 4: "Storage Section",
          // 5: "Bin",
          // 6: "Invoice No",
          // 7: "Quantity",
          // 8: "PLU/SKU",
          // 9: "Barcode",
          // 10: "Supplier Product No",
          // 11: "Purchase Order No",
          // 12: "Expiration Date",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.warehouse,
            2: item.lot,
            3: this.momentLocalCSV(item.createdAt),
            // 3: item.storageType,
            // 4: item.storageSection,
            // 5: item.bin,
            // 6: item.invoiceNumber,
            // 7: item.quantity,
            // 8: item.sku,
            // 9: item.barcode,
            // 10: item.supplierProductNumber,
            // 11: item.orderNumber,
            // 12: this.momentLocalCSV(item.expirationDate),
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Movements/New/Pagination/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },

    setDefault() {
      this.formDataLOTS = Object.assign({}, this.formDataLOTSDefault);
    },

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Movements/New/Pagination/" + this.warehouseAbbreviation, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            var originalItems = response.data.data.items;
            this.items = originalItems.filter((x) => x.invoiceNumber == null);
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
