<template>
    <MyAutocomplete
      v-model="value"
      :placeholder="placeholder"
      :items="_items"
      :itemText="itemText"
      :itemValue="itemValue"
      :disabled="disabled"
      :loading="loading"
      @loadMore="loadMore"
    />
  </template>
  <script>
  import axios from "../../services/http-common";

  export default {
    name: "AutocompleteWrapper",
    props: {
      modelValue: {},
      placeholder: {
        type: String,
        default: "",
      },
      table: {
        type: String,
        default: "",
      },
      items: {
        type: Array,
        default: [],
      },
      itemText: {
        type: String,
        default: "",
      },
      itemValue: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      loading: false,
      page: 1,
      itemsPerPage: 10,
      paginatedItems: [],
      totalItems: 0,
      totalPages: 0,
      loadTimeout: null,
      searchTimeout: null,
    }),
    computed: {
      _items() {
        return this.table ? this.paginatedItems : this.items;
      },
      projection() {
        return [this.itemText, this.itemValue];
      },
      obj() {
        return {
          table: this.table,
          itemText: this.itemText,
          itemValue: this.itemValue,
        };
      },
      value: {
        get() {
          return this.modelValue;
        },
        set(val) {
          this.$emit("update:modelValue", val);
        },
      },
    },
    watch: {
      search() {
        console.log("worked")
        if (this.table) {
          this.loading = true;
          this.page = 1;
          this.paginatedItems = [];
          clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this.getDataFromApi();
          }, 1500);
        }
      },
  
      obj() {
        if (this.table && this.itemText && this.itemValue) {
          this.page = 1;
          this.paginatedItems = [];
          this.getDataFromApi();
        }
      },
    },
  
    methods: {
      loadMore() {
        if (this.page < this.totalPages) {
          this.loading = true;
          clearTimeout(this.loadTimeout);
          this.loadTimeout = setTimeout(() => {
            this.page++;
            this.getDataFromApi();
          }, 1500);
        }
      },
      getDataFromApi() {
        console.log("getDataFromApi Worked")
        this.loading = true;
        this.axios
          .post(`${this.table}/Pagination`, {
            page: this.page,
            pageSize: this.itemsPerPage,
            search: { [this.itemText]: this.search },
            projection: this.projection,
          })
          .then((res) => {
            this.paginatedItems = [...this.paginatedItems, ...res.data.items];
            this.totalItems = res.data.totalCount;
            this.totalPages = res.data.totalPages;
            this.loading = false;
          });
      },
    },
  };
  </script>