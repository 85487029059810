<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="flex justify-between items-center space-x-2 border-2 border-primary p-3 rounded-md">
    <div class="flex justify-start items-start space-x-2">
      <component :is="PermissionAdminIdentifier(item.value)" class="w-6 h-6"> </component>
      <div class="flex flex-col items-start justify-center">
        <span class="font-semibold">{{ getSpace(getName(item.value)) }}</span>
      </div>
    </div>

    <div class="form-switch ml-1" @click="openPermission(!item.selected)">
      <input id="booleanSwitch" type="checkbox" class="form-check-input" v-model="item.selected" />
    </div>
    <!-- <div
      v-if="$h.editItemAccess(policyAccess)"
      class="form-switch ml-1"
      @click="openPermission(!item.selected)"
    >
      <input
        id="booleanSwitch"
        type="checkbox"
        class="form-check-input"
        v-model="item.selected"
      />
    </div>

    <div v-else class="form-switch ml-3">
      <input
        disabled
        id="booleanSwitch"
        type="checkbox"
        class="form-check-input"
        v-model="item.selected"
      />
    </div> -->
  </div>

  <!-- <PermissionConfirmModal
    :name="getName(item.value)"
    :modalStatus="permissionConfirmationModal"
    :modalConfirm="permissionItemConfirm"
    :modalClose="closePermission"
  /> -->

  <ConfirmAlert
    :modalStatus="permissionConfirmationModal"
    :modalConfirm="permissionItemConfirm"
    :modalClose="closePermission"
    :isClose="true"
    :icon="item.selected ? 'ShieldCheckIcon' : 'ShieldCloseIcon'"
    :color="item.selected ? 'success' : 'danger'"
    :name="item.selected ? `Activate ${getName(item.value)}` : `Deactivate ${getName(item.value)}`"
    :question="item.selected ? 'Do you really want to active this permission?' : 'Do you really want to deactive this permission?'"
    nameBtn="Ok"
  />
</template>
<script>
import { PermissionAdminIdentifier } from "../../global-functions/PermissionIdentifier.js";
import { successToast, failedToast, warningToast, statusMessage } from "@/global-functions/toast";
import axios from "@/services/http-common";

export default {
  name: "PermissionCard",
  data() {
    return {
      permissionConfirmationModal: false,
      active: false,
      isLoading: false,
      policyAccess: "UserPermissions",
    };
  },
  props: {
    vmodel: "",
    value: "",
    item: {},
    roleId: "",
    roleName: "",
    policyName: "",
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    getName(value) {
      const index = value.lastIndexOf(".");
      const name = value.slice(index + 1);
      return name;
    },
    getSpace(value) {
      const name = value.replace(/([A-Z])/g, " $1").trim();
      return name;
    },
    PermissionAdminIdentifier(value) {
      return PermissionAdminIdentifier(value);
    },
    closePermission() {
      this.permissionConfirmationModal = false;
      this.item.selected = !this.active;
    },
    openPermission(bool) {
      // this.item.selected = !bool;
      this.permissionConfirmationModal = true;
      this.active = bool;
    },

    async permissionItemConfirm() {
      this.isLoading = true;
      this.permissionConfirmationModal = false;
      var formData = {
        roleId: this.roleId,
        roleClaims: [
          {
            type: this.item.type,
            value: this.item.value,
            selected: this.active,
          },
        ],
      };
      await axios
        .put("Roles/" + this.roleName + "/Permissions", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            this.getDataFromApi(this.roleName, this.policyName);
            successToast("Permission changed successfully");
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getDataFromApi(roleName, policyName) {
      var data = { roleName, policyName };
      this.$emit("activiteApi", data);
    },
  },
};
</script>
