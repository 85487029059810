<template>
  <TransparentModal
    :show="show"
    :size="size"
    :backdrop="backdrop"
    :slideOver="slideOver"
    :refKey="refKey"
  >
    <div
      ref="movable-container"
      class="absolute w-full bg-inherit rounded-lg"
    >
    <!-- style="position: absolute; background: white; border-radius: 7.5px" -->
      <div
        class="modal-header flex items-center justify-between cursor-move"
        @mousedown="dragMouseDown"
      >
        <h2 class="font-medium text-base mr-auto">
          {{ title }}
        </h2>

        <div class="p-2">
          <XCircleIcon
            class="
              text-danger
              float-right
              lg:w-8 lg:h-8
              md:w-7 md:h-7
              w-6
              h-6
              cursor-pointer
            "
            @click="close"
          />
        </div>
      </div>
      <slot />
    </div>
  </TransparentModal>
</template>
<script>
export default {
  name: "MoveableModal",
  props: {
    title: {},
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    backdrop: {
      type: String,
      default: "",
    },
    slideOver: {
      type: Boolean,
      default: false,
    },
    refKey: {
      type: String,
      default: null,
    },
  },
  data: () => ({ 
    pointer1: 0, 
    pointer2: 0,
    pointer3: 0,
    pointer4: 0,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();

      // this.pointer.x = e.clientX;
      // this.pointer.y = e.clientY;

      this.pointer3 = e.clientX;
      this.pointer4 = e.clientY;

      document.onmouseup = this.closeDragElement;
      document.onmousemove = this.elementDrag;
    },

    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();

      // let x = e.clientX - this.pointer.x;
      // let y = e.clientY - this.pointer.y;

      this.pointer1 = this.pointer3 - e.clientX;
      this.pointer2 = this.pointer4 - e.clientY;
      this.pointer3 = e.clientX;
      this.pointer4 = e.clientY

      let elmt = this.$refs["movable-container"];
      elmt.style.top = (elmt.offsetTop - this.pointer2) + "px";
      elmt.style.left = (elmt.offsetLeft - this.pointer1) + "px";
     // elmt.style.transform = `translate(${x}px, ${y}px)`;
    },

    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>