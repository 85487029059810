<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full">
        <div class="flex justify-center items-center">
          <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport"
            :page="pagination.pageNumber" :selected="checkedData.length" @printSelected="printSelected"
            @csvExportSelected="csvExportSelected" @pdfExportSelected="pdfExportSelected" />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)"
            @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />

          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th></th>
              <th>Priority</th>
              <th>Order#</th>
              <!-- <th>priority</th> -->
              <th>status</th>
              <th>Customer</th>
              <th>type</th>
              <th>created</th>
              <th>amount</th>
              <th>currency</th>
              <th>booking#</th>
              <th>discount %</th>
              <th>discount</th>
              <th>note</th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)"
                  @unSelectAll="unSelectAll($event)" />
              </th>
            </tr>
          </thead>

          <tbody v-for="(item, i) in items" :key="i">
            <tr class="intro-x">
              <td class="cursor-pointer" :style="opened.includes(item.orderId) ? getSelectedColor() : 'color: #1a73e8'">
                <svg v-if="!opened.includes(item.orderId)" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  class="bi bi-caret-down-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-primary text-bold"
                  @click="toggle(item)" viewBox="0 0 16 16">
                  <path
                    d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>

                <svg v-if="opened.includes(item.orderId)" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                  class="bi bi-caret-up-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-white text-bold"
                  viewBox="0 0 16 16" @click="closeToggle(item)">
                  <path
                    d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
              </td>
              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                <div class="flex justify-center items-center space-x-1 w-[75px]" v-if="item.status == 1">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Picking Order">
                    <input id="vertical-form-1" v-model="item.priorityNumber" type="number" autocomplete="off"
                      class="form-control" :placeholder="setPriorityNumber(item.orderId)"
                      :style="opened.includes(item.orderId) ? 'color:black' : ''" />
                  </Tippy>
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Save Priority Number">
                    <SaveIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" @click="changePriorityNumber(item)" />
                  </Tippy>
                </div>
              </td>
              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.orderNumber != null ? item.orderNumber : "" }}
              </td>
              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                <div class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center whitespace-nowrap"
                  :style="{
                    'background-color': getStatusColor(item.status),
                  }">
                  {{ item.status != null ? getStatus(item.status) : "" }}
                </div>
              </td>
              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.customer && item.customer.company ? item.customer.company : item.billTo || '' }}
              </td>
              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.type != null ? getType(item.type) : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.time != null ? momentLocal(item.time) : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ currencyFormat(item.amount) }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.currency != null ? item.currency : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ item.despatchBookingNumber != null ? item.despatchBookingNumber : "" }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ percentageFormat(item.discountPercentage) }}
              </td>

              <td :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                {{ currencyFormat(item.discountTotal) }}
              </td>

              <td class="w-24" :style="opened.includes(item.orderId) ? getSelectedColor() : ''">
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.note">
                    {{ item.note != null ? item.note : "" }}
                  </Tippy>
                </div>
              </td>
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-6 gap-y-2 p-2 flex flex-wrap ml-4">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />

                  <RestoreItem v-if="item.status == 1" :activeAccess="$h.deleteItemAccess(policyAccess)"
                    @parentMethod="reQueueItem($event)" :item="item" name="Send to Queue" />

                  <DropOrder v-else :activeAccess="$h.addItemAccess('DroppedOrders')" @parentMethod="dropItem($event)"
                    :item="item" name="Drop Order" />

                  <ClearItem @parentMethod="openClearOrderAlert(item)" :item="item"
                    :name="`Clear ${item.orderNumber}`" />
                </div>
              </td>
            </tr>
            <tr v-if="opened.includes(item.orderId)">
              <td colspan="1000" v-if="!isException">
                <table class="table table-report -mt-2 table-auto">
                  <thead>
                    <tr class="uppercase whitespace-nowrap">
                      <th>description</th>
                      <th>PLU/SKU</th>
                      <th>Barcode/Ean</th>
                      <th>quantity</th>
                      <!-- <th>Reserved Qty</th> -->
                      <th>Picked</th>
                      <th>Not Picked</th>
                      <th>Is Picked</th>
                      <th>Status</th>
                      <th>Exception</th>
                      <th>Lot</th>
                      <th>Expiry Date</th>
                      <th>Location</th>
                      <th>Storage Section</th>
                      <th>Bin S-Type</th>
                      <th>Storage Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="orderLines.length <= 0">
                      <td colspan="12">
                        <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_ORDER_LINE
                          }}</span>
                      </td>
                    </tr>
                    <tr v-show="orderLines.length > 0" v-for="(item, index) in orderLines" :key="index" class="intro-x">
                      <td class="w-24">
                        <div class="text-left line-clamp-2">
                          <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                            {{ item.description != null ? item.description : "" }}
                          </Tippy>
                        </div>
                      </td>
                      <td>
                        {{ item.sku != null ? item.sku : "" }}
                      </td>
                      <td>
                        {{ item.ean != null ? item.ean : "" }}
                      </td>
                      <td>
                        {{ item.quantity != null ? item.quantity : 0 }}
                      </td>
                      <!-- <td class="text-center">
                        {{ item.reservedQuantity ? item.reservedQuantity : 0 }}
                      </td> -->
                      <td>
                        {{ item.picked != null ? item.picked : 0 }}
                      </td>
                      <td>
                        {{ item.picked != null ? (Number(item.quantity) - Number(item.picked)) : 0 }}
                      </td>
                      <td>
                        <div class="form-switch ml-3">
                          <input id="booleanSwitch" type="checkbox" v-model="item.isPicked" class="form-check-input"
                            disabled />
                        </div>
                      </td>
                      <td>
                        <div
                          class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center whitespace-nowrap"
                          :style="{
                            'background-color': getOrderLineStatusColor(item.status),
                          }">
                          {{ item.status != null ? getOrderLineStatus(item.status) : "" }}
                        </div>
                      </td>
                      <td>
                        <div class="flex items-center justify-center">
                          <Tippy tag="a" href="javascript:;" class="tooltip" content="Exception" :options="{
                            zIndex: 99999,
                          }">
                            <div class="relative flex items-center justify-center" @click="openExceptions(item)">
                              <div class="absolute top-0 left-6 w-auto h-auto p-1 rounded-full bg-danger text-white">{{
                                getCountOfExceptions(item) }}</div>
                              <InfoIcon class="w-8 h-8 text-danger" />
                            </div>
                          </Tippy>
                        </div>
                      </td>

                      <td>
                        {{ item.lot != null ? item.lot : "" }}
                      </td>
                      <td>
                        {{ item.expiryDate != null ? momentLocal(item.expiryDate) : "" }}
                      </td>
                      <td>
                        {{ item.bin != null ? item.bin : "" }}
                      </td>
                      <td>
                        {{ item.section != null ? item.section : "" }}
                      </td>
                      <td>
                        {{ item.binStorageType != null ? item.binStorageType : "" }}
                      </td>
                      <td>
                        {{ item.sectionStorageType != null ? item.sectionStorageType : "" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <PaginationBar @changePage="changePageOrderLines($event)"
                  @pageSizeChange="pageSizeChangeOrderLines($event)" :itemsData="orderLinesData"
                  :pageCount="pageCountOrderLines" :pageSize="pageSizeOrderLines"
                  :pageNumber="paginationOL.pageNumber" />
              </td>

              <td colspan="1000" v-else>
                <div class="col-span-12 p-4 intro-y">
                  <div @click="goBack()">
                    <Tippy tag="a" href="javascript:;" class="tooltip" content="Go back to order" :options="{
                      zIndex: 99999,
                    }">
                      <ArrowLeftIcon class="w-6 h-6 mr-1" />
                    </Tippy>
                  </div>
                  <div class="overflow-auto">
                    <table class="table -mt-2 table-auto table-report">
                      <thead>
                        <tr class="uppercase whitespace-nowrap">
                          <th>PLU/SKU</th>
                          <th>Barcode/Ean</th>
                          <th>Type</th>
                          <th>quantity</th>
                          <th>scanned At</th>
                          <th>scanned By</th>
                          <th>storage Section</th>
                          <th>storage Type</th>
                          <th>lpn</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in orderLineExceptionsLocal" :key="i" class="intro-x">
                          <td>
                            {{ item.sku ? item.sku : "" }}
                          </td>
                          <td>
                            {{ item.ean ? item.ean : "" }}
                          </td>
                          <td>
                            {{ item.type ? getExceptionType(item.type) : "" }}
                          </td>

                          <td>
                            {{ item.quantity ? item.quantity : "" }}
                          </td>

                          <td>
                            {{ item.scannedAt ? momentLocal(item.scannedAt) : "" }}
                          </td>

                          <td>
                            {{ item.scannedBy ? item.scannedBy : "" }}
                          </td>

                          <td>
                            {{ item.storageSection ? item.storageSection : "" }}
                          </td>

                          <td>
                            {{ item.storageType ? item.storageType : "" }}
                          </td>
                          <td>
                            {{ item.lpn ? item.lpn : "" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <PaginationBarLocal @changePage="changeExceptionPage($event)" :itemsData="itemsExceptionData"
                    :pageCount="pageCountException" :pageRange="pageRowCountException" :pageSize="pageSizeException" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData"
        :pageCount="pageCount" :pageSize="pageSizeView" :pageNumber="pagination.pageNumber" />

      <!-- END: Pagination -->
    </div>
  </div>

  <PickingConfirmModal :name="formDataActive.orderNumber" :modalStatus="deleteConfirmationModal"
    :modalConfirm="reQueueItemConfirm" :modalClose="closeDelete" />

  <DropConfirmModal :name="formDataActive.orderNumber" :modalStatus="dropConfirmationModal"
    :modalConfirm="dropItemConfirm" :modalClose="closeDrop" />

  <ConfirmAlert :modalStatus="alertClearOrder" :modalClose="closeClearOrderAlert" :modalConfirm="confirmOrderAlert"
    :isClose="true" icon="CheckCircleIcon" color="green-600"
    :question="`Are you sure you want to clear the order ${this.currentPickingOrder}?`" nameBtn="Ok" />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import countriesJSON from "@/assets/json/countries.json";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import moment from "moment";
import { customerOrderStatus, customerOrderStatusColor } from "../../../global-functions/status";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import Moveable from "vue3-moveable";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Picking Orders");
  },
  components: {
    Moveable,

    autoTable,
  },
  data() {
    return {
      headersFilter: [
        { text: "Order", value: "orderId" },
        { text: "priority", value: "priority" },
        { text: "status", value: "status" },
        { text: "Order Created", value: "time" },
        { text: "Booking", value: "despatchBookingNumber" },
        { text: "type", value: "type" },
      ],
      const_txt: const_txt,
      doc: null,

      searchData: "",
      isLoading: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      titleChange: -1,
      orderLines: [],
      formDataActive: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        statusList: "",
        despatchBookingNumber: "",
        currency: "",
        priority: 0,
        priorityNumber: 0,
        customer: {},
        deliveryAddress: {},
        despatch: {},

        orderLines: [
          {
            quantity: 0,
            price: 0,
            retailPrice: 0,
            discountedPrice: 0,
            discountedReason: "",
            status: 1,
            skuName: "",
            ean: "",
            description: "",
            skuId: 0,
            sku: "",
            orderId: 0,
          },
        ],
      },
      formDataActiveDefault: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        statusList: "",
        despatchBookingNumber: "",
        currency: "",
        priority: 0,
        priorityNumber: 0,
        customer: {},
        deliveryAddress: {},
        despatch: {},
        orderLines: [
          {
            quantity: 0,
            price: 0,
            retailPrice: 0,
            discountedPrice: 0,
            discountedReason: "",
            status: 1,
            skuName: "",
            ean: "",
            description: "",
            skuId: 0,
            sku: "",
            orderId: 0,
          },
        ],
      },

      pageCountOrderLines: 0,
      pageRowCountOrderLines: 5,
      pageSizeOrderLines: 10,
      productStart: 0,
      productEnd: 5,

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      paginationOL: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      defaultPaginationOL: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      pageSizeOL: 10,

      items: [],
      itemsData: [],

      orderLinesData: [],

      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,

      typeItems: store.state.authUser.OrderTypes,
      statusItems: store.state.authUser.PickingOrderStatus,
      priorityItems: [],

      orderLinesLocal: [],
      orderNo: "",
      isRow: false,
      opened: [],
      indexToggle: 0,
      itemsNew: [],
      orderLineStatusItems: store.state.authUser.OrderLineStatuses,
      splitData: false,
      allSelected: false,
      checkedData: [],
      pickingConfirmationModal: false,

      policyAccess: "PickingOrders",
      toggleOrderId: 0,

      exceptionList: [],
      orderLineExceptions: [],
      isException: false,

      orderLineExceptionsLocal: [],
      pageCountException: 0,
      pageRowCountException: 5,
      exceptionStart: 0,
      exceptionEnd: 5,
      pageNumberException: 1,
      itemsExceptionData: [],
      alertClearOrder: false,
      currentPickingOrder: undefined,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Picking" : "Edit Picking";
    },
  },
  watch: {},
  created() {
    this.getDataFromApi();
    this.$signalRHub.emitter.on("PickedProduct", this.updateProductStatus);
    this.$signalRHub.emitter.on("OrderPicked", this.updateOrderPicked);
    this.$signalRHub.emitter.on("OrderLineStatusUpdated", this.updateOrderLineStatus);
    this.$signalRHub.emitter.on("OrderStatusUpdated", this.updateOrderStatus);
  },
  beforeDestroy() {
    this.$signalRHub.emitter.off("PickedProduct", this.updateProductStatus);
    this.$signalRHub.emitter.off("OrderPicked", this.updateOrderPicked);
    this.$signalRHub.emitter.off("OrderLineStatusUpdated", this.updateOrderLineStatus);
    this.$signalRHub.emitter.off("OrderStatusUpdated", this.updateOrderStatus);
  },

  methods: {
    changeExceptionPage(pageNum) {
      this.pageNumberException = pageNum;
      this.exceptionEnd = this.pageRowCountException * pageNum;
      this.exceptionStart = this.exceptionEnd - this.pageRowCountException;
      this.orderLineExceptionsLocal = this.orderLineExceptions.slice(this.exceptionStart, this.exceptionEnd);
    },
    getExceptionType(type) {
      switch (type) {
        case 1:
          return "SKIP TASK";
        case 2:
          return "SPLIT PICK";
        case 3:
          return "PARTIAL PICK";
        case 4:
          return "STOCK OUT";
        case 5:
          return "INCORRECT ITEM LOCATION";
        default:
          return "";
      }
    },
    goBack() {
      this.isException = false;
    },
    getExceptions() {
      axios
        .get("CustomerOrders/" + this.toggleOrderId + "/PickingReport")
        .then((response) => {
          if (response.data.success) {
            this.exceptionList = response.data?.data?.pickingExceptions;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    openExceptions(item) {
      if (this.exceptionList.length <= 0) {
        warningToast("There are no exceptions");
      } else {
        this.orderLineExceptions = this.exceptionList.filter((f) => f.orderLineId === item.orderLineId);
        this.itemsExceptionData = this.orderLineExceptions;
        this.setLocalPagination();
        if (this.orderLineExceptions.length > 0) {
          this.isException = true;
        }
      }
    },
    setLocalPagination() {
      if (this.itemsExceptionData) {
        this.itemsExceptionData.totalCount = this.orderLineExceptions.length;
      }
      var pageCount = this.orderLineExceptions.length / this.pageRowCountException;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountException = pageCountRound;
      } else {
        this.pageCountException = pageCountRound;
      }

      if (this.pageRowCountException > this.orderLineExceptions.totalCount) {
        this.pageSizeException = this.orderLineExceptions.totalCount;
      } else {
        this.pageSizeException = this.pageRowCountException;
      }

      this.orderLineExceptionsLocal = this.orderLineExceptions.slice(this.exceptionStart, this.exceptionEnd);
    },
    getCountOfExceptions(item) {
      if (this.exceptionList.length <= 0) {
        return 0;
      } else {
        let count = this.exceptionList.filter((f) => f.orderLineId === item.orderLineId);
        return count ? count.length : 0;
      }
    },
    updateOrderPicked(data) {
      if (this.orderLines.length > 0 && this.toggleOrderId && this.toggleOrderId === data.OrderId) {
        let obj = this.orderLines?.find((orderLine) => orderLine.orderLineId === data.OrderLineId);
        if (obj) {
          let qty = obj?.picked + data?.Quantity;
          obj.picked = qty;
          if (obj.quantity === qty) {
            obj.isPicked = true;
          }
        }
      }

      this.updateOrderStatusLocal(data);
    },
    updateOrderLineStatus(data) {
      if (this.orderLines.length > 0 && this.toggleOrderId && this.toggleOrderId === data.OrderId) {
        let obj = this.orderLines?.find((orderLine) => orderLine.orderLineId === data.OrderLineId);
        if (obj) {
          obj.status = data?.Status;
          if (data?.Status === 3) {
            obj.isPicked = true;
          }
        }
      }
      //this.updateOrderStatus(data)
    },
    updateOrderStatusLocal(data) {
      if (this.items && this.items.length > 0) {
        let obj = this.items?.find((order) => order.orderId === data.OrderId);
        if (obj && data.Quantity > 0) {
          obj.status = 2;
        }
      }
    },
    updateOrderStatus(data) {
      if (this.items && this.items.length > 0) {
        let obj = this.items?.find((order) => order.orderId === data.OrderId);
        if (obj && data.Status === 4) {
          obj.status = 3;
        }
      }
    },
    updateProductStatus(data) {
      console.log("PickedProduct", data);
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    dropItem(item) {
      console.log("item", item);
      axios
        .get("CustomerOrders/" + this.warehouseAbbreviation + "/" + item.orderNumber, FormData)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            let isPicked = response.data?.data?.orderLines.every((ol) => ol.status === 3);
            if (isPicked) {
              this.formDataActive = Object.assign({}, item);
              this.dropConfirmationModal = true;
            } else {
              warningToast("Make sure, all products are picked!");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    dropItemConfirm() {
      this.isLoading = true;
      var FormData = {
        orderNumber: this.formDataActive.orderNumber,
      };
      axios
        .post("DroppedOrders/" + this.warehouseAbbreviation, FormData)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            // this.getDataFromApi();
            // this.$nextTick(()=> this.closeDrop() )
            successToast(response.data.message);
            this.closeDrop();
            setTimeout(() => {
              location.reload();
              this.isLoading = false;
            }, 400);
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setPriorityNumber(value) {
      try {
        return this.allItems.find((x) => x.orderId === parseInt(value)).priorityNumber;
      } catch (e) {
        return "";
      }
    },
    changePriorityNumber(item) {
      this.isLoading = true;
      if (item.priorityNumber == null || item.priorityNumber == "") {
        failedToast("Priority Number is required");
        this.isLoading = false;
      } else {
        var formDataChangePriorityNumber = {
          warehouse: this.warehouseAbbreviation,
          priorityNumber: item.priorityNumber,
          orderNumber: item.orderNumber,
        };
        axios
          .post("OrderPickingQueue/UpdateOrderPriorityNumber", formDataChangePriorityNumber)
          .then((response) => {
            if (response.data.success === true) {
              successToast(response.data.message);
              this.getDataFromApi();
              this.getAllDataFromApi();
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setDefaultPaginationOL() {
      this.paginationOL.pageNumber = 1;
      this.paginationOL.pageSize = 10;
      this.orderLinesData = [];
      this.pageCountOrderLines = 0;
      this.pageSizeOrderLines = 10;
    },
    toggle(item) {
      this.orderLines = [];
      this.opened.splice(this.indexToggle, 1);
      this.indexToggle = this.opened.indexOf(item.orderId);
      this.toggleOrderId = item.orderId;
      this.setDefaultPaginationOL();

      if (item.status > 1) {
        this.getExceptions();
      }


      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.orderNumber = item.orderNumber;
        this.getDataFromOrderLines();
        this.opened.push(item.orderId);
      }
    },
    closeToggle(item) {
      this.orderLines = [];
      this.indexToggle = this.opened.indexOf(item.orderId);
      this.toggleOrderId = 0;

      this.exceptionList = [];
      this.orderLineExceptions = [];
      this.isException = false;

      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.orderNumber = item.orderNumber;
        this.getDataFromOrderLines();
        this.opened.push(item.orderId);
      }
      this.setDefaultPaginationOL();
    },
    getOrderLineStatus(value) {
      try {
        return this.orderLineStatusItems.find((x) => x.number === parseInt(value)).displayName;
      } catch (e) {
        return "";
      }
    },
    getOrderLineStatusColor(value) {
      try {
        return this.orderLineStatusItems.find((x) => x.number === parseInt(value)).color;
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getStatus(value) {
      try {
        return this.statusItems.find((x) => x.number === parseInt(value)).displayName;
      } catch (e) {
        return "";
      }
    },
    getStatusColor(value) {
      try {
        return this.statusItems.find((x) => x.number === parseInt(value)).color;
        //return "#006400";
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getType(value) {
      try {
        return this.typeItems.find((type) => type.number === parseInt(value)).name;
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    getPriority(value) {
      try {
        if (value == 1) {
          return "Low";
        } else if (value == 2) {
          return "Medium";
        } else if (value == 3) {
          return "High";
        } else {
          return "";
        }
        // return priorityData.find((x) => x.number === value).name;
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Order#", dataKey: "orderNumber" },
              { title: "Amount", dataKey: "amount" },
              { title: "Order Created", dataKey: "time" },
              { title: "Booking#", dataKey: "despatchBookingNumber" },
              { title: "Customer", dataKey: "customer" },
              { title: "Address", dataKey: "deliveryAddress" },
              { title: "Note", dataKey: "note" },
              { title: "Status", dataKey: "status" },
              { title: "Type", dataKey: "type" },
              { title: "Discount Percentage", dataKey: "discountPercentage" },
              { title: "Discount Total", dataKey: "discountTotal" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                orderNumber: el.orderNumber,
                amount: el.amount,
                time: this.momentLocal(el.time),
                despatchBookingNumber: el.despatchBookingNumber,
                customer: el.customer.length <= 0 ? "" : el.customer.firstName + " " + el.customer.lastName,
                deliveryAddress:
                  el.deliveryAddress == null
                    ? ""
                    : el.deliveryAddress.line +
                    "\n" +
                    el.deliveryAddress.postcode +
                    "\n" +
                    el.deliveryAddress.state +
                    "\n" +
                    el.deliveryAddress.city +
                    "\n" +
                    el.deliveryAddress.country,
                note: el.note,
                status: this.getStatus(el.status),
                type: this.getType(el.type),
                discountPercentage: el.discountPercentage,
                discountTotal: el.discountTotal,
              };
              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Picking Orders" + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Picking Orders " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Order#",
          2: "Amount",
          3: "Order Created",
          4: "Booking#",
          5: "Customer",
          6: "Address Line",
          7: "Postcode",
          8: "State",
          9: "City",
          10: "Country",
          11: "Note",
          12: "Status",
          13: "Type",
          14: "Discount Percentage",
          15: "Discount Total",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.orderNumber,
            2: item.amount,
            3: this.momentLocalCSV(item.time),
            4: item.despatchBookingNumber,
            5: item.customer.length <= 0 ? "" : item.customer.firstName + " " + item.customer.lastName,
            6: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.line,
            7: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.postcode,
            8: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.state,
            9: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.city,
            10: item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.country,
            11: item.note,
            12: this.getStatus(item.status),
            13: this.getType(item.type),
            14: item.discountPercentage,
            15: item.discountTotal,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post(`OrderPickingQueue/ActiveOrders/${this.warehouseAbbreviation}`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },
    customerOrderStatus(status) {
      return customerOrderStatus(status);
    },
    customerOrderStatusColor(status) {
      return customerOrderStatusColor(status);
    },

    getToday() {
      const today = new Date();

      this.formDataActive.time = moment(today).format("DD MMMM, YYYY");
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.pagination.pageNumber = 1;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;

      this.getDataFromApi();
    },

    changePageOrderLines(pageNum) {
      this.paginationOL.pageNumber = pageNum;
      this.paginationOL.pageSize = this.pageSizeOL;
      this.getDataFromOrderLines();
    },

    pageSizeChangeOrderLines(pageSize) {
      this.pageSizeOL = pageSize;
      this.paginationOL.pageSize = this.pageSizeOL;
      this.paginationOL.pageNumber = 1;
      this.getDataFromOrderLines();
    },
    setDefault() {
      this.formDataActive = Object.assign({}, this.formDataActiveDefault);
      this.getToday();
    },

    getDataFromApi() {
      this.isLoading = true;

      axios
        .post(`OrderPickingQueue/ActiveOrders/${this.warehouseAbbreviation}`, this.pagination)
        .then((response) => {
          this.itemsData = response.data.data;
          this.items = response.data.data.items;

          this.pageCount = this.itemsData.totalPages;
          if (this.pagination.pageNumber == this.itemsData.totalPages) {
            this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
          } else {
            this.pageSizeView = this.pagination.pageSize;
          }
          for (var i = 0; i < this.items.length; i++) {
            this.items[i].selected = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getDataFromOrderLines() {
      this.isLoading = true;

      axios
        .post("Picking/" + this.warehouseAbbreviation + "/" + this.orderNumber + "/GetPickingOrderLines", this.paginationOL)
        .then((response) => {
          if (response.data.success) {
            this.orderLinesData = response.data.data;
            this.orderLines = response.data.data.items;
            this.pageCountOrderLines = this.orderLinesData.totalPages;

            if (this.paginationOL.pageNumber == this.orderLinesData.totalPages) {
              this.pageSizeOrderLines = this.orderLinesData.totalCount - (this.pageCountOrderLines - 1) * this.paginationOL.pageSize;
            } else {
              this.pageSizeOrderLines = this.paginationOL.pageSize;
            }

            console.log("getDataFromOrderLines", this.orderLinesData, this.pageCountOrderLines, this.pageSizeOrderLines);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    connectTimetoDate() {
      var currentTime = moment(new Date()).format();
      var splitTime = currentTime.split("T");

      var time = moment(this.formDataActive.time).format("YYYY-MM-DD");
      time = time.concat("T").concat(splitTime[1]);
      this.formDataActive.time = time;

      var deliveryDate = moment(this.formDataActive.deliveryDate).format("YYYY-MM-DD");
      deliveryDate = deliveryDate.concat("T").concat(splitTime[1]);
      this.formDataActive.deliveryDate = deliveryDate;
    },
    archiveItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.archiveConfirmationModal = true;
      console.log(item);
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },
    closePick() {
      this.pickingConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeDrop() {
      this.dropConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataActive.orderId;

      axios
        .get("CustomerOrders/" + itemId + "/Archive")
        .then((response) => {
          this.archiveConfirmationModal = false;
          this.getDataFromApi();
          successToast(response.data.message);
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("CustomerOrders/Archived", this.paginationArchive)
        .then((response) => {
          this.archiveData = response.data.data;
          this.archiveItems = response.data.data.items;
          this.pageCountArchive = this.archiveData.totalPages;

          this.archiveModal = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive() {
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },

    restoreItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataActive.orderId;

      axios
        .get("CustomerOrders/" + itemId + "/Restore")
        .then((response) => {
          this.restoreConfirmationModal = false;
          this.viewArchives();
          this.getDataFromApi();
          if (this.archiveData.totalCount == 0) {
            this.archiveModal = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    reQueueItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },

    reQueueItemConfirm() {
      this.isLoading = true;
      var orderId = this.formDataActive.orderId;

      axios
        .post("OrderPickingQueue/" + this.warehouseAbbreviation + "/NewOrders/Add?orderId=" + orderId)
        .then((response) => {
          if (response.data.success) {
            successToast(response.data.message);
            this.deleteConfirmationModal = false;
            this.getDataFromApi();
            this.isLoading = false;
          } else {
            failedToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    // deleteItemConfirm() {
    //   this.isLoading = true;
    //   var orderNumber = this.formDataActive.orderNumber;
    //   axios
    //     .get(`OrderPickingQueue/Remove?orderNumber=${orderNumber}&warehouse=${this.warehouseAbbreviation}`)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         // this.getDataFromApi();
    //         // this.$nextTick(()=> this.deleteConfirmationModal = false )

    //         successToast(response.data.message);
    //         this.deleteConfirmationModal = false;
    //         setTimeout(() => {
    //           location.reload();
    //           this.isLoading = false;
    //         }, 400);
    //       } else {
    //         this.isLoading = false;
    //         failedToast(response.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white;";
    },

    openClearOrderAlert(item) {
      this.alertClearOrder = true;
      this.currentPickingOrder = item.orderNumber
    },

    closeClearOrderAlert() {
      this.currentPickingOrder = undefined;
      this.alertClearOrder = false;
    },
    confirmOrderAlert() {
      this.clearOrder();
      this.alertClearOrder = false
    },
    clearOrder() {
      this.isLoading = true;
      axios
        .get(`OrderPickingQueue/Remove`, {
          params: {
            orderNumber: this.currentPickingOrder,
            warehouse: this.warehouseAbbreviation
          }
        })
        .then((response) => {
          successToast("Cleared successfully.");
          this.isLoading = false;
          this.getDataFromApi();
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    }
  },
};
</script>
