<template>
  <a @click="selectAll()" class="flex text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0">
    <Tippy
      v-if="!allSelected"
      tag="a"
      href="javascript:;"
      class="tooltip w-full"
      content="Select All"
      :options="{
        zIndex: 99999,
      }"
    >
      <SquareIcon
        class="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 ml-1 text-primary"
      />
    </Tippy>
  </a>

  <a @click="unSelectAll()" class="flex text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0"
    ><Tippy
      v-if="allSelected"
      tag="a"
      href="javascript:;"
      class="tooltip w-full"
      content="Unselect All"
      :options="{
        zIndex: 99999,
      }"
    >
      <CheckSquareIcon
        class="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 ml-1 text-primary"
    /></Tippy>
  </a>
</template>
<script>
export default {
  name: "SelectLabels",
  props: { allSelected: false },
  methods: {
    selectAll() {
      this.$emit("selectAll");
    },
    unSelectAll() {
      this.$emit("unSelectAll");
    },
  },
};
</script>
