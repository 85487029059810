<template>
  <LoadingScreen :isLoading="isLoading" />

  <template v-if="!isException">
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <Autocomplete ref="cororder" v-model:modelValue="orderId" label="Order" itemText="orderNumber" itemValue="orderId" @load="newLoadOrder" refKey="cororder" />
        </div>
     
        <!-- <div>
          <a href="javascript:;" @click="getReports()" class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"> GET REPORT </a>
        </div> -->
        <div>
          <a href="javascript:;" @click="clearReports()" class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"> CLEAR </a>
        </div>
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport" :page="pageNumber" />
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto" v-if="reportsLocal.length > 0">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th>description</th>
              <th>PLU/SKU</th>
              <th>BARCODE/EAN</th>
              <th>quantity</th>
              <th>picked At</th>
              <th>picked By</th>
              <th>expiry Date</th>
              <th>lot</th>
              <th>lpn</th>
              <th>Section</th>
              <th>Bin</th>
              <th>Storage Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td>
                {{ item.description  ?  item.description : ""}}
              </td>

              <td>
                {{ item.sku  ?  item.sku : ""}}
              </td>
              <td>
                {{ item.ean  ?  item.ean : ""}}
              </td>
              <td>
                {{ item.quantity  ?  item.quantity : ""}}
              </td>
              <td>
                {{ item.pickedAt ? momentLocal(item.pickedAt) : "" }}
              </td>
            
              <td>
                {{ item.pickedBy  ?  item.pickedBy : ""}}
              </td>
              <td>
                {{ item.expiryDate ? momentLocal(item.expiryDate) : "" }}
              </td>
              <td>
                {{ item.lot  ?  item.lot : ""}}
              </td>
              <td>
                {{ item.lpn  ?  item.lpn : ""}}
              </td>
              <td>
                {{ item.storageSection  ?  item.storageSection : ""}}
              </td>
              <td>
                {{ item.storageBin  ?  item.storageBin : ""}}
              </td>
              <td>
                {{ item.storageType  ?  item.storageType : ""}}
              </td>
              <td class="table-report__action w-28">
                <InfoItem @parentMethod="openException($event)" :item="item" name="Exceptions" />
                </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <div
      v-if="reports.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>
    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />
  </div>
</template>

  <template v-else>
    <ExceptionReport v-if="isException" v-model:modelValue="isException" :exceptions="exceptionsCopy" :orderLineId="orderLineId" />
  </template>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-components/toast/toast";

import store from "../../../store";

import ExceptionReport from "./ExceptionReport.vue"
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Order Reports");
  },
  components: {
    paginate: Paginate,
    ExceptionReport
  },
  data() {
    return {
      orders: [],
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,
      orderId: null,
     
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      itemsData: {},

      reports: [],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
      switchOption: 0,

      exceptions: [],
      exceptionsCopy: [],
      orderLineId: 0,
      isException: false,
    };
  },
  computed: {
    getReportData() {
      return this.orderId;
    },
  },
  created() {

  },
  watch: {
   
    getReportData() {
      // if (this.orderId === null) {
      //   this.clearSS();
      // }
      // if (this.formDataStorageabb != "") {
      //   if (this.formDataStorageabb === "ALL") {
      //     this.switchOption = 0;
      //   } else {
      //     this.switchOption = 1;
      //   }
      //   // this.getReports();
      // } else {
      //   warningToast(const_txt.NO_DATA);
      // }
      this.getReports()
    },
   
  },
  methods: {
    openException(item){
      this.isException = true;
      this.exceptionsCopy = [...this.exceptions]
      this.exceptionsCopy = this.exceptionsCopy.filter((e)=> e.orderLineId === item.orderLineId)
      this.orderLineId = item.orderLineId;
      console.log('exceptions',this.orderLineId,this.exceptions);
    },
    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
        { title: "Order Number", dataKey: "orderNumber" },
          { title: "Description", dataKey: "description" },
          { title: "PLU/SKU", dataKey: "sku" },
          { title: "BARCODE/EAN", dataKey: "ean" },
          { title: "Quantity", dataKey: "quantity" },
          { title: "Picked At", dataKey: "pickedAt" },
          { title: "Picked By", dataKey: "pickedBy" },
          { title: "Expiry Date", dataKey: "expiryDate" },
          { title: "LOT", dataKey: "lot" },
          { title: "LPN", dataKey: "lpn" },
          { title: "Warehouse", dataKey: "warehouse" },
          { title: "Section", dataKey: "storageSection" },
          { title: "Bin", dataKey: "storageBin" },
          { title: "Storage Type", dataKey: "storageType" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            orderNumber: el.orderNumber ? el.orderNumber : "",
            description: el.description ? el.description : "",
            sku: el.sku ? el.sku : "",
            ean: el.ean ? el.ean : "",
            quantity: el.quantity ? el.quantity : 0,
            pickedAt: el.pickedAt ? this.momentLocal(el.pickedAt) : "",
            pickedBy: el.pickedBy ? el.pickedBy : "",
            expiryDate: el.expiryDate ? this.momentLocal(el.expiryDate) : "",
            lot: el.lot ? el.lot : "",
            lpn: el.lpn ? el.lpn : "",
            warehouse: el.warehouse ? el.warehouse : "",
            storageSection: el.storageSection ? el.storageSection : "",
            storageBin: el.storageBin ? el.storageBin : "",
            storageType: el.storageType ? el.storageType : "",
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Order Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Order Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1:"Order Number",
          2: "Description",
          3: "PLU/SKU",
          4: "BARCODE/EAN",
          5: "Quantity",
          6: "Picked At",
          7: "Picked By",
          8: "Expiry Date",
          9: "LOT",
          10: "LPN",
          11: "Warehouse",
          12: "Section",
          13: "Bin",
          14: "Storage Type",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.orderNumber ? item.orderNumber : "",
            2: item.description ? item.description : "",
            3: item.sku ? item.sku : "",
            4: item.ean ? item.ean : "",
            5: item.quantity ? item.quantity : "",
            6: item.pickedAt ? this.momentLocalCSV(item.pickedAt) : "",
            7: item.pickedBy ? item.pickedBy : "",
            8: item.expiryDate ? this.momentLocalCSV(item.expiryDate) : "",
            9: item.lot ? item.lot : "",
            10: item.lpn ? item.lpn : "",
            11: item.warehouse ? item.warehouse : "",
            12: item.storageSection ? item.storageSection : "",
            13: item.storageBin ? item.storageBin : "",
            14: item.storageType ? item.storageType : "",
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },

    clearReports() {
      this.reports = [];
      this.reportsLocal = [];
      this.orderId = null;
      this.orderLineId = null;
      this.exceptions = []
      this.exceptionsCopy = []
      //this.getCurrentMonth();
    },

    getReports() {
      if(this.orderId){
        this.isLoading = true;
      // axios
      //   .get(
      //     this.switchOption > 0
      //       ? "Reports/" +
      //           this.warehouseAbbreviation +
      //           "/" +
      //           this.formDataStorageabb +
      //           "/Bins/Empty"
      //       : "Reports/" + this.warehouseAbbreviation + "/Bins/Empty"
      //   )
      //   .then((response) => {
      //     console.log(response);
      //     if (response.data.success === true) {
      //       this.isLoading = false;
      //       this.itemsData = response.data;
      //       if (response.data) {
      //         this.itemsData.totalCount = this.itemsData.data.length;
      //       }
      //       this.reports = response.data.data;
      //       this.setLocalReport();
      //       if (this.reports.length == 0) {
      //         this.isLoading = false;
      //         warningToast("NO DATA ", response.data.message);
      //       }
      //     } else {
      //       this.isLoading = false;
      //       failedToast(response.data.message);
      //     }
      //   });

      // this.switchOption = 0;

      axios.get("CustomerOrders/" +this.orderId+"/PickingReport").then((response) => {
        console.log(response);
        if (response.data.success) {
          this.isLoading = false;
          this.itemsData = response.data.data?.pickedOrderLines;
         
          if (response.data.pickedOrderLines) {
            this.itemsData.totalCount = this.itemsData.data.data?.pickedOrderLines.length;
          }
          this.reports = response.data.data?.pickedOrderLines;
          this.exceptions = response.data.data?.pickingExceptions;
          let orderNumber = this.orders.find((o)=> o.orderId === this.orderId)?.orderNumber;
          if(orderNumber){
            this.reports.forEach((o)=> {
            o.orderNumber = orderNumber
          })

          this.exceptions.forEach((e)=> {
            e.orderNumber = orderNumber
          })
          }
         
          
          
          this.setLocalReport();
          if (this.reports.length == 0) {
            this.isLoading = false;
            warningToast("NO DATA ", response.data.message);
          }
        } else {
          this.isLoading = false;
          failedToast(response.data.message);
        }
      })
      .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
      }
      
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getReports();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
    newLoadOrder(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("CustomerOrders/Paginated/"+this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            let list = [...JSON.parse(JSON.stringify(this.orders)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.orders = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};

</script>
