<template>
  <MoveableModal :show="_modalStatus" @hidden="_modalStatus = false" @close="modalClose()">
    <ModalBody class="p-1">
      <div class="p-8 text-center">
        <component :is="icon" :class="`text-${color} w-16 h-16 mx-auto mt-1`" />
        <div class="mt-3 text-3xl">Are you sure?</div>
        <div :class="`text-xl font-bold mt-5 text-${color}`">{{ name }}</div>
        <div class="mt-2 text-base text-slate-500">
          {{ question }}
        </div>
        <slot />
      </div>
      <div class="px-5 pb-8 text-center">
        <button type="button" @click="modalClose()" class="w-24 mr-1 btn btn-outline-secondary"
          v-if="isClose">Cancel</button>
        <button type="button" :class="`btn text-${color} w-auto whitespace-nowrap`" @click="modalConfirm()">
          {{ nameBtn }}
        </button>
      </div>
    </ModalBody>
  </MoveableModal>
</template>
<script>
export default {
  props: {
    name: "",
    icon: "",
    color: "",
    nameBtn: "",
    question: "",
    isClose: true,
    modalStatus: false,
    modalConfirm: Function,
    modalClose: Function,
  },
  computed: {
    _modalStatus: {
      get() {
        return this.modalStatus;
      },
    },
  },
  watch: {
    _modalStatus(val) {
      val || this.modalClose();
    },
  },
  created() { },

  methods: {
    modalClose() {
      return this.modalClose();
    },
  },
};
</script>
