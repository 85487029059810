import axios from 'axios'
import store from '../store/index.js'
import router from '../router'

var webUrl = window.location.href;
var origin = new URL(webUrl).origin;
var combineUrl = origin + "/api/";


const Url = localStorage.getItem("baseurl") !== null ? JSON.parse(localStorage.getItem("baseurl")) : `${combineUrl}`;
// const Url = splitUrl === "http://localhost:8080" ?  'https://mywarehouse.myweb.net.au/api/' : `${splitUrl}/api/`;
// const Url = `${localStorage.getItem("licensee").website}/api/`; `${combineUrl}` 'fibrecycle-mywarehouse.myweb.net.au/api/'

console.log('urls',Url);
const instance = axios.create({
    baseURL: Url,
    headers: {
        'Content-type': 'application/json',
    },
})

instance.interceptors.request.use(
    config => {
        if (store.getters["authUser/isLoggedIn"]) {
            config.headers.Authorization = 'Bearer ' + store.getters["authUser/token"]
        }
        return config
    }, error => Promise.reject(error));

instance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        const status = error.response.status;

        if (status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (store.getters["authUser/isRefreshing"]) {
                store.dispatch("authUser/getAccessToken")
                    .then(() => {
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + store.getters["authUser/token"];
                        return instance.request(originalRequest);
                    })
                    .catch(() => {
                        router.push('/login')
                    })
            } else {
                router.push('/login')
            }
        }
        // router.push('/logout')
        // localStorage.clear();
        return Promise.reject(error);
    });

export default instance
