<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label">Supplier</label>
          <div class="w-72">
            <v-select
              v-model="purchaseReportData.supplierId"
              placeholder="SELECT"
              :filterable="false"
              @open="onOpenSup"
              @close="onCloseSup"
              @search="searchSupplier"
              class="style-chooser"
              :key="company"
              :get-option-label="(option) => option.company"
              :reduce="(company) => company.supplierId"
              :options="suppliers"
              label="company"
            >
              <template #list-footer>
                <li v-show="hasNextPageSup" ref="loadSup" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">PLU/SKU</label>
          <div class="w-72">
            <v-select
              v-model="purchaseReportData.skuId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenSku"
              @close="onCloseSku"
              @search="searchSKU"
              :options="skus"
              :reduce="(skuName) => skuName.skuId"
              :get-option-label="(option) => option.skuName"
            >
              <template #list-footer>
                <li v-show="hasNextPageSku" ref="loadSku" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">From Date</label>
          <PreviewComponent class="intro-y">
            <div class="w-40">
              <Preview>
                <Litepicker
                  v-model="purchaseReportData.fromDate"
                  :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }"
                  class="form-control"
                />
              </Preview>
            </div>
          </PreviewComponent>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">To Date</label>
          <PreviewComponent class="intro-y">
            <div class="w-40">
              <Preview>
                <Litepicker
                  v-model="purchaseReportData.toDate"
                  :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }"
                  class="form-control"
                />
              </Preview>
            </div>
          </PreviewComponent>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Filter </label>

          <Dropdown>
            <DropdownToggle class="btn px-2 box">
              <span class="w-5 h-5 flex items-center justify-center">
                <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4" />
              </span>
            </DropdownToggle>
            <DropdownMenu class="w-40">
              <DropdownContent>
                <DropdownItem
                  @click="setMonth(item)"
                  v-for="(item, i) in monthsList"
                  :key="i"
                >
                  <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4 mr-2" />
                  {{ item.name }}
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="getReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            GET REPORT
          </a>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="clearReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            CLEAR
          </a>
        </div>
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pageNumber"
        />
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table
        class="table table-report -mt-2 table-auto"
        v-if="reportsLocal.length > 0"
      >
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
            <th>InvoiceDate</th>
            <th>ReceivedDate</th>
            <th>ProductionDate</th>
            <th>ExpirationDate</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
            <td>
              {{
                item.invoiceDate != null ? momentLocal(item.invoiceDate) : ""
              }}
            </td>
            <td>
              {{
                item.receivedDate != null ? momentLocal(item.receivedDate) : ""
              }}
            </td>
            <td>
              {{
                item.productionDate != null
                  ? momentLocal(item.productionDate)
                  : ""
              }}
            </td>
            <td>
              {{
                item.expirationDate != null
                  ? momentLocal(item.expirationDate)
                  : ""
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div
      v-if="reports.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-components/toast/toast";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Product Purchase Reports");
    this.observerSup = new IntersectionObserver(this.infiniteScrollSup);
    this.observerSku = new IntersectionObserver(this.infiniteScrollSku);
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,

      headers: [
        {
          text: "Invoice Number",
          value: "invoiceNumber",
        },

        { text: "Order Number", value: "orderNumber" },
        { text: "Description", value: "description" },
        { text: "SupplierProductNo", value: "supplierProductNumber" },
        { text: "Product Description", value: "productDescription" },
        { text: "Sku", value: "sku" },
        { text: "Ean", value: "ean" },
        { text: "Quantity", value: "quantity" },
        { text: "Utilized Quantity", value: "utilizedQuantity" },
        { text: "Unit Price", value: "unitPrice" },
        { text: "Discount Percentage", value: "discountPercentage" },
        { text: "Gross Value", value: "grossValue" },
        { text: "Vat", value: "vat" },
        { text: "VipAmount", value: "vipAmount" },
        { text: "Additional Cost", value: "additionalCost" },

        { text: "Supplier", value: "supplier" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      suppliers: [],
      itemsData: {},

      purchaseReportData: {
        supplierId: "",
        fromDate: "",
        toDate: "",
        skuId: "",
      },
      skus: [],

      observerSup: null,
      limitSup: 5,
      hasNextPageSup: false,
      totalSupCount: 0,
      isSupSearchResult: false,
      supPageNumber: 1,
      supSearchTimer: undefined,

      observerSku: null,
      limitSku: 5,
      hasNextPageSku: false,
      totalSkuCount: 0,
      isSkuSearchResult: false,
      skuPageNumber: 1,
      skuSearchTimer: undefined,

      skuOption: false,

      reports: [],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
      tempSkuName: "",
      tempCompany: "",
    };
  },
  computed: {
    checkLimtSku() {
      return this.skuPageNumber;
    },
    checkLimtSup() {
      return this.supPageNumber;
    },
    getSupplierId() {
      return this.purchaseReportData.supplierId;
    },
    viewModalSku() {
      return this.purchaseReportData.skuId;
    },
  },
  created() {
    this.getCurrentMonth();
    this.getSuppliers("");
    this.getSkus("");
  },
  watch: {
    checkLimtSku() {
      this.getSkus("");
    },
    checkLimtSup() {
      this.getSuppliers("");
    },
    getSupplierId() {
      if (this.purchaseReportData.supplierId === null) {
        this.skuOption = false;
        this.clearSku();
        this.clearSupplier();
      } else {
        this.purchaseReportData.skuId = null;
        this.skuOption = true;
        this.clearSku();
      }
      this.getSkus();
    },
    viewModalSku() {
      if (this.purchaseReportData.skuId === null) {
        this.clearSku();
      }
    },
  },
  methods: {
    clearSku() {
      this.skus = [];
      this.skuPageNumber = 1;
      this.limitSku = 5;
      this.getSkus("");
    },
    searchSKU(search) {
      clearInterval(this.skuSearchTimer);
      this.skuSearchTimer = setTimeout(() => {
        if (search) {
          this.skus = [];
          this.skuPageNumber = 1;
          this.limitSku = this.totalSkuCount;
          this.getSkus(search.trim());
        }
      }, 2000);
    },

    getSkus(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.skuPageNumber,
        pageSize: this.limitSku,
        search: search,
      };
      axios
        .post(
          this.skuOption !== true
            ? `Sku/Stocks/${this.warehouseStoreId}`
            : this.purchaseReportData.supplierId !== null
            ? `SupplierProducts/${this.purchaseReportData.supplierId}/Paginated`
            : `Sku/Stocks/${this.warehouseStoreId}`,
          pagination
        )
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            this.skus.unshift({
              skuId: null,
              skuName: "ALL",
            });

            this.isSkuSearchResult = !!search;

            this.skus =
              this.isSkuSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.skus, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageSku = response.data.data.hasNextPage;
            this.totalSkuCount = response.data.data.totalCount;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in PLU/SKU");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    async onOpenSku() {
      if (this.isSkuSearchResult) {
        this.skuPageNumber = 1;
      }
      if (this.hasNextPageSku) {
        await this.$nextTick();
        this.observerSku.observe(this.$refs.loadSku);
      }
    },
    onCloseSku() {
      this.observerSku.disconnect();
    },
    async infiniteScrollSku([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.skuPageNumber++;
        this.limitSku;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSup() {
      if (this.isSupSearchResult) {
        this.supPageNumber = 1;
      }
      if (this.hasNextPageSup) {
        await this.$nextTick();
        this.observerSup.observe(this.$refs.loadSup);
      }
    },
    onCloseSup() {
      this.observerSup.disconnect();
    },
    async infiniteScrollSup([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supPageNumber++;
        this.limitSup;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Invoice Number", dataKey: "invoiceNumber" },
          { title: "Invoice Date", dataKey: "invoiceDate" },
          { title: "Received Date", dataKey: "receivedDate" },
          { title: "Order Number", dataKey: "orderNumber" },
          { title: "Description", dataKey: "description" },
          { title: "SupplierProductNo", dataKey: "supplierProductNumber" },
          { title: "Product Description", dataKey: "productDescription" },

          { title: "Sku", dataKey: "sku" },
          { title: "Ean", dataKey: "ean" },
          { title: "Quantity", dataKey: "quantity" },
          { title: "Utilized Quantity", dataKey: "utilizedQuantity" },
          { title: "Unit Price", dataKey: "unitPrice" },
          { title: "Discount Percentage", dataKey: "discountPercentage" },
          { title: "Gross Value", dataKey: "grossValue" },

          { title: "Vat", dataKey: "vat" },
          { title: "Vip Amount", dataKey: "vipAmount" },
          { title: "Additional Cost", dataKey: "additionalCost" },
          { title: "Production Date", dataKey: "productionDate" },
          { title: "Expiration Date", dataKey: "expirationDate" },
          { title: "Supplier", dataKey: "supplier" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            invoiceNumber: el.invoiceNumber,
            invoiceDate: this.momentLocal(el.invoiceDate),
            receivedDate: this.momentLocal(el.receivedDate),
            orderNumber: el.orderNumber,
            description: el.description,
            supplierProductNumber: el.supplierProductNumber,
            productDescription: el.productDescription,

            sku: el.sku,
            ean: el.ean,
            quantity: el.quantity,
            utilizedQuantity: el.utilizedQuantity,
            unitPrice: el.unitPrice,
            discountPercentage: el.discountPercentage,
            grossValue: el.grossValue,

            vat: el.vat,
            vipAmount: el.vipAmount,
            additionalCost: el.additionalCost,
            productionDate: el.productionDate,
            expirationDate: el.expirationDate,
            supplier: el.supplier,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(
            footer,
            pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
            pageHeight - 0.2,
            { baseline: "bottom" }
          );
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Product Purchase Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Product Purchase Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "Invoice Number",
          2: "Invoice Date",
          3: "Received Date",
          4: "Order Number",
          5: "Description",
          6: "SupplierProductNo",
          7: "Product Description",
          8: "Sku",
          9: "Ean",
          10: "Quantity",
          11: "Utilized Quantity",
          12: "Unit Price",
          13: "Discount Percentage",
          14: "Gross Value",
          15: "Vat",
          16: "Vip Amount",
          17: "Additional Cost",
          18: "Production Date",
          19: "Expiration Date",
          20: "Supplier",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.invoiceNumber,
            2: item.invoiceDate,
            3: item.receivedDate,
            4: item.orderNumber,
            5: item.description,
            6: item.supplierProductNumber,
            7: item.productDescription,
            8: item.sku,
            9: item.ean,
            10: item.quantity,
            11: item.utilizedQuantity,
            12: item.unitPrice,
            13: item.discountPercentage,
            14: item.grossValue,
            15: item.vat,
            16: item.vipAmount,
            17: item.additionalCost,
            18: this.momentLocalCSV(item.productionDate),
            19: this.momentLocalCSV(item.expirationDate),
            20: item.supplier,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    getCurrentMonth() {
      const today = new Date();
      var startdate =
        today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
      this.purchaseReportData.fromDate = startdate;
    },

    setMonth(item) {
      this.selectedMonth = item.value - 1;
      this.getMonthRange();
    },
    getMonthRange() {
      var d = new Date();
      var start = new Date(d.getFullYear(), this.selectedMonth, 1);
      var end = new Date(d.getFullYear(), this.selectedMonth + 1, 0);

      var startdate =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getDate();

      var enddate =
        end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate();

      this.purchaseReportData.fromDate =
        moment(startdate).format("DD MMMM, YYYY");
      this.purchaseReportData.toDate = moment(enddate).format("DD MMMM, YYYY");
    },

    clearSupplier() {
      this.suppliers = [];
      this.supPageNumber = 1;
      this.limitSup = 5;
      this.getSuppliers("");
    },

    searchSupplier(search) {
      clearInterval(this.supSearchTimer);
      this.supSearchTimer = setTimeout(() => {
        if (search) {
          this.suppliers = [];
          this.supPageNumber = 1;
          this.limitSup = this.totalSupCount;
          this.getSuppliers(search.trim());
        }
      }, 2000);
    },

    async getSuppliers(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.supPageNumber,
        pageSize: this.limitSup,
        search: search,
      };
      await axios
        .post("Suppliers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isSupSearchResult = !!search;
            this.suppliers.unshift({
              supplierId: null,
              company: "ALL",
            });

            this.suppliers =
              this.isSupSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.suppliers, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageSup = response.data.data.hasNextPage;
            this.totalSupCount = response.data.data.totalCount;

            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearReports() {
      this.reports = [];
      this.reportsLocal = [];
      this.purchaseReportData.supplierId = null;
      this.purchaseReportData.skuId = null;
      this.getCurrentMonth();
    },

    getReports() {
      // this.isLoading = true;
      var item = Object.assign({}, this.purchaseReportData);
      item.fromDate = moment(item.fromDate).format("YYYY-MM-DD");
      //  item.fromdate = item.fromdate.concat("T").concat("00:00:00.000Z");
      item.toDate = moment(item.toDate).format("YYYY-MM-DD");
      // item.todate = item.todate.concat("T").concat("23:59:59.000Z");

      console.log(item);

      axios
        .post(
          "Reports/" + this.warehouseStoreId + "/Products/Purchases",
          this.purchaseReportData
        )
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.itemsData = response.data;
            if (response.data) {
              this.itemsData.totalCount = this.itemsData.data.length;
            }
            this.reports = response.data.data;
            this.setLocalReport();
            if (this.reports.length == 0) {
              this.isLoading = false;
              warningToast("NO DATA ", response.data.message);
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        });
    },

    searchOnchange() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getReports();
      this.searchActive = false;
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getReports();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
