<template>
  <div>
    <DarkModeSwitcher />
    <div class="">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page bg-cyan-50 flex flex-col h-screen text-center lg:text-center"
      >
        <div class="lg:ml-20 place-self-center">
          <img
            alt=" "
            class="w-80 h-60 lg:w-auto mt-6"
            src="@/assets/images/access-denied-new.png"
          />
        </div>
        <div class="text-black mt-16 lg:mt-0">
          <div class="intro-x text-6xl font-medium">We are sorry....</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            The page you are trying to access has restricted access.
          </div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            please contact the administrator.
          </div>

          <button
            @click="redirectToPreveious()"
            class="mr-2 btn btn-primary shadow-md text-xs hover:text-white-200 mt-16 mb-1 w-48"
          >
            <ArrowLeftCircleIcon
              class="lg:w-12 lg:h-12 w-6 h-6 mr-2 cursor-pointer"
            />

            Back
          </button>
          <button
            @click="redirectToHome()"
            v-if="isLocation === true"
            class="mr-2 btn btn-primary shadow-md text-xs hover:text-white-200 mt-16 mb-1 w-48"
          >
            <HomeIcon class="lg:w-12 lg:h-12 w-6 h-6 mr-2 cursor-pointer" />

            Home
          </button>
          <button
            @click="logout()"
            class="mr-2 btn btn-primary shadow-md text-xs hover:text-white-200 mt-16 mb-1 w-48"
          >
            <LogOutIcon class="lg:w-12 lg:h-12 w-6 h-6 mr-2 cursor-pointer" />

            Log Out
          </button>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
import router from "../../../router";
import store from "../../../store";
import axios from "../../../services/http-common";
export default {
  data() {
    return {
      baseurl: axios.defaults.baseURL.replace("/api/", "/"),
    };
  },
  created() {},
  computed:{
    isLocation(){
      let permissions = store.state.authUser.permissions;
      if(Object.keys(permissions).length !== 0 && Object.getPrototypeOf(permissions) !== Object.prototype){
        let storedPermission = permissions.split(',');
        var splitArray = [];
            storedPermission.forEach((x)=>{
              var splitData = x.split(".");
              splitArray.push(splitData[1]);
            });
      let uniqueArray = Array.from(new Set(splitArray));
      if(uniqueArray.includes("Locations")){
        return true;
      }else {
        return false;
      }
      }
      return false;

    },
  },
  methods: {
    redirectToPreveious() {
      this.$router.go(-1);
    },
    redirectToHome() {
      router.push("/");
    },

    logout() {
      store.dispatch("authUser/logout").then(() => {
        // this.$router.push("/login");
        var url = window.location.href;
        var splitUrl = url.split("access-denied");
        window.location.href = `${splitUrl[0]}login`;
      });
    },
  },
};
</script>
