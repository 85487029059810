<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex space-x-1">
        <div class="w-64">
          <div
            class="flex justify-center items-center space-x-1 w-full rounded-md"
          >
            <!-- <label
              for="regular-form-1"
              class="px-2 whitespace-nowrap font-semibold"
              >Select Role</label
            > -->

            <div class="w-full" v-if="storedUserRole === 'SuperUser'">
              <v-select
                v-model="selectedroleName"
                placeholder="SELECT ROLE"
                class="style-chooser"
                :options="userRoles"
                :reduce="(name) => name.name"
                label="name"
              />
            </div>

            <div class="w-full" v-else>
              <v-select
                v-model="selectedroleName"
                placeholder="SELECT ROLE"
                class="style-chooser"
                :options="getUserRolesFromPermissions()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex space-x-4 pt-4 w-full">
      <div class="w-full">
        <!-- <h2 class="text-lg font-medium pb-10">Access</h2> -->

        <div class="flex w-full">
          <div
            class="nav-boxed-tabs flex flex-col overflow-scroll"
            style="width: 25%; height: 470px"
          >
            <div
              v-for="(item, i) in policyNames"
              :key="i"
              class="w-full py-2 flex"
              tag="button"
            >
              <div
                @click="selectPolicy(item)"
                class="flex btn w-full mr-2 justify-start"
                :class="
                  selectedPolicyName === item ? 'btn-primary' : 'btn-white'
                "
              >
                <ListIcon style="width: 5%" class="w-6 h-6 mr-2" />
                <!-- <component :is="policyIdentifier(item)" class="w-6 h-6 mr-2">
                </component> -->
                <div class="text-left" style="width: 20%">{{ item }}</div>
              </div>
            </div>
          </div>

          <div class="border bg-gray-200 overflow-scroll" style="width: 75%">
            <div
              class="leading-relaxed px-5 flex flex-col space-y-2 flex-1 bg-gray-200"
            >
              <!-- <div
                class="flex justify-center items-center"
                v-if="viewPermissionItems.length <= 0"
              >
                <div
                  class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
                >
                  Permission Data Not Found
                </div>
              </div>
              <div class="w-full grid grid-cols-4 gap-2">
                <div v-for="(item, i) in viewPermissionItems" :key="i">
                  <PermissonCardView
                    :vmodel="item.selected"
                    :value="item.value"
                    :item="item"
                    :policyName="selectedPolicyName"
                  />
                </div>
              </div> -->

              <div
                class="flex justify-center items-center"
                v-if="selectedroleName == '' || selectedroleName == null"
              >
                <div
                  class="mb-5 border-2 shadow-md py-2 mt-2 font-semibold w-full col-span-12 text-center"
                >
                  Select Role
                </div>
              </div>

              <AllPermission
                :items="permissionItems"
                :roleId="roleId"
                :roleName="selectedroleName"
                :policyName="selectedPolicyName"
                @activiteApi="getApi($event)"
                :active="boolCheck"
              />

              <div class="w-full grid grid-cols-3 gap-4">
                <div v-for="(item, i) in permissionItems" :key="i">
                  <PermissonCard
                    :vmodel="item.selected"
                    :value="item.value"
                    :item="item"
                    :roleId="roleId"
                    :roleName="selectedroleName"
                    :policyName="selectedPolicyName"
                    @activiteApi="getApi($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import axiosReal from "axios";
import Paginate from "vuejs-paginate-next";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";
import { const_txt } from "../../../global-functions/const";

import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-functions/toast";

import {
  currencyFormat,
  percentageFormat,
  temperatureFormat,
  weightFormat,
  removeNullValue,
} from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
// import QRCode from "qrcode";
import Moveable from "vue3-moveable";
import moment from "moment";

import store from "../../../store";
import { policyIdentifier } from "../../../global-functions/PolicyIdentifier.js";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "User Permissions");
  },

  data() {
    return {
      userRoles: [],
      selectedroleName: "",
      selectedPolicyName: "Users",
      policyNames: [],
      checkedData: [],
      formDataPermission: {
        roleId: "",
        roleClaims: [
          {
            type: "",
            value: "",
            selected: false,
          },
        ],
      },
      formDataPermissionDefault: {
        roleId: "",
        roleClaims: [
          {
            type: "",
            value: "",
            selected: false,
          },
        ],
      },
      storedUserRole: store.state.authUser.userRole,
      storedPermission: store.state.authUser.permissions,
      permissionItems: [],
      itemsData: [],
      selected: false,
      roleId: "",
      isLoading: false,
      boolCheck: false,
      viewPermissionItems: [],
    };
  },
  computed: {},
  created() {
    // this.getRolesPermissions("Admin", "Users");

    this.getPolicyNames();
    //this.getPolicyPermissions();
    // this.selectedPolicyName = policyNames[0].cName;
  },
  watch: {
    selectedroleName() {
      console.log(
        "ROLE,POLICY",
        this.selectedroleName,
        this.selectedPolicyName
      );
      if (this.selectedroleName != "" && this.selectedroleName != null) {
        this.getRolesPermissions(
          this.selectedroleName,
          this.selectedPolicyName
        );
      } else {
        //  warningToast("Select User Role");

        this.permissionItems = [];
        // this.getPolicyPermissions();
      }
    },
  },

  methods: {
    getSpace(value) {
      const name = value.replace(/([A-Z])/g, " $1").trim();
      return name;
    },

    policyIdentifier(value) {
      return policyIdentifier(value);
    },
    selectPolicy(policyName) {
      this.selectedPolicyName = policyName;
      //  this.getPolicyPermissions();

      if (this.selectedroleName != "" && this.selectedroleName != null) {
        this.getRolesPermissions(
          this.selectedroleName,
          this.selectedPolicyName
        );
      }
    },

    async getPolicyNames() {
      this.isLoading = true;
      await axios
        .get("Policies")
        .then((response) => {
          if (response.status === 200) {
            if (this.storedUserRole !== "SuperUser") {
              var storedPermission = [];

              if (Array.isArray(this.storedPermission)) {
                storedPermission = this.storedPermission;
              } else {
                storedPermission = this.storedPermission.split(",");
              }

              let resPolicyname = response.data;
              var splitArray = [];
              storedPermission.forEach((x) => {
                var splitData = x.split(".");
                splitArray.push(splitData[1]);
              });
              let uniqueArray = Array.from(new Set(splitArray));
              this.policyNames = resPolicyname.filter((x) =>
                uniqueArray.includes(x)
              );

              if (this.policyNames.includes("RolePermissions")) {
                this.policyNames = this.policyNames.filter(
                  (item) => item !== "RolePermissions"
                );
                this.policyNames.unshift("RolePermissions");

                //       this.getRolesPermissions(
                //   "Admin",
                //   "RolePermissions"
                // );
              }
            } else {
              this.policyNames = response.data;
            }
            this.selectedPolicyName = this.policyNames[0];
            this.isLoading = false;
            this.getUserRoles();
          } else {
            failedToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("err", error);
          this.isLoading = false;
          warningToast(statusMessage(error.status));
        });
    },
    getPolicyPermissions() {
      this.viewPermissionItems = [];
      this.isLoading = true;
      axios
        .get(`Policies/${this.selectedPolicyName}/Permissions/User`)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.viewPermissionItems = response.data;
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getUserRolesFromPermissions() {
      var storedPermission = [];

      if (Array.isArray(this.storedPermission)) {
        storedPermission = this.storedPermission;
      } else {
        storedPermission = this.storedPermission.split(",");
      }

      var splitArray = [];
      storedPermission
        .filter((f) => f.includes("Permissions.RolePermissions."))
        .forEach((x) => {
          var splitData = x.split(".");
          splitArray.push(splitData[2]);
        });
      return splitArray;
    },
    async getUserRoles() {
      this.isLoading = true;
      await axios
        .get("Roles")
        .then((response) => {
          if (response.data.success === true) {
            if (this.storedUserRole !== "SuperUser") {
              if (this.storedUserRole === "Admin") {
                this.userRoles = response.data.data.filter(
                  (x) => x.name !== this.storedUserRole
                );
              } else {
                this.userRoles = response.data.data.filter(
                  (x) => x.name !== this.storedUserRole && x.name !== "Admin"
                );
              }
            } else {
              this.userRoles = response.data.data;
            }

            // this.selectedroleName = this.userRoles[0].name;
            this.isLoading = false;
          } else {
            failedToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getApi(data) {
      this.getRolesPermissions(data.roleName, data.policyName);
    },
    getRolesPermissions(roleName, policyName) {
      this.permissionItems = [];
      this.isLoading = true;
      axios
        .get("Roles/" + roleName + "/Permissions/" + policyName)
        .then((response) => {
          if (response.data.success === true) {
            var storedPermission = [];
            var splitArray = [];

            this.roleId = response.data.data.roleId;
            var permissionItems = response.data.data.roleClaims;

            if (Array.isArray(this.storedPermission)) {
              storedPermission = this.storedPermission;
            } else {
              storedPermission = this.storedPermission.split(",");
            }

            var permissionString = permissionItems[0].value;
            var splitPermission = permissionString.substring(
              0,
              permissionString.lastIndexOf(".")
            );

            var currentStoredPermission = storedPermission
              .filter((f) => f.includes(`${splitPermission}.`))
              .map((x) => {
                return x;
              });

            var intersection = currentStoredPermission.map((m) => {
              return permissionItems.filter((f) => {
                return f.value === m;
              });
            });

            var finalArray = intersection.map((m) => m[0]);

            this.permissionItems = finalArray;
            var result = this.permissionItems.filter(
              (x) => x.selected == false
            );
            if (result.length <= 0) {
              this.boolCheck = true;
            } else {
              this.boolCheck = false;
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
<style>
.flex-1 {
  flex: 0 0 0% !important;
}
</style>
