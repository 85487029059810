<template>
  <div>
    <DarkModeSwitcher />
    <MainColorSwitcher />
    <MobileMenu />
    <TopBar />
    <!-- <div class=" mt-[50px] ml-[200px] z-[100] flex text-white mb-4 items-center bg-red-600">
      <MenuIcon
        v-if="sideMenuHide"
        v-on:mouseover="toogle"
        class="bg-black/80 rounded-md left-0 w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 ml-4 cursor-pointer transition ease-in-out delay-50 hover:scale-150 duration-100"
      />
      <XIcon
        v-else
        @click="toogle"
        class="bg-black/80 rounded-md w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 ml-[185px] cursor-pointer transition ease-in-out delay-50 hover:scale-150 duration-100"
      />
    </div> -->
    <div class="flex overflow-hidden">
      <!-- BEGIN: Side Menu -->
      <!-- @mouseover="sideMenuHide = false" @mouseleave="sideMenuHide = true"   -->
      <nav
        class="side-nav relative md:fixed overflow-y-auto inset-0 select-none"
        :style="getStylesSideNav()"
        @mouseover="sideMenuHide = false"
        @mouseleave="sideMenuHide = true"
      >
        <!-- <div class="flex text-white mb-4 items-center">
          <MenuIcon
            v-if="sideMenuHide"
            v-on:mouseover="toogle"
            class="bg-black/80 rounded-md left-0 w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 ml-4 cursor-pointer transition ease-in-out delay-50 hover:scale-150 duration-100"
          />
          <XIcon
            v-else
            @click="toogle"
            class="bg-black/80 rounded-md w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 ml-[185px] cursor-pointer transition ease-in-out delay-50 hover:scale-150 duration-100"
          />
        </div> -->
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                v-if="menu.viewPermission"
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu bg-slate-200/[0.8]"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown,
                }"
                @click="
                  () => {
                    linkTo(menu, router, $event);
                    activeMenu(menu, menuKey);
                  }
                "
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title" :style="getStylesSideNavList()">
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      v-if="subMenu.viewPermission"
                      :style="
                        subMenu.status ? '' : { 'background-color': 'red' }
                      "
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon text-white">
                        <component :is="subMenu.icon" />
                      </div>
                      <div
                        class="side-menu__title text-white"
                        :style="getStylesSideNavList()"
                      >
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown,
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({
                                    name: lastSubMenu.pageName,
                                  }).path
                            "
                            class="side-menu"
                            :class="{
                              'side-menu--active': lastSubMenu.active,
                            }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>

      <div
        class="content -mb-5"
        :class="
          sideMenuHide === true ? 'md:ml-[105px] ml-0' : 'md:ml-[260px] ml-0'
        "
      >
        <router-view />
      </div>
      <!-- class={active === value ? 'text-green' : 'text-white'} -->
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->

      <!-- <div class="content">
        <router-view />
      
      </div> -->

      <!-- END: Content -->
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { computed, onMounted, provide, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import { useSideMenuStore } from "@/stores/side-menu";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import MainColorSwitcher from "@/components/main-color-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "./index";
import dom from "@left4code/tw-starter/dist/js/dom";
import Footer from "@/global-components/footer/Main.vue";

const route = useRoute();
const router = useRouter();
const formattedMenu = ref([]);
const sideMenuStore = useSideMenuStore();
const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route));
const sideMenuHide = ref(true);

const activeMenu = (menu, menuKey) => {
  // formattedMenu.value[menuKey].activeDropdown = true;
    if(!formattedMenu.value[menuKey].activeDropdown){
      formattedMenu.value[menuKey].activeDropdown = false;
    }else{
      formattedMenu.value.forEach((x, i) => {
      if (i === menuKey) {
        x.activeDropdown = true;
      } else {
        x.activeDropdown = false;
      }
    });
    }

};

provide("forceActiveMenu", (pageName) => {
  route.forceActiveMenu = pageName;

  formattedMenu.value = $h.toRaw(sideMenu.value);
});

watch(
  computed(() => route.path),
  () => {
    delete route.forceActiveMenu;
    formattedMenu.value = $h.toRaw(sideMenu.value);
  }
);

onMounted(() => {
  dom("body").removeClass("error-page").removeClass("login").addClass("main");
  formattedMenu.value = $h.toRaw(sideMenu.value);
});

const toogle = () => {
  if (sideMenuHide.value) {
    setTimeout(() => {
      sideMenuHide.value = false;
    }, 150);
  } else {
    sideMenuHide.value = true;
  }
  console.log("Worked", sideMenuHide.value);
};

const getStylesSideNav = () => {
  if (sideMenuHide.value) {
    return "width: 105px;";
  } else {
    return "width: 260px;";
  }
};
const getStylesSideNavList = () => {
  if (sideMenuHide.value) {
    return "display: none";
  } else {
    return "display: flex";
  }
};

const getStylesContent = () => {
  if (sideMenuHide.value) {
    return "margin-left: 105px;";
  } else {
    return "margin-left: 260px;";
  }
};
</script>

<style scoped>
.side-nav {
  background-image: url("@/assets/images/wh4.webp");
  background-repeat: no-repeat, repeat;
  background-size: auto 100%;
  /* width: 105px !important; */
  /* background-color: red; */
}

.side-nav::-webkit-scrollbar {
  display: none;
}

/* @media (min-width: 770px) {
  .content {
    margin-left: 105px;
    margin-top: 20px;
  }
}
@media (min-width: 1280px) {
  .content {
    margin-top: 20px;
    margin-left: 260px;
  }
} */
</style>
