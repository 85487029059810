import store from "../store";

const permissionHelpers = {
  addItemAccess(policyName) {
    // return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.Create`
    );
  },

  editItemAccess(policyName) {
    // return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.Modify`
    );
  },
  archiveItemAccess(policyName) {
    // return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.Archive`
    );
  },
  restoreItemAccess(policyName) {
    // return false;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.Restore`
    );
  },
  deleteItemAccess(policyName) {
    // return false;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.Delete`
    );
  },

  addStockAccess(policyName) {
    //  return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.AddStock`
    );
  },
  addProductAccess(policyName) {
    return true;
    // return store.getters["authUser/getPermission"](
    //   `Permissions.${policyName}.AddStock`
    // );
  },
  addSupplierProductAccess(policyName) {
    //  return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.AddSupplierProduct`
    );
  },
  addProductVariantsAccess(policyName) {
    return true;
  },
  addDispatchAccess(policyName) {
    //   return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.AddDispatch`
    );
  },

  addSupplierContactAccess(policyName) {
    //   return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.AddSupplierContact`
    );
  },
  addCustomerContactAccess(policyName) {
    //  return true;
    return store.getters["authUser/getPermission"](
      `Permissions.${policyName}.AddCustomerContact`
    );
  },
};

const install = (app) => {
  app.config.globalProperties.$h = permissionHelpers;

  app.config.globalProperties.$roleCheck = (roles) => {
    return store.getters["authUser/roleCheck"](roles);
  };
};

export { install as default, permissionHelpers as permissionHelper };
