<template>
  <LoadingScreen :isLoading="isLoading" />

  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Bin" />

          <div v-if="$h.addItemAccess(policyAccess) == true">
            <a href="javascript:;" class="mr-2 btn btn-primary shadow-md text-xs mt-1 mb-1" @click="openUploadModal()">
              Upload File
              <span class="ml-2">
                <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>
          </div>

          <!-- <UploadFile
            :activeAccess="$h.addItemAccess(policyAccess)"
            @chooseFiles="chooseFiles($event)"
            @onFilePicked="onFilePicked($event)"
            @downloadTemplate="downloadTemplate($event)"
          /> -->
          <viewThreeD @parentMethod="view3D($event)" />
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="setPrintLimit"
          />

          <PrintLabels @parentMethod="printLabels($event)" />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
        </div>

        <ViewArchiveItem @parentMethod="viewArchives($event)" />
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto w-full xl:h-[50vh] lg:h-[50vh] md:h-[50vh] h-[50vh]" id="scroll" ref="scroll" @scroll="handleScroll">
        <table class="table table-report -mt-2 table-auto">
          <thead class="sticky top-0 z-50 bg-[#f1f5f9]">
            <tr class="uppercase whitespace-nowrap">
              <th>QR</th>
              <th>Abbreviation</th>
              <th>DESC</th>
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
                <!-- <ArchiveItem
                  class="ml-3 -mt-1"
                  :activeAccess="$h.archiveItemAccess(policyAccess)"
                  v-if="checkedData.length > 0"
                  @parentMethod="archiveMultipleItem($event)"
                  name="Archive Selected"
                /> -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td>
                <Tippy tag="a" href="javascript:;" class="tooltip w-full" :content="'ABBREVIATION : ' + item.abbreviation">
                  <qrcode-vue
                    @click="showQrModal(item)"
                    :style="item.selected ? 'border:2px solid; border-color: white;' : ''"
                    render-as="svg"
                    :value="item.abbreviation"
                    size="60"
                    level="H"
                  />
                </Tippy>
              </td>

              <td @click="viewItemInfo(item)" class="cursor-pointer" style="color: #1a73e8">
                {{ item.abbreviation }}
              </td>
              <td class="">
                <div class="text-left break-all w-24">
                  {{ item.description }}
                </div>
              </td>
              <td class="" v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <!-- <td class="w-20">
               
              </td> -->
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    content="Locations 3D View"
                    :options="{
                      zIndex: 99999,
                    }"
                    @click="
                      $router.push({
                        path: '/wms/locations',
                        query: {
                          section: `${item.sectionAbbreviation}`,
                          bin: `${item.abbreviation}`,
                        },
                      })
                    "
                  >
                    <Scale3dIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-4 text-primary" />
                  </Tippy>
                  <AddSubItem :activeAccess="$h.addStockAccess(policyAccess)" @parentMethod="addSku($event)" :item="item" name="ADD STOCKS" />
                  <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                  <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)" :item="item" name="Archive" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <NoData :itemsData="itemsData" />
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->

      <!-- BEGIN: Pagination -->

      <PaginationBar
        @changePage="changePage($event)"
        @pageSizeChange="pageSizeChange($event)"
        :itemsData="itemsData"
        :pageCount="pageCount"
        :pageSize="pageSizeView"
        :pageNumber="pagination.pageNumber"
      />

      <!-- END: Pagination -->
    </div>
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addBin" @hidden="addBin = false" backdrop="static" :title="formTitle" @close="addBin = false">
    <ModalBody class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-4 gap-2">
      <div class="col-span-2">
        <label for="regular-form-1" class="form-label">Name</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataBin.name" type="text" class="form-control" name="BinName" placeholder="Storage Location Name" autocomplete="off" />
        </div>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Abbreviation</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataBin.abbreviation"
            type="text"
            class="form-control"
            name="abbreviation"
            placeholder="Storage Location Abbreviation"
            autocomplete="off"
          />
        </div>
      </div>
      <div>
        <label for="regular-form-1" class="form-label">Warehouse</label>
        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="warehouseAbbreviation" type="text" class="form-control" autocomplete="off" disabled />
        </div>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Storage Section</label>
        <v-select
          v-model="formDataBin.storageSectionId"
          placeholder="SELECT"
          :filterable="false"
          @open="onOpenSS"
          @close="onCloseSS"
          class="style-chooser cursor-pointer"
          @search="searchSS"
          :key="name"
          :get-option-label="(option) => option.name"
          :options="storageSections"
          :reduce="(name) => name.storageSectionId"
          label="name"
        >
          <template #list-footer>
            <li v-show="hasNextPageSS" ref="loadSS" class="loader">Loading more...</li>
          </template>
        </v-select>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Storage Type</label>
        <v-select
          v-model="formDataBin.storageTypeId"
          placeholder="SELECT"
          class="style-chooser"
          :filterable="false"
          @open="onOpenST"
          @close="onCloseST"
          @search="searchST"
          :key="name"
          :get-option-label="(option) => option.name"
          :options="storageTypes"
          :reduce="(name) => name.storageTypeId"
          label="name"
        >
          <template #list-footer>
            <li v-show="hasNextPageST" ref="loadST" class="loader">Loading more...</li>
          </template>
        </v-select>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Block Type</label>
        <v-select v-model="formDataBin.blockTypeId" placeholder="SELECT" class="style-chooser" :options="blockTypeItems" :reduce="(name) => name.blockTypeId" label="name" />
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Maximum Weight(kg)</label>

        <input id="vertical-form-1" v-model="formDataBin.maxWeight" type="text" class="form-control" name="maxWeight" placeholder="Maximum Weight" autocomplete="off" />
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Maximum Dimension(mm)</label>
        <input id="vertical-form-1" v-model="formDataBin.maxDimension" type="text" class="form-control" name="maxDimension" placeholder="Maximum Dimension" autocomplete="off" />
      </div>

      <div class="col-span-3">
        <label for="regular-form-1" class="form-label">Description</label>

        <div class="flex flex-col space-y-1">
          <textarea
            id="vertical-form-1"
            v-model="formDataBin.description"
            type="text"
            class="form-control"
            name="BinDescription"
            placeholder="Type here about Storage Location..."
          ></textarea>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Archive Modal -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="text-danger float-right lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6 cursor-pointer" @click="archiveModal = false" />
      </div>

      <div class="p-5 text-center lg:text-lg text-base uppercase font-semibold">Archived Storage Locations</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            autocomplete="off"
            clearable
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>DESC</th>
              <th class="flex justify-start w-24">
                <SelectLabels :allSelected="allSelectedArchive" @selectAll="selectAllArchive($event)" @unSelectAll="unSelectAllArchive($event)" />
                <RestoreItem
                  :activeAccess="$h.restoreItemAccess(policyAccess)"
                  class="ml-3 -mt-1"
                  v-if="checkedDataArchive.length > 0"
                  @parentMethod="restoreMultipleItem($event)"
                  name="Restore Selected"
                />
                <DeleteItem
                  :activeAccess="$h.deleteItemAccess(policyAccess)"
                  class="-mt-1"
                  v-if="checkedDataArchive.length > 0"
                  @parentMethod="deleteMultipleItem($event)"
                  name="Delete Selected"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index" class="w-20">
                {{ item[header.value] }}
              </td>
              <td class="w-24">
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                    {{ item.description }}
                  </Tippy>
                </div>
              </td>

              <td class="table-report__action w-24">
                <div class="flex justify-start items-start">
                  <div class="justify-start items-start mr-3">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Select"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <input id="vertical-form-3" class="form-check-input w-5 h-5 mr-1 bg-slate-100 ml-2" type="checkbox" :value="item" v-model="checkedDataArchive" />
                    </Tippy>
                  </div>
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- <div
        class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
        style="background-color: #164e63"
        v-if="this.archiveData.totalCount > 0"
      >
        <div
          class="w-full sm:w-auto sm:mr-auto"
          style="
            background-color: #e1e7ef;
            border: 2px solid #164e63;
            border-radius: 10px;
          "
        >
          <paginate
            class="text-white"
            :page-count="pageCountArchive"
            :page-range="5"
            :margin-pages="2"
            :click-handler="changePageArchive"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :first-last-button="true"
            :initial-page="1"
            :prev-text="'<'"
            :next-text="'>'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
          >
          </paginate>
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select
          v-model="pageSizeArchive"
          class="w-20 form-select box sm:mt-0 cursor-pointer"
          @change="pageSizeChangeArchive"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> -->

      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: View Bin Info Modal -->

  <MoveableModal :show="viewItemModal" @hidden="viewItemModal = false" backdrop="static" size="modal-sl-85" title="INFO" @close="closeInfo()">
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-2">
        <div class="flex flex-col text-center items-center justify-between space-y-1">
          <qrcode-vue render-as="svg" :value="formDataBin.abbreviation" size="80" level="H" />
          <!-- :value="JSON.stringify(formDataBin)" -->
          <div class="text-xl font-bold mt-2">{{ formDataBin.name }}</div>
        </div>
        <div class="intro-y box p-2 mt-6 sm:mt-5">
          <div class="pt-2 intro-y box grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-4 gap-2">
            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>ABBREVIATION</div>
              </div>
              <div>
                {{ formDataBin.abbreviation != null ? formDataBin.abbreviation : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>STORAGE SECTION</div>
              </div>
              <div>
                {{ formDataBin.sectionName != null ? formDataBin.sectionName : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>STORAGE TYPE</div>
              </div>
              <div>
                {{ formDataBin.storageTypeName != null ? formDataBin.storageTypeName : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>Block TYPE</div>
              </div>
              <div>
                {{ formDataBin.blockTypeId != null ? formDataBin.blockTypeName : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>Maximum Weight</div>
              </div>
              <div>(Kg)</div>
              <div>
                {{ formDataBin.maxWeight != null ? formDataBin.maxWeight : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>Maximum Dimension</div>
              </div>
              <div>(mm)</div>
              <div>
                {{ formDataBin.maxDimension || "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-2">
              <div class="flex items-center font-bold uppercase">
                <div>Description</div>
              </div>
              <div class="break-all">
                {{ formDataBin.description != null ? formDataBin.description : "" }}
              </div>
            </div>
          </div>
        </div>
        <div class="intro-y box p-2 mt-6 sm:mt-5">
          <div
            class="flex flex-nowrap cursor-pointer text-lg font-semibold pl-2 text-info text-center items-center justify-between space-y-2"
            @click="viewBinProducts = !viewBinProducts"
          >
            {{ binViewTitle }}
            <ArrowDownIcon class="lg:w-5 lg:h-5 w-3 h-3 ml-4" v-if="!viewBinProducts" />
            <ArrowUpIcon class="lg:w-5 lg:h-5 w-3 h-3 ml-4" v-else />
          </div>
          <!-- BEGIN: Data List -->

          <div v-if="viewBinProducts" id="labelsScroll" class="intro-y col-span-12">
            <div class="w-full sm:w-auto mt-1 mb-1">
              <div class="relative text-slate-500 border-2 border-primary rounded-md">
                <input type="text" class="form-control box pr-10 lg:h-10 h-8" placeholder="Search..." v-model="existBinSearch" />
                <!-- <SearchIcon class="hover:text-black lg:w-6 lg:h-6 w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" /> -->
                <XIcon v-if="existBinSearch" @click="clearExistBinSearch()" class="hover:text-black lg:w-6 lg:h-6 w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
              </div>
            </div>
            <table class="table table-report -mt-2 table-auto">
              <thead>
                <tr class="uppercase whitespace-nowrap">
                  <th>QR</th>
                  <th>PLU/SKU - Description</th>
                  <th>BARCODE/EAN</th>
                  <th class="" v-for="(header, i) in headersBinExistingProducts" :key="i">
                    {{ header.text }}
                  </th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in existBinDataLocal" :key="i" class="intro-x">
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip w-full"
                    :content="'BARCODE/EAN : ' + item.skuDetail && item.skuDetail.ean ? item.skuDetail.ean : item.skuDetail.skuName"
                  >
                    <qrcode-vue render-as="svg" :value="item.skuDetail && item.skuDetail.ean ? item.skuDetail.ean : item.skuDetail.skuName" size="60" level="H" />
                  </Tippy>
                  <td>
                    {{ item.skuDetail.skuName }} -
                    {{ item.skuDetail.description }}
                  </td>
                  <td>
                    {{ item.skuDetail.ean }}
                  </td>
                  <td v-for="(header, index) in headersBinExistingProducts" :key="index" class="w-20">
                    {{ item[header.value] }}
                  </td>

                  <td>
                    {{ item.expiryDate === null || item.expiryDate === undefined ? "" : momentLocal(item.expiryDate) }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="mb-2 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center" v-if="!(existBinDataLocal && existBinDataLocal.length > 0)">No Data</div>
            <PaginationBarLocal
              @changePage="changePageProduct($event)"
              :itemsData="existBinData"
              :pageCount="pageCountProduct"
              :pageRange="pageRowCountProduct"
              :pageSize="pageSizeProduct"
            />
          </div>

          <!-- END: Data List -->
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
  <!-- END: View Bin Info Modal-->

  <!-- BEGIN: Add  Modal -->

  <Modal
    :show="addCheckedPrintModal"
    @hidden="
      () => {
        addCheckedPrintModal = false;
        printData = '';
      }
    "
    backdrop="static"
  >
    <ModalHeader>
      <h2 class="font-medium text-base mr-auto">Do you want to print the selected label(s) ?</h2>
    </ModalHeader>
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div class="relative col-span-3 pt-2">
          <div class="absolute -top-2.5 left-4 form-switch flex justify-center items-center space-x-2">
            <div class="flex space-x-2">
              <input type="radio" id="localip" :value="true" v-model="choosePrint" />

              <label for="localip">Internal Print</label>
            </div>
            <div class="flex space-x-2">
              <input type="radio" id="publicip" :value="false" v-model="choosePrint" />
              <label for="publicip">Print from web</label>
            </div>
          </div>
          <div class="border rounded-md border-slate-200 border-solid p-4 h-full w-full">
            <div class="flex flex-col space-y-2 w-full">
              <div>
                <h2>{{ this.changePrintType }} : {{ this.defaultIp }}</h2>
              </div>
              <div>
                <label for="regular-form-1" class="form-label">Label Printer IP</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="formDataPrint.ip" type="text" autocomplete="off" class="form-control" placeholder="IP" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Port</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.port" type="text" autocomplete="off" class="form-control" placeholder="Port.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Number of Copies</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.nbrOfCopies" type="number" autocomplete="off" class="form-control" placeholder="Number of copies.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Selected Label(s)</label>
          <div id="labelsScroll" class="flex flex-col space-y-1 overflow-scroll h-36">
            <table>
              <tr class="text-left">
                <th>No</th>
                <th>ABB</th>
                <th>Name</th>
                <th>Section</th>
                <th>Type</th>
              </tr>
              <tr class="text-left" v-for="(item, i) in formDataPrint.bins" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.abbreviation }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.sectionAbbreviation }}</td>
                <td>{{ item.storageTypeAbbreviation }}</td>
              </tr>
            </table>
          </div>
          <div>
            <div class="w-full">
              <div class="flex flex-col space-y-1 overflow-auto">
                <textarea id="vertical-form-1" v-model="printData" type="text" class="form-control h-20 overflow-auto" placeholder=""></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <!-- <button
        @click="confirmClientPrint()"
        type="submit"
        class="btn whitespace-nowrap btn-primary px-1 w-auto mr-1"
      >
      Internal Print
      </button>
      <button
        @click="confirmPrint()"
        type="submit"
        class="btn whitespace-nowrap btn-primary w-auto px-1 mr-1"
      >
      Print from web
      </button> -->
      <button @click="confirmPrint()" type="submit" class="btn whitespace-nowrap btn-primary px-1 w-auto mr-1">Print Labels</button>
      <button
        type="button"
        @click="
          () => {
            addCheckedPrintModal = false;
            printData = '';
          }
        "
        class="btn btn-outline-secondary w-auto mr-1"
      >
        {{ const_txt.CANCEL_BTN }}
      </button>
    </ModalFooter>
  </Modal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addSkuModal" @hidden="addSkuModal = false" backdrop="static" size="modal-sl-80" :title="addSkuModalTitle" @close="addSkuModal = false">
    <div class="p-2">
      <!-- BinExistingProducts Test- <br />
      {{ binExistingProducts.length }} -->

      <!-- BEGIN: Data List -->
      <div class="flex flex-nowrap cursor-pointer text-sm font-semibold pl-2 text-info" @click="viewBinProducts = !viewBinProducts">
        {{ binViewTitle }}
        <ArrowDownIcon class="lg:w-5 lg:h-5 w-3 h-3 ml-4" v-if="!viewBinProducts" />
        <ArrowUpIcon class="lg:w-5 lg:h-5 w-3 h-3 ml-4" v-else />
      </div>

      <div v-if="viewBinProducts" id="labelsScroll" class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>QR</th>
              <th>PLU/SKU - Description</th>
              <th>BARCODE/EAN</th>
              <th class="" v-for="(header, i) in headersBinExistingProducts" :key="i">
                {{ header.text }}
              </th>
              <th>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in existBinDataLocal" :key="i" class="intro-x">
              <Tippy
                tag="a"
                href="javascript:;"
                class="tooltip w-full"
                :content="'BARCODE/EAN : ' + item.skuDetail && item.skuDetail.ean ? item.skuDetail.ean : item.skuDetail.skuName"
              >
                <qrcode-vue render-as="svg" :value="item.skuDetail && item.skuDetail.ean ? item.skuDetail.ean : item.skuDetail.skuName" size="60" level="H" />
              </Tippy>
              <td>{{ item.skuDetail.skuName }} - {{ item.skuDetail.description }}</td>
              <td>{{ item.skuDetail.ean }}</td>
              <td v-for="(header, index) in headersBinExistingProducts" :key="index" class="w-20">
                {{ item[header.value] }}
              </td>

              <td>
                {{ item.expiryDate === null || item.expiryDate === undefined ? "" : momentLocal(item.expiryDate) }}
              </td>
              <td class="table-report__action">
                <div class="flex justify-center items-center">
                  <EditItem :activeAccess="$h.addStockAccess(policyAccess)" @parentMethod="editExistingItem($event)" :item="item" name="Edit" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mb-2 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center" v-if="!(binExistingProducts && binExistingProducts.length > 0)">No Data</div>
        <PaginationBarLocal
          @changePage="changePageProduct($event)"
          :itemsData="existBinData"
          :pageCount="pageCountProduct"
          :pageRange="pageRowCountProduct"
          :pageSize="pageSizeProduct"
        />
      </div>

      <!-- END: Data List -->
    </div>
    <div class="flex justify-end">
      <UploadFile
        buttonName="Upload PLU/SKU as a CSV file"
        :activeAccess="$h.addStockAccess(policyAccess)"
        @chooseFiles="chooseFiles($event)"
        @onFilePicked="onFilePickedSku($event)"
        @downloadTemplate="downloadTemplateSku($event)"
      />
    </div>

    <ModalBody>
      <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
        <div>
          <label for="regular-form-1" class="form-label">Supplier Invoice Number</label>

          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formDataAddSku.invoiceNumber"
              placeholder="SELECT"
              :filterable="false"
              @open="onOpenPO"
              @close="onClosePO"
              class="style-chooser"
              @search="searchPO"
              :key="invoiceNumber"
              :get-option-label="(option) => option.invoiceNumber"
              :options="supInvoices"
              :reduce="(invoiceNumber) => invoiceNumber.invoiceNumber"
              label="invoiceNumber"
            >
              <template #list-footer>
                <li v-show="hasNextPagePO" ref="loadPO" class="loader">Loading more...</li>
              </template>
            </v-select>
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">PLU/SKU - Description</label>

          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formDataAddSkuDescription"
              placeholder="SELECT"
              class="style-chooser"
              :options="supplierProducts"
              :reduce="(description) => description"
              label="description"
            >
              <template v-slot:option="option">
                <div :style="`background-color:${option.color}`" class="-my-2 p-2">
                  <span :class="option.icon"></span>
                  <div class="text-black">
                    {{ option.description }} - ({{ option.expirationDate === null || option.expirationDate === undefined ? "" : momentLocal(option.expirationDate) }})
                  </div>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Supplier Item Code </label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataAddSku.supplierProductNumber"
              type="text"
              class="form-control"
              placeholder="Supplier Item Code"
              autocomplete="off"
              disabled
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Expiry Date</label>

          <div class="">
            <Litepicker
              v-model="formDataAddSku.expirationDate"
              disabled
              :options="{
                autoApply: true,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: 2050,
                  months: true,
                  years: true,
                },
              }"
              class="form-control"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">QTY</label>
          <!-- <div v-if="CheckQTY.option > 0">{{ CheckQTY.option === 1 ? `Available Stock ${CheckQTY.qty}` : `Movable Stock ${CheckQTY.qty}` }}</div> -->
          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAddSku.quantity" type="number" autocomplete="off" class="form-control" placeholder="Quantity" :max="100" :min="1" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Previous LOT Number</label>
          <div class="flex flex-col space-y-1" v-if="isPrevLot === false">
            <input id="vertical-form-1" v-model="formDataAddSku.previousLot" type="text" class="form-control" placeholder="Previous LOT No" autocomplete="off" />
          </div>

          <div class="flex flex-col space-y-1" v-if="isPrevLot === true">
            <v-select
              v-model="formDataAddSku.previousLot"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              :options="prevLots"
              :reduce="(lot) => lot.lot"
              label="lot"
            >
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">LOT Number</label>

          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formDataAddSku.lot"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenLot"
              @close="onCloseLot"
              :options="lots"
              @search="searchLot"
              :key="lot"
              :get-option-label="(option) => option.lot"
              :reduce="(lot) => lot.lot"
              label="lot"
            >
              <template #list-footer>
                <li v-show="hasNextPageLot" ref="loadLot" class="loader">Loading more...</li>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="flex flex-nowrap cursor-pointer text-sm font-semibold pl-2 text-info mt-3" @click="checkQuantity()">
        Add Production Units
        <ArrowDownIcon class="lg:w-5 lg:h-5 w-3 h-3 ml-4" v-if="!viewProductionUnits" />
        <ArrowUpIcon class="lg:w-5 lg:h-5 w-3 h-3 ml-4" v-else />
      </div>

      <div v-if="viewProductionUnits" id="labelsScroll">
        <div class="flex flex-col space-y-4">
          <div v-for="(input, index) in formDataAddSku.productionUnits" :key="`phoneInput-${index}`" class="flex space-x-4 mt-2">
            <div class="grid grid-cols-2 gap-4 intro-y col-span-12 overflow-auto">
              <div>
                <label for="regular-form-1" class="form-label">Batch Number</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="input.batchNumber" type="text" class="form-control" placeholder="Batch Number" autocomplete="off" />
                </div>
              </div>
              <div>
                <label for="regular-form-1" class="form-label">Serial Number</label>
                <div class="flex flex-row space-x-1">
                  <input
                    v-if="input.isEditSerial == false"
                    id="vertical-form-1"
                    v-model="input.serialNumber"
                    type="text"
                    class="form-control"
                    placeholder="Serial Number"
                    autocomplete="off"
                    :disabled="relatedArray.some((x) => x.id === input.serialNumber) !== true ? disabled : ''"
                  />
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    content="Edit"
                    :options="{
                      zIndex: 99999,
                    }"
                  >
                    <EditIcon
                      @click="input.isEditSerial = true"
                      v-if="input.isEditSerial == false && input.serialNumber != '' && input.serialNumber != null"
                      class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 cursor-pointer text-success"
                    />
                  </Tippy>
                  <div v-if="input.isEditSerial == true" class="flex flex-row space-x-1">
                    <input id="vertical-form-1" v-model="input.newserialNumber" type="text" class="form-control" placeholder="Serial Number" autocomplete="off" />
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Change"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <PlusCircleIcon
                        @click="
                          () => {
                            changeSerialNumber(input.serialNumber, input.newserialNumber);
                            input.serialNumber = input.newserialNumber;
                            input.isEditSerial = false;
                          }
                        "
                        v-if="input.newserialNumber != ''"
                        class="w-4 h-4 md:w-5 md:h-5 lg:w-8 lg:h-8 cursor-pointer text-primary"
                      />
                    </Tippy>
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Close"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <XCircleIcon @click="input.isEditSerial = false" class="w-4 h-4 md:w-5 md:h-5 lg:w-8 lg:h-8 cursor-pointer text-danger" />
                    </Tippy>
                  </div>
                </div>
              </div>
              <div class="col-span-2">
                <label for="regular-form-1" class="form-label">Related Serials</label>

                <div class="flex flex-row space-y-1">
                  <input
                    ref="resetfield"
                    id="vertical-form-1"
                    v-model="input.relatedSerials"
                    type="text"
                    :disabled="input.serialNumber != '' ? disabled : ''"
                    class="form-control"
                    placeholder="Related Serials"
                    autocomplete="off"
                  />

                  <button
                    type="button"
                    @click="
                      () => {
                        addRelatedSerials(input.relatedSerials, input.serialNumber);
                        input.relatedSerials = '';
                      }
                    "
                    :disabled="input.serialNumber != '' ? disabled : ''"
                    class="btn btn-outline-secondary w-16 ml-2 h-11"
                  >
                    ADD
                  </button>
                </div>
                <div class="flex space-x-2 mt-3 p-2" v-if="relatedArray.length > 0">
                  <div
                    v-for="(item, i) in relatedArray.filter((x) => {
                      return x.id == input.serialNumber;
                    })"
                    :key="i"
                  >
                    <div class="flex justify-center items-center space-x-1 px-2 py-1 bg-gray-200 rounded-md">
                      <div>{{ item.item }}</div>

                      <div>
                        <XIcon @click="closeProductUnit(item)" class="w-4 h-4 cursor-pointer text-danger" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-center items-center space-x-4">
              <Tippy
                tag="a"
                href="javascript:;"
                class="tooltip"
                content="Add More"
                :options="{
                  zIndex: 99999,
                }"
              >
                <PlusIcon
                  v-if="formDataAddSku.productionUnits.length + 1 <= formDataAddSku.quantity"
                  class="w-12 h-12 text-success"
                  @click="addField(input, formDataAddSku.productionUnits)"
                />
              </Tippy>

              <Tippy
                tag="a"
                href="javascript:;"
                class="tooltip"
                content="Remove"
                :options="{
                  zIndex: 99999,
                }"
              >
                <XCircleIcon class="w-12 h-12 text-danger" v-show="formDataAddSku.productionUnits.length > 1" @click="removeField(index, formDataAddSku.productionUnits)" />
              </Tippy>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeSkuModal()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="confirmAddSku()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>

  <!-- END: Add Modal -->

  <Modal size="modal-sm" :show="qrModal" @hidden="qrModal = false">
    <ModalBody class="flex flex-col justify-center items-center space-y-2 w-full">
      <div class="w-full">
        <qrcode-vue v-if="formDataBin.storageBinId" render-as="svg" :value="formDataBin.abbreviation" size="250" level="H" />
      </div>

      <div>
        <span class="lg:text-lg md:text-base text-base text-black-900 font-medium leading-none">{{ formDataBin.abbreviation }}</span>
      </div>
    </ModalBody>
  </Modal>

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataBin.name" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />

  <RestoreConfirmModal :name="formDataBin.name" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataBin.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->

  <!-- BEGIN: Add  Modal -->

  <MoveableModal
    :show="editExistingItemModal"
    @hidden="editExistingItemModal = false"
    backdrop="static"
    size="modal-sl-90"
    title="Update Stock"
    @close="editExistingItemModal = false"
  >
    <ModalBody class="">
      <div class="grid grid-cols-3 lg:gap-4 md:gap-4 gap-2">
        <div class="w-full">
          <label for="regular-form-1" class="form-label">Warehouse</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataExistingItem.warehouse"
              type="text"
              class="form-control"
              name="warehouse"
              placeholder="Warehouse"
              autocomplete="off"
              disabled
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Section</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataExistingItem.section" type="text" class="form-control" name="section" placeholder="Section" autocomplete="off" disabled />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Bin</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataExistingItem.bin" type="text" class="form-control" name="bin" placeholder="Bin" autocomplete="off" disabled />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">LOT</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataExistingItem.lot" type="text" class="form-control" name="lot" placeholder="Lot" autocomplete="off" disabled />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">PLU/SKU</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataExistingItem.sku" type="text" class="form-control" name="sku" placeholder="PLU/SKU" autocomplete="off" disabled />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Previous Stock</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataExistingItem.previousStock"
              type="number"
              class="form-control"
              name="previousStock"
              placeholder="Previous Stock"
              autocomplete="off"
              disabled
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Current Stock</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="currentStock"
              placeholder="currentStock"
              v-model="formDataExistingItem.currentStock"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="editExistingItemModal = false" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveEditedExistingItem()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <MoveableModal :show="printLimitModal" @hidden="printLimitModal = false" backdrop="static" @close="printLimitModal = false">
    <ModalBody class="grid grid-cols-1">
      <div class="">
        <label for="regular-form-1" class="form-label">Items Limit Per Set</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="printLimit" type="text" class="form-control" name="printLimit" autocomplete="off" placeholder="printLimit" />
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="printLimitModal = false" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="printAllPages()" type="submit" class="btn btn-primary w-24">Print</button>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal :show="allPrintModal" @hidden="allPrintModal = false" backdrop="static" :title="'Print Sets : ' + printPages" @close="allPrintModal = false" size="modal-sl-80">
    <ModalBody class="grid lg:grid-cols-6 md:grid-cols-6 grid-cols-6 lg:gap-4 md:gap-4 gap-2">
      <div v-for="(item, i) in printPages" :key="i" class="col-span-1">
        <PrintButton @parentMethod="getDataForEachPrint($event)" :pageNum="item" />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="allPrintModal = false" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal :show="uploadModal" @hidden="uploadModal = false" backdrop="static" size="modal-sl-80" title="Upload CSV" @close="closeUploadModal()">
    <ModalBody class="flex justify-center space-y-4 w-full">
      <div class="flex flex-col space-y-4 w-full">
        <!-- <div class="w-full">
          <label for="regular-form-1" class="form-label">Warehouse</label>
          <v-select
            v-model="formDataUploadWarehouseId"
            :filterable="false"
            @open="onOpenWarehouse"
            @close="onCloseWarehouse"
            placeholder="SELECT"
            class="style-chooser"
            @search="searchWarehouse"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :options="warehousesList"
            :reduce="(abbreviation) => abbreviation.warehouseId"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageWarehouse" ref="loadWarehouse" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div> -->
        <ArrangeCsv
          csvName="StorageBins"
          :fileUploadAPI="`StorageBins/${warehouseStoreId}/Upload/Csv`"
          :list="BinHeaderList"
          templateAPI="Templates/Bins"
          :warehouseId="warehouseStoreId"
          @getDataFromApi="getDataFromApi($event)"
          :templateShow="true"
          :isWarehouse="true"
        />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <div class="flex space-x-2 justify-center items-center">
        <button type="button" @click="closeUploadModal()" class="btn btn-outline-secondary w-24 h-11">
          {{ const_txt.CANCEL_BTN }}
        </button>

        <!-- <UploadFile
            buttonName = "Upload Bin as a CSV file"
            :activeAccess="$h.addItemAccess(policyAccess)"
            @chooseFiles="chooseFiles($event)"
            @onFilePicked="onFilePicked($event)"
            @downloadTemplate="downloadTemplate($event)"
          /> -->
      </div>
    </ModalFooter>
  </MoveableModal>
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import axiosReal from "axios";
import Paginate from "vuejs-paginate-next";

// Component Changes FYI Priya
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

import router from "../../../router";

import { const_txt } from "../../../global-functions/const";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import QRCode from "qrcode";
import Moveable from "vue3-moveable";
import moment from "moment";

import store from "../../../store";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Storage Bins");
    this.observerSS = new IntersectionObserver(this.infiniteScrollSS);
    this.observerST = new IntersectionObserver(this.infiniteScrollST);
    this.observerPO = new IntersectionObserver(this.infiniteScrollPO);
    this.observerLot = new IntersectionObserver(this.infiniteScrollLot);
    this.observerWarehouse = new IntersectionObserver(this.infiniteScrollWarehouse);
  },

  components: {
    Moveable,
    //Loading,  Component Changes
    paginate: Paginate,
  },
  data() {
    return {
      // isEditSerial: false,
      choosePrint: true,
      ipType: "Local IP",
      defaultIp: "",
      printData: "",
      count: 1,
      BinHeaderList: ["Bin", "Description"],
      storedIp: store.state.authUser.localIp,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      filterArray: [],
      getSerials: "",
      relatedArray: [],
      viewProductionUnits: false,
      allPrintModal: false,
      printLimitModal: false,
      printPages: 0,
      printLimit: 0,
      options: [{ country: "Canada", code: "CA" }],
      config: {
        options: ["US", "Canada"],
      },
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      qrModal: false,

      viewItemModal: false,

      addBin: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataBin: {
        name: "",
        description: "",
        abbreviation: "",
        maxWeight: 0,
        maxDimension: 0,
        storageSectionId: null,
        storageTypeId: null,
        blockTypeId: null,
      },
      formDataBinDefault: {
        name: "",
        description: "",
        abbreviation: "",
        maxWeight: 0,
        maxDimension: 0,
        storageSectionId: undefined,
        storageTypeId: undefined,
        blockTypeId: null,
      },

      rules: {
        name: {
          required,
          minLength: minLength(2),
        },
        description: {
          required,
          minLength: minLength(2),
        },
        abbreviation: {
          required,
          maxLength: maxLength(10),
        },
        storageSectionId: {
          required,
          maxLength: maxLength(10),
        },
        StorageTypeId: {
          required,
          maxLength: maxLength(10),
        },
        maxWeight: {
          required,
          maxLength: maxLength(10),
        },
        maxDimension: {
          required,
          maxLength: maxLength(10),
        },
      },

      headers: [
        { text: "NAME", value: "name" },
        { text: "SECTION", value: "sectionName" },
        { text: "TYPE", value: "storageTypeName" },
        { text: "Block", value: "blockTypeName" },
        { text: "MaxWeight(kg)", value: "maxWeight" },
        { text: "MaxDimension(mm)", value: "maxDimension" },
      ],

      headersFilter: [
        { text: "ABBREVIATION", value: "abbreviation" },
        { text: "NAME", value: "name" },
        { text: "STORAGE SECTION", value: "sectionName" },
        { text: "STORAGE TYPE", value: "storageTypeName" },
        { text: "Block Type", value: "blockTypeName" },
        { text: "DESC", value: "description" },
      ],

      headersBinExistingProductsSku: [
        { text: "plu/sku", value: "skuName" },
        { text: "Description", value: "description" },
      ],

      headersBinExistingProducts: [
        { text: "Invoice No", value: "invoiceNumber" },
        { text: "Supplier", value: "supplierProductNumber" },
        { text: "Supplier Code", value: "supplierCompany" },
        { text: "inStock", value: "inStockQuantity" },
      ],

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "abbreviation",
        sortDirection: "DESC",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "abbreviation",
        sortDirection: "DESC",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,
      warehouses: [
        // {
        //   warehouseId: 6,
        //   abbreviation: "W01",
        //   name: "Warehouse 1",
        //   description: "Warehouse one",
        // },
        // {
        //   warehouseId: 1,
        //   abbreviation: "DFLT",
        //   name: "Default",
        //   description: "",
        // },
      ],
      storageSections: [],
      storageTypes: [],
      checkedData: [],

      addCheckedPrintModal: false,
      formDataPrint: {
        bins: [],
        ip: "192.0.0.0",
        port: "8100",
        nbrOfCopies: 1,
      },
      allSelected: false,

      allItems: [],
      allItemsData: [],

      doc: null,
      isActive: 0,
      addSkuModal: false,
      // formDataAddSkuInvoiceNumber : '',
      // CheckQTY : {
      //   option : 0,
      //   qty : 0
      // },
      formDataAddSkuDescription: "",
      formDataAddSku: {
        warehouse: store.state.authUser.warehouse.warehouseId,
        storageType: "",
        storageSection: "",
        lot: "",
        invoiceNumber: "",
        supplierProductNumber: "",
        binAbbreviation: "",
        quantity: "",
        expirationDate: "",
        previousLot: "",
        productionUnits: [
          {
            batchNumber: "",
            serialNumber: "",
            relatedSerials: [],
            isEditSerial: false,
          },
        ],
      },
      formDataAddSkuDef: {
        warehouse: store.state.authUser.warehouse.warehouseId,
        storageType: "",
        storageSection: "",
        lot: undefined,
        invoiceNumber: undefined,
        supplierProductNumber: "",
        binAbbreviation: "",
        quantity: "",
        expirationDate: "",
        previousLot: undefined,
        productionUnits: [
          {
            batchNumber: "",
            serialNumber: "",
            relatedSerials: [],
            isEditSerial: false,
          },
        ],
      },
      supplierProducts: [],
      supInvoices: [],
      selectedInvoice: {},
      maxQty: 100,
      lots: [],
      blockTypeItems: [],
      binExistingProducts: [],
      viewBinProducts: false,
      formDataBinWarehouse: "",
      addSkuModalTitle: "",
      editExistingItemModal: false,
      formDataExistingItem: {
        lot: "",
        warehouse: "",
        section: "",
        bin: "",
        sku: "",
        quantity: 0,
        previousStock: 0,
        currentStock: 0,
      },
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      splitData: false,

      //new
      multiple: false,
      multipleArchive: false,
      allSelectedArchive: false,
      checkedDataArchive: [],

      policyAccess: "Locations",
      uploadModal: false,

      warehousesList: [],

      uploadcsvfile: null,

      observerSS: null,
      limitSS: 5,
      hasNextPageSS: false,
      totalSSCount: 0,
      isSSSearchResult: false,
      ssPageNumber: 1,
      ssSearchTimer: undefined,

      observerST: null,
      limitST: 5,
      hasNextPageST: false,
      totalSTCount: 0,
      isSTSearchResult: false,
      stPageNumber: 1,
      stSearchTimer: undefined,

      observerPO: null,
      limitPO: 5,
      hasNextPagePO: false,
      totalPOCount: 0,
      isPOSearchResult: false,
      poPageNumber: 1,
      poSearchTimer: undefined,

      observerLot: null,
      limitLot: 5,
      hasNextPageLot: false,
      totalLotCount: 0,
      isLotSearchResult: false,
      lotPageNumber: 1,
      lotSearchTimer: undefined,

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,

      prevLots: [],
      isPrevLot: false,
      hasScrolledToBottom: false,

      pageCountProduct: 0,
      pageRowCountProduct: 10,
      productStart: 0,
      productEnd: 10,
      existBinData: {},
      existBinDataLocal: [],
      existBinSearch: "",
      filterExistBinList: [],
    };
  },
  computed: {
    existBinSearchOnchange() {
      if (this.existBinSearch && this.existBinSearch.trim().length > 0) {
        return this.binExistingProducts.filter((x) => {
          return (
            x?.skuDetail?.ean.toLowerCase().includes(this.existBinSearch.trim()?.toLowerCase()) ||
            x?.sku?.toLowerCase().includes(this.existBinSearch.trim()?.toLowerCase()) ||
            x?.skuDetail?.description.toLowerCase().includes(this.existBinSearch.trim()?.toLowerCase()) ||
            x?.expiryDate?.toLowerCase().includes(this.existBinSearch.trim()?.toLowerCase())
          );
        });
      } else {
        return this.binExistingProducts;
      }
    },
    changePrintType() {
      return this.choosePrint ? "Local IP" : "Public IP";
    },

    binViewTitle() {
      return this.viewBinProducts == true ? "Existing Stocks" : "View Existing Stocks";
    },
    formTitle() {
      return this.titleChange === -1 ? "Add New Storage Bin" : "Edit Storage Bin";
    },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
    formDataAddSkuInvoiceNumber() {
      return this.formDataAddSku.invoiceNumber;
    },
    warehouseId() {
      return this.formDataBin.warehouseId;
    },
    checkLimtSS() {
      return this.ssPageNumber;
    },
    checkLimtST() {
      return this.stPageNumber;
    },

    checkLimtPO() {
      return this.poPageNumber;
    },

    checkLimtLot() {
      return this.lotPageNumber;
    },

    checkLimtWarehouse() {
      return this.whPageNumber;
    },

    // viewModalWh() {
    //   return this.formDataUploadWarehouseId;
    // },
    viewModalSS() {
      return this.formDataBin.storageSectionId;
    },
    viewModalST() {
      return this.formDataBin.storageTypeId;
    },
    viewModalLot() {
      return this.formDataAddSku.lot;
    },
    viewModalPO() {
      return this.formDataAddSku.invoiceNumber;
    },
    getExistBinDataLocal(){
      return this.existBinDataLocal
    }
  },

  created() {
    this.getDataFromApi();
    //  this.getSkus();
    // this.getPurchaseOrders();
    // this.getLots();
  },
  watch: {
    getExistBinDataLocal(){
      this.paginateLoadData()
    },
    choosePrint() {
      this.defaultIp = "";
      return this.setIp();
    },
    formDataAddSkuInvoiceNumber() {
      this.formDataAddSkuDescription = "";
      // this.formDataAddSku.previousLot = "";
      // this.formDataAddSku.lot = "";
      this.getSupplierInvoiceInfo();
    },
    formDataAddSkuDescription() {
      this.formDataAddSku.supplierProductNumber = "";
      this.formDataAddSku.expirationDate = "";
      this.formDataAddSku.quantity = "";
      this.formDataAddSku.previousLot = "";
      this.prevLots = [];
      this.isPrevLot = false;
      this.getSupplierProductInfo();
    },

    checkLimtSS() {
      this.getStorageSections();
    },
    checkLimtST() {
      this.getStorageTypes();
    },

    checkLimtPO() {
      this.getPurchaseOrders();
    },

    checkLimtLot() {
      this.getLots();
    },

    checkLimtWarehouse() {
      this.getDataWarehouses();
    },
    // viewModalWh() {
    //   if (this.formDataUploadWarehouseId === null) {
    //     this.clearWarehouse();
    //   }
    // },
    viewModalSS() {
      if (this.formDataBin.storageSectionId === null) {
        this.clearSS();
      }
    },
    viewModalST() {
      if (this.formDataBin.storageTypeId === null) {
        this.clearST();
      }
    },
    viewModalLot() {
      if (this.formDataAddSku.lot === null) {
        this.clearLot();
      }
    },
    viewModalPO() {
      if (this.formDataAddSku.invoiceNumber === null) {
        this.clearPO();
      }
    },
    existBinSearchOnchange() {
      this.productEnd = 10;
      this.productStart = 0;
      return this.setLocalPagination();
    },
  },

  methods: {
    closeInfo() {
      this.viewItemModal = false;
      this.clearExistBinSearch();
    },
    clearExistBinSearch() {
      this.existBinSearch = "";
    },
    setLocalPagination() {
      this.isLoading = true;

      if (this.existBinData) {
        this.existBinData.totalCount = this.existBinSearchOnchange.length;
      }
      var pageCount = this.existBinSearchOnchange.length / this.pageRowCountProduct;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountProduct = pageCountRound;
      } else {
        this.pageCountProduct = pageCountRound;
      }
      if (this.pageRowCountProduct > this.existBinData.totalCount) {
        this.pageSizeProduct = this.existBinData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountProduct;
      }

      //pageCountProduct
      this.existBinDataLocal = this.existBinSearchOnchange.slice(this.productStart, this.productEnd);
      this.isLoading = false;
    },
    changePageProduct(pageNum) {
      this.productEnd = this.pageRowCountProduct * pageNum;
      this.productStart = this.productEnd - this.pageRowCountProduct;
      this.existBinDataLocal = this.binExistingProducts.slice(this.productStart, this.productEnd);
    },

    handleScroll(el) {
      if (el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight) {
        if (this.itemsData.totalPages > this.pagination.pageNumber) {
          this.hasScrolledToBottom = true;
          this.pagination.pageNumber = this.pagination.pageNumber + 1;
          this.changePage(this.pagination.pageNumber);
          this.$refs.scroll.scroll(0, 0);
        }
      }
    },
    async onOpenSS() {
      if (this.isSSSearchResult) {
        this.ssPageNumber = 1;
      }
      if (this.hasNextPageSS) {
        await this.$nextTick();
        this.observerSS.observe(this.$refs.loadSS);
      }
    },
    onCloseSS() {
      this.observerSS.disconnect();
    },
    async infiniteScrollSS([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.ssPageNumber++;
        this.limitSS;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenST() {
      if (this.isSTSearchResult) {
        this.stPageNumber = 1;
      }
      if (this.hasNextPageST) {
        await this.$nextTick();
        this.observerST.observe(this.$refs.loadST);
      }
    },
    onCloseST() {
      this.observerST.disconnect();
    },
    async infiniteScrollST([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.stPageNumber++;
        this.limitST;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenPO() {
      if (this.isPOSearchResult) {
        this.poPageNumber = 1;
      }
      if (this.hasNextPagePO) {
        await this.$nextTick();
        this.observerPO.observe(this.$refs.loadPO);
      }
    },
    onClosePO() {
      this.observerPO.disconnect();
    },
    async infiniteScrollPO([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.poPageNumber++;
        this.limitPO;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenLot() {
      if (this.isLotSearchResult) {
        this.lotPageNumber = 1;
      }
      if (this.hasNextPageLot) {
        await this.$nextTick();
        this.observerLot.observe(this.$refs.loadLot);
      }
    },
    onCloseLot() {
      this.observerLot.disconnect();
    },
    async infiniteScrollLot([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.lotPageNumber++;
        this.limitLot;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenWarehouse() {
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    addField(value, fieldType) {
      this.count++;
      fieldType.push({
        batchNumber: "",
        serialNumber: "",
        relatedSerials: [],
        isEditSerial: false,
      });
    },
    removeField(index, fieldType) {
      this.count = this.count - 1;
      fieldType.splice(index, 1);
    },
    removeAllField(index, fieldType, count) {
      fieldType.splice(index, count);
      this.count = 1;
    },
    closeProductUnit(item) {
      let idx = Object.values(this.relatedArray).findIndex((v) => v.id === item.id && v.item === item.item);
      console.log(idx);
      this.relatedArray.splice(idx, 1);
    },

    filterData(serialNumber) {
      console.log("res");
      this.relatedArray.filter((x) => {
        return x.id == serialNumber;
      });
    },
    addRelatedSerials(item, serialNumber) {
      if (item == "" || serialNumber == "") {
        if (item == "") {
          warningToast("Related serial number is required");
        }
        if (serialNumber == "") {
          warningToast("Serial number is required");
        }
      } else {
        let isExist = Object.values(this.relatedArray).some((s) => s.id === serialNumber && s.item === item);
        if (isExist == true) {
          warningToast("Related Serial already added");
        } else {
          var formData = {
            id: serialNumber,
            item: item,
          };
          this.relatedArray = [...this.relatedArray, formData];
        }
      }
    },
    changeSerialNumber(oldname, newname) {
      this.relatedArray.forEach((x) => {
        if (x.id === oldname) {
          x.id = newname;
        }
      });
      console.log(this.relatedArray);
    },
    chooseFiles() {
      const fileInput = document.getElementById("fileInput");
      fileInput.value = "";
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      this.uploadcsvfile = event.target.files[0];
      if (this.uploadcsvfile == null) {
        if (this.uploadcsvfile == null) {
          warningToast("Csv file Required");
        }
      } else {
        let formData = new FormData();

        formData.append("File", this.uploadcsvfile);
        formData.append("Name", this.uploadcsvfile.name);

        this.isLoading = true;
        axios
          .post("StorageBins/" + this.warehouseStoreId + "/Upload/Csv", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
        //this.formDataUploadWarehouseId = "";
        this.uploadcsvfile = null;
      }
    },

    downloadTemplate() {
  this.isLoading = true;
  axios
    .get("Templates/Bins", { responseType: 'blob' })  
    .then((response) => {
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'templateSupplier.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();  
      this.isLoading = false;
    })
    .catch((error) => {
      warningToast(statusMessage(error.response.status));
      this.isLoading = false;
    });
},
    openUploadModal() {
      this.uploadModal = true;
      this.getDataWarehouses();
    },
    closeUploadModal() {
      this.uploadModal = false;
      //this.formDataUploadWarehouseId = "";
      this.uploadcsvfile = null;
    },

    clearWarehouse() {
      this.warehousesList = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getDataWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehousesList = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getDataWarehouses(search.trim());
        }
      }, 2000);
    },

    getDataWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          this.isWhSearchResult = !!search;
          this.warehousesList =
            this.isWhSearchResult !== true
              ? Array.from(new Set([...this.warehousesList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;

          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    permissionStatus(val) {},

    onFilePickedSku(uploadedfile) {
      // this.fileName = uploadedfile.name;
      let formData = new FormData();

      formData.append("file", uploadedfile);

      this.isLoading = true;
      axios
        .post("StorageBins/" + this.warehouseStoreId + "/UploadStock/Csv", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;

            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
   
    downloadTemplate() {
  this.isLoading = true;
  axios
    .get("Templates/BinsStocks", { responseType: 'blob' })  
    .then((response) => {
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'templateSupplier.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();  
      this.isLoading = false;
    })
    .catch((error) => {
      warningToast(statusMessage(error.response.status));
      this.isLoading = false;
    });
},
    //here we are getting the DATA(object) as the parameter from the CHILD through emit
    // and settin it to the pagination obects values and make the api call
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    view3D() {
      router.push("locations");
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    getSupplierProductInfo() {
      this.prevLots = [];
      this.isPrevLot = false;
      let item = this.formDataAddSkuDescription;

      if (item != "SELECT") {
        var result = this.supplierProducts.filter((obj) => {
          return obj.description === item.description && obj.expirationDate === item.expirationDate;
        });
        console.log("result", result);

        if (result.length > 0) {
          this.formDataAddSku.supplierProductNumber = result[0].supplierProductNumber;
          // this.maxQty = result[0].quantity;
          let qty = result[0].quantity;
          let utilqty = result[0].utilizedQuantity;
          let availableQty = qty - utilqty;

          if (qty >= availableQty) {
            this.formDataAddSku.quantity = availableQty;
          }
          if (availableQty === 0) {
            this.formDataAddSku.quantity = 0;
            new Promise(async () => {
              let prevMomentRes = await axios.get(`Movements/Available/${result[0].purchaseProductId}`);
              if (prevMomentRes?.data?.data != null && prevMomentRes?.data?.success === true) {
                this.isPrevLot = true;
                this.prevLots = prevMomentRes?.data?.data;
              } else {
                this.isPrevLot = false;
                this.formDataAddSku.previousLot = "";
              }
            });
          }

          this.formDataAddSku.expirationDate =
            result[0].expirationDate === null || result[0].expirationDate === undefined ? "" : moment(result[0].expirationDate).format("YYYY-MM-DD");
        }
      } else {
        warningToast("Please Select Product");
      }
    },
    getSupplierInvoiceInfo() {
      let item = this.formDataAddSku.invoiceNumber;
      console.log("invoiceNumber", item);
      if (item != "SELECT" && item != "" && item != null) {
        this.isLoading = true;
        axios
          .get(`PurchaseInvoices/${this.warehouseAbbreviation}/${item}`)
          .then((response) => {
            if (response.data.success === true) {
              this.isLoading = false;
              if (response.data.data.products) {
                this.supplierProducts = response.data.data.products;
                this.supplierProducts.forEach((m) => {
                  let actualqty = m.quantity - m.utilizedQuantity;
                  if (m.quantity >= actualqty) {
                    m["color"] = "#f59e0d";
                  }
                  if (m.utilizedQuantity <= 0) {
                    m["color"] = "#90ee90";
                  }
                  if (actualqty <= 0) {
                    m["color"] = "#ff7376";
                  }
                });
                console.log(this.supplierProducts);
              } else {
                this.supplierProducts = [];
              }

              this.selectedInvoice = response.data;
              console.log("selectedInvoice", this.selectedInvoice);
              console.log("supplierProducts", this.supplierProducts);
            } else {
              this.isLoading = false;
              warningToast("No Products Available");
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
      } else {
        this.supplierProducts = [];
      }
    },
    async getBlockType() {
      await axios
        .get("BlockTypes/" + this.warehouseStoreId)
        .then((response) => {
          this.blockTypeItems = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    onDragInfo({ transform }) {
      let targetInfo = this.$refs.targetInfo;
      targetInfo.style.transform = transform;
    },
    onDragAddBin({ transform }) {
      let targetAddBin = this.$refs.targetAddBin;
      targetAddBin.style.transform = transform;
    },
    onDragAddSku({ transform }) {
      let targetAddSku = this.$refs.targetAddSku;
      targetAddSku.style.transform = transform;
    },
    clearLot() {
      this.lots = [];
      this.lotPageNumber = 1;
      this.limitLot = 5;
      this.getLots("");
    },

    searchLot(search) {
      clearInterval(this.lotSearchTimer);
      this.lotSearchTimer = setTimeout(() => {
        if (search) {
          this.lots = [];
          this.lotPageNumber = 1;
          this.limitLot = this.totalLotCount;
          this.getLots(search.trim());
        }
      }, 2000);
    },
    getLots(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.lotPageNumber,
        pageSize: this.limitLot,
        search: search,
      };
      axios
        .post("Movements/New/Pagination/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isLotSearchResult = !!search;
            var originalItems =
              this.isLotSearchResult !== true ? Array.from(new Set([...this.lots, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse) : response.data.data.items;

            this.lots = originalItems.filter((x) => x.invoiceNumber == null);

            this.hasNextPageLot = response.data.data.hasNextPage;
            this.totalLotCount = response.data.data.totalCount;

            if (response.data.data.length == 0) {
              warningToast("Please Create Lots");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    clearPO() {
      this.supInvoices = [];
      this.poPageNumber = 1;
      this.limitPO = 5;
      this.getPurchaseOrders("");
    },

    searchPO(search) {
      clearInterval(this.poSearchTimer);
      this.poSearchTimer = setTimeout(() => {
        if (search) {
          this.supInvoices = [];
          this.poPageNumber = 1;
          this.limitPO = this.totalPOCount;
          this.getPurchaseOrders(search.trim());
        }
      }, 2000);
    },
    getPurchaseOrders(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.poPageNumber,
        pageSize: this.limitPO,
        search: search,
      };
      axios
        .post("PurchaseInvoices/Paginated/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isPOSearchResult = !!search;
            this.supInvoices =
              this.isPOSearchResult !== true
                ? Array.from(new Set([...this.supInvoices, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPagePO = response.data.data.hasNextPage;
            this.totalPOCount = response.data.data.totalCount;
            if (this.supInvoices.length == 0) {
              warningToast("Please Create a Supplier Invoice");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
        });
    },

    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "QR", dataKey: "qr" },
              { title: "Abbreviation", dataKey: "abbreviation" },
              { title: "Name", dataKey: "name" },
              { title: "Storage Section", dataKey: "sectionName" },
              { title: "Storage Type", dataKey: "storageTypeName" },
              { title: "Maximum Weight", dataKey: "maxWeight" },
              { title: "Maximum Dimension", dataKey: "maxDimension" },
              { title: "Description", dataKey: "description" },
              { title: "ID", dataKey: "storageBinId" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = await this.getItemsWithQR(items);

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              columnStyles: {
                1: {
                  rowHeight: 1,
                  cellWidth: 1,
                },
              },
              margin: { left: 0.5, top: 0.5 },
              didDrawCell: this.didDrawCell,
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
              this.docs.setPage(i);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${i} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    getItemsWithQR(list) {
      return new Promise((resolve, reject) => {
        try {
          var data = [];
          let i = 0;
          list.forEach(async (el) => {
            i += 1;
            var obj = {
              no: i,
              abbreviation: el.abbreviation,
              name: el.name,
              sectionName: el.sectionName,
              storageTypeName: el.storageTypeName,
              maxWeight: el.maxWeight,
              maxDimension: el.maxDimension,
              description: el.description,
              storageBinId: el.storageBinId,
            };
            obj.qrData = await QRCode.toDataURL(JSON.stringify(el.abbreviation));
            data.push(obj);
          });
          resolve(data);
        } catch (error) {
          reject([]);
        }
      });
    },

    didDrawCell(data) {
      if (data.column.dataKey === "qr" && data.cell.section === "body") {
        var dim = data.cell.height - data.cell.padding("vertical");
        var textPos = data.cell;

        if (data.row && data.row.raw && data.row.raw.qrData) {
          this.docs.addImage(data.row.raw.qrData, textPos.x + 0.05, textPos.y + 0.04, dim, dim);
        }
      }
    },
    setPrintLimit() {
      this.printLimit = this.itemsData.totalCount;
      this.printLimitModal = true;
    },
    async printAllPages() {
      // var printLimit = const_txt.BIN_PRINT_LIMIT;
      var printLimit = this.printLimit;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        // alert(pageCount);
        this.printPages = pageCount;
        this.allPrintModal = true;
        // for (var i = 1; i <= pageCount; i++) {
        //   await this.getDataForEachPrint(i);
        // }
      } else {
        this.getAllDataFromApi();
      }
    },

    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: this.printLimit,
        // pageSize: const_txt.BIN_PRINT_LIMIT,
      };
      //this.isLoading = true;
      await axios
        .post(`StorageBins/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.isLoading = false;
            this.pdfPrintEach(items);
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;

          failedToast(error);
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },
    pdfPrint() {
      this.setReports().then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports().then((res) => {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Storage Bin " + generateDate}.pdf`);
      });
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },
    csvDownload() {
      if (this.isActive == 4) {
        var items = this.checkedData;
      } else if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      removeNullValueObjectArray(items);
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Storage Bin " + generateDate;

        var csvItems = [];

        var csvHeaders = {
          1: "Abbreviation",
          2: "Name",
          3: "Storage Section",
          4: "Storage Type",
          5: "Maximum Weight",
          6: "Maximum Dimension",
          7: "Description",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.abbreviation,
            2: item.name,
            3: item.sectionName,
            4: item.storageTypeName,
            5: item.maxWeight,
            6: item.maxDimension,
            7: item.description,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post(`StorageBins/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;

            removeNullValueObjectArray(this.allItems);

            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },

    showQrModal(item) {
      this.test = JSON.stringify({
        name: item.name,
        description: item.description,
        abbreviation: item.abbreviation,
        warehouseName: item.warehouseName,
      });
      removeNullValue(item);
      this.formDataBin = Object.assign({}, item);
      this.qrModal = true;
      console.log(this.formDataBin);
    },

    viewItemInfo(item) {
      removeNullValue(item);
      this.formDataBin = Object.assign({}, item);
      this.getExistingBinProducts();
      this.viewBinProducts = false;
      this.viewItemModal = true;
    },

    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    selectAllArchive() {
      this.allSelectedArchive = true;
      this.checkedDataArchive = [];
      for (var i = 0; i < this.archiveItems.length; i++) {
        this.checkedDataArchive[i] = this.archiveItems[i];
      }
    },
    unSelectAllArchive() {
      this.allSelectedArchive = false;
      this.checkedDataArchive = [];
    },

    confirmPrint() {
      if (this.choosePrint) {
        this.confirmClientPrint();
      } else {
        if (this.formDataPrint.ip == "" || this.formDataPrint.port == "" || this.formDataPrint.nbrOfCopies == "") {
          if (this.formDataPrint.ip == "") {
            warningToast("IP Required");
          }
          if (this.formDataPrint.port == "") {
            warningToast("Port Required");
          }
          if (this.formDataPrint.nbrOfCopies == "") {
            warningToast("Num of Copies Required");
          }
        } else {
          this.isLoading = true;
          axios
            .post("StorageBins/PrintBins", this.formDataPrint)
            .then((response) => {
              if (response.data.success === true) {
                successToast(response.data.message);
                //         if (
                //   this.formDataPrint !== null ||
                //   this.formDataPrint.ip !== null ||
                //   this.formDataPrint.ip !== undefined ||
                //   this.formDataPrint.ip !== ""
                // ) {
                //   store.dispatch("authUser/setlocalIp", this.formDataPrint.ip);
                // }
                this.isLoading = false;
              } else {
                failedToast(response.data.message);
                this.isLoading = false;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
          this.addCheckedPrintModal = false;
          this.checkedData = [];
          this.allSelected = false;
        }
      }
    },

    confirmClientPrint() {
      this.$printerHub.connect({
        host: this.formDataPrint.ip,
        port: this.formDataPrint.port,
        timeout: 3000,
      });

      let lastValues = this.formDataPrint.ip.split(".")[3];
      if (this.formDataPrint !== null) {
        store.dispatch("authUser/setlocalIp", lastValues);
      }

      let selectedLables = this.checkedData;
      let maxLables = 3;
      let totalLables = selectedLables.length;
      let totalBatches = Math.ceil(totalLables / maxLables);
      let positions = [
        {
          l1: "FT53",
          l2: "FT82",
        },
        {
          l1: "FT330",
          l2: "FT360",
        },
        {
          l1: "FT602",
          l2: "FT632",
        },
      ];
      for (let batch = 1; batch <= totalBatches; batch++) {
        let start = (batch - 1) * maxLables;
        let end = batch * maxLables;
        let batchLabels = selectedLables.slice(start, end);

        //         ^XA
        // ^LS0,^LT8
        // ^FT53,192^BQN,2,8
        // ^FH^FDLA,1-1-A^FS
        // ^FT82,198^CFA,30^FD1-1-A^FS
        // ^PQ1,0,1,Y

        // ^FT330,192^BQN,2,8
        // ^FH^FDLA,1-1-A^FS
        // ^FT360,198^CFA,30^FD1-1-A^FS
        // ^PQ1,0,1,Y

        // ^FT602,192^BQN,2,8
        // ^FH^FDLA,1-1-A^FS
        // ^FT632,198^CFA,30^FD1-1-A^FS
        // ^PQ1,0,1,Y
        // ^XZ
        let query = `^XA^LS0,^LT8`;
        var data;
        setTimeout(() => {
          for (let i = 0; i < this.formDataPrint.nbrOfCopies; i++) {
            for (let position = 0; position < batchLabels.length; position++) {
              // batchLabels.forEach((bin) => {
              data =
                this.printData === ""
                  ? (query += `^${positions[position].l1},192^BQN,2,8
                    ^FH\^FDLA,${batchLabels[position].abbreviation}^FS
                    ^${positions[position].l2},198^CFA,30^FD${batchLabels[position].abbreviation}^FS
                    ^PQ${this.formDataPrint.nbrOfCopies},0,1,Y`)
                  : this.printData;

              this.$printerHub.write(data + "^XZ");

              // });
            }
          }
          console.log("BIN : ", data + "^XZ");
        }, 2000);

        // if (
        //   this.formDataPrint !== null ||
        //   this.formDataPrint.ip !== null ||
        //   this.formDataPrint.ip !== undefined ||
        //   this.formDataPrint.ip !== ""
        // ) {
        //   store.dispatch("authUser/setlocalIp", this.formDataPrint.ip);
        // }
        this.addCheckedPrintModal = false;
        this.checkedData = [];
      }
    },

    setIp() {
      if (this.choosePrint) {
        let config = this.$printerHub.getConfig();
        if (config.host) {
          var lastValue = localStorage.getItem("localIp") !== null ? JSON.parse(localStorage.getItem("localIp")) : "";
          var host = config.host;
          console.log("localIp", host);
          var ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          if (ipRegex.test(host)) {
            var split = host.split(/\.(?=[^\.]+$)/);
            this.formDataPrint.ip = split[0] + "." + lastValue;
          } else {
            this.formDataPrint.ip = host + lastValue;
          }

          this.formDataPrint.port = config.port;
          this.defaultIp = config.host;

          successToast("Internal print configuration selected");
        } else {
          failedToast("Local IP is not conneced, please check your configuration");
        }
      } else {
        this.getDefaultIP();
        this.formDataPrint.port = 8100;
        successToast("Print for web configuration selected");
      }
    },

    printLabels() {
      this.setIp();
      this.formDataPrint.bins = [];
      if (this.checkedData.length > 0) {
        this.addCheckedPrintModal = true;
        for (var i = 0; i < this.checkedData.length; i++) {
          this.formDataPrint.bins[i] = this.checkedData[i];
        }
      } else {
        warningToast("Please Select Storage Bin");
      }
    },

    getDefaultIP() {
      this.isLoading = true;
      axiosReal
        .get("https://api.ipify.org/")
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.defaultIp = response.data;
            var host = response.data;
            var split = host.split(/\.(?=[^\.]+$)/);
            this.formDataPrint.ip = host;
          } else {
            this.isLoading = false;
            failedToast("something went wrong in getting public IP");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    selectRemove() {
      // console.log("Workeddd");
    },

    clearSS() {
      this.storageSections = [];
      this.ssPageNumber = 1;
      this.limitSS = 5;
      this.getStorageSections("");
    },

    searchSS(search) {
      clearInterval(this.ssSearchTimer);
      this.ssSearchTimer = setTimeout(() => {
        if (search) {
          this.storageSections = [];
          this.ssPageNumber = 1;
          this.limitSS = this.totalSSCount;
          this.getStorageSections(search.trim());
        }
      }, 2000);
    },

    async getStorageSections(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.ssPageNumber,
        pageSize: this.limitSS,
        search: search,
      };
      await axios
        .post("StorageSections/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          this.isSSSearchResult = !!search;
          this.storageSections =
            this.isSSSearchResult !== true
              ? Array.from(new Set([...this.storageSections, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageSS = response.data.data.hasNextPage;
          this.totalSSCount = response.data.data.totalCount;
          if (this.storageSections.length == 0) {
            warningToast("Please Add Items in Storage Section");
          } else {
            //  console.log("WH", store.state.authUser.warehouse.warehouseId);
            this.storageSections = this.storageSections.filter(function (x) {
              return x.warehouseId == store.state.authUser.warehouse.warehouseId;
            });
          }
        })
        .catch((error) => {
          //   this.addBin = false;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    async getWarehouses() {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      await axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          this.isWhSearchResult = !!search;
          this.warehouses = this.isWhSearchResult !== true ? [...this.warehouses, ...response.data.data.items] : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          //CHANGES
          // if (this.warehouses.length > 0) {
          //   if (this.titleChange === -1) {
          //     this.formDataSection.warehouseId = this.warehouses[0].warehouseId;
          //   }
          // } else
          if (this.warehouses.length == 0) {
            warningToast("Please Add Items in Warehouse");
            // this.addSection = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearST() {
      this.storageTypes = [];
      this.stPageNumber = 1;
      this.limitST = 5;
      this.getStorageTypes("");
    },

    searchST(search) {
      clearInterval(this.stSearchTimer);
      this.stSearchTimer = setTimeout(() => {
        if (search) {
          this.storageTypes = [];
          this.stPageNumber = 1;
          this.limitST = this.totalSTCount;
          this.getStorageTypes(search.trim());
        }
      }, 2000);
    },
    async getStorageTypes(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.stPageNumber,
        pageSize: this.limitST,
        search: search,
      };
      await axios
        .post("StorageTypes/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSTSearchResult = !!search;
            this.storageTypes =
              this.isSTSearchResult !== true
                ? Array.from(new Set([...this.storageTypes, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageST = response.data.data.hasNextPageST;
            this.totalSTCount = response.data.data.totalCount;
            if (this.storageTypes.length == 0) {
              warningToast("Please Add Items in Storage Type");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //Normal Search - Start

    searchOnchange(searchData) {
      //searchData added as the function parameter, emit from the child component searchBar
      this.searchData = searchData?.toUpperCase(); //newly added line
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      // this is also a function activating from child
      // but we are not passing the parameter searchData here
      // cuz we already get that value by searchOnchange()
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      // this is also a function activating from child
      // but we are not passing the parameter searchData here
      // cuz we already get that value by searchOnchange()
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    //Normal Search - END

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive?.toUpperCase();
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive?.toUpperCase();
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataBin = Object.assign({}, this.formDataBinDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(`StorageBins/${this.warehouseAbbreviation}/Paginated`, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.activateModal();
    },
    activateModal() {
      this.getStorageTypes("");
      this.getStorageSections("");
      this.getBlockType();
      this.getWarehouses();
      this.addBin = true;
    },
    closeForm() {
      this.addBin = false;
    },
    closeSkuModal() {
      this.prevLots = [];
      this.isPrevLot = false;
      this.removeAllField("", this.formDataAddSku.productionUnits, this.count);
      this.addField("", this.formDataAddSkuDef.productionUnits);
      this.viewProductionUnits = false;
      this.addSkuModal = false;
    },
    checkQuantity() {
      if (this.formDataAddSku.productionUnits.length + 1 <= this.formDataAddSku.quantity) {
        this.viewProductionUnits = !this.viewProductionUnits;
      } else {
        failedToast("Please add quantity");
        this.viewProductionUnits = false;
      }
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataBin = Object.assign({}, item);
      console.log("  this.formDataBin", this.formDataBin);
      this.activateModal();
    },
    editExistingItem(item) {
      this.formDataExistingItem.lot = item.lot;
      this.formDataExistingItem.warehouse = item.warehouse;
      this.formDataExistingItem.section = item.section;
      this.formDataExistingItem.bin = item.bin;
      this.formDataExistingItem.sku = item.sku;
      this.formDataExistingItem.quantity = 0;
      this.formDataExistingItem.currentStock = 0;
      this.formDataExistingItem.previousStock = item.quantity - item.utilizedQuantity;

      this.editExistingItemModal = true;
    },
    saveEditedExistingItem() {
      this.formDataExistingItem.quantity = this.formDataExistingItem.previousStock - this.formDataExistingItem.currentStock;
      console.log(this.formDataExistingItem);
      axios
        .post("Stocks/Adjustment", this.formDataExistingItem)
        .then((response) => {
          console.log(response);
          this.editExistingItemModal = false;
          this.getExistingBinProducts();
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    addSku(item) {
      this.formDataBin = Object.assign({}, item);
      this.addSkuModalTitle = "Add Stocks to " + this.formDataBin.abbreviation;
      this.formDataAddSku = Object.assign({}, this.formDataAddSkuDef);

      this.viewBinProducts = false;
      this.getExistingBinProducts();
      this.getPurchaseOrders("");
      this.getLots("");

      this.addSkuModal = true;
    },



    async getExistingBinProducts() {
      this.isLoading = true;
      await axios
        .get("MemoryStocks/Bins/Products", {
          params: {
            warehouse: this.formDataBin.warehouseAbbreviation,
            section: this.formDataBin.sectionAbbreviation,
            bin: this.formDataBin.abbreviation,
          },
        })
        .then((response) => {
          this.binExistingProducts = response.data;
          this.existBinData = response.data;
          this.setLocalPagination();
          if (this.binExistingProducts?.length > 0) {
            this.viewBinProducts = true;
          }
          this.isLoading = false;
        });
    },

    async paginateLoadData() {
      for (var i = 0; i < this.existBinDataLocal.length; i++) {
            this.existBinDataLocal[i].invoiceNumber = "";
            this.existBinDataLocal[i].supplierProductNumber = "";

            var movementId = this.existBinDataLocal[i].movementId;
            if(movementId){
            this.isLoading = true;
            axios
              .get("Movements", {
                params: {
                  movementId: movementId,
                },
              })
              .then((response) => {
                var movement = response.data.data;

                let index = this.existBinDataLocal.findIndex((obj) => {
                  return obj.movementId === movement.movementId;
                });
                this.existBinDataLocal[index].invoiceNumber = movement.invoiceNumber;
                this.existBinDataLocal[index].supplierProductNumber = movement.supplierProductNumber;
                this.isLoading = false;
                var skuId = this.existBinDataLocal[index].skuId;

                if(skuId){
                axios
                  .get("SupplierProducts/Products/" + skuId)
                  .then((response) => {
                    var supplierProducts = response.data.data;
                    let supplierProduct = supplierProducts.filter((obj) => {
                      return obj.productNumber === movement.supplierProductNumber;
                    });

                    this.existBinDataLocal[index].supplierCompany = supplierProduct[0].supplierCompany;
                    this.isLoading = false;
                  })
                  .catch((error) => {
                    this.isLoading = false;
                    warningToast(statusMessage(error.response.status));
                  });
                }
              });
            }
          }
    },
    confirmAddSku() {
      this.formDataAddSku.warehouse = this.formDataBin.warehouseAbbreviation;
      this.formDataAddSku.storageType = this.formDataBin.storageTypeAbbreviation;
      this.formDataAddSku.storageSection = this.formDataBin.sectionAbbreviation;
      this.formDataAddSku.binAbbreviation = this.formDataBin.abbreviation;

      if (this.formDataAddSku.quantity == "" || this.formDataAddSku.lot == "" || this.formDataAddSku.invoiceNumber == "") {
        if (this.formDataAddSku.quantity == "") {
          warningToast("Quantity Required");
        }
        // if (this.formDataAddSku.supplierProductNumber == "") {
        //   warningToast("PLU/SKU -Supplier Product Required");
        // }
        if (this.formDataAddSku.lot == "") {
          warningToast("LOT Number Required");
        }
        if (this.formDataAddSku.invoiceNumber == "") {
          warningToast("Invoice Number Required");
        }
      } else {
        for (var i = 0; i < this.formDataAddSku.productionUnits.length; i++) {
          this.formDataAddSku.productionUnits[i].relatedSerials = this.relatedArray
            .filter((x) => {
              delete this.formDataAddSku.productionUnits[i].isEditSerial;
              delete this.formDataAddSku.productionUnits[i].newserialNumber;
              return x.id == this.formDataAddSku.productionUnits[i].serialNumber;
            })
            .map((y) => {
              return y.item;
            });
        }

        console.log(this.formDataAddSku);

        this.isLoading = true;
        axios
          .post("Movements", this.formDataAddSku)
          .then((response) => {
            if (response.data.success === true) {
              this.isLoading = false;
              this.getExistingBinProducts();
              this.clearLot();
              //  this.addSkuModal = false;
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              warningToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
        this.prevLots = [];
        this.isPrevLot = false;
        this.formDataAddSku = Object.assign({}, this.formDataAddSkuDef);
        this.removeAllField("", this.formDataAddSku.productionUnits, this.count);
        this.addField("", this.formDataAddSkuDef.productionUnits);
      }
    },

    saveForm() {
      if (
        this.formDataBin.name == "" ||
        this.formDataBin.abbreviation == "" ||
        this.formDataBin.storageTypeId == "" ||
        this.formDataBin.storageSectionId == "" ||
        this.formDataBin.storageTypeId == "SELECT" ||
        this.formDataBin.storageSectionId == "SELECT"
      ) {
        if (this.formDataBin.name == "") {
          warningToast("Name Required");
        }
        if (this.formDataBin.abbreviation == "") {
          warningToast("Abbreviation Required");
        }
        if (this.formDataBin.storageTypeId == "" || this.formDataBin.storageTypeId == "SELECT") {
          warningToast("Storage Type Required");
        }
        if (this.formDataBin.storageSectionId == "" || this.formDataBin.storageSectionId == "SELECT") {
          warningToast("Storage Section Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("StorageBins", this.formDataBin)
            .then((response) => {
              if (response.data.success === true) {
                this.addBin = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataBin.storageBinId;
          this.isLoading = true;
          axios
            .put("StorageBins/" + itemId, this.formDataBin)
            .then((response) => {
              if (response.data.success === true) {
                this.addBin = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataBin = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    archiveMultipleItem() {
      this.multiple = true;
      this.formDataBin.name = "Archive Selected Items";
      this.archiveConfirmationModal = true;
    },

    async archiveMultipleItemConfirm(items) {
      console.log("checkedData", items);
      for (var i = 0; i < items.length; i++) {
        var itemId = items[i].storageBinId;
        this.isLoading = true;
        await axios
          .get("StorageBins/" + itemId + "/Archive")
          .then((response) => {
            if (response.data.success === true) {
              this.isLoading = false;
              // this.archiveConfirmationModal = false;
              // this.getDataFromApi();
              //  successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    async archiveItemConfirm() {
      if (this.multiple) {
        var selectdItems = this.checkedData;
        await this.archiveMultipleItemConfirm(selectdItems);
        this.checkedData = [];
        this.allSelected = false;
        this.multiple = false;
        this.getDataFromApi();
        this.archiveConfirmationModal = false;
        successToast("Archived Successfully");
      } else {
        var itemId = this.formDataBin.storageBinId;
        this.isLoading = true;
        await axios
          .get("StorageBins/" + itemId + "/Archive")
          .then((response) => {
            if (response.data.success === true) {
              this.archiveConfirmationModal = false;
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post(`StorageBins/${this.warehouseAbbreviation}/Archived`, this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },

    restoreItem(item) {
      this.formDataBin = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreMultipleItem() {
      this.multipleArchive = true;
      this.formDataBin.name = "Restore Selected Items";
      this.restoreConfirmationModal = true;

      // this.getDataFromApi();
    },
    async restoreMultipleItemConfirm(items) {
      console.log("checkedData", items);
      for (var i = 0; i < items.length; i++) {
        var itemId = items[i].storageBinId;
        this.isLoading = true;
        await axios
          .get("StorageBins/" + itemId + "/Restore")
          .then((response) => {
            if (response.data.success === true) {
              // successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },

    async restoreItemConfirm() {
      if (this.multipleArchive) {
        var selectdItems = this.checkedDataArchive;
        await this.restoreMultipleItemConfirm(selectdItems);
        this.checkedDataArchive = [];
        this.allSelectedArchive = false;
        this.multipleArchive = false;
        this.viewArchives();
        this.getDataFromApi();
        this.restoreConfirmationModal = false;
        successToast("Restored Successfully");
      } else {
        var itemId = this.formDataBin.storageBinId;
        this.isLoading = true;
        await axios
          .get("StorageBins/" + itemId + "/Restore")
          .then((response) => {
            if (response.data.success === true) {
              this.restoreConfirmationModal = false;
              this.viewArchives();
              this.getDataFromApi();
              successToast(response.data.message);
              if (this.archiveData.totalCount == 0) {
                this.archiveModal = false;
              }
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    deleteItem(item) {
      this.formDataBin = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteMultipleItem() {
      alert("Not yet implemented");
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataBin.storageBinId;
      axios
        .delete("StorageBins/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
