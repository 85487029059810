<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label">Department</label>
          <div class="w-72">
            <v-select
              v-model="productExpirtaionReportData.departmentId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenDep"
              @close="onCloseDep"
              @search="searchDep"
              :key="name"
              :get-option-label="(option) => option.name"
              :options="departmentList"
              :reduce="(name) => name.departmentId"
              label="name"
            >
              <template #list-footer>
                <li v-show="hasNextPageDep" ref="loadDep" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">PLU/SKU</label>
          <div class="w-72">
            <v-select
              v-model="productExpirtaionReportData.skuId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenSku"
              @close="onCloseSku"
              @search="searchSKU"
              :options="skus"
              :reduce="(skuName) => skuName.skuId"
              :get-option-label="(option) => option.skuName"
              label="skuName"
            >
              <template #list-footer>
                <li v-show="hasNextPageSku" ref="loadSku" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Date</label>
          <PreviewComponent class="intro-y">
            <div class="w-40">
              <Preview>
                <Litepicker
                  v-model="productExpirtaionReportData.date"
                  :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }"
                  class="form-control"
                />
              </Preview>
            </div>
          </PreviewComponent>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Filter </label>

          <Dropdown>
            <DropdownToggle class="btn px-2 box">
              <span class="w-5 h-5 flex items-center justify-center">
                <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4" />
              </span>
            </DropdownToggle>
            <DropdownMenu class="w-40">
              <DropdownContent>
                <DropdownItem
                  @click="setMonth(item)"
                  v-for="(item, i) in monthsList"
                  :key="i"
                >
                  <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4 mr-2" />
                  {{ item.name }}
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="getReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            GET REPORT
          </a>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="clearReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            CLEAR
          </a>
        </div>

        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pageNumber"
        />
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table
        class="table table-report -mt-2 table-auto"
        v-if="reportsLocal.length > 0"
      >
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div
      v-if="reports.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />

    <!-- END: Pagination -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-components/toast/toast";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Product Expiration Reports");
    this.observerDep = new IntersectionObserver(this.infiniteScrollDep);
    this.observerSku = new IntersectionObserver(this.infiniteScrollSku);
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      departmentList: [],
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,

      headers: [
        {
          text: "Warehouse",
          value: "warehouse",
        },
        { text: "Section", value: "section" },
        { text: "Bin", value: "bin" },
        { text: "SkuId", value: "skuId" },

        { text: "Sku", value: "sku" },
        { text: "Ean", value: "ean" },
        { text: "Description", value: "description" },

        { text: "Department", value: "department" },
        { text: "Category", value: "category" },
        { text: "Quantity", value: "quantity" },

        { text: "MovedQuantity", value: "movedQuantity" },
        { text: "UtilizedQuantity", value: "utilizedQuantity" },
        { text: "ExpiryDate", value: "expiryDate" },

        { text: "SellingPrice", value: "sellingPrice" },
        { text: "Cost", value: "cost" },
        { text: "MovementId", value: "movementId" },
        { text: "Lot", value: "lot" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },

      observerDep: null,
      limitDep: 5,
      hasNextPageDep: false,
      totalDepCount: 0,
      isDepSearchResult: false,
      depPageNumber: 1,
      depSearchTimer: undefined,

      observerSku: null,
      limitSku: 5,
      hasNextPageSku: false,
      totalSkuCount: 0,
      isSkuSearchResult: false,
      skuPageNumber: 1,
      skuSearchTimer: undefined,

      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      customers: [],
      itemsData: {},
      skus: [],
      productExpirtaionReportData: {
        departmentId: "",
        skuId: "",
        date: "",
      },

      reports: [],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
    };
  },
  computed: {
    checkLimtSku() {
      return this.skuPageNumber;
    },
    checkLimtDep() {
      return this.depPageNumber;
    },
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },
    // getReportData() {
    //   return (
    //     this.productExpirtaionReportData.departmentId +
    //     this.productExpirtaionReportData.skuId
    //   );
    // },
    viewModalSku() {
      return this.productExpirtaionReportData.skuId;
    },
    viewModalDep() {
      return this.productExpirtaionReportData.departmentId;
    },
  },
  created() {
    this.getCurrentMonth();
    this.getDepartmentData("");
    this.getSkus("");
  },
  watch: {
    viewModalSku() {
      if (this.productExpirtaionReportData.skuId === null) {
        this.clearSku();
      }
    },
    viewModalDep() {
      if (this.productExpirtaionReportData.departmentId === null) {
        this.clearDep();
      }
    },
    checkLimtDep() {
      this.getDepartmentData();
    },
    checkLimtSku() {
      this.getSkus();
    },
    // getReportData() {
    //   if (
    //     this.productExpirtaionReportData.departmentId != "" ||
    //     this.productExpirtaionReportData.skuId != ""
    //   ) {
    //     this.showReports();
    //   } else {
    //     warningToast(const_txt.NO_DATA);
    //   }
    // },
  },
  methods: {
    clearSku() {
      this.skus = [];
      this.skuPageNumber = 1;
      this.limitSku = 5;
      this.getSkus("");
    },
    searchSKU(search) {
      clearInterval(this.skuSearchTimer);
      this.skuSearchTimer = setTimeout(() => {
        if (search) {
          this.skus = [];
          this.skuPageNumber = 1;
          this.limitSku = this.totalSkuCount;
          this.getSkus(search.trim());
        }
      }, 2000);
    },

    clearDep() {
      this.departmentList = [];
      this.depPageNumber = 1;
      this.limitDep = 5;
      this.getDepartmentData("");
    },
    searchDep(search) {
      clearInterval(this.depSearchTimer);
      this.depSearchTimer = setTimeout(() => {
        if (search) {
          this.departmentList = [];
          this.depPageNumber = 1;
          this.limitDep = this.totalDepCount;
          this.getDepartmentData(search.trim());
        }
      }, 2000);
    },
    async onOpenSku() {
      if (this.isSkuSearchResult) {
        this.skuPageNumber = 1;
      }
      if (this.hasNextPageSku) {
        await this.$nextTick();
        this.observerSku.observe(this.$refs.loadSku);
      }
    },
    onCloseSku() {
      this.observerSku.disconnect();
    },
    async infiniteScrollSku([{ isIntersecting, target }]) {
      console.log("isIntersectingSku " + isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.skuPageNumber++;
        this.limitSku;
        console.log("limitSku " + this.limitSku);
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenDep() {
      if (this.isDepSearchResult) {
        this.depPageNumber = 1;
      }
      if (this.hasNextPageDep) {
        await this.$nextTick();
        this.observerDep.observe(this.$refs.loadDep);
      }
    },
    onCloseDep() {
      this.observerDep.disconnect();
    },
    async infiniteScrollDep([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.depPageNumber++;
        this.limitDep;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Warehouse", dataKey: "warehouse" },
          { title: "Section", dataKey: "section" },
          { title: "Bin", dataKey: "bin" },
          { title: "SkuId", dataKey: "skuId" },
          { title: "Sku", dataKey: "sku" },
          { title: "Ean", dataKey: "ean" },
          { title: "Description", dataKey: "description" },

          { title: "Department", dataKey: "department" },
          { title: "Category", dataKey: "category" },
          { title: "Quantity", dataKey: "quantity" },
          { title: "MovedQuantity", dataKey: "movedQuantity" },
          { title: "UtilizedQuantity", dataKey: "utilizedQuantity" },
          { title: "ExpiryDate", dataKey: "expiryDate" },
          { title: "SellingPrice", dataKey: "sellingPrice" },
          { title: "Cost", dataKey: "cost" },
          { title: "MovementId", dataKey: "movementId" },
          { title: "Lot", dataKey: "lot" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            warehouse: el.warehouse,
            section: el.section,
            bin: el.bin,
            skuId: el.skuId,
            sku: el.sku,
            ean: el.ean,
            description: el.description,
            department: el.department,
            category: el.category,
            quantity: el.quantity,
            movedQuantity: el.movedQuantity,
            utilizedQuantity: el.utilizedQuantity,
            expiryDate: el.expiryDate,
            sellingPrice: el.sellingPrice,
            cost: el.cost,
            movementId: el.movementId,
            lot: el.lot,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(
            footer,
            pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
            pageHeight - 0.2,
            { baseline: "bottom" }
          );
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Product Sales Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Product Sales Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "Warehouse",
          2: "Section",
          3: "Bin",
          4: "SkuId",
          5: "Sku",
          6: "Ean",
          7: "Description",
          8: "Department",
          9: "Category",
          10: "Quantity",
          11: "MovedQuantity",
          12: "UtilizedQuantity",
          13: "ExpiryDate",
          14: "SellingPrice",
          15: "Cost",
          16: "MovementId",
          17: "Lot",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.warehouse,
            2: item.section,
            3: item.bin,
            4: item.skuId,
            5: item.sku,
            6: item.ean,
            7: item.description,
            8: item.department,
            9: item.category,
            10: item.quantity,
            11: item.movedQuantity,
            12: item.utilizedQuantity,
            13: item.expiryDate,
            14: item.sellingPrice,
            15: item.cost,
            16: item.movementId,
            17: item.lot,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    getCurrentMonth() {
      const today = new Date();
      var startdate =
        today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
      this.productExpirtaionReportData.fromDate = startdate;
    },

    setMonth(item) {
      this.selectedMonth = item.value - 1;
      this.getMonthRange();
    },
    getMonthRange() {
      var d = new Date();
      var start = new Date(d.getFullYear(), this.selectedMonth, 1);

      var startdate =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getDate();

      this.productExpirtaionReportData.date =
        moment(startdate).format("DD MMMM, YYYY");
    },

    getSkus(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.skuPageNumber,
        pageSize: this.limitSku,
        search: search,
      };
      axios
        .post(
          "MemorySkus/" + this.warehouseAbbreviation + "/Stocks",
          pagination
        )
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            this.isSkuSearchResult = !!search;
            this.skus.unshift({
              skuId: null,
              skuName: "ALL",
              warehouseId: this.warehouseStoreId,
            });
            this.skus =
              this.isSkuSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.skus, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSku = response.data.data.hasNextPage;
            this.totalSkuCount = response.data.data.totalCount;
            // this.addProductModalForm = true;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in PLU/SKU");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    async getDepartmentData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.DepPageNumber,
        pageSize: this.limitDep,
        search: search,
      };
      await axios
        .post("Departments/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isDepSearchResult = !!search;

            this.departmentList.unshift({
              departmentId: null,
              name: "ALL",
              warehouseId: this.warehouseStoreId,
            });
            this.departmentList =
              this.isDepSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.departmentList, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageDep = response.data.data.hasNextPage;
            this.totalDepCount = response.data.data.totalCount;
            console.log(this.departmentList);
            if (this.departmentList.length == 0) {
              warningToast("Please Add Department");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearReports() {
      this.reports = [];
      this.reportsLocal = [];
      this.productExpirtaionReportData.departmentId = "";
      this.productExpirtaionReportData.skuId = "";
      this.getCurrentMonth();
    },
    // showReports() {
    //   var departmentId = this.productExpirtaionReportData.departmentId;
    //   var skuId = this.productExpirtaionReportData.skuId;
    //   if (departmentId == null || skuId == null) {
    //     if (departmentId == null) {
    //       failedToast(const_txt.SELECT_CUSTOMER);

    //       this.reports = [];
    //       this.setLocalReport();
    //     }
    //     if (skuId == null) {
    //       failedToast(const_txt.SELECT_SKU_DESC);
    //       this.reports = [];
    //       this.setLocalReport();
    //     }
    //   } else {
    //     this.getReports();
    //   }
    // },

    getReports() {
      // this.isLoading = true;
      var item = Object.assign({}, this.productExpirtaionReportData);
      item.date = moment(item.date).format("YYYY-MM-DD");
      //  item.date = item.date.concat("T").concat("00:00:00.000Z");
      // item.todate = moment(item.todate).format("YYYY-MM-DD");
      // item.todate = item.todate.concat("T").concat("23:59:59.000Z");

      console.log(item);

      axios
        .post(
          "Reports/" + this.warehouseStoreId + "/Products/Expiration",
          this.productExpirtaionReportData
        )
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.itemsData = response.data;
            if (response.data) {
              this.itemsData.totalCount = this.itemsData.data.length;
            }
            this.reports = response.data.data;
            this.setLocalReport();
            if (this.reports.length == 0) {
              this.isLoading = false;
              warningToast("NO DATA ", response.data.message);
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        });
    },

    searchOnchange() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getReports();
      this.searchActive = false;
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getReports();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
