import {
  Info,
  ChevronDown,
  ChevronUp,
  Activity,
  Zap,
  BarChart2,
  X,
  XCircle,
  Search,
  Inbox,
  Users,
  CreditCard,
  Bell,
  User,
  Edit,
  Lock,
  HelpCircle,
  ToggleRight,
  Archive,
  Plus,
  AlertTriangle,
  Upload,
  Download,
  Trash2,
  Server,
  Printer,
  Square,
  CheckSquare,
  PlusCircle,
  FileText,
  RotateCcw
} from "lucide-vue-next";

export default {
  Info,
  ChevronDown,
  ChevronUp,
  Activity,
  Zap,
  BarChart2,
  X,
  XCircle,
  Search,
  Inbox,
  Users,
  CreditCard,
  Bell,
  User,
  Edit,
  Lock,
  HelpCircle,
  ToggleRight,
  Archive,
  Plus,
  AlertTriangle,
  Upload,
  Download,
  Trash2,
  Server,
  Printer,
  Square,
  CheckSquare,
  PlusCircle,
  FileText,
  RotateCcw
};
