 
import store from "../store"


const currencyFormat = function (val) {
  return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
};
const percentageFormat = function (val) {
  return val ? val + "%" : "0%";
};

const temperatureFormat = function (val) {
  return val ? val + "°C" : "";
};

const weightFormat = function (val) {
  return val ? val + "Kg" : "";
};

const removeNullValue = function (item) {
  Object.keys(item).forEach((key) => {
    if (item[key] === null) {
      item[key] = "";
    }
  });
};

const removeNullValueObjectArray = function (items) {
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    Object.keys(item).forEach((key) => {
      if (item[key] === null) {
        item[key] = "";
      }
    });
  }
};

const removeDuplicate = function (array) {
  var uniqueArray = [];

  if (array.length >= 0) {
    for (var i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
};

const exportCSVFile = function (csvHeaders, csvItems, csvTitle) {
  if (csvHeaders) {
    csvItems.unshift(csvHeaders);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(csvItems);
  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = csvTitle || "export.csv";
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
const convertToCSV = function (objArray) {
  console.log("convertToCSV");
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
};


 
export {
  currencyFormat,
  percentageFormat,
  temperatureFormat,
  weightFormat,
  removeNullValue,
  removeNullValueObjectArray,
  removeDuplicate,
  exportCSVFile,  
};
