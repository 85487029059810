<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="w-full mt-5">
    <div
      class="flex flex-col flex-wrap items-end justify-end w-full mt-2 intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between">
      <div class="flex items-center justify-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)"
          buttonName="Add New SKU" />

        <div v-if="$h.addItemAccess(policyAccess) == true">
          <a href="javascript:;" class="mt-1 mb-1 mr-2 text-xs shadow-md btn btn-primary" @click="openUploadModal()">
            Upload File
            <span class="ml-2">
              <UploadIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
            </span>
          </a>
        </div>

        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport"
          :page="pagination.pageNumber" :selected="checkedData.length" @printSelected="printSelected"
          @csvExportSelected="csvExportSelected" @pdfExportSelected="pdfExportSelected"
          @printAllPages="printAllPages" />
        <PrintLabels @parentMethod="printLabels($event)" />

        <div v-if="$h.addItemAccess(policyAccess) == true">
          <a href="javascript:;" class="mt-1 mb-1 mr-2 text-xs shadow-md btn btn-primary" @click="openPrintQueue()">
            Print Queue
            <span class="ml-2">
              <ListOrderedIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
            </span>
          </a>
        </div>

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)"
          @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>

      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto w-full xl:h-[50vh] lg:h-[50vh] md:h-[50vh] h-[50vh]" ref="scroll"
      @scroll="handleScroll">
      <table class="table -mt-2 table-auto table-report">
        <thead class="sticky top-0 z-50 bg-[#f1f5f9]">
          <tr class="uppercase whitespace-nowrap">
            <th>Image</th>
            <th>QR</th>

            <th>PLU/SKU</th>

            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>DESC</th>
            <th>Price</th>
            <th>RESERVED</th>
            <!-- <th>UNRESERVED</th> -->
            <th>SOH</th>
            <th>SOH%</th>
            <th>Discount</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)"
                @unSelectAll="unSelectAll($event)" />
              <!-- <ArchiveItem
                class="ml-3 -mt-1"
                :activeAccess="$h.archiveItemAccess(policyAccess)"
                v-if="checkedData.length > 0"
                @parentMethod="archiveMultipleItem($event)"
                name="Archive Selected"
              /> -->
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td>
              <ImageView :src="baseResourceUrl + item.imageUrl" />
            </td>
            <td class="cursor-pointer">
              <Tippy tag="a" href="javascript:;" class="w-full tooltip" :content="'PLU/SKU : ' + item.skuName">
                <qrcode-vue @click="showQrModal(item)" v-if="item.skuId" render-as="svg" :value="item.skuName" size="50"
                  level="H" />
              </Tippy>
            </td>

            <td @click="viewSkuInfo(item)" class="cursor-pointer" style="color: #1a73e8"
              v-if="!$roleCheck(['BranchAdmin'])">
              {{ item.skuName }}
            </td>

            <td v-else>
              {{ item.skuName }}
            </td>

            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>

            <td>
              <div class="text-left line-clamp-2">
                <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                  {{ item.description }}
                </Tippy>
              </div>
            </td>
            <td>
              <div class="text-left">
                {{ currencyFormat(item.price) }}
              </div>
            </td>
            <td>
              <div class="editable-item">
                <span> {{ item.designatedQuantity || 0 }}</span>
                <EditItem class="edit-icon" :activeAccess="$h.editItemAccess(policyAccess)"
                  @parentMethod="updateReserveStock($event)" :item="item" name="Update Reserved Stock" />

              </div>
              <!-- reserved -->

            </td>
            <!-- <td>
                unreserved 
              {{ item.available || 0 }}
            </td> -->
            <td>
              <!-- soh -->
              {{ item.available || 0 }}
            </td>
            <td class="w-44">
              <!-- <div class="text-left">
                {{ item.available }}
              </div> -->

              <div class="flex flex-col items-center justify-center space-y-1">
                <div class="mt-3 progress">
                  <div class="progress-bar bg-success" :style="{
                    width: `${item.quantity <= 0 || item.available <= 0
                      ? 0
                      : Math.round((item.available / item.quantity) * 100) >= 100
                        ? 100
                        : Math.round((item.available / item.quantity) * 100)
                      }%`,
                  }" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div>Min.Stock: {{ item.minStock }}, P: {{ item.quantity <= 0 || item.available <= 0 ? 0 :
                  Math.round((item.available / item.quantity) * 100) }}%</div>
                </div>

                <!-- <div
                class="flex flex-col items-center justify-center space-y-1"
                v-if="1000 - item.minStock > 500"
              >
                <div class="mt-3 progress">
                  <div
                    class="progress-bar bg-success"
                    :style="{
                      width: `${((1000 - item.minStock) / 1000) * 100}%`,
                    }"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <div>
                  Min.Stock: {{ item.minStock }}, P:
                  {{ Math.round(((1000 - item.minStock) / 1000) * 100) }}%
                </div>
              </div> -->

                <!-- <div
                class="flex flex-col items-center justify-center space-y-1"
                v-if="1000 - item.minStock < 500"
              >
                <div class="mt-3 progress">
                  <div
                    class="progress-bar bg-warning"
                    :style="{
                      width: `${((1000 - item.minStock) / 1000) * 100}%`,
                    }"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div>
                  Min.Stock: {{ item.minStock }}, P:
                  {{ Math.round(((1000 - item.minStock) / 1000) * 100) }}%
                </div>
              </div> -->
            </td>

            <td>
              <div class="ml-3 form-switch">
                <input id="booleanSwitch" type="checkbox" v-model="item.isDiscount" class="form-check-input" disabled />
              </div>
            </td>

            <td class="table-report__action w-28">
              <div class="flex flex-wrap p-2 lg:grid lg:grid-cols-3 gap-x-2 gap-y-2">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />

                <Tippy tag="a" href="javascript:;" class="tooltip" content="Locations 3D View" :options="{
                  zIndex: 99999,
                }" @click="$router.push(`/wms/locations/${item.skuName}`)" v-if="!$roleCheck(['BranchAdmin'])">
                  <Scale3dIcon class="w-4 h-4 mr-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-primary" />
                </Tippy>

                <a @click="addStock(item)" class="flex items-center mr-3" href="javascript:;"
                  v-if="!$roleCheck(['BranchAdmin'])">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Add Stock" :options="{
                    zIndex: 99999,
                  }">
                    <PackagePlusIcon class="w-4 h-4 mr-1 md:w-5 text-pending md:h-5 lg:w-6 lg:h-6" />
                  </Tippy>
                </a>

                <a @click="addImage(item)" class="flex items-center mr-3" href="javascript:;"
                  v-if="!$roleCheck(['BranchAdmin'])">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Add Image" :options="{
                    zIndex: 99999,
                  }">
                    <ImagePlusIcon class="w-4 h-4 mr-1 md:w-5 text-pending md:h-5 lg:w-6 lg:h-6" />
                  </Tippy>
                </a>

                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item"
                  name="Edit" />
                <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)"
                  :item="item" name="Archive" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <NoData :itemsData="itemsData" />
    </div>
    <!-- END: Data List -->
    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->

    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData"
      :pageCount="pageCount" :pageSize="pageSizeView" :pageNumber="pagination.pageNumber" />

    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addSku" @hidden="addSku = false" backdrop="static" size="modal-sl-90" :title="formTitle"
    @close="closeForm()">
    <ModalBody class="" v-if="addSku">
      <div v-if="titleChange != -1" class="flex p-2 cursor-pointer flex-nowrap text-md font-semiboldgit text-info"
        @click="viewAddskuForm = !viewAddskuForm">
        {{ binViewSkuTitle }}
        <ArrowDownIcon class="w-3 h-3 ml-4 lg:w-5 lg:h-5" v-if="!viewAddskuForm" />
        <ArrowUpIcon class="w-3 h-3 ml-4 lg:w-5 lg:h-5" v-else />
      </div>
      <div v-if="viewAddskuForm" class="grid grid-cols-6 gap-2 p-2 lg:gap-4 md:gap-4">
        <div class="w-full">
          <label for="regular-form-1" class="form-label"><span
              class="font-extrabold text-danger">*</span>PLU/SKU</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.skuName" type="text" class="form-control" name="skuName"
              placeholder="SKU" autocomplete="off" />
          </div>
        </div>

        <div class="w-full col-span-2">
          <Autocomplete ref="skuproduct" v-model:modelValue="formDataSku.productId" label="Product" itemText="name"
            itemValue="productId" :updatedItem="updateProductItem" @load="newLoadProduct" refKey="skuproduct" />

          <!-- <label for="regular-form-1" class="form-label">Product</label>
          <v-select ref="productSelect" v-model="formDataSku.productId" placeholder="SELECT" class="style-chooser"
            :reduce="(name) => name.productId" :options="productList" :filterable="false" @open="onOpenProduct"
            @close="onCloseProduct" @search="searchProduct" :key="name" :get-option-label="(option) => option.name"
            label="name">
            <template #list-footer>
              <li v-show="hasNextPageProduct" ref="loadProduct" class="loader">Loading more...</li>
            </template>
</v-select> -->
        </div>

        <div class="w-full col-span-2">
          <label for="regular-form-1" class="form-label"><span
              class="font-extrabold text-danger">*</span>Description</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.description" type="text" class="h-10 form-control"
              placeholder="Description" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label"><span
              class="font-extrabold text-danger">*</span>BARCODE/EAN</label>

          <div class="flex flex-col space-y-1">
            <input
              :style="validateEan(formDataSku.ean) == true && formDataSku.ean != '' ? 'border-color: red' : 'border-color: none'"
              id="vertical-form-1" v-model="formDataSku.ean" type="text" class="form-control" name="ean"
              placeholder="BARCODE/EAN" autocomplete="off" />
          </div>
        </div>

        <div class="w-full" v-if="titleChange == -1">
          <Autocomplete ref="skuwarehouse" v-model:modelValue="formDataSku.warehouseId" label="Warehouse"
            itemText="abbreviation" itemValue="warehouseId" :updatedItem="updateWarehouseItem" @load="newLoadWarehouse"
            refKey="skuwarehouse" />

          <!-- <label for="regular-form-1" class="form-label"><span
              class="font-extrabold text-danger">*</span>Warehouse</label>
          <v-select v-model="formDataSku.warehouseId" placeholder="SELECT" class="style-chooser" :filterable="false"
            @open="onOpenWarehouse" @close="onCloseWarehouse" @search="searchWarehouse" :key="abbreviation"
            :get-option-label="(option) => option.abbreviation" :options="warehousesList"
            :reduce="(abbreviation) => abbreviation.warehouseId" label="abbreviation">
            <template #list-footer>
              <li v-show="hasNextPageWarehouse" ref="loadWarehouse" class="loader">Loading more...</li>
            </template>
          </v-select> -->

          <!-- <v-select v-if="storeUserRole != 'SuperUser'" v-model="formDataSku.warehouseId" placeholder="SELECT"
            class="style-chooser" :filterable="false" @open="onOpenUsersWarehouse" @close="onCloseUsersWarehouse" @search="
              (query) => {
                onUserWarehouseSearch(query);
              }
            " :options="paginatedUserWarehouse" :reduce="(abbreviation) => abbreviation.warehouseId"
            label="abbreviation">
            <template #list-footer>
              <li v-show="hasNextPageUsersWarehouse" ref="loadUsersWarehouse" class="loader">
                Loading more...
              </li>
            </template>
          </v-select> -->
        </div>
        <div class="w-full">
          <!-- <label for="regular-form-1" class="form-label">Department</label>
          <v-select v-model="formDataSku.department" placeholder="SELECT" :filterable="false" @open="onOpenDep"
            @close="onCloseDep" class="style-chooser" @search="searchDep" :key="name"
            :get-option-label="(option) => option.name" :reduce="(name) => name.name" :options="departmentList"
            label="name">
            <template #list-footer>
              <li v-show="hasNextPageDep" ref="loadDep" class="loader">Loading more...</li>
            </template>
          </v-select> -->
          <Autocomplete ref="skudepartment" v-model:modelValue="formDataSku.department" label="Department"
            itemText="name" itemValue="name" :updatedItem="updateDepartmentItem" @load="newLoadDepartment"
            refKey="skudepartment" />
        </div>

        <div class="w-full">
          <Autocomplete ref="skuunit" v-model:modelValue="formDataSku.unitId" label="Unit" itemText="unitName"
            itemValue="unitId" :updatedItem="updateUnitItem" @load="newLoadUnit" refKey="skuunit" />

          <!-- <label for="regular-form-1" class="form-label">Unit</label>

          <v-select v-model="formDataSku.unitId" placeholder="SELECT" class="style-chooser" :filterable="false"
            @open="onOpenUnit" @close="onCloseUnit" @search="searchUnit" :key="unitName"
            :get-option-label="(option) => option.unitName" :reduce="(unitName) => unitName.unitId" :options="unitList"
            label="unitName">
            <template #list-footer>
              <li v-show="hasNextPageUnit" ref="loadUnit" class="loader">Loading more...</li>
            </template>
          </v-select> -->
        </div>

        <!-- <div class="w-full">
          <label for="regular-form-1" class="form-label">SOH</label>

          <div class="flex flex-col space-y-1">
            <input
              v-model="formDataSku.minStock"
              id="vertical-form-1"
              type="text"
              class="form-control"
              name="SOH"
              placeholder="SOH"
            />
          </div>
        </div> -->

        <div class="w-full">
          <label for="regular-form-1" class="form-label"><span class="font-extrabold text-danger">*</span>Min
            Stock</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.minStock" type="text" class="form-control"
              placeholder="Min Stock" autocomplete="off" />
          </div>
        </div>
        <!-- 
        <div class="w-full">
          <label for="regular-form-1" class="form-label">Price</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataSku.price"
              type="text"
              class="form-control"
              name="price"
              placeholder="Price"
              autocomplete="off"
            />
          </div>
        </div> -->

        <div class="w-full">
          <label for="regular-form-1" class="form-label">VAT Percentage</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.vatPercentage" type="number" class="form-control"
              name="vatPercentage" placeholder="VAT Percentage" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Minimum Margin</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.minMargin" type="number" class="form-control"
              name="minMargin" placeholder="Minimum Margin" autocomplete="off" />
          </div>
        </div>

        <div class="w-full" v-if="titleChange >= 0">
          <label for="regular-form-1" class="form-label">SOH</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" :value="viewSOH != null || viewSOH >= 0 ? viewSOH : 0" disabled type="number"
              class="form-control" placeholder="SOH" autocomplete="off" />
          </div>
        </div>

        <!-- <div class="flex items-start justify-start w-full col-span-3 pt-2">
        <label for="regular-form-1" class="form-label">Discount?</label>

        <div class="flex flex-col space-y-1">
          <div class="ml-3 form-switch">
            <input
              type="checkbox"
              v-model="formDataSku.isDiscount"
              class="form-check-input"
            />
          </div>
        </div>
      </div> -->

        <div class="relative col-span-6 pt-2">
          <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Price & Discount Details</h3>
          <div
            class="grid h-full grid-cols-2 gap-2 p-4 border border-solid rounded-md border-slate-200 lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4">
            <div>
              <label for="regular-form-1" class="form-label"><span
                  class="font-extrabold text-danger">*</span>Price</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="formDataSku.price" type="text" class="form-control" name="price"
                  placeholder="Price" autocomplete="off" />
              </div>
            </div>
            <!-- <div>
              <label for="regular-form-1" class="form-label"
                >VAT Percentage</label
              >

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataSku.vatPercentage"
                  type="text"
                  class="form-control"
                  name="vatPercentage"
                  placeholder="VAT Percentage"
                  autocomplete="off"
                />
              </div>
            </div>
            <div>
              <label for="regular-form-1" class="form-label"
                >Minimum Margin</label
              >

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataSku.minMargin"
                  type="number"
                  class="form-control"
                  name="minMargin"
                  placeholder="Minimum Margin"
                  autocomplete="off"
                />
              </div>
            </div> -->
            <div>
              <label for="regular-form-1" class="form-label">Discount</label>

              <div class="flex flex-col space-y-1">
                <div class="ml-3 form-switch">
                  <input type="checkbox" v-model="formDataSku.isDiscount" class="form-check-input" />
                </div>
              </div>
            </div>

            <div>
              <div v-if="formDataSku.isDiscount">
                <label for="regular-form-1" class="form-label">Discounted Price</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="formDataSku.discountedPrice" type="text" class="form-control"
                    name="discountedPrice" placeholder="Discounted Price" autocomplete="off" />
                </div>
              </div>
            </div>

            <div>
              <div v-if="formDataSku.isDiscount">
                <label for="regular-form-1" class="form-label">Discount Reason</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="formDataSku.discountReason" type="text" class="form-control"
                    name="discountReason" placeholder="Discount Reason" autocomplete="off" />
                </div>
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="capitalize form-label">Std cost</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
                  placeholder="Std cost" v-model="formDataSku.stdCost" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="capitalize form-label">Reprice markup</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
                  placeholder="Reprice markup" v-model="formDataSku.rePriceMarkup" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="capitalize form-label">Reprice method</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
                  placeholder="Reprice method" v-model="formDataSku.rePriceMethod" autocomplete="off" />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="w-full">
          <label for="regular-form-1" class="form-label">Discount</label>

          <div class="flex flex-col space-y-1">
            <div class="ml-3 form-switch">
              <input
                type="checkbox"
                v-model="formDataSku.isDiscount"
                class="form-check-input"
              />
            </div>
          </div>
        </div>

        <div class="w-full" v-if="formDataSku.isDiscount">
          <label for="regular-form-1" class="form-label"
            >Discounted Price</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataSku.discountedPrice"
              type="text"
              class="form-control"
              name="discountedPrice"
              placeholder="Discounted Price"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full" v-if="formDataSku.isDiscount">
          <label for="regular-form-1" class="form-label">Discount Reason</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataSku.discountReason"
              type="text"
              class="form-control"
              name="discountReason"
              placeholder="Discount Reason"
              autocomplete="off"
            />
          </div>
        </div> -->

        <!-- newly added -->
        <!-- <div class="w-full">
          <label for="regular-form-1" class="form-label">PLU Code</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control"
              name="discountReason"
              placeholder="PLU Code"
            />
          </div>
        </div> -->

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Std cost</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Std cost"
              v-model="formDataSku.stdCost"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Reprice markup</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Reprice markup"
              v-model="formDataSku.rePriceMarkup"
              autocomplete="off"
            />
          </div>
        </div> -->

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Sell price</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Sell price"
              v-model="formDataSku.sellPrice"
              autocomplete="off"
            />
          </div>
        </div> -->

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Last cost</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Last cost"
            />
          </div>
        </div> -->

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >prev avg cost</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control"
              name="discountReason"
              placeholder="Prev avg cost"
            />
          </div>
        </div> -->

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Reprice method</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Reprice method"
              v-model="formDataSku.rePriceMethod"
              autocomplete="off"
            />
          </div>
        </div> -->

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Max.discount Reason</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Max.discount Reason"
            />
          </div>
        </div> -->

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label">Head office note</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
              placeholder="Head office note" v-model="formDataSku.headOfficeNote" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Manufacturer</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="form-control" name="discountReason"
              placeholder="Manufacturer" v-model="formDataSku.manufacturer" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label">Manf. product code</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
              placeholder="Manf. product code" v-model="formDataSku.manfProCode" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label">Buying mult</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
              placeholder="Buying mult" v-model="formDataSku.buyingMult" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">RRP</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="form-control" name="discountReason" placeholder="RRP"
              v-model="formDataSku.rrp" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label">RRP adjustment</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
              placeholder="RRP adjustment" v-model="formDataSku.rrpAdjustment" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Note</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="form-control" name="discountReason" placeholder="Note"
              v-model="formDataSku.note" autocomplete="off" />
          </div>
        </div>

        <!-- <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label"
            >Std price list note</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="capitalize form-control"
              name="discountReason"
              placeholder="Std price list note"
            />
          </div>
        </div> -->

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label">Shelf descr</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
              placeholder="Shelf descr" v-model="formDataSku.shelfDescription" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="capitalize form-label">Shelf label</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" type="text" class="capitalize form-control" name="discountReason"
              placeholder="Shelf label" v-model="formDataSku.shelfLabel" autocomplete="off" />
          </div>
        </div>
        <div class="w-full">
          <label for="regular-form-1" class="form-label">Temperature</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.temperature" type="number" class="form-control"
              placeholder="Temperature" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Dimension</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.dimension" type="number" class="form-control"
              placeholder="Dimension" autocomplete="off" />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Weight</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataSku.weight" type="number" class="form-control"
              placeholder="Weight" autocomplete="off" />
          </div>
        </div>
      </div>
      <hr class="mt-2 border-gray-500 border-1" />

      <div v-if="titleChange >= 0" class="p-2">
        <!-- BEGIN: Data List -->
        <div class="flex cursor-pointer flex-nowrap text-md font-semiboldgit text-info"
          @click="viewBinProducts = !viewBinProducts">
          {{ binViewTitle }}
          <ArrowDownIcon class="w-3 h-3 ml-4 lg:w-5 lg:h-5" v-if="!viewBinProducts" />
          <ArrowUpIcon class="w-3 h-3 ml-4 lg:w-5 lg:h-5" v-else />
        </div>
        <div v-if="viewBinProducts" id="labelsScroll" class="col-span-12 overflow-scroll intro-y">
          <table id="labelsScroll" class="table -mt-2 table-auto table-report">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>Supplier</th>
                <th>SPN</th>
                <th>Supplier Product Description</th>
                <th>Price</th>
                <th>Max Delivery Days</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in binExistingProducts" :key="i" class="intro-x">
                <td>{{ item.supplierCompany }}</td>
                <td>{{ item.productNumber }}</td>
                <td>{{ item.productDescription }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.maxDeliveryTimeInDays }}</td>
                <td class="table-report__action w-28">
                  <div class="flex flex-wrap p-2 lg:grid lg:grid-cols-3 gap-x-2 gap-y-2">
                    <EditItem :activeAccess="$h.editItemAccess(policyAccess)"
                      @parentMethod="editItemSupplierProduct($event)" :item="item" name="Edit" />
                    <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)"
                      @parentMethod="deleteItemSupplierProduct($event)" :item="item" name="Delete" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="w-full col-span-12 p-2 mb-5 font-semibold text-center border-2 shadow-md"
            v-if="!(binExistingProducts && binExistingProducts.length > 0)">No Data</div>
        </div>

        <!-- END: Data List -->
      </div>
      <div v-if="titleChange === -1">
        <div class="mt-2 mb-2 text-sm font-semibold">Adding more supplier products</div>

        <div class="grid grid-cols-7 gap-2 lg:gap-4 md:gap-4" v-for="(input, index) in formDataSupplierProduct"
          :key="`phoneInput-${index}`">
          <div class="w-full col-span-2">
            <!-- <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Supplier Product</label>
            <v-select v-model="input.supplierId" placeholder="SELECT" :filterable="false" @open="onOpenSup"
              @close="onCloseSup" @search="searchSupplier" :key="company" :get-option-label="(option) => option.company"
              class="style-chooser" :reduce="(company) => company.supplierId" :options="suppliers" label="company">
              <template #list-footer>
                <li v-show="hasNextPageSup" ref="loadSup" class="loader">Loading more...</li>
              </template>
            </v-select> -->

            <Autocomplete ref="skusupplierProduct" v-model:modelValue="input.supplierId" label="Supplier"
              itemText="company" itemValue="supplierId" :updatedItem="updateSupplierProductItem" @load="newLoadSupplier"
              refKey="skusupplierProduct" />
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>SPN</label>

            <div class="flex flex-col space-y-1">
              <input v-model="input.productNumber" id="vertical-form-1" type="text" class="capitalize form-control"
                placeholder="supplier Product Code" autocomplete="off" />
            </div>
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Supplier Des</label>

            <div class="flex flex-col grid-cols-2 space-y-1">
              <input v-model="input.productDescription" id="vertical-form-1" type="text" class="capitalize form-control"
                placeholder="supplier Product Description" autocomplete="off" />
            </div>
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Price</label>

            <div class="flex flex-col space-y-1">
              <input v-model="input.price" id="vertical-form-1" type="text" class="capitalize form-control"
                placeholder="supplier Product Price" autocomplete="off" />
            </div>
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Max
              Delivery</label>

            <div class="flex flex-col space-y-1">
              <input v-model="input.maxDeliveryTimeInDays" id="vertical-form-1" type="text"
                class="capitalize form-control" placeholder="Max Delivery Time In Days" autocomplete="off" />
            </div>
          </div>

          <div class="flex items-center justify-center mt-5 space-x-4">
            <Tippy tag="a" href="javascript:;" class="tooltip" content="Add More" :options="{
              zIndex: 99999,
            }">
              <PlusIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-success"
                @click="addField(input, formDataSupplierProduct)" />
            </Tippy>

            <Tippy tag="a" href="javascript:;" class="tooltip" content="Remove" :options="{
              zIndex: 99999,
            }">
              <XCircleIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-danger"
                v-show="formDataSupplierProduct.length > 1" @click="removeField(index, formDataSupplierProduct)" />
            </Tippy>
          </div>
        </div>
      </div>

      <div v-if="titleChange !== -1">
        <div class="grid grid-cols-6 gap-2 lg:gap-4 md:gap-4">
          <div class="w-full">
            <!-- <label for="regular-form-1" class="capitalize form-label">Supplier</label>
            <v-select v-model="formDataSupplierProductEdit.supplierId" placeholder="SELECT" :filterable="false"
              @open="onOpenSup" @close="onCloseSup" @search="searchSupplier" :key="company"
              :get-option-label="(option) => option.company" class="style-chooser"
              :reduce="(company) => company.supplierId" :options="suppliers" label="company">
              <template #list-footer>
                <li v-show="hasNextPageSup" ref="loadSup" class="loader">Loading more...</li>
              </template>
            </v-select> -->

            <Autocomplete ref="skusupplierProduct2" v-model:modelValue="formDataSupplierProductEdit.supplierId"
              label="Supplier" itemText="company" itemValue="supplierId" :updatedItem="updateSupplierProductItem"
              @load="newLoadSupplier" refKey="skusupplierProduct2" />
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>SPN</label>

            <div class="flex flex-col space-y-1">
              <input v-model="formDataSupplierProductEdit.productNumber" id="vertical-form-1" type="text"
                class="capitalize form-control" placeholder="supplier Product Code" autocomplete="off" />
            </div>
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Supplier Des</label>

            <div class="flex flex-col grid-cols-2 space-y-1">
              <input v-model="formDataSupplierProductEdit.productDescription" id="vertical-form-1" type="text"
                class="capitalize form-control" placeholder="supplier Product Description" autocomplete="off" />
            </div>
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Price</label>

            <div class="flex flex-col space-y-1">
              <input v-model="formDataSupplierProductEdit.price" id="vertical-form-1" type="text"
                class="capitalize form-control" placeholder="supplier Product Price" autocomplete="off" />
            </div>
          </div>
          <div class="w-full">
            <label for="regular-form-1" class="capitalize form-label"><span
                class="font-extrabold text-danger">*</span>Max
              Delivery</label>

            <div class="flex flex-col space-y-1">
              <input v-model="formDataSupplierProductEdit.maxDeliveryTimeInDays" id="vertical-form-1" type="text"
                class="capitalize form-control" placeholder="Max Delivery Time In Days" autocomplete="off" />
            </div>
          </div>

          <div class="w-full mt-7">
            <div class="flex space-x-2">
              <button v-if="titleChange > 1" @click="editSupplierProduct()" type="submit"
                class="w-24 mr-1 btn btn-primary">Update</button>
              <button v-if="titleChange === 0" @click="addSupplierProduct()" type="submit"
                class="w-24 mr-1 btn btn-primary">Add</button>
              <button v-if="titleChange > 1" @click="cancelProduct()" type="submit"
                class="w-24 mr-1 btn btn-outline-secondary">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="w-24 mr-1 btn btn-outline-secondary">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="w-24 btn btn-primary">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: View Sku Variant Modal -->
  <MoveableModal :show="viewSkuVariantModal" @hidden="viewSkuVariantModal = false" size="modal-xl" backdrop="static"
    @close="viewSkuVariantModal = false">
    <ModalBody class="p-0">
      <div class="p-5 text-base font-semibold text-center uppercase lg:text-lg">{{ formDataSku.skuName }} - SKU Variant
      </div>
      <hr />
      <div class="w-full p-3 mt-3 sm:w-auto sm:mt-0">
        <div class="flex">
          <a v-if="!addSkuVariantModal" href="javascript:;" @click="addSkuVariant()"
            class="mr-2 shadow-md btn btn-primary"> Add New Variant </a>
          <div v-if="addSkuVariantModal" class="grid grid-cols-2 gap-2 lg:gap-4 md:gap-4">
            <div class="flex flex-col w-full space-y-1">
              <label for="regular-form-1" class="form-label">Variant Option</label>
            </div>
            <div class="flex items-end">
              <button @click="saveSkuVariant()" type="submit" class="w-24 mr-1 btn btn-primary">Add</button>
              <button type="button" @click="addSkuVariantModal = false" class="w-24 mr-1 btn btn-outline-secondary">
                {{ const_txt.CANCEL_BTN }}
              </button>
            </div>
          </div>
          <div class="hidden mx-auto md:block text-slate-500"></div>
        </div>
      </div>

      <div class="w-full mt-3 sm:w-auto sm:mt-0">
        <div class="relative w-full text-slate-500">
          <input type="text" class="w-full pr-10 form-control box" placeholder="Search..." v-model="searchData"
            @change="searchOnchange" autocomplete="off" />
          <SearchIcon @click="searchItem" v-if="!searchActive"
            class="absolute inset-y-0 right-0 w-6 h-6 my-auto mr-3 cursor-pointer" />
          <XIcon @click="clearSearch" v-if="searchActive"
            class="absolute inset-y-0 right-0 w-6 h-6 my-auto mr-3 cursor-pointer" />
        </div>
      </div>

      <div class="col-span-12 p-4 overflow-auto intro-y">
        <table class="table -mt-2 table-auto table-report">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in variantOptionsHeaders" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in skuVariantItems" :key="i" class="intro-x">
              <td v-for="(header, index) in skuVariantHeaders" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="w-56 table-report__action">
                <div class="flex items-center justify-center">
                  <a @click="editItemSkuVariant(item)" class="flex items-center mr-3 text-blue-900" href="javascript:;">
                    <Tippy tag="a" href="javascript:;" class="tooltip" content="Edit Option" :options="{
                      zIndex: 99999,
                    }">
                      <EditIcon class="w-4 h-4 mr-1 md:w-5 md:h-5 lg:w-6 lg:h-6" />
                    </Tippy>
                  </a>
                  <a @click="deleteItemSkuVariant(item)" class="flex items-center mr-3 text-danger" href="javascript:;">
                    <Tippy tag="a" href="javascript:;" class="tooltip" content="Delete" :options="{
                      zIndex: 99999,
                    }">
                      <Trash2Icon class="w-4 h-4 mr-1 md:w-5 md:h-5 lg:w-6 lg:h-6" />
                    </Tippy>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <!-- 
      <div
        class="flex flex-col items-center justify-center p-2 intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center"
        style="background-color: #164e63"
      >
        <div
          class="w-full sm:w-auto sm:mr-auto"
          style="
            background-color: #e1e7ef;
            border: 2px solid #164e63;
            border-radius: 10px;
          "
        >
       <paginate
            class="text-white"
            :page-count="pageCountOption"
            :page-range="5"
            :margin-pages="2"
            :click-handler="changePageOption"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :first-last-button="true"
            :initial-page="1"
            :prev-text="'<'"
            :next-text="'>'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
          >
          </paginate> 
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select
          v-model="pageSizeOption"
          class="w-20 cursor-pointer form-select box"
          @change="pageSizeChangeOption"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> -->

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>

  <!-- BEGIN: Archive Modal -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="float-right w-6 h-6 cursor-pointer text-danger lg:w-8 lg:h-8 md:w-7 md:h-7"
          @click="archiveModal = false" />
      </div>

      <div class="p-5 text-base font-semibold text-center uppercase lg:text-lg">Archived SKU</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full mt-3 sm:w-auto sm:mt-0">
        <div class="relative w-full text-slate-500">
          <input type="text" class="w-full pr-10 form-control box" placeholder="Search..." v-model="searchDataArchive"
            @change="searchOnchangeArchive" autocomplete="off" clearable />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false"
            class="absolute inset-y-0 right-0 w-6 h-6 my-auto mr-3 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true"
            class="absolute inset-y-0 right-0 w-6 h-6 my-auto mr-3 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <hr />
      <!-- BEGIN: Archive Data List -->

      <NoData :itemsData="archiveData" />

      <div class="col-span-12 p-4 overflow-auto intro-y" v-if="this.archiveData.totalCount > 0">
        <table class="table -mt-2 table-auto table-report">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in archiveHeaders" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in archiveHeaders" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="w-56 table-report__action">
                <div class="flex items-center justify-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)"
                    :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)"
                    :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <PaginationBar @changePage="changePageArchive($event)" @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData" :pageCount="pageCountArchive" :pageSize="pageSizeArchive" />

      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataSku.skuName" :modalStatus="archiveConfirmationModal"
    :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataSku.skuName" :modalStatus="restoreConfirmationModal"
    :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataSku.skuName" :modalStatus="deleteConfirmationModal"
    :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <DeleteConfirmModal
    :name="formDataSupplierProductEdit.supplierCompany + '(SPN-' + formDataSupplierProductEdit.productNumber + ')'"
    :modalStatus="deleteSupplierProductConfirmationModal" :modalConfirm="deleteSupplierProductItemConfirm"
    :modalClose="closeDeleteSupplierProductConfirmationModal" />

  <!-- END: Confirmation Modals -->

  <!-- BEGIN:  Notification Content -->

  <!-- END:  Notification Content -->

  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal :show="deleteSkuVariantConfirmationModal" @hidden="deleteSkuVariantConfirmationModal = false">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <Trash2Icon class="w-16 h-16 mx-auto mt-3 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-5 text-xl font-bold">
          {{ formDataSkuVariant.skuId }}
        </div>
        <div class="mt-2 text-slate-500">Do you really want to delete these records? <br />This process cannot be
          undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button type="button" @click="deleteSkuVariantConfirmationModal = false"
          class="w-24 mr-1 btn btn-outline-secondary">
          {{ const_txt.CANCEL_BTN }}
        </button>
        <button type="button" class="w-24 btn btn-danger" @click="deleteItemSkuVariantConfirm()">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- BEGIN: View Bin Info Modal -->

  <MoveableModal :show="skuInfoModal" @hidden="skuInfoModal = false" backdrop="backdrop" size="modal-sl-80" title="INFO"
    @close="skuInfoModal = false">
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
        <div class="flex flex-col items-center justify-between space-y-2 text-center">
          <qrcode-vue render-as="svg" :value="formDataSku.skuName" size="80" level="H" />
          <div class="mt-5 text-xl font-bold">PLU/SKU : {{ formDataSku.skuName }}</div>
        </div>
        <div class="p-5 mt-12 intro-y box sm:mt-5">
          <div class="grid grid-cols-6 gap-2 pt-4 intro-y box">
            <div class="relative col-span-6 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">PLU/SKU Detail</h3>
              <div class="grid h-full grid-cols-4 gap-2 p-4 border border-solid rounded-md border-slate-200">
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Description</div>
                  </div>
                  <div v-if="formDataSku.description" class="text-center">
                    {{ formDataSku.description }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>PLU/SKU</div>
                  </div>
                  <div v-if="formDataSku.skuName">
                    {{ formDataSku.skuName }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>BARCODE/EAN</div>
                  </div>
                  <div v-if="formDataSku.ean">
                    {{ formDataSku.ean }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>SOH</div>
                  </div>
                  <div v-if="formDataSkuSOH && formDataSkuSOH?.available">
                    {{ formDataSkuSOH?.available }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>RESERVED</div>
                  </div>
                  <div v-if="formDataSkuSOH && formDataSkuSOH?.designatedQuantity">
                    {{ formDataSkuSOH?.designatedQuantity }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Department</div>
                  </div>
                  <div v-if="formDataSku.department">
                    {{ formDataSku.department }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Unit</div>
                  </div>
                  <div v-if="formDataSku.unitId">
                    {{ getUnit(formDataSku?.unitId) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>VAT Percentage</div>
                  </div>
                  <div v-if="formDataSku.vatPercentage">
                    {{ (formDataSku.vatPercentage) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Minimum Margin</div>
                  </div>
                  <div v-if="formDataSku.minMargin">
                    {{ formDataSku.minMargin }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <v-select v-model="formDataSkuLocation" placeholder="LOCATIONS" class="style-chooser w-36"
                  :reduce="(e) => e.bin" :options="formDataSkuLocations" label="bin" />
              </div>
            </div> -->

            <div class="relative col-span-6 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Price & Discount Details</h3>
              <div class="grid h-full grid-cols-4 gap-2 p-4 border border-solid rounded-md border-slate-200">
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>PRICE</div>
                  </div>
                  <div v-if="formDataSku.price">
                    {{ currencyFormat(formDataSku.price) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>DISCOUNT</div>
                  </div>
                  <div>
                    <div class="ml-3 form-switch">
                      <input id="booleanSwitch" type="checkbox" v-model="formDataSku.isDiscount"
                        class="form-check-input" disabled />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Discounted Price</div>
                  </div>
                  <div v-if="formDataSku.discountedPrice">
                    {{ currencyFormat(formDataSku.discountedPrice) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <!-- <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <v-select v-model="formDataSkuSupplier" placeholder="SUPPLIERS" class="style-chooser w-36"
                  :reduce="(e) => e.supplierId" :options="formDataSkuSuppliers" label="supplierCompany" />
              </div>
            </div> -->

                <div v-if="quantityExist" class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>QTY</div>
                  </div>
                  <div v-if="formDataSkuLocationQty">
                    {{ formDataSkuLocationQty }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Discount Reason</div>
                  </div>
                  <div v-if="formDataSku.discountReason">
                    {{ formDataSku.discountReason }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Std cost</div>
                  </div>
                  <div v-if="formDataSku.stdCost">
                    {{ currencyFormat(formDataSku.stdCost) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Reprice markup</div>
                  </div>
                  <div v-if="formDataSku.rePriceMarkup">
                    {{ formDataSku.rePriceMarkup }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Reprice method</div>
                  </div>
                  <div v-if="formDataSku.rePriceMethod">
                    {{ formDataSku.rePriceMethod }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div v-if="supplierCodeExist" class="flex flex-col items-center mb-5 ml-8">
                  <div class="flex items-center font-bold uppercase">
                    <div class="w-40">Supplier Code</div>
                  </div>
                  <div class="w-40" v-if="formDataSkuSupplierCode">
                    {{ formDataSkuSupplierCode }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="relative col-span-6 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Manufacturer Details</h3>
              <div class="grid h-full grid-cols-4 gap-2 p-4 border border-solid rounded-md border-slate-200">
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Head office note</div>
                  </div>
                  <div v-if="formDataSku.headOfficeNote">
                    {{ formDataSku.headOfficeNote }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Manufacturer</div>
                  </div>
                  <div v-if="formDataSku.manufacturer">
                    {{ formDataSku.manufacturer }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Manf. product code</div>
                  </div>
                  <div v-if="formDataSku.manfProCode">
                    {{ formDataSku.manfProCode }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Buying mult</div>
                  </div>
                  <div v-if="formDataSku.buyingMult">
                    {{ formDataSku.buyingMult }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="relative col-span-6 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Other Details</h3>
              <div class="grid h-full grid-cols-4 gap-2 p-4 border border-solid rounded-md border-slate-200">
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Dimension</div>
                  </div>
                  <div v-if="formDataSku.dimension">
                    {{ formDataSku.dimension }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Weight</div>
                  </div>
                  <div v-if="formDataSku.weight">
                    {{ weightFormat(formDataSku.weight) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Temperature</div>
                  </div>
                  <div v-if="formDataSku.temperature">
                    {{ temperatureFormat(formDataSku.temperature) }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Sell price</div>
                  </div>
                  <div v-if="formDataSku.sellPrice">
                    {{ formDataSku.sellPrice }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>RRP</div>
                  </div>
                  <div v-if="formDataSku.rrp">
                    {{ formDataSku.rrp }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>RRP adjustment</div>
                  </div>
                  <div v-if="formDataSku.rrpAdjustment">
                    {{ formDataSku.rrpAdjustment }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Note</div>
                  </div>
                  <div v-if="formDataSku.note">
                    {{ formDataSku.note }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Shelf descr</div>
                  </div>
                  <div v-if="formDataSku.shelfDescription">
                    {{ formDataSku.shelfDescription }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Shelf label</div>
                  </div>
                  <div v-if="formDataSku.shelfLabel">
                    {{ formDataSku.shelfLabel }}
                  </div>
                  <div v-else class="text-red-600">
                    <span>Nill</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-span-12 overflow-auto intro-y">
            <div class="font-bold uppercase">Supplier Info</div>
            <table class="table -mt-2 table-auto table-report">
              <thead>
                <tr class="uppercase whitespace-nowrap">
                  <th>Supplier</th>
                  <th>SPN</th>
                  <th>Supplier Product Description</th>
                  <th>Price</th>
                  <th>Max Delivery Days</th>
                </tr>
              </thead>
              <tbody>
                <tr class="intro-x" v-for="(item, i) in binExistingProducts" :key="i">
                  <td>{{ item.supplierCompany }}</td>
                  <td>{{ item.productNumber }}</td>
                  <td>{{ item.productDescription }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.maxDeliveryTimeInDays }}</td>
                </tr>
              </tbody>
            </table>
            <div class="w-full col-span-12 p-2 mb-5 font-semibold text-center border-2 shadow-md"
              v-if="!(binExistingProducts && binExistingProducts.length > 0)">No Data</div>
          </div>

          <div class="col-span-12 overflow-auto intro-y">
            <div class="font-bold uppercase">Location Info</div>
            <table class="table -mt-2 table-auto table-report">
              <thead>
                <tr class="uppercase whitespace-nowrap">
                  <th>Location</th>
                  <th>Block Type</th>
                  <th>Quantity</th>
                  <th>Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                <tr class="intro-x" v-for="(item, i) in locationItems" :key="i">
                  <td>{{ item.bin }}</td>
                  <td>{{ item.blockType }}</td>
                  <td>{{ item.inStockQuantity }}</td>
                  <td>
                    <div v-if="item.expiryDate">
                      {{ momentMY(item.expiryDate) }}
                    </div>
                    <div v-else></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="w-full col-span-12 p-2 mb-5 font-semibold text-center border-2 shadow-md"
              v-if="!(locationItems && locationItems.length > 0)">No Data</div>
          </div>
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Delete Confirmation Modal -->

  <Modal size="modal-sm" :show="qrModal" @hidden="qrModal = false">
    <ModalBody class="flex flex-col items-center justify-center w-full space-y-2">
      <div class="w-full">
        <qrcode-vue v-if="formDataSku.skuId" render-as="svg" :value="formDataSku.skuName" size="250" level="H" />
      </div>

      <div>
        <span class="text-base font-medium leading-none lg:text-lg md:text-base text-black-900">PLU/SKU : {{
          formDataSku.skuName }}</span>
      </div>
    </ModalBody>
  </Modal>

  <Modal size="modal-sm" :show="reserveQtyDialog" @hidden="reserveQtyDialog = false">
    <ModalHeader>Reserved Quantity</ModalHeader>
    <ModalBody class="flex flex-col items-center justify-center w-full space-y-2">

      <div class="w-full">
        <input id="vertical-form-1" v-model="editReservedQty.quantity" type="number" autocomplete="off"
          class="form-control" placeholder="Reserved Quantity" />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="reserveQtyDialog = false" class="w-24 mr-4 btn btn-outline-secondary">
        Cancel
      </button>
      <button @click="reserveQtyDialog = false; saveReserveStock()" type="submit" class="w-24 btn btn-primary">
        Save
      </button>
    </ModalFooter>
  </Modal>


  <MoveableModal backdrop="backdrop" size="modal-sl-70" title="Print Queue" @close="closePrintQueue()"
    :show="printQueue" @hidden="printQueue = false">
    <ModalBody class="w-full">
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>COPIES</th>
              <th>PLU/SKU</th>
              <th>BARCODE/EAN</th>
              <th>description</th>
              <th>department</th>
              <th>price</th>
              <th>vat%</th>

              <th>Discount</th>
              <th>discounted Price</th>
              <th>temperature</th>
              <th>dimension</th>
              <th>weight</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in itemPrintQueueLocal" :key="i" class="intro-x">

              <td>

                {{ item.noOfCopies ? item.noOfCopies : 0 }}

              </td>
              <td>

                {{ item.skuName ? item.skuName : "" }}

              </td>
              <td>

                {{ item.ean ? item.ean : "" }}

              </td>
              <td>



              <td>
                <div class="text-left line-clamp-2" v-if="item.description">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description" :options="{
                    zIndex: 99999,
                  }">
                    {{ item.description }}
                  </Tippy>
                </div>
              </td>
              </td>
              <td>

                {{ item.department ? item.department : "" }}

              </td>
              <td>

                {{ currencyFormat(item.price) }}

              </td>
              <td>

                {{ item.vatPercentage ? item.vatPercentage + "%" : '0%' }}

              </td>
              <td>
                <div class="form-switch">
                  <input id="booleanSwitch" type="checkbox" v-model="item.isDiscount" class="form-check-input"
                    disabled />
                </div>
              </td>
              <td>

                {{ currencyFormat(item.discountedPrice) }}

              </td>
              <td>

                {{ item.temperature ? item.temperature + 'C' : "" }}

              </td>
              <td>

                {{ item.dimension ? item.dimension : "" }}

              </td>
              <td>

                {{ item.weight ? item.weight + "Kg" : "" }}

              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <PaginationBarLocal @changePage="changePagePrintQueue($event)" @pageSizeChange="pageSizeChangePrintQueue($event)"
        :itemsData="itemPrintQueueData" :pageCount="pageCountPrintQueue" :pageRange="pageRowCountPrintQueue"
        :pageSize="pageSizePrintQueue" />
    </ModalBody>
  </MoveableModal>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addCheckedPrintModal" @hidden="addCheckedPrintModal = false" backdrop="static"
    title="Do you want to print the selected label(s) ?" @close="closePrintLable()">
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col w-full space-y-4">
        <div class="relative col-span-3 pt-2">
          <div class="absolute -top-2.5 left-4 form-switch flex justify-center items-center space-x-2">
            <div class="flex space-x-2">
              <input type="radio" id="mobileip" value="0" v-model="choosePrint" />
              <label for="mobileip">Mobile Print</label>
            </div>
            <div class="flex space-x-2">
              <input type="radio" id="localip" value="1" v-model="choosePrint" />

              <label for="localip">Internal Print</label>
            </div>
            <div class="flex space-x-2">
              <input type="radio" id="publicip" value="2" v-model="choosePrint" />
              <label for="publicip">Print from web</label>
            </div>
          </div>
          <div class="w-full h-full p-4 border border-solid rounded-md border-slate-200" v-if="choosePrint > 0">
            <div class="flex flex-col w-full space-y-2">
              <div>
                <h2>{{ this.changePrintType }} : {{ this.defaultIp }}</h2>
              </div>
              <div>
                <label for="regular-form-1" class="form-label">Label Printer IP</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="formDataPrint.ip" type="text" autocomplete="off"
                    class="form-control" placeholder="IP" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="choosePrint > 0">
          <label for="regular-form-1" class="form-label">Port</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.port" autocomplete="off" type="text" class="form-control"
              placeholder="Port.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Number of Copies</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.nbrOfCopies" type="number" autocomplete="off"
              class="form-control" placeholder="Number of copies.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Selected Label(s)</label>

          <div id="labelsScroll" class="flex flex-col space-y-1 overflow-scroll h-36">
            <table>
              <tr class="text-left">
                <th>No</th>
                <th>SKU</th>
                <th>Description</th>
              </tr>
              <tr class="text-left" v-for="(item, i) in formDataPrint.skus" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.skuName }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </table>
            <!-- <div v-for="(item, i) in formDataPrint.skus" :key="i">
              {{ i + 1 }} : {{ item.skuName }} - {{ item.description }}
            </div> -->
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <!-- <button
        @click="confirmClientPrint()"
        type="submit"
        class="w-auto px-1 mr-1 btn whitespace-nowrap btn-primary"
      >
        Internal Print
      </button>
      <button
        @click="confirmPrint()"
        type="submit"
        class="w-auto px-1 mr-1 btn whitespace-nowrap btn-primary"
      >
        Print from web
      </button> -->
      <button @click="confirmPrint()" type="submit" class="w-auto px-1 mr-1 btn whitespace-nowrap btn-primary">Print
        Labels</button>
      <button type="button" @click="closePrintLable()" class="w-auto mr-1 btn btn-outline-secondary">
        {{ const_txt.CANCEL_BTN }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <MoveableModal :show="uploadModal" @hidden="uploadModal = false" size="modal-sl-80" backdrop="static"
    title="Upload CSV" @close="closeUploadModal()">
    <ModalBody class="flex justify-center space-y-4 w-full">
      <div class="flex flex-col w-full space-y-4">
        <div class="w-full">
          <!-- <label for="regular-form-1" class="form-label">Warehouse</label>
          <v-select v-model="formDataUploadWarehouseId" placeholder="SELECT" class="style-chooser" :filterable="false"
            @open="onOpenWarehouse" @close="onCloseWarehouse" @search="searchWarehouse" :key="abbreviation"
            :get-option-label="(option) => option.abbreviation" :options="warehousesList"
            :reduce="(abbreviation) => abbreviation.warehouseId" label="abbreviation">
            <template #list-footer>
              <li v-show="hasNextPageWarehouse" ref="loadWarehouse" class="loader">Loading more...</li>
            </template>
          </v-select> -->

          <!-- <Autocomplete
            ref="skuwarehouse2"
            v-model:modelValue="formDataUploadWarehouseId"
            label="Warehouse"
            itemText="abbreviation"
            itemValue="warehouseId"
            @load="newLoadWarehouse"
            refKey="skuwarehouse2"
          /> -->
        </div>

        <ArrangeCsv csvName="PLU/SKU" :fileUploadAPI="`Sku/${warehouseStoreId}/Upload/Csv`" :list="SkuHeaderList"
        templateAPI="Templates/Skus"  :warehouseId="warehouseStoreId" @getDataFromApi="getDataFromApi($event)"
          :templateShow="true" :isWarehouse="true" />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <div class="flex items-center justify-center space-x-2">
        <button type="button" @click="closeUploadModal()" class="w-24 btn btn-outline-secondary h-11">
          {{ const_txt.CANCEL_BTN }}
        </button>
        <!-- <UploadFile
          buttonName = "Upload PLU/SKU as a CSV file"
          :activeAccess="$h.addItemAccess(policyAccess)"
          @chooseFiles="chooseFiles($event)"
          @onFilePicked="onFilePicked($event)"
          @downloadTemplate="downloadTemplate($event)"
        /> -->

        <!-- <a
          v-if="$h.addItemAccess(policyAccess) == true"
          href="javascript:;"
          @click="chooseFiles()"
          class="w-full text-xs shadow-md btn btn-primary hover:text-yellow-200"
          >Upload File
          <UploadIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />

          <input
            id="fileInput"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            ref="fileInput"
            @change="onFilePicked"
            hidden
          />
        </a> -->

        <!-- <a
          v-if="$h.addItemAccess(policyAccess) == false"
          href="javascript:;"
          @click="chooseFiles()"
          class="w-full text-xs shadow-md cursor-not-allowed btn btn-primary hover:text-yellow-200"
          >Upload File
          <UploadIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />

          <input
            id="fileInput"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            ref="fileInput"
            disabled
            @change="onFilePicked"
            hidden
          />
        </a> -->

        <!-- <a
          href="javascript:;"
          @click="downloadTemplate()"
          class="text-xs shadow-md btn btn-primary lg:text-sm"
        >
          <svg
            @click="downloadTemplate()"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="w-4 h-4 text-white lg:w-6 lg:h-6 hover:text-yellow-200"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 1.5q.41 0 .7.3.3.29.3.7v19q0 .41-.3.7-.29.3-.7.3H7q-.41 0-.7-.3-.3-.29-.3-.7V18H1q-.41 0-.7-.3-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87 2.34-3.8H7.46l-1.3 2.4-.05.08-.04.09-.64-1.28-.66-1.29H2.59l2.27 3.82-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3Z"
            />
          </svg>
        </a> -->
      </div>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal :show="imageModal" @hidden="imageModal = false" backdrop="static" size="modal-xl" title="Product Image"
    @close="imageModal = false">
    <ModalBody class="flex justify-center space-y-4">
      <div class="grid grid-cols-1 divide-y">

        <a>

          <img :src="baseResourceUrl + imageModalUrl" style="width: 80%;  object-fit:cover; border-radius: 25px"
            v-if="imageModalUrl">
          <img src="@/assets/images/product-image-placeholder.jpg"
            style="width: 80%; object-fit:cover; border-radius: 25px" v-else>
        </a>

        <div>
          <div class="grid grid-cols-4 gap-2 p-2 lg:gap-4 md:gap-4">
            <div class="relative col-span-4 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Product Image</h3>
              <div
                class="grid h-full grid-cols-2 gap-2 p-4 border border-solid rounded-md border-slate-200 lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4">
                <div>

                  <div class="flex flex-col space-y-4">
                    <div class="flex items-center justify-center w-full" v-if="imageUrl != null">
                      <img :src="baseResourceUrl + imageModalUrl" class="object-fill w-full h-16" />
                    </div>
                    <div class="w-full">
                      <a href="javascript:;" @click="chooseProductImageFiles()"
                        class="w-full text-xs shadow-md btn btn-primary hover:text-yellow-200">Upload Image
                        <UploadIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />

                        <input id="skuImageFileInput" accept="image/*" type="file" @change="onProductImageFilePicked"
                          hidden />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <!-- <ModalFooter class="text-right">
      <button
        type="button"
        @click="closeAddForm()"
        class="w-24 mr-4 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button @click="saveForm()" type="submit" class="w-24 btn btn-primary">
        Done
      </button>
    </ModalFooter> -->
  </MoveableModal>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";
import { PencilIcon } from 'lucide-vue-next'

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import QrcodeVue from "qrcode.vue";
import QRCode from "qrcode";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";
import axiosReal from "axios";
import store from "../../../store";
import validationMixins from "../../../mixins/validation";
import moment from "moment";
import ImageView from "@/views/Components/ImageView.vue";

export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "PLU/SKU");
    this.observerWarehouse = new IntersectionObserver(this.infiniteScrollWarehouse);
    this.observerUsersWarehouse = new IntersectionObserver(this.infiniteScrollUsersWarehouse);
    this.observerUnit = new IntersectionObserver(this.infiniteScrollUnit);
    this.observerProduct = new IntersectionObserver(this.infiniteScrollProduct);
    this.observerDep = new IntersectionObserver(this.infiniteScrollDep);
    this.observerSup = new IntersectionObserver(this.infiniteScrollSup);
  },
  components: { ImageView },
  data() {
    return {
      editReservedQty: { ean: '', sku: '', quantity: 0 },
      reserveQtyDialog: false,
      viewSOH: 0,
      choosePrint: 0,
      ipType: "Local IP",
      defaultIp: "",
      SkuHeaderList: ["Code", "Name", "Department", "SOH", "Cost", "Sell", "Barcode"],
      storeUserRole: store.state.authUser.userRole,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      const_txt: const_txt,
      isLoading: false,
      skuInfoModal: false,
      viewAddskuForm: true,
      qrModal: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      addSkuVariantModal: false,
      viewSkuVariantModal: false,

      addSku: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataSku: {
        skuName: "",
        ean: "",
        description: "",
        productId: null,
        price: "",
        vatPercentage: 15,
        isDiscount: true,
        discountedPrice: "",
        discountReason: "",
        temperature: "",
        dimension: "",
        weight: "",
        minMargin: "",
        department: null,
        unitId: null,
        minStock: "",
        sellPrice: "",
        headOfficeNote: "",
        manufacturer: "",
        manfProCode: "",
        buyingMult: "",
        rrp: "",
        rrpAdjustment: "",
        rePriceMethod: "",
        rePriceMarkup: "",
        note: "",
        shelfLabel: "",
        shelfDescription: "",
        stdCost: "",
        categoryId: null,
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },
      formDataSkuDefault: {
        skuName: "",
        ean: "",
        description: "",
        productId: null,
        price: "",
        vatPercentage: 15,
        isDiscount: true,
        discountedPrice: "",
        discountReason: "",
        temperature: "",
        dimension: "",
        weight: "",
        department: null,
        unitId: null,
        minStock: "",
        sellPrice: "",
        minMargin: "",
        headOfficeNote: "",
        manufacturer: "",
        manfProCode: "",
        buyingMult: "",
        rrp: "",
        rrpAdjustment: "",
        rePriceMethod: "",
        rePriceMarkup: "",
        note: "",
        shelfLabel: "",
        shelfDescription: "",
        stdCost: "",
        categoryId: null,
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },

      rules: {
        skuName: {
          required,
          minLength: minLength(2),
        },
        description: {
          required,
          minLength: minLength(2),
        },
        abbreviation: {
          required,
          maxLength: maxLength(10),
        },
      },
      headers: [{ text: "BARCODE/EAN", value: "ean" }],

      archiveHeaders: [
        // { text: "ID", value: "skuId" },
        { text: "SKU", value: "skuName" },
        { text: "BARCODE/EAN", value: "ean" },
        { text: "DESC", value: "description" },
        { text: "Price", value: "price" },
        { text: "SOH", value: "available" },
        { text: "Discount", value: "isDiscount" },
        // { text: "Product", value: "productId" },

        // { text: "VAT Percentage", value: "vatPercentage" },
        // { text: "Discount", value: "isDiscount" },
        // { text: "Discounted Price", value: "discountedPrice" },
        // { text: "Discount Reason", value: "discountReason" },
      ],
      headersFilter: [
        { text: "DESC", value: "description" },
        { text: "BARCODE/EAN", value: "ean" },
      ],
      percentage: 30,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "description",
        sortDirection: "ASC",
        // searchColumn: "description",
        // sortColumnName: "description",
        // sortDirection: "asc",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "description",
        sortDirection: "ASC",
      },
      productList: [],
      unitList: [],
      departmentList: [],
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "description",
        // sortDirection: "asc",
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,
      serverError: false,
      errorMessage: "",

      //SkuVariant
      formDataSkuVariant: {
        skuId: "",
        variantOptionId: "",
      },
      formDataSkuVariantDefault: {
        skuId: "",
        variantOptionId: "",
      },

      skuVariantHeaders: [
        { text: "PLU/SKU", value: "skuId" },
        { text: "Variant Option", value: "variantOptionId" },
      ],
      skuVariantItems: [],
      skuVariantItemsData: [],

      variantOptions: [],
      skuChange: -1,

      checkedData: [],

      addCheckedPrintModal: false,
      formDataPrint: {
        skus: [],
        ip: "192.0.0.0",
        port: "8100",
        nbrOfCopies: 1,
      },
      allSelected: false,
      uploadedfile: null,
      fileName: " ",
      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      unitItems: [],
      formDataSkuSupplier: "",
      formDataSkuSupplierCode: "",
      formDataSkuSuppliers: [],
      supplierCodeExist: false,

      supplierProductList: [],
      supplierProducts: [],
      binExistingProducts: [],
      locationItems: [],
      viewBinProducts: false,

      formDataSupplierProduct: [
        {
          productNumber: "",
          supplierId: "",
          skuId: "",
          productDescription: "",
          maxDeliveryTimeInDays: "",
          price: "",
        },
      ],
      formDataSupplierProductEdit: {
        productNumber: "",
        supplierId: "",
        skuId: "",
        productDescription: "",
        maxDeliveryTimeInDays: "",
        price: "",
      },
      formDataSupplierProductDefault: {
        productNumber: "",
        supplierId: undefined,
        skuId: "",
        productDescription: "",
        maxDeliveryTimeInDays: "",
        price: "",
      },
      suppliers: [],

      formDataSkuLocation: "",
      formDataSkuLocationQty: "",
      formDataSkuLocations: [],
      quantityExist: false,
      formDataSkuSOH: {},
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      splitData: false,

      policyAccess: "SKUs",

      supplierProductChange: -1,
      deleteSupplierProductConfirmationModal: false,
      warehousesList: [],
      uploadModal: false,
      imageModal: false,
      imageModalUrl: '',
      imageModalSku: 0,
      uploadcsvfile: null,

      observerUnit: null,
      limitUnit: 5,
      hasNextPageUnit: false,
      totalUnitCount: 0,
      isUnitSearchResult: false,
      unitPageNumber: 1,
      unitSearchTimer: undefined,

      observerDep: null,
      limitDep: 5,
      hasNextPageDep: false,
      totalDepCount: 0,
      isDepSearchResult: false,
      depPageNumber: 1,
      depSearchTimer: undefined,

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,

      warehousesearch: "",
      observerUsersWarehouse: null,
      limitUsersWarehouse: 5,

      observerSup: null,
      limitSup: 5,
      hasNextPageSup: false,
      totalSupCount: 0,
      isSupSearchResult: false,
      supPageNumber: 1,
      supSearchTimer: undefined,

      observerProduct: null,
      limitProduct: 5,
      hasNextPageProduct: false,
      totalProductCount: 0,
      isProductSearchResult: false,
      productPageNumber: 1,
      productSearchTimer: undefined,
      count: 1,
      hasScrolledToBottom: false,

      updateProductItem: null,
      updateWarehouseItem: null,
      updateDepartmentItem: null,
      updateUnitItem: null,
      updateSupplierProductItem: null,


      printQueue: false,
      itemPrintQueue: [],
      pageCountPrintQueue: 0,
      pageRowCountPrintQueue: 5,
      printQueueStart: 0,
      printQueueEnd: 5,
      itemPrintQueueLocal: [],
      pageNumberPrintQueue: 1,
      pageSizePrintQueue: 10,
      itemPrintQueueData: {}
    };
  },
  computed: {
    baseResourceUrl() {
      return axios.defaults.baseURL.replace('/api', '')
    },
    changePrintType() {
      return this.choosePrint === "1" ? "Local IP" : this.choosePrint === "2" ? "Public IP" : "";
    },
    supplierProductTitle() {
      return this.supplierProductChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },

    binViewTitle() {
      return this.viewBinProducts == true ? "Existing Supplier" : "View Existing Supplier";
    },
    binViewSkuTitle() {
      return this.viewAddskuForm == true ? "Sku Info" : "View Sku Info";
    },
    formTitle() {
      return this.titleChange === -1 ? "Add New SKU" : this.formDataSku?.skuName ? `Edit SKU - ${this.formDataSku?.skuName}` : "Edit SKU";
    },
    skuTitle() {
      return this.skuChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },

    checkLimtUnit() {
      return this.unitPageNumber;
    },
    checkLimtProduct() {
      return this.productPageNumber;
    },
    checkLimtDep() {
      return this.depPageNumber;
    },

    checkLimtWarehouse() {
      return this.whPageNumber;
    },

    checkLimtSup() {
      return this.supPageNumber;
    },
    viewModalUnit() {
      return this.formDataSku.unitId;
    },
    viewModalProduct() {
      return this.formDataSku.productId;
    },
    viewModalDep() {
      return this.formDataSku.department;
    },
    viewModalWh() {
      return this.formDataSku.warehouseId;
    },

    viewModalSup() {
      return this.formDataSupplierProduct.supplierId;
    },

    getUsersWarehouses() {
      this.isLoading = true;
      let storedArrayData = store.state.authUser.licensee.warehouses;
      if (storedArrayData.length > 0) {
        this.isLoading = false;
        this.warehousesList = storedArrayData;
        return this.warehousesList.filter((x) => x.abbreviation.toLowerCase().includes(this.warehousesearch.toLowerCase()));
      } else {
        this.isLoading = false;
        warningToast("There are no Warehouses found");
      }
    },
    paginatedUserWarehouse() {
      return this.getUsersWarehouses.slice(0, this.limitUsersWarehouse);
    },
    hasNextPageUsersWarehouse() {
      return this.paginatedUserWarehouse.length < this.getUsersWarehouses.length;
    },
  },
  created() {
    // this.warehouseAbbreviation = store.state.authUser.warehouse.abbreviation;
    this.getDataFromApi();
    this.getUnitWithoutl();
    this.getUnitData();
  },
  watch: {
    choosePrint() {
      this.defaultIp = "";
      return this.setIp();
    },
    viewSkuVariant(val) {
      val || this.viewSkuVariantClose();
    },
    formDataSkuSupplier() {
      this.viewSupplierCode();
    },
    formDataSkuLocation() {
      this.viewSkuLocationQty();
    },

    checkLimtUnit() {
      this.getUnitData();
    },
    checkLimtProduct() {
      this.getProductData();
    },
    checkLimtDep() {
      this.getDepartmentData();
    },

    checkLimtWarehouse() {
      this.getWarehouses();
    },
    checkLimtSup() {
      this.getSuppliers();
    },
    viewModalUnit() {
      if (this.formDataSku.unitId === null) {
        this.clearUnit();
      }
    },
    async viewModalProduct(val, oldval) {
      if (val === null) {
        this.clearProduct();
      }

      if (this.titleChange === -1) {
        if (val !== "") {
          this.formDataSku.description = this.productList.find((x) => x.productId === val)?.name;
        }
      } else {
        this.formDataSku.description =
          this.formDataSku.description && oldval === undefined
            ? this.formDataSku.description
            : val
              ? this.productList.find((x) => x.productId === val)?.name
              : this.formDataSku.description;
      }
    },
    viewModalDep() {
      if (this.formDataSku.department === null) {
        this.clearDep();
      }
    },
    viewModalSup() {
      if (this.formDataSupplierProduct.supplierId === null) {
        this.clearSupplier();
      }
    },
    viewModalWh() {
      if (this.formDataSku.warehouseId === null) {
        this.clearWarehouse();
      }
    },

  },
  methods: {
    addStock(item) {
      this.$router.push({ path: "/wms/addstocks", query: { sku: `${item.skuName}`, }, });
    },
    changePagePrintQueue(pageNum) {
      this.pageNumberPrintQueue = pageNum;
      this.printQueueEnd = this.pageRowCountPrintQueue * pageNum;
      this.printQueueStart = this.printQueueEnd - this.pageRowCountPrintQueue;
      this.itemPrintQueueLocal = this.itemPrintQueue.slice(this.printQueueStart, this.printQueueEnd);
    },

    openPrintQueue() {
      this.isLoading = true;
      axios
        .get("PrintingQueues/Skus/" + this.warehouseStoreId)
        .then((response) => {
          if (response.data.success) {
            this.itemPrintQueueData = response.data
            this.itemPrintQueue = response.data?.data
            this.printQueue = true
            this.setLocalPagination();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    setLocalPagination() {
      if (this.itemPrintQueueData) {
        this.itemPrintQueueData.totalCount = this.itemPrintQueue.length;
      }

      var pageCount = this.itemPrintQueue.length / this.pageRowCountPrintQueue;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountPrintQueue = pageCountRound;
      } else {
        this.pageCountPrintQueue = pageCountRound;
      }
      if (this.pageRowCountPrintQueue > this.itemPrintQueueData.totalCount) {
        this.pageSizePrintQueue = this.itemPrintQueueData.totalCount;
      } else {
        this.pageSizePrintQueue = this.pageRowCountPrintQueue;
      }

      this.itemPrintQueueLocal = this.itemPrintQueue.slice(this.printQueueStart, this.printQueueEnd);
    },
    closePrintQueue() {
      this.printQueue = false
    },
    handleScroll(el) {
      if (el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight) {
        if (this.itemsData.totalPages > this.pagination.pageNumber) {
          this.hasScrolledToBottom = true;
          this.pagination.pageNumber = this.pagination.pageNumber + 1;
          this.changePage(this.pagination.pageNumber);
          this.$refs.scroll.scroll(0, 0);
        }
      }
    },
    addField(value, fieldType) {
      this.count++;
      fieldType.push({
        productNumber: "",
        supplierId: "",
        skuId: "",
        productDescription: "",
        maxDeliveryTimeInDays: "",
        price: "",
      });
    },
    removeField(index, fieldType) {
      this.count = this.count - 1;
      fieldType.splice(index, 1);
    },
    removeAllField(index, fieldType, count) {
      fieldType.splice(index, count);
      this.count = 1;
    },
    onUserWarehouseSearch(query) {
      this.warehousesearch = query;
    },

    async onOpenUsersWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.hasNextPageUsersWarehouse) {
        await this.$nextTick();
        this.observerUsersWarehouse.observe(this.$refs.loadUsersWarehouse);
      }
    },
    onCloseUsersWarehouse() {
      this.observerUsersWarehouse.disconnect();
      this.limitUsersWarehouse = 5;
    },
    async infiniteScrollUsersWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitUsersWarehouse = this.limitUsersWarehouse + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenUnit() {
      if (this.isUnitSearchResult) {
        this.unitPageNumber = 1;
      }
      if (this.hasNextPageUnit) {
        await this.$nextTick();
        this.observerUnit.observe(this.$refs.loadUnit);
      }
    },
    onCloseUnit() {
      this.observerUnit.disconnect();
    },
    async infiniteScrollUnit([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.unitPageNumber++;
        this.limitUnit;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenProduct() {
      if (this.isProductSearchResult) {
        this.productPageNumber = 1;
      }
      if (this.hasNextPageProduct) {
        await this.$nextTick();
        this.observerProduct.observe(this.$refs.loadProduct);
      }
    },
    onCloseProduct() {
      this.observerProduct.disconnect();
    },
    async infiniteScrollProduct([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.productPageNumber++;
        this.limitProduct;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenDep() {
      if (this.isDepSearchResult) {
        this.depPageNumber = 1;
      }
      if (this.hasNextPageDep) {
        await this.$nextTick();
        this.observerDep.observe(this.$refs.loadDep);
      }
    },
    onCloseDep() {
      this.observerDep.disconnect();
    },
    async infiniteScrollDep([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.depPageNumber++;
        this.limitDep;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSup() {
      if (this.isSupSearchResult) {
        this.supPageNumber = 1;
      }
      if (this.hasNextPageSup) {
        await this.$nextTick();
        this.observerSup.observe(this.$refs.loadSup);
      }
    },
    onCloseSup() {
      this.observerSup.disconnect();
    },
    async infiniteScrollSup([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supPageNumber++;
        this.limitSup;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    openUploadModal() {
      this.uploadModal = true;
      //this.getWarehouses();
    },
    closeUploadModal() {
      this.uploadModal = false;
      this.uploadcsvfile = null;
      this.warehousesList = [];
    },

    clearWarehouse() {
      this.warehousesList = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehousesList = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },

    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehousesList =
            this.isWhSearchResult !== true
              ? Array.from(new Set([...this.warehousesList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    newLoadWarehouse(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          let list = [...JSON.parse(JSON.stringify(this.warehousesList)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
          this.warehousesList = list;

          options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    closeUpdateSupplierProducts() {
      this.supplierProductChange = -1;
      this.formDataSupplierProductEdit = Object.assign({}, this.formDataSupplierProductDefault);
    },

    editItemSupplierProduct(item) {
      this.supplierProductChange = 0;
      this.formDataSupplierProductEdit = Object.assign({}, item);
      this.titleChange = 2;

      //this.formDataSupplierProductEdit.supplierId = this.formDataSupplier.supplierId;

      console.log("formDataSupplierProductEdit", this.formDataSupplierProductEdit);
      // this.activateModalSupplierProducts();
      // this.addSupplierProductModal = true;
    },

    deleteItemSupplierProduct(item) {
      this.formDataSupplierProductEdit = Object.assign({}, item);
      this.deleteSupplierProductConfirmationModal = true;
    },
    deleteSupplierProductItemConfirm() {
      var itemId = this.formDataSupplierProductEdit.supplierProductId;
      this.isLoading = true;
      axios
        .delete("SupplierProducts/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteSupplierProductConfirmationModal = false;
            this.getSupplierProductsBySkuId(this.formDataSku.skuId);
            this.closeUpdateSupplierProducts();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    closeDeleteSupplierProductConfirmationModal() {
      this.deleteSupplierProductConfirmationModal = false;
    },

    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    clearSupplier() {
      this.suppliers = [];
      this.supPageNumber = 1;
      this.limitSup = 5;
      this.getSuppliers("");
    },

    searchSupplier(search) {
      clearInterval(this.supSearchTimer);
      this.supSearchTimer = setTimeout(() => {
        if (search) {
          this.suppliers = [];
          this.supPageNumber = 1;
          this.limitSup = this.totalSupCount;
          this.getSuppliers(search.trim());
        }
      }, 2000);
    },
    async getSuppliers(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.supPageNumber,
        pageSize: this.limitSup,
        search: search,
      };
      await axios
        .post("Suppliers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isSupSearchResult = !!search;
            this.suppliers =
              this.isSupSearchResult !== true
                ? Array.from(new Set([...this.suppliers, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSup = response.data.data.hasNextPage;
            this.totalSupCount = response.data.data.totalCount;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    newLoadSupplier(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };

      axios
        .post("Suppliers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            let list = [...JSON.parse(JSON.stringify(this.suppliers)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.suppliers = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewSupplierCode() {
      let item = this.formDataSkuSupplier;
      if (item != null) {
        this.supplierCodeExist = true;
        var supplier = this.formDataSkuSuppliers.filter((obj) => {
          return obj.supplierId == item;
        });
        this.formDataSkuSupplierCode = supplier[0].productNumber;
      } else {
        this.supplierCodeExist = false;
      }
    },
    viewSkuLocationQty() {
      let item = this.formDataSkuLocation;

      if (item != null) {
        this.quantityExist = true;
        var location = this.formDataSkuLocations.filter((obj) => {
          return obj.bin == item;
        });
        this.formDataSkuLocationQty = location[0].quantity;
      } else {
        this.quantityExist = false;
      }
    },

    getUnit(value) {
      try {
        return this.unitList.find((x) => x.unitId === parseInt(value)).unitName;
      } catch {
        return "Null";
      }
    },

    getUnitWithoutl(value) {
      try {
        return this.unitList.find((x) => x.unitId === parseInt(value)).unitName;
      } catch {
        return "";
      }
    },

    getProduct(value) {
      try {
        return this.productList.find((x) => x.productId == parseInt(value)).name;
      } catch {
        return "";
      }
    },

    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "QR", dataKey: "qr" },
              { title: "PLU/SKU", dataKey: "skuName" },
              { title: "BARCODE/EAN", dataKey: "ean" },
              { title: "Description", dataKey: "description" },
              { title: "Price", dataKey: "price" },
              { title: "Department", dataKey: "department" },
              { title: "Weight", dataKey: "weight" },
              { title: "Unit", dataKey: "unitId" },
              { title: "Minimum Stock", dataKey: "minStock" },
              { title: "Temperature", dataKey: "temperature" },
              { title: "Dimension", dataKey: "dimension" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = await this.getItemsWithQR(items);

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              columnStyles: {
                1: {
                  rowHeight: 1,
                  cellWidth: 1,
                },
              },
              margin: { left: 0.5, top: 0.5 },
              didDrawCell: this.didDrawCell,
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
              this.docs.setPage(i);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${i} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    getItemsWithQR(list) {
      return new Promise((resolve, reject) => {
        try {
          var data = [];
          let i = 0;
          list.forEach(async (el) => {
            i += 1;
            var obj = {
              no: i,
              skuName: el.skuName,
              ean: el.ean,
              description: el.description,
              price: el.price,
              department: el.department,
              weight: el.weight,
              unitId: this.getUnitWithoutl(el.unitId),
              minStock: el.minStock,
              temperature: this.temperatureFormat(el.temperature),
              dimension: el.dimension,
            };
            obj.qrData = await QRCode.toDataURL(JSON.stringify(el.skuName));
            data.push(obj);
          });
          resolve(data);
        } catch (error) {
          reject([]);
        }
      });
    },

    didDrawCell(data) {
      if (data.column.dataKey === "qr" && data.cell.section === "body") {
        var dim = data.cell.height - data.cell.padding("vertical");
        var textPos = data.cell;

        if (data.row && data.row.raw && data.row.raw.qrData) {
          this.docs.addImage(data.row.raw.qrData, textPos.x + 0.05, textPos.y + 0.04, dim, dim);
        }
      }
    },

    async printAllPages() {
      var printLimit = const_txt.BIN_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },

    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.BIN_PRINT_LIMIT,
      };
      //this.isLoading = true;
      await axios
        .post("Sku/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.isLoading = false;
            this.pdfPrintEach(items);
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(error);
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports().then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports().then((res) => {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"SKU " + generateDate}.pdf`);
      });
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;

      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Sku/Paginated", pagination)
        .then((response) => {
          if (response.status === 200) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "SKU " + generateDate;

        var items = this.allItems;

        var csvItems = [];

        var csvHeaders = {
          1: "PLU/SKU",
          2: "BARCODE/EAN",
          3: "Description",
          4: "Price",
          5: "Department",
          6: "Weight",
          7: "Unit",
          8: "Minimum Stock",
          9: "Temperature",
          10: "Dimension",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.skuName,
            2: item.ean,
            3: item.description,
            4: item.price,
            5: item.department,
            6: item.weight,
            7: this.getUnitWithoutl(item.unitId),
            8: item.minStock,
            9: item.temperature,
            10: item.dimension,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    chooseFiles() {
      // this.$refs.fileInput.click();
      // console.log("file upload");
      const fileInput = document.getElementById("fileInput");
      fileInput.value = "";
      this.$refs.fileInput.click();
      console.log("file upload");
    },
    // onFilePicked(uploadedfile) {
    //   if (this.formDataUploadWarehouseId == "" || this.formDataUploadWarehouseId == null) {
    //     if (this.formDataUploadWarehouseId == "") {
    //       warningToast("Warehouse Required");
    //     }
    //   }
    //   else{
    //     this.fileName = uploadedfile.name;
    //   let formData = new FormData();

    //   formData.append("File", uploadedfile);
    //   formData.append("Name", uploadedfile.name);

    //   this.isLoading = true;
    //   axios
    //     .post("Sku/"+this.formDataUploadWarehouseId+"/Upload/Csv", formData)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         this.getDataFromApi();
    //         successToast(response.data.message);
    //       } else {
    //         this.isLoading = false;

    //         failedToast(response.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //       this.isLoading = false;
    //     });
    //     this.formDataUploadWarehouseId = ""

    //   }

    // },

    onFilePicked(event) {
      this.uploadcsvfile = event.target.files[0];
      if (this.uploadcsvfile == null) {
        if (this.uploadcsvfile == null) {
          warningToast("Csv file Required");
        }
      } else {
        // this.fileName = uploadedfile.name;
        let formData = new FormData();

        formData.append("File", this.uploadcsvfile);
        formData.append("Name", this.uploadcsvfile.name);

        this.isLoading = true;
        axios
          .post("Sku/" + this.warehouseStoreId + "/Upload/Csv", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;

              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            warningToast(statusMessage(error.response.status));
            this.isLoading = false;
          });

        this.uploadcsvfile = null;
      }
    },
    downloadTemplate() {
  this.isLoading = true;
  axios
    .get("Templates/Skus", { responseType: 'blob' })  
    .then((response) => {
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'templateSku.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();  
      this.isLoading = false;
    })
    .catch((error) => {
      warningToast(statusMessage(error.response.status));
      this.isLoading = false;
    });
},

    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    closePrintLable() {
      this.addCheckedPrintModal = false
      this.checkedData = [];
    },
    confirmPrint() {
      if (this.choosePrint === 0) {
        this.mobilePrint();
      } else if (this.choosePrint === 1) {
        this.confirmClientPrint();
      } else {
        if (this.formDataPrint.ip == "" || this.formDataPrint.port == "" || this.formDataPrint.nbrOfCopies == "") {
          if (this.formDataPrint.ip == "") {
            warningToast("IP Required");
          }
          if (this.formDataPrint.port == "") {
            warningToast("Port Required");
          }
          if (this.formDataPrint.nbrOfCopies == "") {
            warningToast("Num of Copies Required");
          }
        } else {
          this.isLoading = true;
          axios
            .post("Sku/PrintSkus ", this.formDataPrint)
            .then((response) => {
              if (response.data.success === true) {
                successToast(response.data.message);

                this.isLoading = false;
              } else {
                failedToast(response.data.message);
                this.isLoading = false;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
          this.addCheckedPrintModal = false;
          this.checkedData = [];
        }
      }
    },

    mobilePrint() {

      var formData = {
        skuIds: this.formDataPrint.skus.map((x) => x.skuId),
        noOfCopies: this.formDataPrint.nbrOfCopies,
        warehouseId: this.warehouseStoreId,
      };

      this.isLoading = true
      axios
        .post("PrintingQueues/Skus", formData)
        .then((response) => {
          if (response.data.success) {
            successToast(response.data.message);
            this.closePrintLable();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    confirmClientPrint() {
      this.$printerHub.connect({
        host: this.formDataPrint.ip,
        port: this.formDataPrint.port,
        timeout: 3000,
      });

      let lastValues = this.formDataPrint.ip.split(".")[3];
      if (this.formDataPrint !== null) {
        store.dispatch("authUser/setlocalIp", lastValues);
      }

      let selectedLables = this.checkedData;
      let maxLables = 3;
      let totalLables = selectedLables.length;
      let totalBatches = Math.ceil(totalLables / maxLables);
      let positions = [
        {
          l1: "FT53",
          l2: "FT82",
        },
        {
          l1: "FT330",
          l2: "FT360",
        },
        {
          l1: "FT602",
          l2: "FT632",
        },
      ];
      for (let batch = 1; batch <= totalBatches; batch++) {
        let start = (batch - 1) * maxLables;
        let end = batch * maxLables;
        let batchLabels = selectedLables.slice(start, end);

        let query = `^XA^LS0,^LT8`;
        var data;
        setTimeout(() => {
          for (let i = 0; i < this.formDataPrint.nbrOfCopies; i++) {
            for (let position = 0; position < batchLabels.length; position++) {
              data = query += `^${positions[position].l1},192^BQN,2,8
^FH\^FDLA,${batchLabels[position].skuName}^FS
^${positions[position].l2},198^CFA,30^FD${batchLabels[position].skuName}^FS
^PQ${this.formDataPrint.nbrOfCopies},0,1,Y`;

              this.$printerHub.write(data + "^XZ");
            }
          }
          console.log("SKU : ", data + "^XZ");
        }, 2000);

        this.addCheckedPrintModal = false;
        this.checkedData = [];
      }
    },

    setIp() {
      if (this.choosePrint === 1) {
        let config = this.$printerHub.getConfig();
        if (config.host) {
          var lastValue = localStorage.getItem("localIp") !== null ? JSON.parse(localStorage.getItem("localIp")) : "";
          var host = config.host;
          var ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          if (ipRegex.test(host)) {
            var split = host.split(/\.(?=[^\.]+$)/);
            this.formDataPrint.ip = split[0] + "." + lastValue;
          } else {
            this.formDataPrint.ip = host + lastValue;
          }
          this.formDataPrint.port = config.port;
          this.defaultIp = config.host;
          successToast("Internal print configuration selected");
        } else {
          failedToast("Local IP is not conneced, please check your configuration");
        }
      }

      if (this.choosePrint === 2) {
        this.getDefaultIP();
        this.formDataPrint.port = 8100;
        successToast("Print for web configuration selected");
      }
    },

    printLabels() {
      this.setIp();
      this.formDataPrint.skus = [];
      if (this.checkedData.length > 0) {
        this.addCheckedPrintModal = true;
        for (var i = 0; i < this.checkedData.length; i++) {
          this.formDataPrint.skus[i] = this.checkedData[i];
        }
      } else {
        warningToast("Please Select PLU/SKU");
      }
    },

    getDefaultIP() {
      this.isLoading = true;
      axiosReal
        .get("https://api.ipify.org/")
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.defaultIp = response.data;
            this.formDataPrint.ip = response.data;
          } else {
            this.isLoading = false;
            failedToast("something went wrong in getting public IP");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },

    getSelectedColor() {
      // return "background-color:;color: white";
    },
    viewSkuVariantClose() {
      // this.isLoading = true;

      // for (var i = 0; i < this.items.length; i++) {
      //   this.items[i].selected = false;
      // this.isLoading = false;

      this.viewSkuVariantModal = false;
      // }
    },
    showQrModal(item) {
      removeNullValue(item);
      this.formDataSku = Object.assign({}, item);
      this.qrModal = true;
    },
    async viewSkuInfo(item) {
      this.formDataSku = Object.assign({}, item);
      this.getSupplierProducts();
      this.getSkuLocation();
      this.getSOH();
      this.getSkuById(item.skuId);
      this.getSupplierProductsBySkuId(item.skuId);
      this.loadSkuLocation(item.skuName);
      this.skuInfoModal = true;
    },
    async getSkuLocation() {
      var sku = this.formDataSku.skuName;

      this.isLoading = true;
      await axios
        .get(`MemoryStocks/${this.warehouseAbbreviation}/Products/${sku}`)
        .then((response) => {
          if (response.status === 200) {
            console.log("res:", response);
            this.formDataSkuLocations = response.data;
          } else {
            warningToast(response.data.message);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    async getSupplierProducts() {
      this.isLoading = true;
      await axios
        .get("SupplierProducts/Products/" + this.formDataSku.skuId)
        .then((response) => {
          if (response.status === 200) {
            this.formDataSkuSuppliers = response.data.data;
          } else {
            warningToast(response.data.message);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    async getSOH() {
      var sku = this.formDataSku.skuName;

      this.isLoading = true;
      await axios
        .get(`MemoryStocks/${this.warehouseAbbreviation}/Product/${sku}`)
        .then((response) => {
          if (response.status === 200) {
            this.formDataSkuSOH = response.data;
          } else {
            this.formDataSkuSOH = null;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    clearProduct() {
      this.productList = [];
      this.productPageNumber = 1;
      this.limitProduct = 5;
      this.getProductData("");
    },

    searchProduct(search) {
      clearInterval(this.productSearchTimer);
      this.productSearchTimer = setTimeout(() => {
        if (search) {
          this.productList = [];
          this.productPageNumber = 1;
          this.limitProduct = this.totalProductCount;
          this.getProductData(search.trim());
        }
      }, 2000);
    },

    async getProductData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.productPageNumber,
        pageSize: this.limitProduct,
        search: search,
      };
      await axios
        .post("Products/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isProductSearchResult = !!search;
            this.productList =
              this.isProductSearchResult !== true
                ? Array.from(new Set([...this.productList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageProduct = response.data.data.hasNextPage;
            this.totalProductCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    newLoadProduct(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("Products/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            let list = [...JSON.parse(JSON.stringify(this.productList)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.productList = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearUnit() {
      this.unitList = [];
      this.unitPageNumber = 1;
      this.limitUnit = 5;
      this.getUnitData("");
    },

    searchUnit(search) {
      clearInterval(this.unitSearchTimer);
      this.unitSearchTimer = setTimeout(() => {
        if (search) {
          this.unitList = [];
          this.unitPageNumber = 1;
          this.limitUnit = this.totalUnitCount;
          this.getUnitData(search.trim());
        }
      }, 2000);
    },
    async getUnitData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.unitPageNumber,
        pageSize: this.limitUnit,
        search: search,
      };
      await axios
        .post("Units/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isUnitSearchResult = !!search;
            this.unitList =
              this.isUnitSearchResult !== true
                ? Array.from(new Set([...this.unitList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageUnit = response.data.data.hasNextPage;
            this.totalUnitCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    newLoadUnit(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("Units/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            let list = [...JSON.parse(JSON.stringify(this.unitList)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.unitList = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);

            this.isLoading = false;
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearDep() {
      this.departmentList = [];
      this.depPageNumber = 1;
      this.limitDep = 5;
      this.getDepartmentData("");
    },

    searchDep(search) {
      clearInterval(this.depSearchTimer);
      this.depSearchTimer = setTimeout(() => {
        if (search) {
          this.departmentList = [];
          this.depPageNumber = 1;
          this.limitDep = this.totalDepCount;
          this.getDepartmentData(search.trim());
        }
      }, 2000);
    },
    async getDepartmentData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.depPageNumber,
        pageSize: this.limitDep,
        search: search,
      };
      await axios
        .post("Departments/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isDepSearchResult = !!search;
            this.departmentList =
              this.isDepSearchResult !== true
                ? Array.from(new Set([...this.departmentList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageDep = response.data.data.hasNextPage;
            this.totalDepCount = response.data.data.totalCount;
            //if (this.departmentList.length == 0) {
            //  warningToast("Please Add Department");
            //}
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    newLoadDepartment(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("Departments/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            let list = [...JSON.parse(JSON.stringify(this.departmentList)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.departmentList = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      //console.log(this.itemData)

      this.getDataFromApi();
    },
    setRowSize() {
      if (this.itemsData.totalPages == 1) {
        this.pageSize = this.itemsData.totalCount;
      } else {
        this.pageSize = this.pagination.pageSize;
      }
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataSku = Object.assign({}, this.formDataSkuDefault);
      this.formDataSupplierProduct = [];
    },
    setDefaultSPEdit() {
      this.formDataSupplierProductEdit = Object.assign({}, this.formDataSupplierProductDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(`MemorySkus/${this.warehouseAbbreviation}/Stocks`, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;

            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.setRowSize();
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      // this.setDefault();
      this.titleChange = -1;

      this.viewAddskuForm = true;
      this.activateModal();
    },

    activateModal() {
      //this.getProductData();
      //this.getSuppliers();
      //this.getDepartmentData();
      this.addSku = true;
      // this.getWarehouses();
      // if (this.storeUserRole == "SuperUser") {
      //   this.getWarehouses();
      // } else {
      //   this.getUsersWarehouses;
      // }
    },

    //selection variant option
    getVariantOptions() {
      var pagination = { pageNumber: 1, pageSize: 10 };
      axios
        .post("VariantOption/Paginated", pagination)
        .then((response) => {
          this.variantOptions = response.data.data.items;

          if (this.variantOptions.length > 0) {
            if (this.titleChange === -1) {
              this.formDataSkuVariant.variantOptionId = this.variantOptions[0].variantOptionId;
            }
          } else if (this.variantOptions.length == 0) {
            warningToast("Please Add Items in Variant Options");
            this.addSku = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
        });
    },

    closeForm() {
      this.supplierProductChange = -1;
      this.setDefault();
      this.addSku = false;
      this.removeAllField("", this.formDataSupplierProduct, this.count);
      this.addField("", this.formDataSupplierProduct);
      this.setDefaultSPEdit();

      this.productList = [];
      this.warehousesList = [];
      this.departmentList = [];
      this.unitList = [];
      this.suppliers = [];

      this.updateProductItem = null;
      this.updateWarehouseItem = null;
      this.updateDepartmentItem = null;
      this.updateUnitItem = null;
      this.updateSupplierProductItem = null;

      this.viewSOH = 0;
    },
    async getSkuById(skuId) {
      await axios.get("Sku/" + skuId).then((response) => {
        this.formDataSku = response.data.data;
        removeNullValue(this.formDataSku);
        console.log(" this.formDataSku ", this.formDataSku);
      });
    },
    editItem(item) {
      this.viewSOH = item.available;
      this.getSkuById(item.skuId);
      // this.formDataSku = Object.assign({}, item);
      this.titleChange = 0;
      this.viewAddskuForm = true;
      this.viewBinProducts = true;
      this.setDefault();
      removeNullValue(item);

      console.log("edit sku", item);
      if (item.productId && this.formDataSku) {
        this.findProductById(this.formDataSku.productId);
      }
      if (item.warehouseId && this.formDataSku) {
        this.findWarehouseById(this.formDataSku.warehouseId);
      }
      if (item.departmentId && this.formDataSku) {
        this.findDepartmentById(this.formDataSku.departmentId);
      }
      if (item.unitId && this.formDataSku) {
        this.findUnitById(this.formDataSku.unitId);
      }
      // if(item.supplierId){
      //   this.findSupplierById(item.supplierId)
      // }

      console.log("result of  updated items", this.updateProductItem, this.updateWarehouseItem, this.updateDepartmentItem, this.updateUnitItem, this.updateSupplierProductItem);
      //this.formDataSku = Object.assign({}, item);
      //this.formDataSupplierProduct = Object.assign({}, this.formDataSupplierProductDefault);

      // this.formDataSupplierProduct[0].skuId = item.skuId;
      // this.formDataSupplierProduct[0].productDescription = item.description;
      // this.formDataSupplierProduct[0].price = item.price;
      console.log("this.formDataSupplierProduct", this.formDataSupplierProduct);

      if (this.titleChange == 0) {
        this.getSupplierProductsBySkuId(item.skuId);
      }
      this.activateModal();
    },
    updateReserveStock(item) {
      this.editReservedQty = { sku: item.skuName, ean: item.ean, quantity: item.designatedQuantity };
      this.reserveQtyDialog = true;
    },
    saveReserveStock() {
      axios
        .post(`MemoryStocks/${this.warehouseAbbreviation}/Products/update-reserved-stock`, this.editReservedQty)
        .then((response) => {
          if (response.data.success) {
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
        });
    },
    getSupplierProductsBySkuId(skuId) {
      this.isLoading = true;
      axios
        .get("SupplierProducts/Products/" + skuId)
        .then((response) => {
          this.binExistingProducts = response.data.data;
          // this.binExistingProducts.forEach((x)=>{
          //   x.isEdit = false
          // });
          this.isLoading = false;
          // this.binExistingProducts = [{}, {}, {}, {}, {}, {}, {}, {}];
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    momentMY(date) {
      return moment.utc(date).local().format("MMMM, YYYY");
    },

    loadSkuLocation(sku) {
      this.isLoading = true;
      axios
        .get("MemoryStocks/" + this.warehouseAbbreviation + "/Products/" + sku)
        .then((response) => {
          if (response.status == 200) {
            this.locationItems = response.data;
            this.isLoading = false;
          } else {
            failedToast("Something went wrong");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    // removeNullValue(item) {
    //   Object.keys(item).forEach((key) => {
    //     if (item[key] === null) {
    //       item[key] = "";
    //     }
    //   });
    // },

    setNullValue(item) {
      this.submitItem = Object.assign({}, item);
      if (this.submitItem.productId) {
        this.submitItem.productId = null;
      }
      if (this.submitItem.unitId) {
        this.submitItem.unitId = null;
      }
      if (this.submitItem.department) {
        this.submitItem.department = null;
      }
      if (this.submitItem.categoryId) {
        this.submitItem.categoryId = null;
      }

      this.submitItem.price = this.numberConvert(item.price);
      this.submitItem.vatPercentage = this.numberConvert(item.vatPercentage);
      this.submitItem.discountedPrice = this.numberConvert(item.discountedPrice);
      this.submitItem.minMargin = this.numberConvert(item.minMargin);
      this.submitItem.temperature = this.numberConvert(item.temperature);
      this.submitItem.weight = this.numberConvert(item.weight);
      this.submitItem.minStock = this.numberConvert(item.minStock);
      this.submitItem.stdCost = this.numberConvert(item.stdCost);
    },

    numberConvert(val) {
      if (!val) {
        return 0;
      } else {
        if (typeof Number(val) === "number") {
          return Number(val);
        } else {
          return 0;
        }
      }
    },

    saveForm() {
      this.setNullValue(this.formDataSku);

      if (this.titleChange === -1) {
        let formDataSupplierProductExist = this.formDataSupplierProduct.every((val) => {
          if (val.supplierId && val.productNumber && val.productDescription && val.maxDeliveryTimeInDays && val.price) {
            return true;
          } else {
            return false;
          }
        });

        if (
          !this.formDataSku.skuName ||
          !this.formDataSku.description ||
          !this.formDataSku.ean ||
          !this.formDataSku.minStock ||
          !this.formDataSku.price ||
          !this.formDataSku.warehouseId
        ) {
          if (!this.formDataSku.skuName) {
            warningToast("PLU/SKU is Required");
          }
          // if (!this.formDataSku.productId) {
          //   warningToast("Product is Required");
          // }
          if (!this.formDataSku.description) {
            warningToast("Description is Required");
          }
          if (!this.formDataSku.ean) {
            warningToast("BARCODE/EAN is Required");
          }
          if (!this.formDataSku.minStock) {
            warningToast("Min Stock is Required");
          }
          if (!this.formDataSku.price) {
            warningToast("Price is Required");
          }
          if (!this.formDataSku.warehouseId) {
            warningToast("Warehouse is Required");
          }
          // if (!formDataSupplierProductExist) {
          //   warningToast("Supplier Product is Required, check all the required fields");
          // }
        } else {
          this.isLoading = true;

          axios
            .post("Sku", this.submitItem)
            .then((response) => {
              if (response.data.success === true) {
                if (formDataSupplierProductExist) {
                  var supplierId = this.formDataSupplierProduct.supplierId;

                  if (supplierId != "") {
                    var response = response.data.data;
                    this.assignSupplierProduct(response);
                  } else {
                    this.getDataFromApi();
                    successToast(response.data.message);
                  }
                }

                this.getDataFromApi();
                successToast(response.data.message);
                this.closeForm();
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      } else {
        var itemId = this.submitItem.skuId;
        this.submitItem.warehouseId = store.state.authUser.warehouse.warehouseId;
        this.isLoading = true;

        axios
          .put("Sku/" + itemId, this.submitItem)
          .then((response) => {
            if (response.data.success === true) {
              this.itemsData = response.data.data;
              this.items = response.data.data.items;
              this.closeForm();
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },

    assignSupplierProduct(response) {
      // this.formDataSupplierProduct.skuId = response.skuId;
      // if (this.formDataSupplierProduct.productDescription == "") {
      //   this.formDataSupplierProduct.productDescription = response.description;
      // }
      // if (this.formDataSupplierProduct.price == "") {
      //   this.formDataSupplierProduct.price = response.price;
      // }
      this.formDataSupplierProduct.forEach((x) => (x.skuId = response.skuId));

      console.log("formDataSupplierProduct", this.formDataSupplierProduct);
      this.saveSupplierProduct();
    },

    addSupplierProduct() {
      const supplierId = this.formDataSupplierProductEdit.supplierId;
      const productNumber = this.formDataSupplierProductEdit.productNumber;
      const deliveryDays = this.formDataSupplierProductEdit.maxDeliveryTimeInDays;
      const price = this.formDataSupplierProductEdit.price;

      const validate = ["", null, undefined, "SELECT"];

      if (!validate.includes(supplierId) && !validate.includes(productNumber) && !validate.includes(deliveryDays) && !validate.includes(price)) {
        this.formDataSupplierProductEdit.skuId = this.formDataSku.skuId;
        this.supplierProductChange = 2;
        this.saveSupplierProduct();
        console.log("formDataSupplierProductEdit skus", this.formDataSupplierProductEdit);
      } else {
        if (validate.includes(supplierId)) {
          warningToast("Supplier Required");
        }
        if (validate.includes(productNumber)) {
          warningToast("Product Code Required");
        }
        if (validate.includes(deliveryDays)) {
          warningToast("Delivery Days Required");
        }
        if (validate.includes(price)) {
          warningToast("Price Required");
        }
      }
    },
    cancelProduct() {
      this.titleChange = 0;
      this.supplierProductChange = 2;
      this.formDataSupplierProductEdit = Object.assign({}, this.formDataSupplierProductDefault);
    },
    editSupplierProduct() {
      const supplierId = this.formDataSupplierProductEdit.supplierId;
      const productNumber = this.formDataSupplierProductEdit.productNumber;
      const deliveryDays = this.formDataSupplierProductEdit.maxDeliveryTimeInDays;
      const price = this.formDataSupplierProductEdit.price;

      const validate = ["", null, undefined, "SELECT"];

      if (!validate.includes(supplierId) && !validate.includes(productNumber) && !validate.includes(deliveryDays) && !validate.includes(price)) {
        this.supplierProductChange = 0;
        this.saveSupplierProduct();
      } else {
        if (validate.includes(supplierId)) {
          warningToast("Supplier Required");
        }
        if (validate.includes(productNumber)) {
          warningToast("Product Code Required");
        }
        if (validate.includes(deliveryDays)) {
          warningToast("Delivery Days Required");
        }
        if (validate.includes(price)) {
          warningToast("Price Required");
        }
      }
    },

    addSupplier() {
      const supplierId = this.titleChange === 0 ? this.formDataSupplierProductEdit.supplierId : this.formDataSupplierProduct[0].supplierId;
      const productNumber = this.titleChange === 0 ? this.formDataSupplierProductEdit.productNumber : this.formDataSupplierProduct[0].productNumber;
      const deliveryDays = this.titleChange === 0 ? this.formDataSupplierProductEdit.maxDeliveryTimeInDays : this.formDataSupplierProduct[0].maxDeliveryTimeInDays;
      const price = this.titleChange === 0 ? this.formDataSupplierProductEdit.price : this.formDataSupplierProduct[0].price;

      const validate = ["", null, undefined, "SELECT"];

      if (!validate.includes(supplierId) && !validate.includes(productNumber) && !validate.includes(deliveryDays) && !validate.includes(price)) {
        this.saveSupplierProduct();
      } else {
        if (validate.includes(supplierId)) {
          warningToast("Supplier Required");
        }
        if (validate.includes(productNumber)) {
          warningToast("Product Code Required");
        }
        if (validate.includes(deliveryDays)) {
          warningToast("Delivery Days Required");
        }
        if (validate.includes(price)) {
          warningToast("Price Required");
        }
      }
    },

    saveSupplierProduct() {
      if (this.supplierProductChange === -1) {
        this.formDataSupplierProduct.map((x) => {
          axios
            .post("SupplierProducts", x)
            .then((response) => {
              if (response.data.success === true) {
                if (this.titleChange === -1) {
                  this.addSku = false;
                  this.getDataFromApi();
                } else {
                  this.getSupplierProductsBySkuId(this.formDataSku.skuId);
                }
                //successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast("PLU Added, but SPN created failed");
              // warningToast(statusMessage(error.response.status));
            });
        });
      } else if (this.supplierProductChange === 2) {
        axios
          .post("SupplierProducts", this.formDataSupplierProductEdit)
          .then((response) => {
            if (response.data.success === true) {
              if (this.titleChange === -1) {
                this.addSku = false;
                this.getDataFromApi();
              } else {
                this.getSupplierProductsBySkuId(this.formDataSku.skuId);
              }
              this.setDefaultSPEdit();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            // warningToast(statusMessage(error.response.status));
            failedToast("SPN created failed");
          });
      } else {
        var itemId = this.formDataSupplierProductEdit.supplierProductId;
        this.isLoading = true;

        axios
          .put("SupplierProducts/" + itemId, this.formDataSupplierProductEdit)
          .then((response) => {
            if (response.data.success === true) {
              this.closeUpdateSupplierProducts();
              this.isLoading = false;
              this.getSupplierProductsBySkuId(this.formDataSku.skuId);
              successToast(response.data.message);
              this.titleChange = 0;
              this.setDefaultSPEdit();
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    archiveItem(item) {
      removeNullValue(item);
      this.formDataSku = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },

    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataSku.skuId;
      this.isLoading = true;
      axios
        .get("Sku/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Sku/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      removeNullValue(item);
      this.formDataSku = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataSku.skuId;
      this.isLoading = true;
      axios
        .get("Sku/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataSku = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataSku.skuId;
      axios
        .delete("Sku/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //Sku Variant
    viewSkuVariant(item) {
      // this.formDataSku = Object.assign({}, item);
      // this.viewSkuVariantModal = true;
      // this.addSkuVariantModal = false;
      // this.skuChange = -1;
      // this.titleChange = -1;
      // this.getSkuVariants();
    },
    addSkuVariant() {
      this.getVariantOptions();

      this.activateModalSkuVariant();
      this.skuChange = -1;
    },
    activateModalSkuVariant() {
      this.setDefaultSkuVariant();

      this.addSkuVariantModal = true;
    },

    setDefaultSkuVariant() {
      this.formDataSkuVariant = Object.assign({}, this.formDataSkuVariantDefault);

      this.formDataSkuVariant.skuId = this.formDataSku.skuId;
    },
    getSkuVariants() {
      var skuId = this.formDataSku.skuId;
      axios
        .post("SkuVariant/" + skuId + "/Paginated", this.paginationOption)
        .then((response) => {
          this.skuVariantItemsData = response.data.data;
          this.skuVariantItems = response.data.data.items;

          this.pageCountOption = this.skuVariantItemsData.totalPages;

          //   var variantTypeId = this.formDataSkuVariant.variantTypeId;
          //    this.skuVariantItems = response.data.data.items.filter(
          //    (a) => a.variantTypeId === variantTypeId
          //   );

          //this.formDataSkuVariant.variantTypeId
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
        });
    },

    saveSkuVariant() {
      if (this.optionChange === -1) {
        this.isLoading = true;
        axios
          .post("SkuVariant", this.formDataSkuVariant)
          .then((response) => {
            if (response.data.success === true) {
              this.getSkuVariants();
              this.addSkuVariantModal = false;
              // this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      } else {
        var itemId = this.formDataSkuVariant.variantOptionId;
        this.isLoading = true;
        axios
          .put("SkuVariant/" + itemId, this.formDataSkuVariant)
          .then((response) => {
            if (response.data.success === true) {
              this.addSkuVariantModal = false;
              this.getSkuVariants();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },

    editItemSkuVariant(item) {
      this.skuChange = 0;
      this.formDataSkuVariant = Object.assign({}, item);
      this.addSkuVariantModal = true;
    },

    deleteItemSkuVariant(item) {
      this.formDataSkuVariant = Object.assign({}, item);
      this.deleteSkuVariantConfirmationModal = true;
    },
    deleteItemSkuVariantConfirm() {
      var itemId = this.formDataSkuVariant.SkuVariantId;
      this.isLoading = true;
      axios
        .delete("SkuVariant/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteSkuVariantConfirmationModal = false;
            this.getSkuVariants();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    findProductById(id) {
      this.isLoading = true;
      axios
        .get("Products/" + id)
        .then((response) => {
          if (response.data.success === true) {
            this.updateProductItem = response.data?.data;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    findWarehouseById(id) {
      this.isLoading = true;
      axios
        .get("Warehouses/" + id)
        .then((response) => {
          if (response.data.success === true) {
            this.updateWarehouseItem = response.data?.data;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    findDepartmentById(id) {
      this.isLoading = true;
      axios
        .get("Departments/" + id)
        .then((response) => {
          if (response.data.success === true) {
            this.updateDepartmentItem = response.data?.data;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    findUnitById(id) {
      this.isLoading = true;
      axios
        .get("Units/" + id)
        .then((response) => {
          if (response.data.success === true) {
            this.updateUnitItem = response.data?.data;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    findSupplierById(id) {
      this.isLoading = true;
      axios
        .get("Suppliers/" + id)
        .then((response) => {
          if (response.data.success === true) {
            this.updateSupplierProductItem = response.data?.data;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    addImage(item) {
      this.imageModal = true;
      this.imageModalUrl = item.imageUrl;
      this.imageModalSku = item.skuId;
    },
    chooseProductImageFiles() {
      const imageInput = document.getElementById("skuImageFileInput");
      imageInput.value = "";
      imageInput.click();
    },
    onProductImageFilePicked(event) {
      const imageFile = event.target.files[0];
      this.imageModalUrl = URL.createObjectURL(imageFile);

      let formData = new FormData();
      formData.append("File", imageFile);
      formData.append("Name", imageFile.name);

      axios
        .post(`Sku/${this.imageModalSku}/UpdateImage`, formData)
        .then((response) => {
          if (response.data.success === true) {
            const index = this.items.findIndex(x => x.skuId == this.imageModalSku);
            const item = this.items[index];
            item.imageUrl = response.data.data
            this.items.splice(index, 1, item)
            successToast(response.data.message);
          } else {
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(statusMessage(error.response.status));
        }).finally(() => {
          this.imageModal = false;
        });
    }
  },
};
</script>

<style>
.editable-item {
  display: flex;
  justify-content: center;
  align-items: center;

  .edit-icon {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    display: none;
  }
}

.editable-item:hover .edit-icon {
  display: inline-block;
  vertical-align: top;
  color: rgb(var(--color-info) / 1)
}
</style>