import { reg } from "../global-functions/regex";

export default {
  data() {
    return {
      reg: reg,
    };
  },
  methods: {
    validateEmail(email) {
      return !this.reg.EMAIL.test(email);
    },
    validatePhoneNo(phoneNumber) {
      return !this.reg.PHONENO.test(phoneNumber);
    },

    validateEan(ean) {
      return !this.reg.EAN.test(ean);
    },
    checkValue(val, defaultVal){
      return val ? (val === "null" ? defaultVal : val) : defaultVal;
    }
  },
};
