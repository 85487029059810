<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label">Department</label>
          <div class="w-72">
            <v-select
              v-model="productSummaryReportData.departmentId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenDep"
              @close="onCloseDep"
              @search="searchSS"
              :key="name"
              :get-option-label="(option) => option.name"
              :options="departmentList"
              :reduce="(name) => name.departmentId"
              label="name"
            >
              <template #list-footer>
                <li v-show="hasNextPageDep" ref="loadDep" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>

        <div>
          <a
            href="javascript:;"
            @click="getReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            GET REPORT
          </a>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="clearReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            CLEAR
          </a>
        </div>

        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pageNumber"
        />
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table
        class="table table-report -mt-2 table-auto"
        v-if="reportsLocal.length > 0"
      >
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div
      v-if="reports.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-components/toast/toast";

import store from "../../../store";
export default {
  mounted() {
    this.observerDep = new IntersectionObserver(this.infiniteScrollDep);
    store.dispatch("menuTitle/setSubMenuTitle", "Stock Summary Reports");
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      departmentList: [],
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,

      headers: [
        {
          text: "Warehouse",
          value: "warehouse",
        },
        { text: "SkuId", value: "skuId" },
        { text: "Sku", value: "sku" },
        { text: "Ean", value: "ean" },
        { text: "Description", value: "description" },
        { text: "Department", value: "department" },
        { text: "Category", value: "category" },
        { text: "QuantityOrdered", value: "quantityOrdered" },
        { text: "StockPurchased", value: "stockPurchased" },
        { text: "Purchase Returns", value: "purchaseReturns" },
        { text: "StockIn", value: "stockIn" },
        { text: "StockOut", value: "stockOut" },
        { text: "StockOnHand", value: "stockOnHand" },
        { text: "Commited Stock", value: "commitedStock" },
        { text: "Sales", value: "sales" },
        { text: "SalesReturns", value: "salesReturns" },
        { text: "AvailableForSale", value: "availableForSale" },
        { text: "ReOrderLevel", value: "reOrderLevel" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      productSummaryReportData: {
        departmentId: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      customers: [],
      itemsData: {},

      observerDep: null,
      limitDep: 5,
      hasNextPageDep: false,
      totalSSCount: 0,
      isSSSearchResult: false,
      ssPageNumber: 1,
      ssSearchTimer: undefined,

      reports: [],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
    };
  },
  computed: {
    checkLimtDep() {
      return this.ssPageNumber;
    },
    viewModalDep() {
      return this.productSummaryReportData.departmentId;
    },
    // getReportData() {
    //   return this.productSummaryReportData.departmentId;
    // },
  },
  created() {
    this.getCurrentMonth();
    this.getDepartmentData();
  },
  watch: {
    viewModalDep() {
      if (this.productSummaryReportData.departmentId === null) {
        this.clearSS();
      }
    },
    checkLimtDep() {
      this.getDepartmentData();
    },
    // getReportData() {
    //   if (this.productSummaryReportData.departmentId != "") {
    //     this.getReports();
    //   } else {
    //     warningToast(const_txt.NO_DATA);
    //   }
    // },
    getWarehouseSection() {
      this.getReports();
    },
  },
  methods: {
    async onOpenDep() {
      if (this.isSSSearchResult) {
        this.ssPageNumber = 1;
      }
      if (this.hasNextPageDep) {
        await this.$nextTick();
        this.observerDep.observe(this.$refs.loadDep);
      }
    },
    onCloseDep() {
      this.observerDep.disconnect();
    },
    async infiniteScrollDep([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.ssPageNumber++;
        this.limitDep;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    clearSS() {
      this.storageSections = [];
      this.ssPageNumber = 1;
      this.limitDep = 5;
      this.getDepartmentData("");
    },

    searchSS(search) {
      clearInterval(this.ssSearchTimer);
      this.ssSearchTimer = setTimeout(() => {
        if (search) {
          this.storageSections = [];
          this.ssPageNumber = 1;
          this.limitDep = this.totalSSCount;
          this.getDepartmentData(search.trim());
        }
      }, 2000);
    },
    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Warehouse", dataKey: "warehouse" },
          { title: "SkuId", dataKey: "skuId" },
          { title: "Sku", dataKey: "sku" },
          { title: "Ean", dataKey: "ean" },
          { title: "Description", dataKey: "description" },
          { title: "Department", dataKey: "department" },
          { title: "Category", dataKey: "category" },
          { title: "QuantityOrdered", dataKey: "quantityOrdered" },
          { title: "StockPurchased", dataKey: "stockPurchased" },
          { title: "Purchase Returns", dataKey: "purchaseReturns" },
          {
            title: "StockIn",
            dataKey: "stockIn",
          },
          { title: "StockOut", dataKey: "stockOut" },
          { title: "StockOnHand", dataKey: "stockOnHand" },
          { title: "Commited Stock", dataKey: "commitedStock" },
          { title: "Sales", dataKey: "sales" },
          { title: "SalesReturns", dataKey: "salesReturns" },
          { title: "SalAvailableForSalees", dataKey: "availableForSale" },
          { title: "ReOrderLevel", dataKey: "reOrderLevel" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            warehouse: el.warehouse,
            skuId: el.skuId,
            sku: el.sku,
            ean: el.ean,
            description: el.description,
            department: el.department,
            category: el.category,
            quantityOrdered: el.quantityOrdered,
            stockPurchased: el.stockPurchased,
            purchaseReturns: el.purchaseReturns,
            stockIn: el.stockIn,
            stockOut: el.stockOut,
            stockOnHand: el.stockOnHand,
            commitedStock: el.commitedStock,
            sales: el.sales,
            salesReturns: el.salesReturns,
            availableForSale: el.availableForSale,
            reOrderLevel: el.reOrderLevel,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(
            footer,
            pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
            pageHeight - 0.2,
            { baseline: "bottom" }
          );
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Stock Summary Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Stock Summary Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "Warehouse",
          2: "SkuId",
          3: "Sku",
          4: "Ean",
          5: "Description",
          6: "Department",
          7: "Category",
          8: "QuantityOrdered",
          9: "StockPurchased",
          10: "Purchase Returns",
          11: "StockIn",
          12: "StockOut",
          13: "StockOnHand",
          14: "Commited Stock",
          15: "Sales",
          16: "SalesReturns",
          17: "AvailableForSale",
          18: "ReOrderLevel",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.warehouse,
            2: item.skuId,
            3: item.sku,
            4: item.ean,
            5: item.description,
            6: item.department,
            7: item.category,
            8: item.quantityOrdered,
            9: item.stockPurchased,
            10: item.purchaseReturns,
            11: item.stockIn,
            12: item.stockOut,
            13: item.stockOnHand,
            14: item.commitedStock,
            15: item.sales,
            16: item.salesReturns,
            17: item.availableForSale,
            18: item.reOrderLevel,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    getCurrentMonth() {
      const today = new Date();
      this.selectedMonth = today.getMonth();
      this.getMonthRange();
    },

    setMonth(item) {
      this.selectedMonth = item.value - 1;
      this.getMonthRange();
    },
    getMonthRange() {
      var d = new Date();
      var start = new Date(d.getFullYear(), this.selectedMonth, 1);
      var end = new Date(d.getFullYear(), this.selectedMonth + 1, 0);

      var startdate =
        start.getFullYear() +
        "-" +
        (start.getMonth() + 1) +
        "-" +
        start.getDate();
      var enddate =
        end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate();
    },

    async getDepartmentData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.ssPageNumber,
        pageSize: this.limitDep,
        search: search,
      };
      await axios
        .post("Departments/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSSSearchResult = !!search;
            this.departmentList.unshift({
              departmentId: null,
              name: "ALL",
              warehouseId: this.warehouseStoreId,
            });
            this.departmentList =
              this.isSSSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.departmentList, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageDep = response.data.data.hasNextPage;
            this.totalSSCount = response.data.data.totalCount;
            console.log(this.departmentList);
            if (this.departmentList.length == 0) {
              warningToast("Please Add Department");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearReports() {
      this.reports = [];
      this.reportsLocal = [];
      this.productSummaryReportData.departmentId = "";
      this.getCurrentMonth();
    },

    getReports() {
      this.isLoading = true;
      //var item = Object.assign({}, this.productSummaryReportData);

      axios
        .post(
          "Reports/" + this.warehouseStoreId + "/Stocks/Summary",
          this.productSummaryReportData
        )
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.isLoading = false;
            this.itemsData = response.data;
            if (response.data) {
              this.itemsData.totalCount = this.itemsData.data.length;
            }
            this.reports = response.data.data;
            this.setLocalReport();
            if (this.reports.length == 0) {
              this.isLoading = false;
              warningToast("NO DATA ", response.data.message);
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        });
    },

    searchOnchange() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getReports();
      this.searchActive = false;
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getReports();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
