<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:justify-between md:flex md:flex-row md:items-center md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Attribute" />

        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pagination.pageNumber"
          :selected="checkedData.length"
          @printSelected="printSelected"
          @csvExportSelected="csvExportSelected"
          @pdfExportSelected="pdfExportSelected"
        />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th>Attribute Name</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td>
              <span class="font-bold pb-1">{{ item.name }}</span>

              <div class="flex justify-start items-center space-x-2">
                <div v-for="(valueItem, index) in item.attributeValues" :key="index">
                  <div
                    class="bg-gray-200 rounded-lg px-4 py-2 whitespace-nowrap text-black hover:bg-gray-400 hover:text-white cursor-pointer"
                    @click="viewAttributeValueInfo(valueItem)"
                  >
                    {{ valueItem.abbreviation }}
                  </div>
                </div>

                <div class="bg-gray-200 rounded-full w-8 h-8 hover:bg-gray-400 hover:text-white cursor-pointer flex items-center justify-center" v-if="hasNextPage == true">
                  <a @click="loadAttributeValue()" class="flex items-center" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Load More"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-arrow-clockwise text-black w-6 h-6" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                      </svg>
                    </Tippy>
                  </a>
                </div>
              </div>
            </td>

            <td class="table-report__action w-40">
              <div class="lg:grid lg:grid-cols-4 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <Add :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showAttributeValuesModal($event)" :item="item" name=" Add New Attribute Value" />
                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />

    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />
    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addAttribute" @hidden="addAttribute = false" backdrop="static" :title="formTitle" @close="closeForm()">
    <ModalBody class="flex justify-center space-y-4 p-8">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">Attribute Name</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAttribute.name" type="text" class="form-control" placeholder="Attribute Name" autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Sort Order</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAttribute.sortOrder" type="text" class="form-control" placeholder="Attribute Name" autocomplete="off" />
          </div>
        </div>

        <!-- <div>
          <label for="regular-form-1" class="form-label">Sort Order</label>
          <v-select
            v-model="formDataAttribute.sortOrder"
            placeholder="SELECT"
            class="style-chooser"
            :options="sortOrderList"
            :reduce="(name) => name.value"
            label="name"
          />
        </div> -->
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnTitle }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <MoveableModal
    :show="addAttributeValue"
    @hidden="addAttributeValue = false"
    backdrop="static"
    :title="formAttributeValueTitle"
    @close="formAttributeValueTitle == -1 ? closeAttributeValueForm() : closeEditAttributeValueForm()"
  >
    <ModalBody class="flex justify-center space-y-4 p-8">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">Attribute Value Name</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataAttributeValue.name" type="text" class="form-control" placeholder="Attribute Value Name" autocomplete="off" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Attribute Value Abbreviation</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataAttributeValue.abbreviation"
              type="text"
              class="form-control"
              placeholder="Attribute Value Abbreviation"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="formAttributeValueTitle == -1 ? closeAttributeValueForm() : closeEditAttributeValueForm()" class="btn btn-outline-secondary w-24 mr-3">
        Cancel
      </button>
      <button @click="saveAttributeValueForm()" type="submit" class="btn btn-primary w-24">
        {{ btnAttributeValueTitle }}
      </button>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal
    :show="viewAttributeValue"
    @hidden="viewAttributeValue = false"
    backdrop="static"
    :title="'Attribute Info for ' + formDataAttributeValue.name"
    @close="viewAttributeValue = false"
  >
    <ModalBody class="flex flex-col justify-start items-start space-y-4 p-8">
      <div class="flex justify-start items-start space-x-2">
        <div>Attribute Value Name</div>
        <div>{{ formDataAttributeValue.name }}</div>
      </div>
      <div class="flex justify-start items-start space-x-2">
        <div>Attribute Value Abbreviation</div>
        <div>{{ formDataAttributeValue.abbreviation }}</div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="viewAttributeValue = false" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="editAttributeValue(formDataAttributeValue)" type="submit" class="btn btn-primary w-24 mr-3">Edit</button>
      <button @click="deleteAttributeValueItem(formDataAttributeValue)" type="submit" class="btn btn-danger w-24">Delete</button>
    </ModalFooter>
  </MoveableModal>
  <DeleteConfirmModal :name="formDataAttribute.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />

  <DeleteConfirmModal
    :name="formDataAttributeValue.name"
    :modalStatus="deleteConfirmationAttributeValueModal"
    :modalConfirm="deleteItemConfirmAttributeValue"
    :modalClose="closeAttributeValueDelete"
  />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";

// import { permissionHelper as $h } from "@/utils/permissionHelper";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Attributes");
  },
  components: {},
  data() {
    return {
      viewItemModal: false,
      warehouseId: store.state.authUser.warehouse.warehouseId,
      warehousesList: [],
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,

      addAttribute: false,
      addAttributeValue: false,

      sortOrderList: [
        {
          name: "ASC",
          value: 1,
        },
        {
          name: "DESC",
          value: 0,
        },
      ],

      deleteConfirmationModal: false,
      deleteConfirmationAttributeValueModal: false,
      titleChange: -1,
      titleAttributeValueChange: -1,
      formDataAttribute: {
        name: "",
        sortOrder: 0,
        warehouseId: 0,
      },
      formDataAttributeDefault: {
        name: "",
        sortOrder: 0,
        warehouseId: 0,
      },

      formDataAttributeValue: {
        name: "",
        abbreviation: "",
        attributeId: 0,
      },

      formDataAttributeValueDefault: {
        name: "",
        abbreviation: "",
        attributeId: 0,
      },

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },

      attributeValuePagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },

      addPageSize: 5,
      hasNextPage: false,

      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],

      valueItems: [],
      valueItemsData: [],

      allItems: [],
      allItemsData: [],

      isActive: 0,
      docs: null,

      allSelected: false,
      checkedData: [],

      viewAttributeValue: false,

      policyAccess: "Attributes",
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Attribute" : "Edit Attribute";
    },

    btnTitle() {
      return this.titleChange === -1 ? "Add" : "Save";
    },

    formAttributeValueTitle() {
      return this.titleAttributeValueChange === -1 ? "Add New Attribute Value" : "Edit Attribute Value";
    },

    btnAttributeValueTitle() {
      return this.titleAttributeValueChange === -1 ? "Add" : "Save";
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    viewAttributeValueInfo(item) {
      this.viewAttributeValue = true;
      this.formDataAttributeValue = Object.assign({}, item);
    },
    editAttributeValue(item) {
      this.formDataAttributeValue = Object.assign({}, item);
      this.titleAttributeValueChange = 0;
      this.addAttributeValue = true;
    },
    showAttributeValuesModal(item) {
      this.setAttributeValueDefault();
      this.formDataAttributeValue.attributeId = item.attributeId;
      this.titleAttributeValueChange = -1;
      this.addAttributeValue = true;
    },
    saveAttributeValueForm() {
      if (this.formDataAttributeValue.name == "" || this.formDataAttributeValue.abbreviation == "") {
        if (this.formDataAttributeValue.name == "") {
          warningToast("Attributes Value Name Required");
        }
        if (this.formDataAttributeValue.abbreviation == "") {
          warningToast("Attributes Value Abbreviation Required");
        }
      } else {
        if (this.titleAttributeValueChange === -1) {
          var itemId = this.formDataAttributeValue.attributeId;
          console.log("itemId", this.formDataAttributeValue);
          this.isLoading = true;
          axios
            .post("AttributeValues", this.formDataAttributeValue)
            .then((response) => {
              if (response.data.success === true) {
                // this.itemsData = response.data.data;
                // this.items = response.data.data.items;
                this.addAttributeValue = false;
                // this.getDataFromApi();

                console.log("items", this.items);
                let updateItem = this.items.find((x) => x.attributeId === itemId);
                console.log("updateItem", updateItem);
                if (updateItem.hasOwnProperty("attributeValues")) {
                  updateItem.attributeValues.push({
                    attributeValueId: response.data.data.attributeValueId,
                    name: response.data.data.name,
                    abbreviation: response.data.data.abbreviation,
                    imageUrl: response.data.data.imageUrl,
                    attributeId: response.data.data.attributeId,
                    attributeName: response.data.data.attributeName,
                  });
                } else {
                  updateItem.attributeValues = [];
                  updateItem.attributeValues.push({
                    attributeValueId: response.data.data.attributeValueId,
                    name: response.data.data.name,
                    abbreviation: response.data.data.abbreviation,
                    imageUrl: response.data.data.imageUrl,
                    attributeId: response.data.data.attributeId,
                    attributeName: response.data.data.attributeName,
                  });
                }

                console.log("updateItem2", updateItem);
                this.isLoading = false;
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataAttributeValue.attributeValueId;
          var attributeId = this.formDataAttributeValue.attributeId;
          this.isLoading = true;
          axios
            .put("AttributeValues/" + itemId, this.formDataAttributeValue)
            .then((response) => {
              if (response.data.success === true) {
                // this.itemsData = response.data.data;
                // this.items = response.data.data.items;
                this.viewAttributeValue = false;
                this.addAttributeValue = false;

                // this.getDataFromApi();

                let updateItem = this.items.find((x) => x.attributeId === attributeId);
                let index = updateItem.attributeValues.findIndex((x) => x.attributeValueId === response.data.data.attributeValueId);
                updateItem.attributeValues[index] = {
                  attributeValueId: response.data.data.attributeValueId,
                  name: response.data.data.name,
                  abbreviation: response.data.data.abbreviation,
                  imageUrl: response.data.data.imageUrl,
                  attributeId: response.data.data.attributeId,
                  attributeName: response.data.data.attributeName,
                };
                this.isLoading = false;
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },

    closeAttributeValueForm() {
      this.setAttributeValueDefault();
      this.addAttributeValue = false;
    },

    closeEditAttributeValueForm() {
      this.addAttributeValue = false;
    },

    // getWarehouses(search) {
    //   this.isLoading = true;
    //   var pagination = { pageNumber: 1, pageSize: 5, search: search };
    //   axios
    //     .post("Warehouses/Paginated", pagination)
    //     .then((response) => {
    //       this.warehousesList = response.data.data.items;
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       failedToast(statusMessage(error.response.status));
    //     });
    // },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "Attribute Name", dataKey: "name" },
              { title: "Unit Name", dataKey: "unitName" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                unitName: el.unitName,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Units " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Units " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Attribute Name",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.name,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Attributes/" + this.warehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData; //newly added line
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataAttribute = Object.assign({}, this.formDataAttributeDefault);
    },
    setAttributeValueDefault() {
      this.formDataAttributeValue = Object.assign({}, this.formDataAttributeValueDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Attributes/" + this.warehouseId + "/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;

            this.items.forEach((x) => {
              this.getAttributeValuesDataFromApi(x.attributeId).then((arr) => {
                x.attributeValues = arr;
              });
            });

            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    loadAttributeValue() {
      if (this.hasNextPage == true) {
        this.attributeValuePagination.pageSize = this.attributeValuePagination.pageSize + this.addPageSize;
        this.getDataFromApi();
      }
    },
    getAttributeValuesDataFromApi(attributeValueId) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await axios.post("AttributeValues/" + attributeValueId + "/Paginated", this.attributeValuePagination);

          this.hasNextPage = response.data.data.hasNextPage;

          resolve(response.data.data.items);
        } catch {
          resolve();
        }
      });
    },

    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addAttribute = true;
      this.activateModal();
    },
    activateModal() {
      this.addAttribute = true;
      // this.getWarehouses();
    },
    closeForm() {
      this.addAttribute = false;
      this.setDefault();
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataAttribute = Object.assign({}, item);
      this.addAttribute = true;
    },

    saveForm() {
      if (this.formDataAttribute.name == "") {
        if (this.formDataAttribute.name == "") {
          warningToast("Attributes Name Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          this.formDataAttribute.warehouseId = this.warehouseId;
          axios
            .post("Attributes", this.formDataAttribute)
            .then((response) => {
              if (response.data.success === true) {
                // this.itemsData = response.data.data;
                // this.items = response.data.data.items;
                this.addAttribute = false;
                // this.getDataFromApi();

                this.items.push({
                  attributeId: response.data.data.attributeId,
                  name: response.data.data.name,
                  sortOrder: response.data.data.sortOrder,
                  warehouseId: response.data.data.warehouseId,
                  warehouse: response.data.data.warehouse,
                });
                this.isLoading = false;
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataAttribute.attributeId;

          this.isLoading = true;

          var formData = {
            name: this.formDataAttribute.name,
            skuOrder: this.formDataAttribute.sortOrder,
          };

          axios
            .put("Attributes/" + itemId, formData)
            .then((response) => {
              if (response.data.success === true) {
                // this.itemsData = response.data.data;
                // this.items = response.data.data.items;
                this.addAttribute = false;
                // this.getDataFromApi();

                let updateItem = this.items.find((y) => y.attributeId === itemId);
                let index = this.items.findIndex((x) => x.attributeId === itemId);

                this.items[index] = {
                  attributeId: response.data.data.attributeId,
                  name: response.data.data.name,
                  sortOrder: response.data.data.sortOrder,
                  warehouseId: response.data.data.warehouseId,
                  warehouse: response.data.data.warehouse,
                  attributeValues: updateItem.attributeValues || [],
                };

                this.isLoading = false;
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeAttributeValueDelete() {
      this.deleteConfirmationAttributeValueModal = false;
    },
    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },

    deleteItem(item) {
      this.formDataAttribute = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      var itemId = this.formDataAttribute.attributeId;
      this.isLoading = true;
      axios
        .delete("Attributes/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            // this.getDataFromApi();

            let index = this.items.findIndex((x) => x.attributeId === response.data.data);
            this.items.splice(index, 1);
            this.isLoading = false;
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    deleteAttributeValueItem(item) {
      this.formDataAttributeValue = Object.assign({}, item);
      this.deleteConfirmationAttributeValueModal = true;
    },

    deleteItemConfirmAttributeValue() {
      var itemId = this.formDataAttributeValue.attributeValueId;
      this.isLoading = true;
      axios
        .delete("AttributeValues/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            // this.getDataFromApi();
            let updateItem = this.items.find((x) => x.attributeId === this.formDataAttributeValue.attributeId);

            let index = updateItem.attributeValues.findIndex((x) => x.attributeValueId === response.data.data);
            updateItem.attributeValues.splice(index, 1);

            this.deleteConfirmationAttributeValueModal = false;
            this.viewAttributeValue = false;
            this.isLoading = false;
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
