<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem
            :activeAccess="$h.addItemAccess(policyAccess)"
            @parentMethod="showModal($event)"
            buttonName="Add New Licensee"
          />

          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
          />

          <FilterItems
            :headersFilter="headersFilter"
            @parentMethod="sorting($event)"
          />

          <SearchBar
            :searchActive="searchActive"
            @searchOnchange="searchOnchange($event)"
            @searchItem="searchItem($event)"
            @clearSearch="clearSearch($event)"
          />

          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
        <ViewArchiveItem @parentMethod="viewArchives($event)" />
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto w-full">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th
                class="whitespace-nowrap"
                v-for="(header, i) in headers"
                :key="i"
              >
                {{ header.text }}
              </th>
              <th class="flex justify-start">
                <SelectLabels
                  :allSelected="allSelected"
                  @selectAll="selectAll($event)"
                  @unSelectAll="unSelectAll($event)"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action">
                <div
                  class="lg:grid lg:grid-cols-3 gap-x-1 gap-y-2 p-2 flex w-28 flex-wrap"
                >
                  <CheckItem
                    :item="item"
                    :checkedData="checkedData"
                    @parentMethod="checkItem($event)"
                  />
                  <!-- <ViewWarehouse
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="viewWarehouseItemInfo($event)"
                    :item="item"
                    name="Add warehouses"
                  />

                  <ViewUsers
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="viewUserItemInfo($event)"
                    :item="item"
                    name="Add users"
                  /> -->

                  <ViewWarehouse
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="resetPasscode($event)"
                    :item="item"
                    name="Reset Passcode"
                  />

                  <EditItem
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="editValues($event)"
                    :item="item"
                    name="Edit"
                  />
                  <ArchiveItem
                    :activeAccess="$h.archiveItemAccess(policyAccess)"
                    @parentMethod="archiveItem($event)"
                    :item="item"
                    name="Archive"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePage($event)"
        @pageSizeChange="pageSizeChange($event)"
        :itemsData="itemsData"
        :pageCount="pageCount"
        :pageSize="pageSizeView"
      />

      <!-- END: Pagination -->
    </div>
  </div>
  <!-- BEGIN: Add  Modal -->

  <MoveableModal
    :show="addWarehouse"
    @hidden="addWarehouse = false"
    backdrop="static"
    size="modal-xl"
    :title="formTitle"
    @close="closeAddForm()"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="grid grid-cols-4 lg:gap-4 md:gap-4 gap-2 p-2">
        <!-- <div>
          <label for="regular-form-1" class="form-label">Logo</label>
          <div class="flex flex-col space-y-4">
            <div
              class="flex justify-center items-center w-full"
              v-if="url != null"
            >
              <img :src="url" class="w-full h-16 object-fill" />
            </div>
            <div class="w-full">
              <a
                href="javascript:;"
                @click="chooseFiles()"
                class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-full"
                >Upload Image
                <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />

                <input
                  id="fileInput"
                  accept="image/*"
                  type="file"
                  ref="fileInput"
                  @change="onFilePicked"
                  hidden
                />
              </a>
            </div>
          </div>
        </div> -->

        <div class="relative col-span-4 pt-2">
          <h3 class="absolute -top-2.5 left-4 bg-white pt-2">
            Company Details
          </h3>
          <div
            class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2"
          >
            <div>
              <label for="regular-form-1" class="form-label">Logo</label>
              <div class="flex flex-col space-y-4">
                <div
                  class="flex justify-center items-center w-full"
                  v-if="url != null"
                >
                  <img :src="url" class="w-full h-16 object-fill" />
                </div>
                <div class="w-full">
                  <a
                    href="javascript:;"
                    @click="chooseFiles()"
                    class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-full"
                    >Upload Image
                    <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />

                    <input
                      id="fileInput"
                      accept="image/*"
                      type="file"
                      ref="fileInput"
                      @change="onFilePicked"
                      hidden
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <label for="regular-form-1" class="form-label"
                >Company Name</label
              >

              <div class="flex flex-col justify-center items-center space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataLicensee.companyName"
                  type="text"
                  class="form-control"
                  placeholder="Company Name"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="col-span-2">
              <label for="regular-form-1" class="form-label">Website</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataLicensee.website"
                  type="text"
                  class="form-control"
                  placeholder="Website"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Email</label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="
                    validateEmail(formDataLicensee.email) == true &&
                    formDataLicensee.email != ''
                      ? 'border-color: red'
                      : 'border-color: none'
                  "
                  id="vertical-form-1"
                  v-model="formDataLicensee.email"
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label"
                >Phone Number</label
              >

              <div class="flex flex-col space-y-1">
                <input
                  :style="
                    validatePhoneNo(formDataLicensee.phoneNumber) == true &&
                    formDataLicensee.phoneNumber != ''
                      ? 'border-color: red'
                      : 'border-color: none'
                  "
                  id="vertical-form-1"
                  v-model="formDataLicensee.phoneNumber"
                  type="text"
                  class="form-control"
                  placeholder="Phone Number"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Mobile</label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="
                    validatePhoneNo(formDataLicensee.mobile) == true &&
                    formDataLicensee.mobile != ''
                      ? 'border-color: red'
                      : 'border-color: none'
                  "
                  id="vertical-form-1"
                  v-model="formDataLicensee.mobile"
                  type="text"
                  class="form-control"
                  placeholder="Mobile"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
          <label for="regular-form-1" class="form-label">Fax</label>

          <div class="flex flex-col space-y-1">
                <input
                  :style="
                    validatePhoneNo(formDataLicensee.fax) == true &&
                    formDataLicensee.fax != ''
                      ? 'border-color: red'
                      : 'border-color: none'
                  "
                  id="vertical-form-1"
                  v-model="formDataLicensee.fax"
                  type="text"
                  class="form-control"
                  placeholder="Fax"
                  autocomplete="off"
                />
              </div>
        </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Address</label>

              <div class="flex flex-col space-y-1">
                <textarea
                  id="vertical-form-1"
                  v-model="formDataLicensee.address"
                  type="text"
                  class="form-control h-10"
                  placeholder="Address"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-span-3">
          <label for="regular-form-1" class="form-label">Company Name</label>

          <div class="flex flex-col justify-center items-center space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.companyName"
              type="text"
              class="form-control"
              placeholder="Company Name"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Phone Number</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.phoneNumber"
              type="text"
              @keypress="onlyNumber"
              class="form-control"
              placeholder="Phone Number"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Mobile</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.mobile"
              type="text"
              @keypress="onlyNumber"
              class="form-control"
              placeholder="Mobile"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Website</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.website"
              type="text"
              class="form-control"
              placeholder="Website"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Email</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.email"
              type="text"
              class="form-control"
              placeholder="Email"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Email</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.email"
              type="text"
              class="form-control"
              placeholder="Email"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="col-span-2">
          <label for="regular-form-1" class="form-label">Address</label>

          <div class="flex flex-col space-y-1">
            <textarea
              id="vertical-form-1"
              v-model="formDataLicensee.address"
              type="text"
              class="form-control h-10"
              placeholder="Address"
            ></textarea>
          </div>
        </div> -->

        <div>
          <label for="regular-form-1" class="form-label">ABN</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.abn"
              type="text"
              class="form-control"
              placeholder="ABN"
              autocomplete="off"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">TIN</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.acn"
              type="text"
              class="form-control"
              placeholder="TIN"
              autocomplete="off"
            />
          </div>
        </div>

        <!-- <div>
          <label for="regular-form-1" class="form-label"
            >Delivery Address</label
          >

          <div class="flex flex-col space-y-1">
            <textarea
              id="vertical-form-1"
              v-model="formDataLicensee.deliveryAddress"
              type="text"
              class="form-control h-10"
              placeholder="Delivery Address"
            ></textarea>
          </div>
        </div> -->

       

        <div>
          <label for="regular-form-1" class="form-label">Licencee Code</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.licenceeCode"
              type="text"
              class="form-control"
              placeholder="Licencee Code"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">ATTN</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.attn"
              type="text"
              class="form-control"
              placeholder="ATTN"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">BSB</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.bsb"
              type="text"
              class="form-control"
              placeholder="BSB"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">AC</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.ac"
              type="text"
              class="form-control"
              placeholder="AC"
              autocomplete="off"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Bank Name</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataLicensee.bankName"
              type="text"
              class="form-control"
              placeholder="Bank Name"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="closeAddForm()"
        class="btn btn-outline-secondary w-24 mr-4"
      >
        Cancel
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        Done
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Archive Modal -->
  <MoveableModal
    :show="archiveModal"
    @hidden="archiveModal = false"
    size="modal-sl-80"
    backdrop="static"
    title="Archived Licensee"
    @close="archiveModal = false"
  >
    <ModalBody class="p-0">
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0 p-4">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            autocomplete="off"
            clearable
          />
          <SearchIcon
            @click="searchItemArchive"
            v-if="searchActiveArchive == false"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
          />
          <XIcon
            @click="clearSearchArchive"
            v-if="searchActiveArchive == true"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
          />
        </div>
      </div>
      <!-- SEARCH END-->
      <hr />
      <!-- BEGIN: Archive Data List -->
      <div
        class="p-5 text-center text-lg"
        v-if="this.archiveData.totalCount == 0"
      >
        No data
      </div>
      <div
        class="intro-y col-span-12 overflow-auto p-4"
        v-if="this.archiveData.totalCount > 0"
      >
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th
                class="whitespace-nowrap"
                v-for="(header, i) in headers"
                :key="i"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-24">
                <div class="flex">
                  <RestoreItem
                    :activeAccess="$h.restoreItemAccess(policyAccess)"
                    @parentMethod="restoreItem($event)"
                    :item="item"
                    name="Restore"
                  />
                  <DeleteItem
                    :activeAccess="$h.deleteItemAccess(policyAccess)"
                    @parentMethod="deleteItem($event)"
                    :item="item"
                    name="Delete"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal
    :name="formDataLicensee.companyName"
    :modalStatus="archiveConfirmationModal"
    :modalConfirm="archiveItemConfirm"
    :modalClose="closeArchive"
  />
  <RestoreConfirmModal
    :name="formDataLicensee.companyName"
    :modalStatus="restoreConfirmationModal"
    :modalConfirm="restoreItemConfirm"
    :modalClose="closeRestore"
  />
  <DeleteConfirmModal
    :name="formDataLicensee.companyName"
    :modalStatus="deleteConfirmationModal"
    :modalConfirm="deleteItemConfirm"
    :modalClose="closeDelete"
  />

  <DeleteConfirmModal
    :name="formDataWarehouses.name"
    :modalStatus="deleteWarehouseConfirmationModal"
    :modalConfirm="deleteWarehouseItemConfirm"
    :modalClose="closeWarehouseDelete"
  />

  <DeleteConfirmModal
    :name="formDataUsers.userName"
    :modalStatus="deleteUserConfirmationModal"
    :modalConfirm="deleteUserItemConfirm"
    :modalClose="closeUserDelete"
  />

  <MoveableModal
    :show="addWarehouseInfoModal"
    @hidden="addWarehouseInfoModal = false"
    backdrop="static"
    size="modal-sl-60"
    :title="`Add Warehouse for ${formDataLicensee.companyName}`"
    @close="closeAddWarehouse()"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4">
        <div class="intro-y col-span-12 overflow-auto p-4">
          <div
            class="flex items-center justify-between w-full space-x-4"
            v-if="addwarehousemodal == false"
          >
            <button
              @click="addwarehousemodal = true"
              class="btn btn-primary h-10"
            >
              <BuildingIcon
                class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-white mr-2"
              />
              <span class="text-white uppercase">Add Warehouse</span>
            </button>
          </div>

          <div
            class="grid grid-cols-4 lg:gap-4 md:gap-4 gap-2 p-2"
            v-if="addwarehousemodal == true"
          >
            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Warehouse</label>

              <div class="flex flex-col space-y-1">
                <v-select
                  v-model="formDataWarehouses.warehouseId"
                  placeholder="SELECT"
                  class="style-chooser"
                  :filterable="false"
                  @open="onOpenWarehouse"
                  @close="onCloseWarehouse"
                  @search="searchWarehouse"
                  :key="abbreviation"
                  :get-option-label="(option) => option.abbreviation"
                  :options="warehouses"
                  :reduce="(abbreviation) => abbreviation.warehouseId"
                  label="abbreviation"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPageWarehouse"
                      ref="loadWarehouse"
                      class="loader"
                    >
                      Loading more...
                    </li>
                  </template>
                </v-select>
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Default</label>

              <div class="flex flex-col space-y-1">
                <div class="form-switch">
                  <input
                    id="booleanSwitch"
                    type="checkbox"
                    v-model="formDataWarehouses.isDefault"
                    class="form-check-input"
                  />
                </div>
              </div>
            </div>

            <div class="flex justify-end items-end col-span-4">
              <button
                type="button"
                @click="addwarehousemodal = false"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button
                @click="saveWarehouses()"
                type="submit"
                class="btn btn-primary mr-1 px-8"
              >
                Save
              </button>
            </div>
          </div>

          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th>Warehouse Name</th>
                <th>Abbr</th>
                <th>Description</th>

                <!-- <th>Address</th>
              <th>Date</th>
              <th>Time</th>
              <th>Booking#</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in warehousesLocal" :key="i" class="intro-x">
                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  <div :class="item.isDefault == true ? '' : ''">
                    <span
                      v-if="item.isDefault == true"
                      class="font-black text-xl text-center textStroke"
                      >DFLT</span
                    >
                  </div>
                  <div :class="item.isDefault == true ? 'hidden' : ''">
                    {{ i + 1 }}
                  </div>
                </td>

                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  {{ item.name ? item.name : "" }}
                </td>
                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  {{ item.abbreviation ? item.abbreviation : "" }}
                </td>
                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  {{ item.description ? item.description : "" }}
                </td>

                <td class="table-report__action w-20">
                  <div class="flex justify-center items-center">
                    <a
                      @click="defaultWarehouseItem(item)"
                      class="flex items-center mr-3"
                      href="javascript:;"
                      v-if="item.isDefault == false"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Set as Default Warehouse"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <AlbumIcon class="w-6 h-6 mr-1 text-primary" />
                      </Tippy>
                    </a>

                    <a
                      class="flex items-center mr-3"
                      href="javascript:;"
                      v-if="item.isDefault == true"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Default Warehouse"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <AlbumIcon class="w-6 h-6 mr-1 text-success" />
                      </Tippy>
                    </a>

                    <DeleteItem
                      :activeAccess="$h.deleteItemAccess(policyAccess)"
                      @parentMethod="deleteWarehouseItem($event)"
                      :item="item"
                      name="Delete"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <NoDataCus :items="warhousesList" />

          <PaginationBarLocal
            @changePage="changePageWarehouse($event)"
            :itemsData="itemWarehouseData"
            :pageCount="pageCountWarehouse"
            :pageRange="pageRowCountWarehouse"
            :pageSize="pageSizeProduct"
          />
        </div>

        <!-- END: Pagination -->
      </div>

      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <MoveableModal
    :show="addUserInfoModal"
    @hidden="addUserInfoModal = false"
    backdrop="static"
    size="modal-sl-60"
    :title="`Add User for ${formDataLicensee.companyName}`"
    @close="closeAddUser()"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4">
        <div class="intro-y col-span-12 overflow-auto p-4">
          <div
            class="flex items-center justify-between w-full space-x-4"
            v-if="addusermodal == false"
          >
            <button @click="addusermodal = true" class="btn btn-primary h-10">
              <BuildingIcon
                class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-white mr-2"
              />
              <span class="text-white uppercase">Add User</span>
            </button>
          </div>

          <div
            class="grid grid-cols-4 lg:gap-4 md:gap-4 gap-2 p-2"
            v-if="addusermodal == true"
          >
            <div class="col-span-4">
              <label for="regular-form-1" class="form-label">User</label>

              <div class="flex flex-col space-y-1">
                <v-select
                  v-model="formDataUsers.userId"
                  placeholder="SELECT"
                  :filterable="false"
                  @open="onOpenUsers"
                  @close="onCloseUsers"
                  @search="searchUsers"
                  :key="userName"
                  :get-option-label="(option) => option.userName"
                  :options="users"
                  :reduce="(userName) => userName.id"
                  label="userName"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPageUsers"
                      ref="loadUsers"
                      class="loader"
                    >
                      Loading more...
                    </li>
                  </template>
                </v-select>
              </div>
            </div>

            <div class="flex justify-end items-end col-span-4">
              <button
                type="button"
                @click="addusermodal = false"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button
                @click="saveUsers()"
                type="submit"
                class="btn btn-primary mr-1 px-8"
              >
                Save
              </button>
            </div>
          </div>

          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th>User Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>PhoneNumber</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in usersLocal" :key="i" class="intro-x">
                <td>
                  {{ i + 1 }}
                </td>

                <td>
                  {{ item.userName ? item.userName : "" }}
                </td>
                <td>
                  {{ item.firstName ? item.firstName : "" }}
                </td>
                <td>
                  {{ item.lastName ? item.lastName : "" }}
                </td>
                <td>
                  {{ item.email ? item.email : "" }}
                </td>
                <td>
                  {{ item.phoneNumber ? item.phoneNumber : "" }}
                </td>
                <td class="table-report__action w-20">
                  <div class="flex justify-center items-center">
                    <DeleteItem
                      :activeAccess="$h.deleteItemAccess(policyAccess)"
                      @parentMethod="deleteUserItem($event)"
                      :item="item"
                      name="Delete"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <NoDataCus :items="usersList" />

          <PaginationBarLocal
            @changePage="changePageProduct($event)"
            :itemsData="itemUserData"
            :pageCount="pageCountProduct"
            :pageRange="pageRowCountProduct"
            :pageSize="pageSizeProduct"
          />
        </div>

        <!-- END: Pagination -->
      </div>

      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Confirmation Modals -->

  <DefaultWarehouseConfirmModal
    :name="this.formDataWarehouses.name"
    :modalStatus="defaultWarehouseConfirmationModal"
    :modalConfirm="defaultItemWarehouseConfirm"
    :modalClose="closeWarehouseDefault"
  />

  <ResetPasswordConfirmModal
    :name="this.formDataLicensee.companyName"
    :modalStatus="resetPasscodeConfirmationModal"
    :modalConfirm="resetPasscodeConfirm"
    :modalClose="closeresetPasscode"
  />
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import validationMixins from "../../../mixins/validation";

import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";
import { const_txt } from "../../../global-functions/const";
import store from "../../../store";

export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Licensee");
    this.observerWarehouse = new IntersectionObserver(
      this.infiniteScrollWarehouse
    );
    this.observerUsers = new IntersectionObserver(this.infiniteScrollUsers);
  },
  components: {
    Loading,
    paginate: Paginate,
  },
  data() {
    return {
      resetPasscodeConfirmationModal: false,
      storeUserRole: store.state.authUser.userRole,
      baseurl: axios.defaults.baseURL.replace("/api/", "/"),
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      addWarehouse: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      deleteWarehouseConfirmationModal: false,
      deleteUserConfirmationModal: false,
      addwarehousemodal: false,
      addusermodal: false,
      addUserInfoModal: false,
      defaultWarehouseConfirmationModal: false,
      titleChange: -1,
      formDataLicensee: {
        companyName: "",
        abn: "",
        acn: "",
        address: "",
        deliveryAddress: "",
        isDifferentDeliveryAddress: false,
        phoneNumber: "",
        fax: "",
        website: "",
        email: "",
        attn: "",
        mobile: "",
        licenceeCode: "",
        bsb: "",
        ac: "",
        bankName: "",
      },
      formDataLicenseeDefault: {
        companyName: "",
        abn: "",
        acn: "",
        address: "",
        deliveryAddress: "",
        isDifferentDeliveryAddress: false,
        phoneNumber: "",
        fax: "",
        website: "",
        email: "",
        attn: "",
        mobile: "",
        licenceeCode: "",
        bsb: "",
        ac: "",
        bankName: "",
      },
      logo: null,
      formDataWarehouses: {
        warehouseId: "",
        isDefault: false,
      },
      formDataWarehousesDefault: {
        warehouseId: undefined,
        isDefault: false,
      },
      formDataUsers: {
        userId: "",
      },
      formDataUsersDefault: {
        userId: undefined,
      },
      headers: [
        { text: "Company Name", value: "companyName" },
        { text: "Passcode", value: "passcode" },
        { text: "address", value: "address" },
        { text: "Phone No", value: "phoneNumber" },
        { text: "mobile No", value: "mobile" },
        { text: "Fax", value: "fax" },
        { text: "website", value: "website" },
        { text: "email", value: "email" },
        { text: "licencee Code", value: "licenceeCode" },
        { text: "bankName", value: "bankName" },
        { text: "abn", value: "abn" },
        { text: "TIN", value: "acn" },
        { text: "attn", value: "attn" },
        { text: "bsb", value: "bsb" },
        { text: "ac", value: "ac" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },
      addWarehouseInfoModal: false,
      pageCountArchive: 20,
      pageSizeArchive: 5,

      csvHeaders: {
        warehouseId: "warehouseId",
        abbreviation: "abbreviation",
        name: "name",
        description: "description",
      },

      allItems: [],
      allItemsData: [],
      isActive: 0,
      doc: null,
      allSelected: false,
      checkedData: [],
      policyAccess: "Licensees",

      warhousesList: [],
      usersList: [],
      warehouses: [],
      users: [],

      itemUserData: [],
      pageCountProduct: 0,
      pageRowCountProduct: 5,
      productStart: 0,
      productEnd: 5,
      usersLocal: [],

      itemWarehouseData: [],
      pageCountWarehouse: 0,
      pageRowCountWarehouse: 5,
      warehouseStart: 0,
      warehouseEnd: 5,
      warehousesLocal: [],
      url: null,

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,

      observerUsers: null,
      limitUsers: 5,
      hasNextPageUsers: false,
      totalUsersCount: 0,
      isUsersSearchResult: false,
      usersPageNumber: 1,
      usersSearchTimer: undefined,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Licensee" : "Edit Licensee";
    },
    checkLimtWarehouse() {
      return this.limitWarehouse;
    },

    checkLimtUsers() {
      return this.usersPageNumber;
    },
    viewModalWh() {
      return this.formDataWarehouses.warehouseId;
    },
    viewModalUsers() {
      return this.formDataUsers.userId;
    },
  },
  watch: {
    viewModalWh() {
      if (this.formDataWarehouses.warehouseId === null) {
        this.clearWarehouse();
      }
    },
    viewModalUsers() {
      if (this.formDataUsers.userId === null) {
        this.clearUsers();
      }
    },
    checkLimtWarehouse() {
      this.getWarehouses();
    },
    checkLimtUsers() {
      this.getUsers();
    },
  },
  created() {
    this.getDataFromApi();
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async onOpenWarehouse() {
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenUsers() {
      if (this.isUsersSearchResult) {
        this.usersPageNumber = 1;
      }
      if (this.hasNextPageUsers) {
        await this.$nextTick();
        this.observerUsers.observe(this.$refs.loadUsers);
      }
    },
    onCloseUsers() {
      this.observerUsers.disconnect();
    },
    async infiniteScrollUsers([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.usersPageNumber++;
        this.limitUsers;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    closeAddForm() {
      this.addWarehouse = false;
      this.url = null;
      this.logo = null;
      this.setDefault();
    },
    chooseFiles() {
      const fileInput = document.getElementById("fileInput");
      fileInput.value = "";
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      this.logo = event.target.files[0];
      this.url = URL.createObjectURL(this.logo);
    },
    defaultWarehouseItem(item) {
      this.formDataWarehouses = Object.assign({}, item);
      this.defaultWarehouseConfirmationModal = true;
    },
    closeWarehouseDefault() {
      this.defaultWarehouseConfirmationModal = false;
    },
    closeresetPasscode() {
      this.resetPasscodeConfirmationModal = false;
    },
    defaultItemWarehouseConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
      var formData = {
        warehouseId: this.formDataWarehouses.warehouseId,
      };
      axios
        .put("Licensees/" + itemId + "/ChangeDefaultWarehouse", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.defaultWarehouseConfirmationModal = false;
            this.getWarehousesList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    resetPasscodeConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
     
      axios
        .get("Licensees/" + itemId + "/ResetPasscode",)
        .then((response) => {
          if (response.data.success === true) {
            this.resetPasscodeConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    changePageWarehouse(pageNum) {
      // this.pageNumber = pageNum;
      this.warehouseEnd = this.pageRowCountWarehouse * pageNum;
      this.warehouseStart = this.warehouseEnd - this.pageRowCountWarehouse;
      this.warehousesLocal = this.warhousesList.slice(
        this.warehouseStart,
        this.warehouseEnd
      );
    },
    changePageProduct(pageNum) {
      // this.pageNumber = pageNum;
      this.productEnd = this.pageRowCountProduct * pageNum;
      this.productStart = this.productEnd - this.pageRowCountProduct;
      this.usersLocal = this.usersList.slice(
        this.productStart,
        this.productEnd
      );
    },
    closeAddWarehouse() {
      this.addWarehouseInfoModal = false;
      this.addwarehousemodal = false;
      this.setWarehouseDefault();
    },
    closeAddUser() {
      this.addUserInfoModal = false;
      this.addusermodal = false;
      this.setUserDefault();
    },
    viewWarehouseItemInfo(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.addWarehouseInfoModal = true;
      this.getWarehousesList();
      this.isLoading = true;
      this.getWarehouses();
    },

    resetPasscode(item){
      this.formDataLicensee = Object.assign({}, item);
      this.resetPasscodeConfirmationModal = true;
    },

    viewUserItemInfo(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.addUserInfoModal = true;
      this.getusersList();
      this.isLoading = true;
      this.getUsers();
    },
    getWarehousesList() {
      this.isLoading = true;
      var licenseeId = this.formDataLicensee.licenseeId;
      axios
        .get("Licensees/" + licenseeId + "/Warehouses")
        .then((response) => {
          if (response.data.success === true) {
            this.itemWarehouseData = response.data;
            this.warhousesList = response.data.data;
            this.isLoading = false;
            this.setLocalWarehousePagination();
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setLocalWarehousePagination() {
      if (this.itemWarehouseData) {
        this.itemWarehouseData.totalCount = this.warhousesList.length;
      }

      var pageCount = this.warhousesList.length / this.pageRowCountWarehouse;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountWarehouse = pageCountRound;
      } else {
        this.pageCountWarehouse = pageCountRound;
      }
      if (this.pageRowCountWarehouse > this.itemWarehouseData.totalCount) {
        this.pageSizeProduct = this.itemWarehouseData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountWarehouse;
      }

      //pageCountWarehouse
      this.warehousesLocal = this.warhousesList.slice(
        this.warehouseStart,
        this.warehouseEnd
      );
    },
    getusersList() {
      this.isLoading = true;
      var licenseeId = this.formDataLicensee.licenseeId;
      axios
        .get("Licensees/" + licenseeId + "/Users")
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.itemUserData = response.data;
            this.usersList = response.data.data;
            this.isLoading = false;
            this.setLocalPagination();
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setLocalPagination() {
      if (this.itemUserData) {
        this.itemUserData.totalCount = this.usersList.length;
      }

      var pageCount = this.usersList.length / this.pageRowCountProduct;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountProduct = pageCountRound;
      } else {
        this.pageCountProduct = pageCountRound;
      }
      if (this.pageRowCountProduct > this.itemUserData.totalCount) {
        this.pageSizeProduct = this.itemUserData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountProduct;
      }

      //pageCountProduct
      this.usersLocal = this.usersList.slice(
        this.productStart,
        this.productEnd
      );
    },

    clearWarehouse() {
      this.warehouses = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehouses = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },

    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehouses =
            this.isWhSearchResult !== true
              ? Array.from(
                  new Set(
                    [...this.warehouses, ...response.data.data.items].map(
                      JSON.stringify
                    )
                  )
                ).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    clearUsers() {
      this.users = [];
      this.usersPageNumber = 1;
      this.limitUsers = 5;
      this.getUsers("");
    },

    searchUsers(search) {
      clearInterval(this.usersSearchTimer);
      this.usersSearchTimer = setTimeout(() => {
        if (search) {
          this.users = [];
          this.usersPageNumber = 1;
          this.limitUsers = this.totalUsersCount;
          this.getUsers(search.trim());
        }
      }, 2000);
    },

    getUsers(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.usersPageNumber,
        pageSize: this.limitUsers,
        search: search,
      };
      axios
        .post("Users/Paginated", pagination)
        .then((response) => {
          this.isUsersSearchResult = !!search;
          this.users =
            this.isUsersSearchResult !== true
              ? Array.from(
                  new Set(
                    [...this.users, ...response.data.data.items].map(
                      JSON.stringify
                    )
                  )
                ).map(JSON.parse)
              : response.data.data.items;

          this.hasNextPageUsers = response.data.data.hasNextPage;
          this.totalUsersCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    saveWarehouses() {
      if (
        this.formDataWarehouses.warehouseId == "" ||
        this.formDataWarehouses.warehouseId == null
      ) {
        if (this.formDataWarehouses.warehouseId == "") {
          warningToast("warehouse Required");
        }
      } else {
        this.isLoading = true;
        var licenseeId = this.formDataLicensee.licenseeId;
        var formData = {
          warehouseId: this.formDataWarehouses.warehouseId,
          isDefault: this.formDataWarehouses.isDefault,
        };
        axios
          .post("Licensees/" + licenseeId + "/Warehouses", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.addwarehousemodal = false;
              this.getWarehousesList();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
      }
    },

    saveUsers() {
      if (
        this.formDataUsers.userId == "" ||
        this.formDataUsers.userId == null
      ) {
        if (this.formDataUsers.userId == "") {
          warningToast("User Required");
        }
      } else {
        this.isLoading = true;
        var licenseeId = this.formDataLicensee.licenseeId;
        var formData = {
          userId: this.formDataUsers.userId,
        };
        axios
          .post("Licensees/" + licenseeId + "/Users", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.addusermodal = false;
              this.getusersList();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
      }
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Company Name", dataKey: "companyName" },
          { title: "Address", dataKey: "address" },
          { title: "Phone No", dataKey: "phoneNumber" },
          { title: "Mobile No", dataKey: "mobile" },
          { title: "Website", dataKey: "website" },
          { title: "Fax", dataKey: "fax" },
          { title: "Email", dataKey: "email" },
          { title: "Licencee Code", dataKey: "licenceeCode" },
          { title: "BankName", dataKey: "bankName" },
          { title: "ABN", dataKey: "abn" },
          { title: "TIN", dataKey: "acn" },
          { title: "ATTN", dataKey: "attn" },
          { title: "BSB", dataKey: "bsb" },
          { title: "AC", dataKey: "ac" },
        ];
        this.doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            companyName: el.companyName,
            address: el.address,
            phoneNumber: el.phoneNumber,
            mobile: el.mobile,
            fax: el.fax,
            website: el.website,
            email: el.email,
            licenceeCode: el.licenceeCode,
            bankName: el.bankName,
            abn: el.abn,
            acn: el.acn,
            attn: el.attn,
            bsb: el.bsb,
            ac: el.ac,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.doc
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.doc, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
      }
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.doc.autoPrint();
      this.doc.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.doc.save(`${"Licensee " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Licensee " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Company Name",
          2: "Phone No",
          3: "Mobile No",
          4: "Email",
          5: "Website",
          6: "Email",
          7: "Licencee Code",
          8: "BankName",
          9: "ABN",
          10: "TIN",
          11: "ATTN",
          12: "BSB",
          13: "AC",
          14: "Address",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.companyName,
            2: item.phoneNumber,
            3: item.mobile,
            3: item.fax,
            4: item.website,
            5: item.email,
            6: item.licenceeCode,
            7: item.bankName,
            8: item.abn,
            9: item.acn,
            10: item.attn,
            11: item.bsb,
            12: item.ac,
            13: item.address,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Licensees/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataLicensee = Object.assign({}, this.formDataLicenseeDefault);
    },
    setWarehouseDefault() {
      this.formDataWarehouses = Object.assign(
        {},
        this.formDataWarehousesDefault
      );
    },
    setUserDefault() {
      this.formDataUsers = Object.assign({}, this.formDataUsersDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Licensees/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView =
                this.itemsData.totalCount -
                (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addWarehouse = true;
      this.activateModal();
    },
    activateModal() {
      this.addWarehouse = true;
      this.getWarehouses();
    },
    closeForm() {
      this.addWarehouse = false;
    },
    editValues(item) {
      this.titleChange = 0;
      this.url = `${this.baseurl}${item.logo}`;
      this.formDataLicensee = Object.assign({}, item);
      this.addWarehouse = true;
    },

    saveForm() {
      if (
        this.formDataLicensee.companyName == "" ||
        this.formDataLicensee.phoneNumber == "" ||
        this.formDataLicensee.email == ""
      ) {
        if (this.formDataLicensee.companyName == "") {
          warningToast("Company Name Required");
        }
        if (this.formDataLicensee.phoneNumber == "") {
          warningToast("Phone Number Required");
        }
        if (this.formDataLicensee.email == "") {
          warningToast("Email Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;

          let formData = new FormData();

          formData.append("CompanyName", this.formDataLicensee.companyName);
          formData.append("ABN", this.formDataLicensee.abn);
          formData.append("ACN", this.formDataLicensee.acn);
          formData.append("Address", this.formDataLicensee.address);
          formData.append(
            "DeliveryAddress",
            this.formDataLicensee.deliveryAddress
          );
          formData.append(
            "IsDifferentDeliveryAddress",
            this.formDataLicensee.isDifferentDeliveryAddress
          );
          formData.append("PhoneNumber", this.formDataLicensee.phoneNumber);
          formData.append("Fax", this.formDataLicensee.fax);
          formData.append("Website", this.formDataLicensee.website);
          formData.append("Email", this.formDataLicensee.email);
          formData.append("ATTN", this.formDataLicensee.attn);
          formData.append("Mobile", this.formDataLicensee.mobile);
          formData.append("LicenceeCode", this.formDataLicensee.licenceeCode);
          formData.append("BSB", this.formDataLicensee.bsb);
          formData.append("AC", this.formDataLicensee.ac);
          formData.append("BankName", this.formDataLicensee.bankName);

          if (this.logo != null) {
            formData.append("Logo", this.logo, this.logo.name);
          } else {
            formData.append("Logo", null);
          }
          axios
            .post("Licensees", formData)
            .then((response) => {
              if (response.data.success === true) {
                this.closeAddForm();
                this.getDataFromApi();
                successToast(response.data.message);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataLicensee.licenseeId;
          this.isLoading = true;
          let formData = new FormData();

          formData.append("CompanyName", this.formDataLicensee.companyName);
          formData.append("ABN", this.formDataLicensee.abn);
          formData.append("ACN", this.formDataLicensee.acn);
          formData.append("Address", this.formDataLicensee.address);
          formData.append(
            "DeliveryAddress",
            this.formDataLicensee.deliveryAddress
          );
          formData.append(
            "IsDifferentDeliveryAddress",
            this.formDataLicensee.isDifferentDeliveryAddress
          );
          formData.append("PhoneNumber", this.formDataLicensee.phoneNumber);
          formData.append("Website", this.formDataLicensee.website);
          formData.append("Email", this.formDataLicensee.email);
          formData.append("ATTN", this.formDataLicensee.attn);
          formData.append("Mobile", this.formDataLicensee.mobile);
          formData.append("Fax", this.formDataLicensee.fax);
          formData.append("LicenceeCode", this.formDataLicensee.licenceeCode);
          formData.append("BSB", this.formDataLicensee.bsb);
          formData.append("AC", this.formDataLicensee.ac);
          formData.append("BankName", this.formDataLicensee.bankName);

          if (this.logo != null) {
            formData.append("Logo", this.logo, this.logo.name);
          } else {
            formData.append("Logo", null);
          }
          axios
            .put("Licensees/" + itemId, formData)
            .then((response) => {
              if (response.data.success === true) {
                this.closeAddForm();
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast(statusMessage(error.response.status));
            });
        }
      }
    },

    archiveItem(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },

    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },
    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeWarehouseDelete() {
      this.deleteWarehouseConfirmationModal = false;
      this.setWarehouseDefault();
      this.addwarehousemodal = false;
    },
    closeUserDelete() {
      this.deleteUserConfirmationModal = false;
      this.setUserDefault();
      this.addusermodal = false;
    },
    archiveItemConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
      axios
        .get("Licensees/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Licensees/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
      axios
        .get("Licensees/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteWarehouseItem(item) {
      this.formDataWarehouses = Object.assign({}, item);
      this.deleteWarehouseConfirmationModal = true;
      this.addwarehousemodal = false;
    },
    deleteUserItem(item) {
      this.formDataUsers = Object.assign({}, item);
      this.deleteUserConfirmationModal = true;
      this.addusermodal = false;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      axios
        .delete("Licensees/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteWarehouseItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      var formdata = {
        warehouseId: this.formDataWarehouses.warehouseId,
        isDefault: false,
      };

      console.log("wareid");
      console.log(formdata);
      axios
        .delete("Licensees/" + itemId + "/Warehouses", formdata)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.deleteWarehouseConfirmationModal = false;
            this.getWarehousesList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    deleteWarehouseItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      var formdata = {
        warehouseId: this.formDataWarehouses.warehouseId,
        isDefault: false,
      };
      axios
        .delete("Licensees/" + itemId + "/Warehouses", { data: formdata })
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.deleteWarehouseConfirmationModal = false;
            this.getWarehousesList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteUserItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      var formdata = {
        userId: this.formDataUsers.userId,
      };

      axios
        .delete("Licensees/" + itemId + "/Users", { data: formdata })
        .then((response) => {
          if (response.data.success === true) {
            this.deleteUserConfirmationModal = false;
            this.getusersList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    getSelectedColor() {
      return "background-color:rgb(23,79,100);color: white";
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
