<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showLPNModal($event)" buttonName="Add LPN" />

          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="printAllPages"
          />
          <PrintLabels @parentMethod="printLabels($event)" />
          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
          <!-- SEARCH END-->
          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="whitespace-nowrap uppercase">
              <th class="whitespace-nowrap uppercase" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <!-- <th>Order</th> -->
              <th>Created Date</th>
              <th>Despatched</th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
                <!-- <ArchiveItem
                  class="ml-3 -mt-1"
                  :activeAccess="$h.archiveItemAccess(policyAccess)"
                  v-if="checkedData.length > 0"
                  @parentMethod="archiveMultipleItem($event)"
                  name="Archive Selected"
                /> -->
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] != null || item[header.value] != undefined ? item[header.value] : "" }}
              </td>
              <!-- <td>
                {{ item.order != null ? item.order.orderNumber : "" }}
              </td> -->
              <td>
                {{ item.date != null || item.date != undefined ? momentLocal(item.date) : "" }}
              </td>

              <td>
                <div class="form-switch ml-3">
                  <input id="booleanSwitch" type="checkbox" v-model="item.isDespatched" class="form-check-input" disabled />
                </div>
              </td>

              <td class="table-report__action w-32">
                <div class="lg:grid lg:grid-cols-3 gap-x-6 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                  <a @click="viewProduct(item)" class="flex items-center mr-3" href="javascript:;">
                    <Tippy tag="a" href="javascript:;" class="tooltip" content="View Product">
                      <PackageIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1 text-primary" />
                    </Tippy>
                  </a>
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->

      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>
  <!-- BEGIN: Add  Modal -->

  <!-- END: Add Modal -->

  <MoveableModal :show="addLPN" @hidden="addLPN = false" backdrop="static" size="modal-sl-80" :title="formLPNTitle" @close="closeLPNForm()">
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <div class="flex items-center space-x-2 bg-slate-200 text-black px-2 py-2">
            Last Created LPN :

            <div v-if="itemsLPNS != 'LPN0'" class="text-success">
              {{ itemsLPNS }}
            </div>

            <div v-if="itemsLPNS == 'LPN0'" class="text-success">
              {{ itemsLPNS }}
            </div>
          </div>

          <label for="regular-form-1" class="form-label pt-4">Number of LPN</label>

          <div class="flex flex space-x-2 justify-center items-center pt-2">
            <input id="vertical-form-1" v-model="count" type="number" class="form-control" placeholder="LPN Number" autocomplete="off" />

            <svg
              v-if="count != '' && !countSelected"
              @click="countField(formDataLPNGen.lpns, count)"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi bi-plus-circle w-8 h-8 text-success"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>

            <svg
              v-show="formDataLPNGen.lpns.length >= 1 && count > 0"
              @click="removeCountField(index, formDataLPNGen.lpns, count)"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi bi-x-circle w-8 h-8 text-red-400"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>

        <div class="intro-y col-span-12 overflow-auto" v-if="count > 0 && countSelected == true">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>QR Code</th>
                <th>LPN</th>
                <th class="flex justify-start">
                  <SelectLabels :allSelected="allSelectedLpn" @selectAll="selectAllLpn($event)" @unSelectAll="unSelectAllLpn($event)" />
                </th>
              </tr>
            </thead>
            <tr class="intro-x" v-for="(input, index) in formDataLPNGen.lpns" :key="`phoneInput-${index}`">
              <td>
                <qrcode-vue render-as="canvas" :value="input.fieldNo" size="45" level="H" :id="`fieldNoQR-${input.fieldNo}`" />
              </td>

              <td>
                <input id="vertical-form-1" v-model="input.fieldNo" type="text" class="form-control" placeholder="LPN" autocomplete="off" />
              </td>
              <td class="table-report__action w-auto">
                <div class="flex justify-center items-center">
                  <CheckItem :item="input" :checkedData="checkedDataLpn" @parentMethod="checkItemLpn($event)" />
                  <!-- <a class="flex items-center mr-3" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Select LPN"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <input
                        id="vertical-form-3"
                        class="form-check-input w-6 h-6 mr-1 bg-slate-100"
                        type="checkbox"
                        :value="input.fieldNo"
                        v-model="checkedDataField"
                      />
                    </Tippy>
                  </a>
                  <a
                    @click="printLPN(input)"
                    class="flex items-center mr-3"
                    href="javascript:;"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Print"
                      :options="{
                        zIndex: 99999,
                      }"
                      ><PrinterIcon
                        class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                      />
                    </Tippy>
                  </a>
                  <a
                    class="flex items-center text-blue-900"
                    href="javascript:;"
                    @click="generateLPN(input)"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Download"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <FileTextIcon
                        class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                      />
                    </Tippy>
                  </a> -->
                </div>
              </td>
            </tr>
          </table>

          <div class="flex items-center justify-center">
            <PrintLabels @parentMethod="printLabelsLpn($event)" />

            <a href="javascript:;" @click="onPrintLPN()" class="mr-2 btn btn-outline-secondary shadow-md text-xs hover:text-black sm:w-auto mr-2 mt-1 mb-1">
              Print
              <span class="ml-2">
                <PrinterIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>

            <a href="javascript:;" @click="generateReportLPN()" class="mr-2 btn btn-outline-secondary shadow-md text-xs hover:text-black sm:w-auto mr-2 mt-1 mb-1">
              Download PDF
              <span class="ml-2">
                <FileTextIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeLPNForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button :disabled="!countSelected" @click="saveLPNForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal
    :show="pickedProductsModal"
    @hidden="pickedProductsModal = false"
    backdrop="static"
    size="modal-sl-85"
    :title="'LPN: ' + getLpn"
    @close="pickedProductsModal = false"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-6">
        <!-- BEGIN: Data List -->

        <div class="intro-y col-span-12 overflow-auto md:mt-4 mt-2">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th class="whitespace-nowrap" v-for="(header, i) in pickedProductsHeaders" :key="i">
                  {{ header.text }}
                </th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-show="pickedProductsItems.length <= 0">
                <td colspan="12">
                  <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_DATA_TABLE }}</span>
                </td>
              </tr>
              <tr v-for="(item, i) in pickedProductsItems" :key="i" class="intro-x">
                <td v-for="(header, index) in pickedProductsHeaders" :key="index" class="cursor-pointer">
                  {{ item[header.value] != null || item[header.value] != undefined ? item[header.value] : "" }}
                </td>
                <td>
                  <div class="text-left line-clamp-2">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      :content="item.description"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      {{ item.description != null || item.description != undefined ? item.description : "" }}
                    </Tippy>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <!-- BEGIN: print  Modal -->

  <MoveableModal
    :show="addCheckedPrintModal"
    @hidden="addCheckedPrintModal = false"
    backdrop="static"
    title="Do you want to print the selected label(s) ?"
    @close="addCheckedPrintModal = false"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div class="relative col-span-3 pt-2">
          <div class="absolute -top-2.5 left-4 form-switch flex justify-center items-center space-x-2">
            <div class="flex space-x-2">
              <input type="radio" id="localip" :value="true" v-model="choosePrint" />

              <label for="localip">Internal Print</label>
            </div>
            <div class="flex space-x-2">
              <input type="radio" id="publicip" :value="false" v-model="choosePrint" />
              <label for="publicip">Print from web</label>
            </div>
          </div>
          <div class="border rounded-md border-slate-200 border-solid p-4 h-full w-full">
            <div class="flex flex-col space-y-2 w-full">
              <div>
                <h2>{{ this.changePrintType }} : {{ this.defaultIp }}</h2>
              </div>
              <div>
                <label for="regular-form-1" class="form-label">Label Printer IP</label>

                <div class="flex flex-col space-y-1">
                  <input id="vertical-form-1" v-model="formDataPrint.ip" type="text" autocomplete="off" class="form-control" placeholder="IP" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Port</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.port" autocomplete="off" type="text" class="form-control" placeholder="Port.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Number of Copies</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataPrint.nbrOfCopies" type="number" autocomplete="off" class="form-control" placeholder="Number of copies.." />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Selected Label(s)</label>

          <div id="labelsScroll" class="flex flex-col space-y-1 overflow-scroll h-36">
            <table>
              <tr class="text-center">
                <th>No</th>
                <th>LPN</th>
                <th>Despatched</th>
                <th>Date</th>
              </tr>
              <tr class="text-center" v-for="(item, i) in formDataPrint.lpns" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.lpn }}</td>
                <td>
                  <div v-if="item.isDespatched">Yes</div>
                  <div else>No</div>
                </td>
                <td>
                  {{ item.date === null || item.date === undefined ? "" : momentLocal(item.date) }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <!-- <button
        @click="confirmClientPrint()"
        type="submit"
        class="btn whitespace-nowrap btn-primary px-1 w-auto mr-1"
      >
        Internal Print
      </button>
      <button
        @click="confirmPrint()"
        type="submit"
        class="btn whitespace-nowrap btn-primary px-1 w-auto mr-1"
      >
        Print for web
      </button> -->
      <button @click="confirmPrint()" type="submit" class="btn whitespace-nowrap btn-primary px-1 w-auto mr-1">Print Labels</button>
      <button type="button" @click="addCheckedPrintModal = false" class="btn btn-outline-secondary w-auto mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: print Modal -->

  <DeleteConfirmModal :name="formDataLPNGen.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import QRCode from "qrcode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";
import QrcodeVue from "qrcode.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import moment from "moment";
import axiosReal from "axios";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "NEW LPNS");
  },
  components: {
    Loading,

    QrcodeVue,
  },

  name: "AddRemove",

  data() {
    return {
      isLpnPrint: false,
      choosePrint: true,
      ipType: "Local IP",
      defaultIp: "",
      allSelectedLpn: false,
      checkedDataLpn: [],
      itemsTemp: [],
      deleteConfirmationModal: false,
      isLoading: false,
      const_txt: const_txt,
      searchData: "",
      searchActive: false,

      // formDataLPNS: {
      //   warehouse: "",
      //   lots: [],
      // },
      // formDataLPNSDefault: {
      //   warehouse: "",
      //   lots: [],
      // },

      headers: [
        { text: "LPN", value: "lpn" },
        // { text: "Date", value: "date" },
        // { text: "Order", value: "order" },
        // { text: "User Name", value: "addedBy" },
        // { text: "Verified By", value: "verifiedBy" },
        // { text: "Verified At", value: "verifiedAt" },
      ],
      headersFilter: [
        { text: "LPN", value: "lpn" },

        { text: "User Name", value: "addedBy" },
        { text: "Verified By", value: "verifiedBy" },
        { text: "Verified At", value: "verifiedAt" },
      ],
      pickedProductsHeaders: [
        { text: "Order Number", value: "orderNumber" },
        { text: "PLU/SKU", value: "sku" },
        { text: "BARCODE/EAN", value: "ean" },
        { text: "Warehouse", value: "warehouse" },
        { text: "Storage Section", value: "storageSection" },
        { text: "Storage Bin", value: "storageBin" },
        { text: "Storage Type", value: "storageType" },
        { text: "QTY", value: "quantity" },
        { text: "Expiry Date", value: "expiryDate" },
        { text: "Picked", value: "picked" },
        { text: "Not Picked", value: "notPicked" },
        // { text: "Description", value: "description" },
      ],

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        // sortColumnName: "lpn",
        // sortDirection: "desc",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],

      //lpn generate
      count: "",
      countSelected: false,

      warehouseList: [],
      addLPN: false,

      titleLPNChange: -1,
      formDataLPNGen: {
        warehouse: "",
        lpns: [],
      },
      formDataLPNGenDefault: {
        warehouse: "",
        lpns: [],
      },
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      itemsLPNS: "",

      docLPN: null,
      pdfLPN: null,
      checkedDataField: [],
      selectedItemsData: [],

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      getLpn: "",
      pickedProductsModal: false,
      pickedProductsItems: [],
      splitData: false,
      allSelected: false,
      checkedData: [],
      addCheckedPrintModal: false,
      formDataPrint: {
        lpns: [],
        ip: "192.0.0.0",
        port: "8100",
        nbrOfCopies: 1,
      },

      policyAccess: "LPNS",
    };
  },
  computed: {
    changePrintType() {
      return this.choosePrint ? "Local IP" : "Public IP";
    },
    formLPNTitle() {
      return this.titleLPNChange === -1 ? "Generate LPN" : "Edit LPN";
    },
    btnName() {
      return this.titleLPNChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
  },
  created() {
    this.getDataFromApi();
  },
  watch: {
    choosePrint() {
      this.defaultIp = "";
      return this.setIp();
    },
  },
  methods: {
    checkItemLpn(item) {
      var include = this.checkedDataLpn.includes(item);
      if (include) {
        const index = this.checkedDataLpn.indexOf(item);
        if (index > -1) {
          this.checkedDataLpn.splice(index, 1);
        }
      } else {
        this.checkedDataLpn.push(item);
      }
      this.isLpnPrint = true;
    },
    unSelectAllLpn() {
      this.allSelectedLpn = false;
      this.checkedDataLpn = [];
      this.isLpnPrint = true;
    },
    selectAllLpn() {
      this.allSelectedLpn = true;
      this.isLpnPrint = true;
      this.checkedDataLpn = [];
      for (var i = 0; i < this.itemsTemp.length; i++) {
        this.checkedDataLpn[i] = this.itemsTemp[i];
      }
    },
    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    deleteItem(item) {
      this.formDataLPNGen = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      var itemId = this.formDataLPNGen.lpnId;
      this.isLoading = true;
      axios
        .delete("Lpns/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
      this.isLpnPrint = false;
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    viewProduct(item) {
      this.isLoading = true;
      this.getLpn = item.lpn;
      this.pickedProductsModal = true;
      axios
        .post("Despatches/Lpns/" + this.getLpn)
        .then((response) => {
          if (response.data.success === true) {
            this.pickedProductsItems = response.data.data.pickedProducts;

            // this.pageCount = this.itemsData.totalPages;
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setIp() {
      if (this.choosePrint) {
        let config = this.$printerHub.getConfig();
        if (config.host) {
          var lastValue = localStorage.getItem("localIp") !== null ? JSON.parse(localStorage.getItem("localIp")) : "";
          var host = config.host;
          var ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
          if (ipRegex.test(host)) {
            var split = host.split(/\.(?=[^\.]+$)/);
            this.formDataPrint.ip = split[0] + "." + lastValue;
          } else {
            this.formDataPrint.ip = host + lastValue;
          }
          this.formDataPrint.port = config.port;
          this.defaultIp = config.host;
          successToast("Internal print configuration selected");
        } else {
          failedToast("Local IP is not conneced, please check your configuration");
        }
      } else {
        this.getDefaultIP();
        this.formDataPrint.port = 8100;
        successToast("Print for web configuration selected");
      }
    },
    printLabelsLpn() {
      this.setIp();

      this.formDataPrint.lpns = [];
      if (this.checkedDataLpn.length > 0) {
        this.addCheckedPrintModal = true;
        // for (var i = 0; i < this.checkedDataLot.length; i++) {
        //   this.formDataPrint.lots[i] = this.checkedDataLot[i];
        // }
        this.formDataPrint.lpns = this.checkedDataLpn.map((x) => {
          return { lpn: x.fieldNo };
        });
        console.log("formDataPrint", this.formDataPrint);
      } else {
        warningToast("Please Select LPN");
      }
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "LPN", dataKey: "lpn" },
              { title: "Created Date", dataKey: "date" },
              // { title: "Order", dataKey: "order" },
              // { title: "Added By", dataKey: "addedBy" },
              // { title: "Verified By", dataKey: "verifiedBy" },
              // { title: "Verified At", dataKey: "verifiedAt" },
              // { title: "Date", dataKey: "date" },
              // { title: "Despatched", dataKey: "isDespatched" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                lpn: el.lpn,
                date: el.date,
                // order: el.order,
                // addedBy: el.addedBy,
                // verifiedBy: el.verifiedBy,
                // verifiedAt: el.verifiedAt,
                // date: this.momentLocal(el.date),
                // isDespatched: el.isDespatched,
              };
              //i++;
              data.push(obj);
              //   console.log(obj);
            });
            //console.log("DATA", data);

            // var imgData = "/src/assets/images/pl_logo2.png";
            // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
            // this.docs
            //   .setFontSize(16)
            //   .text("Storage Bin", 5, 1.2);

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post(`Lpns/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
      this.isLpnPrint = false;
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
      this.isLpnPrint = false;
    },
    confirmPrint() {
      if (this.choosePrint) {
        this.confirmClientPrint();
      } else {
        if (this.formDataPrint.ip == "" || this.formDataPrint.port == "" || this.formDataPrint.nbrOfCopies == "") {
          if (this.formDataPrint.ip == "") {
            warningToast("IP Required");
          }
          if (this.formDataPrint.port == "") {
            warningToast("Port Required");
          }
          if (this.formDataPrint.nbrOfCopies == "") {
            warningToast("Num of Copies Required");
          }
        } else {
          this.isLoading = true;
          var lpns = this.formDataPrint.lpns.map((x) => {
            return {
              lpnId: x.lpnId,
              lpn: x.lpn,
              date: x.date,
              addedBy: x.addedBy,
              isDespatched: x.isDespatched,
              verifiedBy: x.verifiedBy,
              verifiedAt: x.verifiedAt,
              orderId: x.order.orderId,
              despatch: x.dispatch,
              pickedProducts: x.order.orderLines,
            };
          });

          var formData = {
            lpns: lpns,
            ip: this.formDataPrint.ip,
            port: this.formDataPrint.port,
            nbrOfCopies: this.formDataPrint.nbrOfCopies,
          };

          axios
            .post("Lpns/PrintLpns ", formData)
            .then((response) => {
              if (response.data.success === true) {
                successToast(response.data.message);
                this.isLoading = false;
              } else {
                failedToast(response.data.message);
                this.isLoading = false;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
          this.addCheckedPrintModal = false;
          this.checkedData = [];
        }
      }
    },
    confirmClientPrint() {
      this.$printerHub.connect({
        host: this.formDataPrint.ip,
        port: this.formDataPrint.port,
        timeout: 3000,
      });

      let lastValues = this.formDataPrint.ip.split(".")[3];
      if (this.formDataPrint !== null) {
        store.dispatch("authUser/setlocalIp", lastValues);
      }

      if (this.isLpnPrint === false) {
        let selectedLables = this.checkedData;
        let maxLables = 3;
        let totalLables = selectedLables.length;
        let totalBatches = Math.ceil(totalLables / maxLables);
        let positions = [
          {
            l1: "FT53",
            l2: "FT82",
          },
          {
            l1: "FT330",
            l2: "FT360",
          },
          {
            l1: "FT602",
            l2: "FT632",
          },
        ];
        for (let batch = 1; batch <= totalBatches; batch++) {
          let start = (batch - 1) * maxLables;
          let end = batch * maxLables;
          let batchLabels = selectedLables.slice(start, end);

          let query = `^XA^LS0,^LT8`;
          var data;
          setTimeout(() => {
            for (let i = 0; i < this.formDataPrint.nbrOfCopies; i++) {
              for (let position = 0; position < batchLabels.length; position++) {
                data = query += `^${positions[position].l1},192^BQN,2,8
^FH\^FDLA,${batchLabels[position].lpn}^FS
^${positions[position].l2},198^CFA,30^FD${batchLabels[position].lpn}^FS
^PQ${this.formDataPrint.nbrOfCopies},0,1,Y`;
                this.$printerHub.write(data + "^XZ");
              }
            }
            console.log("LPN : ", data + "^XZ");
          }, 2000);

          this.addCheckedPrintModal = false;
          this.checkedData = [];
        }
      } else {
        let selectedLables = this.checkedDataLpn;
        let maxLables = 3;
        let totalLables = selectedLables.length;
        let totalBatches = Math.ceil(totalLables / maxLables);
        let positions = [
          {
            l1: "FT53",
            l2: "FT82",
          },
          {
            l1: "FT330",
            l2: "FT360",
          },
          {
            l1: "FT602",
            l2: "FT632",
          },
        ];
        for (let batch = 1; batch <= totalBatches; batch++) {
          let start = (batch - 1) * maxLables;
          let end = batch * maxLables;
          let batchLabels = selectedLables.slice(start, end);

          let query = `^XA^LS0,^LT8`;
          var data;
          setTimeout(() => {
            for (let i = 0; i < this.formDataPrint.nbrOfCopies; i++) {
              for (let position = 0; position < batchLabels.length; position++) {
                data = query += `^${positions[position].l1},192^BQN,2,8
^FH\^FDLA,${batchLabels[position].fieldNo}^FS
^${positions[position].l2},198^CFA,30^FD${batchLabels[position].fieldNo}^FS
^PQ${this.formDataPrint.nbrOfCopies},0,1,Y`;
                this.$printerHub.write(data + "^XZ");
              }
            }
            console.log("LPN : ", data + "^XZ");
          }, 2000);

          this.addCheckedPrintModal = false;
          this.checkedDataLpn = [];
        }
      }
    },
    printLabels() {
      this.setIp();

      this.formDataPrint.lpns = [];
      if (this.checkedData.length > 0) {
        this.addCheckedPrintModal = true;
        for (var i = 0; i < this.checkedData.length; i++) {
          this.formDataPrint.lpns[i] = this.checkedData[i];
        }
      } else {
        warningToast("Please Select LPNS");
      }
    },
    getDefaultIP() {
      this.isLoading = true;
      axiosReal
        .get("https://api.ipify.org/")
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.defaultIp = response.data;
            this.formDataPrint.ip = response.data;
          } else {
            this.isLoading = false;
            failedToast("something went wrong in getting public IP");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Lpns " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Lpns " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "LPN",
          2: "Created Date",
          // 2: "Order",
          // 3: "Added By",
          // 4: "Verified By",
          // 5: "Verified At",
          // 6: "Date",
          // 7: "Despatched",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.lpn,
            2: this.momentLocalCSV(item.date),
            // 2: item.order,
            // 3: item.addedBy,
            // 4: item.verifiedBy,
            // 5: item.verifiedAt,
            // 6: this.momentLocalCSV(item.date),
            // 7: item.isDespatched,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post(`Lpns/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    //lpn generate
    countField(fieldType, count) {
      var split_string = this.itemsLPNS.split(/(\d+)/);

      var str_c = split_string.slice(0, split_string.length - 2).join("");
      var str = parseInt(split_string[split_string.length - 2]);

      for (let val = 1; val <= count; val++) {
        fieldType.push({ fieldNo: str_c + parseInt(val + str) });

        fieldType.splice(count, count);
      }
      this.countSelected = true;
      this.itemsTemp = fieldType;
    },
    removeCountField(index, fieldType, count) {
      this.countSelected = false;
      this.count = "";
      fieldType.splice(index, count);
      this.itemsTemp = [];
      this.unSelectAllLpn();
    },
    addField(value, fieldType) {
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    // getWarehouseData() {
    //   var pagination = { pageNumber: 1, pageSize: 10 };
    //   axios
    //     .post("Warehouses/Paginated", pagination)
    //     .then((response) => {
    //       this.warehouseList = response.data.data.items;
    //       // if (this.warehouseList.length > 0) {
    //       //   this.formDataPurchaseOrder.warehouseId =
    //       //     this.warehouseList[0].warehouseId;
    //       // } else

    //       if (this.warehouseList.length == 0) {
    //         warningToast("Please Add Items in Warehouse");
    //         this.addLPN = false;
    //       }
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },

    getDataFromLPNS() {
      var abbreviation = this.warehouseAbbreviation;
      this.isLoading = true;
      axios
        .post("Lpns/LastCreated/" + abbreviation)
        .then((response) => {
          if (response.data.success === true) {
            var lastValue = response.data.data;
            if (lastValue == "" || lastValue == null) {
              this.itemsLPNS = "LPN0";
            } else {
              this.itemsLPNS = lastValue;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    setSelectReportLPN(qrGenerate) {
      var newArray = [];
      for (var x = 0; x < this.checkedDataField.length; x++) {
        for (var y = 0; y < qrGenerate.length; y++) {
          var result = qrGenerate.filter((obj) => {
            return obj.fieldNo === this.checkedDataField[x];
          });

          newArray.push(result[0]);
        }
      }

      newArray = newArray.filter((value, index, self) => index === self.findIndex((t) => t.fieldNo == value.fieldNo));
      this.selectedItemsData = newArray;
    },

    setReportLPN() {
      return new Promise(async (resolve, reject) => {
        if (this.checkedDataLpn.length > 0) {
          var items = this.checkedDataLpn;
        } else {
          var items = this.itemsTemp;
        }

        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "QR", dataKey: "qr" },
              { title: "LPN#", dataKey: "fieldNo" },
            ];
            this.docLPN = new jsPDF({
              orientation: "portrait",
              unit: "in",
              format: "a4",
            });

            let data = await this.getItemsWithQRLpn(items);

            autoTable(this.docLPN, {
              theme: "grid",
              columns,
              body: data,
              headStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              columnStyles: {
                1: {
                  minCellHeight: 1,
                  cellWidth: 1,
                },
              },
              margin: { left: 0.5, top: 0.5 },
              didDrawCell: this.didDrawCell,
            });

            const pageCount = this.docLPN.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
              this.docLPN.setPage(i);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docLPN.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${i} of ${pageCount}`;
              this.docLPN.setFontSize(10);
              this.docLPN.text(footer, pageWidth / 2 - this.docLPN.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    getItemsWithQRLpn(list) {
      return new Promise((resolve, reject) => {
        try {
          var data = [];
          let i = 0;
          list.forEach(async (el) => {
            i += 1;
            var obj = {
              fieldNo: el.fieldNo,
            };
            obj.qrData = await QRCode.toDataURL(JSON.stringify(el.fieldNo));
            data.push(obj);
          });
          resolve(data);
        } catch (error) {
          reject([]);
        }
      });
    },

    didDrawCell(data) {
      if (data.column.dataKey === "qr" && data.cell.section === "body") {
        var dim = data.cell.height - data.cell.padding("vertical");
        var textPos = data.cell;

        if (data.row && data.row.raw && data.row.raw.qrData) {
          this.docLPN.addImage(data.row.raw.qrData, textPos.x + 0.05, textPos.y + 0.04, dim, dim);
        }
      }
    },

    // setReportLPN(qrGenerate) {
    //   if (this.checkedDataField.length > 0) {
    //     this.setSelectReportLPN(qrGenerate);
    //     var qrGenerate = this.selectedItemsData;
    //   } else {
    //     var qrGenerate = qrGenerate;
    //   }

    //   this.docLPN = new jsPDF({
    //     orientation: "portrait",
    //     unit: "in",
    //     format: "a4",
    //   });

    //   let data = [];
    //   let i = 0;
    //   let obj;

    //   qrGenerate.forEach((el) => {
    //     obj = {
    //       fieldNo: el.fieldNo,
    //     };
    //     //i++;
    //     data.push(obj);
    //     //   console.log(obj);
    //   });
    //   //console.log("DATA", data);

    //   for (var j = 0; j < data.length; j++) {
    //     var imgData = "/src/assets/images/pl_logo2.png";
    //     this.docLPN.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
    //     // this.docLPN
    //     //   .setFontSize(16)
    //     //   .text("Order Number : ", 5, 1.2);

    //     this.docLPN.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

    //     this.docLPN.setFontSize(14).text("Deliver To :", 0.5, 2.5);

    //     this.docLPN.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
    //     this.docLPN.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
    //     this.docLPN.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
    //     this.docLPN.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
    //     this.docLPN.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
    //     this.docLPN.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
    //     this.docLPN
    //       .setFontSize(12)
    //       .text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

    //     //trading partner
    //     this.docLPN.setFontSize(14).text("Trading Partner :", 5, 2.5);

    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text(

    //     //       " fname" ,
    //     //     5.2,
    //     //     2.7
    //     //   );
    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text("this.formDataOrder.customer.company", 5.2, 2.9);
    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text("this.formDataOrder.customer.mobile", 5.2, 3.1);
    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text("this.formDataOrder.customer.email", 5.2, 3.3);
    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text("Date: " + "this.momentLocal(this.formDataOrder.time)", 5.2, 3.5);
    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text(
    //     //     "Total : " + 'this.currencyFormat(this.formDataOrder.amount)',
    //     //     5.2,
    //     //     3.7
    //     //   );
    //     // this.docLPN
    //     //   .setFontSize(12)
    //     //   .text(
    //     //     "Booking No: ",
    //     //     5.2,
    //     //     3.9
    //     //   );
    //     var qrCanvas = document.getElementById(`fieldNoQR-${data[j].fieldNo}`);
    //     this.docLPN.setFontSize(12).text("QR Code", 0.7, 4.5);
    //     if (qrCanvas) {
    //       this.docLPN.addImage(
    //         qrCanvas.toDataURL(),
    //         "JPEG",
    //         0.7,
    //         4.7,
    //         0.75,
    //         0.75
    //       );
    //     }

    //     this.docLPN
    //       .setFontSize(12)
    //       .text("Number: " + data[j].fieldNo, 4.0, 4.5);
    //     if (j < data.length - 1) {
    //       this.docLPN.addPage();
    //     }

    //     const pageCount = this.docLPN.internal.getNumberOfPages();
    //     for (var z = 1; z <= pageCount; z++) {
    //       this.docLPN.setPage(z);
    //       // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

    //       const pageSize = this.docLPN.internal.pageSize;
    //       const pageWidth = pageSize.width
    //         ? pageSize.width
    //         : pageSize.getWidth();
    //       const pageHeight = pageSize.height
    //         ? pageSize.height
    //         : pageSize.getHeight();
    //       // const header = 'Report 2014';
    //       const footer = `Page ${z} of ${pageCount}`;
    //       this.docLPN.setFontSize(10);
    //       this.docLPN.text(
    //         footer,
    //         pageWidth / 2 - this.docLPN.getTextWidth(footer) / 2,
    //         pageHeight - 0.2,
    //         { baseline: "bottom" }
    //       );
    //     }
    //   }
    // },

    onPrintLPN() {
      this.setReportLPN().then((res) => {
        this.docLPN.autoPrint();
        this.docLPN.output("dataurlnewwindow");
      });
    },

    generateReportLPN() {
      this.setReportLPN().then((res) => {
        var generateDate = new Date().toLocaleString();
        this.docLPN.save(`${"QRCode" + "_" + generateDate}.pdf`);
      });
    },

    reportLPN(input) {
      const qrCanvas = document.getElementById(`fieldNoQR-${input.fieldNo}`);
      this.pdfLPN = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "a4",
      });

      var imgData = "/src/assets/images/pl_logo2.png";
      this.pdfLPN.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
      // this.pdfLPN
      //   .setFontSize(16)
      //   .text("Order Number : ", 5, 1.2);

      this.pdfLPN.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

      this.pdfLPN.setFontSize(14).text("Deliver To :", 0.5, 2.5);

      this.pdfLPN.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
      this.pdfLPN.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
      this.pdfLPN.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
      this.pdfLPN.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
      this.pdfLPN.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
      this.pdfLPN.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
      this.pdfLPN.setFontSize(12).text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

      //trading partner
      this.pdfLPN.setFontSize(14).text("Trading Partner :", 5, 2.5);

      // this.docLPN
      //   .setFontSize(12)
      //   .text(

      //       " fname" ,
      //     5.2,
      //     2.7
      //   );
      // this.docLPN
      //   .setFontSize(12)
      //   .text("this.formDataOrder.customer.company", 5.2, 2.9);
      // this.docLPN
      //   .setFontSize(12)
      //   .text("this.formDataOrder.customer.mobile", 5.2, 3.1);
      // this.docLPN
      //   .setFontSize(12)
      //   .text("this.formDataOrder.customer.email", 5.2, 3.3);
      // this.docLPN
      //   .setFontSize(12)
      //   .text("Date: " + "this.momentLocal(this.formDataOrder.time)", 5.2, 3.5);
      // this.docLPN
      //   .setFontSize(12)
      //   .text(
      //     "Total : " + 'this.currencyFormat(this.formDataOrder.amount)',
      //     5.2,
      //     3.7
      //   );
      // this.docLPN
      //   .setFontSize(12)
      //   .text(
      //     "Booking No: ",
      //     5.2,
      //     3.9
      //   );

      this.pdfLPN.setFontSize(12).text("QR Code", 0.7, 4.5);
      if (qrCanvas) {
        this.pdfLPN.addImage(qrCanvas.toDataURL(), "JPEG", 0.7, 4.7, 0.75, 0.75);
      }

      this.pdfLPN.setFontSize(12).text("Number: " + input.fieldNo, 4.0, 4.5);

      const pageCount = this.pdfLPN.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        this.pdfLPN.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = this.pdfLPN.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;
        this.pdfLPN.setFontSize(10);
        this.pdfLPN.text(footer, pageWidth / 2 - this.pdfLPN.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
      }
    },

    printLPN(input) {
      this.reportLPN(input);
      this.pdfLPN.autoPrint();
      this.pdfLPN.output("dataurlnewwindow");
    },

    generateLPN(input) {
      this.reportLPN(input);
      var generateDate = new Date().toLocaleString();
      this.pdfLPN.save(`${"Number" + input.fieldNo + "_" + generateDate}.pdf`);
    },

    // searchOnchange() {
    //   if (this.searchData != "") {
    //     this.searchActive = true;
    //     this.searchItem();
    //   } else {
    //     this.clearSearch();
    //   }
    // },
    // clearSearch() {
    //   this.searchData = "";
    //   this.pagination.search = this.searchData;
    //   this.getDataFromApi();
    //   this.searchActive = false;
    // },
    // searchItem() {
    //   if (this.searchData != "") {
    //     this.searchActive = true;
    //     this.pagination.search = this.searchData;
    //     this.getDataFromApi();
    //   }
    // },

    // searchOnchangeArchive() {
    //   if (this.searchDataArchive != "") {
    //     this.searchActiveArchive = true;
    //     this.searchItemArchive();
    //   } else {
    //     this.clearSearchArchive();
    //   }
    // },
    // clearSearchArchive() {
    //   this.searchDataArchive = "";
    //   this.paginationArchive.search = this.searchDataArchive;
    //   this.viewArchives();
    //   this.searchActiveArchive = false;
    // },
    // searchItemArchive() {
    //   if (this.searchDataArchive != "") {
    //     this.searchActiveArchive = true;
    //     this.paginationArchive.search = this.searchDataArchive;
    //     this.viewArchives();
    //   }
    // },

    // loadMoreData() {
    //   this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
    //   this.getDataFromApi();
    // },
    // pageSizeChange() {
    //   this.pagination.pageSize = this.pageSize;
    //   this.getDataFromApi();
    // },
    // changePage(pageNum) {
    //   this.pagination.pageNumber = pageNum;
    //   this.pagination.pageSize = this.pageSize;
    //   this.getDataFromApi();
    // },
    // setDefault() {
    //   this.formDataMovement = Object.assign({}, this.formDataMovementDefault);

    // },
    // getDataFromApi() {
    //   this.isLoading = true;
    //   axios
    //     .post("Warehouses/Paginated", this.pagination)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         this.itemsData = response.data.data;
    //         this.items = response.data.data.items;
    //         this.pageCount = this.itemsData.totalPages;
    //         this.isLoading = false;
    //       } else {
    //         warningToast(response.data.message);
    //         this.isLoading = false;
    //       }
    //     })
    //     .catch((error) => {
    //       localStorage.setItem("errorMessage", error);
    //       router.push("/page-down");
    //     });
    // },
    showLPNModal() {
      this.setGenDefault();
      this.titleLPNChange = -1;
      // this.getWarehouseData();
      this.getDataFromLPNS();
      this.addLPN = true;
    },
    closeLPNForm() {
      this.addLPN = false;
      this.setGenDefault();
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    saveLPNForm() {
      // if (this.titleChange === -1) {

      var lpnsArray = [];
      for (var i = 0; i < this.formDataLPNGen.lpns.length; i++) {
        var lpnNo = this.formDataLPNGen.lpns[i].fieldNo;
        lpnsArray.push(lpnNo);
      }

      var formDataLPNGen = {};
      formDataLPNGen.warehouse = this.warehouseAbbreviation;
      formDataLPNGen.lpns = lpnsArray;

      this.isLoading = true;

      axios
        .post("Lpns/Create", formDataLPNGen)
        .then((response) => {
          if (response.data.success === true) {
            this.addLPN = false;
            this.getDataFromApi();
            successToast(response.data.message);
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
      // }
      // else {
      // var itemId = this.formDataMovement.warehouseId;
      // this.isLoading = true;
      // axios
      //   .put("Warehouses/" + itemId, this.formDataMovement)
      //   .then((response) => {
      //     if (response.data.success === true) {
      //       this.addMovement = false;
      //       this.getDataFromApi();
      //       this.apiMessage = response.data.message;
      //       successToast(this.apiMessage);
      //     } else {
      //       this.isLoading = false;
      //       failedToast(response.data.message);
      //     }
      //   })
      //   .catch((error) => {
      //     this.isLoading = false;
      //     warningToast(statusMessage(error.response.status));
      //   });
      // }
    },

    //
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      // this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },

    setGenDefault() {
      this.formDataLPNGen = Object.assign({}, this.formDataLPNGenDefault);
      this.removeCountField("", this.formDataLPNGen.lpns, this.count);
    },

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(`Lpns/${this.warehouseAbbreviation}/Paginated`, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          //  this.isLoading = false;
          //     warningToast(statusMessage(error.response.status));

          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
