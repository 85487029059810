<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Picker" />

        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport" />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
        <div class="hidden md:block mx-auto text-slate-500"></div>
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>

            <td class="table-report__action w-28">
              <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />

                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />
    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addPickerModal" @hidden="addPickerModal = false" backdrop="static" size="modal-lg" :title="formTitle" @close="addPickerModal = false">
    <ModalBody class="grid grid-cols-2 lg:gap-4 md:gap-4 gap-2">
      <div class="relative col-span-3 pt-2">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Picker's Detail</h3>
        <div class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
          <div>
            <!-- <button @click="printData()">Click</button> -->
            <label for="regular-form-1" class="form-label">First Name</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.firstName" type="text" class="form-control" name="firstName" placeholder="First Name" autocomplete="off" />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Last Name</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.lastName" type="text" class="form-control" name="lastName" placeholder="Last Name" autocomplete="off" />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Email</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validateEmail(formDataUser.email) == true && formDataUser.email != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1"
                v-model="formDataUser.email"
                type="text"
                class="form-control"
                name="email"
                placeholder="Email"
                autocomplete="off"
              />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">Phone Number</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validatePhoneNo(formDataUser.phoneNumber) == true && formDataUser.phoneNumber != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1"
                v-model="formDataUser.phoneNumber"
                type="text"
                class="form-control"
                name="phoneNumber"
                placeholder="Phone Number"
                autocomplete="off"
              />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">User Role</label>
            <div class="flex flex-col space-y-1">
              <input disabled id="vertical-form-1" v-model="formDataUser.role" type="text" class="form-control" name="phoneNumber" placeholder="User Role" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>
      <!-- 
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Last Name</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.lastName"
            type="text"
            class="form-control"
            name="lastName"
            placeholder="Last Name"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Email</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.email"
            type="text"
            class="form-control"
            name="email"
            placeholder="Email"
            autocomplete="off"
          />
        </div>
      </div> -->
      <div class="w-full">
        <label for="regular-form-1" class="form-label">User Name</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataUser.username" type="text" class="form-control" name="username" placeholder="User Name" autocomplete="off" />
        </div>
      </div>
      <div class="w-full" v-if="titleChange == -1">
        <label for="regular-form-1" class="form-label">Password</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataUser.password" type="password" class="form-control" name="password" placeholder="password Name" autocomplete="off" />
        </div>
      </div>
      <div class="w-full" v-if="titleChange == -1">
        <label for="regular-form-1" class="form-label">Confirm Password</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.conformPassword"
            type="password"
            class="form-control"
            name="conformPassword"
            placeholder="Confirm Password"
            autocomplete="off"
          />
        </div>
      </div>
      <!-- <div class="w-full">
        <label for="regular-form-1" class="form-label">Phone Number</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.phoneNumber"
            type="text"
            class="form-control"
            name="phoneNumber"
            placeholder="Phone Number"
            autocomplete="off"
          />
        </div>
      </div> -->

      <!-- <div class="w-full">
        <label for="regular-form-1" class="form-label">User Role</label>
        <div class="flex flex-col space-y-1">
          <input
            disabled
            id="vertical-form-1"
            v-model="formDataUser.role"
            type="text"
            class="form-control"
            name="phoneNumber"
            placeholder="User Role"
            autocomplete="off"
          />
        </div>
      </div> -->
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Warehouse</label>

        <v-select
          v-model="formDataUser.warehouseId"
          placeholder="SELECT"
          class="style-chooser"
          :filterable="false"
          @open="onOpenWarehouse"
          @close="onCloseWarehouse"
          @search="searchWarehouse"
          :key="abbreviation"
          :get-option-label="(option) => option.abbreviation"
          :options="warehousesList"
          :reduce="(abbreviation) => abbreviation.warehouseId"
          label="abbreviation"
        >
          <template #list-footer>
            <li v-show="hasNextPageWarehouse" ref="loadWarehouse" class="loader">Loading more...</li>
          </template>
        </v-select>

        <!-- <v-select
          v-if="storeUserRole != 'SuperUser'"
          v-model="formDataUser.warehouseId"
          placeholder="SELECT"
          class="style-chooser"
          :filterable="false"
          @open="onOpenUsersWarehouse"
          @close="onCloseUsersWarehouse"
          @search="
            (query) => {
              onUserWarehouseSearch(query);
            }
          "
          :options="paginatedUserWarehouse"
          :reduce="(abbreviation) => abbreviation.warehouseId"
          label="abbreviation"
        >
          <template #list-footer>
            <li
              v-show="hasNextPageUsersWarehouse"
              ref="loadUsersWarehouse"
              class="loader"
            >
              Loading more...
            </li>
          </template>
        </v-select> -->
      </div>
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Block Type</label>
        <v-select v-model="formDataUser.blockTypeId" placeholder="SELECT" class="style-chooser" :options="blockTypes" :reduce="(name) => name.blockTypeId" label="name" />

        <!-- <TomSelect v-model="formDataUser.blockTypeId" class="w-full">
          <option
            :value="formDataUser.blockTypeId"
            v-if="titleChange != -1 && formDataUser.blockTypeName != ''"
          >
            {{ formDataUser.blockTypeName }}
          </option>

          <option>SELECT</option>

          <option
            :value="item.blockTypeId"
            v-for="(item, i) in blockTypes"
            :key="i"
          >
            {{ item.name }}
          </option>
        </TomSelect> -->
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ const_txt.SAVE_BTN }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Archive Modal -->
  <!-- not used -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="text-danger float-right lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6 cursor-pointer" @click="archiveModal = false" />
      </div>

      <div class="p-5 text-center lg:text-lg text-base uppercase font-semibold">Archived user</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            autocomplete="off"
            clearable
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <div
        class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
        style="background-color: #164e63"
        v-if="this.archiveData.totalCount > 0"
      >
        <div class="w-full sm:w-auto sm:mr-auto" style="background-color: #e1e7ef; border: 2px solid #164e63; border-radius: 10px">
          <paginate
            class="text-white"
            :page-count="pageCountArchive"
            :page-range="5"
            :margin-pages="2"
            :click-handler="changePageArchive"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :first-last-button="true"
            :initial-page="1"
            :prev-text="'<'"
            :next-text="'>'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
          >
          </paginate>
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select v-model="pageSize" class="w-20 form-select box cursor-pointer" @change="pageSizeChange">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataUser.userName" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataUser.userName" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataUser.userName" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import validationMixins from "../../../mixins/validation";
//import { getWarehouses } from "../../../global-functions/functions";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile, removeNullValue } from "../../../global-functions/functions";

import store from "../../../store";
export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Pickers");
    this.observerWarehouse = new IntersectionObserver(this.infiniteScrollWarehouse);
    this.observerUsersWarehouse = new IntersectionObserver(this.infiniteScrollUsersWarehouse);
  },
  components: {
    Loading,
    paginate: Paginate,
  },
  data() {
    return {
      warehousesList: [
        {
          id: 0,
          abbreviation: store.state.authUser.warehouse.abbreviation,
          warehouseId: store.state.authUser.warehouse.warehouseId,
        },
      ],
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      storeUserRole: store.state.authUser.userRole,
      const_txt: const_txt,
      warehouses: [],
      blockTypes: [],
      userRoles: [],

      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addPickerModal: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataUser: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        conformPassword: "",
        phoneNumber: "",
        role: "Pickers",
        blockTypeId: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },
      formDataUserDefault: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        conformPassword: "",
        phoneNumber: "",
        role: "Pickers",
        blockTypeId: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },

      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Username", value: "userName" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "User Role", value: "role" },
        { text: "Block Type", value: "blockTypeName" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      policyAccess: "Pickers",

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,

      warehousesearch: "",
      observerUsersWarehouse: null,
      limitUsersWarehouse: 5,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Picker" : "Edit Picker";
    },
    checkLimtWarehouse() {
      return this.whPageNumber;
    },
    viewModalWh() {
      return this.formDataUser.warehouseId;
    },
    getUsersWarehouses() {
      this.isLoading = true;
      let storedArrayData = store.state.authUser.licensee.warehouses;
      if (storedArrayData.length > 0) {
        this.isLoading = false;
        this.warehousesList = storedArrayData;
        return this.warehousesList.filter((x) => x.abbreviation.toLowerCase().includes(this.warehousesearch.toLowerCase()));
      } else {
        this.isLoading = false;
        warningToast("There are no Warehouses found");
      }
    },
    paginatedUserWarehouse() {
      return this.getUsersWarehouses.slice(0, this.limitUsersWarehouse);
    },
    hasNextPageUsersWarehouse() {
      return this.paginatedUserWarehouse.length < this.getUsersWarehouses.length;
    },
  },
  watch: {
    viewModalWh() {
      if (this.formDataUser.warehouseId === null) {
        this.clearWarehouse();
      }
    },
    checkLimtWarehouse() {
      this.getWarehouses();
    },
  },
  created() {
    this.getDataFromApi();
    //this.getUserRoles();
    this.getWarehouses();
    this.getBlockType();
  },
  methods: {
    onUserWarehouseSearch(query) {
      this.warehousesearch = query;
    },

    async onOpenUsersWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.hasNextPageUsersWarehouse) {
        await this.$nextTick();
        this.observerUsersWarehouse.observe(this.$refs.loadUsersWarehouse);
      }
    },
    onCloseUsersWarehouse() {
      this.observerUsersWarehouse.disconnect();
      this.limitUsersWarehouse = 5;
    },
    async infiniteScrollUsersWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitUsersWarehouse = this.limitUsersWarehouse + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    // async getWarehouses() {
    //   console.log("Data",  getWarehouses());
    // },
    clearWarehouse() {
      this.warehouses = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehouses = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },
    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehousesList =
            this.isWhSearchResult !== true
              ? Array.from(new Set([...this.warehousesList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    getBlockType() {
      axios
        .get("BlockTypes/" + this.warehouseStoreId)
        .then((response) => {
          this.blockTypes = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    printData() {},

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Pickers/Paginated", this.pagination)

        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.status);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getUserRoles() {
      // this.userRoles = [
      //   { role: "Admin" },
      //   { role: "Supervisor" },
      //   { role: "Sales Rep" },
      //   { role: "User" },
      // ];
      this.isLoading = true;
      axios
        .get("Roles")
        .then((response) => {
          if (response.data.success === true) {
            this.userRoles = response.data.data;
          } else {
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    setReports() {
      if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        const columns = [
          { title: "First Name", dataKey: "firstName" },
          { title: "Last Name", dataKey: "lastName" },
          { title: "Email", dataKey: "email" },
          { title: "Username", dataKey: "userName" },
          { title: "Phone Number", dataKey: "phoneNumber" },
          { title: "User Role", dataKey: "role" },
          { title: "Block Type", dataKey: "blockTypeName" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email,
            userName: el.userName,
            phoneNumber: el.phoneNumber,
            role: el.role,
            blockTypeName: el.blockTypeName,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
      }
    },

    printCurrentPage() {
      // this.getAllDataFromApi();
      // this.isActive = 1;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Pickers " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },
    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Pickers " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "First Name",
          2: "Last Name",
          3: "Email",
          4: "Username",
          5: "Phone Number",
          6: "User Role",
          7: "Block Type",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.firstName,
            2: item.lastName,
            3: item.email,
            4: item.userName,
            5: item.phoneNumber,
            6: item.role,
            7: item.blockTypeName,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },
    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Pickers/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;

      this.getDataFromApi();
    },
    setDefault() {
      this.formDataUser = Object.assign({}, this.formDataUserDefault);
    },

    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addPickerModal = true;
      this.activateModal();
    },
    activateModal() {
      this.addPickerModal = true;
      if (this.storeUserRole == "SuperUser") {
        this.getWarehouses();
      } else {
        this.getUsersWarehouses;
      }
    },
    closeForm() {
      this.addPickerModal = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataUser = Object.assign({}, item);
      this.activateModal();
    },

    saveForm() {
      console.log(this.formDataUser);

      if (this.titleChange === -1) {
        if (
          this.formDataUser.username == "" ||
          this.formDataUser.password == "" ||
          this.formDataUser.conformPassword == "" ||
          this.formDataUser.warehouseId == "" ||
          this.formDataUser.blockTypeId == "" ||
          this.formDataUser.password != this.formDataUser.conformPassword
        ) {
          if (this.formDataUser.username == "") {
            warningToast("user Name Required");
          }
          if (this.formDataUser.warehouseId == "" || this.formDataAddSku.warehouseId == null) {
            warningToast("Warehouse Required");
          }
          if (this.formDataUser.blockTypeId == "" || this.formDataAddSku.blockTypeId == null) {
            warningToast("Block Type Required");
          }
          if (this.formDataUser.password == "") {
            warningToast("Password Required");
          }
          if (this.formDataUser.conformPassword == "") {
            warningToast("Confirm Password Required");
          }
          if (this.formDataUser.password != "" && this.formDataUser.conformPassword != "" && this.formDataUser.password != this.formDataUser.conformPassword) {
            warningToast("Password Mismatch");
          }
        } else {
          this.isLoading = true;
          axios
            .post("Pickers", this.formDataUser)
            .then((response) => {
              if (response.data.success === true) {
                this.addPickerModal = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      } else {
        if (this.formDataUser.username == "" || this.formDataUser.warehouseId == "" || this.formDataUser.blockTypeId == "") {
          if (this.formDataUser.username == "") {
            warningToast("user Name Required");
          }
          if (this.formDataUser.warehouseId == "" || this.formDataAddSku.warehouseId == null) {
            warningToast("Warehouse Required");
          }
          if (this.formDataUser.blockTypeId == "" || this.formDataAddSku.blockTypeId == null) {
            warningToast("Block Type Required");
          }
        } else {
          var itemId = this.formDataUser.userId;
          this.isLoading = true;
          axios
            .put("Users/" + itemId, this.formDataUser)
            .then((response) => {
              if (response.data.success === true) {
                this.addPickerModal = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataUser = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataUser.userId;
      this.isLoading = true;
      axios
        .get("Pickers/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Pickers/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }

          this.archiveModal = true;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataUser = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataUser.userId;
      this.isLoading = true;
      axios
        .get("Pickers/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      removeNullValue(item);
      this.formDataUser = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataUser.id;
      axios
        .delete("Pickers/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            successToast(response.data.message);
            this.getDataFromApi();
          } else {
            this.isLoading = false;
            this.deleteConfirmationModal = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
