<template>
  <LoadingScreen :isLoading="isLoading" />
  <MoveableModal
    :show="addWarehouseInfoModal"
    @hidden="addWarehouseInfoModal = false"
    backdrop="static"
    size="modal-sl-60"
    :title="'Add Warehouse'"
    @close="closeAddWarehouse()"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4">
        <div class="intro-y col-span-12 overflow-auto p-4">
          <div
            class="flex items-center justify-between w-full space-x-4"
            v-if="addwarehousemodal == false"
          >
            <button
              @click="addwarehousemodal = true"
              class="btn btn-primary h-10"
            >
              <BuildingIcon
                class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-white mr-2"
              />
              <span class="text-white uppercase">Add Warehouse</span>
            </button>
          </div>

          <div
            class="grid grid-cols-4 lg:gap-4 md:gap-4 gap-2 p-2"
            v-if="addwarehousemodal == true"
          >
            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Warehouse</label>

              <div class="flex flex-col space-y-1">
                <v-select
                  v-model="formDataWarehouses.warehouseId"
                  placeholder="SELECT"
                  @search="searchWarehouse"
                  :key="abbreviation"
                  :get-option-label="(option) => option.abbreviation"
                  :options="warehouses"
                  :reduce="(abbreviation) => abbreviation.warehouseId"
                  label="abbreviation"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPageWarehouse"
                      ref="loadWarehouse"
                      class="loader"
                    >
                      Loading more...
                    </li>
                  </template>
                </v-select>
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Default</label>

              <div class="flex flex-col space-y-1">
                <div class="form-switch">
                  <input
                    id="booleanSwitch"
                    type="checkbox"
                    v-model="formDataWarehouses.isDefault"
                    class="form-check-input"
                  />
                </div>
              </div>
            </div>

            <div class="flex justify-end items-end col-span-4">
              <button
                type="button"
                @click="addwarehousemodal = false"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button
                @click="saveWarehouses()"
                type="submit"
                class="btn btn-primary mr-1 px-8"
              >
                Save
              </button>
            </div>
          </div>

          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th>Warehouse Name</th>
                <th>Abbr</th>
                <th>Description</th>

                <!-- <th>Address</th>
              <th>Date</th>
              <th>Time</th>
              <th>Booking#</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in warehousesLocal" :key="i" class="intro-x">
                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  <div :class="item.isDefault == true ? '' : ''">
                    <span
                      v-if="item.isDefault == true"
                      class="font-black text-xl text-center textStroke"
                      >DFLT</span
                    >
                  </div>
                  <div :class="item.isDefault == true ? 'hidden' : ''">
                    {{ i + 1 }}
                  </div>
                </td>

                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  {{ item.name ? item.name : "" }}
                </td>
                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  {{ item.abbreviation ? item.abbreviation : "" }}
                </td>
                <td :style="item.isDefault == true ? getSelectedColor() : ''">
                  {{ item.description ? item.description : "" }}
                </td>

                <td class="table-report__action w-20">
                  <div class="flex justify-center items-center">
                    <a
                      @click="defaultWarehouseItem(item)"
                      class="flex items-center mr-3"
                      href="javascript:;"
                      v-if="item.isDefault == false"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Set as Default Warehouse"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <AlbumIcon class="w-6 h-6 mr-1 text-primary" />
                      </Tippy>
                    </a>

                    <a
                      class="flex items-center mr-3"
                      href="javascript:;"
                      v-if="item.isDefault == true"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Default Warehouse"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <AlbumIcon class="w-6 h-6 mr-1 text-success" />
                      </Tippy>
                    </a>

                    <DeleteItem
                      :activeAccess="$h.deleteItemAccess(policyAccess)"
                      @parentMethod="deleteWarehouseItem($event)"
                      :item="item"
                      name="Delete"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <NoDataCus :items="warhousesList" />

          <PaginationBarLocal
            @changePage="changePageWarehouse($event)"
            :itemsData="itemWarehouseData"
            :pageCount="pageCountWarehouse"
            :pageRange="pageRowCountWarehouse"
            :pageSize="pageSizeProduct"
          />
        </div>

        <!-- END: Pagination -->
      </div>

      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";
import { const_txt } from "../../../global-functions/const";
import store from "../../../store";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Licensee");
    this.observerWarehouse = new IntersectionObserver(
      this.infiniteScrollWarehouse
    );
  },
  components: {
    Loading,
    paginate: Paginate,
  },
  data() {
    return {
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      addWarehouse: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      deleteWarehouseConfirmationModal: false,
      deleteUserConfirmationModal: false,
      addwarehousemodal: false,
      addusermodal: false,
      addUserInfoModal: false,
      defaultWarehouseConfirmationModal: false,
      titleChange: -1,
      formDataLicensee: {
        companyName: "",
        abn: "",
        acn: "",
        address: "",
        deliveryAddress: "",
        isDifferentDeliveryAddress: false,
        phoneNumber: "",
        website: "",
        email: "",
        attn: "",
        mobile: "",
        licenceeCode: "",
        bsb: "",
        ac: "",
        bankName: "",
        logo: "",
      },
      formDataLicenseeDefault: {
        companyName: "",
        abn: "",
        acn: "",
        address: "",
        deliveryAddress: "",
        isDifferentDeliveryAddress: false,
        phoneNumber: "",
        website: "",
        email: "",
        attn: "",
        mobile: "",
        licenceeCode: "",
        bsb: "",
        ac: "",
        bankName: "",
        logo: "",
      },
      formDataWarehouses: {
        warehouseId: "",
        isDefault: false,
      },
      formDataWarehousesDefault: {
        warehouseId: undefined,
        isDefault: false,
      },
      formDataUsers: {
        userId: "",
      },
      formDataUsersDefault: {
        userId: "",
      },
      headers: [
        { text: "Company Name", value: "companyName" },
        { text: "address", value: "address" },
        { text: "Phone No", value: "phoneNumber" },
        { text: "mobile No", value: "mobile" },
        { text: "website", value: "website" },
        { text: "email", value: "email" },
        { text: "licencee Code", value: "licenceeCode" },
        { text: "bankName", value: "bankName" },
        { text: "abn", value: "abn" },
        { text: "TIN", value: "acn" },
        { text: "attn", value: "attn" },
        { text: "bsb", value: "bsb" },
        { text: "ac", value: "ac" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },
      addWarehouseInfoModal: true,
      pageCountArchive: 20,
      pageSizeArchive: 5,

      csvHeaders: {
        warehouseId: "warehouseId",
        abbreviation: "abbreviation",
        name: "name",
        description: "description",
      },

      allItems: [],
      allItemsData: [],
      isActive: 0,
      doc: null,
      allSelected: false,
      checkedData: [],
      policyAccess: "Licensees",

      warhousesList: [],
      usersList: [],
      warehouses: [],
      users: [],

      itemUserData: [],
      pageCountProduct: 0,
      pageRowCountProduct: 5,
      productStart: 0,
      productEnd: 5,
      usersLocal: [],

      itemWarehouseData: [],
      pageCountWarehouse: 0,
      pageRowCountWarehouse: 5,
      warehouseStart: 0,
      warehouseEnd: 5,
      warehousesLocal: [],

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Licensee" : "Edit Licensee";
    },
    checkLimtWarehouse() {
      return this.whPageNumber;
    },
    viewModalWh() {
      return this.formDataWarehouses.warehouseId;
    },
  },
  watch: {
    checkLimtWarehouse() {
      this.getWarehouses();
    },
    viewModalWh() {
      if (this.formDataWarehouses.warehouseId === null) {
        this.clearWarehouse();
      }
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    async onOpenWarehouse() {
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    defaultWarehouseItem(item) {
      this.formDataWarehouses = Object.assign({}, item);
      this.defaultWarehouseConfirmationModal = true;
    },
    closeWarehouseDefault() {
      this.defaultWarehouseConfirmationModal = false;
    },
    defaultItemWarehouseConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
      var formData = {
        warehouseId: this.formDataWarehouses.warehouseId,
      };
      axios
        .put("Licensees/" + itemId + "/ChangeDefaultWarehouse", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.defaultWarehouseConfirmationModal = false;
            this.getWarehousesList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    changePageWarehouse(pageNum) {
      // this.pageNumber = pageNum;
      this.warehouseEnd = this.pageRowCountWarehouse * pageNum;
      this.warehouseStart = this.warehouseEnd - this.pageRowCountWarehouse;
      this.warehousesLocal = this.warhousesList.slice(
        this.warehouseStart,
        this.warehouseEnd
      );
    },
    changePageProduct(pageNum) {
      // this.pageNumber = pageNum;
      this.productEnd = this.pageRowCountProduct * pageNum;
      this.productStart = this.productEnd - this.pageRowCountProduct;
      this.usersLocal = this.usersList.slice(
        this.productStart,
        this.productEnd
      );
    },
    closeAddWarehouse() {
      this.addWarehouseInfoModal = false;
      this.addwarehousemodal = false;
      this.setWarehouseDefault();
    },
    closeAddUser() {
      this.addUserInfoModal = false;
      this.addusermodal = false;
      this.setUserDefault();
    },
    viewWarehouseItemInfo(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.addWarehouseInfoModal = true;
      this.getWarehousesList();
      this.isLoading = true;
      this.getWarehouses();
    },

    viewUserItemInfo(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.addUserInfoModal = true;
      this.getusersList();
      this.isLoading = true;
      this.getUsers();
    },
    getWarehousesList() {
      this.isLoading = true;
      var licenseeId = this.formDataLicensee.licenseeId;
      axios
        .get("Licensees/" + licenseeId + "/Warehouses")
        .then((response) => {
          if (response.data.success === true) {
            this.itemWarehouseData = response.data;
            this.warhousesList = response.data.data;
            this.isLoading = false;
            this.setLocalWarehousePagination();
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setLocalWarehousePagination() {
      if (this.itemWarehouseData) {
        this.itemWarehouseData.totalCount = this.warhousesList.length;
      }

      var pageCount = this.warhousesList.length / this.pageRowCountWarehouse;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountWarehouse = pageCountRound;
      } else {
        this.pageCountWarehouse = pageCountRound;
      }
      if (this.pageRowCountWarehouse > this.itemWarehouseData.totalCount) {
        this.pageSizeProduct = this.itemWarehouseData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountWarehouse;
      }

      //pageCountWarehouse
      this.warehousesLocal = this.warhousesList.slice(
        this.warehouseStart,
        this.warehouseEnd
      );
    },
    getusersList() {
      this.isLoading = true;
      var licenseeId = this.formDataLicensee.licenseeId;
      axios
        .get("Licensees/" + licenseeId + "/Users")
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.itemUserData = response.data;
            this.usersList = response.data.data;
            this.isLoading = false;
            this.setLocalPagination();
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setLocalPagination() {
      if (this.itemUserData) {
        this.itemUserData.totalCount = this.usersList.length;
      }

      var pageCount = this.usersList.length / this.pageRowCountProduct;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountProduct = pageCountRound;
      } else {
        this.pageCountProduct = pageCountRound;
      }
      if (this.pageRowCountProduct > this.itemUserData.totalCount) {
        this.pageSizeProduct = this.itemUserData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountProduct;
      }

      //pageCountProduct
      this.usersLocal = this.usersList.slice(
        this.productStart,
        this.productEnd
      );
    },

    clearWarehouse() {
      this.warehouses = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehouses = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },

    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          this.isWhSearchResult = !!search;
          this.warehouses =
            this.isWhSearchResult !== true
              ? [...this.warehouses, ...response.data.data.items]
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    getUsers(search) {
      this.isLoading = true;
      var pagination = { pageNumber: 1, pageSize: 5, search: search };
      axios
        .post("Users/Paginated", pagination)
        .then((response) => {
          this.users = response.data.data.items;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    saveWarehouses() {
      if (
        this.formDataWarehouses.warehouseId == "" ||
        this.formDataWarehouses.warehouseId == null
      ) {
        if (this.formDataWarehouses.warehouseId == "") {
          warningToast("warehouse Required");
        }
      } else {
        this.isLoading = true;
        var licenseeId = this.formDataLicensee.licenseeId;
        var formData = {
          warehouseId: this.formDataWarehouses.warehouseId,
          isDefault: this.formDataWarehouses.isDefault,
        };
        axios
          .post("Licensees/" + licenseeId + "/Warehouses", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.addwarehousemodal = false;
              this.getWarehousesList();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
      }
    },

    saveUsers() {
      if (
        this.formDataUsers.userId == "" ||
        this.formDataUsers.userId == null
      ) {
        if (this.formDataUsers.userId == "") {
          warningToast("User Required");
        }
      } else {
        this.isLoading = true;
        var licenseeId = this.formDataLicensee.licenseeId;
        var formData = {
          userId: this.formDataUsers.userId,
        };
        axios
          .post("Licensees/" + licenseeId + "/Users", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.addusermodal = false;
              this.getusersList();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
      }
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Company Name", dataKey: "companyName" },
          { title: "Address", dataKey: "address" },
          { title: "Phone No", dataKey: "phoneNumber" },
          { title: "Mobile No", dataKey: "mobile" },
          { title: "Website", dataKey: "website" },
          { title: "Email", dataKey: "email" },
          { title: "Licencee Code", dataKey: "licenceeCode" },
          { title: "BankName", dataKey: "bankName" },
          { title: "ABN", dataKey: "abn" },
          { title: "TIN", dataKey: "acn" },
          { title: "ATTN", dataKey: "attn" },
          { title: "BSB", dataKey: "bsb" },
          { title: "AC", dataKey: "ac" },
        ];
        this.doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            companyName: el.companyName,
            address: el.address,
            phoneNumber: el.phoneNumber,
            mobile: el.mobile,
            website: el.website,
            email: el.email,
            licenceeCode: el.licenceeCode,
            bankName: el.bankName,
            abn: el.abn,
            acn: el.acn,
            attn: el.attn,
            bsb: el.bsb,
            ac: el.ac,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.doc
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.doc, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
      }
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.doc.autoPrint();
      this.doc.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.doc.save(`${"Licensee " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Licensee " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Company Name",
          2: "Phone No",
          3: "Mobile No",
          4: "Website",
          5: "Email",
          6: "Licencee Code",
          7: "BankName",
          8: "ABN",
          9: "TIN",
          10: "ATTN",
          11: "BSB",
          12: "AC",
          13: "Address",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.companyName,
            2: item.phoneNumber,
            3: item.mobile,
            4: item.website,
            5: item.email,
            6: item.licenceeCode,
            7: item.bankName,
            8: item.abn,
            9: item.acn,
            10: item.attn,
            11: item.bsb,
            12: item.ac,
            13: item.address,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Licensees/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataLicensee = Object.assign({}, this.formDataLicenseeDefault);
    },
    setWarehouseDefault() {
      this.formDataWarehouses = Object.assign(
        {},
        this.formDataWarehousesDefault
      );
    },
    setUserDefault() {
      this.formDataUsers = Object.assign({}, this.formDataUsersDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Licensees/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView =
                this.itemsData.totalCount -
                (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addWarehouse = true;
    },
    closeForm() {
      this.addWarehouse = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataLicensee = Object.assign({}, item);
      this.addWarehouse = true;
    },

    saveForm() {
      if (
        this.formDataLicensee.name == "" ||
        this.formDataLicensee.abbreviation == ""
      ) {
        if (this.formDataLicensee.name == "") {
          warningToast("Name Required");
        }
        if (this.formDataLicensee.abbreviation == "") {
          warningToast("Abbreviation Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Licensees", this.formDataLicensee)
            .then((response) => {
              if (response.data.success === true) {
                this.addWarehouse = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataLicensee.licenseeId;
          this.isLoading = true;
          axios
            .put("Licensees/" + itemId, this.formDataLicensee)
            .then((response) => {
              if (response.data.success === true) {
                this.addWarehouse = false;
                this.getDataFromApi();
                this.apiMessage = response.data.message;
                successToast(this.apiMessage);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast(statusMessage(error.response.status));
            });
        }
      }
    },

    archiveItem(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },

    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },
    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeWarehouseDelete() {
      this.deleteWarehouseConfirmationModal = false;
      this.setWarehouseDefault();
      this.addwarehousemodal = false;
    },
    closeUserDelete() {
      this.deleteUserConfirmationModal = false;
      this.setUserDefault();
      this.addusermodal = false;
    },
    archiveItemConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
      axios
        .get("Licensees/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Licensees/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataLicensee.licenseeId;
      this.isLoading = true;
      axios
        .get("Licensees/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataLicensee = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteWarehouseItem(item) {
      this.formDataWarehouses = Object.assign({}, item);
      this.deleteWarehouseConfirmationModal = true;
      this.addwarehousemodal = false;
    },
    deleteUserItem(item) {
      this.formDataUsers = Object.assign({}, item);
      this.deleteUserConfirmationModal = true;
      this.addusermodal = false;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      axios
        .delete("Licensees/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteWarehouseItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      var formdata = {
        warehouseId: this.formDataWarehouses.warehouseId,
        isDefault: false,
      };

      console.log("wareid");
      console.log(formdata);
      axios
        .delete("Licensees/" + itemId + "/Warehouses", formdata)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.deleteWarehouseConfirmationModal = false;
            this.getWarehousesList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    deleteWarehouseItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      var formdata = {
        warehouseId: this.formDataWarehouses.warehouseId,
        isDefault: false,
      };
      axios
        .delete("Licensees/" + itemId + "/Warehouses", { data: formdata })
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.deleteWarehouseConfirmationModal = false;
            this.getWarehousesList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteUserItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataLicensee.licenseeId;
      var formdata = {
        userId: this.formDataUsers.userId,
      };

      axios
        .delete("Licensees/" + itemId + "/Users", { data: formdata })
        .then((response) => {
          if (response.data.success === true) {
            this.deleteUserConfirmationModal = false;
            this.getusersList();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    getSelectedColor() {
      return "background-color:rgb(23,79,100);color: white";
    },
  },
};
</script>

<style>
.textBreak {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
</style>
