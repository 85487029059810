<template>
  <a
    v-if="activeAccess"
    @click="buttonClick()"
    class="flex items-center text-blue-900"
    href="javascript:;"
  >
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      :content="name"
      :options="{
        zIndex: 99999,
      }"
    >
      <PlusSquareIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
    /></Tippy>
  </a>
  <a v-else class="flex items-center text-blue-900" href="javascript:;">
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip cursor-not-allowed"
      content="Not Allowed"
      :options="{
        zIndex: 99999,
      }"
    >
      <PlusSquareIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
    /></Tippy>
  </a>
</template>
<script>
export default {
  props: { item: {}, name: "", activeAccess: false },
  name: "AddSubItem",
  methods: {
    buttonClick() {
      this.$emit("parentMethod", this.item);
    },
  },
};
</script>
