<template>
  <Tippy
    v-if="!allSelectedPickType"
    tag="a"
    href="javascript:;"
    class="tooltip w-full"
    content="Select All"
    :options="{
      zIndex: 99999,
    }"
  >
    <a
      @click="selectAllPickType()"
      class="text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0"
    >
      <span class="flex">
        Select All
        <SquareIcon class="lg:w-6 lg:h-6 lg:-mt-1 w-6 h-6 ml-2 text-primary" />
      </span>
    </a>
  </Tippy>
  <Tippy
    v-if="allSelectedPickType"
    tag="a"
    href="javascript:;"
    class="tooltip w-full"
    content="Unselect All"
    :options="{
      zIndex: 99999,
    }"
  >
    <a
      @click="unSelectAllPickType()"
      class="text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0"
    >
      <span class="flex">
        Unselect All
        <CheckSquareIcon
          class="lg:w-6 lg:h-6 lg:-mt-1 w-6 h-6 ml-2 text-primary"
        />
      </span> </a
  ></Tippy>
</template>
<script>
export default {
  name: "SelectLabelsForPickType",
  props: { allSelectedPickType: false },
  methods: {
    selectAllPickType() {
      this.$emit("selectAllPickType");
    },
    unSelectAllPickType() {
      this.$emit("unSelectAllPickType");
    },
  },
};
</script>
