<template>
   
  <div
    class="w-full flex justify-center items-center shadow-2xl py-4 px-2"
    v-show="items.length == 0"
  >
    <span class="p-5 text-center text-lg  ">{{
      const_txt.NO_DATA_TABLE
    }}</span>
  </div>
</template>
<script>
import { const_txt } from "../../global-functions/const";
export default {
  data() {
    return {
      const_txt: const_txt,
    };
  },
  props: { items: {} },
};
</script>
