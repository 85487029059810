<template>
    <ValidationProvider
      v-slot="{ errors, classes }"
      :rules="rules"
      :mode="custom"
      tag="div"
    >
      <v-select
        v-model="dropdownValue"
        v-bind="config"
        class="form-input"
        :class="classes"
        @search:blur="onBlur"
      >
        <!-- <template
          slot="search"
          slot-scope="search"
        >
          <input
          style="width: 100%;"
            class="form-control vs__search--custom"
            :class="{closed: !search.attributes['aria-expanded'] }"
            v-bind="search.attributes"
            v-on="search.events"
          >
        </template> -->
  
        <template  >
          {{ noOptionsText }}
        </template>
      </v-select>
      <span style="color: black;">{{ errors[0] }}</span>
    </ValidationProvider>
  </template>
  
  <script>
  
  export default {
    name: 'SearchableDropdown',
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      value: {
        type: [String, Number, Object],
        default: null
      },
      config: {
        type: Object,
        default() {
          return {
            placeholder: 'Select',
            options: []
          };
        },
        // required: true
      },
      noOptionsText: {
        type: String,
        default: 'No options'
      },
      rules: {
        type: String,
        default: ''
      }
    },
    computed: {
      dropdownValue: {
        get() {
          return this.value;
        },
        set(newDropdownValue) {
          this.$emit('input', newDropdownValue);
          this.$emit('change', newDropdownValue);
        }
      }
    },
    methods: {
      custom() {
        return { on: ['search:blur', 'input'] };
      },
      onBlur() {
        console.log('yes');
      }
    }
  };
  </script>
  
  <style >
  .form-input {
    margin-bottom: 8px;
  }
  </style>