<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class=" " v-if="items.length > 0">
    <h2 class="text-lg font-medium mb-4">{{ getS(roleName) }} Permissions</h2>

    <div class="flex justify-between items-center space-x-4 border-4 rounded-md border-primary p-4">
      <div class="flex justify-start items-start space-x-4">
        <component :is="PermissionAdminIdentifier('All')" class="w-10 h-10"> </component>
        <div class="flex flex-col items-start justify-center font-semibold">
          <span class="">Activate / Deactivate</span>
          <span class=""> All Permissions </span>
        </div>
      </div>
      <div class="form-switch ml-3" @click="openPermission(!_active)">
        <input id="booleanSwitch" type="checkbox" class="form-check-input" v-model="_active" />
      </div>
      <!-- <div
        v-if="$h.editItemAccess(policyAccess)"
        class="form-switch ml-3"
        @click="openPermission(!active)"
      >
        <input
          id="booleanSwitch"
          type="checkbox"
          class="form-check-input"
          v-model="active"
        />
      </div>
      <div v-else class="form-switch ml-3">
        <input
          disabled
          id="booleanSwitch"
          type="checkbox"
          class="form-check-input"
          v-model="active"
        />
      </div> -->
    </div>
  </div>

  <!-- <PermissionConfirmModal
    name="Activate All Permissions"
    :modalStatus="permissionConfirmationModal"
    :modalConfirm="permissionItemConfirm"
    :modalClose="closePermission"
  /> -->

  <ConfirmAlert
    :modalStatus="permissionConfirmationModal"
    :modalConfirm="permissionItemConfirm"
    :isClose="true"
    :modalClose="closePermission"
    :icon="!_active ? 'ShieldCheckIcon' : 'ShieldCloseIcon'"
    :color="!_active ? 'success' : 'danger'"
    :name="!_active ? 'Activate All Permissions' : 'Deactivate All Permissions'"
    :question="!_active ? 'Do you really want to active this permission?' : 'Do you really want to deactive this permission?'"
    nameBtn="Ok"
  />
</template>

<script>
import { successToast, failedToast, warningToast, statusMessage } from "../../global-functions/toast";
import axios from "../../services/http-common";
import { PermissionAdminIdentifier } from "../../global-functions/PermissionIdentifier.js";
export default {
  name: "AllPermission",
  data() {
    return {
      permissionConfirmationModal: false,
      activaBool: false,
      isLoading: false,
      policyAccess: "UserPermissions",
    };
  },
  props: {
    items: [],
    roleId: "",
    roleName: "",
    policyName: "",
    active: false,
  },
  watch: {
    _active(val) {
      console.log("_active", val);
    },
  },
  created() {},
  computed: {
    _active: {
      get() {
        return this.active;
      },
    },
  },
  methods: {
    getS(x) {
      var res = x.charAt(x.length - 1);
      return res == "s" ? x + "'" : x + "'s";
    },
    PermissionAdminIdentifier(value) {
      return PermissionAdminIdentifier(value);
    },
    closePermission() {
      this.permissionConfirmationModal = false;
      this.getDataFromApi(this.roleName, this.policyName);
    },
    openPermission(bool) {
      this.permissionConfirmationModal = true;
      this.activaBool = bool;
    },

    permissionItemConfirm() {
      this.isLoading = true;
      var formData = {};
      var arr = [];
      for (var i = 0; i < this.items.length; i++) {
        var obj = {
          type: this.items[i].type,
          value: this.items[i].value,
          selected: this.activaBool,
        };
        arr.push(obj);
      }

      formData = {
        roleId: this.roleId,
        roleClaims: arr,
      };

      axios
        .put("Roles/" + this.roleName + "/Permissions", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.permissionConfirmationModal = false;
            this.getDataFromApi(this.roleName, this.policyName);
            this.isLoading = false;
            successToast("All Permission changed sucessfully");
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getDataFromApi(roleName, policyName) {
      var data = { roleName, policyName };
      this.$emit("activiteApi", data);
    },
  },
};
</script>
