<template>
  <div
    class="flex justify-between items-center space-x-4 border-primary rounded-md border-2 p-3"
  >
    <div class="flex justify-start items-start space-x-4">
      <component :is="PermissionAdminIdentifier(item.value)" class="w-6 h-6">
      </component>
      <div class="flex flex-col items-start justify-center">
        <span class="font-semibold">{{ getSpace(getName(item.value)) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { PermissionAdminIdentifier } from "../../global-functions/PermissionIdentifier.js";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "@/global-functions/toast";
import axios from "@/services/http-common";

export default {
  name: "PermissionCard",
  data() {
    return {
      policyAccess: "UserPermissions",
    };
  },
  props: {
    vmodel: "",
    value: "",
    item: {},

    policyName: "",
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    getName(value) {
      const index = value.lastIndexOf(".");
      const name = value.slice(index + 1);

      return name;
    },
    getSpace(value) {
      const name = value.replace(/([A-Z])/g, " $1").trim();
      return name;
    },

    PermissionAdminIdentifier(value) {
      return PermissionAdminIdentifier(value);
    },
  },
};
</script>
