<template>
  <MoveableModal
    :show="modalStatus"
    @hidden="modalStatus = false"
    backdrop="static"
    :title="formTitle"
    size="modal-lg"
    @close="modalStatus = false"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full p-3">
        <div>
          <label for="regular-form-1" class="form-label">Name</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formData.name"
              type="text"
              class="form-control w-64"
              placeholder="Storage Type Name"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Abbreviation</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formData.abbreviation"
              type="text"
              class="form-control"
              placeholder="Storage Type Abbreviation"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Warehouse</label>
          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formData.warehouseId"
              placeholder="SELECT"
              @search="
                (search) => {
                  getWarehouses(search);
                }
              "
              :options="warehousesList"
              :reduce="(abbreviation) => abbreviation.warehouseId"
              label="abbreviation"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Description</label>

          <div class="flex flex-col space-y-1">
            <textarea
              id="vertical-form-1"
              v-model="formData.description"
              type="text"
              class="form-control"
              placeholder="Type here about Storage Type..."
            ></textarea>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right space-x-5">
      <button
        type="button"
        @click="closeForm()"
        class="btn btn-outline-secondary w-24 mr-1"
      >
        Cancel
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        Done
      </button>
    </ModalFooter>
  </MoveableModal>
</template>

<script>
import axios from "axios";
import { statusMessage, warningToast } from "@/global-functions/toast";
export default {
  props: {
    formData: {},
    titleStatus: 0,
    modalStatus: false,
    modalConfirm: Function,
    modalClose: Function,
  },
  data() {
    return {
      formTitle: "",
      warehousesList: [],
    };
  },

  watch: {
    modalStatus(val) {
      val || this.closeForm();
    },
  },
  computed: {
    formTitle() {
      return this.titleStatus === -1
        ? "Add New Storage Type"
        : "Edit Storage Type";
    },
  },

  created() {},
  methods: {
    closeForm() {
      return this.modalClose();
    },
    saveForm() {
      return this.modalConfirm();
    },

    getWarehouses(search) {
      this.isLoading = true;
      var pagination = { pageNumber: 1, pageSize: 5, search: search };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.warehousesList = response.data.data.items;
          console.log(this.warehousesList);
          this.isLoading = false;
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
