<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Department" />
          <!-- <div>
            <a
              href="javascript:;"
              class="mr-2 btn btn-primary shadow-md text-xs mt-1 mb-1"
              @click="openUploadModal()"
            >
              Upload File
              <span class="ml-2">
                <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>
          </div> -->
          <!-- <UploadFile
            :activeAccess="$h.addItemAccess(policyAccess)"
            buttonName="File Upload"
            @chooseFiles="chooseFiles($event)"
            @onFilePicked="onFilePicked($event)"
            @downloadTemplate="downloadTemplate($event)"
          />  -->

          <!-- <a
            href="javascript:;"
            @click="chooseFiles()"
            class="btn btn-primary shadow-md lg:w-auto w-1/2 text-xs lg:text-sm mr-2"
          >
            <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 mr-2" />
            Upload File
          </a>
          <input
            id="fileInput"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            ref="fileInput"
            @change="onFilePicked"
            hidden
          /> -->
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="printAllPages"
          />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
        <ViewArchiveItem @parentMethod="viewArchives($event)" />
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto w-full xl:h-[50vh] lg:h-[50vh] md:h-[50vh] h-[50vh]" ref="scroll" @scroll="handleScroll">
        <table class="table table-report -mt-2 table-auto">
          <thead class="sticky top-0 z-50 bg-[#f1f5f9]">
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Visible</th>
              <!-- <th>DESC</th> -->
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
                <!-- <ArchiveItem
                  class="ml-3 -mt-1"
                  :activeAccess="$h.archiveItemAccess(policyAccess)"
                  v-if="checkedData.length > 0"
                  @parentMethod="archiveMultipleItem($event)"
                  name="Archive Selected"
                /> -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <td>
                <div class="form-switch ml-3">
                  <input id="booleanSwitch" type="checkbox" v-model="item.visible" class="form-check-input" disabled />
                </div>
              </td>

              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                  <InfoItem @parentMethod="viewDepartmentProduct($event)" :item="item" name="View PLU/SKU" />
                  <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                  <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)" :item="item" name="Archive" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <NoData :itemsData="itemsData" />
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePage($event)"
        @pageSizeChange="pageSizeChange($event)"
        :itemsData="itemsData"
        :pageCount="pageCount"
        :pageSize="pageSizeView"
        :pageNumber="pagination.pageNumber"
      />

      <!-- END: Pagination -->
    </div>
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addDepartment" @hidden="addDepartment = false" backdrop="static" size="modal-lg" :title="formTitle" @close="addDepartment = false">
    <ModalBody class="flex justify-center space-y-4 p-8">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">Department Name</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataDepartment.name" type="text" class="form-control" name="name" placeholder="Department Name" autocomplete="off" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Department Code</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataDepartment.code" type="text" class="form-control" name="code" placeholder="Department Code" autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Warehouse</label>
          <v-select
            v-model="formDataDepartment.warehouseId"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenWarehouse"
            @close="onCloseWarehouse"
            @search="searchWarehouse"
            :options="warehousesList"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :reduce="(abbreviation) => abbreviation.warehouseId"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageWarehouse" ref="loadWarehouse" class="loader">Loading more...</li>
            </template>
          </v-select>
          <!-- <v-select
            v-if="storeUserRole != 'SuperUser'"
            v-model="formDataDepartment.warehouseId"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenUsersWarehouse"
            @close="onCloseUsersWarehouse"
            @search="
              (query) => {
                onUserWarehouseSearch(query);
              }
            "
            :options="paginatedUserWarehouse"
            :reduce="(abbreviation) => abbreviation.warehouseId"
            label="abbreviation"
          >
            <template #list-footer>
              <li
                v-show="hasNextPageUsersWarehouse"
                ref="loadUsersWarehouse"
                class="loader"
              >
                Loading more...
              </li>
            </template>
          </v-select> -->
        </div>
        <div class="mt-3 flex">
          <label>Visible</label>
          <div class="form-switch ml-3">
            <input type="checkbox" v-model="formDataDepartment.visible" class="form-check-input" />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Archive Modal -->
  <MoveableModal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static" title="  Archived Departments" @close="archiveModal = false">
    <ModalBody class="p-0">
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            clearable
            autocomplete="off"
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataDepartment.name" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataDepartment.name" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataDepartment.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->

  <!-- BEGIN: View Lpn Product Info Modal -->

  <MoveableModal
    :show="viewDepartmentProductModal"
    @hidden="viewDepartmentProductModal = false"
    backdrop="static"
    size="modal-sl-85"
    title="View PLU/SKU"
    @close="viewDepartmentProductModal = false"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-6">
        <div class="p-2 text-center">
          <div class="text-xl font-bold mt-5">Department : {{ this.formDataDepartment.name }}</div>
        </div>

        <!-- BEGIN: Data List -->

        <div class="intro-y col-span-12 overflow-auto md:mt-4 mt-2">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th class="whitespace-nowrap" v-for="(header, i) in DepartmentProductHeaders" :key="i">
                  {{ header.text }}
                </th>
                <th>Price</th>
                <th>VAT Percentage</th>
                <th>Temperature</th>
                <th>Weight</th>
                <th>Discount</th>
                <th>Discounted Price</th>
                <th>Discount Reason</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in DepProItems" :key="i" class="intro-x">
                <td v-for="(header, index) in DepartmentProductHeaders" :key="index" class="cursor-pointer">
                  {{ item[header.value] }}
                </td>
                <td>
                  {{ currencyFormat(item.price) }}
                </td>
                <td>
                  {{ percentageFormat(item.vatPercentage) }}
                </td>
                <td>
                  {{ temperatureFormat(item.temperature) }}
                </td>
                <td>
                  {{ weightFormat(item.weight) }}
                </td>
                <td>
                  <div class="form-switch ml-3">
                    <input id="booleanSwitch" type="checkbox" v-model="item.isDiscount" class="form-check-input" disabled />
                  </div>
                </td>
                <td>
                  {{ currencyFormat(item.discountedPrice) }}
                </td>
                <td>
                  {{ item.discountReason }}
                </td>
                <td>
                  <div class="text-left line-clamp-2">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      :options="{
                        zIndex: 99999,
                      }"
                      :content="item.description"
                    >
                      {{ item.description }}
                    </Tippy>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- END: Data List -->
        <!-- <LoadMoreData
          :itemsData="itemsDataSku"
          :loadMoreData="loadMoreDataSku"
        /> -->
        <NoData :itemsData="itemsDataSku" />
        <!-- BEGIN: Pagination -->
        <PaginationBar @changePage="changePageSku($event)" @pageSizeChange="pageSizeChangeSku($event)" :itemsData="itemsData" :pageCount="pageCountSku" :pageSize="pageSizeSku" />

        <!-- <div
          class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
          style="background-color: #164e63; border-radius: 10px"
        >
          <div
            class="w-full sm:w-auto sm:mr-auto"
            style="
              background-color: #e1e7ef;
              border: 2px solid #164e63;
              border-radius: 10px;
            "
          >
            <paginate
              class="text-white"
              :page-count="pageCountSku"
              :page-range="5"
              :margin-pages="2"
              :click-handler="changePageSku"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :first-last-button="true"
              :initial-page="initialPage"
              :prev-text="'<'"
              :next-text="'>'"
              :first-button-text="'<<'"
              :last-button-text="'>>'"
            >
            </paginate>
          </div>
          <div class="mr-2 text-white">Page Rows :</div>
          <select
            v-model="pageSizeSku"
            class="w-20 form-select box cursor-pointer"
            @change="pageSizeChangeSku"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option :value="pageSizeSku">{{ pageSizeSku }}</option>
          </select>
        </div> -->

        <!-- END: Pagination -->

        <!-- <div
          v-if="itemsDataLpn.totalCount == 0"
          class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
        >
          No Data
        </div> -->
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <MoveableModal :show="uploadModal" @hidden="uploadModal = false" size="modal-sl-80" backdrop="static" title="Upload CSV" @close="closeUploadModal()">
    <ModalBody class="flex justify-center space-y-4 w-full">
      <div class="flex flex-col space-y-4 w-full">
        <ArrangeCsv
          csvName="Departments"
          fileUploadAPI="Departments/Upload/Csv"
          :list="DepartmentsHeaderList"
          templateAPI="Templates/Departments"
          :warehouseId="0"
          @getDataFromApi="getDataFromApi($event)"
          :templateShow="false"
          :isWarehouse="false"
        />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <div class="flex space-x-2 justify-center items-center">
        <button type="button" @click="closeUploadModal()" class="btn btn-outline-secondary w-24 h-11">
          {{ const_txt.CANCEL_BTN }}
        </button>

        <!-- <UploadFile
            buttonName = "Upload Bin as a CSV file"
            :activeAccess="$h.addItemAccess(policyAccess)"
            @chooseFiles="chooseFiles($event)"
            @onFilePicked="onFilePicked($event)"
            @downloadTemplate="downloadTemplate($event)"
          /> -->
      </div>
    </ModalFooter>
  </MoveableModal>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Departments");
    this.observerWarehouse = new IntersectionObserver(this.infiniteScrollWarehouse);
    this.observerUsersWarehouse = new IntersectionObserver(this.infiniteScrollUsersWarehouse);
  },
  components: {},
  data() {
    return {
      uploadModal: false,
      formDataUploadWarehouseId: "",
      storeUserRole: store.state.authUser.userRole,
      warehousesearch: "",
      observerUsersWarehouse: null,
      limitUsersWarehouse: 5,

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,

      warehousesList: [
        {
          id: 0,
          abbreviation: store.state.authUser.warehouse.abbreviation,
          warehouseId: store.state.authUser.warehouse.warehouseId,
        },
      ],
      storeWarehouseId: store.state.authUser.warehouse.warehouseId,
      viewDepartmentProductModal: false,
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addDepartment: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataDepartment: {
        name: "",
        code: "",
        visible: true,
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },
      formDataDepartmentDefault: {
        name: "",
        code: "",
        visible: true,
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },

      rules: {
        name: {
          required,
          minLength: minLength(2),
        },
        DepartmentDescription: {
          required,
          minLength: minLength(2),
        },
        code: {
          required,
          maxLength: maxLength(10),
        },
      },

      headers: [
        // { text: "ID", value: "departmentId" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        // { text: "Visible", value: "visible" },
        // { text: "PLU/SKU", value: "sku" },
      ],
      headersFilter: [
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
      ],
      DepartmentProductHeaders: [
        { text: "PLU/SKU", value: "skuName" },
        { text: "BARCODE/EAN", value: "ean" },
        // { text: "Price", value: "price" },
        // { text: "VAT Percentage", value: "vatPercentage" },
        // { text: "Temperature", value: "temperature" },
        { text: "Dimension", value: "dimension" },
        // { text: "Weight", value: "weight" },
        { text: "Department", value: "department" },
        { text: "Min Stock", value: "minStock" },
        // { text: "Discounted Price", value: "discountedPrice" },
        // { text: "Discount Reason", value: "discountReason" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "name",
        sortDirection: "ASC",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "name",
        sortDirection: "ASC",
      },

      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      paginationSku: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultSkuPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCountSku: 0,
      pageSizeSku: 10,

      items: [],
      itemsData: [],
      itemsDataSku: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,
      uploadedfile: null,
      fileName: " ",

      allItems: [],
      allItemsData: [],

      isActive: 0,
      docs: null,

      DepProItems: [],
      splitData: false,
      allSelected: false,
      checkedData: [],
      policyAccess: "Departments",
      hasScrolledToBottom: false,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Department" : this.formDataDepartment?.name ? `Edit Department - ${this.formDataDepartment?.name}` : "Edit Department";
    },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
    checkLimtWarehouse() {
      return this.whPageNumber;
    },
    viewModalWh() {
      return this.formDataDepartment.warehouseId;
    },
    getUsersWarehouses() {
      this.isLoading = true;
      let storedArrayData = store.state.authUser.licensee.warehouses;
      if (storedArrayData.length > 0) {
        this.isLoading = false;
        this.warehousesList = storedArrayData;
        return this.warehousesList.filter((x) => x.abbreviation.toLowerCase().includes(this.warehousesearch.toLowerCase()));
      } else {
        this.isLoading = false;
        warningToast("There are no Warehouses found");
      }
    },
    paginatedUserWarehouse() {
      return this.getUsersWarehouses.slice(0, this.limitUsersWarehouse);
    },
    hasNextPageUsersWarehouse() {
      return this.paginatedUserWarehouse.length < this.getUsersWarehouses.length;
    },
  },
  watch: {
    checkLimtWarehouse() {
      this.getWarehouses();
    },
    viewModalWh() {
      if (this.formDataDepartment.warehouseId === null) {
        this.clearWarehouse();
      }
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    handleScroll(el) {
      if (el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight) {
        if (this.itemsData.totalPages > this.pagination.pageNumber) {
          this.hasScrolledToBottom = true;
          this.pagination.pageNumber = this.pagination.pageNumber + 1;
          this.changePage(this.pagination.pageNumber);
          this.$refs.scroll.scroll(0, 0);
        }
      }
    },
    openUploadModal() {
      this.uploadModal = true;
    },
    closeUploadModal() {
      this.uploadModal = false;
      this.formDataUploadWarehouseId = "";
      this.uploadcsvfile = null;
    },
    onUserWarehouseSearch(query) {
      this.warehousesearch = query;
    },

    async onOpenUsersWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.hasNextPageUsersWarehouse) {
        await this.$nextTick();
        this.observerUsersWarehouse.observe(this.$refs.loadUsersWarehouse);
      }
    },
    onCloseUsersWarehouse() {
      this.observerUsersWarehouse.disconnect();
      this.limitUsersWarehouse = 5;
    },
    async infiniteScrollUsersWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitUsersWarehouse = this.limitUsersWarehouse + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    clearWarehouse() {
      this.warehousesList = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehousesList = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },
    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehousesList =
            this.isWhSearchResult !== true
              ? Array.from(new Set([...this.warehousesList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    async onOpenWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    onDragInfo({ transform }) {
      let targetInfo = this.$refs.targetInfo;
      targetInfo.style.transform = transform;
    },

    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Name", dataKey: "name" },
              { title: "Code", dataKey: "code" },
              { title: "Visible", dataKey: "visible" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                name: el.name,
                code: el.code,
                visible: el.visible,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },
    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post("Departments/" + this.storeWarehouseId + "/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.isActive = 3;
      this.getAllDataFromApi();
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Departments " + generateDate}.pdf`);
    },

    csvExport() {
      this.isActive = 2;
      this.getAllDataFromApi();
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Departments " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Name",
          2: "Code",
          3: "Visible",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.name,
            2: item.code,
            3: item.visible,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Departments/" + this.storeWarehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    chooseFiles() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      this.uploadcsvfile = event.target.files[0];
      if (this.formDataUploadWarehouseId == "" || this.formDataUploadWarehouseId == null || this.uploadcsvfile == null) {
        if (this.formDataUploadWarehouseId == "") {
          warningToast("Warehouse Required");
        }
        if (this.uploadcsvfile == null) {
          warningToast("Csv file Required");
        }
      } else {
        let formData = new FormData();

        formData.append("File", this.uploadcsvfile);
        formData.append("Name", this.uploadcsvfile.name);

        this.isLoading = true;
        axios
          .post("Departments/Upload/Csv", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
        this.formDataUploadWarehouseId = "";
        this.uploadcsvfile = null;
      }
    },
    // onFilePicked(uploadedfile) {
    //   // this.uploadedfile = document.getElementById("fileInput").files[0];
    //   this.fileName = uploadedfile.name;
    //   let formData = new FormData();

    //   formData.append("file", this.uploadedfile);

    //   this.isLoading = true;
    //   axios
    //     .post("Departments/Upload/Csv", formData)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         this.getDataFromApi();
    //         successToast(response.data.message);
    //       } else {
    //         this.isLoading = false;

    //         failedToast(response.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    loadMoreDataSku() {
      this.paginationSku.pageSize = parseInt(this.paginationSku.pageSize) + 10;
      this.pageSizeSku = this.paginationSku.pageSize;
      this.getDataFromDEpartmentProduct();
    },
    pageSizeChangeSku(pageSize) {
      this.pageSizeSku = pageSize;
      this.paginationSku.pageSize = this.pageSizeSku;
      this.getDataFromDEpartmentProduct();
    },
    changePageSku(pageNum) {
      this.paginationSku.pageNumber = pageNum;
      this.getDataFromDEpartmentProduct();
    },

    setDefault() {
      this.formDataDepartment = Object.assign({}, this.formDataDepartmentDefault);
    },

    viewDepartmentProduct(item) {
      this.formDataDepartment = Object.assign({}, item);
      this.viewDepartmentProductModal = true;
      this.getDataFromDEpartmentProduct();
    },

    closeDepartmentProductForm() {
      this.viewDepartmentProductModal = false;
    },

    getDataFromDEpartmentProduct() {
      this.isLoading = true;
      var depId = this.formDataDepartment.departmentId;
      axios
        .post("Departments/" + depId + "/Products", this.paginationSku)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsDataSku = response.data.data;
            this.DepProItems = response.data.data.items;
            this.isLoading = false;
            this.pageCountSku = this.itemsDataSku.totalPages;
          } else {
            failedToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
        });
    },

    getDataFromApi() {
      this.isLoading = true;

      axios
        .post("Departments/" + this.storeWarehouseId + "/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      //this.getWarehouses();
      this.addDepartment = true;
      this.activateModal();
    },
    activateModal() {
      this.addDepartment = true;
      this.getWarehouses();
      // if (this.storeUserRole == "SuperUser") {
      //   this.getWarehouses();
      // } else {
      //   this.getUsersWarehouses;
      // }
    },
    closeForm() {
      this.addDepartment = false;
    },

    editItem(item) {
      this.titleChange = 0;
      this.formDataDepartment = Object.assign({}, item);
      this.activateModal();
    },

    saveForm() {
      if (this.formDataDepartment.name == "") {
        if (this.formDataDepartment.name == "") {
          warningToast("Department Name Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Departments", this.formDataDepartment)
            .then((response) => {
              if (response.data.success === true) {
                this.addDepartment = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          //(this.formDataDepartment);
          var itemId = this.formDataDepartment.departmentId;
          this.isLoading = true;
          axios
            .put("Departments/" + itemId, this.formDataDepartment)
            .then((response) => {
              if (response.data.success === true) {
                this.addDepartment = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataDepartment = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataDepartment.departmentId;
      this.isLoading = true;
      axios
        .get("Departments/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Departments/" + this.storeWarehouseId + "/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;

            this.archiveModal = true;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataDepartment = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataDepartment.departmentId;
      this.isLoading = true;
      axios
        .get("Departments/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataDepartment = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataDepartment.departmentId;
      axios
        .delete("Departments/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
