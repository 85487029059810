<template>
  <div class="w-auto hidden">
    <div class="flex justify-center items-center whitespace-nowrap space-x-4">
      <div
        class="h-10 flex justify-center items-center space-x-1 bg-primary rounded-md"
      >
          
        <Tippy
          tag="a"
          href="javascript:;"
          class="tooltip"
          content="Sort Items"
          :options="{
            zIndex: 99999,
          }"
        >
        

       

        <TomSelect
          v-model="sortColumnName"
          :options="{
            placeholder: 'Search..',
          }"
          class="w-40 border-white ml-2"
        >
          <option
            :value="item.value"
            v-for="(item, i) in headersFilter"
            :key="i"
          >
            {{ item.text }}
          </option>
        </TomSelect>
      </Tippy>
        <Tippy
          tag="a"
          href="javascript:;"
          class="tooltip"
          content="ASC"
          :options="{
            zIndex: 99999,
          }"
        >
          <ChevronsUpIcon
            class="lg:w-6 lg:h-6 w-4 h-4 hover:text-yellow-200 text-white"
            @click="sorting(sortColumnName, 'asc')"
          />
        </Tippy>

        <Tippy
          tag="a"
          href="javascript:;"
          class="tooltip"
          content="DESC"
          :options="{
            zIndex: 99999,
          }"
        >
          <ChevronsDownIcon
            class="lg:w-6 lg:h-6 w-4 h-4 hover:text-yellow-200 text-white"
            @click="sorting(sortColumnName, 'desc')"
          />
        </Tippy>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FilterItems",
  props: { headersFilter: [] },
  methods: {
    //sortColumnName, sortDirection are set as the parameters of SORTING method
    //var data initate and create an object consiste of sortColumnName, sortDirection
    //check it by the console log the DATA, check the link for reference
    //https://codybontecou.com/vuejs-emit-multiple-values-from-child-to-parent.html#access-the-parameters-from-the-parent-component-using-an-event-listener
    //-comment to be continued in SORTING method in Parent Comp

    sorting(sortColumnName, sortDirection) {
      var data = { sortColumnName, sortDirection };
      // console.log("DATA", data);
      this.$emit("parentMethod", data);
    },
  },
};
</script>
