<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">Lucide Icons</h2>
  </div>
  <!-- BEGIN: Icon List -->
  <div class="intro-y grid grid-cols-12 sm:gap-6 gap-y-6 box px-5 py-8 mt-5">
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ActivityIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ActivityIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AirplayIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AirplayIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlertCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlertCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlertOctagonIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlertOctagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlertTriangleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlertTriangleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlignCenterIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlignCenterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlignJustifyIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlignJustifyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlignLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlignLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AlignRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AlignRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AnchorIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AnchorIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ApertureIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ApertureIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArchiveIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArchiveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowDownCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowDownLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowDownRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowLeftCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowLeftCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowRightCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowRightCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowUpCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowUpLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowUpRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ArrowUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ArrowUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AtSignIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AtSignIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <AwardIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">AwardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BarChart2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BarChart2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BarChartIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BarChartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BatteryChargingIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BatteryChargingIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BatteryIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BatteryIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BellOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BellOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BellIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BellIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BluetoothIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BluetoothIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BoldIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BoldIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BookOpenIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BookOpenIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BookIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BookIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BookmarkIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BookmarkIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BoxIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BoxIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <BriefcaseIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">BriefcaseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CalendarIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CalendarIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CameraOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CameraOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CameraIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CameraIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CastIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CastIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CheckCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CheckCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CheckSquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CheckSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CheckIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CheckIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronsDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronsLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronsRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChevronsUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChevronsUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ChromeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ChromeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ClipboardIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ClipboardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ClockIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ClockIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CloudDrizzleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CloudDrizzleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CloudLightningIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CloudLightningIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CloudOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CloudOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CloudRainIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CloudRainIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CloudSnowIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CloudSnowIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CloudIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CloudIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CodeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CodeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CodepenIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CodepenIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CodesandboxIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CodesandboxIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CoffeeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CoffeeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ColumnsIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ColumnsIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CommandIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CommandIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CompassIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CompassIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CopyIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CopyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerDownLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerDownLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerDownRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerDownRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerLeftDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerLeftDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerLeftUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerLeftUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerRightDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerRightDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerRightUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerRightUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerUpLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerUpLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CornerUpRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CornerUpRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CpuIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CpuIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CreditCardIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CreditCardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CropIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CropIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <CrosshairIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">CrosshairIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DatabaseIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DatabaseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DeleteIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DeleteIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DiscIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DiscIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DivideCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DivideCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DivideSquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DivideSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DivideIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DivideIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DollarSignIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DollarSignIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DownloadCloudIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DownloadCloudIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DownloadIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DownloadIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DribbbleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DribbbleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <DropletIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">DropletIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Edit2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Edit2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Edit3Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Edit3Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <EditIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">EditIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ExternalLinkIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ExternalLinkIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <EyeOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">EyeOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <EyeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">EyeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FacebookIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FacebookIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FastForwardIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FastForwardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FigmaIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FigmaIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FileMinusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FileMinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FilePlusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FilePlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FileTextIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FileTextIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FileIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FileIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FilmIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FilmIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FilterIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FilterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FlagIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FlagIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FolderMinusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FolderMinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FolderPlusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FolderPlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FolderIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FolderIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FramerIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FramerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <FrownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">FrownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GiftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GiftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GitBranchIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GitBranchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GitCommitIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GitCommitIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GitMergeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GitMergeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GitPullRequestIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GitPullRequestIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GithubIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GithubIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GitlabIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GitlabIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GlobeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GlobeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <GridIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">GridIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HardDriveIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HardDriveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HashIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HashIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HeadphonesIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HeadphonesIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HeartIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HeartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HelpCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HelpCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HexagonIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HexagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <HomeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">HomeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ImageIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ImageIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <InboxIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">InboxIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <InfoIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">InfoIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <InstagramIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">InstagramIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ItalicIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ItalicIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <KeyIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">KeyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LayersIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LayersIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LayoutIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LayoutIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LifeBuoyIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LifeBuoyIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Link2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Link2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LinkIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LinkIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LinkedinIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LinkedinIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ListIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ListIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LoaderIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LoaderIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LockIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LockIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LogInIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LogInIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <LogOutIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">LogOutIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MailIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MailIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MapPinIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MapPinIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MapIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MapIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Maximize2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Maximize2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MaximizeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MaximizeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MehIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MehIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MenuIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MenuIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MessageCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MessageCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MessageSquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MessageSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MicOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MicOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MicIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MicIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Minimize2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Minimize2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MinimizeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MinimizeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MinusCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MinusCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MinusSquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MinusSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MinusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MonitorIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MonitorIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MoonIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MoonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MoreHorizontalIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MoreHorizontalIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MoreVerticalIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MoreVerticalIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MousePointerIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MousePointerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MoveIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MoveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <MusicIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">MusicIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Navigation2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Navigation2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <NavigationIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">NavigationIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <OctagonIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">OctagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PackageIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PackageIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PaperclipIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PaperclipIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PauseCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PauseCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PauseIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PauseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PenToolIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PenToolIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PercentIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PercentIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneCallIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneCallIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneForwardedIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneForwardedIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneIncomingIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneIncomingIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneMissedIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneMissedIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneOutgoingIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneOutgoingIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PhoneIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PhoneIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PieChartIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PieChartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PlayCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PlayCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PlayIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PlayIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PlusCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PlusCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PlusSquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PlusSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PlusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PocketIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PocketIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PowerIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PowerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <PrinterIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">PrinterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RadioIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RadioIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RefreshCcwIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RefreshCcwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RefreshCwIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RefreshCwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RepeatIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RepeatIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RewindIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RewindIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RotateCcwIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RotateCcwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RotateCwIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RotateCwIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <RssIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">RssIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SaveIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SaveIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ScissorsIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ScissorsIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SearchIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SearchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SendIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SendIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ServerIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ServerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SettingsIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SettingsIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Share2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Share2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ShareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ShareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ShieldOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ShieldOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ShieldIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ShieldIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ShoppingBagIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ShoppingBagIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ShoppingCartIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ShoppingCartIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ShuffleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ShuffleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SidebarIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SidebarIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SkipBackIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SkipBackIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SkipForwardIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SkipForwardIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SlackIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SlackIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SlashIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SlashIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SlidersIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SlidersIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SmartphoneIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SmartphoneIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SmileIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SmileIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SpeakerIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SpeakerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <StarIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">StarIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <StopCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">StopCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SunIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SunIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SunriseIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SunriseIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <SunsetIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">SunsetIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TabletIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TabletIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TagIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TagIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TargetIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TargetIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TerminalIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TerminalIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ThermometerIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ThermometerIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ThumbsDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ThumbsDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ThumbsUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ThumbsUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ToggleLeftIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ToggleLeftIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ToggleRightIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ToggleRightIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ToolIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ToolIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Trash2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Trash2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TrashIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TrashIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TrelloIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TrelloIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TrendingDownIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TrendingDownIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TrendingUpIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TrendingUpIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TriangleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TriangleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TruckIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TruckIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TvIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TvIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TwitchIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TwitchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TwitterIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TwitterIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <TypeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">TypeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UmbrellaIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UmbrellaIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UnderlineIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UnderlineIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UnlockIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UnlockIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UploadCloudIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UploadCloudIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UploadIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UploadIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UserCheckIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UserCheckIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UserMinusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UserMinusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UserPlusIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UserPlusIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UserXIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UserXIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UserIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UserIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <UsersIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">UsersIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <VideoOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">VideoOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <VideoIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">VideoIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <VoicemailIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">VoicemailIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Volume1Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Volume1Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <Volume2Icon class="block mx-auto" />
      <div class="text-center text-xs mt-2">Volume2Icon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <VolumeXIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">VolumeXIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <VolumeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">VolumeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <WatchIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">WatchIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <WifiOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">WifiOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <WifiIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">WifiIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <WindIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">WindIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <XCircleIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">XCircleIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <XOctagonIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">XOctagonIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <XSquareIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">XSquareIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <XIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">XIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <YoutubeIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">YoutubeIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ZapOffIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ZapOffIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ZapIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ZapIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ZoomInIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ZoomInIcon</div>
    </div>
    <div class="col-span-6 sm:col-span-3 lg:col-span-2 xl:col-span-1">
      <ZoomOutIcon class="block mx-auto" />
      <div class="text-center text-xs mt-2">ZoomOutIcon</div>
    </div>
  </div>
  <!-- END: Icon List -->
</template>
