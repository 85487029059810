<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:justify-between md:flex md:flex-row md:items-center md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName=" Add New Cycle Count" />

        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pagination.pageNumber"
          :selected="checkedData.length"
          @printSelected="printSelected"
          @csvExportSelected="csvExportSelected"
          @pdfExportSelected="pdfExportSelected"
        />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>
      <!-- <ViewArchiveItem @parentMethod="viewArchives($event)" /> -->
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th></th>
            <th>Description</th>
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>Date</th>
            <th>Completed</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
            </th>
          </tr>
        </thead>
        <tbody v-for="(item, i) in items" :key="i">
          <tr class="intro-x">
            <td class="cursor-pointer" :style="opened.includes(item.cycleCountId) ? getSelectedColor() : 'color: #1a73e8'">
              <svg
                v-if="!opened.includes(item.cycleCountId)"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-caret-down-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-primary text-bold"
                @click="toggle(item)"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>

              <svg
                v-if="opened.includes(item.cycleCountId)"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-caret-up-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-white text-bold"
                viewBox="0 0 16 16"
                @click="closeToggle(item)"
              >
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </td>
            <td :style="opened.includes(item.cycleCountId) ? getSelectedColor() : ''">
              {{ item.description }}
            </td>
            <td v-for="(header, index) in headers" :key="index" :style="opened.includes(item.cycleCountId) ? getSelectedColor() : ''">
              {{ item[header.value] }}
            </td>
            <td :style="opened.includes(item.cycleCountId) ? getSelectedColor() : ''">
              {{ customizeDataAndTime(item.date) }}
            </td>

            <td :style="opened.includes(item.cycleCountId) ? getSelectedColor() : ''">
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.completedFlag" class="form-check-input" disabled />
              </div>
            </td>

            <td class="table-report__action w-28">
              <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />

                <AddSubItem :activeAccess="$h.addProductAccess(policyAccess)" @parentMethod="addProducts($event)" :item="item" name="ADD PRODUCTS" />
                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
              </div>
            </td>
          </tr>

          <tr v-if="opened.includes(item.cycleCountId)">
            <td colspan="13">
              <div class="flex justify-between items-center">
                <div class="flex items-center" v-if="!addProductModalForm">
                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="printProductsPdf()">
                    <PrinterIcon class="w-4 h-4 mr-2" /> Print
                  </button>

                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="downloadProductsPdf()">
                    <FileTextIcon class="w-4 h-4 mr-2" /> Download PDF
                  </button>
                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="csvProductsDownload()">
                    <FileIcon class="w-4 h-4 mr-2" /> Download CSV
                  </button>
                </div>
              </div>
              <table class="table table-report -mt-2 table-auto">
                <thead>
                  <tr class="uppercase whitespace-nowrap">
                    <th>PLU/SKU</th>
                    <th>Section</th>
                    <th>Location</th>
                    <th>Lot</th>
                    <th>Prev Stock</th>
                    <th>Curr Stock</th>
                    <th>Adju By</th>
                    <th>Adju Date</th>
                    <!-- <th class="flex justify-start">
                      <SelectLabels
                        :allSelected="allSelected"
                        @selectAll="selectAll($event)"
                        @unSelectAll="unSelectAll($event)"
                      />
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="productData.length <= 0">
                    <td colspan="12">
                      <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_ORDER_LINE }}</span>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in productData" :key="index" class="intro-x">
                    <td>
                      {{ item.sku != null ? item.sku : "" }}
                    </td>
                    <td>
                      {{ item.section != null ? item.section : "" }}
                    </td>
                    <td>
                      {{ item.bin != null ? item.bin : "" }}
                    </td>
                    <td>
                      {{ item.lot != null ? item.lot : "" }}
                    </td>
                    <td>
                      {{ item.previousStock != null ? item.previousStock : "" }}
                    </td>

                    <td>
                      {{ item.currentStock != null ? item.currentStock : "" }}
                    </td>
                    <td>
                      {{ item.adjustedBy != null ? item.adjustedBy : "" }}
                    </td>
                    <td>
                      {{ customizeDataAndTime(item.adjustedAt != null ? item.adjustedAt : "") }}
                    </td>

                    <td class="table-report__action w-32 gap-x-3">
                      <div class="flex justify-center items-center">
                        <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                        <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteProductItem($event)" :item="item" name="Delete" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <PaginationBarLocal
                @changePage="changePageProduct($event)"
                @pageSizeChange="pageSizeChangeProduct($event)"
                :itemsData="cycleCountData"
                :pageCount="pageCountProduct"
                :pageRange="pageRowCountProduct"
                :pageSize="pageSizeProduct"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />

    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Add PRODUCTS Modal -->

  <MoveableModal :show="addProductsModal" @hidden="addProductsModal = false" backdrop="static" size="modal-sl-90" title="ADD PRODUCTS" @close="closeProductModal()">
    <ModalBody class="">
      <div class="grid grid-cols-3 lg:gap-4 md:gap-4 gap-2">
        <!-- <div class="w-full">
          <label for="regular-form-1" class="form-label">Warehouse</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataProducts.warehouse"
              type="text"
              class="form-control"
              name="warehouse"
              placeholder="Warehouse"
              autocomplete="off"
              disabled
            />
          </div>
        </div> -->

        <div>
          <label for="regular-form-1" class="form-label">Storage Section</label>
          <v-select
            v-model="formDataProducts.section"
            placeholder="SELECT"
            :filterable="false"
            @open="onOpenSS"
            @close="onCloseSS"
            class="style-chooser cursor-pointer"
            @search="searchSS"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :options="storageSections"
            :reduce="(name) => name.abbreviation"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageSS" ref="loadSS" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>

        <div class="flex flex-col w-full space-y-1">
          <label for="regular-form-1" class="form-label">Storage Bin</label>
          <v-select
            v-model="formDataProducts.bin"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenSB"
            @close="onCloseSB"
            @search="searchSB"
            :options="storageBins"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :reduce="(abbreviation) => abbreviation.abbreviation"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageSB" ref="loadSB" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">LOT Number</label>

          <div class="flex flex-col space-y-1">
            <v-select
              v-model="formDataProducts.lot"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenLot"
              @close="onCloseLot"
              :options="itemsLot"
              @search="searchLot"
              :key="lot"
              :get-option-label="(option) => option.lot"
              :reduce="(lot) => lot.lot"
              label="lot"
            >
              <template #list-footer>
                <li v-show="hasNextPageLot" ref="loadLot" class="loader">Loading more...</li>
              </template>
            </v-select>
          </div>
        </div>
        <div class="w-full">
          <label for="regular-form-1" class="form-label">PLU/SKU</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataProducts.skuName" type="text" class="form-control" name="skuName" placeholder="PLU/SKU" autocomplete="off" disabled />
          </div>
        </div>
        <div class="w-full">
          <label for="regular-form-1" class="form-label">Description</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataProducts.description"
              type="text"
              class="form-control"
              name="description"
              placeholder="Description"
              autocomplete="off"
              disabled
            />
          </div>
        </div>
        <div class="w-full">
          <label for="regular-form-1" class="form-label">Previous Stock</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataProducts.previousStock"
              type="number"
              class="form-control"
              name="previousStock"
              placeholder="Previous Stock"
              autocomplete="off"
              disabled
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize">Current Stock</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="number"
              class="form-control capitalize"
              name="currentStock"
              placeholder="currentStock"
              v-model="formDataProducts.currentStock"
              autocomplete="off"
            />
          </div>
        </div>
        <!-- <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize"
            >Quantiy</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="number"
              class="form-control capitalize"
              name="currentStock"
              placeholder="currentStock"
              v-model="formDataProducts.quantity"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="w-full">
          <label for="regular-form-1" class="form-label">Adjusted At</label>

          <div class="flex flex-col space-y-1">
            <PreviewComponent class="intro-y">
              <Litepicker
                v-model="formDataProducts.adjustedAt"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: 2050,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control"
              />
            </PreviewComponent>
          </div>
        </div>
        <div class="w-full">
          <label for="regular-form-1" class="form-label capitalize"
            >Adjusted By</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              type="text"
              class="form-control capitalize"
              name="adjustedBy"
              placeholder="adjustedBy"
              v-model="formDataProducts.adjustedBy"
              autocomplete="off"
            />
          </div>
        </div> -->
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeProductModal()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveProducts()" type="submit" class="btn btn-primary w-24">SAVE</button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add PRODUCTS Modal -->

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addCycleCount" @hidden="addCycleCount = false" backdrop="static" :title="formTitle" @close="addCycleCount = false">
    <ModalBody class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
      <div class="flex flex-col w-full col-span-3 space-y-1">
        <label for="regular-form-1" class="form-label">Description</label>
        <div class="flex flex-col space-y-1">
          <textarea id="vertical-form-1" v-model="formDataCycleCount.description" type="text" class="form-control" name="description" placeholder="description" />
        </div>
      </div>
      <div class="flex flex-col w-full col-span-2 space-y-1">
        <label for="regular-form-1" class="form-label">Date</label>

        <div class="flex flex-col space-y-1">
          <PreviewComponent class="intro-y">
            <Litepicker
              v-model="formDataCycleCount.date"
              :options="{
                autoApply: true,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: 2050,
                  months: true,
                  years: true,
                },
              }"
              class="form-control"
            />
          </PreviewComponent>
        </div>
      </div>
      <div class="flex flex-col w-full space-y-1">
        <label for="regular-form-1" class="form-label">Warehouse</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="warehouseAbbreviation" type="text" class="form-control" autocomplete="off" disabled />
        </div>
      </div>
      <div class="flex flex-col w-full col-span-2 space-y-1">
        <label for="regular-form-1" class="form-label">Note</label>
        <div class="flex flex-col space-y-1">
          <textarea id="vertical-form-1" v-model="formDataCycleCount.note" type="text" class="form-control" name="note" placeholder="note" />
        </div>
      </div>

      <div class="flex flex-col w-full space-y-1">
        <label>Completed Flag</label>
        <div class="form-switch">
          <input type="checkbox" v-model="formDataCycleCount.completedFlag" class="form-check-input" />
        </div>
      </div>

      <!-- <div class="flex flex-col w-full col-span-2 space-y-1">
        <label for="regular-form-1" class="form-label">Taken By</label>
        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataCycleCount.takenBy"
            type="text"
            class="form-control"
            name="takenBy"
            placeholder="takenBy"
            disabled
          />
        </div>
      </div> -->
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">Done</button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: View Product Items Modal -->

  <!-- <MoveableModal
    :show="viewItemModal"
    @hidden="viewItemModal = false"
    backdrop="static"
    size="modal-sl-85"
    :title="viewProductTitle"
    @close="viewItemModal = false"
  >
    <ModalBody class="p-4">
      
      <div
        id="labelsScroll"
        class="intro-y col-span-12 overflow-scroll max-h-72"
      >
        <table class="table table-report table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>PLU/SKU</th>
              <th>Section</th>
              <th>Location</th>
              <th>Lot</th>
              <th>Prev Stock</th>
              <th>Curr Stock</th>
              <th>Adju By</th>
              <th>Adju Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in productData" :key="i" class="intro-x">
              <td>
                {{ item.sku }}
              </td>
              <td>
                {{ item.section }}
              </td>
              <td>
                {{ item.bin }}
              </td>
              <td>
                {{ item.lot }}
              </td>
              <td>
                {{ item.previousStock }}
              </td>
              <td>
                {{ item.currentStock }}
              </td>
              <td>
                {{ item.adjustedBy }}
              </td>

              <td>{{ customizeDataAndTime(item.adjustedAt) }}</td>
              <td class="table-report__action w-32">
                <div class="flex justify-center items-center">
                  <DeleteItem
                    :activeAccess="$h.deleteItemAccess(policyAccess)"
                    @parentMethod="deleteProductItem($event)"
                    :item="item"
                    name="Delete"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
          v-if="!(productData && productData.length > 0)"
        >
          No Data
        </div>
      </div>
      <PaginationBar
        @changePage="batchChangePage($event)"
        @pageSizeChange="batchPageSizeChange($event)"
        :itemsData="batchItemsData"
        :pageCount="batchPageCount"
        :pageSize="batchPageSize"
      />
    </ModalBody>
  </MoveableModal> -->
  <!-- END:Product Items Modal -->

  <!-- BEGIN: Archive Modal -->
  <MoveableModal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static" title="Archived Unit" @close="archiveModal = false">
    <ModalBody class="p-0">
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            clearable
            autocomplete="off"
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <!-- <DeleteItem
                    :activeAccess="$h.deleteItemAccess(policyAccess)"
                    @parentMethod="deleteItem($event)"
                    :item="item"
                    name="Delete"
                  /> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataCycleCount.description" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataCycleCount.description" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataCycleCount.description" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <DeleteConfirmModal
    :name="formDataCycleCount.description"
    :modalStatus="deleteProductConfirmationModal"
    :modalConfirm="deleteProductItemConfirm"
    :modalClose="closeProductDelete"
  />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";
import moment from "moment";
import store from "../../../store";

// import { permissionHelper as $h } from "@/utils/permissionHelper";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Cycle Counts");
    this.observerSS = new IntersectionObserver(this.infiniteScrollSS);
    this.observerSB = new IntersectionObserver(this.infiniteScrollSB);
    this.observerLot = new IntersectionObserver(this.infiniteScrollLot);
  },
  components: {},
  data() {
    return {
      productItemsData: [],
      cycleCountData: {},
      viewProductTitle: "",
      deleteProductConfirmationModal: false,
      productData: [],
      itemsLot: [],
      lots: [],
      storageBins: [],
      storageSections: [],

      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addCycleCount: false,
      addProductsModal: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataCycleCount: {
        description: "",
        takenBy: null,
        note: "",
        date: "",
        completedFlag: false,
        products: [],
        warehouse: store.state.authUser.warehouse.abbreviation,
      },
      formDataCycleCountDefault: {
        description: "",
        takenBy: null,
        note: "",
        date: "",
        completedFlag: false,
        products: [],
        warehouse: store.state.authUser.warehouse.abbreviation,
      },
      formDataCycleCountProducts: {},
      formDataProducts: {
        cycleCountId: 0,
        adjustedAt: null,
        adjustedBy: null,
        warehouse: store.state.authUser.warehouse.abbreviation,
        section: null,
        bin: null,
        lot: "",
        previousStock: 0,
        currentStock: 0,
        quantity: 0,
        skuName: "",
        utilizedQuantity: 0,
        description: "",
      },
      formDataProductsDefault: {
        cycleCountId: 0,
        adjustedAt: null,
        adjustedBy: null,
        warehouse: store.state.authUser.warehouse.abbreviation,
        section: null,
        bin: null,
        lot: "",
        previousStock: 0,
        currentStock: 0,
        quantity: 0,
        skuName: "",
        utilizedQuantity: 0,
        description: "",
      },
      headers: [
        { text: "Note", value: "note" },
        { text: "TakenBy", value: "takenBy" },
      ],
      headersFilter: [{ text: "Description", value: "description" }],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "description",
        // sortDirection: "asc",
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      observerSS: null,
      limitSS: 5,
      hasNextPageSS: false,
      totalSSCount: 0,
      isSSSearchResult: false,
      ssPageNumber: 1,
      ssSearchTimer: undefined,

      observerSB: null,
      limitSB: 5,
      hasNextPageSB: false,
      totalSBCount: 0,
      isSBSearchResult: false,
      sbPageNumber: 1,
      sbSearchTimer: undefined,

      observerLot: null,
      limitLot: 5,
      hasNextPageLot: false,
      totalLotCount: 0,
      isLotSearchResult: false,
      lotPageNumber: 1,
      lotSearchTimer: undefined,

      pageCountArchive: 20,
      pageSizeArchive: 5,

      pageCountArchive: 20,

      allItems: [],
      allItemsData: [],

      pageCountProduct: 0,
      pageRowCountProduct: 5,
      productStart: 0,
      productEnd: 5,

      paginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      defaultPaginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },

      isActive: 0,
      docs: null,

      allSelected: false,
      checkedData: [],

      opened: [],
      indexToggle: 0,
      policyAccess: "CycleCounts",
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Cycle Count" : "Edit Cycle Count";
    },
    checkLimtSS() {
      return this.ssPageNumber;
    },
    viewModalSS() {
      return this.formDataProducts.section;
    },
    checkLimtSB() {
      return this.sbPageNumber;
    },
    viewModalSB() {
      return this.formDataProducts.bin;
    },
    checkLimtLot() {
      return this.lotPageNumber;
    },
    viewModalLot() {
      return this.formDataProducts.lot;
    },
    getBin() {
      return this.formDataProducts.bin;
    },
    getSection() {
      return this.formDataProducts.section;
    },
    findLots() {
      return this.formDataProducts.lot;
    },
  },
  created() {
    this.getDataFromApi();
  },
  watch: {
    findLots() {
      this.getLotSkus(this.formDataProducts.lot);
    },
    getBin() {
      if (this.formDataProducts.section !== null && this.formDataProducts.bin !== null) {
        this.getLotProducts();
      }
    },
    getSection() {
      console.log("text1");
      if (this.formDataProducts.section === null && this.formDataProducts.bin === null) {
        console.log("text");
        this.itemsLot = [];
      }
    },
    checkLimtSB() {
      this.getStorageBins();
    },
    viewModalSB() {
      if (this.formDataProducts.bin === null) {
        this.clearSB();
      }
    },
    checkLimtSS() {
      this.getStorageSections();
    },
    viewModalSS() {
      if (this.formDataProducts.section === null) {
        this.clearSS();
      }
    },
    checkLimtLot() {
      this.getLots();
    },
    viewModalLot() {
      if (this.formDataProducts.lot === null) {
        this.clearLot();
      }
    },
  },

  methods: {
    setLocalPagination() {
      if (this.cycleCountData) {
        this.cycleCountData.totalCount = this.productData.length;
      }

      var pageCount = this.productData.length / this.pageRowCountProduct;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountProduct = pageCountRound;
      } else {
        this.pageCountProduct = pageCountRound;
      }
      if (this.pageRowCountProduct > this.itemBTData.totalCount) {
        this.pageSizeProduct = this.itemBTData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountProduct;
      }

      //pageCountProduct
      this.cycleCountData = this.productData.slice(this.productStart, this.productEnd);
    },

    pageSizeChangeProduct() {
      // this.pagination.pageSize = this.pageSize;
      // this.getCycleCountProducts();
    },

    changePageProduct(pageNum) {
      this.pageNumber = pageNum;
      this.productEnd = this.pageRowCountProduct * pageNum;
      this.productStart = this.productEnd - this.pageRowCountProduct;
      this.cycleCountData = this.productData.slice(this.productStart, this.productEnd);
    },

    csvProductsDownload() {
      if (this.items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "cycleCounts " + generateDate;
        var items = this.cycleCountData.products;
        var csvItems = [];

        var csvHeaders = {
          1: "PLU/SKU",
          2: "Section",
          3: "Location",
          4: "Lot",
          5: "Prev Stock",
          6: "Curr Stock",
          7: "Adju By",
          8: "Adju Date",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.sku,
            2: item.section,
            3: item.bin,
            4: item.lot,
            5: item.previousStock,
            6: item.currentStock,
            7: item.adjustedBy,
            8: this.customizeDataAndTime(item.adjustedAt),
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },
    productsInfopdf(docs) {
      return new Promise(async (resolve, reject) => {
        try {
          var item = this.cycleCountData.products;
          if (item <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "PLU/SKU", dataKey: "sku" },
              { title: "Section", dataKey: "section" },
              { title: "Location", dataKey: "bin" },
              { title: "Lot", dataKey: "lot" },
              { title: "Prev Stock", dataKey: "previousStock" },
              { title: "Curr Stock", dataKey: "currentStock" },
              { title: "Adju By", dataKey: "adjustedBy" },
              { title: "Adju Date", dataKey: "adjustedAt" },
            ];

            let data = [];
            let i = 0;
            let obj;
            removeNullValueObjectArray(item);
            item.forEach((el) => {
              i += 1;
              obj = {
                sku: el.sku,
                section: el.section,
                bin: el.bin,
                lot: el.lot,
                previousStock: el.previousStock,
                currentStock: el.currentStock,
                adjustedBy: el.adjustedBy,
                adjustedAt: this.customizeDataAndTime(el.adjustedAt),
              };
              //i++;
              data.push(obj);
              //   console.log(obj);
            });

            autoTable(docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              docs.setFontSize(10);
              docs.text(footer, pageWidth / 2 - docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    toggle(item) {
      this.opened.splice(this.indexToggle, 1);
      this.indexToggle = this.opened.indexOf(item.cycleCountId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.description = item.description;
        this.getCycleCountProducts(item.cycleCountId);
        this.opened.push(item.cycleCountId);
      }
    },
    closeToggle(item) {
      this.indexToggle = this.opened.indexOf(item.cycleCountId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.description = item.description;
        this.getCycleCountProducts(item.cycleCountId);
        this.opened.push(item.cycleCountId);
      }
    },
    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white;";
    },
    closeProductModal() {
      this.setDefaultProduct();
      this.addProductsModal = false;
    },
    closeProductDelete() {
      this.deleteProductConfirmationModal = false;
      this.setDefault();
    },
    deleteProductItem(item) {
      this.formDataCycleCountProducts = Object.assign({}, item);
      this.deleteProductConfirmationModal = true;
    },
    deleteProductItemConfirm() {
      var itemId = this.formDataCycleCountProducts.cycleCountProductId;
      this.isLoading = true;
      axios
        .delete("CycleCounts/Products/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteProductConfirmationModal = false;
            this.getCycleCountProducts(itemId);
            suskuDetailccessToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getLotSkus(lot) {
      let list = this.itemsLot.find((x) => x.lot === lot) || [];

      if (Object.keys(list).length > 0) {
        this.formDataProducts.skuName = list.skuDetail.skuName || "";
        this.formDataProducts.description = list.skuDetail.description || "";
        this.formDataProducts.previousStock = list.inStockQuantity || "";
      }
    },
    async getLotProducts() {
      this.isLoading = true;

      await axios
        .get("MemoryStocks/Bins/Products", {
          params: {
            warehouse: this.warehouseAbbreviation,
            section: this.formDataProducts.section,
            bin: this.formDataProducts.bin,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.itemsLot = response.data;
          } else {
            this.isLoading = false;
            failedToast("NO DATA");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast("ERROR");
        });
    },

    async onOpenLot() {
      if (this.isLotSearchResult) {
        this.lotPageNumber = 1;
      }
      if (this.hasNextPageLot) {
        await this.$nextTick();
        this.observerLot.observe(this.$refs.loadLot);
      }
    },
    onCloseLot() {
      this.observerLot.disconnect();
    },
    async infiniteScrollLot([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.lotPageNumber++;
        this.limitLot;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    clearLot() {
      this.lots = [];
      this.lotPageNumber = 1;
      this.limitLot = 5;
      this.getLots("");
    },

    searchLot(search) {
      clearInterval(this.lotSearchTimer);
      this.lotSearchTimer = setTimeout(() => {
        if (search) {
          this.lots = [];
          this.lotPageNumber = 1;
          this.limitLot = this.totalLotCount;
          this.getLots(search.trim());
        }
      }, 2000);
    },
    getLots(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.lotPageNumber,
        pageSize: this.limitLot,
        search: search,
      };
      axios
        .post("Movements/New/Pagination/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isLotSearchResult = !!search;
            var originalItems = this.isLotSearchResult !== true ? [...this.lots, ...response.data.data.items] : response.data.data.items;
            this.hasNextPageLot = response.data.data.hasNextPage;
            this.totalLotCount = response.data.data.totalCount;
            this.lots = originalItems.filter((x) => x.invoiceNumber == null);
            console.log(this.lots);
            if (response.data.data.length == 0) {
              warningToast("Please Create Lots");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    async onOpenSB() {
      if (this.isSBSearchResult) {
        this.sbPageNumber = 1;
      }
      if (this.hasNextPageSB) {
        await this.$nextTick();
        this.observerSB.observe(this.$refs.loadSB);
      }
    },
    onCloseSB() {
      this.observerSB.disconnect();
    },
    async infiniteScrollSB([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.sbPageNumber++;
        this.limitSB;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    clearSB() {
      this.storageBins = [];
      this.sbPageNumber = 1;
      this.limitSB = 5;
      this.getStorageBins("");
    },
    searchSB(search) {
      clearInterval(this.sbSearchTimer);
      this.sbSearchTimer = setTimeout(() => {
        if (search) {
          this.storageBins = [];
          this.sbPageNumber = 1;
          this.limitSB = this.totalSBCount;
          this.getStorageBins(search.trim());
        }
      }, 2000);
    },
    getStorageBins(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.sbPageNumber,
        pageSize: this.limitSB,
        search: search,
      };
      axios
        .post("StorageBins/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSBSearchResult = !!search;
            this.storageBins = this.isSBSearchResult !== true ? [...this.storageBins, ...response.data.data.items] : response.data.data.items;

            this.hasNextPageSB = response.data.data.hasNextPage;
            this.totalSBCount = response.data.data.totalCount;

            if (this.storageBins.length == 0) {
              warningToast("Please Add Items in Storage Bin");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    async onOpenSS() {
      if (this.isSSSearchResult) {
        this.ssPageNumber = 1;
      }
      if (this.hasNextPageSS) {
        await this.$nextTick();
        this.observerSS.observe(this.$refs.loadSS);
      }
    },
    onCloseSS() {
      this.observerSS.disconnect();
    },
    async infiniteScrollSS([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.ssPageNumber++;
        this.limitSS;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    clearSS() {
      this.storageSections = [];
      this.ssPageNumber = 1;
      this.limitSS = 5;
      this.getStorageSections("");
    },

    searchSS(search) {
      clearInterval(this.ssSearchTimer);
      this.ssSearchTimer = setTimeout(() => {
        if (search) {
          this.storageSections = [];
          this.ssPageNumber = 1;
          this.limitSS = this.totalSSCount;
          this.getStorageSections(search.trim());
        }
      }, 2000);
    },

    async getStorageSections(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.ssPageNumber,
        pageSize: this.limitSS,
        search: search,
      };
      await axios
        .post("StorageSections/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          this.isSSSearchResult = !!search;
          this.storageSections =
            this.isSSSearchResult !== true
              ? Array.from(new Set([...this.storageSections, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageSS = response.data.data.hasNextPage;
          this.totalSSCount = response.data.data.totalCount;
          if (this.storageSections.length == 0) {
            warningToast("Please Add Items in Storage Section");
          } else {
            //  console.log("WH", store.state.authUser.warehouse.warehouseId);
            this.storageSections = this.storageSections.filter(function (x) {
              return x.warehouseId == store.state.authUser.warehouse.warehouseId;
            });
          }
        })
        .catch((error) => {
          //   this.addBin = false;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    // viewItemInfo(item) {
    //   this.viewProductTitle = "INFO: " + item.description;
    //   this.getCycleCountProducts(item.cycleCountId);

    //   this.viewItemModal = true;
    // },
    getCycleCountProducts(id) {
      this.isLoading = true;
      axios
        .get(`CycleCounts/${id}`)
        .then((response) => {
          if (response.data.success === true) {
            this.cycleCountData = response.data.data;
            this.productData = response.data.data.products;
            this.isLoading = false;
            this.setLocalPagination();
            this.viewItemModal = true;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    saveProducts() {
      var formData = {
        warehouse: this.warehouseAbbreviation,
        cycleCountId: this.formDataProducts.cycleCountId,
        quantity: 0,
        section: this.formDataProducts.section,
        bin: this.formDataProducts.bin,
        lot: this.formDataProducts.lot,
        currentStock: this.formDataProducts.currentStock,
        previousStock: this.formDataProducts.previousStock,
      };

      axios
        .post("CycleCounts/Products", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.addProductsModal = false;
            //this.setDefaultProduct();
            //this.getDataFromApi();
            this.closeProductModal();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })

        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    closeAddProductsModal() {
      this.setProductsDefault();
      this.addProductsModal = false;
    },
    setProductsDefault() {
      this.formDataProducts = Object.assign({}, this.formDataProductsDefault);
    },
    addProducts(item) {
      this.setProductsDefault();
      this.getStorageBins();
      this.getStorageSections();

      this.formDataProducts.cycleCountId = item.cycleCountId;

      this.addProductsModal = true;
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "Description", dataKey: "description" },
              { title: "TakenBy", dataKey: "takenBy" },
              { title: "Note", dataKey: "note" },
              { title: "Date", dataKey: "date" },
              { title: "Flag", dataKey: "completedFlag" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                takenBy: el.takenBy,
                description: el.description,
                note: el.note,
                date: this.momentLocal(el.date),
                completedFlag: el.completedFlag,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },
    printProductsPdf() {
      var docs = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });
      this.productsInfopdf(docs);
      docs.autoPrint();
      docs.output("dataurlnewwindow");
    },
    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"CycleCounts " + generateDate}.pdf`);
    },
    downloadProductsPdf() {
      var docs = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });
      this.productsInfopdf(docs);
      var generateDate = new Date().toLocaleString();
      docs.save(`${this.cycleCountData.description + "_" + generateDate}.pdf`);
    },
    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "cycleCounts " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Description",
          2: "TakenBy",
          3: "Note",
          4: "Date",
          5: "Flag",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.description,
            2: item.takenBy,
            3: item.note,
            4: this.momentLocalCSV(item.date),
            5: item.completedFlag,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post(`CycleCounts/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData; //newly added line
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataCycleCount = Object.assign({}, this.formDataCycleCountDefault);
    },
    setDefaultProduct() {
      this.formDataProducts = Object.assign({}, this.formDataProductsDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(`CycleCounts/${this.warehouseAbbreviation}/Paginated`, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(error);
          this.isLoading = false;
        });
    },
    showModal() {
      this.setDefault();

      this.titleChange = -1;
      this.addCycleCount = true;
    },
    closeForm() {
      this.clearSS();
      this.addCycleCount = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataCycleCount = Object.assign({}, item);
      this.formDataCycleCount.date = this.customizeDataAndTime(item.date);
      this.addCycleCount = true;
    },
    customizeDataAndTime(date) {
      return moment(date).format("lll");
    },
    saveForm() {
      if (this.formDataCycleCount.description == "") {
        if (this.formDataCycleCount.description == "") {
          warningToast("Description Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("CycleCounts", this.formDataCycleCount)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addCycleCount = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataCycleCount.cycleCountId;
          this.isLoading = true;
          var newDate = moment(this.formDataCycleCount.date).format("YYYY-MM-DD");
          console.log(newDate);
          var currentTime = moment.utc().format();
          console.log(currentTime);
          var splitTime = currentTime.split("T");
          newDate = newDate.concat("T").concat(splitTime[1]);
          this.formDataCycleCount.date = newDate;

          axios
            .put("CycleCounts/" + itemId, this.formDataCycleCount)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addCycleCount = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataCycleCount = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataCycleCount.unitId;
      this.isLoading = true;
      axios
        .get("Units/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Units/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataCycleCount = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataCycleCount.unitId;
      this.isLoading = true;
      axios
        .get("Units/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataCycleCount = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      var itemId = this.formDataCycleCount.cycleCountId;
      this.isLoading = true;
      axios
        .delete("CycleCounts/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
