<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:justify-between md:flex md:flex-row md:items-center md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full">
        <div class="flex justify-center items-center">
          <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport"
            :page="pagination.pageNumber" :selected="checkedData.length" @printSelected="printSelected"
            @csvExportSelected="csvExportSelected" @pdfExportSelected="pdfExportSelected" />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)"
            @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>Invoice#</th>
              <th>Order#</th>
              <th>Ordered Date</th>
              <th>Booking#</th>
              <th>Customer</th>
              <th>Currency</th>
              <th>Total Gross Value</th>
              <th>Total Vat</th>
              <th>Total Amount</th>
              <th>Additional Cost%</th>
              <th>Address</th>
              <th>Sales Rep</th>
              <th>Ship Via</th>
              <th>Taken By</th>

              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)"
                  @unSelectAll="unSelectAll($event)" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td @click="
                addItem(item);
              item.selected = true;
              " class="cursor-pointer" :style="item.selected ? getSelectedColor() : 'color: #1a73e8'">
                {{ item.invoiceNumber != null ? item.invoiceNumber : "" }}
              </td>
              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.orderNumber }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.orderedDate ? momentLocal(item.orderedDate) : "" }}
              </td>
              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.despatch?.bookingNumber != null ? item.despatch.bookingNumber : "" }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                <div v-if="item.customer && !item.customer.isIndividualPersonal">
                  {{ item.customer?.company != null ? item.customer.company : "" }}
                </div>
                <div v-else-if="item.customer">
                  {{ item.customer?.firstName != null ? item.customer.firstName : "" }}
                  {{ item.customer?.lastName != null ? item.customer.lastName : "" }}
                </div>
                <div v-else>{{ item.billTo || '' }}</div>
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.currency }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ currencyFormat(item.grossValue) }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ currencyFormat(item.vatTotal) }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ currencyFormat(calTotalValue(item.grossValue, item.vatTotal)) }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.additionalCostPercenatge ? item.additionalCostPercenatge + "%" : "" }}
              </td>

              <td class="w-24" :style="item.selected ? getSelectedColor() : ''">
                {{ item.deliveryAddress?.addressId }}
                {{ item.deliveryAddress?.line }}{{ item.deliveryAddress?.postcode }}{{ item.deliveryAddress?.state }}{{
                  item.deliveryAddress?.city
                }}{{ item.deliveryAddress?.country }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.salesRep ? item.salesRep : "" }}
              </td>
              <td class="" :style="item.selected ? getSelectedColor() : ''">
                {{ item.shipVia ? item.shipVia : "" }}
              </td>

              <td class="" :style="item.selected ? getSelectedColor() : ''">
                {{ item.takenBy ? item.takenBy : "" }}
              </td>

              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->

      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData"
        :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>

  <!-- BEGIN: Add  Modal For Product-->

  <MoveableModal :show="addProductModal" @hidden="addProductModal = false" backdrop="static" size="modal-sl-85"
    @close="addProductModal = false">
    <ModalBody class="p-0">
      <div class="flex flex-col space-y-4 w-full px-16 py-6 mb-8">
        <div class="flex justify-between items-start pt-4" ref="content">
          <div class="flex flex-col justify-center items-start">
            <img v-if="userRole !== 'SuperUser'" :src="`${baseurl}${storeLicensee.logo}`" alt="logo"
              class="lg:w-52 lg:h-24 md:w-36 md:h-16 w-28 h-12" />

            <img v-if="userRole === 'SuperUser'" :src="`${baseurl}${storeLogo.softClientLogo}`" alt="logo"
              class="lg:w-52 lg:h-24 md:w-36 md:h-16 w-40 h-12" />

            <h5 class="text-lg text-danger font-medium leading-none mt-3">
              {{ checkValue(storeLicensee.companyName, "PHARMATEC PTE LTD") }}
            </h5>
            <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
              {{ checkValue(storeLicensee.address, "P O BOX 460 BA") }}
            </h6>

            <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
              {{ "Ph: " + checkValue(storeLicensee.phoneNumber, "") + (storeLicensee.mobile !== 'null' ||
                toreLicensee.mobile ? "/" : "") + checkValue(storeLicensee.mobile, "") }}
            </h6>
            <!-- <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
              {{ "Fax: " + storeLicensee.phoneNumber || "Fax: 6670118" }}
            </h6> -->
            <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
              {{ "Email: " + checkValue(storeLicensee.email, "Email: admin@pharmatec.com.fj") }}
            </h6>

            <h6 class="mt-3 text-sm font-medium leading-none text-black" v-if="formDataOrder.deliveryDate">
              {{ formDataOrder.deliveryDate ? "DeliveryDate : " + momentLocal(formDataOrder.deliveryDate) : "" }}
            </h6>

            <div class="flex flex-col items-start justify-center space-y-2" v-if="formDataOrder.deliveryAddress">
              <h6 class="mt-3 text-sm font-medium leading-none text-black">
                <div class="flex flex-col space-y-1">
                  <div>Delivery :</div>
                  <div v-if="formDataOrder.deliveryAddress.line">{{ formDataOrder.deliveryAddress.line }},</div>
                  <div v-if="formDataOrder.deliveryAddress.postcode">{{ formDataOrder.deliveryAddress.postcode }},</div>
                  <div v-if="formDataOrder.deliveryAddress.state">{{ formDataOrder.deliveryAddress.state }},</div>
                  <div v-if="formDataOrder.deliveryAddress.city">{{ formDataOrder.deliveryAddress.city }},</div>
                  <div v-if="formDataOrder.deliveryAddress.country">{{ formDataOrder.deliveryAddress.country }}</div>
                </div>
              </h6>
            </div>
          </div>
          <div class="flex flex-col text-right items-start">
            <div class="flex justify-start items-start space-x-2">
              <h2 class="lg:text-2xl md:text-xl text-lg font-medium leading-none">Invoice Number #{{
                checkValue(formDataOrder.invoiceNumber, "") }}</h2>
              <div v-if="formDataOrder.orderNumber != null || formDataOrder.orderNumber != undefined"
                class="self-end mr-8">
                <qrcode-vue render-as="canvas" :value="formDataOrder.orderNumber" size="50" level="H" />
              </div>
            </div>

            <div class="flex flex-col justify-center items-start">
              <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
                Ordered Date :
                {{ checkValue(formDataOrder.orderedDate, "") }}
              </h6>

              <h6 vif="" class="text-sm text-slate-500 font-medium leading-none mt-3">
                {{ checkValue(formDataOrder.customer?.firstName, "") }}
                {{ checkValue(formDataOrder.customer?.lastName, "") }}
              </h6>
              <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
                {{ checkValue(formDataOrder.customer?.company, "") }}
              </h6>

              <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
                Mobile :
                {{ checkValue(formDataOrder.customer?.mobile, "") }}
                E-mail :

                {{ checkValue(formDataOrder.customer?.email, "") }}
              </h6>

              <div class="flex flex-col justify-center items-start space-y-2">
                <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
                  To :
                  <div class="flex">
                    <div>{{ checkValue(line, "") }} <span v-if="state">, </span></div>
                    <div>{{ checkValue(state, "") }} <span v-if="city">, </span></div>
                    <div>{{ checkValue(city, "") }} <span v-if="country && country.toLowerCase() !== 'fiji'">, </span>
                    </div>
                    <div v-if="country.toLowerCase() !== 'fiji'">{{ checkValue(country, "") }}</div>
                  </div>
                </h6>
              </div>

              <h6 class="flex space-x-4 text-sm text-slate-500 font-medium leading-none mt-3">
                Ordered Date:
                {{ formDataOrder.orderedDate ? momentLocal(formDataOrder.orderedDate) : "" }}
              </h6>

              <h6 class="flex space-x-4 text-sm text-slate-500 font-medium leading-none mt-3">
                Invoice Date:
                {{ formDataOrder.invoiceDate ? momentLocal(formDataOrder.invoiceDate) : "" }}
                <!-- {{ date != null || date != undefined ? date : "" }}
                {{ time != null || time != undefined ? time : "" }} -->
              </h6>

              <h6 class="text-sm text-slate-500 font-medium leading-none mt-3">
                Booking# :
                {{ checkValue(bookingNom, "") }}
              </h6>

              <div class="flex justify-start items-start space-x-2 pt-2">
                <h2 class="lg:text-2xl md:text-xl text-lg font-medium leading-none">Order Number {{
                  checkValue(formDataOrder.orderNumber, "") }}</h2>
                <div v-if="formDataOrder.orderNumber != null || formDataOrder.orderNumber != undefined"
                  class="self-end">
                  <qrcode-vue render-as="canvas" :value="formDataOrder.orderNumber" size="50" level="H" />
                </div>
              </div>

              <h6 class="text-sm text-slate-500 font-medium leading-none mt-3" v-if="formDataOrder.amount != null">
                Total Amount:
                {{ currencyFormat(formDataOrder.amount) }}
              </h6>
              <h6 class="text-sm text-slate-500 font-medium leading-none mt-3"
                v-if="formDataOrder.despatchBookingNumber != null">
                Booking#: {{ checkValue(formDataOrder.despatchBookingNumber, "") }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="p-5 text-center text-lg uppercase font-semibold">
        Purchase Order - {{ formDataOrder.orderNumber }}
      </div> -->
      <hr />
      <div class="w-full sm:w-auto mt-3 sm:mt-0 p-3">
        <div class="flex justify-between items-center">
          <div class="flex items-center" v-if="!addProductModalForm">
            <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="newprint">
              <PrinterIcon class="w-4 h-4 mr-2" /> Print
            </button>

            <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
              @click="newdownload(formDataOrder.orderNumber)">
              <FileTextIcon class="w-4 h-4 mr-2" /> Download PDF
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="flex pl-6 justify-end items-center">
        <div class="flex items-center mb-4">
          <div
            class="mr-2 mt-1 text-white rounded-2xl font-medium px-2 py-2 flex items-end justify-center w-full float-right whitespace-nowrap"
            :style="{
              'background-color': getStatusColor(formDataOrder.status),
            }"
          >
            Status : {{ getStatus(formDataOrder.status) }}
          </div>

          <div @click="updateStatus" class="text-success cursor-pointer">
            <CheckIcon class="w-8 h-8 mr-4 text-success" />
          </div>
        </div>
      </div> -->

      <div class="intro-y col-span-12 overflow-auto p-4">
        <div class="overflow-auto">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th class="whitespace-nowrap" v-for="(header, i) in productHeaders" :key="i">
                  {{ header.text }}
                </th>
                <th>MCODE</th>
                <th>Status</th>
                <th>Ordered Qty</th>
                <th>Dispatched Qty</th>
                <th>Barcode/Ean</th>
                <th>Retail Price</th>
                <th>Discount%</th>
                <th>Discount Price</th>
                <th>Gross Value</th>
                <th>VAT%</th>
                <th>VAT</th>
                <th>Total amount</th>

                <!-- <th>Address</th>
              <th>Date</th>
              <th>Time</th>
              <th>Booking#</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in orderLinesLocal" :key="i" class="intro-x">
                <td>
                  {{ item.no }}
                </td>
                <td v-for="(header, index) in productHeaders" :key="index">
                  {{ item[header.value] }}
                </td>
                <td>
                  {{ item.manufacturerCode ? item.manufacturerCode : "" }}
                </td>
                <td>
                  <div class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center" :style="{
                    'background-color': getOrderLineStatusColors(item.status),
                  }">
                    {{ item.status != null ? getOrderLineStatus(item.status) : "" }}
                  </div>
                </td>
                <td>
                  {{ item.orderedQuantity != null ? item.orderedQuantity : "" }}
                </td>
                <td>
                  {{ item.quantity != null ? item.quantity : "" }}
                </td>
                <td>
                  {{ item.ean ? item.ean : "" }}
                </td>
                <td>
                  {{ currencyFormat(item.price) }}
                </td>
                <td>
                  {{ item.discountPercentage ? item.discountPercentage : "" }}
                </td>
                <td>
                  {{ currencyFormat(item.discountedPrice) }}
                </td>
                <td>
                  {{ currencyFormat(item.grossValue) }}
                </td>
                <td>
                  {{ item.sku ? percentageFormat(item.sku.vatPercentage) : "" }}
                </td>
                <td>
                  {{ currencyFormat(item.vat) }}
                </td>
                <td>
                  {{ currencyFormat(calTotalValue(item.grossValue, item.vat)) }}
                  <!-- {{ currencyFormat(item.total) }} -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- BEGIN: Pagination -->
        <PaginationBarLocal @changePage="changePageProduct($event)" @pageSizeChange="pageSizeChangeProduct($event)"
          :itemsData="itemsData" :pageCount="pageCountProduct" :pageRange="pageRowCountProduct"
          :pageSize="pageSizeProduct" />

        <!-- END: Pagination -->
        <hr />

        <div class="sticky left-0 w-full" v-if="orderLines.length > 0">
          <div class="text-center sm:text-right sm:ml-auto">
            <div class="grid grid-cols-2 gap-x-0 gap-y-0.5">
              <div class="text-base font-medium text-slate-500">Subtotal Ex VAT</div>
              <div class="text-base font-medium text-primary">
                {{ currencyFormat(calTotalExVat()) }}
              </div>
              <div class="text-base font-medium text-slate-500">VAT</div>
              <div class="text-base font-medium text-primary">
                {{ currencyFormat(calTotalVat()) }}
              </div>
              <div class="text-base font-medium text-slate-500">Subtotal Inc VAT</div>
              <div class="text-base font-medium text-primary">
                {{ currencyFormat(calTotalIncVat()) }}
              </div>
              <div class="col-span-full">
                <hr />
              </div>
              <div class="text-base font-medium text-slate-500">Total</div>
              <div class="text-base font-medium text-primary">
                {{ currencyFormat(calTotal()) }}
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex items-start justify-between pt-12 px-10 pb-2">
          <div>Date Printed: {{ this.momentLocal(new Date().toLocaleString()) }}</div>
          <div>
            <span class="font-[600]">"Quality Service Is Our Business"</span><br />
            We Are Not Responsible For Any Goods Returned After 7 Days Of Sale
          </div>
        </div>

        <!-- <div class="flex items-end mt-4">
          <div class="text-center sm:text-right sm:ml-auto">
            <div class="text-base text-slate-500 font-medium">Total Amount</div>
            <div v-if="formDataOrder.amount != null" class="text-xl text-primary font-medium mt-2">
              {{ currencyFormat(formDataOrder.amount) }}
            </div>

          </div>
        </div> -->

        <!-- <div class="flex items-end mt-4">
          <div class="text-center sm:text-right sm:ml-auto">
            <a
              ref=""
              href="javascript:;"
              @click="submitOrder()"
              class="btn btn-primary shadow-md"
            >
              Submit Order
            </a>
          </div>
        </div> -->
      </div>

      <!-- END: Archive Data List -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Add Modal For Product-->
</template>

<script>
import { createVNode, render } from 'vue';
import Invoice from './components/Invoice.vue'
import axios from "../../../services/http-common";
import QrcodeVue from "qrcode.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import countriesJSON from "@/assets/json/countries.json";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import moment from "moment";
import { customerOrderStatus, customerOrderStatusColor } from "../../../global-functions/status";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import countryJson from "@/assets/json/countryAllData.json";

import store from "../../../store";

import validationMixins from "../../../mixins/validation";

export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Customer Invoices");
  },
  components: {
    Loading,
    autoTable,
  },
  data() {
    return {
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      countryList: countryJson,
      doc: null,
      const_txt: const_txt,
      searchData: "",
      isLoading: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      viewInfoModal: false,

      addCustomerOrder: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,

      deleteOrderLineModel: false,

      titleChange: -1,
      orderChange: -1,
      desTitleChange: -1,

      formDataOrder: {
        orderNumber: "",
        note: "",
        type: "",
        orderedDate: "",
        amount: 0,
        discountPercentage: 0,
        discountTotal: 0,
        status: "",
        deliveryDate: "",
        currency: "FJD",
        customerId: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
        deliveryAddress: {
          addressLine: "",
          postcode: "",
          state: "",
          city: "",
          country: "Fiji",
        },
        customer: {
          address: "",
          city: "",
          company: "",
          country: "",
          customerId: "",
          email: "",
          firstName: "",
          isBlackListed: "",
          isIndividualPersonal: "",
          lastName: "",
          mobile: "",
          postcode: "",
          state: "",
          telephone: "",
        },
        products: [],
      },
      formDataOrderDefault: {
        orderNumber: "",
        note: "",
        type: "",
        orderedDate: "",
        amount: 0,
        discountPercentage: 0,
        discountTotal: 0,
        status: "",
        deliveryDate: "",
        currency: "FJD",
        customerId: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
        deliveryAddress: {
          addressLine: "",
          postcode: "",
          state: "",
          city: "",
          country: "Fiji",
        },
        customer: {
          address: "",
          city: "",
          company: "",
          country: "",
          customerId: "",
          email: "",
          firstName: "",
          isBlackListed: "",
          isIndividualPersonal: "",
          lastName: "",
          mobile: "",
          postcode: "",
          state: "",
          telephone: "",
        },
        products: [],
      },

      headers: [
        { text: "Order Number", value: "orderNumber" },

        { text: "Status", value: "status" },

        { text: "Amount", value: "amount" },
        // { text: "Type", value: "type" },
        // { text: "Time", value: "time" },
        // { text: "Amount", value: "amount" },
        // { text: "Discount Percentage", value: "discountPercentage" },
        // { text: "Discount Total", value: "discountTotal" },
        // { text: "Status", value: "Status" },
      ],
      headersFilter: [
        { text: "Order Number", value: "orderNumber" },

        { text: "Status", value: "status" },

        { text: "Amount", value: "amount" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      //supplier

      skuList: [],

      //Product

      searchDataProduct: "",
      addProductModal: false,
      addProductModalForm: false,
      formDataOrdeLine: {
        skuId: "",
        quantity: "",
        status: "",
        orderNumber: "",
      },
      formDataOrdeLineDefault: {
        skuId: "",
        quantity: "",
        status: "",
        orderNumber: "",
      },
      paginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      defaultPaginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      productHeaders: [
        // { text: "ID", value: "orderLineId" },
        // { text: "QTY", value: "quantity" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "Description", value: "description" },

        //    { text: "Retail Price", value: "retailPrice" },
        //   { text: "Discounted ", value: "discountedPrice" },
        //  { text: "Reason", value: "discountedReason" },
        //    { text: "Status", value: "status" },
      ],
      productItems: [],
      productItemsData: [],

      products: [],
      orderNumber: "",
      orderLines: [],
      customers: [],
      warehouses: [],
      countries: [],

      uploadedfile: null,
      fileName: " ",

      status: [
        { value: 0, name: "DRFT" },
        { value: 1, name: "NEW" },
        { value: 2, name: "ACPT" },
        { value: 3, name: "PIKD" },
        { value: 4, name: "PRCS" },
        { value: 5, name: "COMP" },
        { value: 6, name: "DISP" },
        { value: 7, name: "DELV" },
        { value: 8, name: "RETN" },
      ],
      orderLineStatus: [
        { value: 1, name: "WAIT " },
        { value: 2, name: "PPKD " },
        { value: 3, name: "PIKD " },
        { value: 4, name: "PRCS" },
        { value: 5, name: "COMP" },
      ],

      addDespatchModal: false,
      formDataDespatch: {
        orderNumber: "",
        bookingNumber: "",
      },
      bookingNumbs: [],

      pageCountProduct: 0,
      pageRowCountProduct: 5,
      productStart: 0,
      productEnd: 5,
      orderLinesLocal: [],

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,

      statusItems: store.state.authUser.OrderStatuses,
      typeItems: store.state.authUser.OrderTypes,
      orderLineStatusItem: store.state.authUser.OrderLineStatuses,
      tin: store.state.authUser.licensee.acn,
      storeLicensee: store.state.authUser.licensee,
      storeLogo: store.state.authUser,
      baseurl: axios.defaults.baseURL.replace("/api/", "/"),
      userRole: store.state.authUser.userRole,

      line: "",
      postcode: "",
      state: "",
      city: "",
      country: "",
      date: "",
      orderedDate: "",
      bookingNom: "",
      btnDisabled: false,

      formDataUpdateStatus: {
        warehouse: store.state.authUser.warehouse.abbreviation,
        status: "",
        orderNumber: "",
      },
      formDataUpdateStatusDefault: {
        warehouse: store.state.authUser.warehouse.abbreviation,
        status: "",
        orderNumber: "",
      },

      statusOption: "CHANGE",
      splitData: false,
      allSelected: false,
      checkedData: [],

      policyAccess: "CustomerInvoices",
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer Order" : "Edit Customer Order";
    },
    desTitle() {
      return this.desTitleChange === -1 ? "Add Despatch " : "Edit Despatch ";
    },

    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
  },
  watch: {
    addProductModal(val) {
      val || this.addProductModalClose();
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    calVatPercentage(item) {
      let total = this.calTotalValue(item.grossValue, item.vat)
      if (total) {
        let percentage = (item.vat * 100) / total
        return percentage + "%"
      }
      else {
        return ""
      }

    },
    calTotalValue(grossValue, vat) {
      let _grossValue = grossValue ? grossValue : 0;
      let _vat = vat ? vat : 0;
      let total = parseFloat(_grossValue) + parseFloat(_vat);
      return total;
    },
    calTotalExVat() {
      return this.orderLines && this.orderLines.length > 0 ? this.orderLines.reduce((accumulator, currentObj) => accumulator + currentObj?.grossValue, 0) : "";
    },
    calTotalVat() {
      return this.orderLines && this.orderLines.length > 0 ? this.orderLines.reduce((accumulator, currentObj) => accumulator + currentObj?.vat, 0) : "";
    },
    calTotalIncVat() {
      return this.calTotalExVat() + this.calTotalVat();
    },
    calTotal() {
      return this.calTotalIncVat();
    },

    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    isDisabled(item) {
      if (item.status === 1) {
        return true;
      } else {
        return false;
      }
    },

    // newprint() {
    //   var doc = new jsPDF("p", "pt", "a4");
    //   // this.$refs.content.style.backgroundColor = '#ffffff';
    //   // document.querySelector(".statuscss").style.backgroundColor = '#ffffff'
    //   // document.querySelector(".statuscss").style.color = '#000000'

    //   // this.$refs.content.querySelector(".statuscss").style.backgroundColor = '#ffffff'
    //   // this.$refs.content.querySelector(".statuscss").style.color = '#000000'

    //   // document.querySelector(".statuscss").style.border = '2px solid #000000'
    //   // document.querySelector(".statuscss").style.borderRadius = '20px'
    //   // document.querySelector(".statuscss").style.padding = '20px'
    //   doc.html(this.$refs.content, {
    //     callback: function (doc) {
    //       doc.autoPrint();
    //       doc.output("dataurlnewwindow");
    //     },
    //     margin: [5, 5, 5, 5],
    //     x: 35,
    //     y: 0,
    //     html2canvas: {
    //       scale: 0.75,
    //       width: 1000,
    //     },
    //   });
    //   const columns = [
    //     { title: "PLU/SKU", dataKey: "skuName" },
    //     { title: "QTY", dataKey: "quantity" },
    //     { title: "Retail Price", dataKey: "retailPrice" },
    //     { title: "Discount Price", dataKey: "discountedPrice" },
    //     { title: "Description", dataKey: "description" },
    //     { title: "Status", dataKey: "status" },
    //   ];

    //   let data = [];
    //   let obj;

    //   this.orderLines.forEach((el) => {
    //     obj = {
    //       skuName: el.skuName,
    //       quantity: el.quantity,
    //       retailPrice: currencyFormat(el.retailPrice),
    //       discountedPrice: currencyFormat(el.discountedPrice),
    //       description: el.description,
    //       status: this.getOrderLineStatus(el.status),
    //     };
    //     data.push(obj);
    //   });

    //   autoTable(doc, {
    //     theme: "grid",
    //     columns,
    //     body: data,
    //     headerStyles: {
    //       fontSize: 7,
    //       fillColor: [22, 78, 99],
    //       lineWidth: 0.01,
    //       lineColor: [22, 78, 99],
    //     },
    //     styles: {
    //       fontSize: 7,
    //       lineWidth: 0.01,
    //       lineColor: [0, 0, 0],
    //     },
    //     startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 300,
    //     margin: { left: 40 },
    //   });

    //   const pageCount = doc.internal.getNumberOfPages();
    //   for (var z = 1; z <= pageCount; z++) {
    //     doc.setPage(z);
    //     // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

    //     const pageSize = doc.internal.pageSize;
    //     const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    //     const pageHeight = pageSize.height
    //       ? pageSize.height
    //       : pageSize.getHeight();
    //     // const header = 'Report 2014';
    //     const footer = `Page ${z} of ${pageCount}`;
    //     doc.setFontSize(10);
    //     doc.text(
    //       footer,
    //       pageWidth / 2 - doc.getTextWidth(footer) / 2,
    //       pageHeight - 5,
    //       { baseline: "bottom" }
    //     );
    //   }
    // },

    nullCheck(val, defaultVal) {
      return val ? (val === "null" ? defaultVal : val) : defaultVal;
    },

    calTotalPages() {
      if (this.orderLines.length > 0) {
        let pageCount = Math.ceil(this.orderLines.length / 28);
        return pageCount;
      } else {
        return 1;
      }
    },

    newprint() {

      const headers = [{
        text: "Qty", value: "quantity"
      }, {
        text: "PCode", value: "pCode"
      }, {
        text: "Manufacturer code", value: "manufacturerCode"
      }, {
        text: "Description", value: "description"
      }, {
        text: "Unit Price ex VAT", value: "retailPrice"
      }, {
        text: "Discount%", value: "discountPercentage"
      }, {
        text: "Gross value", value: "grossValue"
      }, {
        text: "VAT%", value: "vatPercentage"
      }, {
        text: "Total amount", value: "total"
      }]

      const items = this.orderLines.map((x) => ({
        quantity: x.quantity,
        pCode: x.pCode,
        manufacturerCode: x.manufacturerCode,
        description: x.description,
        retailPrice: this.currencyFormat(x.retailPrice),
        discountPercentage: this.percentageFormat(x.discountPercentage),
        grossValue: this.currencyFormat(x.grossValue),
        vatPercentage: this.percentageFormat(x.sku.vatPercentage),
        total: this.currencyFormat(x.retailPrice * x.quantity),
      }));

      const node = createVNode(Invoice, {
        headers, items,
        tin: this.tin,
        company: this.storeLicensee?.companyName || "PHARMATEC PTE LIMITED",
        companyAddress: this.storeLicensee?.address || "P.O.Box 450,Ba",
        companyMobile: this.storeLicensee?.mobile || "6674933/6674533",
        companyPhone: this.storeLicensee?.phoneNumber || "6670118",
        companyEmail: this.storeLicensee?.email || "admin@pharmatec.com.fj",
        customer: this.formDataOrder?.customer?.company || "",
        customerAddress: this.formDataOrder.customer.address
          ? this.formDataOrder.customer.address +
          "\n" +
          (this.formDataOrder.customer.city ? this.formDataOrder.customer.city : "") +
          (this.formDataOrder.customer.country ? ", " + this.formDataOrder.customer.country : "")
          : "",
        customerPhone: this.formDataOrder?.customer?.mobile + (this.formDataOrder?.customer?.telephone ? " / " + this.formDataOrder?.customer?.telephone : ""),
        customerEmail: this.formDataOrder?.customer?.email || "",
        salesRep: this.formDataOrder?.salesRep || "",
        customerOrderRef: this.formDataOrder?.customerOrderRef || "",
        invoiceNo: this.formDataOrder.invoiceNumber || "",
        invoiceDate: this.momentLocalWC(
          this.formDataOrder.invoiceDate),
        grossValue: this.formDataOrder.grossValue,
        vatTotal: this.formDataOrder.vatTotal,
        totalAmount: (Number(this.formDataOrder.grossValue) + Number(this.formDataOrder.vatTotal)).toFixed(2)
      });

      const container = document.createElement('div');
      render(node, container);
      const styles = require('!!raw-loader!./components/Invoice.css');

      var printWindow = window.open('', '', 'left=0,top=0,width=800,height=1000,toolbar=0,scrollbars=0,status=0');
      printWindow.document.write(
        "<!DOCTYPE html>" +
        "<html>" +
        " <head>" +
        '  <meta charset="utf-8" />' +
        "  <title>Receipt</title>" +
        "  <style> " + styles.default + " @media print { body { - webkit-print-color-adjust: exact !important; color-adjust: exact !important; print-color-adjust: exact !important;}}</style >" +
        " </head>" +
        ' <body>');
      printWindow.document.write(container.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();

      return;
      var doc = new jsPDF("p", "pt", "a4");

      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();

      doc
        .setFontSize(15)
        .text(10, 30, this.nullCheck(this.storeLicensee.companyName, "PHARMATEC PTE LIMITED"), {
          align: "left",
        })
        .setFont(undefined, "bold");
      doc.setFontSize(10).text(10, 50, this.nullCheck(this.storeLicensee.address, "P.O.Box 450,Ba"), {
        align: "left",
      });
      doc.setFontSize(10).text(10, 60, "Ph: " + this.nullCheck(this.storeLicensee.mobile, "6674933/6674533"), {
        align: "left",
      });
      doc.setFontSize(10).text(10, 70, "Fax: " + this.nullCheck(this.storeLicensee.phoneNumber, "6670118"), {
        align: "left",
      });
      doc.setFontSize(10).text(10, 80, "Email: " + this.nullCheck(this.storeLicensee.email, "admin@pharmatec.com.fj"), {
        align: "left",
      });

      doc.setFontSize(14).text(docWidth - 100, 30, "TAX INVOICE", {
        align: "left",
      });
      doc.rect(docWidth - 105, 15, 100, 20);

      doc.setFontSize(10).text(docWidth - 100, 50, "T.I.N: " + this.nullCheck(this.tin, ""), {
        align: "left",
      });

      doc
        .setFont(undefined, "bold")
        .setFontSize(8)
        .text(10, 95, "BILL TO", {
          align: "left",
        })
        .setFont(undefined, "normal");

      doc.setFontSize(10).text(12, 115, `${this.formDataOrder?.customer?.company ? this.formDataOrder?.customer?.company : ""}`, {
        align: "left",
        maxWidth: docWidth / 3 - 10,
      });

      doc
        .setFontSize(10)
        .text(
          12,
          125,
          `${this.formDataOrder.customer.address
            ? this.formDataOrder.customer.address +
            "\n" +
            // (this.formDataOrder.customer.state ? ", " + this.formDataOrder.customer.state : "") +
            (this.formDataOrder.customer.city ? this.formDataOrder.customer.city : "") +
            (this.formDataOrder.customer.country ? ", " + this.formDataOrder.customer.country : "")
            : "" +
            "\n" +
            (this.formDataOrder?.customer?.mobile
              ? "Phone:" + this.formDataOrder?.customer?.mobile + (this.formDataOrder?.customer?.telephone ? " / " + this.formDataOrder?.customer?.telephone : "")
              : "") +
            "\n" +
            (this.formDataOrder?.customer?.email ? "Email:" + this.formDataOrder?.customer?.email : "")
          }`,
          {
            align: "left",
            maxWidth: docWidth / 3 - 10,
          }
        );

      doc.rect(10, 100, docWidth / 3 - 5, 100);

      doc
        .setFontSize(10)
        .text(
          12 + docWidth / 3,
          115,
          `Sales Person: ${this.formDataOrder?.salesRep ? this.formDataOrder?.salesRep : ""} ${""}\nCust Order Ref: ${this.formDataOrder?.customerOrderRef ? this.formDataOrder?.customerOrderRef : ""
          }`,
          {
            align: "left",
            maxWidth: docWidth / 3 - 10,
          }
        );
      doc.rect(10 + docWidth / 3, 100, docWidth / 3 - 5, 100);

      doc
        .setFontSize(10)
        .text(
          12 + docWidth / 3 + docWidth / 3,
          115,
          `Invoice No: ${this.formDataOrder.invoiceNumber ? this.formDataOrder.invoiceNumber : ""}\nDate: ${this.momentLocalWC(
            this.formDataOrder.invoiceDate
          )}\n\n\n\n\n\nPage No:${this.calTotalPages()}`,
          {
            align: "left",
            maxWidth: docWidth / 3 - 10,
          }
        );
      doc.rect(10 + docWidth / 3 + docWidth / 3, 100, docWidth / 3 - 20, 100);

      const columns = [
        { title: "Qty", dataKey: "quantity" },
        { title: "PCode", dataKey: "pCode" },
        { title: "Manufacturer code", dataKey: "manufacturerCode" },
        { title: "Description", dataKey: "description" },
        { title: "Unit Price ex VAT", dataKey: "retailPrice" },
        { title: "Discount%", dataKey: "discountPercentage" },
        { title: "Gross value", dataKey: "grossValue" },
        { title: "VAT", dataKey: "vat" },
        { title: "Total amount", dataKey: "total" },

        // { title: "STATUS", dataKey: "status" },
      ];

      let data = [];
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          quantity: el.quantity,
          pCode: el.pCode,
          manufacturerCode: el.manufacturerCode,
          description: el.description,
          retailPrice: this.currencyFormat(el.retailPrice),
          discountPercentage: this.percentageFormat(el.discountPercentage),
          grossValue: this.currencyFormat(el.grossValue),
          vat: this.currencyFormat(el.vat),
          total: this.currencyFormat(el.retailPrice * el.quantity),
        };
        data.push(obj);
      });

      autoTable(doc, {
        theme: "grid",
        columns,
        body: data,
        headStyles: {
          fontSize: 10,
          fillColor: [255, 255, 255],
          textColor: 0,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 220,
        margin: { left: 10, right: 10 },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        doc.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;

        if (pageCount === z) {
          let _totalAmount = (Number(this.formDataOrder.grossValue) + Number(this.formDataOrder.vatTotal)).toFixed(2);
          autoTable(doc, {
            theme: "grid",
            showHead: "never",
            head: [["header1", "header2", "header3"]],
            body: [
              [
                { content: "", rowSpan: 4 },
                "Subtotal Ex VAT\nVAT\nSubtotal Inc VAT\nTotal",
                `$${this.formDataOrder.grossValue}\n$${this.formDataOrder.vatTotal}\n$${_totalAmount}\n$${_totalAmount}`,
              ],
            ],
            columnStyles: {
              0: { cellWidth: 300 },
            },
            styles: {
              fontSize: 8,
              lineWidth: 0.01,
              lineColor: [0, 0, 0],
              halign: "right",
            },
            startY: pageHeight - 100,
            margin: { left: 20 },
          });
        }
        doc.setFontSize(8);
        doc.text(
          `"Quality Service is our Business"\nWe Are Not Responsible For Any Goods Returned After 7 Days Of Sale`,
          pageWidth / 2 - doc.getTextWidth(footer) / 2,
          pageHeight - 30,
          { baseline: "bottom" }
        );

        var generateDate = new Date().toLocaleString();

        doc.setFontSize(8).text(10, pageHeight - 30, `Date Printed: ${this.momentLocal(generateDate)}`, {
          align: "left",
          maxWidth: docWidth / 3 - 10,
        });
      }

      doc.autoPrint();
      doc.output("dataurlnewwindow");
    },
    // newdownload(orderNumber) {
    //   var generateDate = new Date().toLocaleString();
    //   var doc = new jsPDF("p", "pt", "a4");
    //   doc.html(this.$refs.content, {
    //     callback: function (doc) {
    //       doc.save(`${orderNumber + "_" + generateDate}.pdf`);
    //     },
    //     margin: [5, 5, 5, 5],
    //     x: 35,
    //     y: 0,
    //     html2canvas: {
    //       scale: 0.75,
    //       width: 1000,
    //     },
    //   });
    //   const columns = [
    //   { title: "Qty", dataKey: "quantity" },
    //     { title: "PCode", dataKey: "skuName" },
    //     { title: "Manufacturer code", dataKey: "mcode" },
    //     { title: "Description", dataKey: "description" },
    //     { title: "Unit Price ex VAT", dataKey: "retailPrice" },
    //     { title: "Discount", dataKey: "discountedPrice" },
    //     { title: "Gross value", dataKey: "gross" },
    //     { title: "VAT", dataKey: "vat" },
    //     { title: "Total amount", dataKey: "total" },

    //     // { title: "STATUS", dataKey: "status" },
    //   ];

    //   let data = [];
    //   let obj;

    //   this.orderLines.forEach((el) => {
    //     obj = {
    //       skuName: el.skuName,
    //       quantity: el.quantity,
    //       retailPrice: currencyFormat(el.retailPrice),
    //       discountedPrice: currencyFormat(el.discountedPrice),
    //       description: el.description,
    //       status: this.getOrderLineStatus(el.status),
    //     };
    //     data.push(obj);
    //   });

    //   autoTable(doc, {
    //     theme: "grid",
    //     columns,
    //     body: data,
    //     headerStyles: {
    //       fontSize: 7,
    //       fillColor: [22, 78, 99],
    //       lineWidth: 0.01,
    //       lineColor: [22, 78, 99],
    //     },
    //     styles: {
    //       fontSize: 7,
    //       lineWidth: 0.01,
    //       lineColor: [0, 0, 0],
    //     },
    //     showHead: "firstPage",
    //     startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 300,
    //     margin: { left: 40 },
    //   });

    //   const pageCount = doc.internal.getNumberOfPages();
    //   for (var z = 1; z <= pageCount; z++) {
    //     doc.setPage(z);
    //     // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

    //     const pageSize = doc.internal.pageSize;
    //     const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    //     const pageHeight = pageSize.height
    //       ? pageSize.height
    //       : pageSize.getHeight();
    //     // const header = 'Report 2014';
    //     const footer = `Page ${z} of ${pageCount}`;
    //     doc.setFontSize(10);
    //     doc.text(
    //       footer,
    //       pageWidth / 2 - doc.getTextWidth(footer) / 2,
    //       pageHeight - 5,
    //       { baseline: "bottom" }
    //     );
    //   }
    // },

    newdownload() {
      const headers = [{
        text: "Qty", value: "quantity"
      }, {
        text: "PCode", value: "pCode"
      }, {
        text: "Manufacturer code", value: "manufacturerCode"
      }, {
        text: "Description", value: "description"
      }, {
        text: "Unit Price ex VAT", value: "retailPrice"
      }, {
        text: "Discount%", value: "discountPercentage"
      }, {
        text: "Gross value", value: "grossValue"
      }, {
        text: "VAT%", value: "vatPercentage"
      }, {
        text: "Total amount", value: "total"
      }]

      const items = this.orderLines.map((x) => ({
        quantity: x.quantity,
        pCode: x.pCode,
        manufacturerCode: x.manufacturerCode,
        description: x.description,
        retailPrice: this.currencyFormat(x.retailPrice),
        discountPercentage: this.percentageFormat(x.discountPercentage),
        grossValue: this.currencyFormat(x.grossValue),
        vatPercentage: this.percentageFormat(x.sku.vatPercentage),
        total: this.currencyFormat(x.retailPrice * x.quantity),
      }));

      const node = createVNode(Invoice, {
        headers, items,
        tin: this.tin,
        company: this.storeLicensee?.companyName || "PHARMATEC PTE LIMITED",
        companyAddress: this.storeLicensee?.address || "P.O.Box 450,Ba",
        companyMobile: this.storeLicensee?.mobile || "6674933/6674533",
        companyPhone: this.storeLicensee?.phoneNumber || "6670118",
        companyEmail: this.storeLicensee?.email || "admin@pharmatec.com.fj",
        customer: this.formDataOrder?.customer?.company || "",
        customerAddress: this.formDataOrder.customer.address
          ? this.formDataOrder.customer.address +
          "\n" +
          (this.formDataOrder.customer.city ? this.formDataOrder.customer.city : "") +
          (this.formDataOrder.customer.country ? ", " + this.formDataOrder.customer.country : "")
          : "",
        customerPhone: this.formDataOrder?.customer?.mobile + (this.formDataOrder?.customer?.telephone ? " / " + this.formDataOrder?.customer?.telephone : ""),
        customerEmail: this.formDataOrder?.customer?.email || "",
        salesRep: this.formDataOrder?.salesRep || "",
        customerOrderRef: this.formDataOrder?.customerOrderRef || "",
        invoiceNo: this.formDataOrder.invoiceNumber || "",
        invoiceDate: this.momentLocalWC(
          this.formDataOrder.invoiceDate),
        grossValue: this.formDataOrder.grossValue,
        vatTotal: this.formDataOrder.vatTotal,
        totalAmount: (Number(this.formDataOrder.grossValue) + Number(this.formDataOrder.vatTotal)).toFixed(2)
      });

      const invoiceNumber = this.formDataOrder.invoiceNumber;

      const container = document.createElement('div');
      render(node, container);
      const styles = require('!!raw-loader!./components/Invoice.css');

      let html = "<!DOCTYPE html>" +
        "<html>" +
        " <head>" +
        '  <meta charset="utf-8" />' +
        "  <title>Receipt</title>" +
        "  <style> " + styles.default + " @media print { body { - webkit-print-color-adjust: exact !important; color-adjust: exact !important; print-color-adjust: exact !important;}}</style >" +
        " </head>" +
        ' <body>';

      html += container.innerHTML;
      html += '</body></html>';

      var doc = new jsPDF();
      doc.html(html, {
        x: 0,
        y: 0,
        width: 190,
        windowWidth: 675,
        margin: [10, 10, 10, 10],
        callback: function (doc) {
          doc.save(`Invoice_${invoiceNumber}.pdf`);
        }
      });

      return;
      var doc = new jsPDF("p", "pt", "a4");
      var generateDate = new Date().toLocaleString();
      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();

      doc
        .setFontSize(15)
        .text(10, 30, this.nullCheck(this.storeLicensee.companyName, "PHARMATEC PTE LIMITED"), {
          align: "left",
        })
        .setFont(undefined, "bold");
      doc.setFontSize(10).text(10, 50, this.nullCheck(this.storeLicensee.address, "P.O.Box 450,Ba"), {
        align: "left",
      });
      doc.setFontSize(10).text(10, 60, "Ph: " + this.nullCheck(this.storeLicensee.mobile, "6674933/6674533"), {
        align: "left",
      });
      doc.setFontSize(10).text(10, 70, "Fax: " + this.nullCheck(this.storeLicensee.phoneNumber, "6670118"), {
        align: "left",
      });
      doc.setFontSize(10).text(10, 80, "Email: " + this.nullCheck(this.storeLicensee.email, "admin@pharmatec.com.fj"), {
        align: "left",
      });

      doc.setFontSize(14).text(docWidth - 100, 30, "TAX INVOICE", {
        align: "left",
      });
      doc.rect(docWidth - 105, 15, 100, 20);

      doc.setFontSize(10).text(docWidth - 100, 50, "T.I.N: " + this.nullCheck(this.tin, ""), {
        align: "left",
      });


      doc
        .setFont(undefined, "bold")
        .setFontSize(8)
        .text(10, 95, "BILL TO", {
          align: "left",
        })
        .setFont(undefined, "normal");

      doc.setFontSize(10).text(12, 115, `${this.formDataOrder?.customer?.company ? this.formDataOrder?.customer?.company : ""}`, {
        align: "left",
        maxWidth: docWidth / 3 - 10,
      });

      doc
        .setFontSize(10)
        .text(
          12,
          125,
          `${this.formDataOrder.customer.address
            ? this.formDataOrder.customer.address +
            "\n" +
            // (this.formDataOrder.customer.state ? ", " + this.formDataOrder.customer.state : "") +
            (this.formDataOrder.customer.city ? this.formDataOrder.customer.city : "") +
            (this.formDataOrder.customer.country ? ", " + this.formDataOrder.customer.country : "")
            : "" +
            "\n" +
            (this.formDataOrder?.customer?.mobile
              ? "Phone:" + this.formDataOrder?.customer?.mobile + (this.formDataOrder?.customer?.telephone ? " / " + this.formDataOrder?.customer?.telephone : "")
              : "") +
            "\n" +
            (this.formDataOrder?.customer?.email ? "Email:" + this.formDataOrder?.customer?.email : "")
          }`,
          {
            align: "left",
            maxWidth: docWidth / 3 - 10,
          }
        );

      doc.rect(10, 100, docWidth / 3 - 5, 100);

      doc
        .setFontSize(10)
        .text(
          12 + docWidth / 3,
          115,
          `Sales Person: ${this.formDataOrder?.salesRep ? this.formDataOrder?.salesRep : ""} ${""}\nCust Order Ref: ${this.formDataOrder?.customerOrderRef ? this.formDataOrder?.customerOrderRef : ""
          }`,
          {
            align: "left",
            maxWidth: docWidth / 3 - 10,
          }
        );
      doc.rect(10 + docWidth / 3, 100, docWidth / 3 - 5, 100);

      doc
        .setFontSize(10)
        .text(
          12 + docWidth / 3 + docWidth / 3,
          115,
          `Invoice No: ${this.formDataOrder.invoiceNumber ? this.formDataOrder.invoiceNumber : ""}\nDate: ${this.momentLocalWC(
            this.formDataOrder.invoiceDate
          )}\n\n\n\n\n\nPage No:${this.calTotalPages()}`,
          {
            align: "left",
            maxWidth: docWidth / 3 - 10,
          }
        );
      doc.rect(10 + docWidth / 3 + docWidth / 3, 100, docWidth / 3 - 20, 100);

      const columns = [
        { title: "Qty", dataKey: "quantity" },
        { title: "PCode", dataKey: "pCode" },
        { title: "Manufacturer code", dataKey: "manufacturerCode" },
        { title: "Description", dataKey: "description" },
        { title: "Unit Price ex VAT", dataKey: "retailPrice" },
        { title: "Discount%", dataKey: "discountPercentage" },
        { title: "Gross value", dataKey: "grossValue" },
        { title: "VAT", dataKey: "vat" },
        { title: "Total amount", dataKey: "total" },

        // { title: "STATUS", dataKey: "status" },
      ];

      let data = [];
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          quantity: el.quantity,
          pCode: el.pCode,
          manufacturerCode: el.manufacturerCode,
          description: el.description,
          retailPrice: this.currencyFormat(el.retailPrice),
          discountPercentage: this.percentageFormat(el.discountPercentage),
          grossValue: this.currencyFormat(el.grossValue),
          vat: this.currencyFormat(el.vat),
          total: this.currencyFormat(el.retailPrice * el.quantity),
        };
        data.push(obj);
      });

      autoTable(doc, {
        theme: "grid",
        columns,
        body: data,
        headStyles: {
          fontSize: 10,
          fillColor: [255, 255, 255],
          textColor: 0,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        styles: {
          fontSize: 10,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 220,
        margin: { left: 10, right: 10 },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        doc.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;

        if (pageCount === z) {
          let _totalAmount = (Number(this.formDataOrder.grossValue) + Number(this.formDataOrder.vatTotal)).toFixed(2);
          autoTable(doc, {
            theme: "grid",
            showHead: "never",
            head: [["header1", "header2", "header3"]],
            body: [
              [
                { content: "", rowSpan: 4 },
                "Subtotal Ex VAT\nVAT\nSubtotal Inc VAT\nTotal",
                `$${this.formDataOrder.grossValue}\n$${this.formDataOrder.vatTotal}\n$${_totalAmount}\n$${_totalAmount}`,
              ],
            ],
            columnStyles: {
              0: { cellWidth: 300 },
            },
            styles: {
              fontSize: 8,
              lineWidth: 0.01,
              lineColor: [0, 0, 0],
              halign: "right",
            },
            startY: pageHeight - 100,
            margin: { left: 20 },
          });
        }
        doc.setFontSize(8);
        doc.text(
          `"Quality Service is our Business"\nWe Are Not Responsible For Any Goods Returned After 7 Days Of Sale`,
          pageWidth / 2 - doc.getTextWidth(footer) / 2,
          pageHeight - 30,
          { baseline: "bottom" }
        );

        var generateDate = new Date().toLocaleString();

        doc.setFontSize(8).text(10, pageHeight - 30, `Date Printed: ${this.momentLocal(generateDate)}`, {
          align: "left",
          maxWidth: docWidth / 3 - 10,
        });
      }

      doc.save(`${this.formDataOrder?.orderNumber ? this.formDataOrder.orderNumber : "ON" + "_" + generateDate}.pdf`);
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },

    getStatus(value) {
      try {
        return this.statusItems.find((x) => x.number == value).displayName;
      } catch (e) {
        return "";
      }
    },
    getStatusColor(value) {
      try {
        return this.statusItems.find((x) => x.number == value).color;
      } catch (e) {
        return "";
      }
    },
    getType(value) {
      try {
        return this.typeItems.find((type) => type.number === value).name;
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getOrderLineStatus(value) {
      try {
        return this.orderLineStatusItem.find((x) => x.number === value).displayName;
      } catch (e) {
        return "";
      }
    },
    getOrderLineStatusColors(value) {
      try {
        return this.orderLineStatusItem.find((x) => x.number === value).color;
      } catch (e) {
        return "";
      }
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Invoice Number.", dataKey: "invoiceNumber" },
              { title: "Order Number", dataKey: "orderNumber" },
              { title: "Amount", dataKey: "totalAmount" },
              { title: "Order Created", dataKey: "orderedDate" },
              { title: "Company", dataKey: "company" },
              { title: "Customer", dataKey: "customer" },
              { title: "Delivery Address", dataKey: "deliveryAddress" },
              { title: "Sales Rep", dataKey: "salesRep" },
              { title: "Ship Via", dataKey: "shipVia" },
              { title: "Booking No", dataKey: "bookingNo" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;
            removeNullValueObjectArray(items);
            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                invoiceNumber: el.invoiceNumber,
                orderNumber: el.orderNumber,
                amount: el.totalAmount,
                orderedDate: el.orderedDate,
                company: el.customer.company,
                customer: el.customer.firstName + " " + el.customer.lastName,
                deliveryAddress:
                  el.deliveryAddress.addressId +
                  " " +
                  el.deliveryAddress.line +
                  " " +
                  el.deliveryAddress.postcode +
                  " " +
                  el.deliveryAddress.state +
                  " " +
                  el.deliveryAddress.city +
                  " " +
                  el.deliveryAddress.country,
                salesRep: el.salesRep,
                shipVia: el.shipVia,
                bookingNo: el.despatch.bookingNumber,
              };
              //i++;
              data.push(obj);
              //   console.log(obj);
            });
            //console.log("DATA", data);

            // var imgData = "/src/assets/images/pl_logo2.png";
            // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
            // this.docs
            //   .setFontSize(16)
            //   .text("Storage Bin", 5, 1.2);

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 300,
              margin: { left: 40 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 5, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Customer Sales " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Customer Sales " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Invoice Number",
          2: "Order Number",
          3: "Amount",
          4: "Order Created",
          5: "Company",
          6: "Customer",
          7: "Delivery Address",
          8: "Sales Rep",
          9: "Ship Via",
          10: "Booking Number",
        };

        items.forEach((item) => {
          console.log(item);
          csvItems.push({
            1: item.invoiceNumber,
            2: item.orderNumber,
            3: item.totalAmount,
            4: this.momentLocalCSV(item.orderedDate),
            5: item.customer.company + " ",
            6: item.customer.firstName + " " + item.customer.lastName,
            7:
              item.deliveryAddress.addressId +
              " " +
              item.deliveryAddress.line +
              " " +
              item.deliveryAddress.postcode +
              " " +
              item.deliveryAddress.state +
              " " +
              item.deliveryAddress.city +
              " " +
              item.deliveryAddress.country,
            8: item.salesRep,
            9: item.shipVia,
            10: item.despatch.bookingNumber,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },
    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post(`CustomerInvoices/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },
    customerOrderStatus(status) {
      return customerOrderStatus(status);
    },
    customerOrderStatusColor(status) {
      return customerOrderStatusColor(status);
    },

    getToday() {
      const today = new Date();

      this.formDataOrder.orderedDate = moment(today).format("DD MMMM, YYYY");
    },
    setReport() {
      const columns = [
        { title: "PLU/SKU", dataKey: "skuName" },
        { title: "QTY", dataKey: "quantity" },
        { title: "Retail Price", dataKey: "retailPrice" },
        { title: "Discount Price", dataKey: "discountedPrice" },
        { title: "Reason", dataKey: "discountedReason" },
        { title: "Status", dataKey: "status" },
      ];
      this.doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });

      let data = [];
      let i = 0;
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          skuName: el.skuName,
          quantity: el.quantity,
          retailPrice: el.retailPrice,
          discountedPrice: el.discountedPrice,
          discountedReason: el.discountedReason,
          status: this.getOrderLineStatus(el.status),
        };
        //i++;
        data.push(obj);
        //   console.log(obj);
      });
      //console.log("DATA", data);

      var imgData = "/src/assets/images/pl_logo2.png";
      this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
      this.doc.setFontSize(16).text("Order Number : " + this.formDataOrder.orderNumber, 5, 1.2);

      this.doc.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

      this.doc.setFontSize(14).text("Deliver To :", 0.5, 2.5);

      this.doc.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
      this.doc.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
      this.doc.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
      this.doc.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
      this.doc.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
      this.doc.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
      this.doc.setFontSize(12).text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

      var fname = this.formDataOrder.customer.firstName;
      var mobile = this.formDataOrder.customer.mobile;

      var lname = this.formDataOrder.customer.lastName;
      var company = this.formDataOrder.customer.company;
      var email = this.formDataOrder.customer.email;
      var orderedDate = this.momentLocal(this.formDataOrder.orderedDate);
      var amount = this.currencyFormat(this.formDataOrder.amount);
      var despatchBookingNumber = this.formDataOrder.despatchBookingNumber;

      if (!fname) {
        fname = "";
      }
      if (!lname) {
        lname = "";
      }
      if (!company) {
        company = "";
      }
      if (!mobile) {
        mobile = "";
      }
      if (!email) {
        email = "";
      }
      if (!orderedDate) {
        orderedDate = "";
      }
      if (!amount) {
        amount = "";
      }
      if (!despatchBookingNumber) {
        despatchBookingNumber = "";
      }

      //trading partner
      this.doc.setFontSize(14).text("Trading Partner :", 5, 2.5);

      this.doc.setFontSize(12).text(fname + " " + lname, 5.2, 2.7);
      this.doc.setFontSize(12).text(company, 5.2, 2.9);
      this.doc.setFontSize(12).text(mobile, 5.2, 3.1);

      this.doc.setFontSize(12).text(email, 5.2, 3.3);

      this.doc.setFontSize(12).text("Date: " + orderedDate, 5.2, 3.5);

      this.doc.setFontSize(12).text("Total : " + amount, 5.2, 3.7);

      this.doc.setFontSize(12).text("Booking No: " + despatchBookingNumber, 5.2, 3.9);

      autoTable(this.doc, {
        theme: "grid",
        columns,
        body: data,
        headerStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        showHead: "firstPage",
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 300,
        margin: { left: 40 },
      });
    },

    onPrint() {
      this.setReport();
      this.doc.autoPrint();
      this.doc.output("dataurlnewwindow");
    },

    generateReport() {
      this.setReport();
      var generateDate = new Date().toLocaleString();
      this.doc.save(`${generateDate}.pdf`);
    },

    addProductModalClose() {
      this.isLoading = true;

      for (var i = 0; i < this.items.length; i++) {
        this.items[i].selected = false;
        this.isLoading = false;

        this.addProductModal = false;
      }
    },
    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWC(date) {
      return moment.utc(date).local().format("DD MMMM YY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    viewItemInfo(item) {
      removeNullValue(item);

      this.formDataOrder = Object.assign({}, item);
      this.viewInfoModal = true;
    },
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;

      this.getDataFromApi();
    },
    setDefault() {
      this.formDataOrder = Object.assign({}, this.formDataOrderDefault);
      this.getToday();
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(`CustomerInvoices/${this.warehouseAbbreviation}/Paginated`, this.pagination)
        // .post("CustomerOrders/Paginated/" + this.warehouseAbbreviation, this.pagination)

        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //Product
    pageSizeChangeProduct() {
      // this.pagination.pageSize = this.pageSize;
      // this.getDataFromApiProduct();
    },
    changePageProduct(pageNum) {
      this.pageNumber = pageNum;

      this.productEnd = this.pageRowCountProduct * pageNum;

      this.productStart = this.productEnd - this.pageRowCountProduct;

      this.orderLinesLocal = this.orderLines.slice(this.productStart, this.productEnd);
      console.log(this.orderLinesLocal);
      // this.getDataFromApiProduct();
    },
    searchOnchangeProduct() {
      if (this.searchDataProduct != "") {
        this.searchActiveProduct = true;
        this.searchItemProduct();
      } else {
        this.clearSearchProduct();
      }
    },
    clearSearchProduct() {
      this.pagination.pageNumber = 1;
      this.searchDataProduct = "";
      this.pagination.searchProduct = this.searchDataProduct;
      //    this.getDataFromApiProduct();
      this.searchActiveProduct = false;
    },
    searchItemProduct() {
      if (this.searchDataProduct != "") {
        this.searchActiveProduct = true;
        this.pagination.pageNumber = 1;
        this.pagination.searchProduct = this.searchDataProduct;
        //      this.getDataFromApiProduct();
      }
    },
    setDefaultProduct() {
      this.formDataOrdeLine = Object.assign({}, this.formDataOrdeLineDefault);
      this.formDataOrdeLine.orderNumber = this.formDataOrder.orderNumber;
    },

    //create orderLine
    addItem(item) {
      this.formDataOrder = Object.assign({}, item);
      this.addProductModal = true;
      this.invoiceNumber = item.invoiceNumber;
      if (item.deliveryAddress) {
        this.line = item.deliveryAddress.line;
        this.postcode = item.deliveryAddress.postcode;
        this.state = item.deliveryAddress.state;
        this.city = item.deliveryAddress.city;
        this.country = item.deliveryAddress.country;
      }

      var dateAndTime = item.time;
      this.date = moment.utc(dateAndTime).local().format("DD MMMM, YYYY");
      this.time = moment.utc(dateAndTime).local().format("h:mm:ss a");

      this.bookingNom = item.despatchBookingNumber;

      this.getOrderLines();
      this.setDefaultProduct();
      this.formDataUpdateStatus = Object.assign({}, this.formDataUpdateStatusDefault);
    },
    getOrderLines() {
      this.isLoading = true;
      axios
        .post(`CustomerInvoices/${this.warehouseAbbreviation}/${this.invoiceNumber}/Lines`, this.pagination)
        .then((response) => {
          this.orderLines = response.data.data;

          for (var i = 0; i < this.orderLines.length; i++) {
            this.orderLines[i].no = i + 1;
            this.isLoading = false;
          }

          var pageCount = this.orderLines.length / this.pageRowCountProduct;
          let pageCountRound = Math.round(pageCount);
          if (pageCount > pageCountRound) {
            pageCountRound += 1;
            this.pageCountProduct = pageCountRound;
          } else {
            this.pageCountProduct = pageCountRound;
          }

          //pageCountProduct
          this.orderLinesLocal = this.orderLines.slice(this.productStart, this.productEnd);

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white";
    },
  },
};
</script>
