<template>
  <LoadingScreen :isLoading="isLoading" />
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] -mt-7 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700">
    <div class="flex items-center h-full -ml-5">
      <!-- BEGIN: Logo -->
      <a v-if="userRole != 'SuperUser'" @click="showImageModal"
        class="logo -intro-x md:flex w-[200px] block bg-white rounded-lg bg-cover flex justify-center items-center">
        <img alt="Logo" class="p-2 logo__image"
          style="width: 200px; height: 60px; border-radius: 0px; object-fit: contain"
          :src="`${baseurl}${store.state.authUser.licensee.logo}`" />
        <!-- <span class="ml-3 text-lg text-white logo__text"> Pharmatec </span> -->
      </a>

      <a v-if="userRole == 'SuperUser'" @click="showImageModal"
        class="logo -intro-x md:flex w-[180px] block bg-white rounded-lg bg-cover">
        <img alt="Logo" class="p-1 logo__image"
          style="width: 180px; height: 60px; border-radius: 5px; object-fit: contain"
          :src="`${baseurl}${store.state.authUser.softClientLogo}`" />
        <!-- <span class="ml-3 text-lg text-white logo__text"> Pharmatec </span> -->
      </a>

      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <nav aria-label="breadcrumb" class="-intro-x h-[45px] mr-auto">
        <!-- <ol class="breadcrumb breadcrumb-light"> 
          <li class="breadcrumb-item"><a href="#">Application</a></li>
          <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol> -->

        <ol class="breadcrumb breadcrumb-light">
          <li class="ml-2 text-xl text-white breadcrumb-item">
            {{ store.state.menuTitle.subMenuTitle }}
          </li>
        </ol>
      </nav>
      <!-- END: Breadcrumb -->
      <!-- BEGIN: Search -->
      <!-- <div class="relative mr-3 intro-x sm:mr-6">
        <div class="hidden search sm:block">
          <input
            type="text"
            class="border-transparent search__input form-control"
            placeholder="Search..."
            @focus="showSearchDropdown"
            @blur="hideSearchDropdown"
          />
          <SearchIcon class="search__icon dark:text-slate-500" />
        </div>
        <a class="notification notification--light sm:hidden" href="">
          <SearchIcon class="notification__icon dark:text-slate-500" />
        </a>
        <div class="search-result" :class="{ show: searchDropdown }">
          <div class="search-result__content">
            <div class="search-result__content__title">Pages</div>
            <div class="mb-5">
              <a href="" class="flex items-center">
                <div
                  class="flex items-center justify-center w-8 h-8 rounded-full bg-success/20 dark:bg-success/10 text-success"
                >
                  <InboxIcon class="w-4 h-4" />
                </div>
                <div class="ml-3">Mail Settings</div>
              </a>
              <a href="" class="flex items-center mt-2">
                <div
                  class="flex items-center justify-center w-8 h-8 rounded-full bg-pending/10 text-pending"
                >
                  <UsersIcon class="w-4 h-4" />
                </div>
                <div class="ml-3">Users & Permissions</div>
              </a>
              <a href="" class="flex items-center mt-2">
                <div
                  class="flex items-center justify-center w-8 h-8 rounded-full bg-primary/10 dark:bg-primary/20 text-primary/80"
                >
                  <CreditCardIcon class="w-4 h-4" />
                </div>
                <div class="ml-3">Transactions Report</div>
              </a>
            </div>
            <div class="search-result__content__title">Users</div>
            <div class="mb-5">
              <a
                v-for="(faker, fakerKey) in $_.take($f(), 4)"
                :key="fakerKey"
                href
                class="flex items-center mt-2"
              >
                <div class="w-8 h-8 image-fit">
                  <img
                    alt="Enigma Tailwind HTML Admin Template"
                    class="rounded-full"
                    :src="faker.photos[0]"
                  />
                </div>
                <div class="ml-3">{{ faker.users[0].name }}</div>
                <div
                  class="w-48 ml-auto text-xs text-right truncate text-slate-500"
                >
                  {{ faker.users[0].email }}
                </div>
              </a>
            </div>
            <div class="search-result__content__title">Products</div>
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt="Enigma Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="faker.images[0]"
                />
              </div>
              <div class="ml-3">{{ faker.products[0].name }}</div>
              <div
                class="w-48 ml-auto text-xs text-right truncate text-slate-500"
              >
                {{ faker.products[0].category }}
              </div>
            </a>
          </div>
        </div>
      </div> -->
      <!-- END: Search -->
      <!-- BEGIN: Notifications -->

      <Dropdown class="mr-4 intro-x sm:mr-6" v-if="userRole == 'SuperUser'">
        <DropdownToggle tag="div" role="button" class="flex text-white cursor-pointer">
          <div class="flex -mr-4">
            <span class="mr-2 text-md"> Switch</span>
            <span class="-mt-1 text-lg font-semibold">
              {{ store.state.authUser.licensee.companyName }}
            </span>
          </div>

          <ArrowLeftRightIcon class="w-16 dark:text-slate-500" />
        </DropdownToggle>
        <DropdownMenu class="pt-2 notification-content">
          <DropdownContent tag="div" class="notification-content__box">
            <div class="notification-content__title">Switch Licensee</div>

            <!-- <div
              class="grid justify-center grid-cols-3 gap-4"
              v-if="userRole != 'SuperUser'"
            >
              <div
                v-for="(item, i) in warehouses"
                :key="i"
                @click="switchWarehouse(item)"
                class="p-2 text-center text-white rounded-md cursor-pointer bg-primary"
              >
                {{ item.abbreviation }}
              </div>
            </div> -->
            <div class="flex flex-col items-center justify-center space-y-2">
              <div class="grid justify-center grid-cols-3 gap-4">
                <div v-for="(item, i) in defaultlicensees" :key="i" @click="switchLicensee(item)"
                  class="p-2 text-center text-white rounded-md cursor-pointer bg-primary">
                  {{ item.companyName }}
                </div>
              </div>

              <!-- <div class="flex items-center justify-center space-x-2">
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Back"
                  :options="{
                    zIndex: 99999,
                  }"
                >
                  <button @click="prevLPage" v-if="hasLPrev == true">
                    <ArrowBigLeftIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
                  </button>
                </Tippy>
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Next"
                  :options="{
                    zIndex: 99999,
                  }"
                >
                  <button @click="nextLPage" v-if="hasLNext == true">
                    <ArrowBigRightIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
                  </button>
                </Tippy>
              </div> -->
            </div>

            <!-- <div class="flex flex-col">
              <v-select
                v-model="warehouse"
                placeholder="SELECT"
                :options="warehouses"
                label="abbreviation"
              />
            </div> -->
            <!-- <div
              v-for="(faker, fakerKey) in $_.take($f(), 5)"
              :key="fakerKey"
              class="relative flex items-center cursor-pointer"
              :class="{ 'mt-5': fakerKey }"
            >
              <div class="flex-none w-12 h-12 mr-1 image-fit">
                <img
                  alt="Enigma Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="faker.photos[0]"
                />
                <div
                  class="absolute bottom-0 right-0 w-3 h-3 border-2 border-white rounded-full bg-success"
                ></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a href="javascript:;" class="mr-5 font-medium truncate">{{
                    faker.users[0].name
                  }}</a>
                  <div class="ml-auto text-xs text-slate-400 whitespace-nowrap">
                    {{ faker.times[0] }}
                  </div>
                </div>
                <div class="w-full truncate text-slate-500 mt-0.5">
                  {{ faker.news[0].shortContent }}
                </div>
              </div>
            </div> -->
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>

      <Dropdown class="mr-4 intro-x sm:mr-6">
        <DropdownToggle tag="div" role="button" class="flex text-white cursor-pointer">
          <div class="flex -mr-4">
            <span class="mr-2 text-md"> Switch</span>
            <span class="-mt-1 text-lg font-semibold">
              {{ storeWarehouse }}
            </span>
          </div>

          <ArrowLeftRightIcon class="w-16 dark:text-slate-500" />
        </DropdownToggle>
        <DropdownMenu class="pt-2 notification-content">
          <DropdownContent tag="div" class="notification-content__box">
            <div class="notification-content__title">Switch Warehouse</div>

            <!-- <div
              class="grid justify-center grid-cols-3 gap-4"
              v-if="userRole != 'SuperUser'"
            >
              <div
                v-for="(item, i) in warehouses"
                :key="i"
                @click="switchWarehouse(item)"
                class="p-2 text-center text-white rounded-md cursor-pointer bg-primary"
              >
                {{ item.abbreviation }}
              </div>
            </div> -->
            <div class="flex flex-col items-center justify-center space-y-2">
              <div class="grid justify-center grid-cols-3 gap-4">
                <div v-for="(item, i) in defaultWarehouses" :key="i" @click="switchWarehouse(item)"
                  class="p-2 text-center text-white rounded-md cursor-pointer bg-primary">
                  {{ item.abbreviation }}
                </div>
              </div>

              <!-- <div class="flex items-center justify-center space-x-2">
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Back"
                  :options="{
                    zIndex: 99999,
                  }"
                >
                  <button @click="prevPage" v-if="hasPrev == true">
                    <ArrowBigLeftIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
                  </button>
                </Tippy>
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Next"
                  :options="{
                    zIndex: 99999,
                  }"
                >
                  <button @click="nextPage" v-if="hasNext == true">
                    <ArrowBigRightIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
                  </button>
                </Tippy>
              </div> -->
            </div>

            <!-- <div class="flex flex-col">
              <v-select
                v-model="warehouse"
                placeholder="SELECT"
                :options="warehouses"
                label="abbreviation"
              />
            </div> -->
            <!-- <div
              v-for="(faker, fakerKey) in $_.take($f(), 5)"
              :key="fakerKey"
              class="relative flex items-center cursor-pointer"
              :class="{ 'mt-5': fakerKey }"
            >
              <div class="flex-none w-12 h-12 mr-1 image-fit">
                <img
                  alt="Enigma Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="faker.photos[0]"
                />
                <div
                  class="absolute bottom-0 right-0 w-3 h-3 border-2 border-white rounded-full bg-success"
                ></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a href="javascript:;" class="mr-5 font-medium truncate">{{
                    faker.users[0].name
                  }}</a>
                  <div class="ml-auto text-xs text-slate-400 whitespace-nowrap">
                    {{ faker.times[0] }}
                  </div>
                </div>
                <div class="w-full truncate text-slate-500 mt-0.5">
                  {{ faker.news[0].shortContent }}
                </div>
              </div>
            </div> -->
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>

      <!-- END: Notifications -->

      <!-- BEGIN: Account Menu -->
      <Dropdown>
        <DropdownToggle tag="div" role="button" class="flex justify-center items-center">
          <!-- <UserIcon class="w-10 h-10" /> -->
          <!-- :src="$f()[9].photos[0]" -->
          <div class="w-8 h-8 intro-x">
            <div class="w-8 h-8 overflow-hidden scale-110 rounded-full shadow-lg image-fit zoom-in">
              <img alt="user image" src="@/assets/images/user-bw.png" />
            </div>
          </div>
          <div class="text-white z-10 ml-3 font-semibold">{{ fullName }}</div>
        </DropdownToggle>
        <DropdownMenu class="w-56">
          <DropdownContent
            class="bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
            <DropdownHeader tag="div" class="!font-normal">
              <div class="flex items-center justify-between">
                <div class="flex-col space-y-1">
                  <div class="font-medium">{{ fullName }}</div>
                  <div class="text-xs text-white/60 mt-0.5 dark:text-slate-500">
                    {{ authUser.email }}
                  </div>
                </div>
                <div v-if="userRole != 'SuperUser'">
                  <svg @click="licenceeWarehouse" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                    class="w-4 h-4 mr-2 text-white bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                </div>
              </div>
            </DropdownHeader>
            <DropdownDivider class="border-white/[0.08]" />
            <DropdownItem @click="getUserInfo" class="dropdown-item hover:bg-white/5">
              <UserIcon class="w-4 h-4 mr-2" /> Profile
            </DropdownItem>
            <!-- <DropdownItem
              @click="licenceeWarehouse"
              class="dropdown-item hover:bg-white/5"
            >
              <InfoIcon class="w-4 h-4 mr-2"
            /></DropdownItem> -->

            <DropdownItem @click="refreshSoh" class="dropdown-item hover:bg-white/5">
              <RefreshCcwIcon class="w-4 h-4 mr-2" /> Refresh SOH
            </DropdownItem>

            <DropdownItem @click="licencee" v-if="userRole.toLowerCase() === 'SuperUser'.toLowerCase()"
              class="dropdown-item hover:bg-white/5">
              <FileBadgeIcon class="w-4 h-4 mr-2" />Licencee
            </DropdownItem>

            <DropdownItem @click="cleanWarehouse" v-if="userRole.toLowerCase() === 'SuperUser'.toLowerCase()"
              class="dropdown-item hover:bg-white/5">
              <EraserIcon class="w-4 h-4 mr-2" />Clear Warehouse
            </DropdownItem>

            <DropdownItem @click="resetWarehouse"
              v-if="userRole.toLowerCase() === 'SuperUser'.toLowerCase() || userRole.toLowerCase() === 'Admin'.toLowerCase()"
              class="dropdown-item hover:bg-white/5">
              <RotateCcwIcon class="w-4 h-4 mr-2" />Reset Warehouse
            </DropdownItem>

            <DropdownItem @click="resetWarehouseStocks"
              v-if="userRole.toLowerCase() === 'SuperUser'.toLowerCase() || userRole.toLowerCase() === 'Admin'.toLowerCase()"
              class="dropdown-item hover:bg-white/5">
              <RotateCcwIcon class="w-4 h-4 mr-2" />Reset Warehouse Stocks
            </DropdownItem>

            <DropdownItem @click="addAccount" class="dropdown-item hover:bg-white/5">
              <EditIcon class="w-4 h-4 mr-2" /> Add Account
            </DropdownItem>

            <DropdownItem @click="changePassword" class="dropdown-item hover:bg-white/5">
              <LockIcon class="w-4 h-4 mr-2" /> Change Password
            </DropdownItem>
            <DropdownItem class="dropdown-item hover:bg-white/5">
              <HelpCircleIcon class="w-4 h-4 mr-2" /> Help
            </DropdownItem>
            <DropdownDivider class="border-white/[0.08]" />
            <DropdownItem @click="logout" class="dropdown-item hover:bg-white/5">
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <!-- END: Account Menu -->
    </div>

    <LogoutConfirm :name="authUser.userName" :modalStatus="logoutConfirmModal" :modalConfirm="logoutConfirm"
      :modalClose="closeLogout" />
    <ResetConfirm :name="authUser.userName" :modalStatus="resetConfirmModal" :modalConfirm="resetConfirm"
      :modalClose="closeReset" />

    <ConfirmModal :name="storeWarehouse" :modalStatus="cleanWarehouseConfirm" :modalConfirm="cleanConfirm"
      :modalClose="closeClean" icon="EraserIcon" color="text-warning" :question="`Do you want to clear this warehouse?`"
      nameBtn="Remove" @changeOption="changeOption" @changeCustomerOption="changeCustomerOption" />
  </div>
  <!-- END: Top Bar -->

  <LicenceConfirm :name="store.state.authUser.licensee.companyName" :modalStatus="editLicenseeConf"
    :modalConfirm="updateForm" :modalClose="CloseEditLicenseeConf" />

  <AddWarehouseUser :addWarehouse="addWarehouse" :formDataWarehouse="formDataWarehouse" :modalClose="closeWarehouse" />
  <MoveableModal :show="imageModal" @hidden="imageModal = false" backdrop="static" size="modal-xl" title="Company Logo"
    @close="imageModal = false">
    <ModalBody class="flex justify-center space-y-4">
      <div class="grid grid-cols-1 divide-y">
        <div v-if="userRole == 'SuperUser'">
          <a>
            <img alt="Logo" class="" style="width: 90%; height: 90%; border-radius: 25px"
              :src="`${baseurl}${store.state.authUser.softClientLogo}`" />
          </a>
        </div>

        <div v-if="userRole != 'SuperUser'" class="p-8">
          <a>
            <img alt="Logo" class="logo__image" style="width: 150%; height: 150%; border-radius: 25px"
              :src="`${baseurl}${store.state.authUser.licensee.logo}`" />
          </a>
        </div>

        <div v-if="userRole == 'SuperUser'">
          <div class="grid grid-cols-4 gap-2 p-2 lg:gap-4 md:gap-4">
            <div class="relative col-span-4 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Company Logo</h3>
              <div
                class="grid h-full grid-cols-2 gap-2 p-4 border border-solid rounded-md border-slate-200 lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4">
                <div>
                  <label for="regular-form-1" class="form-label">Logo</label>
                  <div class="flex flex-col space-y-4">
                    <div class="flex items-center justify-center w-full" v-if="imageUrl != null">
                      <img :src="imageUrl" class="object-fill w-full h-16" />
                    </div>
                    <div class="w-full">
                      <a href="javascript:;" @click="chooseImageFiles()"
                        class="w-full text-xs shadow-md btn btn-primary hover:text-yellow-200">Upload Image
                        <UploadIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />

                        <input id="imageFileInput" accept="image/*" type="file" ref="imageFileInput"
                          @change="onImageFilePicked" hidden />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <!-- <ModalFooter class="text-right">
      <button
        type="button"
        @click="closeAddForm()"
        class="w-24 mr-4 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button @click="saveForm()" type="submit" class="w-24 btn btn-primary">
        Done
      </button>
    </ModalFooter> -->
  </MoveableModal>

  <MoveableModal :show="warehouseInfoModal" @hidden="warehouseInfoModal = false" backdrop="static" size="modal-sl-60"
    :title="`Info for ${store.state.authUser.licensee.companyName}`" @close="warehouseInfoModal = false">
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
        <div class="flex flex-col items-center justify-center p-3 space-y-2">
          <img alt="Logo" class="logo__image" style="width: auto; height: 50px; border-radius: 25px"
            :src="`${baseurl}${store.state.authUser.licensee.logo}`" />

          <div class="mt-5 text-xl font-bold">
            {{ nullCheck(store.state.authUser.licensee.companyName, "Nill") }}
          </div>
          <EditItem :activeAccess="permission" @parentMethod="editItem($event)" :item="store.state.authUser.licensee"
            name="Edit" />
        </div>
        <div class="p-5 mt-12 intro-y box sm:mt-5">
          <div class="grid grid-cols-1 gap-2 pt-4 intro-y box lg:grid-cols-4 md:grid-cols-3 lg:gap-4 md:gap-4">
            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Phone No</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.phoneNumber, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Mobile No</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.mobile, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Fax</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.fax, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Email</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.email, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Website</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.website, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Address</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.address, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>ABN</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.abn, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>TIN</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.acn, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Licencee Code</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.licenceeCode, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>ATTN</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.attn, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>BSB</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.bsb, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>AC</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.ac, "Nill") }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Bank Name</div>
              </div>
              <div class="text-center">
                {{ nullCheck(store.state.authUser.licensee.bankName, "Nill") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <MoveableModal :show="editLicensee" @hidden="editLicensee = false" backdrop="static" size="modal-xl"
    :title="`Edit for ${store.state.authUser.licensee.companyName}`" @close="closeForm()">
    <ModalBody class="flex justify-center space-y-4">
      <div class="grid grid-cols-4 gap-2 p-2 lg:gap-4 md:gap-4">
        <div class="flex justify-center col-span-4">
          <div class="flex flex-col items-center justify-center space-y-4">
            <label for="regular-form-1" class="form-label">Logo</label>
            <div class="flex items-center justify-center w-full" v-if="url != null">
              <img :src="url" class="w-full rounded-lg h-[50px] w-auto object-fill" />
            </div>
            <div class="w-48 mx-8">
              <a href="javascript:;" @click="chooseFiles()"
                class="w-48 text-xs shadow-md btn btn-primary hover:text-yellow-200">Upload Image
                <UploadIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />

                <input id="fileInput" accept="image/*" type="file" ref="fileInput" @change="onFilePicked" hidden />
              </a>
            </div>
          </div>
        </div>

        <div class="relative col-span-4 pt-2">
          <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Company Details</h3>
          <div
            class="grid h-full grid-cols-2 gap-2 p-4 border border-solid rounded-md border-slate-200 lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4">
            <div class="col-span-2">
              <label for="regular-form-1" class="form-label">Company Name</label>

              <div class="flex flex-col items-center justify-center space-y-1">
                <input id="vertical-form-1" v-model="formDataLicensee.companyName" type="text" disabled
                  class="form-control" placeholder="Company Name" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Phone Number</label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="validatePhoneNo(formDataLicensee.phoneNumber) == true && formDataLicensee.mobile != '' ? 'border-color: red' : 'border-color: none'"
                  id="vertical-form-1" v-model="formDataLicensee.phoneNumber" type="text" class="form-control"
                  placeholder="Phone Number" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Mobile</label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="validatePhoneNo(formDataLicensee.mobile) == true && formDataLicensee.mobile != '' ? 'border-color: red' : 'border-color: none'"
                  id="vertical-form-1" v-model="formDataLicensee.mobile" type="text" class="form-control"
                  placeholder="Mobile" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Fax</label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="validatePhoneNo(formDataLicensee.fax) == true && formDataLicensee.fax != '' ? 'border-color: red' : 'border-color: none'"
                  id="vertical-form-1" v-model="formDataLicensee.fax" type="text" class="form-control" placeholder="Fax"
                  autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Website</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="formDataLicensee.website" type="text" class="form-control"
                  placeholder="Website" autocomplete="off" />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">Email</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="formDataLicensee.email" type="text" class="form-control"
                  placeholder="Email" autocomplete="off" />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Address</label>

              <div class="flex flex-col space-y-1">
                <textarea id="vertical-form-1" v-model="formDataLicensee.address" type="text" class="h-10 form-control"
                  placeholder="Address"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">ABN</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.abn" type="text" class="form-control"
              placeholder="ABN" autocomplete="off" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">TIN</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.acn" type="text" class="form-control"
              placeholder="TIN" autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Licencee Code</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.licenceeCode" type="text" disabled
              class="form-control" placeholder="Licencee Code" autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">ATTN</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.attn" type="text" class="form-control"
              placeholder="ATTN" autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">BSB</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.bsb" type="text" class="form-control"
              placeholder="BSB" autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">AC</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.ac" type="text" class="form-control" placeholder="AC"
              autocomplete="off" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Bank Name</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataLicensee.bankName" type="text" class="form-control"
              placeholder="Bank Name" autocomplete="off" />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="w-24 mr-4 btn btn-outline-secondary">Cancel</button>
      <button @click="openEditLicenseeConf()" type="submit" class="w-24 btn btn-primary">Update</button>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal v-if="isOpenProfile" :show="isOpenProfile" @hidden="isOpenProfile = false" backdrop="static"
    size="modal-sl-70" :title="`Info for ${store.state.authUser.username}`" @close="closeProfile()">
    <ModalBody class="flex items-start justify-center px-8 py-4 space-x-4">
      <!-- BEGIN: INFO -->

      <div :class="userRole.toLowerCase() !== 'SuperUser'.toLowerCase() && userRole.toLowerCase() !== 'Admin'.toLowerCase() ? 'relative pt-2 basis-[100%]' : 'relative pt-2 basis-[40%]'
        ">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">User Details</h3>
        <div class="grid h-full grid-cols-2 gap-2 p-4 border border-solid rounded-md border-slate-200">
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold uppercase">
              <div>First Name</div>
            </div>
            <div v-if="!isEditProfile">
              {{ nullCheck(store.state.authUser.firstName, "Nill") }}
            </div>
            <div v-else class="pt-2">
              <input id="vertical-form-1" v-model="formDataUser.firstName" type="text" class="form-control"
                placeholder="First Name" autocomplete="off" />
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold uppercase">
              <div>Last Name</div>
            </div>
            <div v-if="!isEditProfile">
              {{ nullCheck(store.state.authUser.lastName, "Nill") }}
            </div>
            <div v-else class="pt-2">
              <input id="vertical-form-1" v-model="formDataUser.lastName" type="text" class="form-control"
                placeholder="Last Name" autocomplete="off" />
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold uppercase">
              <div>User Name</div>
            </div>
            <div>
              {{ nullCheck(store.state.authUser.username, "Nill") }}
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold uppercase">
              <div>Email</div>
            </div>
            <div v-if="!isEditProfile">
              {{ nullCheck(userInfo.email, "Nill") }}
            </div>
            <div v-else class="pt-2">
              <input id="vertical-form-1" v-model="formDataUser.email" type="text" class="form-control"
                placeholder="Email" autocomplete="off" />
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold uppercase">
              <div>Phone Number</div>
            </div>
            <div v-if="!isEditProfile">
              {{ nullCheck(userInfo.phoneNumber, "Nill") }}
            </div>
            <div v-else class="pt-2">
              <input id="vertical-form-1" v-model="formDataUser.phoneNumber" type="text" class="form-control"
                placeholder="Phone Number" autocomplete="off" />
            </div>
          </div>
        </div>
        <div class="flex items-start mt-4 space-x-4">
          <button class="w-auto btn btn-primary whitespace-nowrap" v-if="!isEditProfile" @click="userEdit()">Edit
            User</button>
          <button class="w-auto btn btn-primary whitespace-nowrap" v-if="isEditProfile" @click="updateUser()">Save
            User</button>
          <button class="w-auto btn btn-primary whitespace-nowrap" v-if="isEditProfile"
            @click="clearUser()">Cancel</button>

          <button class="w-auto btn btn-primary whitespace-nowrap" @click="userChangePassword()">Change
            Password</button>
        </div>
      </div>

      <div class="relative pt-2 basis-[60%]"
        v-if="userRole.toLowerCase() === 'SuperUser'.toLowerCase() || userRole.toLowerCase() === 'Admin'.toLowerCase()">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Licensee Details</h3>
        <div class="grid h-full grid-cols-2 gap-2 p-4 border border-solid rounded-md border-slate-200">
          <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
            <div class="flex flex-col items-center justify-center p-3 space-y-2">
              <img alt="Logo" class="logo__image" style="width: auto; height: 50px; border-radius: 25px"
                :src="`${baseurl}${store.state.authUser.licensee.logo}`" />

              <div class="mt-5 text-xl font-bold">
                {{ nullCheck(store.state.authUser.licensee.companyName, "Nill") }}
              </div>
            </div>
            <div class="p-5 mt-12 intro-y box sm:mt-5">
              <div class="grid grid-cols-1 gap-2 pt-4 intro-y box lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4">
                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Phone No</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.phoneNumber, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Mobile No</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.mobile, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Fax</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.fax, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Email</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.email, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Website</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.website, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Address</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.address, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>ABN</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.abn, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>TIN</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.acn, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Licencee Code</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.licenceeCode, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>ATTN</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.attn, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>BSB</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.bsb, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>AC</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.ac, "Nill") }}
                  </div>
                </div>

                <div class="flex flex-col items-center mb-5">
                  <div class="flex items-center font-bold uppercase">
                    <div>Bank Name</div>
                  </div>
                  <div class="text-center">
                    {{ nullCheck(store.state.authUser.licensee.bankName, "Nill") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <ConfirmAlert :modalStatus="isResetWarehouseStocks" :modalConfirm="confirmResetWarehouseStocks"
    :modalClose="closeResetWarehouseStocks" :isClose="true" icon="RotateCcwIcon" color="warning" :name="storeWarehouse"
    question="Do you want to reset the stocks of this warehouse?" nameBtn="Ok">

    <!-- <div class="mt-3 form-switch">
      <label>Reset Reserved Stocks</label>
      <input id="booleanSwitch" type="checkbox" class="ml-3 form-check-input" v-model="resetReservedStocks" />
    </div> -->
  </ConfirmAlert>

  <ConfirmAlert :modalStatus="isResetWarehouse" :modalConfirm="confirmResetWarehouse" :modalClose="closeResetWarehouse"
    :isClose="true" icon="RotateCcwIcon" color="warning" :name="storeWarehouse"
    question="Do you want to reset the PLU/SKU and Orders of this warehouse?" nameBtn="Ok" />

  <ConfirmAlert :modalStatus="isRefreshSoh" :modalConfirm="confirmRefreshSoh" :modalClose="closeRefreshSoh"
    :isClose="true" icon="RotateCcwIcon" color="warning" :name="storeWarehouse"
    question="Do you want to refresh the SOH?" nameBtn="Ok" />
</template>

<script setup>
import { useSideMenuStore } from "@/stores/side-menu";
import { computed, onMounted, provide, ref, watch } from "vue";
import { helper as $h } from "@/utils/helper";
import { linkTo, nestedMenu, enter, leave } from "../../layouts/side-menu/index";
import { useRoute, useRouter } from "vue-router";
import router from "../../router";
import axios from "../../services/http-common";
import store from "../../store";
import { successToast, failedToast, warningToast, statusMessage } from "../../global-functions/toast";
import { reg } from "../../global-functions/regex";
import jsPDF from "jspdf";

const sideMenuStore = useSideMenuStore();
const route = useRoute();
const formattedMenu = ref([]);
const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route));
const authUser = ref({});
const warehouse = ref();
const warehouses = ref([]);
const defaultWarehouses = ref([]);
const defaultlicensees = ref([]);
const logoutConfirmModal = ref(false);
const resetConfirmModal = ref(false);

const cleanWarehouseConfirm = ref(false);
const addWarehouse = ref(false);

const formDataWarehouse = ref({
  name: "",
  description: "",
  abbreviation: "",
  isDefault: false,
});

const formDataWarehouseDefault = ref({
  name: "",
  description: "",
  abbreviation: "",
  isDefault: false,
});

const picked = ref(false);
const pickedCustomer = ref(false);

const warehouseInfoModal = ref(false);
const imageModal = ref(false);
const warhousesList = ref([]);
const itemWarehouseData = ref([]);
const isLoading = ref(false);
const policyAccess = ref("Licensees");
const editLicensee = ref(false);
const editLicenseeConf = ref(false);
const url = ref(null);
const imageUrl = ref(null);

const limit = ref(5);
const setLimit = ref(5);
const hasNext = ref(false);
const hasPrev = ref(false);

const limitLic = ref(5);
const setLimitLic = ref(5);
const hasLNext = ref(false);
const hasLPrev = ref(false);

const storeWarehouse = computed(() => {
  return store.state.authUser.warehouse !== null ? store.state.authUser.warehouse.abbreviation : "";
});
const storeWarehouseId = computed(() => {
  return store.state.authUser.warehouse !== null ? store.state.authUser.warehouse.warehouseId : 0;
});

const fullName = computed(() => {
  const firstName = store.state.authUser.firstName || "";
  const lastName = store.state.authUser.lastName || "";
  const username = store.state.authUser.username || ""

  if (firstName != 'null' || lastName != 'null') {
    return firstName + " " + lastName
  }

  if (username != 'null') {
    return username;
  }

  return '';
});

const formDataLicensee = ref({
  companyName: "",
  abn: "",
  acn: "",
  address: "",
  deliveryAddress: "",
  isDifferentDeliveryAddress: false,
  phoneNumber: "",
  website: "",
  email: "",
  attn: "",
  fax: "",
  mobile: "",
  licenceeCode: "",
  bsb: "",
  ac: "",
  bankName: "",
});
const formDataLicenseeImage = ref({
  name: "",
});

const formDataLicenseeImageDefault = ref({
  name: "",
});
const formDataLicenseeDefault = ref({
  companyName: "",
  abn: "",
  acn: "",
  address: "",
  deliveryAddress: "",
  isDifferentDeliveryAddress: false,
  phoneNumber: "",
  website: "",
  email: "",
  fax: "",
  attn: "",
  mobile: "",
  licenceeCode: "",
  bsb: "",
  ac: "",
  bankName: "",
});

const formDataUser = ref({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  role: store.state.authUser.userRole,
});

const formDataUserDefault = ref({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  role: store.state.authUser.userRole,
});

const logo = ref(null);

const logoSoftClient = ref(null);

const userRole = store.state.authUser.userRole;

const baseurl = axios.defaults.baseURL.replace("/api/", "/");

const permission = ref(false);

// const validateEmail = (email) => {
//   return !reg.EMAIL.test(email);
// };

const isRefreshSoh = ref(false);

const confirmRefreshSoh = () => {
  isLoading.value = true;
  axios
    .get("Memory/" + storeWarehouse.value + "/ClearStocks")
    .then((response) => {
      if (response.status === 200) {
        setTimeout(() => {
          axios
            .get("Memory/" + storeWarehouse.value + "/UpdateStocks")
            .then((response) => {
              if (response.status === 200) {
                isLoading.value = false;
                successToast("SOH is updated successfully");
              } else {
                isLoading.value = false;
                failedToast("SOH is updated failed");
              }
            })
            .catch((error) => {
              isLoading.value = false;
              failedToast("SOH is updated failed");
            });
        }, 5000);
      } else {
        isLoading.value = false;
        failedToast("SOH is cleared failed");
      }
    })
    .catch((error) => {
      isLoading.value = false;
      failedToast("SOH is cleared failed");
    });
};

const closeRefreshSoh = () => {
  isRefreshSoh.value = false;
};

const refreshSoh = () => {
  isRefreshSoh.value = true;
};

const isOpenProfile = ref(false);
const isEditProfile = ref(false);

const userInfo = ref(null);
const getUserInfo = () => {
  isLoading.value = true;
  axios
    .get("User")
    .then((response) => {
      if (response.data.success) {
        isLoading.value = false;
        userInfo.value = response.data.data;
        isOpenProfile.value = true;
      } else {
        isLoading.value = false;
        failedToast(response.data.message);
      }
    })
    .catch((error) => {
      isLoading.value = false;
      failedToast(statusMessage(error.response.status));
    });
};

const closeProfile = () => {
  isOpenProfile.value = false;
  userInfo.value = null;
  clearUser();
};

const userEdit = () => {
  isEditProfile.value = true;
};

const clearUser = () => {
  isEditProfile.value = false;
  formDataUser.value = Object.assign({}, formDataUserDefault.value);
};

const updateUser = () => {
  let itemId = userInfo.value.userId;
  axios
    .put("Users/" + itemId, formDataUser.value)
    .then((response) => {
      if (response.data.success) {
        successToast(response.data.message);
        store.dispatch("authUser/setFirstName", formDataUser.value.firstName);
        store.dispatch("authUser/setLastName", formDataUser.value.lastName);
        isLoading.value = false;
        if (!isLoading.value) {
          clearUser();
        }
      } else {
        isLoading.value = false;
        failedToast(response.data.message);
      }
    })
    .catch((error) => {
      isLoading.value = false;
      warningToast(statusMessage(error.response.status));
    });
};

const userChangePassword = () => {
  router.push("/change-password");
  closeProfile();
};

const isResetWarehouse = ref(false);

const resetWarehouse = () => {
  isResetWarehouse.value = true;
};

const closeResetWarehouse = () => {
  isResetWarehouse.value = false;
};

const isResetWarehouseStocks = ref(false);
//const resetReservedStocks = ref(true);

const resetWarehouseStocks = () => {
  isResetWarehouseStocks.value = true;
};

const closeResetWarehouseStocks = () => {
  isResetWarehouseStocks.value = false;
  // resetReservedStocks.value = false;
};


const nullCheck = (val, defaultVal) => {
  return val ? (val === "null" ? defaultVal : val) : defaultVal;
};

const confirmResetWarehouse = () => {
  isLoading.value = true;
  axios
    .get("Warehouses/ResetWarehouseData?WarehouseId=" + storeWarehouseId.value)
    .then(async (response) => {
      if (response.data.success) {
        isLoading.value = false;
        successToast(response.data.message);
        location.reload();
      } else {
        isLoading.value = false;
        failedToast(response.data.message);
      }
    })
    .catch((error) => {
      isLoading.value = false;
      failedToast(statusMessage(error.response.status));
    });
};

const confirmResetWarehouseStocks = () => {
  isLoading.value = true;
  axios
    .get("Warehouses/ResetWarehouseStocks", { params: { warehouseId: storeWarehouseId.value, resetReservedStocks: true } })
    .then(async (response) => {
      if (response.data.success) {
        isLoading.value = false;
        successToast(response.data.message);
        location.reload();
      } else {
        isLoading.value = false;
        failedToast(response.data.message);
      }
    })
    .catch((error) => {
      isLoading.value = false;
      failedToast(statusMessage(error.response.status));
    });
};

const validatePhoneNo = (phoneNumber) => {
  return !reg.PHONENO.test(phoneNumber);
};

provide("forceActiveMenu", (pageName) => {
  route.forceActiveMenu = pageName;

  formattedMenu.value = $h.toRaw(sideMenu.value);
});

watch(
  warehouse,

  async () => {
    var selectedWhId = warehouse.value.warehouseId;
    var storeWhId = store.state.authUser.warehouse.warehouseId;
    if (selectedWhId != storeWhId) {
      store.dispatch("authUser/setWarehouse", warehouse.value);
      location.reload();
    } else {
      warningToast("Select different warehouse to Switch");
    }
  }
);

watch(
  computed(() => route.path),

  () => {
    delete route.forceActiveMenu;
    formattedMenu.value = $h.toRaw(sideMenu.value);
  }
);

onMounted(() => {
  console.log(baseurl);
  console.log(store.state.authUser.softClientLogo);
  permission.value = editItemAccess();

  // axios
  //   .get("User")
  //   .then((response) => {
  //     authUser.value = response.data.data;
  //     // console.log("USER : ", authUser.value);
  //   })
  //   .catch((error) => {
  //     router.push("/login");
  //   });

  // var pagination = { pageNumber: 1, pageSize: 1000 };

  // axio$refss
  //   .post("Warehouses/Paginated", pagination)
  //   .then((response) => {
  //     warehouses.value = response.data.data.items;
  //   })
  //   .catch((error) => {
  //     warningToast(statusMessage(error.response.status));
  //   });

  // axios
  //   .get("User/Licensee")
  //   .then((response) => {
  //     warehouses.value = response.data.data.data.warehouses;
  //     // store.dispatch("authUser/setLogo", response.data.data.data.logo);
  //   })
  //   .catch((error) => {
  //     // warningToast(statusMessage(error.response.status));
  //     console.log('Main: Licensee: error',error);
  //   });

  dom("body").removeClass("error-page").removeClass("login").addClass("main");
  formattedMenu.value = $h.toRaw(sideMenu.value);
  getWarehouses();
  getLicensees();
});
const logout = () => {
  logoutConfirmModal.value = true;
};
const switchWarehouse = (item) => {
  console.log(item);
  var selectedWhId = item.warehouseId;
  var storeWhId = store.state.authUser.warehouse.warehouseId;
  if (selectedWhId != storeWhId) {
    store.dispatch("authUser/setWarehouse", item);
    location.reload();
  } else {
    warningToast("Select different warehouse to Switch");
  }
};

const switchLicensee = async (item) => {
  console.log(item);
  var selectedId = item.licenseeId;
  var storeId = store.state.authUser.licensee.licenseeId;
  if (selectedId != storeId) {
    store.dispatch("authUser/setLicensee", item);
    var base_url = "https://" + item.domain + "/api/";
    axios.defaults.baseURL = base_url;
    store.dispatch("authUser/setBaseurl", base_url);
    await getWarehouses();
    if (defaultWarehouses.value.length > 0) {
      var defaultWh = defaultWarehouses.value.find((x) => x.isDefault === true);
      store.dispatch("authUser/setWarehouse", defaultWh === null || defaultWh === undefined ? defaultWarehouses.value[0] : defaultWh);
    }

    location.reload();
  } else {
    warningToast("Select different warehouse to Switch");
  }
};

const logoutConfirm = () => {
  store.dispatch("authUser/logout").then(() => {
    successToast("Successfully LoggedOut");
    logoutConfirmModal.value = false;
    router.push("/login");

    // var url = window.location.href;
    // var check = url.indexOf("=");
    // if (check === -1) {
    //   router.push("/login");
    //   successToast("Logout Successfull");
    //}
  });
};
const closeLogout = () => {
  logoutConfirmModal.value = false;
};

const changePassword = () => {
  router.push("/change-password");
};

const licencee = () => {
  router.push("/wms/licensee");
};

const cleanWarehouse = () => {
  cleanWarehouseConfirm.value = true;
};

const closeClean = () => {
  cleanWarehouseConfirm.value = false;
};

const closeWarehouse = () => {
  addWarehouse.value = false;
  setDefaultWarehouse();
  router.push("/logout");
};

const setDefaultWarehouse = () => {
  formDataWarehouse.value = Object.assign({}, formDataWarehouseDefault.value);
};

const changeOption = (val) => {
  console.log("changeOption", val);
  picked.value = val;
};

const changeCustomerOption = (val) => {
  console.log("changeCustomerOption", val);
  pickedCustomer.value = val;
};

const cleanConfirm = () => {
  if (picked.value) {
    isLoading.value = true;
    axios
      .get("Warehouses/ClearWarehouseDataAndDeleteWarehouse?WarehouseId=" + storeWarehouseId.value)
      .then(async (response) => {
        if (response.data.success === true) {
          isLoading.value = false;
          successToast(response.data.message);

          var pagination = {
            pageNumber: 1,
            pageSize: 10,
          };

          try {
            var responseWarehouse = await axios.post("Warehouses/Paginated", pagination);
            if (responseWarehouse.data.success) {
              closeClean();
              if (responseWarehouse.data.data.items.length <= 0) {
                addWarehouse.value = true;
              } else {
                var defaultWh = responseWarehouse.data.data.items.find((x) => x.isDefault === true);

                store.dispatch("authUser/setWarehouse", defaultWh === null || defaultWh === undefined ? responseWarehouse.data.data.items[0] : defaultWh);

                storeWarehouse.value = store.state.authUser.warehouse !== null ? store.state.authUser.warehouse.abbreviation : "";
              }
            } else {
              failedToast(responseWarehouse.data.message);
            }
          } catch (error) {
            console.log("Main: Warehouse: Error: ", error);
          }
          location.reload();
        } else {
          isLoading.value = false;
          failedToast(response.data.message);
        }
      })
      .catch((error) => {
        isLoading.value = false;
        failedToast(statusMessage(error.response.status));
      });
  } else {
    if (pickedCustomer.value) {
      isLoading.value = true;
      axios
        .get("Warehouses/ClearWarehouseDataWithoutCustomer?WarehouseId=" + storeWarehouseId.value)
        .then(async (response) => {
          if (response.data.success) {
            isLoading.value = false;
            successToast(response.data.message);
            location.reload();
          } else {
            isLoading.value = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          isLoading.value = false;
          failedToast(statusMessage(error.response.status));
        });
    } else {
      isLoading.value = true;
      axios
        .get("Warehouses/ClearWarehouseData?WarehouseId=" + storeWarehouseId.value)
        .then((response) => {
          if (response.data.success === true) {
            isLoading.value = false;
            successToast(response.data.message);
            location.reload();
          } else {
            isLoading.value = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          isLoading.value = false;
          failedToast(statusMessage(error.response.status));
        });
    }
  }
};

const licenceeWarehouse = () => {
  getWarehousesList();
  warehouseInfoModal.value = true;
};
const showImageModal = () => {
  imageModal.value = true;
};

const reset = () => {
  resetConfirmModal.value = true;
};
const resetConfirm = () => {
  resetConfirmModal.value = false;
  router.push("/reset-password");
};

const closeReset = () => {
  resetConfirmModal.value = false;
};

const addAccount = () => {
  router.push("/wms/users");
};
const closeAddForm = () => {
  imageModal.value = false;
  imageUrl.value = null;
  logoSoftClient.value = null;
  // setImageDefault();
};
// const setImageDefault = () => {
//   formDataLicenseeImage.value = Object.assign(
//     {},
//     formDataLicenseeImageDefault.value
//   );
// };
const searchDropdown = ref(false);
const showSearchDropdown = () => {
  searchDropdown.value = true;
};
const hideSearchDropdown = () => {
  searchDropdown.value = false;
};

const getWarehousesList = () => {
  isLoading.value = true;
  var licenseeId = store.state.authUser.licensee.licenseeId;
  axios
    .get("Licensees/" + licenseeId + "/Warehouses")
    .then((response) => {
      if (response.data.success === true) {
        itemWarehouseData.value = response.data;
        warhousesList.value = response.data.data;
        isLoading.value = false;
        // setLocalWarehousePagination();
      } else {
        warningToast(response.data.message);
        isLoading.value = false;
      }
    })
    .catch((error) => {
      isLoading.value = false;
      warningToast(statusMessage(error.response.status));
    });
};

const editItem = (item) => {
  url.value = `${baseurl}${item.logo}`;
  formDataLicensee.value = Object.assign({}, item);
  editLicensee.value = true;
};
const closeForm = () => {
  editLicensee.value = false;
  setDefault();
};
const setDefault = () => {
  formDataLicensee.value = Object.assign({}, formDataLicenseeDefault.value);
};

const chooseFiles = () => {
  const fileInput = document.getElementById("fileInput");
  fileInput.value = "";
  fileInput.click();
};

const chooseImageFiles = () => {
  const imageInput = document.getElementById("imageFileInput");
  imageInput.value = "";
  imageFileInput.click();
};
const onFilePicked = (event) => {
  logo.value = event.target.files[0];
  url.value = URL.createObjectURL(logo.value);
};

const onImageFilePicked = (event) => {
  logoSoftClient.value = event.target.files[0];
  imageUrl.value = URL.createObjectURL(logoSoftClient.value);
  saveForm();
};
const CloseEditLicenseeConf = () => {
  editLicenseeConf.value = false;
};

const openEditLicenseeConf = () => {
  if (formDataLicensee.value.companyName == "") {
    if (formDataLicensee.value.name == "") {
      warningToast("Name Required");
    }
  } else {
    editLicenseeConf.value = true;
  }
};

const saveForm = () => {
  isLoading.value = true;

  let formData2 = new FormData();
  formData2.append("File", logoSoftClient.value);
  formData2.append("Name", logoSoftClient.value.name);

  axios
    .post("Gallery/Softclient/Logo", formData2)
    .then((response) => {
      if (response.data.success === true) {
        isLoading.value = false;
        closeAddForm();
        location.reload();
        successToast(response.data.message);
      } else {
        isLoading.value = false;
        failedToast(response.data.message);
      }
    })
    .catch((error) => {
      isLoading.value = false;
      failedToast(statusMessage(error.response.status));
    });
};
const updateForm = () => {
  var itemId = store.state.authUser.licensee.licenseeId;
  isLoading.value = true;
  let formData = new FormData();

  formData.append("CompanyName", formDataLicensee.value.companyName);
  formData.append("ABN", formDataLicensee.value.abn);
  formData.append("ACN", formDataLicensee.value.acn);
  formData.append("Address", formDataLicensee.value.address);
  formData.append("DeliveryAddress", formDataLicensee.value.deliveryAddress);
  formData.append("IsDifferentDeliveryAddress", formDataLicensee.value.isDifferentDeliveryAddress);
  formData.append("PhoneNumber", formDataLicensee.value.phoneNumber);
  formData.append("Website", formDataLicensee.value.website);
  formData.append("Email", formDataLicensee.value.email);
  formData.append("Fax", formDataLicensee.value.fax);
  formData.append("ATTN", formDataLicensee.value.attn);
  formData.append("Mobile", formDataLicensee.value.mobile);
  formData.append("LicenceeCode", formDataLicensee.value.licenceeCode);
  formData.append("BSB", formDataLicensee.value.bsb);
  formData.append("AC", formDataLicensee.value.ac);
  formData.append("BankName", formDataLicensee.value.bankName);

  if (logo.value != null) {
    formData.append("Logo", logo.value, logo.value.name);
  } else {
    formData.append("Logo", null);
  }

  if (this.formDataLicensee.companyName == "" || this.formDataLicensee.phoneNumber == "" || this.formDataLicensee.email == "") {
    if (this.formDataLicensee.companyName == "") {
      warningToast("Company Name Required");
    }
    if (this.formDataLicensee.phoneNumber == "") {
      warningToast("Phone Number Required");
    }
    if (this.formDataLicensee.email == "") {
      warningToast("Email Required");
    }
  } else {
    axios
      .put("Licensees/" + itemId, formData)
      .then((response) => {
        if (response.data.success === true) {
          successToast(response.data.message);
          logoutConfirm();
          CloseEditLicenseeConf();
          closeForm();
          warehouseInfoModal.value = false;
          isLoading.value = false;
        } else {
          isLoading.value = false;
          CloseEditLicenseeConf();
          failedToast(response.data.message);
        }
      })
      .catch((error) => {
        isLoading.value = false;
        CloseEditLicenseeConf();
        failedToast(statusMessage(error.response.status));
      });
  }
};

const onlyNumber = ($event) => {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    // 46 is dot
    $event.preventDefault();
  }
};

const getWarehouses = async () => {
  defaultWarehouses.value = store.state.authUser.warehouses;

  // isLoading.value = true;

  // var pagination = { pageNumber: 1, pageSize: limit.value };
  // await axios
  //   .post("Warehouses/Paginated", pagination)
  //   .then((response) => {
  //     defaultWarehouses.value = response.data.data.items;

  //     if (limit.value <= setLimit.value) {
  //       hasPrev.value = false;
  //     } else {
  //       hasPrev.value = true;
  //     }

  //     hasNext.value = response.data.data.hasNextPage;
  //     // this.warehouse = this.warehouses[this.warehouses.length - 1];
  //     // this.warehouseName =
  //     //   this.warehouses[this.warehouses.length - 1].abbreviation;
  //     isLoading.value = false;
  //   })
  //   .catch((error) => {
  //     isLoading.value = false;
  //     failedToast(statusMessage(error.response.status));
  //   });
};

const getLicensees = () => {
  //isLoading.value = true;

  defaultlicensees.value = store.state.authUser.licensees;
  // var pagination = { pageNumber: 1, pageSize: limitLic.value };
  // axios
  //   .post("Licensees/Paginated", pagination)
  //   .then((response) => {
  //     defaultlicensees.value = response.data.data.items;
  //     if (limitLic.value <= setLimitLic.value) {
  //       hasLPrev.value = false;
  //     } else {
  //       hasLPrev.value = true;
  //     }

  //     hasLNext.value = response.data.data.hasNextPage;
  //     // this.warehouse = this.warehouses[this.warehouses.length - 1];
  //     // this.warehouseName =
  //     //   this.warehouses[this.warehouses.length - 1].abbreviation;
  //     isLoading.value = false;
  //   })
  //   .catch((error) => {
  //     isLoading.value = false;
  //     failedToast(statusMessage(error.response.status));
  //   });
};

const prevPage = () => {
  limit.value = parseInt(limit.value) - setLimit.value;
  getWarehouses();
};
const nextPage = () => {
  limit.value = parseInt(limit.value) + setLimit.value;
  getWarehouses();
};

const prevLPage = () => {
  limitLic.value = parseInt(limitLic.value) - setLimitLic.value;
  getLicensees();
};
const nextLPage = () => {
  limitLic.value = parseInt(limitLic.value) + setLimitLic.value;
  getLicensees();
};

const editItemAccess = () => {
  var policyName = "Licensees";
  return store.getters["authUser/getPermission"](`Permissions.${policyName}.Modify`);
};
</script>
