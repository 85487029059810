<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full" v-if="_modelValue">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div class="flex items-center justify-between w-full py-4">
      <div @click="goBack()">
        <Tippy
          tag="a"
          href="javascript:;"
          class="tooltip"
          content="Go back to order report"
          :options="{
            zIndex: 99999,
          }"
          ><ArrowLeftIcon class="w-6 h-6 mr-1" />
        </Tippy>
      </div>
      <div class="text-2xl">Picking Exceptions</div>
      <div></div>
    </div>
     
        <!-- <div>
          <a href="javascript:;" @click="getReports()" class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"> GET REPORT </a>
        </div> -->
      
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="exceptions.length > 0">
        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport" :page="pageNumber" />
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto" v-if="reportsLocal.length > 0">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th>Type</th>
              <th>PLU/SKU</th>
              <th>BARCODE/EAN</th>
              <th>quantity</th>
              <th>Scanned At</th>
              <th>Scanned By</th>
              <th>lpn</th>
              <th>Section</th>
              <th>Bin</th>
              <th>Storage Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td>
                {{ item.type  ?  getExceptionType(item.type) : ""}}
              </td>

              <td>
                {{ item.sku  ?  item.sku : ""}}
              </td>
              <td>
                {{ item.ean  ?  item.ean : ""}}
              </td>
              <td>
                {{ item.quantity  ?  item.quantity : ""}}
              </td>
              <td>
                {{ item.scannedAt ? momentLocal(item.scannedAt) : "" }}
              </td>
            
              <td>
                {{ item.scannedBy  ?  item.scannedBy : ""}}
              </td>
             
              <td>
                {{ item.lpn  ?  item.lpn : ""}}
              </td>
              <td>
                {{ item.storageSection  ?  item.storageSection : ""}}
              </td>
              <td>
                {{ item.storageBin  ?  item.storageBin : ""}}
              </td>
              <td>
                {{ item.storageType  ?  item.storageType : ""}}
              </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <div
      v-if="exceptions.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>
    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-components/toast/toast";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Order Reports");
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      orders: [],
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,
     
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      itemsData: {},
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
      switchOption: 0,
    };
  },
  props: {
    modelValue: false,
    exceptions: [],
    orderLineId : 0
  },
  computed: {
    _modelValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    getReportData() {
      return this.orderLineId;
    },
  },
  created() {
    this.setLocalReport()
  },
  watch: {
    // getReportData(val){
    //   if(val){
        
    //   }
    // }
  },
  methods: {
    getExceptionType(type){
      switch (type){
            case 1 : return "SKIP TASK";
            case 2 : return "SPLIT PICK";
            case 3 : return "PARTIAL PICK";
            case 4 : return "STOCK OUT";
            case 5 : return "INCORRECT ITEM LOCATION";
            default: return "";
        }
    },
    goBack(){
      this._modelValue = false;
      //this.exceptions = [];
    },
    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.exceptions;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
        { title: "Order Number", dataKey: "orderNumber" },
          { title: "Type", dataKey: "type" },
          { title: "PLU/SKU", dataKey: "sku" },
          { title: "BARCODE/EAN", dataKey: "ean" },
          { title: "Quantity", dataKey: "quantity" },
          { title: "Scanned At", dataKey: "scannedAt" },
          { title: "Scanned By", dataKey: "scannedBy" },
          { title: "LPN", dataKey: "lpn" },
          { title: "Warehouse", dataKey: "warehouse" },
          { title: "Section", dataKey: "storageSection" },
          { title: "Bin", dataKey: "storageBin" },
          { title: "Storage Type", dataKey: "storageType" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            orderNumber: el.orderNumber ? el.orderNumber : "",
            type: el.type ? this.getExceptionType(el.type) : "",
            sku: el.sku ? el.sku : "",
            ean: el.ean ? el.ean : "",
            quantity: el.quantity ? el.quantity : 0,
            scannedAt: el.scannedAt ? this.momentLocal(el.scannedAt) : "",
            scannedBy: el.scannedBy ? el.scannedBy : "",
            lpn: el.lpn ? el.lpn : "",
            warehouse: el.warehouse ? el.warehouse : "",
            storageSection: el.storageSection ? el.storageSection : "",
            storageBin: el.storageBin ? el.storageBin : "",
            storageType: el.storageType ? el.storageType : "",
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.exceptions <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.exceptions <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Exception Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.exceptions <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Exception Report " + generateDate;
        var items = this.exceptions;
        var csvItems = [];

        var csvHeaders = {
          1:"Order Number",
          2: "Type",
          3: "PLU/SKU",
          4: "BARCODE/EAN",
          5: "Quantity",
          6: "Scanned At",
          7: "Scanned By",
          8: "LPN",
          9: "Warehouse",
          10: "Section",
          11: "Bin",
          12: "Storage Type",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.orderNumber ? item.orderNumber : "",
            2: item.type ? this.getExceptionType(item.type) : "",
            3: item.sku ? item.sku : "",
            4: item.ean ? item.ean : "",
            5: item.quantity ? item.quantity : "",
            6: item.scannedAt ? this.momentLocalCSV(item.scannedAt) : "",
            7: item.scannedBy ? item.scannedBy : "",
            8: item.lpn ? item.lpn : "",
            9: item.warehouse ? item.warehouse : "",
            10: item.storageSection ? item.storageSection : "",
            11: item.storageBin ? item.storageBin : "",
            12: item.storageType ? item.storageType : "",
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },

    clearReports() {
      this.exceptions = [];
      this.reportsLocal = [];
      this.orderLineId = null;
      //this.getCurrentMonth();
    },

  
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.exceptions.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      if(this.exceptions.length > 0){
        this.itemsData = this.exceptions
      this.itemsData.totalCount = this.exceptions.length
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.exceptions.slice(this.reportStart, this.reportEnd);
      }
      else{
        this.reportsLocal = []
      }
     
    },
    
  },
};

</script>
