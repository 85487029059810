<template>
  <LoadingScreen :isLoading="isLoading" />
  <MoveableModal
    :show="_addWarehouse"
    @hidden="_addWarehouse = false"
    backdrop="static"
    size="modal-xl"
    title="Create Warehouse"
    @close="closeForm()"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full p-3">
        <div>
          <label for="regular-form-1" class="form-label">Name</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="_formDataWarehouse.name"
              type="text"
              class="form-control"
              name="name"
              placeholder="Warehouse Name"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div>
            <label for="regular-form-1" class="form-label">Abbreviation</label>

            <div class="flex flex-col space-y-1">
              <input
                id="vertical-form-1"
                v-model="_formDataWarehouse.abbreviation"
                type="text"
                class="form-control"
                name="abbreviation"
                placeholder="Warehouse Abbreviation"
                autocomplete="off"
              />
            </div>
          </div>

          <div
            class="form-switch ml-3 flex flex-col justify-center items-center space-y-2"
          >
            <label for="regular-form-1" class="form-label">Default</label>
            <input
              id="booleanSwitch"
              type="checkbox"
              v-model="_formDataWarehouse.isDefault"
              class="form-check-input"
            />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Description</label>

          <div class="flex flex-col space-y-1">
            <textarea
              id="vertical-form-1"
              v-model="_formDataWarehouse.description"
              type="text"
              class="form-control"
              name="description"
              placeholder="Type here about Warehouse..."
            ></textarea>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="closeForm()"
        class="btn btn-outline-secondary w-24 mr-8"
      >
        Cancel
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        Done
      </button>
    </ModalFooter>
  </MoveableModal>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
import router from "../../router";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../global-functions/toast";

export default {
  components: {
    Loading,
  },
  props: {
    addWarehouse: false,
    formDataWarehouse: {},
    modalClose: Function,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {
    _addWarehouse(val) {
      val || this.closeForm();
    },
  },
  computed: {
    _addWarehouse: {
      get() {
        return this.addWarehouse;
      },
    },

    _formDataWarehouse: {
      get() {
        return this.formDataWarehouse;
      },
    },
  },
  created() {},
  methods: {
    closeForm() {
      return this.modalClose();
    },
    saveForm() {
      if (
        this._formDataWarehouse.name == "" ||
        this._formDataWarehouse.abbreviation == ""
      ) {
        if (this._formDataWarehouse.name == "") {
          warningToast("Name is Required");
        }
        if (this._formDataWarehouse.abbreviation == "") {
          warningToast("Abbreviation is Required");
        }
      } else {
        this.isLoading = true;
        axios
          .post(
            `${store.state.authUser.baseurl}Warehouses`,
            this._formDataWarehouse
          )
          .then(async (response) => {
            if (response.data.success === true) {
              this.isLoading = false;

              var pagination = {
                pageNumber: 1,
                pageSize: 10,
              };

              try {
                var responseWarehouse = await axios.post(
                  `${store.state.authUser.baseurl}Warehouses/Paginated`,
                  pagination
                );
                if (responseWarehouse.data.success) {
                  var defaultWh = responseWarehouse.data.data.items.find(
                    (x) => x.isDefault === true
                  );

                  store.dispatch(
                    "authUser/setWarehouse",
                    defaultWh === null || defaultWh === undefined
                      ? responseWarehouse.data.data.items[0]
                      : defaultWh
                  );
                } else {
                  failedToast(responseWarehouse.data.message);
                }
              } catch (error) {
                console.log("Add warehouse: Warehouse: Error: ", error);
              }

              //this._addWarehouse = false;

              // this.closeForm();
              location.reload();
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            failedToast(statusMessage(error.response.status));
          });
      }
    },
  },
};
</script>
