<template>
  <MoveableModal
    :show="modalStatus"
    @hidden="modalStatus = false"
    @close="modalStatus = false"
  >
    <ModalBody class="p-3">
      <div class="flex justify-start ...">
        <div>
          <LogOutIcon class="w-24 h-24 text-danger mx-auto mt-10 font-bold" />
        </div>
        <div>
          <div class="p-0 text-left ml-6">
            <div class="text-2xl font-bold mt-2 mb-2">Application SignOff</div>
            <div class="text-2xl mt-5 mb-6">
              Are you sure you wish to logout this session?
            </div>
            <!-- <div class="text-2xl mt-1 mb-6">Hi, {{ name }}!</div> -->
          </div>
        </div>
      </div>

      <div class="px-6 pb-8 text-center">
        <button
          type="button"
          @click="modalClose()"
          class="btn btn-outline-secondary w-24 mr-6"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="modalConfirm()"
        >
          Logout
        </button>
      </div>
    </ModalBody>
  </MoveableModal>
</template>
<script>
export default {
  props: {
    name: "",
    modalStatus: false,
    modalConfirm: Function,
    modalClose: Function,
  },
  watch: {
    modalStatus(val) {
      val || this.modalClose();
    },
  },
  created() {},
  methods: {
    modalClose() {
      return this.modalClose();
    },
  },
};
</script>
