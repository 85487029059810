<template>
  <Tippy
    tag="a"
    href="javascript:;"
    class="tooltip"
    content="Print Selected Labels"
    :options="{
      zIndex: 99999,
    }"
  >
    <a
      href="javascript:;"
      @click="buttonClick()"
      class="mr-2 w-full btn btn-primary shadow-md text-xs hover:text-yellow-200"
    >
      Print Set : {{ pageNum }}
    </a>
  </Tippy>
</template>
<script>
export default {
  props: { pageNum: "" },
  name: "PrintButton",
  methods: {
    buttonClick() {
      console.log("pageNum", this.pageNum);
      this.$emit("parentMethod", this.pageNum);
    },
  },
};
</script>
