<template>
  <div id="image-slider">
		<!-- <ul ref="reful" v-for="(item, i) in images" :key="i">
			<li><img :src= '`${item.image}`' ></li>
		</ul> -->
    <ul ref="reful">
			<li><img src="https://unsplash.imgix.net/reserve/N13RIliTTASBbuYyszgq_P9020914-54.jpg?fit=crop&fm=jpg&h=800&q=75&w=1050"></li>
			<li><img src="https://unsplash.imgix.net/uploads/1413386993023a925afb4/4e769802?fit=crop&fm=jpg&h=700&q=75&w=1050"></li>
      <li><img src="https://unsplash.imgix.net/reserve/N13RIliTTASBbuYyszgq_P9020914-54.jpg?fit=crop&fm=jpg&h=800&q=75&w=1050"></li>
		</ul>

	</div>
	<div class="controls">
		<button id="previous" @click="previous()" title="previous-image">Previous</button>
		|
		<button id="next" @click="next()" title="next-image">Next</button>
	</div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: "Slider",
  props: { images: [] },
 
  methods: {
    next(){
          let reful = this.$refs.reful;
          reful.style.marginTop = '-303px';
           reful.style.transition = 'all 1.5s ease-in-out';
         
        },
        previous(){
           let reful = this.$refs.reful;
          reful.style.marginTop = '0px';
         
        },
  },
  
};
</script>

<style scoped>
  * {
	padding: 0;
	margin:0;
}

body {
	font-family: verdana;
	color: #121212;
	background: #ffffff;
}

hgroup {
	text-transform: uppercase;
	padding: 1em 0;
	text-align: center;
}
hgroup h1 {
	font-size:2em;
	color:#9b59b6;
	font-weight: 800;
	letter-spacing: .1em;
	padding: .2em 0;
}
hgroup h2 {
	font-weight: 300;
	font-size: 1em;
	letter-spacing: .4em;
}

#image-slider {
	display: block;
	margin: 0 auto;
	width: 500px;
	height: 300px;
	overflow: hidden;
	background: #fff;
}
#image-slider ul {
	list-style: none;
}
#image-slider ul li {
	display: inline-block;
	margin: 0;
	padding: 0;
}
#image-slider ul img {
	width: 100%;
	max-width: 500px;
	height: 300px;
}
.controls {
	padding: 1em 0;
	width: 500px;
	display: block;
	margin: 0 auto;
}
.controls a {
	text-decoration: none;
	color: #34495e;
}
.controls a:hover {
	cursor: pointer;
}
.img-up {
	margin-top: -300px;
}
</style>