export const const_txt = {
  NO_DATA: "Currently No Data Available",

  NO_ORDER_LINE: "No Order Line",
  ADDED_ORDER_LINE: "Order Line Added Successfully",
  FAILED_ORDER_LINE: "Order Line Added Failed",
  REMOVED_ORDER_LINE: "Order Line Removed Successfully",
  CONFIRM_PICKING: "Added Order Line List",
  DEFAULT_PICKING: "Default Order Line List",

  SAVE_BTN: "Save",
  CANCEL_BTN: "Cancel",
  UPDATE_BTN: "Update",
  PRINT_BTN: "Print",
  ARCHIVE_BTN: "Archive",

  ADDED_ORDER_PICKING: "Order Picking Added Successfully",
  FAILED_ORDER_PICKING: "Order Picking Added Failed",

  NO_DATA_TABLE: "No Data",

  //Reports
  SELECT_SUPPLIER: "Please Select Supplier",
  SELECT_CUSTOMER: "Please Select Customer",
  SELECT_SKU_DESC:"Please Select SKU Description",
  SELECT_PRODUCT: "Please Select Product",
  SELECT_SECTION: "Please Select Section",
  SELECT_YEAR: "Please Select a Year",

  //Print
  BIN_PRINT_LIMIT:25,
  SKU_PRINT_LIMIT:25,
  DFLT_PRINT_LIMIT:100
};
