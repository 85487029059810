<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:justify-between md:flex md:flex-row md:items-center md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full">
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)"
          buttonName=" Add New Stocktake" />

        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport"
          :page="pagination.pageNumber" :selected="checkedData.length" @printSelected="printSelected"
          @csvExportSelected="csvExportSelected" @pdfExportSelected="pdfExportSelected" />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)"
          @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>
      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th></th>
            <th>Stocktake</th>
            <th>Date</th>
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>Completed</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)"
                @unSelectAll="unSelectAll($event)" />
            </th>
          </tr>
        </thead>
        <tbody v-for="(item, i) in items" :key="i">
          <tr class="intro-x">
            <td class="cursor-pointer"
              :style="opened.includes(item.stocktakeId) ? getSelectedColor() : 'color: #1a73e8'">
              <svg v-if="!opened.includes(item.stocktakeId)" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                class="bi bi-caret-down-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-primary text-bold"
                @click="toggle(item)" viewBox="0 0 16 16">
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>

              <svg v-if="opened.includes(item.stocktakeId)" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                class="bi bi-caret-up-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-white text-bold" viewBox="0 0 16 16"
                @click="closeToggle(item)">
                <path
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </td>
            <td :style="opened.includes(item.stocktakeId) ? getSelectedColor() : ''">
              {{ item.description }}
            </td>
            <td :style="opened.includes(item.stocktakeId) ? getSelectedColor() : ''">
              {{ item.date === null || item.date === undefined ? "" : momentLocal(item.date) }}
            </td>
            <td v-for="(header, index) in headers" :key="index"
              :style="opened.includes(item.stocktakeId) ? getSelectedColor() : ''">
              {{ item[header.value] }}
            </td>
            <td :style="opened.includes(item.stocktakeId) ? getSelectedColor() : ''">
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.completedFlag" class="form-check-input"
                  disabled />
              </div>
            </td>

            <!-- <td>
              <FlagTriangleRightIcon
                v-if="item.completedFlag"
                class="bg-green-700 lg:w-6 lg:h-6 w-4 h-4"
              />

              <FlagTriangleRightIcon v-else class="bg-red-700 lg:w-6 lg:h-6 w-4 h-4" />
            </td> -->

            <td class="table-report__action w-32">
              <div class="lg:grid lg:grid-cols-4 gap-x-6 gap-y-2 p-2 ml-3 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <!-- <InfoItem
                  @parentMethod="viewItemInfo($event)"
                  :item="item"
                  name="INFO"
                /> -->

                <a @click="applyStock(item)" class="flex items-center text-yellow-600" href="javascript:;">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Apply stock" :options="{
                    zIndex: 99999,
                  }">
                    <PackagePlusIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                  </Tippy>
                </a>

                <a @click="varianceReport(item)" class="flex items-center text-sky-600" href="javascript:;">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Variance Report" :options="{
                    zIndex: 99999,
                  }">
                    <FileIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                  </Tippy>
                </a>

                <EditItem v-if="!item.completedFlag" :activeAccess="$h.editItemAccess(policyAccess)"
                  @parentMethod="editItem($event)" :item="item" name="Edit" />
                <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)"
                  :item="item" name="Archive" />
              </div>
            </td>
          </tr>

          <tr v-if="opened.includes(item.stocktakeId)">
            <td colspan="13">
              <div class="flex justify-between items-center">
                <div class="flex items-center" v-if="!addProductModalForm">
                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                    @click="printProductsPdf()">
                    <PrinterIcon class="w-4 h-4 mr-2" /> Print
                  </button>

                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                    @click="downloadProductsPdf()">
                    <FileTextIcon class="w-4 h-4 mr-2" /> Download PDF
                  </button>
                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                    @click="csvProductsDownload()">
                    <FileIcon class="w-4 h-4 mr-2" /> Download CSV
                  </button>
                </div>
              </div>
              <table class="table table-report -mt-2 table-auto">
                <thead>
                  <tr class="uppercase whitespace-nowrap">
                    <th>Description</th>
                    <th class="whitespace-nowrap" v-for="(header, i) in stockHeaders" :key="i">
                      {{ header.text }}
                    </th>
                    <th>MFD</th>
                    <th>EXP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="stockTakeItems.length <= 0">
                    <td colspan="12">
                      <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_ORDER_LINE
                        }}</span>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in stockTakeItems" :key="index" class="intro-x">
                    <td>
                      <div class="text-left line-clamp-2">
                        <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.skuDescription">
                          {{ item.skuDescription }}
                        </Tippy>
                      </div>
                    </td>

                    <td v-for="(header, index) in stockHeaders" :key="index">
                      {{ item[header.value] }}
                    </td>
                    <td>
                      {{ item.manufactureDate != null ? momentLocal(item.manufactureDate) : "" }}
                    </td>
                    <td>
                      {{ item.expirationDate != null ? momentLocal(item.expirationDate) : "" }}
                    </td>
                    <td class="table-report__action w-32">
                      <div class="lg:grid lg:grid-cols-4 gap-x-6 gap-y-2 p-2 ml-3 flex flex-wrap">
                        <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <PaginationBar @changePage="changePageStock($event)" @pageSizeChange="pageSizeChangeStock($event)"
                :itemsData="stockItemsData" :pageCount="pageCountStock" :pageSize="pageSizeStock" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />

    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData"
      :pageCount="pageCount" :pageSize="pageSizeView" />
    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addStocktake" @hidden="addStocktake = false" backdrop="static" :title="formTitle"
    @close="addStocktake = false">
    <ModalBody class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
      <div class="flex flex-col w-full col-span-2 space-y-1">
        <label for="regular-form-1" class="form-label">Date</label>

        <div class="flex flex-col space-y-1">
          <PreviewComponent class="intro-y">
            <Litepicker v-model="formData.date" :options="{
              autoApply: true,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: 2050,
                months: true,
                years: true,
              },
            }" class="form-control" />
          </PreviewComponent>
        </div>
      </div>
      <div class="flex flex-col w-full space-y-1">
        <label for="regular-form-1" class="form-label">Warehouse</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="warehouseAbbreviation" type="text" class="form-control"
            autocomplete="off" disabled />
        </div>
      </div>
      <div class="flex flex-col w-full col-span-2 space-y-1">
        <label for="regular-form-1" class="form-label">Note</label>
        <div class="flex flex-col space-y-1">
          <textarea id="vertical-form-1" v-model="formData.note" type="text" class="form-control" name="note"
            placeholder="note" />
        </div>
      </div>
      <!-- <div class="flex flex-col w-full col-span-2 space-y-1">
        <label for="regular-form-1" class="form-label">Stocktake</label>
        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formData.description"
            type="text"
            class="form-control"
            name="description"
            placeholder="description"
          />
        </div>
      </div> -->

      <div class="flex flex-col w-full space-y-1">
        <label>Completed Flag</label>
        <div class="form-switch">
          <input type="checkbox" v-model="formData.completedFlag" class="form-check-input" />
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">Done</button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <MoveableModal :show="openVarianceReport" @hidden="openVarianceReport = false" size="modal-sl-80" backdrop="static"
    title="Variance Report" @close="closeVarianceReport">
    <ModalBody class="flex flex-col w-full justify-center items-center p-2">
      <hr />
      <!-- SEARCH -->

      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <Export class="ml-auto" @printCurrentPage="printReportCurrentPage" @csvExport="csvReportExport"
        @pdfExport="pdfReportExport" :selected="checkedReportData.length" @printSelected="printReportSelected"
        @csvExportSelected="csvReportExportSelected" @pdfExportSelected="pdfReportExportSelected" />

      <div class="intro-y col-span-12 overflow-auto p-4 w-full">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in varianceHeader" :key="i">
                {{ header.text }}
              </th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allReportSelected" @selectAll="selectReportAll($event)"
                  @unSelectAll="unSelectReportAll($event)" />
              </th>
            </tr>
          </thead>
          <tbody v-if="varianceItems?.length > 0">
            <tr v-for="(item, i) in varianceItems" :key="i" class="intro-x">
              <td v-for="(header, index) in varianceHeader" :key="index">
                <template v-if="header.value === 'skuDescription'">
                  <div class="break-all w-56">{{ item[header.value] }}</div>
                </template>
                <template v-else>
                  {{ item[header.value] }}
                </template>
              </td>
              <td class="table-report__action w-32">
                <div class="lg:grid lg:grid-cols-4 gap-x-6 gap-y-2 p-2 ml-3 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedReportData" @parentMethod="checkReportItem($event)" />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="intro-x">
              <td colspan="12" class="text-center">No Data</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- BEGIN: Archive Modal -->
  <MoveableModal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static"
    title="Archived Unit" @close="archiveModal = false">
    <ModalBody class="p-0">
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input type="text" class="form-control w-full box pr-10" placeholder="Search..." v-model="searchDataArchive"
            @change="searchOnchangeArchive" autocomplete="off" clearable />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>Date</th>
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Completed Flag</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td>
                {{ item.date === null || item.date === undefined ? "" : momentLocal(item.date) }}
              </td>
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <td class="justify-center">
                <div v-if="item.completedFlag">
                  <FlagIcon class="text-success lg:w-6 lg:h-6 w-4 h-4" />
                </div>
                <div v-else>
                  <FlagIcon class="text-danger lg:w-6 lg:h-6 w-4 h-4" />
                </div>
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)"
                    :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)"
                    :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <PaginationBar @changePage="changePageArchive($event)" @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="itemsData" :pageCount="pageCountArchive" :pageSize="pageSizeArchive" />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: View  Info Modal -->

  <!-- <MoveableModal
    :show="viewInfoModal"
    @hidden="viewInfoModal = false"
    backdrop="static"
    size="modal-sl-60"
    :title="formData.description + ' - INFO'"
    @close="viewInfoModal = false"
  >
    <ModalBody class="p-2">
      <div
        class="flex flex-nowrap cursor-pointer text-sm font-semibold pl-2 text-info"
      >
        Existing Stock Products
      </div>
      <div
        class="p-5 text-center text-lg"
        v-if="this.stockItemsData.totalCount == 0"
      >
        No data
      </div>
      <div
        class="intro-y col-span-12 overflow-auto p-4"
        v-if="this.stockItemsData.totalCount > 0"
      >
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th
                class="whitespace-nowrap"
                v-for="(header, i) in stockHeaders"
                :key="i"
              >
                {{ header.text }}
              </th>
              <th>MFD</th>
              <th>EXP</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in stockTakeItems" :key="i" class="intro-x">
              <td v-for="(header, index) in stockHeaders" :key="index">
                {{ item[header.value] }}
              </td>
              <td>
                {{
                  item.manufactureDate != null
                    ? momentLocal(item.manufactureDate)
                    : ""
                }}
              </td>
              <td>
                {{
                  item.expirationDate != null
                    ? momentLocal(item.expirationDate)
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationBar
        @changePage="changePageStock($event)"
        @pageSizeChange="pageSizeChangeStock($event)"
        :itemsData="stockItemsData"
        :pageCount="pageCountStock"
        :pageSize="pageSizeStock"
      />

    </ModalBody>
  </MoveableModal> -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formData.unitName" :modalStatus="archiveConfirmationModal"
    :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formData.unitName" :modalStatus="restoreConfirmationModal"
    :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formData.unitName" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm"
    :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->

  <ConfirmAlert :modalStatus="applyStockModalConfirm" :modalConfirm="applyStockConfirm" :modalClose="closeApplyStock"
    :isClose="true" icon="PackagePlusIcon" color="warning" :question="`Do you want to apply this stock?`"
    nameBtn="Okay" />
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";
import moment from "moment";
import store from "../../../store";

// import { permissionHelper as $h } from "@/utils/permissionHelper";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Stocktake");
  },
  components: {},
  data() {
    return {
      applyStockModalConfirm: false,
      stockTakeItems: [],
      viewInfoModal: false,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addStocktake: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formData: {
        // description: "",
        note: "",
        date: "",
        completedFlag: false,
        warehouse: store.state.authUser.warehouse.abbreviation,
      },
      formDataDefault: {
        // description: "",
        note: "",
        date: "",
        completedFlag: false,
        warehouse: store.state.authUser.warehouse.abbreviation,
      },

      rules: {
        unitName: {
          required,
          minLength: minLength(2),
        },
        UnitDescription: {
          required,
          minLength: minLength(2),
        },
      },

      headers: [
        // { text: "Stocktake", value: "description" },
        { text: "Note", value: "note" },
      ],
      stockHeaders: [
        { text: "PLU/SKU", value: "skuName" },
        { text: "Barcode/Ean", value: "skuEan" },
        { text: "Lot", value: "lot" },
        { text: "Stock Qty", value: "stocktakeQuantity" },
        { text: "System Qty", value: "currentQuantity" },
        { text: "Adjustmen Qty", value: "variance" },
        { text: "Bin", value: "binAbbreviation" },
      ],
      headersFilter: [{ text: "Unit Name", value: "unitName" }],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      stockItemsData: [],
      stockItems: [],
      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "unitName",
        // sortDirection: "asc",
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      pageCountArchive: 20,

      allItems: [],
      allItemsData: [],

      isActive: 0,
      docs: null,

      allSelected: false,
      checkedData: [],

      opened: [],
      indexToggle: 0,

      policyAccess: "Stocktake",

      isReportActive: 0,
      rdocs: null,
      allReportSelected: false,
      checkedReportData: [],
      openVarianceReport: false,
      varianceItems: [],
      varianceHeader: [
        { text: "Description", value: "skuDescription" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "BARCODE/EAN", value: "skuEan" },
        { text: "StockTake Qty", value: "stocktakeQuantity" },
        { text: "Current Qty", value: "currentQuantity" },
        { text: "Adjustment QTY", value: "variance" },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Stocktake" : this.formData?.description ? `Edit Stocktake - ${this.formData?.description}` : "Edit Stocktake";
    },
  },
  created() {
    this.$signalRHub.emitter.on("StocktakeProductAdded", this.stocktakeProductAdded);
    this.getDataFromApi();
  },
  beforeDestroy() {
    this.$signalRHub.emitter.off("StocktakeProductAdded", this.stocktakeProductAdded);
  },
  methods: {
    applyStock(item) {
      this.applyStockModalConfirm = true;
      this.formData = Object.assign({}, item);
    },
    closeApplyStock() {
      this.applyStockModalConfirm = false;
      this.setDefault();
    },
    applyStockConfirm() {
      this.isLoading = true;
      axios
        .get(`Stocks/ChangeStocksByStocktake/${this.formData.stocktakeId}`)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        })
        .finally(() => {
          this.closeApplyStock();
        });
    },
    varianceReport(item) {
      this.isLoading = true;
      axios
        .get(`Stocktake/${item.stocktakeId}/ProductVarianceReport`)
        .then((response) => {
          if (response.data.success === true) {
            this.varianceItems = response.data.data;
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
      this.openVarianceReport = true;
    },

    closeVarianceReport() {
      this.openVarianceReport = false;
      this.varianceItems = [];
      this.checkedReportData = [];
    },
    checkProductItem(item) {
      var include = this.checkedProductData.includes(item);
      if (include) {
        const index = this.checkedProductData.indexOf(item);
        if (index > -1) {
          this.checkedProductData.splice(index, 1);
        }
      } else {
        this.checkedProductData.push(item);
      }
    },

    customizeDataAndTime(date) {
      return moment(date).format("lll");
    },
    csvProductsDownload() {
      if (this.items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "StockTakeProducts " + generateDate;
        var items = this.stockTakeItems;
        var csvItems = [];

        var csvHeaders = {
          1: "PLU/SKU",
          2: "Lot",
          3: "Stock Qty",
          4: "System Qty",
          5: "Adjustmen Qty",
          6: "Bin Id",
          7: "MFD",
          8: "EXP",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.skuId,
            2: item.lot,
            3: item.stocktakeQuantity,
            4: item.currentQuantity,
            5: item.adjustmentQuantity,
            6: item.storageBinId,
            7: this.customizeDataAndTime(item.manufactureDate),
            8: this.customizeDataAndTime(item.expirationDate),
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },
    printProductsPdf() {
      var docs = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });
      this.productsInfopdf(docs);
      docs.autoPrint();
      docs.output("dataurlnewwindow");
    },
    downloadProductsPdf() {
      var docs = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });
      this.productsInfopdf(docs);
      var generateDate = new Date().toLocaleString();
      docs.save(`${this.description + "_" + generateDate}.pdf`);
    },
    productsInfopdf(docs) {
      return new Promise(async (resolve, reject) => {
        try {
          var item = this.stockTakeItems;
          if (item <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "PLU/SKU", dataKey: "skuId" },
              { title: "Lot", dataKey: "lot" },
              { title: "Stock Qty", dataKey: "stocktakeQuantity" },
              { title: "System Qty", dataKey: "currentQuantity" },
              { title: "Adjustmen Qty", dataKey: "adjustmentQuantity" },
              { title: "Bin Id", dataKey: "storageBinId" },
              { title: "MFD", dataKey: "manufactureDate" },
              { title: "EXP", dataKey: "expirationDate" },
            ];

            let data = [];
            let i = 0;
            let obj;
            removeNullValueObjectArray(item);
            item.forEach((el) => {
              i += 1;
              obj = {
                skuId: el.skuId,
                lot: el.lot,
                stocktakeQuantity: el.stocktakeQuantity,
                currentQuantity: el.currentQuantity,
                adjustmentQuantity: el.adjustmentQuantity,
                storageBinId: el.storageBinId,
                manufactureDate: this.momentLocal(el.manufactureDate),
                expirationDate: this.momentLocal(el.expirationDate),
              };
              //i++;
              data.push(obj);
              //   console.log(obj);
            });

            autoTable(docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              docs.setFontSize(10);
              docs.text(footer, pageWidth / 2 - docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    toggle(item) {
      this.opened.splice(this.indexToggle, 1);
      this.indexToggle = this.opened.indexOf(item.stocktakeId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.description = item.description;
        this.getStocktakeProducts(item.stocktakeId);
        this.opened.push(item.stocktakeId);
      }
    },
    closeToggle(item) {
      this.indexToggle = this.opened.indexOf(item.stocktakeId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.description = item.description;
        this.getStocktakeProducts(item.stocktakeId);
        this.opened.push(item.stocktakeId);
      }
    },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white;";
    },
    stocktakeProductAdded(data) {
      successToast("New stocktake product added");
      if (this.stocktakeId === data.stocktakeId) {
        this.getDataFromApi();
        this.getStocktakeProducts();
      }
    },

    // viewItemInfo(item) {
    //   removeNullValue(item);
    //   this.formData = Object.assign({}, item);
    //   this.stocktakeId = item.stocktakeId;
    //   this.isLoading = true;
    //   this.getStocktakeProducts();
    // },
    getStocktakeProducts(id) {
      axios
        .post(`StocktakeProduct/${id}/Paginated`, this.pagination)
        .then((response) => {
          this.stockItemsData = response.data.data;
          this.stockTakeItems = response.data.data.items;
          this.pageCountStock = this.stockItemsData.totalPages;

          if (this.pagination.pageNumber == this.stockItemsData.totalPages) {
            this.pageSizeStock = this.stockItemsData.totalCount - (this.pageCountStock - 1) * this.pagination.pageSize;
          } else {
            this.pageSizeStock = this.pagination.pageSize;
          }

          this.isLoading = false;
          this.viewInfoModal = true;
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },

    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    checkReportItem(item) {
      var include = this.checkedReportData.includes(item);
      if (include) {
        const index = this.checkedReportData.indexOf(item);
        if (index > -1) {
          this.checkedReportData.splice(index, 1);
        }
      } else {
        this.checkedReportData.push(item);
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },

    setReports() {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "Date", dataKey: "date" },
              { title: "Stocktake", dataKey: "description" },
              { title: "Note", dataKey: "note" },
              { title: "Flag", dataKey: "completedFlag" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                date: this.momentLocal(el.date),
                description: el.description,
                note: el.note,
                completedFlag: el.completedFlag,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    setVarianceReports() {
      return new Promise(async (resolve, reject) => {
        if (this.isReportActive == 4) {
          var items = this.checkedReportData;
        } else if (this.isReportActive == 0) {
          var items = this.varianceItems;
        } else {
          var items = this.varianceItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "Description", dataKey: "skuDescription" },
              { title: "PLU/SKU", dataKey: "skuName" },
              { title: "BARCODE/EAN", dataKey: "skuEan" },
              { title: "StockTake Qty", dataKey: "stocktakeQuantity" },
              { title: "Current Qty", dataKey: "currentQuantity" },
              { title: "Adjustment QTY", dataKey: "variance" },
            ];
            this.rdocs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                skuDescription: el.skuDescription,
                skuName: el.skuName,
                skuEan: el.skuEan,
                stocktakeQuantity: el.stocktakeQuantity,
                currentQuantity: el.currentQuantity,
                variance: el.variance,
              };

              data.push(obj);
            });

            autoTable(this.rdocs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.rdocs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.rdocs.setPage(z);
              const pageSize = this.rdocs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.rdocs.setFontSize(10);
              this.rdocs.text(footer, pageWidth / 2 - this.rdocs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printReportSelected() {
      this.isReportActive = 4;
      this.pdfReportPrint();
    },
    pdfReportExportSelected() {
      this.isReportActive = 4;
      this.pdfReportDownload();
    },
    csvReportExportSelected() {
      this.isReportActive = 4;
      this.csvReportDownload();
    },

    printReportCurrentPage() {
      this.isReportActive = 0;
      this.pdfReportPrint();
    },

    pdfReportPrint() {
      this.setVarianceReports();
      this.rdocs.autoPrint();
      this.rdocs.output("dataurlnewwindow");
    },

    pdfReportExport() {
      this.isReportActive = 3;
      this.pdfReportDownload();
    },

    pdfReportDownload() {
      this.setVarianceReports();
      var generateDate = new Date().toLocaleString();
      this.rdocs.save(`${"Variance Report_" + generateDate}.pdf`);
    },

    csvReportExport() {
      this.isReportActive = 2;
      this.csvReportDownload();
    },

    csvReportDownload() {
      if (this.varianceItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Variance Report " + generateDate;
        var items = this.varianceItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Description",
          2: "PLU/SKU",
          3: "BARCODE/EAN",
          4: "StockTake Qty",
          5: "Current Qty",
          6: "Adjustment QTY",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.skuDescription,
            2: item.skuName,
            3: item.skuEan,
            4: item.stocktakeQuantity,
            5: item.currentQuantity,
            6: item.variance,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    unSelectReportAll() {
      this.allReportSelected = false;
      this.checkedReportData = [];
    },
    selectReportAll() {
      this.allReportSelected = true;
      this.checkedReportData = [];
      for (var i = 0; i < this.varianceItems.length; i++) {
        this.checkedReportData[i] = this.varianceItems[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Stocktake " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Stocktake " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Date",
          2: "Stocktake",
          3: "Note",
          4: "Flag",
        };

        items.forEach((item) => {
          csvItems.push({
            1: this.momentLocalCSV(item.date),
            2: item.description,
            3: item.note,
            4: item.completedFlag,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post(`Stocktake/${this.warehouseAbbreviation}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData; //newly added line
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formData = Object.assign({}, this.formDataDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(`Stocktake/${this.warehouseAbbreviation}/Paginated`, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(error);
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addStocktake = true;
    },
    closeForm() {
      this.addStocktake = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formData = Object.assign({}, item);
      this.addStocktake = true;
      this.formData.date = this.momentLocal(this.formData.date);
    },

    saveForm() {
      if (this.formData.unitName == "") {
        if (this.formData.unitName == "") {
          warningToast("Name Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Stocktake", this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addStocktake = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formData.stocktakeId;
          this.isLoading = true;
          axios
            .put(`Stocktake/${itemId}`, this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addStocktake = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formData = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formData.stocktakeId;
      this.isLoading = true;
      axios
        .get("Stocktake/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post(`Stocktake/${this.warehouseAbbreviation}/Archived`, this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    changePageStock(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.viewItemInfo(item);
    },
    pageSizeChangeStock(pageSize) {
      this.pageSizeStock = pageSize;
      this.pagination.pageSize = this.pageSizeStock;
      this.viewItemInfo(item);
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formData = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formData.stocktakeId;
      this.isLoading = true;
      axios
        .get("Stocktake/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formData = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      var itemId = this.formData.stocktakeId;
      this.isLoading = true;
      axios
        .delete("Stocktake/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
