<template>
  <div
    v-if="itemsData?.totalCount > 0"
    style=" z-index: 0 !important;"
    class="mt-2 rounded-lg intro-y bg-primary lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
  >
    <div class="rounded-lg w-full sm:w-auto sm:mr-auto bg-slate-200">
      <paginate
        class="text-white"
        :page-count="pageCount"
        :page-range="5"
        :margin-pages="2"
        :click-handler="changePage"
        :force-page="pageNumber"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :first-last-button="true"
        :initial-page="1"
        :prev-text="'<'"
        :next-text="'>'"
        :first-button-text="'<<'"
        :last-button-text="'>>'"
      >
      </paginate>
    </div>
    <div class="mr-2 text-white">Page Rows :</div>
    <select
    v-model="_pageSize"
      class="w-20 form-select box cursor-pointer rounded-lg"
    
    >
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
      <option value="25">25</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="50">50</option>
      <option value="100">100</option>
      <option :value="_pageSize"> {{ _pageSize }}</option>
      <option :value="itemsData.totalCount">{{ itemsData.totalCount }}</option>
      
    </select>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate-next";

export default {
  props: { itemsData: {}, pageSize: 0, pageCount: 0, pageNumber: 1 },
  name: "SearchBar",
  components: {
    paginate: Paginate,
  },
  data: () => ({
    pageCount: 0,
  }),
  computed:{
    _pageSize:{
      get(){
        return this.pageSize
      },
      set(val){
        this.$emit("pageSizeChange", val);
      }
    }
  },
  watch:{
    pageNumber(val){
      this.changePage(val);
    }
  },
  methods: {
    changePage(pageNum) {
      console.log('pageNum',pageNum);
      this.$emit("changePage", pageNum);
    },
  },
};
</script>
