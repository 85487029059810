<template>
  <div class="w-full flex flex-col justify-center items-center space-y-4">
    <div class="w-full">
      <Selection
        v-model="_vmodel"
        :key="key"
        :getOptionLabel="getOptionLabel"
        :list="list"
        :getValue="getValue"
        :label="label"
        :hasNextPage="hasNextPage"
        :headers="headers"
        :observer="observer"
        :pageNumber="pageNumber"
        :isSearchResult="isSearchResult"
        @search="search"
      />
    </div>
    <div class="w-full" v-if="_vmodel !== null || _vmodel !== undefined">
      hi
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectionWithDetails",

  data() {
    return {};
  },
  props: {
    modelValue: "",
    key: "",
    getOptionLabel: "",
    list: [],
    getValue: "",
    label: "",
    hasNextPage: false,
    headers: [],
    pageNumber: 0,
    observer: null,
    isSearchResult: false,
  },
  created() {},
  computed: {
    _vmodel: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    _pageNumber: {
      get() {
        return this.pageNumber;
      },
      set(val) {
        this.$emit("update:pageNumber", val);
      },
    },
  },
  methods: {
    // onOpen() {
    //   console.log("getvalue", this.getValue);
    //   // {
    //   //     page:1,
    //   //     itemPerPage: 10,
    //   //     search: '',
    //   //     callback(items, totalItems){

    //   //     }
    //   // }
    //   this.$emit("onOpen");
    // },
    async onOpen() {
      if (this.isSearchResult) {
        this._pageNumber = 1;
      }
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onCloseSku() {
      this.observer.disconnect();
    },

    search() {
      this.$emit("search");
    },
  },
};
</script>
