import axios from "../services/http-common";

const state = {
  localIp:
    localStorage.getItem("localIp") === null ||
    localStorage.getItem("localIp") === undefined
      ? null
      : localStorage.getItem("localIp"),
  publicIp:
    localStorage.getItem("publicIp") === null ||
    localStorage.getItem("publicIp") === undefined
      ? null
      : localStorage.getItem("publicIp"),

  baseurl:
    localStorage.getItem("baseurl") === null ||
    localStorage.getItem("baseurl") === undefined
      ? null
      : JSON.parse(localStorage.getItem("baseurl")),
  username:
    localStorage.getItem("username") === null ||
    localStorage.getItem("username") === undefined
      ? null
      : localStorage.getItem("username"),
  firstName:
    localStorage.getItem("firstName") === null ||
    localStorage.getItem("firstName") === undefined
      ? null
      : localStorage.getItem("firstName"),
  lastName:
    localStorage.getItem("lastName") === null ||
    localStorage.getItem("lastName") === undefined
      ? null
      : localStorage.getItem("lastName"),
  token:
    localStorage.getItem("token") === null ||
    localStorage.getItem("token") === undefined
      ? null
      : localStorage.getItem("token"),
  refreshToken:
    localStorage.getItem("refreshToken") === null ||
    localStorage.getItem("refreshToken") === undefined
      ? null
      : localStorage.getItem("refreshToken"),
  permissions:
    localStorage.getItem("permissions") === null ||
    localStorage.getItem("permissions") === undefined
      ? []
      : localStorage.getItem("permissions"),
  isRefreshing: true,
  warehouseAbb:
    localStorage.getItem("warehouseAbb") === null ||
    localStorage.getItem("warehouseAbb") === undefined
      ? null
      : localStorage.getItem("warehouseAbb"),

  warehouses:
    localStorage.getItem("warehouses") === null ||
    localStorage.getItem("warehouses") === undefined
      ? null
      : JSON.parse(localStorage.getItem("warehouses")),
  licensees:
    localStorage.getItem("licensees") === null ||
    localStorage.getItem("licensees") === undefined
      ? null
      : JSON.parse(localStorage.getItem("licensees")),

  warehouse:
    localStorage.getItem("warehouse") === null ||
    localStorage.getItem("warehouse") === undefined
      ? null
      : JSON.parse(localStorage.getItem("warehouse")),
  licensee:
    localStorage.getItem("licensee") === null ||
    localStorage.getItem("licensee") === undefined
      ? null
      : JSON.parse(localStorage.getItem("licensee")),
  licenseeId:
    localStorage.getItem("licenseeId") === null ||
    localStorage.getItem("licenseeId") === undefined
      ? null
      : JSON.parse(localStorage.getItem("licenseeId")),
  userRole:
    localStorage.getItem("userRole") === null ||
    localStorage.getItem("userRole") === undefined
      ? null
      : JSON.parse(localStorage.getItem("userRole")),
  softClientLogo:
    localStorage.getItem("softClientLogo") === null ||
    localStorage.getItem("softClientLogo") === undefined
      ? null
      : JSON.parse(localStorage.getItem("softClientLogo")),

  OrderStatuses:
    localStorage.getItem("OrderStatuses") === null ||
    localStorage.getItem("OrderStatuses") === undefined
      ? null
      : JSON.parse(localStorage.getItem("OrderStatuses")),
  OrderTypes:
    localStorage.getItem("OrderTypes") === null ||
    localStorage.getItem("OrderTypes") === undefined
      ? null
      : JSON.parse(localStorage.getItem("OrderTypes")),
  InvoiceStatuses:
    localStorage.getItem("InvoiceStatuses") === null ||
    localStorage.getItem("InvoiceStatuses") === undefined
      ? null
      : JSON.parse(localStorage.getItem("InvoiceStatuses")),
  OrderLineStatuses:
    localStorage.getItem("OrderLineStatuses") === null ||
    localStorage.getItem("OrderLineStatuses") === undefined
      ? null
      : JSON.parse(localStorage.getItem("OrderLineStatuses")),
  PurchaseInvoiceStatuses:
    localStorage.getItem("PurchaseInvoiceStatuses") === null ||
    localStorage.getItem("PurchaseInvoiceStatuses") === undefined
      ? null
      : JSON.parse(localStorage.getItem("PurchaseInvoiceStatuses")),
  PickingTypes:
    localStorage.getItem("PickingTypes") === null ||
    localStorage.getItem("PickingTypes") === undefined
      ? null
      : JSON.parse(localStorage.getItem("PickingTypes")),
  PurchaseOrderStatus:
    localStorage.getItem("PurchaseOrderStatus") === null ||
    localStorage.getItem("PurchaseOrderStatus") === undefined
      ? null
      : JSON.parse(localStorage.getItem("PurchaseOrderStatus")),
  PickingOrderStatus:
    localStorage.getItem("PickingOrderStatus") === null ||
    localStorage.getItem("PickingOrderStatus") === undefined
      ? null
      : JSON.parse(localStorage.getItem("PickingOrderStatus")),
  ProductTypes:
    localStorage.getItem("ProductTypes") === null ||
    localStorage.getItem("ProductTypes") === undefined
      ? null
      : JSON.parse(localStorage.getItem("ProductTypes")),
  PaymentTypes:
    localStorage.getItem("PaymentTypes") === null ||
    localStorage.getItem("PaymentTypes") === undefined
      ? null
      : JSON.parse(localStorage.getItem("PaymentTypes")),
  ReturnedProductStates:
    localStorage.getItem("ReturnedProductStates") === null ||
    localStorage.getItem("ReturnedProductStates") === undefined
      ? null
      : JSON.parse(localStorage.getItem("ReturnedProductStates")),
  branch: localStorage.getItem("Branch")
    ? JSON.parse(localStorage.getItem("Branch"))
    : undefined,
};

const mutations = {
  auth_success(state, user) {
    //let warehouse = user.warehouse;
    // console.log("userData", user);
    // console.log("userData user",  user.user);
    // console.log("userData warehouse",  user.warehouse);
    // console.log("userData warehouseAbb",  user.warehouseAbb);
    // console.log("userData permissions",  user.permissions);
    // console.log("userData token",  user.token);

    state.username = user.user;
    state.firstName = user.firstName;
    state.lastName = user.lastName;
    state.token = user.token;
    state.refreshToken = user.refreshToken;
    state.permissions = user.permissions;
    // state.warehouse = user.warehouse;

    localStorage.setItem("username", user.user);
    localStorage.setItem("firstName", user.firstName);
    localStorage.setItem("lastName", user.lastName);
    localStorage.setItem("token", user.token);
    localStorage.setItem("refreshToken", user.refreshToken);
    localStorage.setItem("permissions", user.permissions);

    if (user.branchId) {
      axios.get(`/Branches/${user.branchId}`).then((res) => {
        if (res.data?.data) {
          state.branch = res.data?.data;
          localStorage.setItem("Branch", JSON.stringify(res.data?.data));
        }
      });
    }
    // localStorage.setItem("warehouse", JSON.stringify(user.warehouse));

    // try {
    //   this._vm.$signalR.setupSignalRConnection();
    // } catch (error) {
    //   console.log("authUser: startSignalR:", error);
    // }
  },

  auth_error(state) {
    state.username = null;
    state.firstName = null;
    state.lastName = null;
    state.token = null;
    state.localIp = null;
    state.publicIp = null;
    state.refreshToken = null;
    state.permissions = [];
    state.warehouse = null;
    state.licensee = null;

    state.warehouses = null;
    state.licensees = null;

    state.licenseeId = null;
    state.userRole = null;
    state.warehouseAbb = null;
    state.softClientLogo = null;
    state.baseurl = null;

    state.OrderStatuses = null;
    state.OrderTypes = null;
    state.InvoiceStatuses = null;
    state.OrderLineStatuses = null;
    state.PurchaseInvoiceStatuses = null;
    state.PickingTypes = null;
    state.PurchaseOrderStatus = null;
    state.PickingOrderStatus = null;
    state.ProductTypes = null;
    state.PaymentTypes = null;
    state.ReturnedProductStates = null;

    localStorage.removeItem("username");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("token");
    localStorage.removeItem("localIp");
    localStorage.removeItem("publicIp");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("warehouse");
    localStorage.removeItem("licensee");

    localStorage.removeItem("warehouses");
    localStorage.removeItem("licensees");

    localStorage.removeItem("licenseeId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("softClientLogo");
    localStorage.removeItem("warehouseAbb");

    localStorage.removeItem("baseurl");

    localStorage.removeItem("OrderStatuses");
    localStorage.removeItem("OrderTypes");
    localStorage.removeItem("InvoiceStatuses");
    localStorage.removeItem("OrderLineStatuses");
    localStorage.removeItem("PurchaseInvoiceStatuses");
    localStorage.removeItem("PickingTypes");
    localStorage.removeItem("PurchaseOrderStatus");
    localStorage.removeItem("PickingOrderStatus");
    localStorage.removeItem("ProductTypes");
    localStorage.removeItem("PaymentTypes");
    localStorage.removeItem("ReturnedProductStates");
    localStorage.removeItem("Branch");
  },
  warehouse(state, warehouse) {
    console.log("setWarehouse", warehouse);
    state.warehouse = warehouse;
    localStorage.setItem("warehouse", JSON.stringify(warehouse));

    //  state.warehouseAbb = warehouse;
    // localStorage.setItem("warehouseAbb", JSON.stringify(warehouse));
  },

  firstName(state, firstName) {
    state.firstName = firstName;
    localStorage.setItem("firstName", JSON.stringify(firstName));
  },

  lastName(state, lastName) {
    state.lastName = lastName;
    localStorage.setItem("lastName", JSON.stringify(lastName));
  },

  licensee(state, licensee) {
    console.log("setLicensee", licensee);
    state.licensee = licensee;
    localStorage.setItem("licensee", JSON.stringify(licensee));
  },

  warehouses(state, warehouses) {
    console.log("setWarehouses", warehouses);
    state.warehouses = warehouses;
    localStorage.setItem("warehouses", JSON.stringify(warehouses));
  },
  licensees(state, licensees) {
    console.log("setLicensees", licensees);
    state.licensees = licensees;
    localStorage.setItem("licensees", JSON.stringify(licensees));
  },

  licenseeId(state, licenseeId) {
    state.licenseeId = licenseeId;
    localStorage.setItem("licenseeId", JSON.stringify(licenseeId));
  },
  userRole(state, userRole) {
    state.userRole = userRole;
    localStorage.setItem("userRole", JSON.stringify(userRole));
  },
  baseurl(state, baseurl) {
    state.baseurl = baseurl;
    localStorage.setItem("baseurl", JSON.stringify(baseurl));
  },
  localIp(state, localIp) {
    state.localIp = localIp;
    localStorage.setItem("localIp", JSON.stringify(localIp));
  },
  publicIp(state, publicIp) {
    state.publicIp = publicIp;
    localStorage.setItem("publicIp", JSON.stringify(publicIp));
  },
  softClientLogo(state, softClientLogo) {
    state.softClientLogo = softClientLogo;
    localStorage.setItem("softClientLogo", JSON.stringify(softClientLogo));
  },
  OrderStatuses(state, OrderStatuses) {
    state.OrderStatuses = OrderStatuses;
    localStorage.setItem("OrderStatuses", JSON.stringify(OrderStatuses));
  },

  OrderTypes(state, OrderTypes) {
    state.OrderTypes = OrderTypes;
    localStorage.setItem("OrderTypes", JSON.stringify(OrderTypes));
  },

  InvoiceStatuses(state, InvoiceStatuses) {
    state.InvoiceStatuses = InvoiceStatuses;
    localStorage.setItem("InvoiceStatuses", JSON.stringify(InvoiceStatuses));
  },

  OrderLineStatuses(state, OrderLineStatuses) {
    state.OrderLineStatuses = OrderLineStatuses;
    localStorage.setItem(
      "OrderLineStatuses",
      JSON.stringify(OrderLineStatuses)
    );
  },

  PurchaseInvoiceStatuses(state, PurchaseInvoiceStatuses) {
    state.PurchaseInvoiceStatuses = PurchaseInvoiceStatuses;
    localStorage.setItem(
      "PurchaseInvoiceStatuses",
      JSON.stringify(PurchaseInvoiceStatuses)
    );
  },

  PickingTypes(state, PickingTypes) {
    state.PickingTypes = PickingTypes;
    localStorage.setItem("PickingTypes", JSON.stringify(PickingTypes));
  },

  PurchaseOrderStatus(state, PurchaseOrderStatus) {
    state.PurchaseOrderStatus = PurchaseOrderStatus;
    localStorage.setItem(
      "PurchaseOrderStatus",
      JSON.stringify(PurchaseOrderStatus)
    );
  },

  PickingOrderStatus(state, PickingOrderStatus) {
    state.PickingOrderStatus = PickingOrderStatus;
    localStorage.setItem(
      "PickingOrderStatus",
      JSON.stringify(PickingOrderStatus)
    );
  },

  ProductTypes(state, ProductTypes) {
    state.ProductTypes = ProductTypes;
    localStorage.setItem("ProductTypes", JSON.stringify(ProductTypes));
  },

  PaymentTypes(state, PaymentTypes) {
    state.PaymentTypes = PaymentTypes;
    localStorage.setItem("PaymentTypes", JSON.stringify(PaymentTypes));
  },

  ReturnedProductStates(state, ReturnedProductStates) {
    state.ReturnedProductStates = ReturnedProductStates;
    localStorage.setItem(
      "ReturnedProductStates",
      JSON.stringify(ReturnedProductStates)
    );
  },
};

const actions = {
  authenticate: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get("Authentications/Authenticate")
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          commit("auth_error");
          reject();
        });
    });
  },

  login: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post("Authentications/Login", user)
        .then((response) => {
          if (response.data.success) {
            var userData = response.data.data;

            // axios.get("User/Licensee").then((response) => {
            //   var licensee = response.data.data.data;
            //   var warehouses = [];
            //   warehouses = licensee.warehouses;
            //   var warehouse = warehouses.find((obj) => {
            //     return obj.isDefault === true;
            //   });
            //   userData.warehouse = Object.assign({}, warehouse);
            //   userData.warehouseAbb = warehouse.abbreviation;
            // });

            commit("auth_success", userData);
          } else {
            commit("auth_error");
          }
          resolve(response.data);
        })
        .catch((err) => {
          commit("auth_error");
          reject(err);
        });
    });
  },

  getAccessToken: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      if (state.refreshToken) {
        axios
          .post("Authentications/RefreshToken", { token: state.refreshToken })
          .then((response) => {
            commit("auth_success", response.data);
            resolve(response);
          })
          .catch((err) => {
            commit("auth_error");
            reject(err);
          });
      } else {
        reject();
      }
    });
  },

  logout: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit("auth_error");
      resolve();
      location.reload();
      router.push("/login");
      successToast("Successfully LoggedOut");

      // var formData = {
      //   refershToken : ""
      // }
      //       axios
      //         .post("Authentications/Logout", formData)
      //         .then((response) => {
      //           if (response.data.success) {
      //             var userData = response.data.data;

      //             commit("auth_success", userData);
      //           } else {
      //             commit("auth_error");
      //           }
      //           resolve(response.data);
      //         })
      //         .catch((err) => {
      //           commit("auth_error");
      //           reject(err);
      //         });
    });
  },
  setWarehouse: ({ commit }, warehouse) => {
    return new Promise(() => {
      commit("warehouse", warehouse);
    });
  },
  setLicensee: ({ commit }, licensee) => {
    return new Promise(() => {
      commit("licensee", licensee);
    });
  },

  setFirstName: ({ commit }, firstName) => {
    return new Promise(() => {
      commit("firstName", firstName);
    });
  },

  setLastName: ({ commit }, lastName) => {
    return new Promise(() => {
      commit("lastName", lastName);
    });
  },

  setWarehouses: ({ commit }, warehouses) => {
    return new Promise(() => {
      commit("warehouses", warehouses);
    });
  },
  setLicensees: ({ commit }, licensees) => {
    return new Promise(() => {
      commit("licensees", licensees);
    });
  },

  setLicenseeId: ({ commit }, licenseeId) => {
    return new Promise(() => {
      commit("licenseeId", licenseeId);
    });
  },
  setUserRole: ({ commit }, userRole) => {
    return new Promise(() => {
      commit("userRole", userRole);
    });
  },
  setBaseurl: ({ commit }, baseurl) => {
    return new Promise(() => {
      commit("baseurl", baseurl);
    });
  },
  setlocalIp: ({ commit }, localIp) => {
    return new Promise(() => {
      commit("localIp", localIp);
    });
  },
  setpublicIp: ({ commit }, publicIp) => {
    return new Promise(() => {
      commit("publicIp", publicIp);
    });
  },
  setSoftClientLogo: ({ commit }, softClientLogo) => {
    return new Promise(() => {
      commit("softClientLogo", softClientLogo);
    });
  },

  setOrderStatuses: ({ commit }, OrderStatuses) => {
    return new Promise(() => {
      commit("OrderStatuses", OrderStatuses);
    });
  },

  setOrderTypes: ({ commit }, OrderTypes) => {
    return new Promise(() => {
      commit("OrderTypes", OrderTypes);
    });
  },

  setInvoiceStatuses: ({ commit }, InvoiceStatuses) => {
    return new Promise(() => {
      commit("InvoiceStatuses", InvoiceStatuses);
    });
  },

  setOrderLineStatuses: ({ commit }, OrderLineStatuses) => {
    return new Promise(() => {
      commit("OrderLineStatuses", OrderLineStatuses);
    });
  },

  setPurchaseInvoiceStatuses: ({ commit }, PurchaseInvoiceStatuses) => {
    return new Promise(() => {
      commit("PurchaseInvoiceStatuses", PurchaseInvoiceStatuses);
    });
  },

  setPickingTypes: ({ commit }, PickingTypes) => {
    return new Promise(() => {
      commit("PickingTypes", PickingTypes);
    });
  },

  setPurchaseOrderStatus: ({ commit }, PurchaseOrderStatus) => {
    return new Promise(() => {
      commit("PurchaseOrderStatus", PurchaseOrderStatus);
    });
  },

  setPickingOrderStatus: ({ commit }, PickingOrderStatus) => {
    return new Promise(() => {
      commit("PickingOrderStatus", PickingOrderStatus);
    });
  },

  setProductTypes: ({ commit }, ProductTypes) => {
    return new Promise(() => {
      commit("ProductTypes", ProductTypes);
    });
  },

  setPaymentTypes: ({ commit }, PaymentTypes) => {
    return new Promise(() => {
      commit("PaymentTypes", PaymentTypes);
    });
  },

  setReturnedProductStates: ({ commit }, ReturnedProductStates) => {
    return new Promise(() => {
      commit("ReturnedProductStates", ReturnedProductStates);
    });
  },
};

const getters = {
  isRefreshing: (state) => !!state.isRefreshing,
  isLoggedIn: (state) => !!state.token,
  token: (state) => state.token,
  permissions: (state) => state.permissions,
  getPermission: (state) => (permissionName) => {
    let status = state.permissions.includes(permissionName);
    return status;
  },
  roleCheck: (state) => (roles) => {
    const userRole = state.userRole || "";
    return roles.includes(userRole);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
