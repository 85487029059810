import { HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import axios from "./services/http-common";
import mitt from "mitt";

export default {
  install: (app) => {
    const emitter = mitt();
    var hubConnection = null;
    var hubConnectionStatus = HubConnectionState.Disconnected;

    var signalRHub = {
      status: hubConnectionStatus,
      emitter,

      updateStatus(status) {
        hubConnectionStatus = status;
        console.log(new Date(), "updateStatus", status);
        emitter.emit("status", status);
      },

      connection() {
        var signalRUrl = axios.defaults.baseURL.replace("api/", "hubs/dashboard");
        let connection = new HubConnectionBuilder().withUrl(signalRUrl).withAutomaticReconnect([0, 5000, 10000, 30000]).configureLogging(LogLevel.Information).build();

        connection.on("PickedProduct", (data) => {
          console.log(new Date(), "PickedProduct", data);
          emitter.emit("PickedProduct", data);
        });

        connection.on("StocktakeProductAdded", (data) => {
          console.log(new Date(), "StocktakeProductAdded", data);
          emitter.emit("StocktakeProductAdded", data);
        });

        connection.on("DetectedBarcode", (data) => {
          console.log(new Date(), "DetectedBarcode", data);
          emitter.emit("DetectedBarcode", data);
        });

        connection.on("OrderPicked", (OrderId, OrderLineId, Quantity) => {
          var data = {OrderId,OrderLineId,Quantity}
          console.log(new Date(), "SignalR : OrderPicked", data);
          emitter.emit("OrderPicked", data);
        });

        connection.on("OrderLineStatusUpdated", (OrderId, OrderLineId, Status) => {
          var data = {OrderId,OrderLineId,Status}
          console.log(new Date(), "SignalR : OrderLineStatusUpdated", data);
          emitter.emit("OrderLineStatusUpdated", data);
        });

        connection.on("OrderStatusUpdated", (OrderId, Status) => {
          var data = {OrderId,Status}
          console.log(new Date(), "SignalR : OrderStatusUpdated", data);
          emitter.emit("OrderStatusUpdated", data);
        });

        connection.onreconnecting(() => {
          this.updateStatus(HubConnectionState.Reconnecting);
        });

        connection.onreconnected(() => {
          this.updateStatus(HubConnectionState.Connected);
        });

        connection.onclose(() => {
          this.updateStatus(HubConnectionState.Disconnected);
        });

        return connection;
      },

      startConnection() {
        return new Promise(async (resolve, reject) => {
          try {
            hubConnection = this.connection();
            await hubConnection.start();
            this.updateStatus(HubConnectionState.Connected);
            resolve();
          } catch (err) {
            this.updateStatus(HubConnectionState.Disconnected);
            reject(err);
          }
        });
      },

      sendMessage(message) {
        return new Promise(async (resolve, reject) => {
          if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
            let result = await hubConnection.invoke("SendMessage", message);
            resolve(result);
          } else {
            reject("no connection");
          }
        });
      },
    };

    signalRHub.startConnection();
    app.config.globalProperties.$signalRHub = signalRHub;
  },
};
