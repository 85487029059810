<template>
  <v-select
    v-model="_vmodel"
    placeholder="SELECT"
    class="style-chooser"
    :filterable="false"
    @open="onOpen"
    @close="onClose"
    @search="search"
    :key="key"
    :get-option-label="(option) => `${option[getOptionLabel]}`"
    :options="list"
    :reduce="(x) => (isObject ? x : `${x[getValue]}`)"
    :label="`${label}`"
  >
    <template #list-header v-if="list.length > 0">
      <table class="border-collapse table-fixed w-full">
        <thead>
          <tr>
            <th v-for="(header, i) in headers" :key="i">
              <div :class="`whitespace-normal ${header.width}`">
                {{ header.title }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </template>

    <template v-slot:option="option">
      <table class="border-collapse table-fixed w-full">
        <tbody>
          <tr>
            <td v-for="(header, index) in headers" :key="index">
              <div :class="`whitespace-normal ${header.width}`">
                {{ option[`${header.value}`] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <template #list-footer v-if="list.length > 0">
      <li v-show="hasNextPage" ref="load" class="loader">Loading more...</li>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "Selection",

  data() {
    return {};
  },
  props: {
    modelValue: "",
    key: "",
    getOptionLabel: "",
    list: [],
    getValue: "",
    label: "",
    hasNextPage: false,
    headers: [],
    pageNumber: 0,
    observer: null,
    isSearchResult: false,
    isObject: false,
  },
  created() {},
  computed: {
    _vmodel: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    _pageNumber: {
      get() {
        return this.pageNumber;
      },
      set(val) {
        this.$emit("update:pageNumber", val);
      },
    },
  },
  methods: {
    // onOpen() {
    //   console.log("getvalue", this.getValue);
    //   // {
    //   //     page:1,
    //   //     itemPerPage: 10,
    //   //     search: '',
    //   //     callback(items, totalItems){

    //   //     }
    //   // }
    //   this.$emit("onOpen");
    // },
    async onOpen() {
      if (this.isSearchResult) {
        this._pageNumber = 1;
      }
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onCloseSku() {
      this.observer.disconnect();
    },

    search() {
      this.$emit("search");
    },
  },
};
</script>
