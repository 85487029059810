<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full">
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)"
          buttonName="Add New User" />

        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport"
          :page="pagination.pageNumber" />
        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)"
          @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
        <div class="hidden md:block mx-auto text-slate-500"></div>
      </div>
      <!-- <a
        href="javascript:;"
        @click="viewArchives()"
        class="btn btn-primary shadow-md text-xs lg:text-sm mt-2 lg:mt-0 md:mt-0"
      >
        {{ const_txt.ARCHIVE_BTN }}
        <span
          class="ml-2 lg:w-5 lg:h-5 w-4 h-4 flex items-center justify-center"
        >
          <ArchiveIcon class="lg:w-6 lg:h-6 lg:-mt-1 w-4 h-4 mt-0" />
        </span>
      </a> -->
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>Active/Deactive</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
            <td>
              <div class="form-switch ml-3">
                <input @click="lockout(item)" id="booleanSwitch" type="checkbox" v-model="item.lockoutEnabled"
                  :true-value="false" :false-value="true" class="form-check-input" />
              </div>
            </td>
            <td class="table-report__action w-28">
              <div class="lg:grid lg:grid-cols-3 gap-x-3 ml-4 gap-y-2 p-2 flex flex-wrap">
                <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)"
                  :item="item" name="Delete" />

                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item"
                  name="Edit" />

                <ResetPsd :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="resetPsd($event)" :item="item"
                  name="Change Password" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />
    <!-- BEGIN: Pagination -->
    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData"
      :pageCount="pageCount" :pageSize="pageSizeView" />

    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addUser" @hidden="addUser = false" backdrop="static" size="modal-lg" :title="formTitle"
    @close="closeForm()">
    <ModalBody class="grid grid-cols-2 lg:gap-4 md:gap-4 gap-2">
      <div class="relative col-span-3 pt-2">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">User Details</h3>
        <div
          class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
          <div>
            <label for="regular-form-1" class="form-label">First Name</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.firstName" type="text" class="form-control"
                name="firstName" placeholder="First Name" autocomplete="off" />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Last Name</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.lastName" type="text" class="form-control"
                name="lastName" placeholder="Last Name" autocomplete="off" />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Email</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validateEmail(formDataUser.email) == true && formDataUser.email != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1" v-model="formDataUser.email" type="text" class="form-control" name="email"
                placeholder="Email" autocomplete="off" />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">Phone Number</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validatePhoneNo(formDataUser.phoneNumber) == true && formDataUser.phoneNumber != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1" v-model="formDataUser.phoneNumber" type="text" class="form-control"
                name="phoneNumber" placeholder="Phone Number" autocomplete="off" />
            </div>
          </div>
        </div>
      </div>

      <div class="relative col-span-3 pt-2">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">User Account Details</h3>
        <div
          class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
          <div>
            <label for="regular-form-1" class="form-label">User Name</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.userName" type="text" class="form-control"
                name="username" placeholder="User Name" autocomplete="off" />
            </div>
          </div>

          <div v-if="titleChange == -1">
            <label for="regular-form-1" class="form-label">Password</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.password" type="password" class="form-control"
                name="password" placeholder="password Name" autocomplete="off" />
            </div>
          </div>

          <div v-if="titleChange == -1">
            <label for="regular-form-1" class="form-label">Confirm Password</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataUser.conformPassword" type="password" class="form-control"
                name="conformPassword" placeholder="Confirm Password" autocomplete="off" />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">User Role</label>
            <div>
              <v-select v-model="formDataUser.role" placeholder="SELECT" class="style-chooser" :options="userRoles"
                :reduce="(name) => name.name" label="name" />
            </div>
          </div>

          <div v-if="formDataUser.role === 'BranchAdmin'">
            <label for="regular-form-1" class="form-label">Branch</label>
            <div>
              <v-select v-model="formDataUser.branchId" placeholder="SELECT" class="style-chooser" :options="branches"
                :get-option-label="(option) => option.name" :reduce="(option) => option.branchId" label="name"
                :key="branchId" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="w-full">
        <label for="regular-form-1" class="form-label">First Name</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.firstName"
            type="text"
            class="form-control"
            name="firstName"
            placeholder="First Name"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="w-full">
        <label for="regular-form-1" class="form-label">Last Name</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.lastName"
            type="text"
            class="form-control"
            name="lastName"
            placeholder="Last Name"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Email</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.email"
            type="text"
            class="form-control"
            name="email"
            placeholder="Email"
            autocomplete="off"
          />
        </div>
      </div> -->
      <!-- <div class="w-full">
        <label for="regular-form-1" class="form-label">User Name</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.username"
            type="text"
            class="form-control"
            name="username"
            placeholder="User Name"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Password</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.password"
            type="password"
            class="form-control"
            name="password"
            placeholder="password Name"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="w-full">
        <label for="regular-form-1" class="form-label">Confirm Password</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.conformPassword"
            type="password"
            class="form-control"
            name="conformPassword"
            placeholder="Confirm Password"
            autocomplete="off"
          />
        </div>
      </div> -->
      <!-- <div class="w-full">
        <label for="regular-form-1" class="form-label">Phone Number</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataUser.phoneNumber"
            type="text"
            class="form-control"
            name="phoneNumber"
            placeholder="Phone Number"
            autocomplete="off"
          />
        </div>
      </div> -->
      <!-- 
      <div class="w-full">
        <label for="regular-form-1" class="form-label">User Role</label>
        <div>
          <v-select
            v-model="formDataUser.role"
            placeholder="SELECT"
            class="style-chooser"
            :options="userRoles"
            :reduce="(name) => name.name"
            label="name"
          />
        </div>
      </div> -->
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->
  <MoveableModal :show="resetPasswordModal" @hidden="resetPasswordModal = false" backdrop="static" size="modal-lg"
    :title="`Password Change For ${formDataResetPassword.userName}`" @close="closeResetPassword">
    <ModalBody class="grid grid-cols-1 lg:gap-4 md:gap-4 gap-2">
      <div class="w-full relative text-slate-500 mb-4">
        <input :type="visibility" class="form-control w-full pr-10" placeholder="New Password"
          v-model="formDataResetPassword.newPassword" />
        <EyeIcon @click="showPassword()" v-if="visibility == 'password'"
          class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        <EyeOffIcon @click="hidePassword()" v-if="visibility == 'text'"
          class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
      </div>

      <div class="w-full relative text-slate-500 mb-4">
        <input :type="confirmVisibility" class="form-control w-full pr-10" placeholder="Confirm Password"
          v-model="formDataResetPassword.conformPassword" />
        <EyeIcon @click="showConfirmPassword()" v-if="confirmVisibility == 'password'"
          class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        <EyeOffIcon @click="hideConfirmPassword()" v-if="confirmVisibility == 'text'"
          class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeResetPassword()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveResetForm()" type="submit" class="btn btn-primary w-24">Change</button>
    </ModalFooter>
  </MoveableModal>
  <!-- BEGIN: Archive Modal -->
  <!-- not used -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="text-danger float-right lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6 cursor-pointer"
          @click="archiveModal = false" />
      </div>

      <div class="p-5 text-center lg:text-lg text-base uppercase font-semibold">Archived user</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input type="text" class="form-control w-full box pr-10" placeholder="Search..." v-model="searchDataArchive"
            @change="searchOnchangeArchive" autocomplete="off" clearable />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <div
        class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
        style="background-color: #164e63" v-if="this.archiveData.totalCount > 0">
        <div class="w-full sm:w-auto sm:mr-auto"
          style="background-color: #e1e7ef; border: 2px solid #164e63; border-radius: 10px">
          <paginate class="text-white" :page-count="pageCountArchive" :page-range="5" :margin-pages="2"
            :click-handler="changePageArchive" :container-class="'pagination'" :page-class="'page-item'"
            :first-last-button="true" :initial-page="1" :prev-text="'<'" :next-text="'>'" :first-button-text="'<<'"
            :last-button-text="'>>'">
          </paginate>
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select v-model="pageSize" class="w-20 form-select box cursor-pointer" @change="pageSizeChange">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataUser.userName" :modalStatus="archiveConfirmationModal"
    :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataUser.userName" :modalStatus="restoreConfirmationModal"
    :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataUser.userName" :modalStatus="deleteConfirmationModal"
    :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <DeactiveConfirmModal :name="formDataUser.userName" :modalStatus="deactiveConfirmationModal"
    :modalConfirm="deactiveItemConfirm" :modalClose="closeDeactive" />
  <ActiveConfirmModal :name="formDataUser.userName" :modalStatus="activeConfirmationModal"
    :modalConfirm="activeItemConfirm" :modalClose="closeActive" />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import validationMixins from "../../../mixins/validation";
// import { reg } from "../../../global-functions/regex";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import store from "../../../store";
import ResetPassword from "@/views/Auth/Reset Password.vue";

export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Users");
  },
  components: { ResetPassword },
  data() {
    return {
      // reg: reg,

      const_txt: const_txt,
      userRoles: [],
      branches: [],
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addUser: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      deactiveConfirmationModal: false,
      activeConfirmationModal: false,
      titleChange: -1,
      formDataUser: {
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        conformPassword: "",
        phoneNumber: "",
        role: "",
        lockoutEnabled: false,
      },
      formDataUserDefault: {
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        conformPassword: "",
        phoneNumber: "",
        role: "",
        lockoutEnabled: false,
      },

      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Username", value: "userName" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "User Role", value: "roles" },
      ],
      headersFilter: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Username", value: "userName" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "User Role", value: "roles" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      policyAccess: "Users",
      licenseeId: store.state.authUser.licensee.licenseeId,

      resetPasswordModal: false,
      formDataResetPassword: {
        userName: "",
        token: "",
        newPassword: "",
        conformPassword: "",
      },
      formDataResetPasswordDefault: {
        userName: "",
        token: "",
        newPassword: "",
        conformPassword: "",
      },
      resetToken: "",
      visibility: "password",
      confirmVisibility: "password",
      userRole: store.state.authUser.userRole,
    };
  },
  computed: {
    // validateEmail() {
    //   return !this.reg.EMAIL.test(this.formDataUser.email);
    // },
    // validatePhoneNo() {
    //   return !this.reg.PHONENO.test(this.formDataUser.phoneNumber);
    // },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
    formTitle() {
      return this.titleChange === -1 ? "Add New User" : this.formDataUser?.userName ? `Edit User - ${this.formDataUser?.userName}` : "Edit User";
    },
    canCreate() {
      return store.getters["authUser/getPermission"]("Permissions.Users.Create");
    },
    storeWarehouseId() {
      return store.state.authUser.warehouse !== null ? store.state.authUser.warehouse.warehouseId : 0;
    }
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    showConfirmPassword() {
      this.confirmVisibility = "text";
    },
    hideConfirmPassword() {
      this.confirmVisibility = "password";
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    userActivate(item) {
      console.log(item.id);
      this.isLoading = true;
      axios
        .get(`Users/${item.id}/Activate`)
        .then((response) => {
          if (response.data.success === true) {
            successToast(response.data.message);

            this.isLoading = false;
          } else {
            item.lockoutEnabled = true;
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          item.lockoutEnabled = true;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    userDeactivate(item) {
      console.log(item.id);
      this.isLoading = true;
      axios
        .get(`Users/${item.id}/Deactivate`)
        .then((response) => {
          if (response.data.success === true) {
            successToast(response.data.message);

            this.isLoading = false;
          } else {
            item.lockoutEnabled = false;
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          item.lockoutEnabled = false;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    lockout(item) {
      console.log(item);
      if (item.lockoutEnabled == true) {
        //item.lockoutEnabled = false;
        //this.userActivate(item);
        this.activeItem(item);
      } else {
        //item.lockoutEnabled = true;
        //this.userDeactivate(item);
        this.deactiveItem(item);
      }
    },
    closeResetPassword() {
      this.resetPasswordModal = false;
      this.setResetPasswordDefault();
      this.resetToken = "";
    },

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("/Users/Paginated", this.pagination)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.isLoading = false;
          } else {
            warningToast(response.status);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("/Users/Paginated - Error", error);

          //   router.push("/login")

          this.isLoading = false;

          //warningToast(error.status);

          //warningToast(statusMessage(error.response.status));
        });
    },

    getUserRoles() {
      this.isLoading = true;
      axios
        .get("Roles")
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.userRoles = response.data.data;
          } else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("/Roles - Error", error);
        });
    },

    getBranches() {
      this.isLoading = true;
      axios
        .post(`/Branches/${this.storeWarehouseId}/IdAndNamePaginated`, { pageNumber: 1, pageSize: 1000 })
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.branches = response.data.data.items;
          } else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("/Branches - Error", error);
        });
    },

    setReports() {
      if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        const columns = [
          { title: "First Name", dataKey: "firstName" },
          { title: "Last Name", dataKey: "lastName" },
          { title: "Username", dataKey: "userName" },
          { title: "Email", dataKey: "email" },
          { title: "Phone Number", dataKey: "phoneNumber" },
          { title: "User Role", dataKey: "roles" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            firstName: el.firstName,
            lastName: el.lastName,
            phoneNumber: el.phoneNumber,
            email: el.email,
            userName: el.userName,
            roles: el.roles,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
        }
      }
    },

    printCurrentPage() {
      // this.getAllDataFromApi();
      // this.isActive = 1;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Users " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },
    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Users " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "First Name",
          2: "Last Name",
          3: "Username",
          4: "Email",
          5: "Phone Number",
          6: "User Role",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.firstName,
            2: item.lastName,
            3: item.userName,
            4: item.email,
            5: item.phoneNumber,
            6: item.roles,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },
    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Users/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;

      this.getDataFromApi();
    },
    setDefault() {
      this.formDataUser = Object.assign({}, this.formDataUserDefault);
    },
    setResetPasswordDefault() {
      this.formDataResetPassword = Object.assign({}, this.formDataResetPasswordDefault);
    },

    showModal() {
      this.setDefault();
      this.getUserRoles();
      this.getBranches();
      this.titleChange = -1;
      this.addUser = true;
    },
    closeForm() {
      this.setDefault();
      this.addUser = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataUser = Object.assign({}, item);
      this.addUser = true;
    },
    resetPsd(item) {
      this.isLoading = true;
      var formData = {
        userName: item.userName,
      };
      axios
        .post("Authentications/ResetPasswordRequest", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.resetToken = response.data.data.data;
            console.log("token " + this.resetToken);
            this.formDataResetPassword.userName = item.userName;
            this.resetPasswordModal = true;
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    saveResetForm() {
      if (this.formDataResetPassword.newPassword == "" || this.formDataResetPassword.conformPassword == "") {
        if (this.formDataResetPassword.newPassword == "") {
          warningToast("New Password is Required");
        }
        if (this.formDataResetPassword.conformPassword == "") {
          warningToast("Conform Password is Required");
        }
      } else if (this.formDataResetPassword.newPassword != this.formDataResetPassword.conformPassword) {
        warningToast("Password is not match");
        this.formDataResetPassword.newPassword = "";
        this.formDataResetPassword.conformPassword = "";
      } else {
        this.isLoading = true;
        this.formDataResetPassword.token = this.resetToken;
        console.log(this.formDataResetPassword);
        axios
          .post("Authentications/ResetPassword", this.formDataResetPassword)
          .then((response) => {
            if (response.data.success === true) {
              this.closeResetPassword();
              this.getDataFromApi();
              successToast(response.data.message);
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },

    saveForm() {
      if (this.titleChange === -1) {
        if (
          this.formDataUser.userName == "" ||
          this.formDataUser.password == "" ||
          this.formDataUser.conformPassword == "" ||
          this.formDataUser.role == "" ||
          this.formDataUser.password != this.formDataUser.conformPassword
        ) {
          if (this.formDataUser.userName == "") {
            warningToast("user Name Required");
          }
          if (this.formDataUser.role == "" || this.formDataUser.role == null) {
            warningToast("User Role Required");
          }
          if (this.formDataUser.password == "") {
            warningToast("Password Required");
          }
          if (this.formDataUser.conformPassword == "") {
            warningToast("Confirm Password Required");
          }
          if (this.formDataUser.password != "" && this.formDataUser.conformPassword != "" && this.formDataUser.password != this.formDataUser.conformPassword) {
            warningToast("Password Mismatch");
          }
        } else {
          this.isLoading = true;
          axios
            .post("Users", this.formDataUser)
            .then((response) => {
              if (response.data.success === true) {
                this.addUser = false;

                if (this.userRole == "SuperUser") {
                  this.getDataFromApi();
                  successToast(response.data.message);
                } else {
                  var formData = {
                    userId: response.data.data.userId,
                  };
                  axios
                    .post("Licensees/" + this.licenseeId + "/Users", formData)
                    .then((response) => {
                      if (response.data.success === true) {
                        console.log(response.data.message);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  this.getDataFromApi();
                  successToast(response.data.message);
                }
                // location.reload();
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      } else {
        if (this.formDataUser.userName == "" || this.formDataUser.role == "") {
          if (this.formDataUser.userName == "") {
            warningToast("user Name Required");
          }
          if (this.formDataUser.role == "" || this.formDataUser.role == null) {
            warningToast("User Role Required");
          }
        } else {
          var itemId = this.formDataUser.id;
          this.isLoading = true;

          axios
            .put("Users/" + itemId, this.formDataUser)
            .then((response) => {
              if (response.data.success === true) {
                this.addUser = false;
                this.getDataFromApi();
                successToast(response.data.message);
                // location.reload();
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataUser = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    closeDeactive() {
      this.deactiveConfirmationModal = false;
      this.getDataFromApi();
    },
    closeActive() {
      this.activeConfirmationModal = false;
      this.getDataFromApi();
    },
    archiveItemConfirm() {
      var itemId = this.formDataUser.userId;
      this.isLoading = true;
      axios
        .get("Users/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            // location.reload();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Users/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }

          this.archiveModal = true;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },

    deactiveItem(item) {
      this.formDataUser = Object.assign({}, item);
      this.deactiveConfirmationModal = true;
    },
    deactiveItemConfirm(item) {
      this.isLoading = true;
      var item = this.formDataUser;
      axios
        .get(`Users/${item.id}/Deactivate`)
        .then((response) => {
          if (response.data.success === true) {
            this.deactiveConfirmationModal = false;
            successToast(response.data.message);
            // location.reload();
            this.isLoading = false;
          } else {
            item.lockoutEnabled = true;
            this.deactiveConfirmationModal = true;
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          item.lockoutEnabled = true;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    activeItem(item) {
      this.formDataUser = Object.assign({}, item);
      this.activeConfirmationModal = true;
    },
    activeItemConfirm() {
      this.isLoading = true;
      var item = this.formDataUser;
      axios
        .get(`Users/${item.id}/Activate`)
        .then((response) => {
          if (response.data.success === true) {
            this.activeConfirmationModal = false;
            successToast(response.data.message);
            // location.reload();
            this.isLoading = false;
          } else {
            item.lockoutEnabled = true;
            this.activeConfirmationModal = false;
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          item.lockoutEnabled = true;
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    restoreItem(item) {
      this.formDataUser = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },

    restoreItemConfirm() {
      var itemId = this.formDataUser.userId;
      this.isLoading = true;
      axios
        .get("Users/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            // location.reload();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      removeNullValue(item);
      this.formDataUser = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataUser.id;
      axios
        .delete("Users/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.getDataFromApi();
            // location.reload();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            this.deleteConfirmationModal = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
</script>
