<template>
  <div
    v-if="itemsData?.totalCount == 0"
    class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
  >
    No Data
  </div>
</template>
<script>
export default {
  name: "NoData",
  props: { itemsData: {} },
};
</script>
