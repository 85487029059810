<template>
  <div class="mt-5 w-full">
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in logs" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
  </div>
</template>
<script>
export default {
  name: "Logs",
  data: () => ({
    headers: [
      {
        text: "Timestamp",
        value: "time",
      },
      {
        text: "Log",
        value: "data",
      },
    ],
    logs: [],
  }),
  created() {
    this.$signalRHub.emitter.on("DetectedBarcode", this.detectedBarcode);
  },
  beforeDestroy() {
    this.$signalRHub.emitter.off("DetectedBarcode", this.detectedBarcode);
  },
  methods: {
    detectedBarcode(barcode) {
      this.logs.unshift({
        time: new Date(),
        data: barcode,
      });
    },
  },
};
</script>