<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Warehouse" />

          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
          />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />

          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
        <ViewArchiveItem @parentMethod="viewArchives($event)" />
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Default</th>
              <th>DESC</th>

              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td>
                <div class="form-switch ml-3">
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    :options="{
                      zIndex: 99999,
                    }"
                    content="Set as Default Warehouse"
                    v-if="item.isDefault === false || item.isDefault === null"
                    @click="defaultWarehouseItem(item)"
                  >
                    <input id="booleanSwitch" type="checkbox" v-model="item.isDefault" class="form-check-input" />
                  </Tippy>

                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    :options="{
                      zIndex: 99999,
                    }"
                    content="Default Warehouse"
                    v-if="item.isDefault === true"
                  >
                    <input id="booleanSwitch" type="checkbox" v-model="item.isDefault" class="form-check-input" disabled />
                  </Tippy>
                </div>
              </td>
              <td>
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                    {{ item.description }}
                  </Tippy>
                </div>
              </td>

              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />

                  <!-- <ActionButton
                    v-if="item.isDefault === false || item.isDefault === null"
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="defaultWarehouseItem($event)"
                    :item="item"
                    name="Set as Default Warehouse"
                    icon="AlbumIcon"
                    color="text-primary"
                  />

                  <ActionButton
                    v-if="item.isDefault === true"
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    :item="item"
                    name="Default Warehouse"
                    icon="AlbumIcon"
                    color="text-success"
                  /> -->

                  <ActionButton :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" icon="EditIcon" color="" />
                  <ActionButton
                    :activeAccess="$h.archiveItemAccess(policyAccess)"
                    @parentMethod="archiveItem($event)"
                    :item="item"
                    name="Archive"
                    icon="ArchiveIcon"
                    color="text-danger"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>
  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addWarehouse" @hidden="addWarehouse = false" backdrop="static" size="modal-xl" :title="formTitle" @close="addWarehouse = false">
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full p-3">
        <div>
          <label for="regular-form-1" class="form-label">Name</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataWarehouse.name" type="text" class="form-control" name="name" placeholder="Warehouse Name" autocomplete="off" />
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div>
            <label for="regular-form-1" class="form-label">Abbreviation</label>

            <div class="flex flex-col space-y-1">
              <input
                id="vertical-form-1"
                v-model="formDataWarehouse.abbreviation"
                type="text"
                class="form-control"
                name="abbreviation"
                placeholder="Warehouse Abbreviation"
                autocomplete="off"
              />
            </div>
          </div>

          <div class="form-switch ml-3 flex flex-col justify-center items-center space-y-2">
            <label for="regular-form-1" class="form-label">Default</label>
            <input id="booleanSwitch" type="checkbox" v-model="formDataWarehouse.isDefault" class="form-check-input" />
          </div>
        </div>

        <div>
          <label for="regular-form-1" class="form-label">Description</label>

          <div class="flex flex-col space-y-1">
            <textarea
              id="vertical-form-1"
              v-model="formDataWarehouse.description"
              type="text"
              class="form-control"
              name="description"
              placeholder="Type here about Warehouse..."
            ></textarea>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-8">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">Done</button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Archive Modal -->
  <MoveableModal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static" title="Archived Warehouses" @close="archiveModal = false">
    <ModalBody class="p-0">
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0 p-4">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            autocomplete="off"
            clearable
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <hr />
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-24">
                <div class="flex">
                  <ActionButton
                    :activeAccess="$h.restoreItemAccess(policyAccess)"
                    @parentMethod="restoreItem($event)"
                    :item="item"
                    name="Restore"
                    icon="RotateCcwIcon"
                    color="text-blue-900"
                  />
                  <ActionButton
                    :activeAccess="$h.deleteItemAccess(policyAccess)"
                    @parentMethod="deleteItem($event)"
                    :item="item"
                    name="Delete"
                    icon="Trash2Icon"
                    color="text-danger"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataWarehouse.name" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataWarehouse.name" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataWarehouse.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />

  <DefaultWarehouseConfirmModal
    :name="this.formDataWarehouse.name"
    :modalStatus="defaultWarehouseConfirmationModal"
    :modalConfirm="defaultItemWarehouseConfirm"
    :modalClose="closeWarehouseDefault"
  />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";
import { const_txt } from "../../../global-functions/const";
import store from "../../../store";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Warehouses");
  },
  components: {
    Loading,
    paginate: Paginate,
  },
  data() {
    return {
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      addWarehouse: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataWarehouse: {
        name: "",
        description: "",
        abbreviation: "",
        isDefault: false,
      },
      formDataWarehouseDefault: {
        name: "",
        description: "",
        abbreviation: "",
        isDefault: false,
      },

      rules: {
        name: {
          required,
          minLength: minLength(2),
        },
        description: {
          required,
          minLength: minLength(2),
        },
        abbreviation: {
          required,
          maxLength: maxLength(10),
        },
      },

      headers: [
        // { text: "ID", value: "warehouseId" },
        { text: "Abbreviation", value: "abbreviation" },
        { text: "Name", value: "name" },

        // { text: "Description", value: "description" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      csvHeaders: {
        warehouseId: "warehouseId",
        abbreviation: "abbreviation",
        name: "name",
        description: "description",
      },

      allItems: [],
      allItemsData: [],
      isActive: 0,
      doc: null,
      allSelected: false,
      checkedData: [],
      policyAccess: "Warehouses",
      licenseeId: store.state.authUser.licensee.licenseeId,
      userRole: store.state.authUser.userRole,

      defaultWarehouseConfirmationModal: false,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Warehouse" : "Edit Warehouse";
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Abbreviation", dataKey: "abbreviation" },
          { title: "Name", dataKey: "name" },
          { title: "Description", dataKey: "description" },
        ];
        this.doc = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            abbreviation: el.abbreviation,
            name: el.name,
            description: el.description,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.doc
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.doc, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
      }
    },

    defaultWarehouseItem(item) {
      this.formDataWarehouse = Object.assign({}, item);
      this.defaultWarehouseConfirmationModal = true;
    },
    closeWarehouseDefault() {
      var findWarehouse = this.items.find((x) => x.warehouseId === this.formDataWarehouse.warehouseId).isDefault;
      var index = this.items.findIndex((x) => x.warehouseId === this.formDataWarehouse.warehouseId);
      this.items[index].isDefault = !findWarehouse;
      this.defaultWarehouseConfirmationModal = false;
    },
    defaultItemWarehouseConfirm() {
      this.isLoading = true;

      axios
        .get("Warehouses/UpdateDefaultWarehouse?WarehouseId=" + this.formDataWarehouse.warehouseId)
        .then((response) => {
          if (response.data.success === true) {
            this.defaultWarehouseConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.doc.autoPrint();
      this.doc.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.doc.save(`${"Warehouses " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Warehouses " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Abbreviation",
          2: "Name",
          3: "Description",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.abbreviation,
            2: item.name,
            3: item.description,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataWarehouse = Object.assign({}, this.formDataWarehouseDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Warehouses/Paginated", this.pagination)
        .then((response) => {
          console.log("RES", response);
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
          // localStorage.setItem("errorMessage", error);
          // router.push("/page-down");
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addWarehouse = true;
    },
    closeForm() {
      this.addWarehouse = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataWarehouse = Object.assign({}, item);
      this.addWarehouse = true;
    },

    saveForm() {
      if (this.formDataWarehouse.name == "" || this.formDataWarehouse.abbreviation == "") {
        if (this.formDataWarehouse.name == "") {
          warningToast("Name Required");
        }
        if (this.formDataWarehouse.abbreviation == "") {
          warningToast("Abbreviation Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Warehouses", this.formDataWarehouse)
            .then((response) => {
              if (response.data.success === true) {
                this.addWarehouse = false;

                if (this.userRole == "SuperUser") {
                  this.getDataFromApi();
                  successToast(response.data.message);
                } else {
                  var formData = {
                    warehouseId: response.data.data.warehouseId,
                    isDefault: false,
                  };

                  axios
                    .post("Licensees/" + this.licenseeId + "/Warehouses", formData)
                    .then((res) => {
                      if (res.data.success === true) {
                        console.log(res.data.message);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  this.getDataFromApi();
                  successToast(response.data.message);
                }
                location.reload();
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataWarehouse.warehouseId;
          this.isLoading = true;
          axios
            .put("Warehouses/" + itemId, this.formDataWarehouse)
            .then((response) => {
              if (response.data.success === true) {
                this.addWarehouse = false;
                this.getDataFromApi();
                this.apiMessage = response.data.message;
                successToast(this.apiMessage);
                location.reload();
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              failedToast(statusMessage(error.response.status));
            });
        }
      }
    },

    archiveItem(item) {
      this.formDataWarehouse = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },

    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },
    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataWarehouse.warehouseId;
      this.isLoading = true;
      axios
        .get("Warehouses/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            location.reload();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Warehouses/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataWarehouse = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataWarehouse.warehouseId;
      this.isLoading = true;
      axios
        .get("Warehouses/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            location.reload();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataWarehouse = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataWarehouse.warehouseId;
      axios
        .delete("Warehouses/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            location.reload();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>

<style>
.textBreak {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
</style>
