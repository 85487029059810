<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label">Product Name</label>
          <div class="w-72">
            <v-select
              v-model="reportData.skuName"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenSku"
              @close="onCloseSku"
              @search="searchSS"
              :key="description"
              :get-option-label="(option) => option.description"
              :options="skus"
              :reduce="(description) => description.skuName"
              label="description"
            >
              <template #list-footer>
                <li v-show="hasNextPageSku" ref="loadSku" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>

        <div>
          <a
            href="javascript:;"
            @click="getReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            GET REPORT
          </a>
        </div>
        <div>
          <a
            href="javascript:;"
            @click="clearReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            CLEAR
          </a>
        </div>

        <!-- <div class="w-56">
          <div class="relative text-slate-500">
            <input
              type="text"
              class="form-control box pr-10"
              placeholder="Search..."
              v-model="searchData"
              @change="searchOnchange"
            />
            <SearchIcon
              @click="searchItem"
              v-if="!searchActive"
              class="lg:w-6 lg:h-6 w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
            />
            <XIcon
              @click="clearSearch"
              v-if="searchActive"
              class="lg:w-6 lg:h-6 w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
            />
          </div>
        </div> -->
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pageNumber"
        />
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table
        class="table table-report -mt-2 table-auto"
        v-if="reportsLocal.length > 0"
      >
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Department</th>
            <th>BARCODE/EAN</th>
            <th>ExpiryDate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
            <td>{{ item.skuDetail.description }}</td>
            <td>{{ item.skuDetail.productType }}</td>
            <td>{{ item.skuDetail.department }}</td>
            <td>{{ item.skuDetail.ean }}</td>
            <td>
              {{ item.expiryDate === null || item.expiryDate === undefined ? '' : momentLocal(item.expiryDate) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div
      v-if="reportsLocal.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />

    <!-- END: Pagination -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-functions/toast";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Stock Location Reports");
    this.observerSku = new IntersectionObserver(this.infiniteScrollSku);
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,

      headers: [
        // { text: "Product Name", value: "description" },
        // { text: "Product Type", value: "productType" },
        //{ text: "Department", value: "department" },
        { text: "PLU/SKU", value: "sku" },
        { text: "InStockQuantity", value: "inStockQuantity" },
        //{ text: "BARCODE/EAN", value: "ean" },
        { text: "AvailableStock", value: "availableStock" },
        { text: "Bin", value: "bin" },
        { text: "Section", value: "section" },
        // { text: "ExpiryDate", value: "expiryDate" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      itemsData: [],
      pageSize: 10,

      skus: [],
      reportData: {
        skuName: "",
      },

      observerSku: null,
      limitSku: 5,
      hasNextPageSku: false,
      totalSkuCount: 0,
      isSkuSearchResult: false,
      skuPageNumber: 1,
      skuSearchTimer: undefined,

      reports: [
        // {
        //   orderNumber: "PO000001",
        //   pickingType: 1,
        //   status: 1,
        //   warehouseId: "1",
        //   supplierInvoice: "2",
        //   invoiceDate: "2022-05-18T19:58:27.41",
        //   receivedDate: "2022-05-19T19:58:27.41",
        //   userName: "SuperUser",
        //   supplierName: "Alex ",
        //   skuDescription: "Aclor 125mg/5ml Susp 100ml (Cefaclor) (L)",
        //   skuName: "SKU001",
        //   brandName: "Default",
        //   supplierProductNumber: "SC001",
        //   expirationDate: "2022-05-19T19:58:27.41",
        //   manufactureDate: "2022-05-19T19:58:27.41",
        //   receivedQuantity: 100,
        // },
      ],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },

      isActive: 0,
      docs: null,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },

    checkLimtSku() {
      return this.limitSku;
    },
    viewModalSku() {
      return this.reportData.skuName;
    },
  },
  created() {
    this.getSkus("");
    //this.test();
  },
  watch: {
    checkLimtSku() {
      this.getSkus();
    },
    viewModalSku() {
      if (this.reportData.skuNamed === null) {
        this.clearSku();
      }
    },
  },
  methods: {
    clearSku() {
      this.skus = [];
      this.skuPageNumber = 1;
      this.limitSku = 5;
      this.getSkus("");
    },
    searchSKU(search) {
      clearInterval(this.skuSearchTimer);
      this.skuSearchTimer = setTimeout(() => {
        if (search) {
          this.skus = [];
          this.skuPageNumber = 1;
          this.limitSku = this.totalSkuCount;
          this.getSkus(search.trim());
        }
      }, 2000);
    },
    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Product Name", dataKey: "description" },
          { title: "Product Type", dataKey: "productType" },
          { title: "Department", dataKey: "department" },
          { title: "PLU/SKU", dataKey: "sku" },
          { title: "InStockQuantity", dataKey: "inStockQuantity" },
          { title: "BARCODE/EAN", dataKey: "ean" },
          { title: "AvailableStock", dataKey: "availableStock" },
          { title: "Bin", dataKey: "bin" },
          { title: "Section", dataKey: "section" },
          { title: "ExpiryDate", dataKey: "expiryDate" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            description: el.skuDetail.description,
            productType: el.skuDetail.productType,
            department: el.skuDetail.department,
            sku: el.sku,
            inStockQuantity: el.inStockQuantity,
            ean: el.skuDetail.ean,
            availableStock: el.availableStock,
            bin: el.bin,
            section: el.section,
            expiryDate: momentLocal(el.expiryDate),
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(
            footer,
            pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
            pageHeight - 0.2,
            { baseline: "bottom" }
          );
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Stock Location Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Stock Location Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "Product Name",
          2: "Product Type",
          3: "Department",
          4: "PLU/SKU",
          5: "InStockQuantity",
          6: "BARCODE/EAN",
          7: "AvailableStock",
          8: "Bin",
          9: "Section",
          10: "ExpiryDate",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.skuDetail.description,
            2: item.skuDetail.productType,
            3: item.skuDetail.department,
            4: item.sku,
            5: item.inStockQuantity,
            6: item.skuDetail.ean,
            7: item.availableStock,
            8: item.bin,
            9: item.section,
            10: momentLocal(item.expiryDate),
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },

    getSkus(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.skuPageNumber,
        pageSize: this.limitSku,
        search: search,
      };
      axios
        .post("Sku/Stocks/" + this.warehouseStoreId, pagination)
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSSSearchResult = !!search;

            this.skus.unshift({
              skuName: null,
              description: "ALL",
            });
            this.skus =
              this.isSSSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.skus, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSku = response.data.data.hasNextPage;
            this.totalSSCount = response.data.data.totalCount;
            // this.addProductModalForm = true;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in PLU/SKU");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    async onOpenSku() {
      if (this.hasNextPageSku) {
        await this.$nextTick();
        this.observerSku.observe(this.$refs.loadSku);
      }
    },
    onCloseSku() {
      this.limitSku = 5;
      this.observerSku.disconnect();
    },
    async infiniteScrollSku([{ isIntersecting, target }]) {
      console.log("isIntersectingSku " + isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitSku = this.limitSku + 5;
        console.log("limitSku " + this.limitSku);
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    clearReports() {
      this.reportData.skuName = "";
      this.reports = [];
      this.reportsLocal = [];
    },

    getReports() {
      this.isLoading = true;

      axios
        .get(
          "MemoryStocks/" +
            this.warehouseAbbreviation +
            "/Products/" +
            this.reportData.skuName
        )
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = false;
            this.itemsData = response.data;
            if (response.data) {
              this.itemsData.totalCount = this.itemsData.length;
            }
            this.reports = response.data;

            this.setLocalReport();
            if (this.reports.length == 0) {
              this.isLoading = false;
              warningToast("NO DATA ");
            }
          } else {
            this.isLoading = false;
            failedToast("failed");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast("Error");
        });
    },

    searchOnchange() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getReports();
      this.searchActive = false;
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getReports();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
