<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="w-full mt-5">
      <div
        class="flex flex-col flex-wrap items-end justify-end w-full mt-2 intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between"
      >
        <div class="flex items-center justify-center">
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="printAllPages"
          />

          <div>
            <a
              href="javascript:;"
              class="mt-1 mb-1 mr-2 text-xs shadow-md btn btn-danger"
              @click="alertClearQueue = true"
            >
              Clear Queue
              <span class="ml-2">
                <ListOrderedIcon class="w-4 h-4 ml-2 mr-2 lg:w-6 lg:h-6" />
              </span>
            </a>
          </div>

          <FilterItems
            :headersFilter="headersFilter"
            @parentMethod="sorting($event)"
          />

          <SearchBar
            :searchActive="searchActive"
            @searchOnchange="searchOnchange($event)"
            @searchItem="searchItem($event)"
            @clearSearch="clearSearch($event)"
          />

          <div class="hidden mx-auto md:block text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="col-span-12 overflow-auto intro-y">
        <table class="table -mt-2 table-auto table-report">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>ORDER#</th>
              <th>Amount</th>
              <th>Created</th>
              <th>Customer</th>
              <th>WHOUSE</th>
              <th>Note</th>
              <th>Status</th>
              <th>Order Type</th>
              <th>Booking#</th>
              <th>PRIORITY No</th>
              <th>URGENT</th>
              <th class="flex justify-start">
                <SelectLabels
                  :allSelected="allSelected"
                  @selectAll="selectAll($event)"
                  @unSelectAll="unSelectAll($event)"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.orderNumber != null ? item.orderNumber : "" }}
              </td>
              <td :style="item.selected ? getSelectedColor() : ''">
                {{ currencyFormat(item.amount) }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.time != null ? momentLocal(item.time) : "" }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{
                  item.customer && item.customer.company
                    ? item.customer.company
                    : item.billTo || ""
                }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.warehouse != null ? item.warehouse : "" }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                <div class="text-left line-clamp-2">
                  <Tippy
                    tag="a"
                    href="javascript:;"
                    class="tooltip"
                    :content="item.note"
                  >
                    {{ item.note != null ? item.note : "" }}
                  </Tippy>
                </div>
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                <div
                  class="flex items-center justify-center p-1 pl-2 pr-2 text-white rounded-2xl whitespace-nowrap"
                  :style="{ 'background-color': getStatusColor(item.status) }"
                >
                  {{ item.status != null ? getStatus(item.status) : "" }}
                </div>
              </td>
              <td :style="item.selected ? getSelectedColor() : ''">
                {{ item.type != null ? getType(item.type) : "" }}
              </td>

              <td :style="item.selected ? getSelectedColor() : ''">
                <div class="flex flex-row items-center gap-2">
                  {{ item.despatchBookingNumber || "Despatch" }}
                  <EditItem
                    v-if="item.despatchBookingNumber != null"
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="editDespatch($event)"
                    :item="item"
                    name="Edit"
                  />
                  <AddSubItem
                    v-else
                    :activeAccess="$h.editItemAccess(policyAccess)"
                    @parentMethod="addDespatch($event)"
                    :item="item"
                    name="Add Dispatch"
                  />
                </div>
              </td>

              <td class="w-24" :style="item.selected ? getSelectedColor() : ''">
                <input
                  id="vertical-form-1"
                  v-model="item.priorityNumber"
                  type="number"
                  class="form-control"
                  name="Priority Number"
                  placeholder="Priority"
                  autocomplete="off"
                />
              </td>

              <td class="w-24" :style="item.selected ? getSelectedColor() : ''">
                <div class="ml-3 form-switch">
                  <input
                    id="booleanSwitch"
                    type="checkbox"
                    v-model="item.isUrgent"
                    class="form-check-input"
                  />
                </div>
              </td>

              <td class="table-report__action w-28">
                <div
                  class="flex flex-wrap p-2 lg:grid lg:grid-cols-3 gap-x-2 gap-y-2"
                >
                  <CheckItem
                    :item="item"
                    :checkedData="checkedData"
                    @parentMethod="checkItem($event)"
                  />
                  <InfoItem
                    @parentMethod="viewItemInfo($event)"
                    :item="item"
                    name="INFO"
                  />
                  <ClearItem
                    @parentMethod="openClearQueueOrderAlert(item)"
                    :item="item"
                    :name="`Clear ${item.orderNumber} from the queue`"
                  />
                  <AddItemPick
                    :activeAccess="$h.addItemAccess('PickingOrders')"
                    @parentMethod="sendPickingQueue($event)"
                    :item="item"
                    name="Submit for picking"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->

      <PaginationBar
        @changePage="changePage($event)"
        @pageSizeChange="pageSizeChange($event)"
        :itemsData="itemsData"
        :pageCount="pageCount"
        :pageSize="pageSizeView"
      />

      <!-- END: Pagination -->
    </div>
  </div>

  <!-- BEGIN: View Order Info Modal -->

  <MoveableModal
    :show="viewInfoModal"
    @hidden="viewInfoModal = false"
    backdrop="static"
    size="modal-sl-60"
    title="INFO"
    @close="viewInfoModal = false"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
        <div class="p-5 text-center">
          <BoxIcon class="w-8 h-8 mx-auto mt-2 text-danger" />

          <div class="mt-5 text-xl font-bold">
            ORDER# {{ formDataOrder.orderNumber }}
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-2 pt-4 intro-y box lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4"
        >
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>AMOUNT</div>
            </div>
            <div>
              {{ currencyFormat(formDataOrder.amount) }}
            </div>
          </div>
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>ORDER CREATED</div>
            </div>
            <div>
              {{
                formDataOrder.time != null
                  ? momentLocal(formDataOrder.time)
                  : ""
              }}
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>WAREHOUSE</div>
            </div>
            <div>
              {{
                formDataOrder.warehouse != null ? formDataOrder.warehouse : ""
              }}
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>BOOKING#</div>
            </div>
            <div>
              {{
                formDataOrder.despatchBookingNumber != null
                  ? formDataOrder.despatchBookingNumber
                  : ""
              }}
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>NOTE</div>
            </div>
            <div>
              {{ formDataOrder.note != null ? formDataOrder.note : "" }}
            </div>
          </div>
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>STATUS</div>
            </div>

            <div
              class="flex items-center justify-center p-1 pl-2 pr-2 text-white rounded-2xl whitespace-nowrap"
              :style="{
                'background-color': getStatusColor(formDataOrder.status),
              }"
            >
              {{
                formDataOrder.status != null
                  ? getStatus(formDataOrder.status)
                  : ""
              }}
            </div>
          </div>

          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>ORDER TYPE</div>
            </div>
            <div>
              {{
                formDataOrder.type != null ? getType(formDataOrder.type) : ""
              }}
            </div>
          </div>
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>Discount Percentage</div>
            </div>
            <div>
              {{ percentageFormat(formDataOrder.discountPercentage) }}
            </div>
          </div>
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>Discount Total</div>
            </div>
            <div>
              {{ currencyFormat(formDataOrder.discountTotal) }}
            </div>
          </div>
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>CUSTOMER</div>
            </div>
            <div>
              {{
                formDataOrder.customer != null
                  ? formDataOrder.customer.firstName
                  : ""
              }}
              {{
                formDataOrder.customer != null
                  ? formDataOrder.customer.lastName
                  : ""
              }}
            </div>
          </div>
          <div class="flex flex-col items-center mb-5">
            <div class="flex items-center font-bold">
              <div>DELIVERY ADDRESS</div>
            </div>
            <div class="flex flex-col items-center justify-center space-y-2">
              <div>
                {{
                  formDataOrder.deliveryAddress != null
                    ? formDataOrder.deliveryAddress.addressLine
                    : ""
                }}
              </div>
              <div>
                {{
                  formDataOrder.deliveryAddress != null
                    ? formDataOrder.deliveryAddress.postcode
                    : ""
                }}
              </div>
              <div>
                {{
                  formDataOrder.deliveryAddress != null
                    ? formDataOrder.deliveryAddress.state
                    : ""
                }}
              </div>
              <div>
                {{
                  formDataOrder.deliveryAddress != null
                    ? formDataOrder.deliveryAddress.city
                    : ""
                }}
              </div>
              <div>
                {{
                  formDataOrder.deliveryAddress != null
                    ? formDataOrder.deliveryAddress.country
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
  <!-- END: View Order Modal-->

  <!-- BEGIN: Add  Modal -->

  <MoveableModal
    :show="addDespatchModal"
    @hidden="addDespatchModal = false"
    backdrop="static"
    size="modal-sl-30"
    :title="desTitle"
    @close="addDespatchModal = false"
  >
    <ModalBody class="grid">
      <div>
        <label for="regular-form-1" class="form-label">Booking Number</label>

        <div class="flex flex-col w-full space-y-1">
          <v-select
            v-model="formDataDespatch.bookingNumber"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenBN"
            @close="onCloseBN"
            @search="searchBN"
            :key="bookingNumber"
            :get-option-label="(option) => option.bookingNumber"
            :options="bookingNumbs"
            :reduce="(bookingNumber) => bookingNumber.bookingNumber"
            label="bookingNumber"
          >
            <template #list-footer>
              <li v-show="hasNextPageBN" ref="loadBN" class="loader">
                Loading more...
              </li>
            </template>
          </v-select>
        </div>
        <div class="py-2 text-blue-600 cursor-pointer" @click="despatchLink()">
          Create Booking Number
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="addDespatchModal = false"
        class="w-24 mr-1 btn btn-outline-secondary"
      >
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button
        @click="saveDespatch()"
        type="submit"
        class="w-24 btn btn-primary"
      >
        {{ const_txt.SAVE_BTN }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <MoveableModal
    :show="pickingConfirmModal"
    @hidden="pickingConfirmModal = false"
    backdrop="static"
    size="modal-sl-60"
    :title="'ORDER# : ' + getOrderNo"
    @close="closeConfirm()"
  >
    <ModalBody class="flex flex-col items-center justify-center space-y-2">
      <div class="p-5 text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="w-16 h-16 mx-auto mt-3 bi bi-cart-check-fill text-success"
          viewBox="0 0 16 16"
        >
          <path
            d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"
          />
        </svg>
        <div class="mt-2 text-3xl">
          Do you want to queue this order for picking ?
        </div>
        <div class="mt-2 text-xl" v-if="checkOutOfStock()">
          Some of the stocks are out of stock
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="closeConfirm()"
          class="w-24 mr-1 btn btn-outline-secondary"
        >
          {{ const_txt.CANCEL_BTN }}
        </button>
        <button
          type="button"
          class="w-24 text-white bg-green-600 btn"
          :disabled="!agreeCheckBox"
          @click="openPickingAlert()"
        >
          Queue
        </button>
      </div>

      <div class="items-center justify-center text-base felx">
        <span class="italic text-danger">
          <span class="text-yellow-500">Warning!</span> if the order's stock is
          empty, process could not be done
        </span>
      </div>

      <div class="items-center justify-center space-x-2 felx">
        <input
          id="vertical-form-3"
          class="w-5 h-5 ml-2 mr-1 form-check-input bg-slate-100"
          type="checkbox"
          v-model="agreeCheckBox"
        />
        <span class="text-base italic"
          >I have agreed to confirm these orders</span
        >
      </div>

      <div class="w-full">
        <!-- <div class="w-full p-3 mt-3 sm:w-auto sm:mt-0">
          <div class="flex items-center justify-between">
            <div class="flex items-center" v-if="!addProductModalForm"></div>
          </div>

          <div
            v-if="addProductModalForm"
            class="grid grid-cols-1 gap-2 lg:grid-cols-3 md:grid-cols-3 lg:gap-4 md:gap-4"
          >
            <div class="col-span-2">
              <label for="regular-form-1" class="font-semibold form-label">
                PLU/SKU
              </label>

              <div class="flex flex-col w-full space-y-1">
                <input
                  id="vertical-form-1"
                  readonly
                  v-model="formDataOrdeLine.skuName"
                  type="text"
                  class="form-control"
                  placeholder="PLU/SKU"
                />
              </div>
            </div>

            <div class="">
              <label for="regular-form-1" class="font-semibold form-label">
                Quantity
              </label>

              <div class="flex flex-col w-full space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataOrdeLine.quantity"
                  type="number"
                  class="form-control"
                  name="quantity"
                  placeholder="0"
                />
              </div>
            </div>

            <div class="">
              <label for="regular-form-1" class="font-semibold form-label">
                Pick Type
              </label>

              <div class="flex flex-col w-full space-y-1">
                <v-select
                  v-model="formDataOrdeLine.blockType"
                  placeholder="SELECT"
                  class="style-chooser"
                  :options="blockTypeItems"
                  :reduce="(name) => name.name"
                  label="name"
                />
              </div>
            </div>

            <div class="">
              <label for="regular-form-1" class="font-semibold form-label">
                User
              </label>

              <div class="flex flex-col w-full space-y-1">
                <v-select
                  v-model="formDataOrdeLine.userId"
                  placeholder="SELECT"
                  class="style-chooser"
                  :options="pickersItems"
                  :reduce="(userName) => userName.userName"
                  label="userName"
                />
              </div>
            </div>

            <div
              class="flex items-end justify-end lg:col-span-3 md:col-span-3 col-span-0"
            >
              <button
                type="button"
                @click="closeFormProduct()"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                {{ const_txt.CANCEL_BTN }}
              </button>
              <button
                @click="updateFormProduct()"
                type="submit"
                class="mr-1 btn btn-primary"
              >
                Update OrderLine
              </button>
            </div>
          </div>
        </div> -->

        <div class="flex flex-col px-4 space-y-2">
          <div
            class="flex items-end justify-center w-full space-x-2"
            v-if="allSelectedPickType == true"
          >
            <div class="flex w-full space-x-2">
              <div class="w-full">
                <label for="regular-form-1" class="font-semibold form-label">
                  Pick Type
                </label>

                <div class="flex flex-col w-full space-y-1">
                  <v-select
                    v-model="blockType"
                    placeholder="SELECT"
                    class="style-chooser"
                    :options="blockTypeItems"
                    :reduce="(name) => name.name"
                    label="name"
                  />
                </div>
              </div>

              <div class="w-full">
                <label for="regular-form-1" class="font-semibold form-label">
                  User
                </label>

                <div class="flex flex-col w-full space-y-1">
                  <v-select
                    v-model="userId"
                    placeholder="SELECT"
                    class="style-chooser"
                    :options="pickersItems"
                    :reduce="(userName) => userName.userName"
                    label="userName"
                  />
                </div>
              </div>
            </div>

            <button
              type="button"
              @click="changeAllPickType(blockType, userId)"
              class="w-24 mr-1 btn btn-primary"
            >
              Update
            </button>

            <button
              type="button"
              @click="closeChangeAllPickType()"
              class="w-24 mr-1 btn btn-outline-secondary"
            >
              {{ const_txt.CANCEL_BTN }}
            </button>
          </div>
        </div>
        <div class="flex items-center pt-2" v-if="orderLinesLocal.length > 0">
          <button
            id="tabulator-print"
            class="w-1/2 mr-2 btn btn-outline-secondary sm:w-auto"
            @click="newprint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>

          <button
            id="tabulator-print"
            class="w-1/2 mr-2 btn btn-outline-secondary sm:w-auto"
            @click="newdownload(getOrderNo)"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Download PDF
          </button>

          <button
            id="tabulator-print"
            class="w-1/2 mr-2 btn btn-primary sm:w-auto"
            v-if="isStockLow"
            @click="reserveStocks()"
          >
            <ClipboardCheckIcon class="w-4 h-4 mr-2" /> Reserve Stocks
          </button>
        </div>
        <div class="col-span-12 p-4 overflow-auto intro-y">
          <table class="table -mt-2 table-auto table-report">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th>PLU/SKU</th>
                <th>Stock Status</th>
                <th>QTY</th>
                <th>reserved QTY</th>
                <th>dropped Quantity</th>
                <th>Unreserved QTY</th>
                <th>Available Stock</th>
                <th>Pick Type</th>
                <th>User</th>

                <th class="flex justify-start">
                  <SelectLabelsForPickType
                    :allSelectedPickType="allSelectedPickType"
                    @selectAllPickType="selectAllPickType($event)"
                    @unSelectAllPickType="unSelectAllPickType($event)"
                  />
                </th>
                <!-- <th>Priority</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-show="orderLinesLocal.length <= 0">
                <td colspan="1000">
                  <span
                    class="flex items-center justify-center font-bold text-success"
                    >{{ const_txt.NO_ORDER_LINE }}</span
                  >
                </td>
              </tr>
              <tr v-for="(item, i) in orderLinesLocal" :key="i" class="intro-x">
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  {{ i + 1 }}
                </td>
                <!-- <td v-for="(header, index) in orderLinesHeaders" :key="index">
                {{ item[header.value] }}
              </td> -->
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  {{ item.skuName != null ? item.skuName : "" }}
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div
                    class="flex items-center justify-center p-1 pl-2 pr-2 text-white rounded-2xl whitespace-nowrap"
                    :style="{
                      'background-color': getStockStatus(item.qtyStatus).color,
                    }"
                  >
                    {{
                      item.qtyStatus != null
                        ? getStockStatus(item.qtyStatus).name
                        : ""
                    }}
                  </div>
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  {{ item.quantity != null ? item.quantity : "" }}

                  <!-- <div v-if="item.isEdit == false">
                    {{ item.quantity != null ? item.quantity : "" }}
                  </div> -->

                  <!-- <div class="w-20" v-if="item.isEdit == true">
                    <input id="vertical-form-1" v-model="item.quantity" type="number" class="form-control" name="quantity" autocomplete="off" placeholder="0" />
                  </div> -->
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div class="flex items-center justify-center">
                    {{
                      item.reservedQty != null
                        ? item.reservedQty - item.droppedQuantity
                        : ""
                    }}
                  </div>
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div class="flex items-center justify-center">
                    {{ item.droppedQuantity }}
                  </div>
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div class="flex items-center justify-center">
                    {{ item.notReservedQty != null ? item.notReservedQty : "" }}
                  </div>
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div class="flex items-center justify-center">
                    {{ item.available != null ? item.available : "" }}
                  </div>
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div v-if="item.isEdit == false">
                    {{ item.blockType != null ? item.blockType : "" }}
                  </div>

                  <div
                    class="flex flex-col w-56 space-y-1"
                    v-if="item.isEdit == true"
                  >
                    <v-select
                      v-model="item.blockType"
                      placeholder="SELECT"
                      class="style-chooser"
                      :options="blockTypeItems"
                      :reduce="(name) => name.name"
                      label="name"
                    />
                  </div>
                </td>
                <td
                  :style="
                    item.qtyStatus === 1 ? 'background-color: #EF9A9A' : ''
                  "
                >
                  <div v-if="item.isEdit == false">
                    {{ item.userId != null ? item.userId : "" }}
                  </div>

                  <div
                    class="flex flex-col w-32 space-y-1"
                    v-if="item.isEdit == true"
                  >
                    <v-select
                      v-model="item.userId"
                      placeholder="SELECT"
                      class="style-chooser"
                      :options="pickersItems"
                      :reduce="(userName) => userName.userName"
                      label="userName"
                    />
                  </div>
                </td>

                <!-- <td>
                {{ item.priority != null ? getPriority(item.priority) : "" }}
              </td> -->

                <td class="w-20 table-report__action">
                  <div class="flex items-center justify-center">
                    <a
                      @click="
                        item.isEdit = true;
                        allSelectedPickType = false;
                      "
                      class="flex items-center mr-3"
                      href="javascript:;"
                      v-if="item.isEdit == false"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Edit"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <EditIcon
                          class="w-4 h-4 mr-1 md:w-5 text-primary md:h-5 lg:w-6 lg:h-6"
                        />
                      </Tippy>
                    </a>
                    <div
                      class="flex items-center justify-center p-1 space-x-1 bg-gray-200 rounded-lg"
                      v-if="item.isEdit == true"
                    >
                      <a
                        @click="editOrderLinesLocal(item, i)"
                        class="flex items-center"
                        href="javascript:;"
                        v-if="item.isEdit == true"
                      >
                        <Tippy
                          tag="a"
                          href="javascript:;"
                          class="tooltip"
                          content="Apply"
                          :options="{
                            zIndex: 99999,
                          }"
                        >
                          <CheckIcon
                            class="w-4 h-4 mr-1 text-green-600 md:w-5 md:h-5 lg:w-6 lg:h-6"
                          />
                        </Tippy>
                      </a>

                      <a
                        @click="item.isEdit = false"
                        class="flex items-center"
                        href="javascript:;"
                        v-if="item.isEdit == true"
                      >
                        <Tippy
                          tag="a"
                          href="javascript:;"
                          class="tooltip"
                          content="Cancel"
                          :options="{
                            zIndex: 99999,
                          }"
                        >
                          <XIcon
                            class="w-4 h-4 mr-1 md:w-5 text-danger md:h-5 lg:w-6 lg:h-6"
                          />
                        </Tippy>
                      </a>
                    </div>

                    <a
                      @click="addStock(item)"
                      class="flex items-center mr-3"
                      href="javascript:;"
                      v-if="item.qtyStatus === 1 || item.qtyStatus === 3"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Add Stock"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <PackagePlusIcon
                          class="w-4 h-4 mr-1 md:w-5 text-pending md:h-5 lg:w-6 lg:h-6"
                        />
                      </Tippy>
                    </a>

                    <a
                      @click="removeOrderLinesLocal(i, item)"
                      class="flex items-center mr-3"
                      href="javascript:;"
                    >
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Remove"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <Trash2Icon
                          class="w-4 h-4 mr-1 md:w-5 text-danger md:h-5 lg:w-6 lg:h-6"
                        />
                      </Tippy>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- BEGIN: Pagination -->
          <PaginationBarLocal
            @changePage="changePageProduct($event)"
            @pageSizeChange="pageSizeChangeProduct($event)"
            :itemsData="orderLinesData"
            :pageCount="pageCountProduct"
            :pageRange="pageRowCountProduct"
            :pageSize="pageSizeProduct"
          />
        </div>
      </div>
    </ModalBody>
  </MoveableModal>

  <PrintConfirm
    :modalStatus="pickerPdf"
    :modalConfirm="okConfirm"
    :modalPrintConfirm="newprintConfirm"
    :modalDownloadConfirm="newdownloadConfirm"
    :modalClose="closePrint"
  />

  <!-- <PrintConfirm
  v-if="pickerPdf"
      name=""
      :modalStatus="pickerPdf"
      :modalConfirm="PdfConfirm"
      :modalClose="closePrint"
      icon="PrinterIcon"
      color="text-green-600"
      :question="`Do you really want to Print/Download PDF?`"
      nameBtn="Ok"
      @changeOption="changeOption"
    /> -->
  <div class="hidden">
    <qrcode-vue
      render-as="canvas"
      :value="orderDataFrom?.orderNumber"
      size="45"
      level="H"
      :id="`fieldNoQR`"
    />
  </div>

  <ConfirmAlert
    :modalStatus="removeQueueModalConfirm"
    :modalConfirm="removeQueueConfirm"
    :modalClose="closeRemoveQueue"
    :isClose="false"
    icon="AlertTriangleIcon"
    color="danger"
    :question="`There are no queues in this order`"
    nameBtn="Ok"
  />

  <ConfirmAlert
    :modalStatus="submitConfirm"
    :modalConfirm="submitPickingConfirm"
    :modalClose="closeSubmitConfirm"
    :isClose="true"
    icon="CheckCircleIcon"
    color="green-600"
    :question="`Are you sure you want to queue this order for picking?`"
    nameBtn="Ok"
  />

  <ConfirmAlert
    :modalStatus="alertClearQueue"
    :modalClose="closeClearQueueAlert"
    :modalConfirm="confirmQueueAlert"
    :isClose="true"
    icon="CheckCircleIcon"
    color="green-600"
    :question="`Are you sure you want to clear the queue?`"
    nameBtn="Ok"
  />

  <ConfirmAlert
    :modalStatus="alertClearQueueOrder"
    :modalClose="closeClearQueueOrderAlert"
    :modalConfirm="confirmOrderQueueAlert"
    :isClose="true"
    icon="CheckCircleIcon"
    color="green-600"
    :question="`Are you sure you want to clear the queue order?`"
    nameBtn="Ok"
  />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";

import countriesJSON from "@/assets/json/countries.json";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import moment from "moment";
import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-functions/toast";
import {
  currencyFormat,
  percentageFormat,
  temperatureFormat,
  weightFormat,
  removeNullValue,
  removeNullValueObjectArray,
} from "../../../global-functions/functions";
import { exportCSVFile } from "../../../global-functions/functions";
import Moveable from "vue3-moveable";

import store from "../../../store";
import { COLORTYPE_GRAYSCALE } from "pngjs/lib/constants";
import zhCN from "date-fns/locale/zh-CN/index.js";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Queue Orders");
    this.observerBN = new IntersectionObserver(this.infiniteScrollBN);
  },
  components: {
    Moveable,

    Loading,
    autoTable,
  },
  data() {
    return {
      alertClearQueueOrder: false,
      alertClearQueue: false,
      submitConfirm: false,
      getOrderNo: "",
      getOrderId: 0,
      pri: 1,
      pickingConfirmModal: false,
      const_txt: const_txt,
      doc: null,
      searchData: "",
      isLoading: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,

      viewInfoModal: false,

      deleteOrderLineModel: false,

      titleChange: -1,
      orderChange: -1,
      desTitleChange: -1,
      pickingOrder: [],

      formDataOrder: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        despatchBookingNumber: "",
        currency: "",
        warehouse: "",
        warehouseId: "",
        customer: [],
        deliveryAddress: [],
        despatch: "",
        orderLines: [],
      },
      formDataOrderDefault: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        despatchBookingNumber: "",
        currency: "",
        warehouse: "",
        warehouseId: "",
        customer: [],
        deliveryAddress: [],
        despatch: "",
        orderLines: [],
      },
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      storedWarehouseId: store.state.authUser.warehouse.warehouseId,
      headers: [
        { text: "Order Number", value: "orderNumber" },

        { text: "Status", value: "status" },

        { text: "Amount", value: "amount" },
        // { text: "Type", value: "type" },
        // { text: "Time", value: "time" },
        // { text: "Amount", value: "amount" },
        // { text: "Discount Percentage", value: "discountPercentage" },
        // { text: "Discount Total", value: "discountTotal" },
        // { text: "Status", value: "Status" },
      ],
      headersFilter: [
        { text: "Order Number", value: "orderNumber" },
        { text: "Ware House", value: "warehouse" },
        { text: "Customer", value: "customer" },
        { text: "Status", value: "status" },

        { text: "Amount", value: "amount" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        // sortColumnName: "status",
        // sortDirection: "asc",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      supplierItems: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      //supplier

      skuList: [],

      //Product
      isUrgent: false,
      priorityNumber: 0,
      searchDataProduct: "",
      addProductModal: false,
      addProductModalForm: false,
      formDataOrdeLine: {
        skuName: "",
        quantity: "",
        blockType: "",
        priority: "",
        userId: "",
        available: "",
        qtyStatus: "",
      },
      formDataOrdeLineDefault: {
        skuName: "",
        quantity: "",
        blockType: "",
        priority: "",
        userId: "",
        available: "",
        qtyStatus: "",
      },
      paginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      defaultPaginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      orderLinesHeaders: [
        { text: "PLU/SKU", value: "skuName" },
        { text: "QTY", value: "quantity" },
        { text: "Pick Type", value: "blockType" },
        // { text: "Priority", value: "priority" },
        { text: "User", value: "userId" },
      ],
      productItems: [],
      productItemsData: [],

      products: [],
      orderNumber: "",
      orderLines: [],
      orderLinesData: {},
      customers: [],
      warehouses: [],
      countries: [],

      addDespatchModal: false,
      formDataDespatch: {
        warehouse: store.state.authUser.warehouse.abbreviation,
        orderNumber: "",
        bookingNumber: "",
      },
      bookingNumbs: [],

      pageCountProduct: 0,
      pageRowCountProduct: 5,
      productStart: 0,
      productEnd: 5,

      defaultOrderLinesLocal: [],
      orderLinesLocal: [],

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,

      blockTypeItems: [],
      pickersItems: [],
      storeIndex: "",
      isAddData: false,
      typeItems: store.state.authUser.OrderTypes,
      statusItems: store.state.authUser.OrderStatuses,
      priorityItems: [],

      priorityList: [
        { id: 1, name: "Low" },
        { id: 2, name: "Medium" },
        { id: 3, name: "High" },
      ],
      splitData: false,
      allSelected: false,
      allSelectedPickType: false,
      checkedData: [],
      checkedDataPickType: [],
      blockType: "",
      userId: "",

      agreeCheckBox: false,
      // isEdit:false,

      policyAccess: "QueueOrders",

      observerBN: null,
      limitBN: 5,
      hasNextPageBN: false,
      totalBNCount: 0,
      isBNSearchResult: false,
      bnPageNumber: 1,
      bnSearchTimer: undefined,
      pickerPdf: false,
      isPrint: false,
      storeLicensee: store.state.authUser.licensee,
      orderDataFrom: null,

      pickingLocations: [],
      removeQueueModalConfirm: false,
      isStockLow: false,
      currentQueueOrder: undefined,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1
        ? "Add New Queue Order"
        : "Edit Queue Order";
    },
    desTitle() {
      return this.desTitleChange === -1 ? "Add Despatch " : "Edit Despatch ";
    },
    checkLimtBN() {
      return this.bnPageNumber;
    },
    viewModalBN() {
      return this.formDataDespatch.bookingNumber;
    },
  },
  watch: {
    addProductModal(val) {
      val || this.addProductModalClose();
    },
    checkLimtBN() {
      this.getBookingNum();
    },
    viewModalBN() {
      if (this.formDataDespatch.bookingNumber === null) {
        this.clearBN();
      }
    },
  },

  created() {
    this.getDataFromApi();
  },
  methods: {
    reserveStocks() {
      this.isLoading = true;
      axios
        .get("CustomerOrders/" + this.getOrderId + "/ReserveStocks")
        .then((response) => {
          if (response.data.success) {
            successToast(response.data.message);
            // this.getDataFromApi();
            this.closeConfirm();
            this.sendPickingQueue(this.orderDataFrom);
            this.isLoading = false;
          } else {
            failedToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status || 500));
        });
    },
    addStock(item) {
      this.$router.push({
        path: "/wms/addstocks",
        query: { sku: `${item.skuName}` },
      });
      this.closeConfirm();
    },
    newdownloadConfirm() {
      this.newdownload(this.getOrderNo);
      this.pickerPdf = false;
      this.pickingConfirmModal = false;
      this.agreeCheckBox = false;
      this.orderDataFrom = null;
      this.closeConfirm();
    },
    newprintConfirm() {
      this.newprint();
      this.pickerPdf = false;
      this.pickingConfirmModal = false;
      this.agreeCheckBox = false;
      this.orderDataFrom = null;
      this.closeConfirm();
    },
    okConfirm() {
      this.pickerPdf = false;
      this.pickingConfirmModal = false;
      this.agreeCheckBox = false;
      this.orderDataFrom = null;
      this.closeConfirm();
    },
    closePrint() {
      this.pickerPdf = false;
      this.orderDataFrom = null;
    },

    newprint() {
      const qrCanvas = document.getElementById(`fieldNoQR`);

      var doc = new jsPDF("p", "pt", "a4");

      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();

      doc
        .setFontSize(15)
        .text(
          10,
          20,
          this.storeLicensee.companyName === undefined
            ? "PHARMATEC PTE LIMITED"
            : `${this.storeLicensee?.companyName || ""}`,
          {
            align: "left",
          }
        )
        .setFont(undefined, "bold");
      doc
        .setFontSize(10)
        .text(
          10,
          40,
          this.storeLicensee.address === undefined
            ? "P.O.Box 450,Ba"
            : `${this.storeLicensee?.address || ""}`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          10,
          50,
          this.storeLicensee.mobile === undefined
            ? "Ph: 6674933/6674533"
            : `Ph: ${this.storeLicensee?.mobile || ""}`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          10,
          60,
          this.storeLicensee.phoneNumber === undefined
            ? "Fax: 6670118"
            : `Fax: ${this.storeLicensee?.phoneNumber || ""}`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          10,
          70,
          this.storeLicensee.email === undefined
            ? "Email: admin@pharmatec.com.fj"
            : `Email: ${this.storeLicensee?.email || ""}`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          10,
          80,
          this.orderDataFrom.deliveryDate
            ? `Delivery Date : ` +
                this.momentLocal(this.orderDataFrom.deliveryDate)
            : "",
          {
            align: "left",
          }
        );

      if (this.orderDataFrom.deliveryAddress) {
        if (this.orderDataFrom.deliveryAddress.line) {
          doc
            .setFontSize(10)
            .text(10, 90, this.orderDataFrom.deliveryAddress.line + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.postcode) {
          doc
            .setFontSize(10)
            .text(10, 100, this.orderDataFrom.deliveryAddress.postcode + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.state) {
          doc
            .setFontSize(10)
            .text(10, 110, this.orderDataFrom.deliveryAddress.state + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.city) {
          doc
            .setFontSize(10)
            .text(10, 120, this.orderDataFrom.deliveryAddress.city + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.country) {
          doc
            .setFontSize(10)
            .text(10, 130, this.orderDataFrom.deliveryAddress.country, {
              align: "left",
            });
        }
      }

      doc
        .setFontSize(14)
        .text(
          docWidth - 150,
          20,
          this.orderDataFrom.orderNumber
            ? "Order# : " + this.orderDataFrom.orderNumber
            : "",
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          30,
          this.orderDataFrom?.customer?.company
            ? this.orderDataFrom.customer.company
            : "",
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          40,
          `${
            this.orderDataFrom?.time
              ? "Time : " + this.momentLocal(this.orderDataFrom?.time)
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          50,
          `${
            this.orderDataFrom?.customer?.email
              ? "Email : " + this.orderDataFrom.customer.email
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          60,
          `${
            this.orderDataFrom?.customer?.mobile
              ? "Mobile : " + this.orderDataFrom.customer.mobile
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          70,
          `${
            this.orderDataFrom?.customer?.telephone
              ? "Telephone : " + this.orderDataFrom.customer.telephone
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          80,
          `${
            this.orderDataFrom.despatchBookingNumber
              ? "Booking# : " + this.orderDataFrom.despatchBookingNumber
              : ""
          }`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          90,
          `${
            this.orderDataFrom.amount
              ? "Total : " + `$` + this.orderDataFrom.amount
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          100,
          `${
            this.orderDataFrom?.customer?.address
              ? "To : " +
                this.orderDataFrom.customer.address +
                (this.orderDataFrom.customer.state
                  ? ", " + this.orderDataFrom.customer.state
                  : "") +
                (this.orderDataFrom.customer.city
                  ? "\n" + this.orderDataFrom.customer.city
                  : "") +
                (this.orderDataFrom.customer.country
                  ? ", " + this.orderDataFrom.customer.country
                  : "")
              : ""
          }`,
          {
            align: "left",
          }
        );

      if (qrCanvas) {
        doc.addImage(qrCanvas.toDataURL(), "JPEG", docWidth - 150, 140, 50, 50);
      }

      const columns = [
        { title: "PLU/SKU", dataKey: "skuName" },
        { title: "QTY", dataKey: "quantity" },
        { title: "Picker Type", dataKey: "blockType" },
        { title: "User", dataKey: "userId" },
        { title: "Available Stock", dataKey: "available" },
        { title: "Stock Status", dataKey: "qtyStatus" },
      ];

      let data = [];
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          skuName: el.skuName,
          quantity: el.quantity,
          blockType: el.blockType,
          userId: el.userId,
          available: el.available,
          qtyStatus: el.qtyStatus,
        };
        data.push(obj);
      });

      autoTable(doc, {
        theme: "grid",
        columns,
        body: data,
        headStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 200,
        showHead: "firstPage",
        margin: { left: 40 },

        //startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 300,
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        doc.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;
        doc.setFontSize(10);
        doc.text(
          footer,
          pageWidth / 2 - doc.getTextWidth(footer) / 2,
          pageHeight - 5,
          { baseline: "bottom" }
        );
      }

      doc.autoPrint();
      doc.output("dataurlnewwindow");
    },

    newdownload(orderNumber) {
      const qrCanvas = document.getElementById(`fieldNoQR`);

      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();

      doc
        .setFontSize(15)
        .text(
          10,
          20,
          this.storeLicensee.companyName === undefined
            ? "PHARMATEC PTE LIMITED"
            : `${this.storeLicensee?.companyName || ""}`,
          {
            align: "left",
          }
        )
        .setFont(undefined, "bold");
      doc
        .setFontSize(10)
        .text(
          10,
          40,
          this.storeLicensee.address === undefined
            ? "P.O.Box 450,Ba"
            : `${this.storeLicensee?.address || ""}`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          10,
          50,
          this.storeLicensee.mobile === undefined
            ? "Ph: 6674933/6674533"
            : `Ph: ${this.storeLicensee?.mobile || ""}`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          10,
          60,
          this.storeLicensee.phoneNumber === undefined
            ? "Fax: 6670118"
            : `Fax: ${this.storeLicensee?.phoneNumber || ""}`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          10,
          70,
          this.storeLicensee.email === undefined
            ? "Email: admin@pharmatec.com.fj"
            : `Email: ${this.storeLicensee?.email || ""}`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          10,
          80,
          this.orderDataFrom.deliveryDate
            ? `Delivery Date : ` +
                this.momentLocal(this.orderDataFrom.deliveryDate)
            : "",
          {
            align: "left",
          }
        );

      if (this.orderDataFrom.deliveryAddress) {
        if (this.orderDataFrom.deliveryAddress.line) {
          doc
            .setFontSize(10)
            .text(10, 90, this.orderDataFrom.deliveryAddress.line + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.postcode) {
          doc
            .setFontSize(10)
            .text(10, 100, this.orderDataFrom.deliveryAddress.postcode + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.state) {
          doc
            .setFontSize(10)
            .text(10, 110, this.orderDataFrom.deliveryAddress.state + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.city) {
          doc
            .setFontSize(10)
            .text(10, 120, this.orderDataFrom.deliveryAddress.city + ",", {
              align: "left",
            });
        }

        if (this.orderDataFrom.deliveryAddress.country) {
          doc
            .setFontSize(10)
            .text(10, 130, this.orderDataFrom.deliveryAddress.country, {
              align: "left",
            });
        }
      }

      doc
        .setFontSize(14)
        .text(
          docWidth - 150,
          20,
          this.orderDataFrom.orderNumber
            ? "Order# : " + this.orderDataFrom.orderNumber
            : "",
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          30,
          this.orderDataFrom?.customer?.company
            ? this.orderDataFrom.customer.company
            : "",
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          40,
          `${
            this.orderDataFrom?.time
              ? "Time : " + this.momentLocal(this.orderDataFrom?.time)
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          50,
          `${
            this.orderDataFrom?.customer?.email
              ? "Email : " + this.orderDataFrom.customer.email
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          60,
          `${
            this.orderDataFrom?.customer?.mobile
              ? "Mobile : " + this.orderDataFrom.customer.mobile
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          70,
          `${
            this.orderDataFrom?.customer?.telephone
              ? "Telephone : " + this.orderDataFrom.customer.telephone
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          80,
          `${
            this.orderDataFrom.despatchBookingNumber
              ? "Booking# : " + this.orderDataFrom.despatchBookingNumber
              : ""
          }`,
          {
            align: "left",
          }
        );
      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          90,
          `${
            this.orderDataFrom.amount
              ? "Total : " + `$` + this.orderDataFrom.amount
              : ""
          }`,
          {
            align: "left",
          }
        );

      doc
        .setFontSize(10)
        .text(
          docWidth - 150,
          100,
          `${
            this.orderDataFrom?.customer?.address
              ? "To : " +
                this.orderDataFrom.customer.address +
                (this.orderDataFrom.customer.state
                  ? ", " + this.orderDataFrom.customer.state
                  : "") +
                (this.orderDataFrom.customer.city
                  ? "\n" + this.orderDataFrom.customer.city
                  : "") +
                (this.orderDataFrom.customer.country
                  ? ", " + this.orderDataFrom.customer.country
                  : "")
              : ""
          }`,
          {
            align: "left",
          }
        );

      if (qrCanvas) {
        doc.addImage(qrCanvas.toDataURL(), "JPEG", docWidth - 150, 140, 50, 50);
      }
      const columns = [
        { title: "PLU/SKU", dataKey: "skuName" },
        { title: "QTY", dataKey: "quantity" },
        { title: "Picker Type", dataKey: "blockType" },
        { title: "User", dataKey: "userId" },
        { title: "Available Stock", dataKey: "available" },
        { title: "Stock Status", dataKey: "qtyStatus" },
      ];

      let data = [];
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          skuName: el.skuName,
          quantity: el.quantity,
          blockType: el.blockType,
          userId: el.userId,
          available: el.available,
          qtyStatus: el.qtyStatus,
        };
        data.push(obj);
      });

      autoTable(doc, {
        theme: "grid",
        columns,
        body: data,
        headStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 200,
        showHead: "firstPage",
        margin: { left: 40 },

        //startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 300,
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        doc.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;
        doc.setFontSize(10);
        doc.text(
          footer,
          pageWidth / 2 - doc.getTextWidth(footer) / 2,
          pageHeight - 5,
          { baseline: "bottom" }
        );
      }
      var generateDate = new Date().toLocaleString();
      doc.save(`${orderNumber + "_" + generateDate}.pdf`);
    },
    checkOutOfStock() {
      let result = this.orderLines.some((x) => x.qtyStatus === "Out of stock");
      //console.log("checkOutOfStock", this.orderLines, result);
      return result;
    },
    async onOpenBN() {
      if (this.isBNSearchResult) {
        this.bnPageNumber = 1;
      }
      if (this.hasNextPageBN) {
        await this.$nextTick();
        this.observerBN.observe(this.$refs.loadBN);
      }
    },
    onCloseBN() {
      this.observerBN.disconnect();
    },
    async infiniteScrollBN([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.bnPageNumber++;
        this.limitBN;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    closeConfirm() {
      this.priorityNumber = 0;
      this.isUrgent = false;
      this.getOrderNo = "";
      this.getOrderId = 0;
      this.pickingConfirmModal = false;
      this.isStockLow = false;
      const index = this.items
        .map((x) => x.orderId)
        .indexOf(this.formDataOrder?.orderId);

      if (index) {
        this.items[index].priorityNumber = "";
        this.items[index].isUrgent = false;
      }
      this.agreeCheckBox = false;
      this.pickingLocations = [];
      // this.getDataFromApi();
    },
    openPickingAlert() {
      if (this.isStockLow) {
        this.submitConfirm = true;
      } else {
        this.submitPicking();
      }
    },
    submitPicking() {
      this.saveFormPicking();
      //this.saveFormProduct();
    },
    saveFormPicking() {
      this.isLoading = true;
      var productList = [];

      // for (var i = 0; i < this.orderLines.length; i++) {
      //   if (
      //     this.orderLines[i].reservedQty - this.orderLines[i].droppedQuantity >
      //     0
      //   ) {
      //     var formData = {
      //       skuName: this.orderLines[i].skuName,
      //       quantity: this.orderLines[i].reservedQty,
      //       blockType: this.orderLines[i].blockType,
      //       priority: 1,
      //       userId: this.orderLines[i].userId
      //         ? this.orderLines[i].userId
      //         : null,
      //     };
      //     productList.push(formData);
      //   }
      // }

      // Filter out order lines that exist in getPickingOrderLines based on SKU name
      let filteredOrderLines;
      if (this.pickingOrder.length > 0) {
        filteredOrderLines = this.orderLines.filter((orderLine) => {
          return !this.pickingOrder.some((pickingOrderLine) => {
            return pickingOrderLine.sku === orderLine.skuName;
          });
        });
      } else {
        // If getPickingOrderLines is undefined, use the original orderLines
        filteredOrderLines = this.orderLines;
      }

      for (var i = 0; i < filteredOrderLines.length; i++) {
        if (
          filteredOrderLines[i].reservedQty -
            filteredOrderLines[i].droppedQuantity >
          0
        ) {
          var formData = {
            skuName: filteredOrderLines[i].skuName,
            quantity: filteredOrderLines[i].reservedQty,
            blockType: filteredOrderLines[i].blockType,
            priority: 1,
            userId: filteredOrderLines[i].userId
              ? filteredOrderLines[i].userId
              : null,
          };
          productList.push(formData);
        }
      }

      var formDataOrderPicking = {
        warehouse: this.warehouseAbbreviation,
        orderNumber: this.getOrderNo,
        priority: this.pri,
        priorityNumber: this.priorityNumber,
        isUrgent: this.isUrgent,
        products: productList,
      };
      if (productList.length > 0) {
        axios
          .post("OrderPickingQueue", formDataOrderPicking)
          .then((response) => {
            if (response.data.success) {
              this.orderPickingItems = response.data.data.items;
              this.getDataFromApi();
              successToast(response.data.message);
              this.pickerPdf = true;
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      } else {
        failedToast("Please  reserve stock.");
        this.isLoading = false;
      }
    },
    getStatus(value) {
      try {
        return this.statusItems.find((x) => x.number === value).displayName;
      } catch (e) {
        return "";
      }
    },
    getStatusColor(value) {
      try {
        return this.statusItems.find((x) => x.number === value).color;
      } catch (e) {
        return "";
      }
    },
    getType(value) {
      try {
        return this.typeItems.find((type) => type.number === value).name;
      } catch (e) {
        return "";
      }
    },

    getPriority(value) {
      try {
        if (value == 1) {
          return "Low";
        } else if (value == 2) {
          return "Medium";
        } else if (value == 3) {
          return "High";
        } else {
          return "";
        }
        // return priorityData.find((x) => x.number === value).name;
      } catch (e) {
        return "";
      }
    },
    editOrderLinesLocal(item, index) {
      this.storeIndex = index;
      this.titleChange = 0;
      // this.formDataOrdeLine = Object.assign({}, item);
      this.updateFormProduct(item);
      // removeNullValue(item);
      // this.addProductModalForm = true;
      for (var i = 0; i < this.orderLines.length; i++) {
        this.orderLines[i].isEdit = false;
      }

      this.activateModalProduct();
      this.allSelectedPickType = false;
      this.blockType = "";
      this.userId = "";
    },

    deleteOrdeLinesLocal(index) {
      this.orderLines.splice(index, 1);
      // for (var i = 0; i < this.orderLinesLocal.length; i++) {
      //   this.orderLinesLocal[i].no = i + 1;
      // }
      // successToast(const_txt.REMOVED_ORDER_LINE);
    },

    removeOrderLinesLocal(index, item) {
      this.orderLinesLocal.splice(index, 1);
      let orderLineIndex = this.orderLines.findIndex(
        (x) => x.skuName === item.skuName
      );
      this.orderLines.splice(orderLineIndex, 1);
      for (var i = 0; i < this.orderLines.length; i++) {
        this.orderLines[i].no = i + 1;
      }
      successToast(const_txt.REMOVED_ORDER_LINE);
    },

    getBlockType() {
      axios
        .get("BlockTypes/" + this.storedWarehouseId)
        .then((response) => {
          this.blockTypeItems = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    getPickers() {
      axios
        .get("Pickers")
        .then((response) => {
          this.pickersItems = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }

        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Order#", dataKey: "orderNumber" },
              { title: "Amount", dataKey: "amount" },
              { title: "Order Created", dataKey: "time" },
              { title: "Booking#", dataKey: "despatchBookingNumber" },
              { title: "Customer", dataKey: "customer" },
              { title: "Address", dataKey: "deliveryAddress" },
              { title: "Note", dataKey: "note" },
              { title: "Status", dataKey: "status" },
              { title: "Type", dataKey: "type" },
              { title: "Discount Percentage", dataKey: "discountPercentage" },
              { title: "Discount Total", dataKey: "discountTotal" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                orderNumber: el.orderNumber,
                amount: el.amount,
                time: this.momentLocal(el.time),
                despatchBookingNumber: el.despatchBookingNumber,
                customer:
                  el.customer.length == null
                    ? ""
                    : el.customer.firstName + " " + el.customer.lastName,
                deliveryAddress:
                  el.deliveryAddress == null
                    ? ""
                    : el.deliveryAddress.line +
                      "\n" +
                      el.deliveryAddress.postcode +
                      "\n" +
                      el.deliveryAddress.state +
                      "\n" +
                      el.deliveryAddress.city +
                      "\n" +
                      el.deliveryAddress.country,
                note: el.note,
                status: this.getStatus(el.status),
                type: this.getType(el.type),
                discountPercentage: el.discountPercentage,
                discountTotal: el.discountTotal,
              };
              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width
                ? pageSize.width
                : pageSize.getWidth();
              const pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(
                footer,
                pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
                pageHeight - 0.2,
                { baseline: "bottom" }
              );
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post(
          `OrderPickingQueue/${this.warehouseAbbreviation}/NewOrders`,
          pagination
        )
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    unSelectAllPickType() {
      this.allSelectedPickType = false;
      this.checkedDataPickType = [];
      this.blockType = "";
      this.userId = "";
    },
    selectAllPickType() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      this.allSelectedPickType = true;
      this.checkedDataPickType = [];
      for (var i = 0; i < this.orderLines.length; i++) {
        this.checkedDataPickType[i] = this.orderLines[i];
      }
      for (var i = 0; i < this.orderLines.length; i++) {
        this.orderLines[i].isEdit = false;
      }

      this.addProductModalForm = false;
    },
    closeChangeAllPickType() {
      this.allSelectedPickType = false;
      this.blockType = "";
      this.userId = "";
    },

    changeAllPickType(blockType, userId) {
      if (blockType == "" && userId == "") {
        failedToast("Pick Type and User both are empty");
      } else {
        for (var z = 0; z < this.orderLines.length; z++) {
          this.isLoading = true;
          var item = this.orderLines[z];

          let pickingSku = this.pickingLocations.find(
            (pl) => pl.skuName === item.skuName
          );

          let index = this.orderLines.findIndex((obj) => {
            return obj.skuName === item.skuName;
          });

          var availableQty;
          var _blockType;
          var qtyStatus;
          var designatedQuantity;
          var notReserved;

          if (pickingSku) {
            availableQty = pickingSku?.stockDetails.available;
            designatedQuantity = pickingSku?.stockDetails.designatedQuantity;
            _blockType = pickingSku?.locations[0].blockType;

            let _notReserved =
              Number(item.quantity) - Number(item.reservedQuantity);
            let _quantity = Number(item.notQueuedQuantity);

            if (_notReserved > 0 && availableQty < _notReserved) {
              qtyStatus = 1;
            } else if (_quantity <= designatedQuantity) {
              qtyStatus = 2;
            } else if (_quantity > designatedQuantity) {
              qtyStatus = 3;
            }

            this.orderLines[index].blockType = blockType;
            this.orderLines[index].userId = userId;
            this.orderLines[index].available = availableQty;
            // this.orderLines[index].notReservedQty = _notReserved;
            // this.orderLines[index].reservedQty = item.reservedQuantity;

            this.isLoading = false;
          }
        }
        successToast("Updated successfully");
        // new Promise(async () => {
        //   this.isLoading = true;
        //   for (var z = 0; z < this.orderLines.length; z++) {
        //     var skuName = this.orderLines[z].skuName;
        //     var response = await axios.get("MemoryStocks/" + this.warehouseAbbreviation + "/Products/" + skuName);

        //     var getBlockType;
        //     var getUserId;

        //     if (response.data.length > 0) {
        //       var res = await response.data[0];
        //       // console.log("res",res);

        //       var res2 = await response.data;
        //       // console.log("orderLines",this.orderLines);

        //       let index = await this.orderLines.findIndex((obj) => {
        //         return obj.skuName === res.sku;
        //       });

        //       if (blockType == "" || userId == "") {
        //         if (blockType == "") {
        //           getBlockType = "";
        //         } else {
        //           getBlockType = blockType;
        //         }
        //         if (userId == "") {
        //           getUserId = "";
        //         } else {
        //           getUserId = userId;
        //         }
        //       } else {
        //         getBlockType = blockType;
        //         getUserId = userId;
        //       }

        //       var totalAvailableStock = res2.reduce((a, b) => ({
        //         availableStock: a.availableStock + b.availableStock,
        //       })).availableStock;
        //       var availableQty = totalAvailableStock;
        //       var qtyBlockType = res.blockType;

        //       if (getBlockType == qtyBlockType) {
        //         if (this.orderLines[index].quantity <= availableQty) {
        //           this.orderLines[index].qtyStatus = 2;
        //         } else if (availableQty == 0) {
        //           this.orderLines[index].qtyStatus = 1;
        //         } else if (this.orderLines[index].quantity > availableQty) {
        //           this.orderLines[index].qtyStatus = 3;
        //         }
        //       } else {
        //         availableQty = 0;
        //         this.orderLines[index].qtyStatus = 1;
        //       }

        //       this.orderLines[index].available = availableQty;
        //       this.orderLines[index].exist = true;
        //     } else {
        //       this.isLoading = false;
        //       this.orderLines.forEach((x) => {
        //         x.exist = false;
        //       });
        //       warningToast(`${skuName} is not found`);
        //     }

        //     for (var i = 0; i < this.orderLines.length; i++) {
        //       if (this.orderLines[i].exist) {
        //         this.orderLines[i].isEdit = false;
        //         this.orderLines[i].blockType = getBlockType;
        //         this.orderLines[i].userId = getUserId;
        //       }
        //     }
        //   }
        //   this.isLoading = false;
        // });
      }

      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 1000);
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Queue Orders " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Queue Orders " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Order#",
          2: "Amount",
          3: "Order Created",
          4: "Booking#",
          5: "Customer",
          6: "Address Line",
          7: "Postcode",
          8: "State",
          9: "City",
          10: "Country",
          11: "Note",
          12: "Status",
          13: "Type",
          14: "Discount Percentage",
          15: "Discount Total",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.orderNumber,
            2: item.amount,
            3: this.momentLocalCSV(item.time),
            4: item.despatchBookingNumber,
            5:
              item.customer.length <= 0
                ? ""
                : item.customer.firstName + " " + item.customer.lastName,
            6:
              item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.line,
            7:
              item.deliveryAddress.length <= 0
                ? ""
                : item.deliveryAddress.postcode,
            8:
              item.deliveryAddress.length <= 0
                ? ""
                : item.deliveryAddress.state,
            9:
              item.deliveryAddress.length <= 0 ? "" : item.deliveryAddress.city,
            10:
              item.deliveryAddress.length <= 0
                ? ""
                : item.deliveryAddress.country,
            11: item.note,
            12: this.getStatus(item.status),
            13: this.getType(item.type),
            14: item.discountPercentage,
            15: item.discountTotal,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },
    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post(
          `OrderPickingQueue/${this.warehouseAbbreviation}/NewOrders`,
          pagination
        )
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },
    getToday() {
      const today = new Date();

      this.formDataOrder.time = moment(today).format("DD MMMM, YYYY");
    },
    setReport() {
      const columns = [
        { title: "PLU/SKU", dataKey: "skuName" },
        { title: "QTY", dataKey: "quantity" },
        { title: "BLOCK TYPE", dataKey: "blockType" },
        { title: "PRIORITY", dataKey: "priority" },
        { title: "USER", dataKey: "user" },
      ];
      this.doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });

      let data = [];
      let i = 0;
      let obj;

      this.orderLinesLocal.forEach((el) => {
        obj = {
          skuName: el.skuName,
          quantity: el.quantity,
          retailPrice: el.retailPrice,
          blockType: el.blockType,
          priority: this.getPriority(el.priority),
          user: el.user,
        };

        data.push(obj);
      });

      var imgData = "../../../assets/images/pl_logo2.png";
      this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
      this.doc
        .setFontSize(16)
        .text("Order Number : " + this.formDataOrder.orderNumber, 5, 1.2);

      this.doc.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

      this.doc.setFontSize(14).text("Deliver To :", 0.5, 2.5);

      this.doc.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
      this.doc.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
      this.doc.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
      this.doc.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
      this.doc.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
      this.doc.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
      this.doc.setFontSize(12).text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

      // var fname = this.formDataOrder.customer.firstName;
      // var mobile = this.formDataOrder.customer.mobile;

      // var lname = this.formDataOrder.customer.lastName;
      // var company = this.formDataOrder.customer.company;
      // var email = this.formDataOrder.customer.email;
      var time = this.momentLocal(this.formDataOrder.time);
      var amount = this.currencyFormat(this.formDataOrder.amount);
      var despatchBookingNumber = this.formDataOrder.despatchBookingNumber;

      // if (!fname) {
      //   fname = "";
      // }
      // if (!lname) {
      //   lname = "";
      // }
      // if (!company) {
      //   company = "";
      // }
      // if (!mobile) {
      //   mobile = "";
      // }
      // if (!email) {
      //   email = "";
      // }
      if (!time) {
        time = "";
      }
      if (!amount) {
        amount = "";
      }
      if (!despatchBookingNumber) {
        despatchBookingNumber = "";
      }

      //trading partner
      this.doc.setFontSize(14).text("Trading Partner :", 5, 2.5);

      // this.doc.setFontSize(12).text(fname + " " + lname, 5.2, 2.7);
      // this.doc.setFontSize(12).text(company, 5.2, 2.9);
      // this.doc.setFontSize(12).text(mobile, 5.2, 3.1);

      // this.doc.setFontSize(12).text(email, 5.2, 3.3);

      this.doc.setFontSize(12).text("Date: " + time, 5.2, 3.5);

      this.doc.setFontSize(12).text("Total : " + amount, 5.2, 3.7);

      this.doc
        .setFontSize(12)
        .text("Booking No: " + despatchBookingNumber, 5.2, 3.9);

      autoTable(this.doc, {
        theme: "grid",
        columns,
        body: data,
        headerStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        showHead: "firstPage",
        margin: { left: 0.5, top: 4.4 },
      });
    },

    onPrint() {
      this.setReport();
      this.doc.autoPrint();
      this.doc.output("dataurlnewwindow");
    },

    generateReport() {
      this.setReport();
      var generateDate = new Date().toLocaleString();
      this.doc.save(`${generateDate}.pdf`);
    },

    addProductModalClose() {
      this.isLoading = true;

      for (var i = 0; i < this.items.length; i++) {
        this.items[i].selected = false;
        this.isLoading = false;

        this.addProductModal = false;
      }
    },

    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    viewItemInfo(item) {
      // removeNullValue(item);
      this.formDataOrder = Object.assign({}, item);
      this.viewInfoModal = true;
    },
    // getProducts() {
    //   this.isLoading = true;
    //   var pagination = { pageNumber: 1, pageSize: 100 };
    //   axios
    //     .post("Products/Paginated", pagination)
    //     .then((response) => {
    //       this.products = response.data.data.items;

    //       // if (this.products.length > 0) {
    //       //   this.formDataOrder.productId = this.products[0].productId;
    //       // } else
    //       if (this.products.length == 0) {
    //         warningToast("Please Add Items in Product");
    //         this.addInvoice = false;
    //       }
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },

    getWarehouseAbb(value) {
      try {
        return this.warehouses.find((x) => x.warehouseId === parseInt(value))
          .abbreviation;
      } catch {
        return "";
      }
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;

      this.getDataFromApi();
    },
    setDefault() {
      this.formDataOrder = Object.assign({}, this.formDataOrderDefault);
      this.getToday();
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post(
          `OrderPickingQueue/${this.warehouseAbbreviation}/NewOrders`,
          this.pagination
        )
        .then((response) => {
          this.itemsData = response.data.data;
          this.items = response.data.data.items;

          this.supplierItems = response.data.data.items.supplier;

          this.pageCount = this.itemsData.totalPages;
          if (this.pagination.pageNumber == this.itemsData.totalPages) {
            this.pageSizeView =
              this.itemsData.totalCount -
              (this.pageCount - 1) * this.pagination.pageSize;
          } else {
            this.pageSizeView = this.pagination.pageSize;
          }

          for (var i = 0; i < this.items.length; i++) {
            this.items[i].selected = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    // getDataFromApiProduct() {
    //   axios
    //     .post("CustomerOrders/Paginated", this.pagination)
    //     .then((response) => {
    //       this.productItemsData = response.data.data;
    //       var customerOrderId = this.formDataOrdeLine.customerOrderId;
    //       this.productItems = response.data.data.items.filter(
    //         (a) => a.customerOrderId === customerOrderId
    //       );
    //       console.log("productItems", this.productItems);
    //       this.pageCountProduct = this.itemsData.totalPages;
    //     });
    // },

    getCountries() {
      this.countries = countriesJSON;
    },

    editItem(item) {
      this.titleChange = 0;
      this.formDataOrder = Object.assign({}, item);
      this.activateModal();
    },

    connectTimetoDate() {
      var currentTime = moment(new Date()).format();
      var splitTime = currentTime.split("T");

      var time = moment(this.formDataOrder.time).format("YYYY-MM-DD");
      time = time.concat("T").concat(splitTime[1]);
      this.formDataOrder.time = time;

      var deliveryDate = moment(this.formDataOrder.deliveryDate).format(
        "YYYY-MM-DD"
      );
      deliveryDate = deliveryDate.concat("T").concat(splitTime[1]);
      this.formDataOrder.deliveryDate = deliveryDate;
    },

    //Product
    pageSizeChangeProduct() {
      // this.pagination.pageSize = this.pageSize;
      // this.getDataFromApiProduct();
    },
    changePageProduct(pageNum) {
      this.pageNumber = pageNum;
      this.productEnd = this.pageRowCountProduct * pageNum;
      this.productStart = this.productEnd - this.pageRowCountProduct;
      this.orderLinesLocal = this.orderLines.slice(
        this.productStart,
        this.productEnd
      );

      // this.getDataFromApiProduct();
    },
    searchOnchangeProduct() {
      if (this.searchDataProduct != "") {
        this.searchActiveProduct = true;
        this.searchItemProduct();
      } else {
        this.clearSearchProduct();
      }
    },
    clearSearchProduct() {
      this.pagination.pageNumber = 1;
      this.searchDataProduct = "";
      this.pagination.searchProduct = this.searchDataProduct;
      //    this.getDataFromApiProduct();
      this.searchActiveProduct = false;
    },
    searchItemProduct() {
      if (this.searchDataProduct != "") {
        this.searchActiveProduct = true;
        this.pagination.pageNumber = 1;
        this.pagination.searchProduct = this.searchDataProduct;
        //      this.getDataFromApiProduct();
      }
    },
    setDefaultProduct() {
      this.formDataOrdeLine = Object.assign({}, this.formDataOrdeLineDefault);
      // this.formDataOrdeLine.orderNumber = this.formDataOrder.orderNumber;
    },

    showModalProduct() {
      this.setDefaultProduct();
      this.activateModalProduct();
      this.orderChange = 0;
    },
    activateModalProduct() {
      this.getSkus();
      // this.getBlockType();
      //  this.addProductModalForm = true;
    },

    closeFormProduct() {
      this.addProductModalForm = false;
    },

    setLocalPagination() {
      if (this.orderLinesData) {
        this.orderLinesData.totalCount = this.orderLines.length;
      }

      console.log("pagination");
      console.log("this.orderLinesData", this.orderLinesData);

      var pageCount = this.orderLines.length / this.pageRowCountProduct;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCountProduct = pageCountRound;
      } else {
        this.pageCountProduct = pageCountRound;
      }
      if (this.pageRowCountProduct > this.orderLinesData.totalCount) {
        this.pageSizeProduct = this.orderLinesData.totalCount;
      } else {
        this.pageSizeProduct = this.pageRowCountProduct;
      }

      //pageCountProduct
      this.orderLinesLocal = this.orderLines.slice(
        this.productStart,
        this.productEnd
      );
      console.log("TEST!", this.orderLinesLocal);
    },
    saveFormProduct() {
      this.isLoading = true;

      if (this.orderLines.length <= 0) {
        this.isLoading = false;
        warningToast("No Order Lines");
        this.pickerPdf = false;
      } else {
        this.pickerPdf = true;
        var orderLineArray = [];
        for (var i = 0; i < this.orderLines.length; i++) {
          var formdata = {
            skuName: this.orderLines[i].skuName,
            quantity: this.orderLines[i].quantity,
            blockType: this.orderLines[i].blockType,
            priority: 1,
            userId: this.orderLines[i].userId,
          };
          orderLineArray.push(formdata);
        }

        axios
          .post(
            "Picking/" +
              this.warehouseAbbreviation +
              "/" +
              this.getOrderNo +
              "/AddOrderLines",
            orderLineArray
          )
          .then((response) => {
            if (response.status === 200) {
              successToast(const_txt.ADDED_ORDER_LINE);
              this.isLoading = false;
            } else {
              failedToast(const_txt.FAILED_ORDER_LINE);
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },

    updateFormProduct(item) {
      if (item != null) {
        this.isLoading = true;

        let pickingSku = this.pickingLocations.find(
          (pl) => pl.skuName === item.skuName
        );

        let index = this.orderLines.findIndex((obj) => {
          return obj.skuName === item.skuName;
        });

        var availableQty;
        var blockType;
        var qtyStatus;
        var designatedQuantity;
        var notReserved;

        if (pickingSku) {
          availableQty = pickingSku?.stockDetails.available;
          designatedQuantity = pickingSku?.stockDetails.designatedQuantity;
          blockType = pickingSku?.locations[0].blockType;

          let _notReserved =
            Number(item.quantity) - Number(item.reservedQuantity);
          let _quantity = Number(item.notQueuedQuantity);

          if (_notReserved > 0 && availableQty < _notReserved) {
            qtyStatus = 1;
          } else if (_quantity <= designatedQuantity) {
            qtyStatus = 2;
          } else if (_quantity > designatedQuantity) {
            qtyStatus = 3;
          }

          this.orderLines[index].blockType = item.blockType;
          this.orderLines[index].userId = item.userId;
          this.orderLines[index].available = availableQty;
          // this.orderLines[index].notReservedQty = _notReserved;
          // this.orderLines[index].reservedQty = item.reservedQuantity;

          successToast(const_txt.ADDED_ORDER_LINE);

          this.isLoading = false;
        }

        // axios
        //   .get("MemoryStocks/" + this.warehouseAbbreviation + "/Products/" + item.skuName)
        //   .then((response) => {
        //     var res = response.data[0];
        //     var res2 = response.data;
        //     let index = this.orderLines.findIndex((obj) => {
        //       return obj.skuName === res.sku;
        //     });

        //     // for (var y = 0; y < res.length; y++) {
        //     var totalAvailableStock = res2.reduce((a, b) => ({
        //       availableStock: a.availableStock + b.availableStock,
        //     })).availableStock;
        //     var availableQty = totalAvailableStock;
        //     var qtyBlockType = res.blockType;
        //     // var qtyStatus;
        //     if (item.blockType == qtyBlockType) {
        //       if (item.quantity <= availableQty) {
        //         this.orderLines[index].qtyStatus = "Stock Available";
        //       } else if (availableQty == 0) {
        //         this.orderLines[index].qtyStatus = "Out of stock";
        //       } else if (item.quantity > availableQty) {
        //         this.orderLines[index].qtyStatus = "Low stock";
        //       }
        //     } else {
        //       availableQty = 0;
        //       this.orderLines[index].qtyStatus = "Out of stock";
        //     }

        //     this.orderLines[index].blockType = item.blockType;
        //     this.orderLines[index].userId = item.userId;
        //     this.orderLines[index].available = availableQty;
        //     // var formDataOrdeLine = {
        //     //   skuName: item.skuName,
        //     //   quantity: item.quantity,
        //     //   blockType: item.blockType,
        //     //   priority: 1,
        //     //   userId: item.userId,
        //     //   available: availableQty,
        //     //   qtyStatus: qtyStatus,
        //     // };

        //     // this.orderLines.push(formDataOrdeLine);
        //     // for (var o = 0; o < this.orderLines.length; o++) {
        //     //   this.orderLines[o].isEdit = false;
        //     //   this.orderLines[o].blockType = item.blockType;
        //     //   this.orderLines[o].quantity = item.quantity;
        //     //   this.orderLines[o].userId = item.userId;
        //     // }

        //     successToast(const_txt.ADDED_ORDER_LINE);
        //     // this.deleteOrdeLinesLocal(this.storeIndex);
        //     // this.addProductModalForm = false;
        //     // this.isAddData = true;
        //     this.isLoading = false;
        //     // }
        //   })
        //   .catch((error) => {
        //     this.isLoading = false;
        //     warningToast(statusMessage(error.response.status));
        //   });
      } else {
        this.isLoading = false;
        warningToast(statusMessage(const_txt.FAILED_ORDER_LINE));
      }
    },

    getStockStatus(type) {
      switch (type) {
        case 1:
          return {
            name: "Out of stock",
            color: "#ff3800",
          };
        case 2:
          return {
            name: "Partially Out of stock",
            color: "#ffb29f",
          };
        case 3:
          return {
            name: "Partially Reserved",
            color: "#A5D6A7",
          };
        case 4:
          return {
            name: "Stock Available",
            color: "#006900",
          };
        case 5:
          return {
            name: "Stocks Not Reserved",
            color: "#62778e",
          };
        default:
          return {
            name: "",
            color: "",
          };
      }
    },
    submitPickingConfirm() {
      this.submitPicking();
      this.submitConfirm = false;
    },
    closeRemoveQueue() {
      this.removeQueueConfirm();
    },
    closeSubmitConfirm() {
      this.submitConfirm = false;
    },
    closeClearQueueAlert() {
      this.alertClearQueue = false;
    },
    confirmQueueAlert() {
      this.clearQueue();
      this.alertClearQueue = false;
    },
    openClearQueueOrderAlert(item) {
      this.alertClearQueueOrder = true;
      this.currentQueueOrder = item.orderNumber;
    },
    closeClearQueueOrderAlert() {
      this.currentQueueOrder = undefined;
      this.alertClearQueueOrder = false;
    },
    confirmOrderQueueAlert() {
      this.clearOrderFromQueue();
      this.alertClearQueueOrder = false;
    },
    removeQueueConfirm() {
      if (this.getOrderNo) {
        this.isLoading = true;
        axios
          .post(
            "OrderPickingQueue/" +
              this.warehouseAbbreviation +
              "/NewOrders/" +
              this.getOrderNo +
              "/Remove"
          )
          .then((response) => {
            if (response.data.success) {
              this.removeQueueModalConfirm = false;
              this.closeConfirm();
              this.getDataFromApi();
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },

    sendPickingQueue(item) {
      this.orderDataFrom = item;
      this.orderLines = [];
      var priorityNumber = item.priorityNumber;
      this.getPickers();
      this.getBlockType();
      if (!priorityNumber) {
        this.priorityNumber = 0;
        this.isUrgent = item.isUrgent;
      } else {
        this.priorityNumber = priorityNumber;
        this.isUrgent = item.isUrgent;
      }
      this.formDataOrder = Object.assign({}, item);
      this.getOrderNo = item.orderNumber;
      this.getOrderId = item.orderId;

      var orderLines = [];
      var getPickingOrderLines = [];
      var getPickingLocations = [];
      this.isLoading = true;

      new Promise(async () => {
        const customerOrderRes = await axios.get(
          "CustomerOrders/" + this.warehouseAbbreviation + "/" + this.getOrderNo
        );
        if (customerOrderRes.data.success) {
          this.orderLinesData = customerOrderRes.data.data;
          orderLines = customerOrderRes.data.data.orderLines;
        } else {
          this.isLoading = false;
          failedToast(response.data.message);
        }

        console.log("CustomerOrders  1", orderLines);

        var pagination = { pageNumber: 1, pageSize: 500 };
        const getPickingOrderLineRes = await axios.post(
          "Picking/" +
            this.warehouseAbbreviation +
            "/" +
            this.getOrderNo +
            "/GetPickingOrderLines",
          pagination
        );

        if (getPickingOrderLineRes.data.success) {
          getPickingOrderLines = getPickingOrderLineRes.data.data.items;
          this.pickingOrder = getPickingOrderLines;
        } else {
          this.isLoading = false;
          failedToast(getPickingOrderLineRes.data.message);
        }

        console.log("CustomerOrders 2 ", getPickingOrderLines);

        const pickingLocationRes = await axios.get(
          "Picking/" +
            this.warehouseAbbreviation +
            "/" +
            this.getOrderNo +
            "/PickingLocations"
        );
        if (
          pickingLocationRes.data.success &&
          pickingLocationRes?.data?.data?.length > 0
        ) {
          getPickingLocations = pickingLocationRes.data.data;
          this.pickingConfirmModal = true;
        } else {
          this.isLoading = false;
          failedToast("No stock available to pick.");
          return;
        }
        console.log("CustomerOrders 3 ", pickingLocationRes);
        console.log("CustomerOrders 4 ", getPickingLocations);

        if (orderLines.length > 0) {
          var filterList = [];

          filterList = orderLines.filter((el) => {
            return Number(el.droppedQuantity) < Number(el.quantity);
          });

          if (filterList.length <= 0) {
            this.isLoading = false;
            this.removeQueueModalConfirm = true;
            return;
          }

          console.log("CustomerOrders 5 ", filterList);

          if (getPickingOrderLines.length > 0) {
            getPickingOrderLines = Object.values(
              getPickingOrderLines.reduce((acc, item) => {
                item.notPicked = item.notPicked || 0;
                acc[item.orderLineId] = acc[item.orderLineId]
                  ? {
                      orderLineId: item.orderLineId,
                      // notPicked:item.notPicked + acc[item.orderLineId].notPicked,
                      notPicked:
                        (item.notPicked || 0) +
                        (acc[item.orderLineId].notPicked || 0),
                    }
                  : item;
                return acc;
              }, {})
            );
            console.log("CustomerOrders 6 ", getPickingOrderLines);

            //console.log('reduce getPickingOrderLines',getPickingOrderLines);

            //   const groupedOrders = orderLines.reduce((result, orderLine) => {
            //   const { orderLineId, quantity } = orderLine;
            //   if (!result[orderLineId]) {
            //     result[orderLineId] = { orderLineId, totalQuantity: 0 };
            //   }
            //   result[orderLineId].totalQuantity += quantity;
            //   return result;
            // }, {});
            // const groupedOrdersArray = Object.values(groupedOrders);
            // console.log(groupedOrdersArray);

            // Filter order lines that don't exist in listB or have a different quantity

            console.log("debug_orderLine", orderLines);
            console.log("debug_getPickingOrderLines", getPickingOrderLines);
            const filteredOrderLines = orderLines.filter((ol) => {
              // Check if the order line does not exist in listB
              const notInListB = !getPickingOrderLines.some(
                (pol) => pol.orderLineId === ol.orderLineId
              );
              console.log("notInListB", notInListB);

              // Check if the quantity is different from the corresponding order line in listB
              const quantityDifferent = getPickingOrderLines.filter((pol) => {
                if (ol.orderLineId === pol.orderLineId) {
                  var queuedQuantity =
                    Number(ol.droppedQuantity) + Number(ol.notPicked);

                  var notQueuedQuantity = Number(pol.quantity) - queuedQuantity;
                  pol.notQueuedQuantity = notQueuedQuantity;
                  console.log("Quantities", queuedQuantity, notQueuedQuantity);
                  return notQueuedQuantity > 0;
                }
              });
              console.log("quantityDifferent", quantityDifferent);

              if (quantityDifferent.length > 0) {
                ol.notQueuedQuantity = quantityDifferent[0].notQueuedQuantity;
              } else {
                ol.notQueuedQuantity =
                  Number(ol.quantity) - Number(ol.droppedQuantity);
              }

              // Return true if the order line does not exist in listB or if the quantity is different
              return notInListB || quantityDifferent.length > 0;
            });

            if (filteredOrderLines.length <= 0) {
              this.isLoading = false;
              this.removeQueueModalConfirm = true;

              return;
            }

            console.log("CustomerOrders 7 ", filteredOrderLines);

            // filterList = orderLines.filter((ol) => {
            //   return getPickingOrderLines.some((pol) => {
            //     if (ol.orderLineId === pol.orderLineId) {
            //       var queuedQuantity = Number(ol.droppedQuantity) + Number(ol.notPicked);
            //       var notQueuedQuantity = Number(pol.quantity) - queuedQuantity;
            //       ol.notQueuedQuantity = notQueuedQuantity;
            //       return notQueuedQuantity > 0;
            //     }
            //   });
            // });
          } else {
            filterList = orderLines.map((el) => {
              let notQueuedQuantity =
                Number(el.quantity) - Number(el.droppedQuantity);
              el.notQueuedQuantity = notQueuedQuantity;
              return el;
            });

            console.log("CustomerOrders 8", filterList);
          }

          // if (getPickingOrderLines.length <= 0) {
          //   filterList = orderLines;
          // } else {
          //   filterList = orderLines.filter((ol) => {
          //     return getPickingOrderLines.some((pol) => {
          //       if (ol.orderLineId === pol.orderLineId) {
          //         return Number(ol.droppedQuantity) + Number(ol.notPicked) < Number(pol.quantity);
          //       }
          //     });
          //   });
          // }
          console.log("CustomerOrders *filterList", filterList);

          this.pickingLocations = getPickingLocations.filter((p1) => {
            return filterList.some((p2) => {
              // p1.picked = p2.picked;
              // p1.notPicked = Number(p2.quantity) - Number(p2.picked);
              return p1.stockDetails.skuId === p2.skuId;
            });
          });
          filterList.forEach((orderLine) => {
            console.log("filterList", orderLine);
            var availableQty;
            var blockType;
            var qtyStatus;
            var designatedQuantity;
            var notReserved;

            let pickingSku = this.pickingLocations.find(
              (pl) => pl.skuName === orderLine.skuName
            );

            if (pickingSku) {
              availableQty = pickingSku?.stockDetails.available;
              designatedQuantity = pickingSku?.stockDetails.designatedQuantity;
              //notReserved = pickingSku?.stockDetails.available;
              blockType = pickingSku?.locations[0].blockType;

              let reserved = Number(orderLine.reservedQuantity);
              let _notReserved =
                Number(orderLine.quantity) - Number(orderLine.reservedQuantity);

              let _quantity = Number(orderLine.notQueuedQuantity);

              //console.log('ss', this.getStockStatusId(_notReserved, availableQty));
              //qtyStatus = this.getStockStatusId(_notReserved, availableQty)

              if (_notReserved > 0) {
                if (availableQty < _notReserved) {
                  if (availableQty == 0 && reserved == 0) {
                    qtyStatus = 1; //out of stock
                  } else {
                    qtyStatus = 2; //partially out of stock
                  }
                } else {
                  if (reserved > 0) {
                    qtyStatus = 3; //partially reserved
                  } else {
                    qtyStatus = 5; //stock not reserved
                  }
                }
              } else {
                qtyStatus = 4; //available
              }

              // if (_notReserved > 0 && availableQty < _notReserved) {
              //   qtyStatus = 1;
              // } else if (_quantity <= designatedQuantity) {
              //   qtyStatus = 2;
              // } else if (_quantity > designatedQuantity) {
              //   qtyStatus = 3;
              // }

              // if (pickingSku.picked == 0) {
              //   _quantity = Number(orderLine.reservedQuantity);
              // } else {
              //   // _quantity = orderLine.reservedQuantity ? Number(orderLine.reservedQuantity) - (Number(pickingSku.notPicked) + Number(orderLine.droppedQuantity)) : 0;
              //   _quantity = Number(orderLine.notQueuedQuantity);
              // }

              if (_quantity) {
                var formData = {
                  skuName: orderLine.skuName,
                  quantity: _quantity,
                  notReservedQty: _notReserved,
                  blockType: blockType,
                  priority: 1,
                  reservedQty: orderLine.reservedQuantity
                    ? orderLine.reservedQuantity
                    : 0, //reserved qty
                  //designatedQuantity: orderLine.reservedQuantity ? orderLine.reservedQuantity : 0,
                  // userId: "",
                  droppedQuantity: orderLine.droppedQuantity,
                  available: availableQty ? availableQty : 0, //soh
                  qtyStatus: qtyStatus,
                };

                this.orderLines.push(formData);
                for (var o = 0; o < this.orderLines.length; o++) {
                  this.orderLines[o].isEdit = false;
                  // this.orderLines[o].blockType = "Ground Stock";
                  this.orderLines[o].userId = null;
                }
              }

              this.orderLines = this.prioritizeList(this.orderLines);

              this.isLoading = false;
            }
          });

          if (this.orderLines.length <= 0) {
            this.isLoading = false;
            this.removeQueueModalConfirm = false;
          }
        }
        console.log("CustomerOrders orderLines", this.orderLines);
        this.setLocalPagination();

        const _isStockLow = this.orderLines.some(
          (el) => el.notReservedQty > 0 && el.available > 0
        );
        if (_isStockLow) {
          this.isStockLow = true;
        }
      }).catch((error) => {
        console.log("sendPickingQueue error: ", error);
      });
    },

    getStockStatusId(notReserved, availableQty) {
      if (notReserved < 1) {
        return 4; //available
      }
      if (availableQty == 0) {
        return 1; //out of stock
      }
      if (availableQty < notReserved) {
        return 2; //partially out of stock
      }

      return 3; //partially reserved
    },
    prioritizeList(recommendationsList) {
      const prioritizedRecommendations = recommendationsList;
      const prioritizeSet = new Set([1]);

      const newArr = prioritizedRecommendations.sort(
        (a, b) =>
          prioritizeSet.has(b.qtyStatus) - prioritizeSet.has(a.qtyStatus)
      );
      return newArr;
    },
    // sendPickingQueue(item) {
    //   this.orderDataFrom = item;
    //   console.log('orderDataFrom',this.orderDataFrom);
    //   // if(item.despatchBookingNumber == null || item.despatchBookingNumber == ""){
    //   //   warningToast("Booking number is required, make sure booking number is available");
    //   // }else{
    //   this.orderLines = [];
    //   var priorityNumber = item.priorityNumber;
    //   this.getPickers();
    //   this.getBlockType();
    //   if (!priorityNumber) {
    //     this.priorityNumber = 0;
    //     this.isUrgent = item.isUrgent;
    //   } else {
    //     this.priorityNumber = priorityNumber;
    //     this.isUrgent = item.isUrgent;
    //   }
    //   this.formDataOrder = Object.assign({}, item);
    //   this.getOrderNo = item.orderNumber;
    //   this.getOrderId = item.orderId;
    //   var orderLines = [];
    //   this.isLoading = true;
    //   axios
    //     .get("CustomerOrders/" + this.warehouseAbbreviation + "/" + this.getOrderNo)
    //     .then((response) => {
    //       if (response.data.success) {
    //         this.orderLinesData = response.data.data;
    //         orderLines = response.data.data.orderLines;

    //         new Promise(async () => {
    //           var pagination = { pageNumber: 1, pageSize: 500 };
    //           const response2 = await axios.post("Picking/" + this.warehouseAbbreviation + "/" + this.getOrderNo + "/GetPickingOrderLines", pagination);

    //           if (response2.data.success) {
    //             var getPickingOrderLines = response2.data.data.items;
    //             this.pickingConfirmModal = true;

    //             console.log("orderLines, getPickingOrderLines", orderLines, getPickingOrderLines);

    //             // var notMatchList = orderLines.filter((o1) => {
    //             //   return !getPickingOrderLines.some((o2) => {
    //             //     return o1.orderLineId === o2.orderLineId;
    //             //   });
    //             // });

    //             // var matchList = orderLines.filter((o1) => {
    //             //   return getPickingOrderLines.some((o2) => {
    //             //     return o1.orderLineId === o2.orderLineId;
    //             //   });
    //             // });

    //             // var checkPickedList = matchList.filter((o1) => {
    //             //   return getPickingOrderLines.some((o2) => {
    //             //     if (o1.orderLineId === o2.orderLineId) {
    //             //       if (o1.quantity !== o2.picked) {
    //             //         return o1;
    //             //       }
    //             //     }
    //             //   });
    //             // });

    //             // let filterOrderLines = [...checkPickedList, ...notMatchList];

    //             let filterOrderLines = orderLines;

    //            // console.log("notMatchList, matchList, checkPickedList", notMatchList, matchList, checkPickedList);
    //             console.log("filterOrderLines", filterOrderLines);

    //           //

    //             var responsePickingLocations = await axios.get("Picking/" + this.warehouseAbbreviation + "/" + this.getOrderNo + "/PickingLocations");
    //             this.isLoading = false;
    //             if (responsePickingLocations.data.success) {
    //               //  this.pickingLocations = responsePickingLocations.data.data

    //               let list = []
    //               if(getPickingOrderLines.length === 0){
    //                 list = [...filterOrderLines]
    //               }
    //               else{
    //                 list =  [...getPickingOrderLines]
    //               }

    //               console.log('list',list);

    //               this.pickingLocations = responsePickingLocations.data.data.filter((p1) => {
    //                 return list.some((p2) => {
    //                   p1.picked = p2.picked;
    //                   p1.notPicked = Number(p2.quantity) - Number(p2.picked);
    //                   return p1.stockDetails.skuId === p2.skuId;
    //                 });
    //               });

    //               console.log('pickingLocations',this.pickingLocations);
    //               filterOrderLines.forEach((orderLine) => {

    //                 var availableQty;
    //                 var qtyBlockType;
    //                 var qtyStatus;
    //                 var designatedQuantity;

    //                 let pickingSku = this.pickingLocations.find((pl) => pl.skuName === orderLine.skuName);
    //                 console.log('pickingSku',pickingSku);
    //                 if (pickingSku) {
    //                   availableQty = pickingSku?.stockDetails.available;
    //                   //designatedQuantity = pickingSku?.stockDetails.designatedQuantity;
    //                   qtyBlockType = pickingSku?.locations[0].blockType;

    //                   if (availableQty === 0 || !availableQty) {
    //                     qtyStatus = 1;
    //                   } else if (orderLine.quantity <= availableQty) {
    //                     qtyStatus = 2;
    //                   } else if (orderLine.quantity > availableQty) {
    //                     qtyStatus = 3;
    //                   }

    //                   let _quantity = 0;
    //                    if(pickingSku.picked == 0){
    //                     _quantity = Number(orderLine.reservedQuantity)
    //                    }

    //                    _quantity = orderLine.reservedQuantity ? Number(orderLine.reservedQuantity) - (orderLine.droppedQuantity): 0
    //                    //pickingSku.notPicked

    //                   //let _quantity = orderLine.quantity ? Number(orderLine.quantity) - this.findDroppedQuantity(orderLines, orderLine.skuId) : 0

    //                     //let _quantity = Number(designatedQuantity) - (Number(orderLine.droppedQuantity) + Number(orderLine.notPicked));

    //                     //let _notResevedQty = orderLine.quantity ? Number(orderLine.quantity) -  Number(designatedQuantity) : 0

    //                     let _notResevedQty = orderLine.quantity ? Number(orderLine.quantity) -  orderLine.reservedQuantity ? Number(orderLine.reservedQuantity) : 0 : 0
    //                     // let _notResevedQty = orderLine.quantity ? Number(orderLine.quantity) -  Number(designatedQuantity) : 0
    //                     console.log('_quantity',_quantity);
    //                     if(_quantity){
    //                       var formData = {
    //                       skuName: orderLine.skuName,
    //                       quantity: _quantity,
    //                       notResevedQty: _notResevedQty,
    //                       blockType: qtyBlockType,
    //                       priority: 1,
    //                       // designatedQuantity: designatedQuantity ? designatedQuantity : 0,
    //                       designatedQuantity: orderLine.reservedQuantity ? orderLine.reservedQuantity : 0,
    //                       // userId: "",
    //                       available: availableQty,
    //                       qtyStatus: qtyStatus,
    //                     };
    //                     this.orderLines.push(formData);
    //                     for (var o = 0; o < this.orderLines.length; o++) {
    //                       this.orderLines[o].isEdit = false;
    //                       // this.orderLines[o].blockType = "Ground Stock";
    //                       this.orderLines[o].userId = "";
    //                     }
    //                     }

    //                   this.isLoading = false;
    //                 }
    //               });

    //               if (this.orderLines.length <= 0) {
    //
    //               }
    //             } else {
    //               failedToast(response.data.message);
    //               this.isLoading = false;
    //             }
    //             this.setLocalPagination();
    //           } else {
    //             this.isLoading = false;
    //             failedToast(response2.data.message);
    //           }

    //           let _isStocklow = this.orderLines.some((el)=> Number(el.quantity) - Number(el.designatedQuantity) !== 0);
    //           console.log('_isStocklow',_isStocklow);
    //           if(_isStocklow){
    //             this.isStockLow = true;
    //           }

    //           // for (var i = 0; i < filterOrderLines.length; i++) {
    //           //   this.isLoading = true;
    //           //   var response3 = await axios.get("MemoryStocks/" + this.warehouseAbbreviation + "/Products/" + filterOrderLines[i].skuName);

    //           //   var res = response3.data;
    //           //   var availableQty;
    //           //   var qtyBlockType;
    //           //   var qtyStatus;

    //           //   // if(res.length > 0){
    //           //   for (var y = 0; y < res.length; y++) {
    //           //     var totalAvailableStock = res.reduce((a, b) => ({
    //           //       availableStock: a.availableStock + b.availableStock,
    //           //     })).availableStock;
    //           //     availableQty = totalAvailableStock;
    //           //     qtyBlockType = res[y].blockType;

    //           //     // if ("Ground Stock" == qtyBlockType) {
    //           //     if (availableQty == 0) {
    //           //       qtyStatus = 1;
    //           //     } else if (filterOrderLines[i].quantity <= availableQty) {
    //           //       qtyStatus = 2;
    //           //     } else if (filterOrderLines[i].quantity > availableQty) {
    //           //       qtyStatus = 3;
    //           //     }
    //           //     // }
    //           //     // else {
    //           //     //   availableQty = 0;
    //           //     //   qtyStatus = "Out of stock";
    //           //     // }
    //           //   }

    //           //   // if (availableQty) {
    //           //   // var formData = {
    //           //   //   skuName: filterOrderLines[i].skuName,
    //           //   //   quantity: filterOrderLines[i].quantity,
    //           //   //   blockType: qtyBlockType,
    //           //   //   priority: 1,
    //           //   //   // userId: "",
    //           //   //   available: availableQty,
    //           //   //   qtyStatus: qtyStatus,
    //           //   // };
    //           //   // this.orderLines.push(formData);

    //           //   //}

    //           //   //               }
    //           //   //               else{
    //           //   // // if (availableQty) {
    //           //   //   var formData = {
    //           //   //                   skuName: filterOrderLines[i].skuName,
    //           //   //                   quantity: filterOrderLines[i].quantity,
    //           //   //                   blockType: "",
    //           //   //                   priority: 1,
    //           //   //                   // userId: "",
    //           //   //                   available: 0,
    //           //   //                   qtyStatus: "Out of stock",
    //           //   //                 };
    //           //   //                 this.orderLines.push(formData);

    //           //   //               //}
    //           //   //               }

    //           //   if (availableQty) {
    //           //     var formData = {
    //           //       skuName: filterOrderLines[i].skuName,
    //           //       quantity: filterOrderLines[i].quantity,
    //           //       blockType: qtyBlockType,
    //           //       priority: 1,
    //           //       // userId: "",
    //           //       available: availableQty,
    //           //       qtyStatus: qtyStatus,
    //           //     };
    //           //     this.orderLines.push(formData);
    //           //     for (var o = 0; o < this.orderLines.length; o++) {
    //           //       this.orderLines[o].isEdit = false;
    //           //       // this.orderLines[o].blockType = "Ground Stock";
    //           //       this.orderLines[o].userId = "";
    //           //     }
    //           //   }

    //           //   this.isLoading = false;
    //           // }

    //           // this.orderLines = arrayData;
    //           // });
    //         });
    //       }
    //       else {
    //         this.isLoading = false;
    //         failedToast(response.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       warningToast(statusMessage(error.response.status));
    //     });
    //   // }
    // },

    findDroppedQuantity(orderLines, skuId) {
      if (orderLines.length <= 0) {
        return 0;
      }
      let orderLine = orderLines.find((x) => x.skuId === skuId);
      if (!orderLine) {
        return 0;
      }
      return Number(orderLine.droppedQuantity);
      console.log("drop", orderLine.droppedQuantity);
    },
    //create dispatch
    addDespatch(item) {
      this.addDespatchModal = true;
      this.desTitleChange = -1;
      this.formDataDespatch.orderNumber = item.orderNumber;
      this.formDataDespatch.bookingNumber = "";
      this.getBookingNum();
    },

    editDespatch(item) {
      this.addDespatchModal = true;
      this.desTitleChange = 0;
      this.formDataDespatch.orderNumber = item.orderNumber;
      this.formDataDespatch.bookingNumber = item.despatchBookingNumber;
      this.getBookingNum();
    },
    clearBN() {
      this.bookingNumbs = [];
      this.bnPageNumber = 1;
      this.limitBN = 5;
      this.getBookingNum("");
    },
    searchBN(search) {
      clearInterval(this.bnSearchTimer);
      this.bnSearchTimer = setTimeout(() => {
        if (search) {
          this.bookingNumbs = [];
          this.bnPageNumber = 1;
          this.limitBN = this.totalBNCount;
          this.getBookingNum(search.trim());
        }
      }, 2000);
    },

    getBookingNum(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.bnPageNumber,
        pageSize: this.limitBN,
        search: search,
      };
      axios
        .post(
          "Despatches/" + this.warehouseAbbreviation + "/Paginated",
          pagination
        )
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isBNSearchResult = !!search;
            this.bookingNumbs =
              this.isBNSearchResult !== true
                ? Array.from(
                    new Set(
                      [...this.bookingNumbs, ...response.data.data.items].map(
                        JSON.stringify
                      )
                    )
                  ).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageBN = response.data.data.hasNextPage;
            this.totalBNCount = response.data.data.totalCount;
            if (this.bookingNumbs.length == 0) {
              warningToast("Please Add Despatches");
              // this.addDespatchModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    despatchLink() {
      this.addDespatchModal = false;
      router.push("/wms/dispatch");
    },
    saveDespatch() {
      this.isLoading = true;
      var formData = {
        warehouse: this.warehouseAbbreviation,
        orderNumber: this.formDataDespatch.orderNumber,
        bookingNumber: this.formDataDespatch.bookingNumber,
      };
      axios
        .post("Despatches/Orders/Add", formData)
        .then((response) => {
          console.log(response);
          successToast(response.data.message);
          this.addDespatchModal = false;
          this.getDataFromApi();
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    //create orderLine

    // getOrderLines() {
    //   this.isLoading = true;
    //   axios
    //     .get("CustomerOrders/" + this.orderNumber)
    //     .then((response) => {
    //       this.orderLines = response.data.data.orderLines;
    //       for (var i = 0; i < this.orderLines.length; i++) {
    //         this.orderLines[i].no = i + 1;
    //         this.isLoading = false;
    //       }
    //       var pageCount = this.orderLines.length / this.pageRowCountProduct;
    //       let pageCountRound = Math.round(pageCount);
    //       if (pageCount > pageCountRound) {
    //         pageCountRound += 1;
    //         this.pageCountProduct = pageCountRound;
    //       } else {
    //         this.pageCountProduct = pageCountRound;
    //       }
    //       //pageCountProduct
    //       this.orderLinesLocal = this.orderLines.slice(
    //         this.productStart,
    //         this.productEnd
    //       );
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //       this.isLoading = false;
    //     });
    // },
    //delete orderline
    deleteOrdeLine(item) {
      this.formDataOrdeLine = Object.assign({}, item);
      this.deleteOrderLineModel = true;

      // this.getOrderLines();
    },
    deleteOrderLineConfirm() {
      this.isLoading = true;
      console.log(this.formDataOrdeLine);
      var itemId = this.formDataOrdeLine.orderLineId;

      axios
        .delete("CustomerOrders/OrderLine/" + itemId)
        .then((response) => {
          console.log("response", response);

          this.deleteOrderLineModel = false;
          successToast(response.data.message);
          this.getOrderLines();
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //SKU
    getSkus() {
      this.isLoading = true;
      var orderNumber = this.formDataOrder.orderNumber;
      axios
        .get("CustomerOrders/" + this.warehouseAbbreviation + "/" + orderNumber)
        .then((response) => {
          this.skus = response.data.data.orderLines;
          // this.addProductModalForm = true;

          if (this.skus.length == 0) {
            warningToast("Please Add Items in SKU");
            this.addProductModal = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white";
    },
    getQtyStatusColor(value) {
      try {
        if (value === 2) {
          return "#006900";
        } else if (value === 1) {
          return "#878983";
        } else if (value === 3) {
          return "#a16703";
        }
        // else if (value == "Not assign") {
        //   return "#62778e";
        // }
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    setCardColor(value) {
      try {
        if (value === 2) {
          return "#A5D6A7";
        } else if (value === 1) {
          return "#ffb29f";
        } else if (value === 3) {
          return "#ffb58e";
        }
        // else if (value == "Not assign") {
        //   return "#62778e";
        // }
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    clearQueue() {
      this.isLoading = true;
      axios
        .get(`OrderPickingQueue/${this.warehouseAbbreviation}/NewOrders/Clear`)
        .then((response) => {
          successToast("Cleared successfully.");
          this.isLoading = false;
          this.getDataFromApi();
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearOrderFromQueue() {
      this.isLoading = true;
      axios
        .post(
          `OrderPickingQueue/${this.warehouseAbbreviation}/NewOrders/${this.currentQueueOrder}/Remove`
        )
        .then((response) => {
          successToast("Cleared successfully.");
          this.isLoading = false;
          this.getDataFromApi();
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
