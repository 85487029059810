<template>
  <div class="position-relative mb-3">
    <a
      :class="['text-body', showMenu ? 'show' : '']"
      style="display: flex; align-items: center; cursor: pointer"
      data-bs-toggle="dropdown"
      @click="showMenu = !showMenu"
    >
      <input
        ref="search"
        class="form-control"
        :placeholder="placeholder"
        v-model="search"
        @focusout="close()"
        :disabled="disabled"
      />
      <!-- <font-awesome-icon
          :icon="`fa fa-angle-${showMenu ? 'up' : 'down'}`"
          style="margin-left: -20px; font-size: 14px"
        /> -->
    </a>
    <ul
      class="w-100 p-1 dropdown-menu shadow-lg dropdown-scrollbar"
      :class="showMenu ? 'show' : ''"
      ref="scrollComponent"
    >
      <li
        :style="
          filteredItems && filteredItems.length === i + 1
            ? ''
            : 'border-bottom: 1px solid #eceef1'
        "
        v-for="(item, i) in filteredItems"
        :key="i"
        @click="select(item)"
      >
        <a
          class="dropdown-item border-radius-sm text-sm font-weight-normal p-3"
          style="cursor: pointer"
        >
          {{ item[itemText] }}
        </a>
      </li>
      <li class="text-center" v-if="loading">
        <!-- <m-spinner />  -->

        Loading...
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyAutocomplete",
  props: {
    modelValue: {},
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      default: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMenu: false,
    closeTimeout: null,
    search: "",
  }),
  computed: {
    filteredItems() {
      var defaultVal =
        this.itemText && this.itemValue
          ? [{ [this.itemValue]: "", [this.itemText]: "None" }]
          : [];

      if (this.items.find((x) => x[this.itemText] === this.search)) {
        return [...defaultVal, ...this.items];
      } else {
        return [
          ...defaultVal,
          ...this.items.filter(
            (x) =>
              x[this.itemText] &&
              x[this.itemText]
                .toLowerCase()
                .includes(this.search?.trim().toLowerCase())
          ),
        ];
      }
    },
  },
  watch: {
    showMenu(val) {
      if (val) {
        this.$refs.search.focus();
      }
    },
    items: {
      handler() {
        this.setVal(this.modelValue);
      },
      deep: true,
    },
    modelValue(val) {
      this.setVal(val);
    },
  },
  created() {
    this.setVal(this.modelValue);
  },
  mounted() {
    this.$refs.scrollComponent.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    this.$refs.scrollComponent.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setVal(val) {
      let result = this.items.find((x) => x[this.itemValue] === val);
      if (result) {
        this.search = result[this.itemText];
      }
    },
    select(item) {
      clearTimeout(this.closeTimeout);
      this.showMenu = false;
      this.search = item[this.itemText];
      this.$emit("update:modelValue", item[this.itemValue]);
      this.$emit("change", item[this.itemValue]);
    },
    close() {
      this.closeTimeout = setTimeout(() => {
        this.showMenu = false;
      }, 1000);
    },
    handleScroll() {
      let el = this.$refs.scrollComponent;
      if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
        this.$emit("loadMore");
      }
    },
  },
};
</script>
<style>
.dropdown-scrollbar {
  max-height: 275px;
  overflow-y: scroll;
}
.dropdown-scrollbar::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.dropdown-scrollbar::-webkit-scrollbar-track {
  background: #f8f9fa; /* color of the tracking area */
}

.dropdown-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9ba7bb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #fff; /* creates padding around scroll thumb */
}
</style>
