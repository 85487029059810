<template>
    <div>
        <div class="image-fit zoom-in" :style="`width: ${width}px; height: ${height}px`">
            <img :src="imageSrc" data-action="zoom" class="cursor-pointer border-white rounded-lg" />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import DefaultImage from '@/assets/images/product-image-placeholder.jpg'

const props = defineProps({
    src: { type: String, default: "" },
    defaultImage: { type: String, default: DefaultImage },
    width: { type: Number, default: 36 },
    height: { type: Number, default: 36 },
    alt: { type: String, default: "" }
})

const isLoaded = ref(false);
const imageSrc = computed(() => isLoaded.value ? props.src : props.defaultImage)

watch(() => props.src, () => {
    isLoaded.value = false;

    const img = new Image();

    img.onload = function () {
        isLoaded.value = true;
    };

    img.src = props.src;
}, { immediate: true })
</script>