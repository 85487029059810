<template>
  <a v-if="products > 0" class="flex items-center" href="javascript:;">
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      content="Products Added"
      :options="{
        zIndex: 99999,
      }"
    >
      <span class="absolute bg-white/50 text-green-900 font-semibold -mt-1">
        {{ products }}</span
      >
      <PackageIcon
        class="text-success w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
      />
    </Tippy>
  </a>
  <a v-else class="flex items-center" href="javascript:;">
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      content="Products Not Added"
      :options="{
        zIndex: 99999,
      }"
    >
      <PackageXIcon
        class="text-danger w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
      />
    </Tippy>
  </a>
</template>
<script>
export default {
  props: { item: {}, name: "", products: 0 },
  name: "DeleteItem",
  methods: {},
};
</script>
