<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="printAllPages"
          />
          <!-- <PrintLabels @parentMethod="printLabels($event)" /> -->
          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="whitespace-nowrap uppercase">
              <th class="whitespace-nowrap uppercase" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Exp Date</th>
              <th>Qty</th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
                <!-- <ArchiveItem
                  class="ml-3 -mt-1"
                  v-if="checkedData.length > 0"
                  @parentMethod="archiveMultipleItem($event)"
                  name="Archive Selected"
                /> -->
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <td>
                {{ item.expirationDate === null || item.expirationDate === undefined ? "" : momentLocal(item.expirationDate) }}
              </td>
              <td>
                {{ item.quantity != null || item.quantity != undefined ? item.quantity : "" }}
              </td>
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>
  <!-- BEGIN: Add  Modal -->

  <!-- END: Add Modal -->

  <!-- BEGIN: print  Modal -->

  <!-- <MoveableModal
    :show="addCheckedPrintModal"
    @hidden="addCheckedPrintModal = false"
    backdrop="static"
    title="Do you want to print the selected label(s) ?"
    @close="addCheckedPrintModal = false"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">WAN IP</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataPrint.ip"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="WAN IP"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Port</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataPrint.port"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="Port.."
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label"
            >Number of Copies</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataPrint.nbrOfCopies"
              type="number"
              autocomplete="off"
              class="form-control"
              placeholder="Number of copies.."
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label"
            >Selected Label(s)</label
          >

          <div
            id="labelsScroll"
            class="flex flex-col space-y-1 overflow-scroll h-36"
          >
            <table>
              <tr class="text-center">
                <th>No</th>
                <th>LOT</th>
                <th>Bin</th>
                <th>IN#</th>
                <th>ON#</th>
                <th>SKU</th>
                <th>SPN</th>
                <th>QTY</th>
              </tr>
              <tr
                class="text-center"
                v-for="(item, i) in formDataPrint.receival"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.lot }}</td>
                <td>{{ item.bin }}</td>

                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.orderNumber }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.supplierProductNumber }}</td>
                <td>{{ item.quantity }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="addCheckedPrintModal = false"
        class="btn btn-outline-secondary w-24 mr-1"
      >
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button
        @click="confirmPrint()"
        type="submit"
        class="btn btn-primary w-24"
      >
        Ok
      </button>
    </ModalFooter>
  </MoveableModal> -->
  <!-- END: print Modal -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import axiosReal from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";
import QrcodeVue from "qrcode.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { exportCSVFile } from "../../../global-functions/functions";
import moment from "moment";
import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Receival");
  },
  components: {
    Loading,

    QrcodeVue,
  },

  name: "AddRemove",

  data() {
    return {
      const_txt: const_txt,
      warehouseList: [],
      isLoading: false,

      selectedItemsData: [],

      headers: [
        { text: "LOT", value: "lot" },
        // { text: "W House", value: "warehouse" },
        { text: "Section", value: "storageSection" },
        { text: "Type", value: "storageType" },
        { text: "Bin", value: "bin" },
        { text: "Inv No", value: "invoiceNumber" },
        { text: "Purch Ord No", value: "orderNumber" },
        { text: "PLU/SKU", value: "sku" },
        { text: "Supp Prod No", value: "supplierProductNumber" },
        { text: "Barcode", value: "barcode" },
        { text: "Batch#", value: "batchNumber" },
        // { text: "QTY", value: "quantity" },
      ],
      headersFilter: [
        { text: "LOT", value: "lot" },
        { text: "W House", value: "warehouse" },
        { text: "Stor Section", value: "storageSection" },
        { text: "Stor Type", value: "storageType" },
        { text: "Bin", value: "bin" },
        { text: "Inv No", value: "invoiceNumber" },
        { text: "Purch Ord No", value: "orderNumber" },
        { text: "PLU/SKU", value: "sku" },
        { text: "Supp Prod No", value: "supplierProductNumber" },
        { text: "Barcode", value: "barcode" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 20,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],

      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      splitData: false,
      allSelected: false,
      checkedData: [],
      addCheckedPrintModal: false,
      // formDataPrint: {
      //   receival: [],
      //   ip: "192.0.0.0",
      //   port: "8100",
      //   nbrOfCopies: 1,
      // },

      policyAccess: "Receival",
    };
  },
  computed: {},
  created() {
    this.getDataFromApi();
    // this.getDefaultIP();
    // this.getWarehouseData();
  },
  methods: {
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Warehouse", dataKey: "warehouse" },
              { title: "LOT", dataKey: "lot" },
              { title: "Storage Type", dataKey: "storageType" },
              { title: "Storage Section", dataKey: "storageSection" },
              { title: "Bin", dataKey: "bin" },
              { title: "Invoice No", dataKey: "invoiceNumber" },
              { title: "Purchase Order No", dataKey: "orderNumber" },
              { title: "Quantity", dataKey: "quantity" },
              { title: "PLU/SKU", dataKey: "sku" },
              { title: "Expiration Date", dataKey: "expirationDate" },
              { title: "Barcode", dataKey: "barcode" },
              { title: "Batch#", dataKey: "batchNumber" },
              {
                title: "Supplier Product No",
                dataKey: "supplierProductNumber",
              },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                warehouse: el.warehouse,
                lot: el.lot,
                storageType: el.storageType,
                storageSection: el.storageSection,
                bin: el.bin,
                invoiceNumber: el.invoiceNumber,
                orderNumber: el.orderNumber,
                quantity: el.quantity,
                sku: el.sku,
                expirationDate: this.momentLocal(el.expirationDate),
                barcode: el.barcode,
                supplierProductNumber: el.supplierProductNumber,
                batchNumber: el.batchNumber,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post("Movements/Pagination/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    // confirmPrint() {
    //   this.isLoading = true;
    //   axios
    //     .post("Sku/PrintSkus ", this.formDataPrint)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         successToast(response.data.message);
    //         this.isLoading = false;
    //       } else {
    //         failedToast(response.data.message);
    //         this.isLoading = false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       warningToast(statusMessage(error.response.status));
    //     });
    //   this.addCheckedPrintModal = false;
    //   this.checkedData = [];
    // },

    // printLabels() {
    //   this.formDataPrint.receival = [];
    //   if (this.checkedData.length > 0) {
    //     this.addCheckedPrintModal = true;
    //     for (var i = 0; i < this.checkedData.length; i++) {
    //       this.formDataPrint.receival[i] = this.checkedData[i];
    //     }
    //   } else {
    //     warningToast("Please Select Receival");
    //   }
    // },

    // getDefaultIP() {
    //   axiosReal.get("https://api.ipify.org/").then((response) => {
    //     this.formDataPrint.ip = response.data;
    //   });
    // },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Receival " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Receival " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Warehouse",
          2: "LOT",
          3: "Storage Type",
          4: "Storage Section",
          5: "Bin",
          6: "Invoice No",
          7: "Quantity",
          8: "PLU/SKU",
          9: "Barcode",
          10: "Supplier Product No",
          11: "Purchase Order No",
          12: "Expiration Date",
          13: "Batch#",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.warehouse,
            2: item.lot,
            3: item.storageType,
            4: item.storageSection,
            5: item.bin,
            6: item.invoiceNumber,
            7: item.quantity,
            8: item.sku,
            9: item.barcode,
            10: item.supplierProductNumber,
            11: item.orderNumber,
            12: this.momentLocalCSV(item.expirationDate),
            13: item.batchNumber,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Movements/Pagination/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    // getWarehouseData() {
    //   var pagination = { pageNumber: 1, pageSize: 10 };
    //   axios
    //     .post("Warehouses/Paginated", pagination)
    //     .then((response) => {
    //       this.warehouseList = response.data.data.items;

    //       if (this.warehouseList.length == 0) {
    //         warningToast("Please Add Items in Warehouse");
    //         this.addMovement = false;
    //       }
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Movements/Receival/Pagination/" + this.warehouseAbbreviation, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            var originalItems = response.data.data.items;
            this.items = originalItems.filter((x) => x.invoiceNumber != null);
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
