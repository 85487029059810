const policyNamesC = [
  
  "Users",
  "User Permissions",
  "Locations",
  "Units",
  "PLU/SKU",
  "Departments",
  "Suppliers",
  "Supplier Invoices",
  "Purchase Orders",
  "Reorder Stocks",
  "Receival",
  "Replenish",
  "Lots",
  "Customers",
  "Customer Orders",
  "Cutsomer Invoices",
  "Queue Orders",
  "Picking Orders",
  "Dispatch",
  "Lpns",
  "Dropped Orders",
  "Supplier Invoice Reports",
  "Customer Sales Reports",
  "Revenue Reports",
  "Product Sales Reports",
  "Product Sku Location Reports",
  "Product Section Location Reports",
];
const policyNames = [
  {name: "Users", cName:"Users"},
]
const policyIdentifier = function (value) { 
  var icon = "ListIcon";
  switch (value) {
    case "Users":
      icon = "userIcon";
      return icon;
    case "User Permissions":
      icon = "keyIcon";
      return icon;
    default:
      return icon;
  }
};

export { policyNames, policyIdentifier, policyNamesC };
