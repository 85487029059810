<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Product Returns" />
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
            @printAllPages="printAllPages"
          />
          <!-- <PrintLabels @parentMethod="printLabels($event)" /> -->
          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="whitespace-nowrap uppercase">
              <th class="whitespace-nowrap uppercase" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>RTN Date</th>
              <th>Exp Date</th>
              <th>State</th>
              <th>Reason</th>
              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
                <!-- <ArchiveItem
                  class="ml-3 -mt-1"
                  v-if="checkedData.length > 0"
                  @parentMethod="archiveMultipleItem($event)"
                  name="Archive Selected"
                /> -->
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in items" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <td>
                {{ item.returnedDate === null || item.returnedDate === undefined ? "" : momentLocal(item.returnedDate) }}
              </td>
              <td>
                {{ item.expirationDate === null || item.expirationDate === undefined ? "" : momentLocal(item.expirationDate) }}
              </td>
              <td>
                {{ item.state != null ? item.state : "" }}
              </td>
              <td>
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.reason">
                    {{ item.reason }}
                  </Tippy>
                </div>
              </td>
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>
  <!-- BEGIN: Add  Modal -->

  <!-- END: Add Modal -->

  <!-- BEGIN: print  Modal -->

  <!-- <MoveableModal
    :show="addCheckedPrintModal"
    @hidden="addCheckedPrintModal = false"
    backdrop="static"
    title="Do you want to print the selected label(s) ?"
    @close="addCheckedPrintModal = false"
  >
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">WAN IP</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataPrint.ip"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="WAN IP"
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Port</label>

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataPrint.port"
              autocomplete="off"
              type="text"
              class="form-control"
              placeholder="Port.."
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label"
            >Number of Copies</label
          >

          <div class="flex flex-col space-y-1">
            <input
              id="vertical-form-1"
              v-model="formDataPrint.nbrOfCopies"
              type="number"
              autocomplete="off"
              class="form-control"
              placeholder="Number of copies.."
            />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label"
            >Selected Label(s)</label
          >

          <div
            id="labelsScroll"
            class="flex flex-col space-y-1 overflow-scroll h-36"
          >
            <table>
              <tr class="text-center">
                <th>No</th>
                <th>LOT</th>
                <th>Bin</th>
                <th>IN#</th>
                <th>ON#</th>
                <th>SKU</th>
                <th>SPN</th>
                <th>QTY</th>
              </tr>
              <tr
                class="text-center"
                v-for="(item, i) in formDataPrint.receival"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.lot }}</td>
                <td>{{ item.bin }}</td>

                <td>{{ item.invoiceNumber }}</td>
                <td>{{ item.orderNumber }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.supplierProductNumber }}</td>
                <td>{{ item.quantity }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="addCheckedPrintModal = false"
        class="btn btn-outline-secondary w-24 mr-1"
      >
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button
        @click="confirmPrint()"
        type="submit"
        class="btn btn-primary w-24"
      >
        Ok
      </button>
    </ModalFooter>
  </MoveableModal> -->
  <!-- END: print Modal -->

  <MoveableModal :show="addReturn" @hidden="addReturn = false" backdrop="static" size="modal-xl" :title="formTitle" @close="closeReturn">
    <ModalBody class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
      <div class="">
        <label for="regular-form-1" class="form-label font-semibold"> PLU/SKU </label>

        <div class="flex flex-col w-full space-y-1">
          <v-select
            v-model="formDataReturn.sku"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenSku"
            @close="onCloseSku"
            @search="searchSKU"
            :options="skus"
            :reduce="(skuName) => skuName.skuId"
            label="skuName"
            :get-option-label="(option) => option.skuName"
          >
            <template #list-footer>
              <li v-show="hasNextPageSku" ref="loadSku" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">BARCODE/EAN</label>

        <div class="flex flex-col space-y-1">
          <input
            :style="validateEan(formDataReturn.ean) == true && formDataReturn.ean != '' ? 'border-color: red' : 'border-color: none'"
            id="vertical-form-1"
            v-model="formDataReturn.ean"
            type="text"
            class="form-control"
            name="lastName"
            placeholder="BARCODE/EAN"
            autocomplete="off"
          />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">Quantity</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.quantity" type="number" class="form-control" name="company" placeholder="Quantity" autocomplete="off" />
        </div>
      </div>

      <div class="w-full">
        <label for="regular-form-1" class="form-label">Returned Date</label>

        <PreviewComponent class="intro-y">
          <div class="">
            <Preview>
              <Litepicker
                v-model="formDataReturn.returnedDate"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  dropdowns: {
                    // format: 'YYYY-MM-DD\T00:00:00',
                    minYear: 1990,
                    maxYear: 2050,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control"
              />
            </Preview>
          </div>
        </PreviewComponent>
      </div>

      <div class="w-full">
        <label for="regular-form-1" class="form-label">Expiration Date</label>

        <PreviewComponent class="intro-y">
          <div class="">
            <Preview>
              <Litepicker
                v-model="formDataReturn.expirationDate"
                :options="{
                  autoApply: true,
                  showWeekNumbers: true,
                  dropdowns: {
                    // format: 'YYYY-MM-DD\T00:00:00',
                    minYear: 1990,
                    maxYear: 2050,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control"
              />
            </Preview>
          </div>
        </PreviewComponent>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">Invoice Number</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.invoiceNumber" type="text" class="form-control" name="lastName" placeholder="Invoice Number" autocomplete="off" />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">Order Number</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.orderNumber" type="text" class="form-control" name="lastName" placeholder="Order Number" autocomplete="off" />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">Booking Number</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.bookingNumber" type="text" class="form-control" placeholder="Booking Number" autocomplete="off" />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">State</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.state" type="number" class="form-control" placeholder="State" autocomplete="off" />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">Reason</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.reason" type="text" class="form-control" placeholder="Reason" autocomplete="off" />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">LOT</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.lot" type="text" class="form-control" placeholder="LOT" autocomplete="off" />
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label">Warehouse</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataReturn.warehouse" type="text" class="form-control" placeholder="Warehouse" autocomplete="off" disabled />
        </div>
      </div>
      <div class="">
        <label for="regular-form-1" class="form-label font-semibold"> Storage Type </label>

        <div class="flex flex-col w-full space-y-1">
          <v-select
            v-model="formDataReturn.storageType"
            placeholder="SELECT"
            class="style-chooser"
            @open="onOpenST"
            @close="onCloseST"
            @search="searchST"
            :filterable="false"
            :options="storageTypes"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :reduce="(abbreviation) => abbreviation.abbreviation"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageST" ref="loadST" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label font-semibold"> Storage Section </label>

        <div class="flex flex-col w-full space-y-1">
          <v-select
            v-model="formDataReturn.storageSection"
            placeholder="SELECT"
            class="style-chooser"
            :options="storageSectionList"
            :reduce="(abbreviation) => abbreviation.abbreviation"
            label="abbreviation"
            @open="onOpenSS"
            @close="onCloseSS"
            @search="searchSS"
            :filterable="false"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageSS" ref="loadSS" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>
      </div>

      <div class="">
        <label for="regular-form-1" class="form-label font-semibold"> Storage Bin </label>

        <div class="flex flex-col w-full space-y-1">
          <v-select
            v-model="formDataReturn.storageBin"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenSB"
            @close="onCloseSB"
            @search="searchSB"
            :options="storageBins"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :reduce="(abbreviation) => abbreviation.abbreviation"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageSB" ref="loadSB" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeReturn()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import axiosReal from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";
import QrcodeVue from "qrcode.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { exportCSVFile } from "../../../global-functions/functions";
import moment from "moment";
import store from "../../../store";
import validationMixins from "../../../mixins/validation";
export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Returns");
    this.observerSB = new IntersectionObserver(this.infiniteScrollSB);
    this.observerST = new IntersectionObserver(this.infiniteScrollST);
    this.observerSS = new IntersectionObserver(this.infiniteScrollSS);
    this.observerSku = new IntersectionObserver(this.infiniteScrollSku);
  },
  components: {
    Loading,

    QrcodeVue,
  },

  name: "AddRemove",

  data() {
    return {
      const_txt: const_txt,
      warehouseList: [],
      isLoading: false,
      addReturn: false,
      selectedItemsData: [],
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      formDataReturn: {
        returnedDate: "",
        sku: "",
        ean: "",
        quantity: 0,
        expirationDate: "",
        invoiceNumber: "",
        orderNumber: "",
        bookingNumber: "",
        state: 0,
        reason: "",
        lot: "",
        warehouse: store.state.authUser.warehouse.abbreviation,
        storageType: "",
        storageSection: "",
        storageBin: "",
      },
      formDataReturnDefault: {
        returnedDate: "",
        sku: undefined,
        ean: "",
        quantity: 0,
        expirationDate: "",
        invoiceNumber: "",
        orderNumber: "",
        bookingNumber: "",
        state: 0,
        reason: "",
        lot: "",
        warehouse: store.state.authUser.warehouse.abbreviation,
        storageType: undefined,
        storageSection: undefined,
        storageBin: undefined,
      },
      skus: [],
      headers: [
        { text: "Invoice#", value: "invoiceNumber" },
        { text: "Order#", value: "orderNumber" },
        { text: "Booking#", value: "bookingNumber" },
        { text: "PLU/SKU", value: "sku" },
        { text: "BARCODE/EAN", value: "ean" },
        { text: "QTY", value: "quantity" },
        { text: "Storage Type", value: "storageType" },
        { text: "Storage Section", value: "storageSection" },
        { text: "Storage Bin", value: "storageBin" },
      ],
      headersFilter: [
        { text: "LOT", value: "lot" },
        { text: "W House", value: "warehouse" },
        { text: "Stor Section", value: "storageSection" },
        { text: "Stor Type", value: "storageType" },
        { text: "Bin", value: "bin" },
        { text: "Inv No", value: "invoiceNumber" },
        { text: "Purch Ord No", value: "orderNumber" },
        { text: "PLU/SKU", value: "sku" },
        { text: "Supp Prod No", value: "supplierProductNumber" },
        { text: "Barcode", value: "barcode" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 20,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],

      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseAbbreviationId: store.state.authUser.warehouse.warehouseId,
      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      splitData: false,
      allSelected: false,
      checkedData: [],
      addCheckedPrintModal: false,
      // formDataPrint: {
      //   receival: [],
      //   ip: "192.0.0.0",
      //   port: "8100",
      //   nbrOfCopies: 1,
      // },
      // policyAccess: "ProductReturns",
      policyAccess: "ProductReturns",

      titleChange: -1,
      storageTypes: [],
      storageBins: [],
      storageSections: [],
      storageSectionList: [],

      observerSku: null,
      limitSku: 5,
      hasNextPageSku: false,
      totalSkuCount: 0,
      isSkuSearchResult: false,
      skuPageNumber: 1,
      skuSearchTimer: undefined,

      observerSB: null,
      limitSB: 5,
      hasNextPageSB: false,
      totalSBCount: 0,
      isSBSearchResult: false,
      sbPageNumber: 1,
      sbSearchTimer: undefined,

      observerST: null,
      limitST: 5,
      hasNextPageST: false,
      totalSTCount: 0,
      isSTSearchResult: false,
      stPageNumber: 1,
      stSearchTimer: undefined,

      observerSS: null,
      limitSS: 5,
      hasNextPageSS: false,
      totalSSCount: 0,
      isSSSearchResult: false,
      ssPageNumber: 1,
      ssSearchTimer: undefined,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Product Returns" : "Edit Product Returns";
    },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
    formDataReturngetStorageType() {
      return this.formDataReturn.storageType;
    },
    checkLimtSku() {
      return this.skuPageNumber;
    },
    viewModalSku() {
      return this.formDataReturn.sku;
    },
    checkLimtSS() {
      return this.ssPageNumber;
    },
    viewModalSS() {
      return this.formDataReturn.storageSection;
    },
    checkLimtSB() {
      return this.sbPageNumber;
    },
    viewModalSB() {
      return this.formDataReturn.storageBin;
    },
    checkLimtST() {
      return this.stPageNumber;
    },
    viewModalST() {
      return this.formDataReturn.storageType;
    },
  },
  watch: {
    viewModalSku() {
      if (this.formDataReturn.sku === null) {
        this.clearSku();
      }
    },
    viewModalSB() {
      if (this.formDataReturn.storageBin === null) {
        this.clearSB();
      }
    },
    viewModalST() {
      if (this.formDataReturn.storageType === null) {
        this.clearST();
      }
    },
    viewModalSS() {
      if (this.formDataReturn.storageSection === null) {
        this.clearSS();
      }
    },
    formDataReturngetStorageType() {
      this.setStorageSection();
    },
    checkLimtSku() {
      this.getSkus();
    },
    checkLimtSS() {
      this.getStorageSections();
    },
    checkLimtSB() {
      this.getStorageBins();
    },
    checkLimtST() {
      this.getStorageTypes();
    },
  },
  created() {
    this.getDataFromApi();
    // this.getDefaultIP();
    // this.getWarehouseData();
  },
  methods: {
    async onOpenSku() {
      if (this.isSkuSearchResult) {
        this.skuPageNumber = 1;
      }
      if (this.hasNextPageSku) {
        await this.$nextTick();
        this.observerSku.observe(this.$refs.loadSku);
      }
    },
    onCloseSku() {
      this.observerSku.disconnect();
    },
    async infiniteScrollSku([{ isIntersecting, target }]) {
      console.log("isIntersectingSku " + isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.skuPageNumber++;
        this.limitSku;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenST() {
      if (this.isSTSearchResult) {
        this.stPageNumber = 1;
      }
      if (this.hasNextPageST) {
        await this.$nextTick();
        this.observerST.observe(this.$refs.loadST);
      }
    },
    onCloseST() {
      this.observerST.disconnect();
    },
    async infiniteScrollST([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.stPageNumber++;
        this.limitST;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSS() {
      if (this.isSSSearchResult) {
        this.ssPageNumber = 1;
      }
      if (this.hasNextPageSS) {
        await this.$nextTick();
        this.observerSS.observe(this.$refs.loadSS);
      }
    },
    onCloseSS() {
      this.observerSS.disconnect();
    },
    async infiniteScrollSS([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.ssPageNumber++;
        this.limitSS;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSB() {
      if (this.isSBSearchResult) {
        this.sbPageNumber = 1;
      }
      if (this.hasNextPageSB) {
        await this.$nextTick();
        this.observerSB.observe(this.$refs.loadSB);
      }
    },
    onCloseSB() {
      this.observerSB.disconnect();
    },
    async infiniteScrollSB([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.sbPageNumber++;
        this.limitSB;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    setStorageSection() {
      try {
        var value = this.formDataReturn.storageType;
        this.storageSectionList = this.storageSections.filter(
          (x) => x.warehouseId == this.warehouseAbbreviationId && x.storageTypeId == this.storageTypes.find((y) => y.abbreviation == value).storageTypeId
        );
      } catch {
        this.storageSectionList = [];
      }
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.getSkus();
      this.getStorageTypes();
      this.getStorageBins();
      this.getStorageSections();
      this.addReturn = true;
    },
    connectTimetoDate() {
      var currentTime = moment(new Date()).format();
      var splitTime = currentTime.split("T");

      var returnedDate = moment(this.formDataReturn.returnedDate).format("YYYY-MM-DD");
      returnedDate = returnedDate.concat("T").concat(splitTime[1]);
      this.formDataReturn.returnedDate = returnedDate;

      var expirationDate = moment(this.formDataReturn.expirationDate).format("YYYY-MM-DD");
      expirationDate = expirationDate.concat("T").concat(splitTime[1]);
      this.formDataReturn.expirationDate = expirationDate;
    },

    clearSku() {
      this.skus = [];
      this.skuPageNumber = 1;
      this.limitSku = 5;
      this.getSkus("");
    },
    searchSKU(search) {
      clearInterval(this.skuSearchTimer);
      this.skuSearchTimer = setTimeout(() => {
        if (search) {
          this.skus = [];
          this.skuPageNumber = 1;
          this.limitSku = this.totalSkuCount;
          this.getSkus(search.trim());
        }
      }, 2000);
    },
    getSkus(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.skuPageNumber,
        pageSize: this.limitSku,
        search: search,
      };
      axios
        .post("MemorySkus/" + this.warehouseAbbreviation + "/Stocks", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSkuSearchResult = !!search;
            this.skus =
              this.isSkuSearchResult !== true ? Array.from(new Set([...this.skus, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse) : response.data.data.items;

            this.hasNextPageSku = response.data.data.hasNextPage;
            this.totalSkuCount = response.data.data.totalCount;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in PLU/SKU");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearST() {
      this.storageTypes = [];
      this.stPageNumber = 1;
      this.limitST = 5;
      this.getStorageTypes("");
    },
    searchST(search) {
      clearInterval(this.stSearchTimer);
      this.stSearchTimer = setTimeout(() => {
        if (search) {
          this.storageTypes = [];
          this.stPageNumber = 1;
          this.limitST = this.totalSTCount;
          this.getStorageTypes(search.trim());
        }
      }, 2000);
    },
    getStorageTypes(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.stPageNumber,
        pageSize: this.limitST,
        search: search,
      };
      axios
        .post("StorageTypes/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSTSearchResult = !!search;
            this.storageTypes =
              this.isSTSearchResult !== true
                ? Array.from(new Set([...this.storageTypes, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageST = response.data.data.hasNextPage;
            this.totalSTCount = response.data.data.totalCount;

            if (this.storageTypes.length == 0) {
              warningToast("Please Add Items in Storage Type");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearSB() {
      this.storageBins = [];
      this.sbPageNumber = 1;
      this.limitSB = 5;
      this.getStorageBins("");
    },
    searchSB(search) {
      clearInterval(this.sbSearchTimer);
      this.sbSearchTimer = setTimeout(() => {
        if (search) {
          this.storageBins = [];
          this.sbPageNumber = 1;
          this.limitSB = this.totalSBCount;
          this.getStorageBins(search.trim());
        }
      }, 2000);
    },
    getStorageBins(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.sbPageNumber,
        pageSize: this.limitSB,
        search: search,
      };
      axios
        .post("StorageBins/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSBSearchResult = !!search;
            this.storageBins =
              this.isSBSearchResult !== true
                ? Array.from(new Set([...this.storageBins, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;

            this.hasNextPageSB = response.data.data.hasNextPage;
            this.totalSBCount = response.data.data.totalCount;

            if (this.storageBins.length == 0) {
              warningToast("Please Add Items in Storage Bin");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearSS() {
      this.storageSections = [];
      this.ssPageNumber = 1;
      this.limitSS = 5;
      this.getStorageSections("");
    },
    searchSB(search) {
      clearInterval(this.ssSearchTimer);
      this.ssSearchTimer = setTimeout(() => {
        if (search) {
          this.storageSections = [];
          this.ssPageNumber = 1;
          this.limitSS = this.totalSSCount;
          this.getStorageSections(search.trim());
        }
      }, 2000);
    },
    getStorageSections(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.ssPageNumber,
        pageSize: this.limitSS,
        search: search,
      };
      axios
        .post("StorageSections/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            this.isSSSearchResult = !!search;
            this.storageSections = this.isSSSearchResult !== true ? [...this.storageSections, ...response.data.data.items] : response.data.data.items;

            this.totalSSCount = response.data.data.totalCount;
            // this.addProductModalForm = true;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in Storage Section");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    closeReturn() {
      this.addReturn = false;
      this.setDefault();
    },
    setDefault() {
      this.formDataReturn = Object.assign({}, this.formDataReturnDefault);

      var today = moment.utc().local(new Date()).format("DD MMM, YYYY");
      this.formDataReturn.returnedDate = today;
      this.formDataReturn.expirationDate = "";
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Invoice#", dataKey: "invoiceNumber" },
              { title: "Order#", dataKey: "orderNumber" },
              { title: "Booking#", dataKey: "bookingNumber" },
              { title: "PLU/SKU", dataKey: "sku" },
              { title: "BARCODE/EAN", dataKey: "ean" },
              { title: "QTY", dataKey: "quantity" },
              { title: "Storage Type", dataKey: "storageType" },
              { title: "Storage Section", dataKey: "storageSection" },
              { title: "Storage Bin", dataKey: "storageBin" },
              { title: "RTN Date", dataKey: "returnedDate" },
              { title: "EXP Date", dataKey: "expirationDate" },
              { title: "State", dataKey: "state" },
              { title: "Reason", dataKey: "reason" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                invoiceNumber: el.invoiceNumber,
                orderNumber: el.orderNumber,
                bookingNumber: el.bookingNumber,
                sku: el.sku,
                ean: el.ean,
                quantity: el.quantity,
                storageType: el.storageType,
                storageSection: el.storageSection,
                storageBin: el.storageBin,
                returnedDate: this.momentLocal(el.returnedDate),
                expirationDate: this.momentLocal(el.expirationDate),
                state: el.state,
                reason: el.reason,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post("ProductReturns/" + this.warehouseAbbreviationId + "/paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    // confirmPrint() {
    //   this.isLoading = true;
    //   axios
    //     .post("Sku/PrintSkus ", this.formDataPrint)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         successToast(response.data.message);
    //         this.isLoading = false;
    //       } else {
    //         failedToast(response.data.message);
    //         this.isLoading = false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       warningToast(statusMessage(error.response.status));
    //     });
    //   this.addCheckedPrintModal = false;
    //   this.checkedData = [];
    // },

    // printLabels() {
    //   this.formDataPrint.receival = [];
    //   if (this.checkedData.length > 0) {
    //     this.addCheckedPrintModal = true;
    //     for (var i = 0; i < this.checkedData.length; i++) {
    //       this.formDataPrint.receival[i] = this.checkedData[i];
    //     }
    //   } else {
    //     warningToast("Please Select Receival");
    //   }
    // },

    // getDefaultIP() {
    //   axiosReal.get("https://api.ipify.org/").then((response) => {
    //     this.formDataPrint.ip = response.data;
    //   });
    // },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Receival " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Receival " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Invoice#",
          2: "Order#",
          3: "Booking#",
          4: "PLU/SKU",
          5: "BARCODE/EAN",
          6: "QTY",
          7: "Storage Type",
          8: "Storage Section",
          9: "Storage Bin",
          10: "RTN Date",
          11: "EXP Date",
          12: "State",
          13: "Reason",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.invoiceNumber,
            2: item.orderNumber,
            3: item.bookingNumber,
            4: item.sku,
            5: item.ean,
            6: item.quantity,
            7: item.storageType,
            8: item.storageSection,
            9: item.storageBin,
            10: item.returnedDate,
            11: this.momentLocalCSV(item.expirationDate),
            12: this.momentLocalCSV(item.state),
            13: item.reason,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("ProductReturns/" + this.warehouseAbbreviationId + "/paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.isLoading = false;
        });
    },

    // getWarehouseData() {
    //   var pagination = { pageNumber: 1, pageSize: 10 };
    //   axios
    //     .post("Warehouses/Paginated", pagination)
    //     .then((response) => {
    //       this.warehouseList = response.data.data.items;

    //       if (this.warehouseList.length == 0) {
    //         warningToast("Please Add Items in Warehouse");
    //         this.addMovement = false;
    //       }
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },

    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("ProductReturns/" + this.warehouseAbbreviationId + "/paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            var originalItems = response.data.data.items;
            this.items = originalItems.filter((x) => x.invoiceNumber != null);
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    saveForm() {
      if (
        this.formDataReturn.sku == "" ||
        this.formDataReturn.ean == "" ||
        this.formDataReturn.quantity == "" ||
        this.formDataReturn.invoiceNumber == "" ||
        this.formDataReturn.warehouse == "" ||
        this.formDataReturn.storageType == "" ||
        this.formDataReturn.storageSection == "" ||
        this.formDataReturn.storageBin == ""
      ) {
        if (this.formDataReturn.sku == "") {
          warningToast("PLU/SKU Required");
        }
        if (this.formDataReturn.ean == "") {
          warningToast("BARCODE/EAN Required");
        }
        if (this.formDataReturn.quantity == "") {
          warningToast("Quantity Required");
        }
        if (this.formDataReturn.invoiceNumber == "") {
          warningToast("Invoice Number Required");
        }
        if (this.formDataReturn.warehouse == "") {
          warningToast("Warehouse Required");
        }
        if (this.formDataReturn.storageType == "") {
          warningToast("Storage Type Required");
        }
        if (this.formDataReturn.storageSection == "") {
          warningToast("Storage Section Required");
        }
        if (this.formDataReturn.storageBin == "") {
          warningToast("Storage Bin Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          this.connectTimetoDate();

          axios
            .post("ProductReturns/", this.formDataReturn)
            .then((response) => {
              if (response.data.success === true) {
                this.addInvoice = false;
                this.getDataFromApi();
                successToast(response.data.message);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          this.isLoading = true;
          this.connectTimetoDate();
          var itemId = this.formDataReturn.invoiceId;

          axios
            .put("ProductReturns/" + itemId, this.formDataReturn)
            .then((response) => {
              if (response.data.success === true) {
                this.addInvoice = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
  },
};
</script>
