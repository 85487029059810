import { defineStore } from "pinia";
import { getCurrentInstance } from "vue";
import store from "../store";
export const useSideMenuStore = defineStore("sideMenu", {
  state: () => ({
    menu: [
      // {
      //   icon: "HomeIcon",
      //   pageName: "StorageBins",
      //   title: "Locations",
      //   viewPermission: store.getters["authUser/getPermission"](
      //     "Permissions.Navigations.Locations"
      //   ),
      // },

      {
        icon: "HomeIcon",
        pageName: "warehouse-management",
        title: "Locations",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Locations"
        ),
        subMenu: [
          // {
          //   icon: "FileBadgeIcon",
          //   pageName: "Licensee",
          //   title: "Licensee",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Licensees.View"
          //   ),
          // },
          {
            icon: "BuildingIcon",
            pageName: "Warehouses",
            title: "Warehouses",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Warehouses.View"
            ),
          },
          {
            icon: "ClipboardIcon",
            pageName: "StorageTypes",
            title: "Storage Types",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.StorageTypes.View"
            ),
          },
          {
            icon: "ClipboardListIcon",
            pageName: "StorageSections",
            title: "Storage Sections",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.StorageSections.View"
            ),
          },
          {
            icon: "BoxIcon",
            pageName: "StorageBins",
            title: "Storage Bins",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Locations.View"
            ),
          },
          {
            icon: "LayoutListIcon",
            pageName: "BlockType",
            title: "BlockType",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Batches.View"
            ),
          },
          {
            icon: "Scale3dIcon",
            pageName: "Locations",
            title: "Storage Bins 3D",
            statusColor: "rgba(0, 0, 255, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Locations.View"
            ),
          },

          // {
          //   icon: "IndentIcon",
          //   pageName: "PalletSizes",
          //   title: "Pallet Sizes",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Navigations.Locations"
          //   ),
          // },
          // {
          //   icon: "AlignEndVerticalIcon",
          //   pageName: "PalletTypes",
          //   title: "Pallet Types",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Navigations.Locations"
          //   ),
          // },
          // {
          //   icon: "Dice6Icon",
          //   pageName: "ContainerSizes",
          //   title: "Container Sizes",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Navigations.Locations"
          //   ),
          // },
          // {
          //   icon: "CopyIcon",
          //   pageName: "ContainerTypes",
          //   title: "Container Types",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Navigations.Locations"
          //   ),
          // },
        ],
      },

      {
        icon: "TrelloIcon",
        pageName: "products-management",
        title: "Products",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Products"
        ),
        subMenu: [
          {
            icon: "PackageIcon",
            pageName: "Products",
            title: "Products",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Products.View"
            ),
          },
          // {
          //   icon: "LayoutListIcon",
          //   pageName: "Category",
          //   title: "Categories",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Users.View"
          //   ),
          // },
          // {
          //   icon: "AwardIcon",
          //   pageName: "Brand",
          //   title: "Brands",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.Users.View"
          //   ),
          // },
          {
            icon: "RulerIcon",
            pageName: "Units",
            title: "Units",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Units.View"
            ),
          },

          {
            icon: "NetworkIcon",
            pageName: "Departments",
            title: "Departments",
            statusColor: "rgba(0, 0, 255, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Departments.View"
            ),
          },
          {
            icon: "QrCodeIcon",
            pageName: "Skus",
            title: "PLU/SKU",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.SKUs.View"
            ),
          },
          {
            icon: "TrelloIcon",
            pageName: "Batches",
            title: "Batches",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Batches.View"
            ),
          },
          {
            icon: "CodesandboxIcon",
            pageName: "Attributes",
            title: "Attributes",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Attributes.View"
            ),
          },

          // {
          //   icon: "GitPullRequestIcon",
          //   pageName: "VariantType",
          //   title: "Variant Types",
          //   statusColor: "rgba(0, 0, 0, 0.5)", status: true,
          // },

          // "devider",
        ],
      },
      {
        icon: "PackageIcon",
        pageName: "stock-management",
        title: "Purchases",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Purchases"
        ),
        subMenu: [
          // "devider",

          {
            icon: "UsersIcon",
            pageName: "Suppliers",
            title: "Suppliers",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Suppliers.View"
            ),
          },
          {
            icon: "CalculatorIcon",
            pageName: "SupplierInvoices",
            title: "Supplier Invoices",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.SupplierInvoices.View"
            ),
          },
          {
            icon: "LayersIcon",
            pageName: "PurchaseOrders",
            title: "Purchase Orders",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.PurchaseOrders.View"
            ),
          },
          {
            icon: "PackagePlusIcon",
            pageName: "ReorderStocks",
            title: "Reorder Stocks",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ReorderStocks.View"
            ),
          },

          // {
          //   icon: "PackageCheckIcon",
          //   pageName: "CheckStocks",
          //   title: "Check Stocks",
          //   statusColor: "rgba(0, 0, 255, 0.5)",  status: false,
          // },
          // {
          //   icon: "SortAscIcon",
          //   pageName: "SortingOrders",
          //   title: "Sorting Orders",
          //   statusColor: "rgba(0, 0, 255, 0.5)",  status: false,
          // },
        ],
      },
      {
        icon: "TruckIcon",
        pageName: "inbound",
        title: "Inbound",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Inbound"
        ),
        subMenu: [
          {
            icon: "ClipboardCopyIcon",
            pageName: "Receival",
            title: "Receival",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Receival.View"
            ),
          },
          {
            icon: "RotateCwIcon",
            pageName: "Replenish",
            title: "Replenish",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Replenish.View"
            ),
          },
          {
            icon: "LayoutTemplateIcon",
            pageName: "Lots",
            title: "NEW LOTS",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.LOTS.View"
            ),
          },
          {
            icon: "CornerDownLeftIcon",
            pageName: "Return",
            title: "Returns",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductReturns.View"
            ),
          },
          {
            icon: "Rotate3dIcon",
            pageName: "CycleCounts",
            title: "Cycle Counts",
            statusColor: "rgba(0, 0, 255, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.CycleCounts.View"
            ),
          },
          {
            icon: "PackagePlusIcon",
            pageName: "AddStocks",
            title: "Add Stocks",
            statusColor: "rgba(0, 0, 255, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.LOTS.View"
            ),
          },

          {
            icon: "ClipboardSignatureIcon",
            pageName: "Stocktakes",
            title: "Stocktakes",
            statusColor: "rgba(0, 0, 255, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Stocktake.View"
            ),
          },
        ],
      },
      {
        icon: "ClipboardListIcon",
        pageName: "sale-management",
        title: "Sales",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Sales"
        ),
        subMenu: [
          {
            icon: "UserIcon",
            pageName: "Customers",
            title: "Customers",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Customers.View"
            ),
          },
          {
            icon: "ScalingIcon",
            pageName: "CustomerQuotations",
            title: "Customer Quotations",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission:
              !store.getters["authUser/roleCheck"](["BranchAdmin"]) &&
              store.getters["authUser/getPermission"](
                "Permissions.CustomerOrders.View"
              ),
          },
          {
            icon: "DollarSignIcon",
            pageName: "CustomerOrders",
            title: "Orders",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.CustomerOrders.View"
            ),
          },
          {
            icon: "PackageOpenIcon",
            pageName: "OutOfStockOrderLines",
            title: "OOS Order Lines",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission:
              !store.getters["authUser/roleCheck"](["BranchAdmin"]) &&
              store.getters["authUser/getPermission"](
                "Permissions.CustomerOrders.View"
              ),
          },
          {
            icon: "LibraryIcon",
            pageName: "QueueOrders",
            title: "Queue Orders",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.QueueOrders.View"
            ),
          },
          {
            icon: "ClipboardCheckIcon",
            pageName: "PickingOrders",
            title: "Picking Orders",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.PickingOrders.View"
            ),
          },
          {
            icon: "SheetIcon",
            pageName: "CustomerInvoices",
            title: "Customer Invoices",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.CustomerInvoices.View"
            ),
          },

          // {
          //   icon: "RefreshCcwIcon",
          //   pageName: "PreparePicking",
          //   title: "Picking Prepare",
          //   statusColor: "rgba(0, 0, 255, 0.5)",status: false,
          // },
        ],
      },

      {
        icon: "BusIcon",
        pageName: "outbound",
        title: "Outbound",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Outbound"
        ),
        subMenu: [
          {
            icon: "PackageCheckIcon",
            pageName: "Dispatch",
            title: "Dispatch",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Dispatch.View"
            ),
          },
          {
            icon: "ScanIcon",
            pageName: "Lpns",
            title: "NEW LPNS",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.LPNS.View"
            ),
          },
          {
            icon: "VerifiedIcon",
            pageName: "DroppedOrders",
            title: "Dropped Orders",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.DroppedOrders.View"
            ),
          },
        ],
      },

      {
        icon: "FileIcon",
        pageName: "reports",
        title: "Reports",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Reports"
        ),
        subMenu: [
          {
            icon: "ClipboardListIcon",
            pageName: "OrderReports",
            title: "Order Reports",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSalesReports.View"
            ),
          },
          {
            icon: "TrendingUpIcon",
            pageName: "RevenueReports",
            title: "Revenue ",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.RevenueReports.View"
            ),
          },

          {
            icon: "FileOutputIcon",
            pageName: "ProductSalesReports",
            title: "Product Sales",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSalesReports.View"
            ),
          },
          {
            icon: "ScanLineIcon",
            pageName: "ProductSkuReports",
            title: "Stock Location",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSKULocationsReports.View"
            ),
          },
          {
            icon: "GitForkIcon",
            pageName: "ProductSectionReports",
            title: "Section Stock",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSectionLocationsReports.View"
            ),
          },

          {
            icon: "FilePlusIcon",
            pageName: "ProductPurchase",
            title: "Product Purchase",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSectionLocationsReports.View"
            ),
          },

          {
            icon: "PackageIcon",
            pageName: "EmptyBins",
            title: "Empty Bins",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSectionLocationsReports.View"
            ),
          },
          {
            icon: "ClipboardIcon",
            pageName: "StockSummary",
            title: "Stock Summary",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSectionLocationsReports.View"
            ),
          },
          // {
          //   icon: "ServerIcon",
          //   pageName: "StockTakeVariance",
          //   title: "Stock Take Variance",
          //   statusColor: "rgba(0, 0, 0, 0.5)",
          //   status: true,
          //   viewPermission: store.getters["authUser/getPermission"](
          //     "Permissions.ProductSectionLocationsReports.View"
          //   ),
          // },
          {
            icon: "CreditCardIcon",
            pageName: "ProductExpiration",
            title: "Product Expiration",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.ProductSectionLocationsReports.View"
            ),
          },
        ],
      },
      {
        icon: "UsersIcon",
        pageName: "users",
        title: "Users",
        viewPermission: store.getters["authUser/getPermission"](
          "Permissions.Navigations.Users"
        ),
        subMenu: [
          {
            icon: "UserIcon",
            pageName: "Users",
            title: "Users",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Users.View"
            ),
          },
          {
            icon: "UserIcon",
            pageName: "Branches",
            title: "Branches",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Branches.View"
            ),
          },
          {
            icon: "UserIcon",
            pageName: "Pickers",
            title: "Pickers",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.Pickers.View"
            ),
          },

          {
            icon: "KeyIcon",
            pageName: "UserPermissions",
            title: "User Permission",
            statusColor: "rgba(0, 0, 0, 0.5)",
            status: true,
            viewPermission: store.getters["authUser/getPermission"](
              "Permissions.UserPermissions.View"
            ),
          },
        ],
      },

      // {
      //   icon: "GithubIcon",
      //   pageName: "test",
      //   title: "Test",
      //   subMenu: [
      //     {
      //       icon: "MoreHorizontalIcon",
      //       pageName: "test-3",
      //       title: "Test",
      //       statusColor: "rgba(0, 255, 0, 0.5)",
      //     },
      //   ],
      // },

      // {
      //   icon: "BoxIcon",
      //   pageName: "side-menu-menu-layout",
      //   title: "Warehouse Management",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-warehouses",
      //       title: "Ware Houses",
      //       ignore: true,
      //     },
      //     {
      //       icon: "",
      //       pageName: "top-menu-dashboard-overview-1",
      //       title: "Storage Types",
      //       ignore: true,
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-dashboard-overview-1",
      //       title: "Storage Sections",
      //       ignore: true,
      //     },
      //     {
      //       icon: "",
      //       pageName: "simple-menu-dashboard-overview-1",
      //       title: "Storage Bins",
      //       ignore: true,
      //     },
      //     // {
      //     //   icon: "",
      //     //   pageName: "simple-menu-dashboard-overview-1",
      //     //   title: "Storage Bins",
      //     //   ignore: true,
      //     // },

      //   ],
      // },

      // {
      //   icon: "CalendarIcon",
      //   pageName: "side-menu-calendar",
      //   title: "Calendar",
      // },

      // "devider",
      // {
      //   icon: "EditIcon",
      //   pageName: "side-menu-crud",
      //   title: "Crud",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-crud-data-list",
      //       title: "Data List",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-crud-form",
      //       title: "Form",
      //     },
      //   ],
      // },
      // {
      //   icon: "UsersIcon",
      //   pageName: "side-menu-users",
      //   title: "Users",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-users-layout-1",
      //       title: "Layout 1",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-users-layout-2",
      //       title: "Layout 2",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-users-layout-3",
      //       title: "Layout 3",
      //     },
      //   ],
      // },
      // {
      //   icon: "TrelloIcon",
      //   pageName: "side-menu-profile",
      //   title: "Profile",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-profile-overview-1",
      //       title: "Overview 1",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-profile-overview-2",
      //       title: "Overview 2",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-profile-overview-3",
      //       title: "Overview 3",
      //     },
      //   ],
      // },
      // {
      //   icon: "LayoutIcon",
      //   pageName: "side-menu-layout",
      //   title: "Pages",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-wizards",
      //       title: "Wizards",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-wizard-layout-1",
      //           title: "Layout 1",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-wizard-layout-2",
      //           title: "Layout 2",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-wizard-layout-3",
      //           title: "Layout 3",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-blog",
      //       title: "Blog",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-blog-layout-1",
      //           title: "Layout 1",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-blog-layout-2",
      //           title: "Layout 2",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-blog-layout-3",
      //           title: "Layout 3",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-pricing",
      //       title: "Pricing",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-pricing-layout-1",
      //           title: "Layout 1",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-pricing-layout-2",
      //           title: "Layout 2",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-invoice",
      //       title: "Invoice",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-invoice-layout-1",
      //           title: "Layout 1",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-invoice-layout-2",
      //           title: "Layout 2",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-faq",
      //       title: "FAQ",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-faq-layout-1",
      //           title: "Layout 1",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-faq-layout-2",
      //           title: "Layout 2",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-faq-layout-3",
      //           title: "Layout 3",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "login",
      //       title: "Login",
      //     },
      //     {
      //       icon: "",
      //       pageName: "register",
      //       title: "Register",
      //     },
      //     {
      //       icon: "",
      //       pageName: "error-page",
      //       title: "Error Page",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-update-profile",
      //       title: "Update profile",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-change-password",
      //       title: "Change Password",
      //     },
      //   ],
      // },
      // "devider",
      // {
      //   icon: "InboxIcon",
      //   pageName: "side-menu-components",
      //   title: "Components",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-table",
      //       title: "Table",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-regular-table",
      //           title: "Regular Table",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-tabulator",
      //           title: "Tabulator",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-overlay",
      //       title: "Overlay",
      //       subMenu: [
      //         {
      //           icon: "",
      //           pageName: "side-menu-modal",
      //           title: "Modal",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-slide-over",
      //           title: "Slide Over",
      //         },
      //         {
      //           icon: "",
      //           pageName: "side-menu-notification",
      //           title: "Notification",
      //         },
      //       ],
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-tab",
      //       title: "Tab",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-accordion",
      //       title: "Accordion",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-button",
      //       title: "Button",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-alert",
      //       title: "Alert",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-progress-bar",
      //       title: "Progress Bar",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-tooltip",
      //       title: "Tooltip",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-dropdown",
      //       title: "Dropdown",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-typography",
      //       title: "Typography",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-icon",
      //       title: "Icon",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-loading-icon",
      //       title: "Loading Icon",
      //     },
      //   ],
      // },
      // {
      //   icon: "SidebarIcon",
      //   pageName: "side-menu-forms",
      //   title: "Forms",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-regular-form",
      //       title: "Regular Form",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-datepicker",
      //       title: "Datepicker",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-tom-select",
      //       title: "Tom Select",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-file-upload",
      //       title: "File Upload",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-wysiwyg-editor",
      //       title: "Wysiwyg Editor",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-validation",
      //       title: "Validation",
      //     },
      //   ],
      // },
      // {
      //   icon: "HardDriveIcon",
      //   pageName: "side-menu-widgets",
      //   title: "Widgets",
      //   subMenu: [
      //     {
      //       icon: "",
      //       pageName: "side-menu-chart",
      //       title: "Chart",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-slider",
      //       title: "Slider",
      //     },
      //     {
      //       icon: "",
      //       pageName: "side-menu-image-zoom",
      //       title: "Image Zoom",
      //     },
      //   ],
      // },
    ],
  }),
});
