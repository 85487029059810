<template>
  <Tippy
    v-if="activeAccess"
    tag="a"
    href="javascript:;"
    class="tooltip"
    content="Add New Item"
    :options="{
      zIndex: 99999,
    }"
  >
    <a href="javascript:;" @click="buttonClick()" class="mr-2 btn btn-primary shadow-md text-xs hover:text-yellow-200 mt-1 mb-1">
      {{ buttonName }}
      <span class="ml-2">
        <PlusIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
      </span>
    </a>
  </Tippy>
  <a v-else href="javascript:;" class="mr-2 btn btn-primary shadow-md text-xs mt-1 mb-1 cursor-not-allowed">
    {{ buttonName }}
    <span class="ml-2">
      <PlusIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
    </span>
  </a>
</template>
<script>
export default {
  props: { buttonName: "", activeAccess: false },
  name: "AddItem",
  methods: {
    buttonClick() {
      this.$emit("parentMethod");
    },
  },
};
</script>
