<template>
  <a
    v-if="_activeAccess"
    @click="buttonClick()"
    class="flex items-center text-blue-900"
    href="javascript:;"
  >
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      :content="name"
      :options="{
        zIndex: 99999,
      }"
      ><component :is="_icon" :class='`${_color} w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1`' />
    </Tippy>
  </a>
  <a v-else class="flex items-center text-blue-900" href="javascript:;">
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip cursor-not-allowed"
      content="Not Allowed"
      :options="{
        zIndex: 99999,
      }"
      >
      <component :is="_icon" :class='`${_color} w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1`' />
    </Tippy>
  </a>
</template>
<script>
export default {
  props: { item: {}, name: "", activeAccess: false, icon: "" , color : "" },
  name: "ActionButton",
  computed:{
    _item:{
      get(){
        return this.item
      },
    
    },
    _icon : {
      get(){
        return this.icon
      },
    },
    _activeAccess: {
      get(){
        return this.activeAccess
      },
    },
    _color: {
      get(){
        return this.color
      },
    }
  },
  methods: {
    buttonClick() {
      this.$emit("parentMethod", this._item);
    },
  },
};
</script>
