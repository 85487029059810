<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="flex flex-col space-y-4 items-end">
    <div class="w-full grid grid-cols-2 gap-2 py-8 px-4">
      <div>
        <Autocomplete ref="aslocation" v-model:modelValue="formDataAddStock.bin" label="Location"
          itemText="abbreviation" itemValue="abbreviation" @load="loadLocation" refKey="aslocation" />
      </div>

      <div>
        <Autocomplete ref="assection" v-model:modelValue="formDataAddStock.section" label="Section"
          itemText="abbreviation" itemValue="abbreviation" @load="loadSection" refKey="assection" />
      </div>

      <div class="col-span-2">
        <Autocomplete ref="assku" v-model:modelValue="formDataAddStock.sku" label="PLU/SKU" itemText="skuName"
          itemValue="skuName" :resultObj="false" :header="skuHeader" :infoEnable="true" @load="loadSku"
          :updatedItem="updateSku" refKey="assku" :focusSearch="false" :isClicked="isShowSku" />
      </div>

      <div class="flex flex-col w-full space-y-1">
        <label for="regular-form-1" class="form-label">Manufacture Date</label>
        <PreviewComponent class="intro-y">
          <div class="">
            <Preview>
              <Litepicker v-model="formDataAddStock.productionDate" :options="{
                autoApply: true,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: 2050,
                  months: true,
                  years: true,
                },
              }" class="form-control" />
            </Preview>
          </div>
        </PreviewComponent>
      </div>

      <div class="flex flex-col w-full space-y-1">
        <label for="regular-form-1" class="form-label">Expiry Date</label>
        <PreviewComponent class="intro-y">
          <div class="">
            <Preview>
              <Litepicker v-model="formDataAddStock.expiryDate" :options="{
                autoApply: true,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: 2050,
                  months: true,
                  years: true,
                },
              }" class="form-control" />
            </Preview>
          </div>
        </PreviewComponent>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Batch Number</label>
        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataAddStock.batchNumber" type="text" class="form-control"
            placeholder="Batch Number" autocomplete="off" />
        </div>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Quantity</label>
        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" ref="focusQty" v-model="formDataAddStock.quantity" type="number"
            class="form-control" placeholder="Quantity" autocomplete="off" @keypress.enter="save()" />
        </div>
      </div>
    </div>

    <div class="flex space-x-4">
      <button type="button" @click="clear()" class="w-24 mr-1 btn btn-outline-secondary">Clear</button>
      <button @click="save()" type="submit" class="w-24 btn btn-primary">Add Stock</button>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import dom from "@left4code/tw-starter/dist/js/dom";
import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import router from "../../../router";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";
import { exportCSVFile } from "../../../global-functions/functions";
import moment from "moment";
import store from "../../../store";

// import { permissionHelper as $h } from "@/utils/permissionHelper";

export default {
  async mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Add Stocks");

    if (this.querySku) {
      // this.isShowSku = true;
      // this.$nextTick(() => (this.isShowSku = false));
      await this.findSkuById(this.querySku)
      this.formDataAddStock.sku = this.querySku;

      console.log('querySku', this.querySku, this.formDataAddStock, this.updateSku);

    }

  },
  components: {},
  data() {
    return {
      storeUserRole: store.state.authUser.userRole,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      const_txt: const_txt,
      isLoading: false,
      titleChange: -1,
      formDataAddStock: {
        quantity: "",
        warehouse: store.state.authUser.warehouse.abbreviation,
        section: "Dflt",
        bin: "Dflt",
        sku: "",
        ean: "",
        expiryDate: moment(new Date()).format("DD MMM, YYYY"),
        productionDate: moment(new Date()).format("DD MMM, YYYY"),
        cost: 0,
        sellingPrice: 0,
        lot: null,
        batchNumber: null,
      },
      formDataAddStockDefault: {
        quantity: "",
        warehouse: store.state.authUser.warehouse.abbreviation,
        section: "Dflt",
        bin: "Dflt",
        sku: "",
        ean: "",
        expiryDate: moment(new Date()).format("DD MMM, YYYY"),
        productionDate: moment(new Date()).format("DD MMM, YYYY"),
        cost: 0,
        sellingPrice: 0,
        lot: null,
        batchNumber: null,
      },

      policyAccess: "Units",

      locations: [],
      sections: [],
      skus: [],
      skuHeader: [
        { text: "DESC", value: "description" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "BARCODE/EAN", value: "ean" },
        { text: "PRICE", value: "price" },
        { text: "DEPT", value: "department" },
        { text: "VAT%", value: "vatPercentage" },
        { text: "Reserved", value: "designatedQuantity" },
        // { text: "Unreserved", value: "available" },
        { text: "SOH", value: "available" },
      ],
      newLot: "",
      isShowSku: false,
      updateSku: null,
      renderComponent: true
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add Stock" : "Edit Stock";
    },
    changeSku() {
      return this.formDataAddStock.sku
    },
    querySku() {
      return this.$route.query.sku;
    },
  },
  watch: {
    changeSku(val) {
      if (val) {
        this.formDataAddStock.quantity = ""
        this.$nextTick(() => this.$refs.focusQty.focus());
      }

    }
  },
  created() { },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    clear() {
      this.isShowSku = true;
      this.$nextTick(() => (this.isShowSku = false));
      this.formDataAddStock = Object.assign({}, this.formDataAddStockDefault);
      this.updateSku = null;
      //this.forceRerender();
    },
    async save() {

      if (!this.formDataAddStock.bin || !this.formDataAddStock.section || !this.formDataAddStock.sku || !this.formDataAddStock.quantity) {
        if (!this.formDataAddStock.bin) {
          warningToast("Location is Required");
        }
        if (!this.formDataAddStock.section) {
          warningToast("Section is Required");
        }
        if (!this.formDataAddStock.sku) {
          warningToast("PLU/SKU is Required");
        }
        if (!this.formDataAddStock.quantity) {
          warningToast("Quantity is Required");
        }
      }
      else {
        this.isLoading = true;

        if (!this.formDataAddStock.expiryDate) {
          this.formDataAddStock.expiryDate = null;
        }
        this.formDataAddStock.expiryDate = moment.utc(this.formDataAddStock.expiryDate).format();

        if (!this.formDataAddStock.productionDate) {
          this.formDataAddStock.productionDate = null;
        }
        this.formDataAddStock.productionDate = moment.utc(this.formDataAddStock.productionDate).format();

        if (!this.formDataAddStock.batchNumber) {
          this.formDataAddStock.batchNumber = null;
        }

        let sku = this.formDataAddStock.sku;

        // let sku = this.formDataAddStock.sku.skuName;
        // let ean = this.formDataAddStock.sku.ean;

        // if (!sku) {
        //   this.formDataAddStock.ean = ean;
        //   this.formDataAddStock.sku = null;
        //   await this.getNewLotNumber(ean);
        // }
        this.formDataAddStock.sku = sku;
        this.formDataAddStock.ean = null;
        await this.getNewLotNumber(sku);


        if (!this.newLot) {
          this.formDataAddStock.lot = null;
        }
        this.formDataAddStock.lot = this.newLot;

        this.formDataAddStock.quantity = parseInt(this.formDataAddStock.quantity);


        if (this.formDataAddStock.lot) {
          axios
            .post("Stocks/AddStock", this.formDataAddStock)
            .then((response) => {
              if (response.data.success === true) {
                successToast(response.data.message);

                let findSku = this.skus.find((x) => x.skuId === response.data.data.skuId);
                if (findSku) {
                  findSku.quantity = findSku.quantity + this.formDataAddStock.quantity;
                }

                this.clear();
                this.$router.go();
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              this.clear();
              warningToast(statusMessage(error.response.status));
            });
        } else {
          failedToast("LOT not Valid, try again");
        }

      }
    },
    async getNewLotNumber(value) {
      this.isLoading = true;
      var query = value;
      await axios
        .get("Movements/" + this.warehouseAbbreviation + "/Lots/LastCreated?query=" + query)
        .then((response) => {
          if (response.data.success) {
            let getData = response.data.data;
            if (getData) {
              var names = getData.trim().split("-");
              if (names.length == 2) {
                try {
                  var number = names[1];
                  var suffix = number ? parseInt(number) : 0;
                  this.newLot = names[0] + "-" + (suffix + 1);
                } catch (e) {
                  this.newLot = "";
                }
              } else {
                this.newLot = names[0] + "-1";
              }
            } else {
              this.newLot = query;
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    async findSkuById(sku) {
      this.isLoading = true;
      await axios
        .get("Sku/" + this.warehouseStoreId + "/" + sku + "/GetBySkuName")
        .then((response) => {
          if (response.data.success === true) {
            this.updateSku = response.data?.data;
            console.log('this.updateSku', this.updateSku);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    loadLocation(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("StorageBins/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            let list = [...JSON.parse(JSON.stringify(this.sections)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.sections = list;
            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    loadSection(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("StorageSections/" + this.warehouseAbbreviation + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            let list = [...JSON.parse(JSON.stringify(this.locations)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.locations = list;
            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    loadSku(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("MemorySkus/" + this.warehouseAbbreviation + "/Stocks", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            let list = [...JSON.parse(JSON.stringify(this.skus)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.skus = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },



};
</script>
