<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem @parentMethod="showModal($event)" buttonName="Add New Category" />
        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport" :page="pagination.pageNumber" />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />

        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>Visible</th>
            <th>DESC</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>

            <td>
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.visible" class="form-check-input" disabled />
              </div>
            </td>

            <td class="w-24">
              <div class="text-left line-clamp-2">
                <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description">
                  {{ item.description }}
                </Tippy>
              </div>
            </td>

            <td class="table-report__action w-56">
              <div class="flex justify-center items-center">
                <a @click="editItem(item)" class="flex items-center mr-3" href="javascript:;">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Edit">
                    <EditIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                  </Tippy>
                </a>
                <a class="flex items-center text-danger" href="javascript:;" @click="archiveItem(item)">
                  <Tippy tag="a" href="javascript:;" class="tooltip" content="Archive">
                    <ArchiveIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                  </Tippy>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />
    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSize" />
    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <Modal :show="addCategory" @hidden="addCategory = false" backdrop="static">
    <ModalHeader>
      <h2 class="font-medium text-base mr-auto">{{ formTitle }}</h2>
    </ModalHeader>
    <ModalBody class="flex justify-center space-y-4">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">Category Name</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataCategory.name" type="text" class="form-control" name="name" placeholder="Category Name" autocomplete="off" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Category Code</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataCategory.code" type="text" class="form-control" name="code" placeholder="Category Code" autocomplete="off" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Category Description</label>

          <div class="flex flex-col space-y-1">
            <textarea
              id="vertical-form-1"
              v-model="formDataCategory.description"
              type="text"
              class="form-control"
              name="description"
              placeholder="Type here about Category..."
            ></textarea>
          </div>
        </div>
        <div class="mt-3">
          <label>Active Status</label>
          <div class="form-switch mt-2">
            <input type="checkbox" v-model="formDataCategory.visible" class="form-check-input" />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">Done</button>
    </ModalFooter>
  </Modal>
  <!-- END: Add Modal -->

  <!-- BEGIN: Archive Modal -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="text-danger float-right lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6 cursor-pointer" @click="archiveModal = false" />
      </div>

      <div class="p-5 text-center lg:text-lg text-base uppercase font-semibold">Archived Categories</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            clearable
            autocomplete="off"
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a @click="restoreItem(item)" class="flex items-center mr-3 text-blue-900" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Restore"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <RotateCcwIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                    </Tippy>
                  </a>

                  <a class="flex items-center text-danger" href="javascript:;" @click="deleteItem(item)">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Delete"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <Trash2Icon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                    </Tippy>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSize" />
      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataCategory.name" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataCategory.name" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataCategory.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";

export default {
  components: {
    Loading,
    paginate: Paginate,
  },
  data() {
    return {
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addCategory: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataCategory: {
        name: "",
        description: "",
        code: "",
        visible: true,
      },
      formDataCategoryDefault: {
        name: "",
        description: "",
        code: "",
        visible: true,
      },

      rules: {
        name: {
          required,
          minLength: minLength(2),
        },
        description: {
          required,
          minLength: minLength(2),
        },
        code: {
          required,
          maxLength: maxLength(10),
        },
        visible: {
          required,
          maxLength: maxLength(10),
        },
      },

      headers: [
        // { text: "ID", value: "categoryId" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        // { text: "Description", value: "description" },
        // { text: "Visible", value: "visible" },
      ],
      headersFilter: [
        // { text: "ID", value: "categoryId" },
        { text: "Name", value: "name" },
        { text: "Code", value: "code" },
        // { text: "Description", value: "description" },
        // { text: "Visible", value: "visible" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: this.pageSize,
        search: "",
        // sortColumnName: "name",
        // sortDirection: "asc",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: this.pageSize,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "name",
        // sortDirection: "asc",
      },

      pageCountArchive: 20,

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Category" : "Edit Category";
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      if (this.isActive == 0) {
        var items = this.items;
      } else {
        var items = this.allItems;
      }
      if (items <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Name", dataKey: "name" },
          { title: "Code", dataKey: "code" },
          { title: "Visible", dataKey: "visible" },
          { title: "Description", dataKey: "description" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            name: el.name,
            code: el.code,
            visible: el.visible,
            description: el.description,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
      }
    },

    printCurrentPage() {
      // this.getAllDataFromApi();
      // this.isActive = 1;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Categories " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Categories " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Name",
          2: "Code",
          3: "Visible",
          4: "Description",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.name,
            2: item.code,
            3: item.visible,
            4: item.description,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post("Categories/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataCategory = Object.assign({}, this.formDataCategoryDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Categories/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addCategory = true;
    },
    closeForm() {
      this.addCategory = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataCategory = Object.assign({}, item);
      this.addCategory = true;
    },

    saveForm() {
      if (this.formDataCategory.name == "" || this.formDataCategory.code == "") {
        if (this.formDataCategory.name == "") {
          warningToast("Name Required");
        }
        if (this.formDataCategory.code == "") {
          warningToast("Code Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Categories", this.formDataCategory)
            .then((response) => {
              if (response.data.success === true) {
                this.addCategory = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataCategory.categoryId;
          this.isLoading = true;
          axios
            .put("Categories/" + itemId, this.formDataCategory)
            .then((response) => {
              if (response.data.success === true) {
                this.addCategory = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataCategory = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataCategory.categoryId;
      this.isLoading = true;
      axios
        .get("Categories/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Categories/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataCategory = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataCategory.categoryId;
      this.isLoading = true;
      axios
        .get("Categories/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataCategory = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataCategory.categoryId;
      axios
        .delete("Categories/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
