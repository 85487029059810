<template>
  <LoadingScreen :isLoading="isLoading" />
  <div>
    <div class="mt-5 w-full">
      <div
        class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
      >
        <div class="flex justify-center items-center">
          <Export
            @printCurrentPage="printCurrentPage"
            @csvExport="csvExport"
            @pdfExport="pdfExport"
            :page="pagination.pageNumber"
            :selected="checkedData.length"
            @printSelected="printSelected"
            @csvExportSelected="csvExportSelected"
            @pdfExportSelected="pdfExportSelected"
          />

          <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

          <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />

          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
        </div>
      </div>

      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th></th>

              <th>EAN</th>
              <th>PLU/SKU</th>
              <th>Description</th>
              <th>Department</th>
              <th>MasterQuantity</th>
              <th>ScannedQuantity</th>
              <th>QuantityVariance</th>
              <th>QuantityOver</th>

              <th class="flex justify-start">
                <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
              </th>
            </tr>
          </thead>

          <tbody v-for="(item, i) in items" :key="i">
            <tr class="intro-x">
              <td class="cursor-pointer" :style="opened.includes(item.skuId) ? getSelectedColor() : 'color: #1a73e8'">
                <svg
                  v-if="!opened.includes(item.skuId)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-caret-down-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-primary text-bold"
                  @click="toggle(item)"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>

                <svg
                  v-if="opened.includes(item.skuId)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-caret-up-fill w-4 h-4 md:w-5 md:h-5 lg:w-5 lg:h-5 text-white text-bold"
                  viewBox="0 0 16 16"
                  @click="closeToggle(item)"
                >
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
              </td>

              <td :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.EAN }}
              </td>

              <td :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.SKU }}
              </td>

              <td :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.description }}
              </td>

              <td :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.department }}
              </td>

              <td :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.masterQuantity }}
              </td>

              <td :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.scannedQuantity }}
              </td>

              <td class="w-24" :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.quantityVariance }}
              </td>
              <td class="w-24" :style="opened.includes(item.skuId) ? getSelectedColor() : ''">
                {{ item.quantityOver }}
              </td>
              <td class="table-report__action w-28">
                <div class="lg:grid lg:grid-cols-3 gap-x-6 gap-y-2 p-2 flex flex-wrap ml-4">
                  <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />

                  <!-- <RestoreItem
                    v-if="item.status == 1"
                    :activeAccess="$h.deleteItemAccess(policyAccess)"
                    @parentMethod="deleteItem($event)"
                    :item="item"
                    name="Send to Que"
                  />

                  <DropOrder
                    v-else
                    :activeAccess="$h.addItemAccess('DroppedOrders')"
                    @parentMethod="dropItem($event)"
                    :item="item"
                    name="Drop Order"
                  /> -->
                </div>
              </td>
            </tr>

            <tr v-if="opened.includes(item.skuId)">
              <td colspan="13">
                <table class="table table-report -mt-2 table-auto">
                  <thead>
                    <tr class="uppercase whitespace-nowrap">
                      <th>InStockQuantity</th>
                      <th>AvailableStock</th>
                      <th>Bin</th>
                      <th>Section</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="orderLines.length <= 0">
                      <td colspan="12">
                        <span class="flex justify-center items-center text-success font-bold">{{ const_txt.NO_ORDER_LINE }}</span>
                      </td>
                    </tr>
                    <tr v-for="(item, index) in orderLines" :key="index" class="intro-x">
                      <td>
                        {{ item.inStockQuantity != null ? item.inStockQuantity : "" }}
                      </td>
                      <td>
                        {{ item.availableStock != null ? item.availableStock : "" }}
                      </td>
                      <td>
                        {{ item.bin != null ? item.bin : "" }}
                      </td>
                      <td>
                        {{ item.section != null ? item.section : "" }}
                      </td>

                      <td>
                        {{ item.expiryDate != null ? momentLocal(item.expiryDate) : "" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <PaginationBar
                  @changePage="changePageOrderLines($event)"
                  @pageSizeChange="pageSizeChangeOrderLines($event)"
                  :itemsData="orderLinesData"
                  :pageCount="pageCountOrderLines"
                  :pageSize="pageSizeOrderLines"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
      <NoData :itemsData="itemsData" />
      <!-- BEGIN: Pagination -->
      <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />

      <!-- END: Pagination -->
    </div>
  </div>

  <PickingConfirmModal :name="formDataActive.orderNumber" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />

  <DropConfirmModal :name="formDataActive.orderNumber" :modalStatus="dropConfirmationModal" :modalConfirm="dropItemConfirm" :modalClose="closeDrop" />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";

import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import countriesJSON from "@/assets/json/countries.json";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import moment from "moment";
import { customerOrderStatus, customerOrderStatusColor } from "../../../global-functions/status";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import Moveable from "vue3-moveable";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Stock Take Variance");
  },
  components: {
    Moveable,

    autoTable,
  },
  data() {
    return {
      headersFilter: [
        { text: "Order", value: "orderId" },
        { text: "priority", value: "priority" },
        { text: "status", value: "status" },
        { text: "Order Created", value: "time" },
        { text: "Booking", value: "despatchBookingNumber" },
        { text: "type", value: "type" },
      ],
      const_txt: const_txt,
      doc: null,

      searchData: "",
      isLoading: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      titleChange: -1,

      formDataActive: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        statusList: "",
        despatchBookingNumber: "",
        currency: "",
        priority: 0,
        priorityNumber: 0,
        customer: {},
        deliveryAddress: {},
        despatch: {},

        orderLines: [
          {
            quantity: 0,
            price: 0,
            retailPrice: 0,
            discountedPrice: 0,
            discountedReason: "",
            status: 1,
            skuName: "",
            ean: "",
            description: "",
            skuId: 0,
            sku: "",
            orderId: 0,
          },
        ],
      },
      formDataActiveDefault: {
        orderNumber: "",
        note: "",
        type: "",
        time: "",
        amount: "",
        discountPercentage: "",
        discountTotal: "",
        status: "",
        statusList: "",
        despatchBookingNumber: "",
        currency: "",
        priority: 0,
        priorityNumber: 0,
        customer: {},
        deliveryAddress: {},
        despatch: {},
        orderLines: [
          {
            quantity: 0,
            price: 0,
            retailPrice: 0,
            discountedPrice: 0,
            discountedReason: "",
            status: 1,
            skuName: "",
            ean: "",
            description: "",
            skuId: 0,
            sku: "",
            orderId: 0,
          },
        ],
      },

      pageCountOrderLines: 0,
      pageRowCountOrderLines: 5,
      pageSizeOrderLines: 10,
      productStart: 0,
      productEnd: 5,

      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortDirection: "ASC",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [
        {
          skuId: "11183",
          EAN: "1000000000015",
          SKU: "19529",
          description: "Supereme Whitening Cream 50g 4/25",
          department: "dept67",
          masterQuantity: "1000",
          scannedQuantity: "900",
          quantityVariance: "100",
          quantityOver: "-100",
        },
        {
          skuId: "10545",
          EAN: "9410000000612",
          SKU: "2206",
          description: "Him Antiwrinkle Cream 50g 2/24",
          department: "dept67",
          masterQuantity: "3000",
          scannedQuantity: "100",
          quantityVariance: "400",
          quantityOver: "-200",
        },
      ],
      itemsData: [],

      orderLinesData: [],

      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,

      typeItems: store.state.authUser.OrderTypes,
      statusItems: store.state.authUser.PickingOrderStatus,
      priorityItems: [],

      orderLinesLocal: [],
      orderNo: "",
      isRow: false,
      opened: [],
      indexToggle: 0,
      itemsNew: [],
      orderLineStatusItems: store.state.authUser.OrderLineStatuses,
      splitData: false,
      allSelected: false,
      checkedData: [],
      pickingConfirmationModal: false,

      policyAccess: "PickingOrders",
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Picking" : "Edit Picking";
    },
  },
  watch: {},
  created() {
    this.getDataFromApi();
    this.$signalRHub.emitter.on("PickedProduct", this.updateProductStatus);
  },
  beforeDestroy() {
    this.$signalRHub.emitter.off("PickedProduct", this.updateProductStatus);
  },

  methods: {
    updateProductStatus(data) {
      console.log(data);
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    dropItemConfirm() {
      this.isLoading = true;
      var FormData = {
        orderNumber: this.formDataActive.orderNumber,
      };
      axios
        .post("DroppedOrders/" + this.warehouseAbbreviation, FormData)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            this.getDataFromApi();
            this.closeDrop();
            successToast(response.data.message);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setPriorityNumber(value) {
      try {
        return this.allItems.find((x) => x.orderId === parseInt(value)).priorityNumber;
      } catch (e) {
        return "";
      }
    },
    changePriorityNumber(item) {
      this.isLoading = true;
      if (item.priorityNumber == null || item.priorityNumber == "") {
        failedToast("Priority Number is required");
        this.isLoading = false;
      } else {
        var formDataChangePriorityNumber = {
          priorityNumber: item.priorityNumber,
          orderNumber: item.orderNumber,
          warehouse: this.warehouseAbbreviation,
        };
        axios
          .post("OrderPickingQueue/UpdateOrderPriorityNumber", formDataChangePriorityNumber)
          .then((response) => {
            if (response.data.success === true) {
              successToast(response.data.message);
              this.getDataFromApi();
              this.getAllDataFromApi();
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    toggle(item) {
      this.opened.splice(this.indexToggle, 1);
      this.indexToggle = this.opened.indexOf(item.skuId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.SKU = item.SKU;
        this.getDataFromOrderLines();
        this.opened.push(item.skuId);
      }
    },
    closeToggle(item) {
      this.indexToggle = this.opened.indexOf(item.skuId);
      if (this.indexToggle > -1) {
        this.opened.splice(this.indexToggle, 1);
      } else {
        this.SKU = item.SKU;
        this.getDataFromOrderLines();
        this.opened.push(item.skuId);
      }
    },
    getOrderLineStatus(value) {
      try {
        return this.orderLineStatusItems.find((x) => x.number === parseInt(value)).displayName;
      } catch (e) {
        return "";
      }
    },
    getOrderLineStatusColor(value) {
      try {
        return this.orderLineStatusItems.find((x) => x.number === parseInt(value)).color;
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getStatus(value) {
      try {
        return this.statusItems.find((x) => x.number === parseInt(value)).displayName;
      } catch (e) {
        return "";
      }
    },
    getStatusColor(value) {
      try {
        // return this.statusItems.find((x) => x.number === parseInt(value)).color;
        return "#006400";
      } catch (e) {
        console.log(e);
        return "";
      }
    },
    getType(value) {
      try {
        return this.typeItems.find((type) => type.number === parseInt(value)).name;
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    getPriority(value) {
      try {
        if (value == 1) {
          return "Low";
        } else if (value == 2) {
          return "Medium";
        } else if (value == 3) {
          return "High";
        } else {
          return "";
        }
        // return priorityData.find((x) => x.number === value).name;
      } catch (e) {
        console.log(e);
        return "";
      }
    },

    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "EAN", dataKey: "EAN" },
              { title: "PLU/SKU", dataKey: "SKU" },
              { title: "Description", dataKey: "description" },
              { title: "Department", dataKey: "department" },
              { title: "MasterQuantity", dataKey: "masterQuantity" },
              { title: "ScannedQuantity", dataKey: "scannedQuantity" },
              { title: "QuantityVariance", dataKey: "quantityVariance" },
              { title: "QuantityOver", dataKey: "quantityOver" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                EAN: el.EAN,
                SKU: el.SKU,
                description: el.description,
                department: el.department,
                masterQuantity: el.masterQuantity,
                scannedQuantity: el.scannedQuantity,
                quantityVariance: el.quantityVariance,
                quantityOver: el.quantityOver,
              };
              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });
            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Stock Take Variance" + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Stock Take Variance " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "EAN",
          2: "PLU/SKU",
          3: "Description",
          4: "Department",
          5: "MasterQuantity",
          6: "ScannedQuantity",
          7: "QuantityVariance",
          8: "QuantityOver",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.EAN,
            2: item.SKU,
            3: this.description,
            4: item.department,
            5: item.masterQuantity,
            6: item.scannedQuantity,
            7: item.quantityVariance,
            8: item.quantityOver,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      this.isLoading = true;
      axios
        .post(`OrderPickingQueue/ActiveOrders/${this.warehouseAbbreviation}`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },
    customerOrderStatus(status) {
      return customerOrderStatus(status);
    },
    customerOrderStatusColor(status) {
      return customerOrderStatusColor(status);
    },

    getToday() {
      const today = new Date();

      this.formDataActive.time = moment(today).format("DD MMMM, YYYY");
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;

      this.getDataFromApi();
    },

    changePageOrderLines(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromOrderLines();
    },

    pageSizeChangeOrderLines(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromOrderLines();
    },
    setDefault() {
      this.formDataActive = Object.assign({}, this.formDataActiveDefault);
      this.getToday();
    },

    getDataFromApi() {
      this.isLoading = true;

      axios
        .post(`OrderPickingQueue/ActiveOrders/${this.warehouseAbbreviation}`, this.pagination)
        .then((response) => {
          this.itemsData = response.data.data;
          //   this.items = response.data.data.items;
          this.pageCount = this.itemsData.totalPages;
          if (this.pagination.pageNumber == this.itemsData.totalPages) {
            this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
          } else {
            this.pageSizeView = this.pagination.pageSize;
          }
          for (var i = 0; i < this.items.length; i++) {
            this.items[i].selected = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getDataFromOrderLines() {
      this.isLoading = true;

      axios
        // .post(
        //   "Picking/" +
        //     this.warehouseAbbreviation +
        //     "/" +
        //     this.orderNumber +
        //     "/GetPickingOrderLines",
        //   this.pagination
        // )
        .get("MemoryStocks/" + this.warehouseAbbreviation + "/Products/" + this.SKU)
        .then((response) => {
          // this.orderLinesData = response.data.data;
          this.orderLines = response.data;
          this.pageCountOrderLines = this.itemsData.totalPages;

          if (this.pagination.pageNumber == this.orderLinesData.totalPages) {
            this.pageSizeOrderLines = this.orderLinesData.totalCount - (this.pageCountOrderLines - 1) * this.pagination.pageSize;
          } else {
            this.pageSizeOrderLines = this.pagination.pageSize;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    connectTimetoDate() {
      var currentTime = moment(new Date()).format();
      var splitTime = currentTime.split("T");

      var time = moment(this.formDataActive.time).format("YYYY-MM-DD");
      time = time.concat("T").concat(splitTime[1]);
      this.formDataActive.time = time;

      var deliveryDate = moment(this.formDataActive.deliveryDate).format("YYYY-MM-DD");
      deliveryDate = deliveryDate.concat("T").concat(splitTime[1]);
      this.formDataActive.deliveryDate = deliveryDate;
    },
    archiveItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.archiveConfirmationModal = true;
      console.log(item);
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },
    closePick() {
      this.pickingConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeDrop() {
      this.dropConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataActive.orderId;

      axios
        .get("CustomerOrders/" + itemId + "/Archive")
        .then((response) => {
          this.archiveConfirmationModal = false;
          this.getDataFromApi();
          successToast(response.data.message);
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("CustomerOrders/Archived", this.paginationArchive)
        .then((response) => {
          this.archiveData = response.data.data;
          this.archiveItems = response.data.data.items;
          this.pageCountArchive = this.archiveData.totalPages;

          this.archiveModal = true;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive() {
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },

    restoreItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataActive.orderId;

      axios
        .get("CustomerOrders/" + itemId + "/Restore")
        .then((response) => {
          this.restoreConfirmationModal = false;
          this.viewArchives();
          this.getDataFromApi();
          if (this.archiveData.totalCount == 0) {
            this.archiveModal = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    dropItem(item) {
      this.formDataActive = Object.assign({}, item);
      this.dropConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var orderNumber = this.formDataActive.orderNumber;
      axios
        .get(`OrderPickingQueue/Remove?orderNumber=${orderNumber}&warehouse=${this.warehouseAbbreviation}`)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white;";
    },
  },
};
</script>
