<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:justify-between md:flex md:flex-row md:items-center md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Batch" />

        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pagination.pageNumber"
          :selected="checkedData.length"
          @printSelected="printSelected"
          @csvExportSelected="csvExportSelected"
          @pdfExportSelected="pdfExportSelected"
        />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>
      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <!-- <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th> -->
            <th>Batch Number</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <!-- <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td> -->
            <td @click="viewBatchItems(item)" class="cursor-pointer" style="color: #1a73e8">
              {{ item.batchNumber }}
            </td>

            <td class="table-report__action w-28">
              <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)" :item="item" name="Archive" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />

    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData" :pageCount="pageCount" :pageSize="pageSizeView" />
    <!-- END: Pagination -->
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addBatch" @hidden="addBatch = false" backdrop="static" :title="formTitle" @close="addBatch = false">
    <ModalBody class="flex justify-center space-y-4 p-8">
      <div class="flex flex-col space-y-4 w-full">
        <div>
          <label for="regular-form-1" class="form-label">Batch Number</label>

          <div class="flex flex-col space-y-1">
            <input id="vertical-form-1" v-model="formDataBatch.batchNumber" type="text" class="form-control" name="batchNumber" placeholder="BatchNumber" autocomplete="off" />
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Warehouse</label>
          <v-select
            v-model="formDataBatch.warehouseId"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenWarehouse"
            @close="onCloseWarehouse"
            @search="searchWarehouse"
            :options="warehousesList"
            :key="abbreviation"
            :get-option-label="(option) => option.abbreviation"
            :reduce="(abbreviation) => abbreviation.warehouseId"
            label="abbreviation"
          >
            <template #list-footer>
              <li v-show="hasNextPageWarehouse" ref="loadWarehouse" class="loader">Loading more...</li>
            </template>
          </v-select>

          <!-- <v-select
            v-if="storeUserRole != 'SuperUser'"
            v-model="formDataBatch.warehouseId"
            placeholder="SELECT"
            class="style-chooser"
            :filterable="false"
            @open="onOpenUsersWarehouse"
            @close="onCloseUsersWarehouse"
            @search="
              (query) => {
                onUserWarehouseSearch(query);
              }
            "
            :options="paginatedUserWarehouse"
            :reduce="(abbreviation) => abbreviation.warehouseId"
            label="abbreviation"
          >
            <template #list-footer>
              <li
                v-show="hasNextPageUsersWarehouse"
                ref="loadUsersWarehouse"
                class="loader"
              >
                Loading more...
              </li>
            </template>
          </v-select> -->
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-3">Cancel</button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">Done</button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <!-- BEGIN: View Batch Items Modal -->

  <MoveableModal :show="viewItemModal" @hidden="viewItemModal = false" backdrop="static" size="modal-sl-85" title="INFO" @close="viewItemModal = false">
    <ModalBody class="p-4">
      <!-- BEGIN: INFO -->
      <div id="labelsScroll" class="intro-y col-span-12 overflow-scroll max-h-72">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>PLU/SKU</th>
              <th>Serial Number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in batchItems" :key="i" class="intro-x">
              <td>{{ item.skuName }}</td>
              <td>{{ item.serialNumber }}</td>
            </tr>
          </tbody>
        </table>
        <div class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center" v-if="!(batchItems && batchItems.length > 0)">No Data</div>
      </div>
      <PaginationBar
        @changePage="batchChangePage($event)"
        @pageSizeChange="batchPageSizeChange($event)"
        :itemsData="batchItemsData"
        :pageCount="batchPageCount"
        :pageSize="batchPageSize"
      />
    </ModalBody>
  </MoveableModal>
  <!-- END:Batch Items Modal -->

  <!-- BEGIN: Archive Modal -->
  <MoveableModal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static" title="Archived Unit" @close="archiveModal = false">
    <ModalBody class="p-0">
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            clearable
            autocomplete="off"
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataBatch.unitName" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataBatch.unitName" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataBatch.unitName" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";

// import { permissionHelper as $h } from "@/utils/permissionHelper";

export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Batches");
    this.observerWarehouse = new IntersectionObserver(this.infiniteScrollWarehouse);
    this.observerUsersWarehouse = new IntersectionObserver(this.infiniteScrollUsersWarehouse);
  },
  components: {},
  data() {
    return {
      storeUserRole: store.state.authUser.userRole,
      paginationBatch: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationBatchDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      batchPageCount: 20,
      batchPageSize: 5,

      pageCountArchive: 20,
      batchItemsData: [],
      viewItemModal: false,
      warehouseId: store.state.authUser.warehouse.warehouseId,
      warehousesList: [
        {
          id: 0,
          abbreviation: store.state.authUser.warehouse.abbreviation,
          warehouseId: store.state.authUser.warehouse.warehouseId,
        },
      ],
      const_txt: const_txt,
      isLoading: false,
      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addBatch: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      titleChange: -1,
      formDataBatch: {
        batchNumber: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },
      formDataBatchDefault: {
        batchNumber: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
      },

      rules: {
        unitName: {
          required,
          minLength: minLength(2),
        },
        UnitDescription: {
          required,
          minLength: minLength(2),
        },
      },

      headers: [
        // { text: "ID", value: "unitId" },
        { text: "Batch Number", value: "batchNumber" },
        // { text: "Description", value: "description" },
      ],
      headersFilter: [{ text: "Unit Name", value: "unitName" }],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "unitName",
        sortDirection: "desc",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "unitName",
        // sortDirection: "asc",
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      pageCountArchive: 20,

      allItems: [],
      allItemsData: [],

      isActive: 0,
      docs: null,

      allSelected: false,
      checkedData: [],

      policyAccess: "Batches",

      observerWarehouse: null,
      limitWarehouse: 5,
      hasNextPageWarehouse: false,
      totalWhCount: 0,
      isWhSearchResult: false,
      whPageNumber: 1,
      whSearchTimer: undefined,

      warehousesearch: "",
      observerUsersWarehouse: null,
      limitUsersWarehouse: 5,
      // hasNextPageUsersWarehouse : false
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Batch" : this.formDataBatch?.batchNumber ? `Edit Batch - ${this.formDataBatch?.batchNumber}` : "Edit Batch";
    },
    checkLimtWarehouse() {
      return this.whPageNumber;
    },
    viewModalWh() {
      return this.formDataBatch.warehouseId;
    },

    getUsersWarehouses() {
      this.isLoading = true;
      let storedArrayData = store.state.authUser.licensee.warehouses;
      if (storedArrayData.length > 0) {
        this.isLoading = false;
        this.warehousesList = storedArrayData;
        return this.warehousesList.filter((x) => x.abbreviation.toLowerCase().includes(this.warehousesearch.toLowerCase()));
      } else {
        this.isLoading = false;
        warningToast("There are no Warehouses found");
      }
    },
    paginatedUserWarehouse() {
      return this.getUsersWarehouses.slice(0, this.limitUsersWarehouse);
    },
    hasNextPageUsersWarehouse() {
      return this.paginatedUserWarehouse.length < this.getUsersWarehouses.length;
    },
  },
  watch: {
    checkLimtWarehouse() {
      this.getWarehouses();
    },
    viewModalWh() {
      if (this.formDataBatch.warehouseId === null) {
        this.clearWarehouse();
      }
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    onUserWarehouseSearch(query) {
      this.warehousesearch = query;
    },

    async onOpenUsersWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.hasNextPageUsersWarehouse) {
        await this.$nextTick();
        this.observerUsersWarehouse.observe(this.$refs.loadUsersWarehouse);
      }
    },
    onCloseUsersWarehouse() {
      this.observerUsersWarehouse.disconnect();
      this.limitUsersWarehouse = 5;
    },
    async infiniteScrollUsersWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitUsersWarehouse = this.limitUsersWarehouse + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenWarehouse() {
      if (this.warehousesList.find((x) => x.id === 0)) {
        this.warehousesList.shift();
      }
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.whPageNumber++;
        this.limitWarehouse;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    batchChangePage(pageNum) {
      this.paginationBatch.pageNumber = pageNum;
      this.getBatchItems();
    },
    batchPageSizeChange(pageSize) {
      this.batchPageSize = pageSize;
      this.paginationBatch.pageSize = this.batchPageSize;
      this.getBatchItems();
    },
    viewBatchItems(item) {
      removeNullValue(item);
      this.formDataBatch = Object.assign({}, item);
      this.batchId = item.batchId;
      this.getBatchItems();
      this.viewItemModal = true;
    },
    getBatchItems() {
      this.isLoading = true;
      axios
        .post(`Batches/${this.warehouseId}/${this.batchId}/PrdocutionUnites/Paginated`, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.batchItemsData = response.data.data;
            this.batchItems = response.data.data.items;
            this.batchPageCount = this.batchItemsData.totalPages;
            this.isLoading = false;
            this.viewItemModal = true;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearWarehouse() {
      this.warehousesList = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehousesList = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },
    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Warehouses/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehousesList =
            this.isWhSearchResult !== true
              ? Array.from(new Set([...this.warehousesList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
              : response.data.data.items;
          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    setReports() {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Unit Name", dataKey: "unitName" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                unitName: el.unitName,
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Units " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Units " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Unit Name",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.unitName,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Batches/" + this.warehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    searchOnchange(searchData) {
      this.searchData = searchData; //newly added line
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataBatch = Object.assign({}, this.formDataBatchDefault);
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Batches/" + this.warehouseId + "/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addBatch = true;
      this.activateModal();
    },

    activateModal() {
      this.addBatch = true;
      this.getWarehouses();
      // if (this.storeUserRole == "SuperUser") {
      //   this.getWarehouses();
      // } else {
      //   this.getUsersWarehouses;
      // }
    },
    closeForm() {
      this.addBatch = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataBatch = Object.assign({}, item);
      this.activateModal();
    },

    saveForm() {
      if (this.formDataBatch.batchNumber == "") {
        if (this.formDataBatch.batchNumber == "") {
          warningToast("Batch Number Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          axios
            .post("Batches", this.formDataBatch)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addBatch = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataBatch.batchId;
          this.isLoading = true;
          axios
            .put("Batches/" + itemId, this.formDataBatch)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addBatch = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataBatch = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      this.isLoading = true;
      var batchId = this.formDataBatch.batchId;
      axios
        .get("Batches/" + batchId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios

        .post("Batches/" + this.warehouseId + "/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataBatch = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var batchId = this.formDataBatch.batchId;
      this.isLoading = true;
      axios
        .get("Batches/" + batchId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataBatch = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      var batchId = this.formDataBatch.batchId;
      this.isLoading = true;
      axios
        .delete("Batches/" + batchId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
  },
};
</script>
