<template>
  <MoveableModal
    :show="_modalStatus"
    @hidden="_modalStatus = false"
    @close="modalClose()"
  >
    <ModalBody class="p-1">
      <div class="p-8 text-center">
        <component :is="_icon" :class="`${_color} w-16 h-16 mx-auto mt-1`" />
        <div class="text-3xl mt-3">Are you sure?</div>
        <div class="text-xl font-bold mt-5">{{ _name }}</div>
        <div class="text-slate-500 mt-2">
          {{ _question }}
        </div>
        <div class="flex flex-col space-y-2 justify-center items-center mt-2">

          <div class="flex space-x-2 justify-center items-center">
              <div class="form-switch ml-3">
                <input
                  id="booleanSwitch"
                  type="checkbox"
                  v-model="_optionsCustomer"
                  class="form-check-input"
                  :disabled="_options"
                />
              </div>
              <div>{{ optionCustomerText }}</div>
            </div>

            <div class="flex space-x-2 justify-center items-center">
              <div class="form-switch ml-3">
                <input
                  id="booleanSwitch"
                  type="checkbox"
                  v-model="_options"
                  class="form-check-input"
                  :disabled="_optionsCustomer"
                />
              </div>
              <div>{{ optionText }}</div>
            </div>

          <!-- <div>
            <input type="radio" id="one" :value="flase" v-model="_options" />

            <label for="one">Without delete warehouse</label>
          </div>
          <div>
            <input type="radio" id="two" :value="true" v-model="_options" />
            <label for="two">With delete warehouse</label>
          </div> -->
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="modalClose()"
          class="btn btn-outline-secondary w-24 mr-1"
        >
          Cancel
        </button>
        <button
          type="button"
          :class="`${_color} btn text-white w-24`"
          @click="modalConfirm()"
        >
          {{ _nameBtn }}
        </button>
      </div>
    </ModalBody>
  </MoveableModal>
</template>
<script>
export default {
  props: {
    name: "",
    icon: "",
    color: "",
    nameBtn: "",
    question: "",
    // options: false,
    modalStatus: false,
    modalConfirm: Function,
    modalClose: Function,
  },
  computed: {
    _modalStatus: {
      get() {
        return this.modalStatus;
      },
    },
    _icon: {
      get() {
        return this.icon;
      },
    },
    _name: {
      get() {
        return this.name;
      },
    },
    _color: {
      get() {
        return this.color;
      },
    },
    _nameBtn: {
      get() {
        return this.nameBtn;
      },
    },
    _question: {
      get() {
        return this.question;
      },
    },
    _options: {
      get() {
        return this.options;
      },
      set(val) {
        this.$emit("changeOption", val);
        this.options = val
        if (val === true) {
          this.optionText = "With removing the warehouse";
        } else {
          this.optionText = "Without removing the warehouse";
        }
      },
    },
    _optionsCustomer: {
      get() {
        return this.optionsCustomer;
      },
      set(val) {
        this.$emit("changeCustomerOption", val);
        this.optionsCustomer = val
        if (val === true) {
          this.optionCustomerText = "Not Removing all customers";
        } else {
          this.optionCustomerText = "Removing all customers";
        }
      },
    },
  },
  watch: {
    _modalStatus(val) {
      val || this.modalClose();
    },
  },
  created() {},
  data() {
    return {
      optionText: "Without removing the warehouse",
      options: false,
      optionCustomerText: "Removing all customer",
      optionsCustomer : false
    };
  },
  methods: {
    modalClose() {
      return this.modalClose();
    },
  },
};
</script>
