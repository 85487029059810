<template>
  <Loading
    opacity="0.5"
    background-color="white"
    :can-cancel="true"
    v-model:active="isLoading"
    color="#164e63"
    loader="dots"
    :width="50"
    :height="50"
    :is-full-page="true"
    z-index="100000"
  />

  <div class="login">
    <div class="login-overlay"></div>
    <!-- <DarkModeSwitcher /> -->
    <div class="container sm:px-10" style="z-index: 100">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <!-- <a
            href="/"
            class="-intro-x flex items-center w-auto bg-white rounded-lg"
          >
            <img
              alt="Pharmatec Logo"
              class="logo__image"
              style="width: auto; height: 50px"
              src="@/assets/images/pl_logo2.png"
            />
             
          </a> -->
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
            style="
              background: rgba(255, 255, 255, 0.75);
              z-index: 100;
              padding: 25px;
            "
          >
            <div class="">
              <img
                alt="Pharmatec Logo"
                class="logo__image xl:h-16 xl:mb-3"
                src="@/assets/images/sclogo.png"
                style="z-index: 9999"
              />
            </div>

            <!-- <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Log in
            </h2> -->
            <div class="intro-x mt-2 text-slate-400 xl:hidden text-center">
              Manage all your warehouse operations in one place
            </div>
            <div class="intro-x mt-8">
              <!-- <div class="mb-4">
                <div class="flex flex-col space-y-1">
                  <v-select
                    v-model="warehouse"
                    placeholder="SELECT"
                    @search="
                      (search) => {
                        getWarehouses(search);
                      }
                    "
                    :options="warehouses"
                    label="abbreviation"
                  />
                </div>
              </div> -->

              <input
                v-if="highLoginStatus === false"
                v-model="formData.userName"
                type="text"
                class="form-control w-full pr-10"
                placeholder="Username"
              />

              <div
                class="w-full relative text-slate-500 mb-4"
                v-if="highLoginStatus === false"
              >
                <input
                  v-model="formData.password"
                  :type="visibility"
                  class="form-control w-full pr-10 mt-5"
                  placeholder="Password"
                  @keyup.enter="login"
                />
                <EyeIcon
                  @click="showPassword()"
                  v-if="visibility == 'password'"
                  class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 mt-7 cursor-pointer"
                />
                <EyeOffIcon
                  @click="hidePassword()"
                  v-if="visibility == 'text'"
                  class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 mt-7 cursor-pointer"
                />
              </div>

              <div v-if="highLoginStatus === true">
                <div class="mb-4">
                  <div class="flex flex-col space-y-1">

                    <Autocomplete
          ref="signinlicensee"
          v-model:modelValue="inputLicensee"
          label="Licensee"
          itemText="companyName"
          itemValue="companyName"
          :resultObj="true"
          @load="loadLicensee"
          refKey="signinlicensee"
        />

                    <!-- <label for="regular-form-1" class="form-label"
                      >Licensee</label
                    >
                    <v-select
                      v-model="warehousefield"
                      placeholder="SELECT"
                      class="style-chooser"
                      :filterable="false"
                      @open="onOpenWarehouse"
                      @close="onCloseWarehouse"
                      @search="searchWarehouse"
                      :options="warehouses"
                      label="companyName"
                    >
                      <template #list-footer>
                        <li
                          v-show="hasNextPageWarehouse"
                          ref="loadWarehouse"
                          class="loader"
                        >
                          Loading more...
                        </li>
                      </template>
                    </v-select> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4"
            >
              <!-- <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >
              </div> -->
              <!-- <a href="">Forgot Password?</a> -->
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                v-if="highLoginStatus === false"
                @click="login()"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Login
              </button>

              <button
                v-if="highLoginStatus === true"
                @click="nextSetup()"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Login
              </button>

              <!-- <button
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              >
                Register
              </button> -->
            </div>
            <!-- <div
              class="intro-x mt-10 xl:mt-24 text-slate-600 dark:text-slate-500 text-center xl:text-left"
            >
              By signin up, you agree to our
              <a class="text-primary dark:text-slate-200" href=""
                >Terms and Conditions</a
              >
              &
              <a class="text-primary dark:text-slate-200" href=""
                >Privacy Policy</a
              >
            </div> -->
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
  <Footer />

  <MoveableModal
    :show="warehousemodal"
    @hidden="warehousemodal = false"
    backdrop="static"
    size="modal-lg"
    :title="'Welcome SuperUser, Select Your licensee'"
    @close="logout()"
  >
    <ModalBody class="grid grid-cols-1 lg:gap-4 md:gap-4 gap-2">
      <div class="mb-4">
        <!-- <div class="flex flex-col space-y-1">
          <v-select
            v-model="warehouse"
            placeholder="SELECT"
            @search="
              (search) => {
                getWarehouses(search);
              }
            "
            :options="warehouses"
            label="abbreviation"
          />
        </div> -->
        <div class="flex flex-col space-y-1">
          <v-select
            v-model="licenseeModel"
            placeholder="SELECT"
            :options="licenseeList"
            label="companyName"
          />
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button
        type="button"
        @click="logout()"
        class="btn btn-outline-secondary w-24 mr-1"
      >
        Logout
      </button>
      <button @click="apply()" type="submit" class="btn btn-primary w-24">
        Change
      </button>
    </ModalFooter>
  </MoveableModal>

  <AddWarehouse
    :addWarehouse="addWarehouse"
    :formDataWarehouse="formDataWarehouse"
    :modalClose="closeWarehouse"
    :response = "storeResponse"
  />
</template>

<script>
import axios from "@/services/http-common";
import store from "../../store";
import Footer from "@/global-components/footer/Main.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../global-functions/toast";
import router from "../../router";

// import { onMounted } from "vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
// import dom from "@left4code/tw-starter/dist/js/dom";

// onMounted(() => {
//   dom("body").removeClass("main").removeClass("error-page").addClass("login");
// });

export default {
  components: {
    Loading,
    Footer,
  },
  mounted() {
    this.observerWarehouse = new IntersectionObserver(
      this.infiniteScrollWarehouse
    );
  },
  data: () => ({
    visibility: "password",
    softClientLogo: "",
    isLoading: false,
    // formData: { userName: "SuperUser", password: "SuperUser$369" },
    formData: { userName: "", password: "" },
    formDataDef: { userName: "", password: "" },
    warehouses: [],
    licenseeItems: [],
    warehouse: {},
    warehouseName: "",
    userRole: "",
    tokenLicenseeId: 0,
    warehousemodal: false,
    warehousefield: "",
    inputLicensee: "",
    observerWarehouse: null,
    limitWarehouse: 5,
    hasNextPageWarehouse: false,
    totalWhCount: 0,
    isWhSearchResult: false,
    whPageNumber: 1,
    whSearchTimer: undefined,

    highLoginStatus: false,

    licenseeModel: {},
    licenseeList: [],

    addWarehouse: false,
    formDataWarehouse: {
      name: "",
      description: "",
      abbreviation: "",
      isDefault: false,
    },
    formDataWarehouseDefault: {
      name: "",
      description: "",
      abbreviation: "",
      isDefault: false,
    },
    storeResponse: {},
  }),
  created() {
    // this.getWarehouses();
  },
  watch: {
    checkLimtWarehouse() {
      //this.getWarehouses();
    },
    viewModalWh() {
      if (this.inputLicensee === null) {
        this.clearWarehouse();
      }
    },
  },
  computed: {
    baseUrl() {
      return window.location.origin;
    },
    checkLimtWarehouse() {
      return this.whPageNumber;
    },
    viewModalWh() {
      return this.inputLicensee;
    },
  },
  methods: {
    closeWarehouse() {
      this.addWarehouse = false;
      this.setDefaultWarehouse();
      // router.push("/logout");
    },
    setDefaultWarehouse() {
      this.formDataWarehouse = Object.assign({}, this.formDataWarehouseDefault);
    },
    async onOpenWarehouse() {
      if (this.isWhSearchResult) {
        this.whPageNumber = 1;
      }
      if (this.hasNextPageWarehouse) {
        await this.$nextTick();
        this.observerWarehouse.observe(this.$refs.loadWarehouse);
      }
    },
    onCloseWarehouse() {
      this.observerWarehouse.disconnect();
    },
    async infiniteScrollWarehouse([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitWarehouse = this.limitWarehouse + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    loadLicensee(options) {
    this.isLoading = true;
    var pagination = {
      pageNumber: options?.page || 1,
      pageSize: options?.itemsPerPage || 10,
      search: options?.search || "",
    };
    axios
    .post("Licensees/Paginated", pagination)
      .then((response) => {
        if (response.data.success === true) {
          this.isLoading = false;
          let list = [...JSON.parse(JSON.stringify(this.licenseeItems)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
          this.licenseeItems = list;
          store.dispatch("authUser/setLicensees", this.licenseeItems);
          options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
        } else {
          this.isLoading = false;
          failedToast(response.data.message);
        }
      })
      .catch((error) => {
        this.isLoading = false;
        warningToast(statusMessage(error.response.status));
      });
  },

    clearWarehouse() {
      this.warehousesList = [];
      this.whPageNumber = 1;
      this.limitWarehouse = 5;
      //this.getWarehouses("");
    },

    searchWarehouse(search) {
      clearInterval(this.whSearchTimer);
      this.whSearchTimer = setTimeout(() => {
        if (search) {
          this.warehousesList = [];
          this.whPageNumber = 1;
          this.limitWarehouse = this.totalWhCount;
          this.getWarehouses(search.trim());
        }
      }, 2000);
    },
    getWarehouses(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.whPageNumber,
        pageSize: this.limitWarehouse,
        search: search,
      };
      axios
        .post("Licensees/Paginated", pagination)
        .then((response) => {
          this.isWhSearchResult = !!search;
          this.warehouses =
            this.isWhSearchResult !== true
              ? Array.from(
                  new Set(
                    [...this.warehouses, ...response.data.data.items].map(
                      JSON.stringify
                    )
                  )
                ).map(JSON.parse)
              : response.data.data.items;

          this.hasNextPageWarehouse = response.data.data.hasNextPage;
          this.totalWhCount = response.data.data.totalCount;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          failedToast(statusMessage(error.response.status));
        });
    },
    showPassword() {
      this.visibility = "text";
    },
    hidePassword() {
      this.visibility = "password";
    },
    nextSetup() {
      if (this.formData.userName == "" || this.formData.password == "") {
        if (this.formData.userName == "") {
          warningToast("Enter Username");
        }
        if (this.formData.password == "") {
          warningToast("Enter Password");
        }
      } else {
        var username = this.formData.userName;
        var password = this.formData.password;
        this.isLoading = true;

        if (this.inputLicensee === "" || this.inputLicensee === null) {
          this.isLoading = false;
          warningToast("Select a licensee");
        } else {
          this.isLoading = false;
          store.dispatch("authUser/setLicensee", this.inputLicensee);
          var base_url = "https://" + this.inputLicensee.domain + "/api/";
          axios.defaults.baseURL = base_url;
          store.dispatch("authUser/setBaseurl", base_url);

          store
            .dispatch("authUser/login", { username, password })
            .then(async (response) => {
              if (response.success === true) {
                this.isLoading = true;
                this.storeResponse = response;
                if (this.userRole == "SuperUser") {
                  this.isLoading = false;

                  // this.getAllLicensee();
                  this.getAllStatus();
                  var isWarehouse = false;

                  try {
                    var pagination = {
                      pageNumber: 1,
                      pageSize: 10,
                    };
                    let responseWh = await axios.post(
                      "Warehouses/Paginated",
                      pagination
                    );
                 
                    if (responseWh.data.data.items.length <= 0) {
                      this.addWarehouse = true;

                    } else {
                      store.dispatch("authUser/setWarehouses", responseWh.data.data.items);
                      var defaultWh = responseWh.data.data.items.find(
                        (x) => x.isDefault === true
                      );
                      store.dispatch(
                        "authUser/setWarehouse",
                        defaultWh === null || defaultWh === undefined
                          ? responseWh.data.data.items[0]
                          : defaultWh
                      );
                      isWarehouse = true;
                    }

                  } catch (error) {
                    console.log(error);
                  }

                  if(isWarehouse){
                    axios.get("Gallery/Softclient/Logo").then((response) => {
                    this.softClientLogo = response.data;
                    store.dispatch(
                      "authUser/setSoftClientLogo",
                      this.softClientLogo
                    );
                  });

                  if (response.data.permissions.length > 0) {
                    successToast(
                      `Welcome ${response.data.firstName} ${response.data.lastName}\nYou are Logged In as ${response.data.user}`
                    );
                  } else {
                    failedToast("Access denied, Contact the Admin");
                  }

                  // successToast(response.message);

                  var url = window.location.href;
                  // var url = base_url;
                  var check = url.indexOf("=");
                  if (check === -1) {
                    // var change_url = `https://${base_url}`;
                    //window.location.href = `${change_url}/wms/skus`;
                    router.push(`/wms/customer-orders`);
                    //window.location.replace(`${change_url}/wms/skus`);
                  } else {
                    var newUrl = url.slice(0, 0) + url.slice(check + 1);
                    router.push(newUrl);
                  }
                  //store.dispatch("authUser/setWarehouse", warehouse);
                  }

                 
                }
              } else {
                this.isLoading = false;
                failedToast(response.message);
                this.formData.password = "";
              }
            })
            .catch(() => {});
        }
      }
    },
    login() {
      if (this.formData.userName == "" || this.formData.password == "") {
        if (this.formData.userName == "") {
          warningToast("Enter Username");
        }
        if (this.formData.password == "") {
          warningToast("Enter Password");
        }
      } else {
        var username = this.formData.userName;
        var password = this.formData.password;
        this.isLoading = true;

        store
          .dispatch("authUser/login", { username, password })
          .then(async (response) => {
            if (response.success === true) {
              this.isLoading = true;

              var encodetoken = response.data.token;

              var jsonObj = JSON.parse(atob(encodetoken.split(".")[1]));
              this.userRole =
                jsonObj[
                  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                ];
              store.dispatch("authUser/setUserRole", this.userRole);

              if (this.userRole == "SuperUser") {
                this.isLoading = false;
               // this.getWarehouses();
                this.highLoginStatus = true;
              } 
              
              else {
                this.isLoading = false;
                this.highLoginStatus = false;

                this.tokenLicenseeId = jsonObj["Licensee"];
                store.dispatch("authUser/setLicenseeId", this.tokenLicenseeId);

                try {
                  var licensee_response = await axios.get(
                    "Licensees/" + this.tokenLicenseeId
                  );
                  if (licensee_response.data.success) {
                    var licensee = licensee_response.data.data;
                    store.dispatch("authUser/setLicensee", licensee);
                  } else {
                    failedToast(licensee_response.data.message);
                  }
                } catch (error) {
                  console.log("Sign in: Licensees: Error: ", error);
                }

                var webUrl = window.location.href;
                var origin = new URL(webUrl).origin;

               var combineUrl = origin + "/api/"; // when locally run, hide this

              //var combineUrl = 'https://mywarehouse.myweb.net.au/api/'; // when locally run, show this

                axios.defaults.baseURL = combineUrl;
                store.dispatch("authUser/setBaseurl", combineUrl);

                var pagination = {
                  pageNumber: 1,
                  pageSize: 10,
                };

                try {
                  var responseWarehouse = await axios.post(
                    "Warehouses/Paginated",
                    pagination
                  );
                  if (responseWarehouse.data.success) {
                    
                    if (responseWarehouse.data.data.items <= 0) {
                      warningToast(
                        "There is no warehouse found, Please contact adminstrator"
                      );
                      router.push("/logout");
                    } else {

                      store.dispatch("authUser/setWarehouses", responseWarehouse.data.data.items);
                      var defaultWh = responseWarehouse.data.data.items.find(
                        (x) => x.isDefault === true
                      );

                      store.dispatch(
                        "authUser/setWarehouse",
                        defaultWh === null || defaultWh === undefined
                          ? responseWarehouse.data.data.items[0]
                          : defaultWh
                      );
                      
                    }
                  } else {
                    failedToast(responseWarehouse.data.message);
                  }
                } catch (error) {
                  console.log("Sign in: Warehouse: Error: ", error);
                }

                this.getAllStatus();

                if (response.data.permissions.length > 0) {
                  successToast(
                    `Welcome ${response.data.firstName} ${response.data.lastName}\nYou are Logged In as ${response.data.user}`
                  );
                } else {
                  failedToast("Access denied, Contact the Admin");
                }

                // successToast(response.message);

                var url = window.location.href;
                var check = url.indexOf("=");
                if (check === -1) {
                  router.push("/wms/customer-orders");
                } else {
                  var newUrl = url.slice(0, 0) + url.slice(check + 1);
                  router.push(newUrl);
                }
                //store.dispatch("authUser/setWarehouse", warehouse);
              }
            } else {
              this.isLoading = false;
              failedToast(response.message);
              this.formData.password = "";
            }
          })
          .catch(() => {});

        //       decodeToken = (encodetoken) =>{

        //   var jsonObj = JSON.parse(atob(encodetoken.split('.')[1]));
        //   var role = Object.keys(jsonObj)[5];

        //   return role;
        // };
        // axios
        //   .post("Authentications/Login", this.formData)
        //   .then((response) => {
        //     if (response.data.success === true) {
        //       successToast(response.data.message);
        //       router.push("/");
        //     } else {
        //       failedToast(response.data.message);
        //       this.formData = Object.assign({}, this.formDataDef);
        //     }
        //   })
        //   .catch(() => {});
      }
    },

    async getAllLicensee() {
      try {
        let response = await axios.get(`Licensees/Paginated`);
        if (response.data.success) {
          this.licenseeList = response.data.data.items;
          console.log("licenseeList", this.licenseeList);
        } else {
          failedToast(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getAllStatus() {
      var statusArray = [
        {
          api: "Enums/OrderStatuses",
          dispatch: "authUser/setOrderStatuses",
        },
        {
          api: "Enums/OrderTypes",
          dispatch: "authUser/setOrderTypes",
        },
        {
          api: "Enums/InvoiceStatuses",
          dispatch: "authUser/setInvoiceStatuses",
        },
        {
          api: "Enums/OrderLineStatuses",
          dispatch: "authUser/setOrderLineStatuses",
        },
        {
          api: "Enums/PurchaseInvoiceStatuses",
          dispatch: "authUser/setPurchaseInvoiceStatuses",
        },
        {
          api: "Enums/PickingTypes",
          dispatch: "authUser/setPickingTypes",
        },
        {
          api: "Enums/PurchaseOrderStatus",
          dispatch: "authUser/setPurchaseOrderStatus",
        },
        {
          api: "Enums/PickingOrderStatus",
          dispatch: "authUser/setPickingOrderStatus",
        },
        {
          api: "Enums/ProductTypes",
          dispatch: "authUser/setProductTypes",
        },
        {
          api: "Enums/PaymentTypes",
          dispatch: "authUser/setPaymentTypes",
        },
        {
          api: "Enums/ReturnedProductStates",
          dispatch: "authUser/setReturnedProductStates",
        },
      ];

      try {
        for (var i = 0; i < statusArray.length; i++) {
          let response = await axios.get(`${statusArray[i].api}`);
          store.dispatch(`${statusArray[i].dispatch}`, response.data);
        }
      } catch (error) {
        console.log(error);
      }

      // axios
      //   .get("Enums/OrderStatuses")
      //   .then((response) => {
      //     store.dispatch("authUser/setOrderStatuses", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/OrderTypes")
      //   .then((response) => {
      //     store.dispatch("authUser/setOrderTypes", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/InvoiceStatuses")
      //   .then((response) => {
      //     store.dispatch("authUser/setInvoiceStatuses", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/OrderLineStatuses")
      //   .then((response) => {
      //     store.dispatch("authUser/setOrderLineStatuses", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/PurchaseInvoiceStatuses")
      //   .then((response) => {
      //     store.dispatch("authUser/setPurchaseInvoiceStatuses", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/PickingTypes")
      //   .then((response) => {
      //     store.dispatch("authUser/setPickingTypes", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/PurchaseOrderStatus")
      //   .then((response) => {
      //     store.dispatch("authUser/setPurchaseOrderStatus", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/PickingOrderStatus")
      //   .then((response) => {
      //     store.dispatch("authUser/setPickingOrderStatus", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/ProductTypes")
      //   .then((response) => {
      //     store.dispatch("authUser/setProductTypes", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/PaymentTypes")
      //   .then((response) => {
      //     store.dispatch("authUser/setPaymentTypes", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // axios
      //   .get("Enums/ReturnedProductStates")
      //   .then((response) => {
      //     store.dispatch("authUser/setReturnedProductStates", response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
  // logout(){
  //   store.dispatch("authUser/logout").then(() => {
  //     this.warehousemodal = false;
  //   router.push("/login");
  // });
  // },
  // apply(){
  //   var warehouse = this.warehouses;
  //   store.dispatch("authUser/setWarehouse", warehouse);
  // }
};
</script>
