<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full">
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)"
          buttonName="Add New Purchase Order" />

        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport"
          :page="pagination.pageNumber" :selected="checkedData.length" @printSelected="printSelected"
          @csvExportSelected="csvExportSelected" @pdfExportSelected="pdfExportSelected" @printAllPages="printAllPages" />
        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />

        <ActionBtn :activeAccess="true" @action="genInvoice()" buttonName="Gen.Invoice" icon="ClipboardListIcon" />

        <!-- SEARCH -->
        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)"
          @clearSearch="clearSearch($event)" />

        <!-- SEARCH END-->
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th>Order#</th>
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>Requested Date</th>
            <th>REQUIRED DATE</th>
            <th>ETA</th>
            <th>Supplier</th>
            <!-- <th>Picking Type</th> -->
            <!-- <th>Picking Type</th> -->
            <th>Status</th>
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)"
                @unSelectAll="unSelectAll($event)" />
              <!-- <ArchiveItem
                class="ml-3 -mt-1"
                :activeAccess="$h.archiveItemAccess(policyAccess)"
                v-if="checkedData.length > 0"
                @parentMethod="archiveMultipleItem($event)"
                name="Archive Selected"
              /> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td @click="
              addItem(item);
            item.selected = true;
            " class="cursor-pointer" :style="item.selected ? getSelectedColor() : 'color: #1a73e8'">
              {{ item.orderNumber }}
            </td>

            <td :style="item.selected ? getSelectedColor() : ''">
              {{ item.requestedDate === null || item.requestedDate === undefined ? "" : momentLocal(item.requestedDate) }}
            </td>
            <td :style="item.selected ? getSelectedColor() : ''">
              {{ item.neededDate === null || item.neededDate === undefined ? "" : momentLocal(item.neededDate) }}
            </td>
            <td :style="item.selected ? getSelectedColor() : ''">
              {{ item.eta === null || item.eta === undefined ? "" : momentLocal(item.eta) }}
            </td>

            <td :style="item.selected ? getSelectedColor() : ''">
              {{ item.supplier.company }}
            </td>

            <!-- <td :style="item.selected ? getSelectedColor() : ''">
              {{ getPickingType(item.pickingType) }}
            </td> -->

            <!-- <td :style="item.selected ? getSelectedColor() : ''" class="w-36">
              <div
                class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center"
                :style="getPickingTypeColor(item.pickingType)"
              >
                {{ getPickingType(item.pickingType) }}
              </div>
            </td> -->

            <td :style="item.selected ? getSelectedColor() : ''" class="">
              <div
                class="text-white rounded-2xl text-center p-1 pl-2 pr-2 flex items-center justify-center whitespace-nowrap"
                :style="{ 'background-color': getStatusColor(item.status) }">
                {{ getStatus(item.status) }}
              </div>
            </td>

            <td class="table-report__action w-28">
              <div class="grid grid-cols-3 gap-x-6 gap-y-2 p-2 ml-4">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item"
                  name="Edit ETA" />
                <!-- <ProductCount :products="getLength(item.orderLines)" /> -->
                <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" v-if="isDisabled(item)"
                  @parentMethod="archiveItem($event)" :item="item" name="Archive" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->
    <NoData :itemsData="itemsData" />
    <!-- BEGIN: Pagination -->

    <PaginationBar @changePage="changePage($event)" @pageSizeChange="pageSizeChange($event)" :itemsData="itemsData"
      :pageCount="pageCount" :pageSize="pageSizeView" />
  </div>

  <!-- BEGIN: Add Product Modal -->

  <MoveableModal :show="openGenInvoice" @hidden="openGenInvoice = false" backdrop="static" size="modal-xl"
    title="Generate Invoice" @close="closeGenInvoiceForm()">
    <ModalBody style="width: 900px">
      <div class="intro-y col-span-12 overflow-auto">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>Gen.Invoice</th>
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Requested Date</th>
              <th>REQUIRED DATE</th>
              <th>ETA</th>
              <th>Supplier</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in checkedData" :key="i" class="intro-x">
              <td>
                {{ item.orderNumber }}
              </td>

              <td>
                {{ item.requestedDate === null || item.requestedDate === undefined ? "" : momentLocal(item.requestedDate)
                }}
              </td>
              <td>
                {{ item.neededDate === null || item.neededDate === undefined ? "" : momentLocal(item.neededDate) }}
              </td>
              <td>
                {{ item.eta === null || item.eta === undefined ? "" : momentLocal(item.eta) }}
              </td>

              <td>
                {{ item.supplier.company }}
              </td>

              <td>
                <div
                  class="text-white rounded-2xl text-center p-1 pl-2 pr-2 flex items-center justify-center whitespace-nowrap"
                  :style="{ 'background-color': getStatusColor(item.status) }">
                  {{ getStatus(item.status) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeGenInvoiceForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveGenInvoiceForm()" type="submit" class="btn btn-primary whitespace-nowrap">Gen Invoice</button>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal :show="addPurchaseOrder" @hidden="addPurchaseOrder = false" backdrop="static" size="modal-xl"
    :title="formTitle" @close="closeForm()">
    <ModalBody>
      <div v-if="this.titleChange === -1">
        <div class="grid grid-cols-2 lg:gap-4 md:gap-4 gap-2 pb-4">
          <div>
            <!-- <label for="regular-form-1" class="form-label">Status</label> -->

            <div class="flex flex-col space-y-1">
              <!-- <v-select v-model="formDataPurchaseOrder.status" placeholder="SELECT" class="style-chooser" :options="statusItems" :reduce="(name) => name.number" label="name" /> -->

              <Autocomplete v-model:modelValue="formDataPurchaseOrder.status" label="Status" itemText="displayName"
                itemValue="number" :resultObj="false" :infoEnable="false" :items="statusItems" refKey="status" />
            </div>
          </div>

          <div>
            <!-- <label for="regular-form-1" class="form-label">Supplier</label> -->

            <div class="flex flex-col space-y-1">
              <Autocomplete v-model:modelValue="formDataPurchaseOrder.supplierId" label="Supplier" itemText="company"
                itemValue="supplierId" :resultObj="false" :infoEnable="false" @load="loadSupplier" refKey="supplierId" />

              <!-- <v-select
                v-model="formDataPurchaseOrder.supplierId"
                placeholder="SELECT"
                class="style-chooser"
                :filterable="false"
                @open="onOpenSup"
                @close="onCloseSup"
                @search="searchSupplier"
                :key="company"
                :get-option-label="(option) => option.company"
                :options="supplierList"
                :reduce="(company) => company.supplierId"
                label="company"
              >
                <template #list-footer>
                  <li v-show="hasNextPageSup" ref="loadSup" class="loader">Loading more...</li>
                </template>
              </v-select> -->
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Requested Date</label>

            <PreviewComponent class="intro-y">
              <div class="">
                <Preview>
                  <Litepicker v-model="formDataPurchaseOrder.requestedDate" :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }" class="form-control" />
                </Preview>
              </div>
            </PreviewComponent>
          </div>

          <div>
            <label for="regular-form-1" class="form-label capitalize">Required Date</label>

            <PreviewComponent class="intro-y">
              <div class="">
                <Preview>
                  <Litepicker v-model="formDataPurchaseOrder.neededDate" :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }" class="form-control" />
                </Preview>
              </div>
            </PreviewComponent>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Warehouse</label>

            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="warehouseAbbreviation" type="text" class="form-control" disabled />
            </div>
          </div>
        </div>
      </div>
      <div>
        <label for="regular-form-1" class="form-label">ETA</label>

        <PreviewComponent class="intro-y">
          <div class="">
            <Preview>
              <Litepicker v-model="formDataPurchaseOrder.eta" :options="{
                autoApply: true,
                showWeekNumbers: true,
                dropdowns: {
                  minYear: 1990,
                  maxYear: 2050,
                  months: true,
                  years: true,
                },
              }" class="form-control" />
            </Preview>
          </div>
        </PreviewComponent>
      </div>
      <hr />
      <div class="pt-4" v-if="titleChange == -1 && formDataPurchaseOrder.supplierId > 0">
        <h2 class="text-center w-full text-lg font-bold uppercase pb-2">Add Products</h2>

        <div class="w-full sm:w-auto mt-3 sm:mt-0 pl-3 pr-3">
          <div class="flex justify-center items-end space-x-2">
            <div class="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-4 lg:gap-4 md:gap-4 gap-2">
              <div class="w-full lg:text-sm md:text-sm text-xs col-span-4">
                <!-- <label for="regular-form-1" class="form-label font-semibold"> Product Description </label> -->

                <div class="flex flex-col w-full space-y-1">
                  <!-- <v-select
                    v-model="formDataOrderLineNew.description"
                    placeholder="SELECT"
                    class="style-chooser"
                    :filterable="false"
                    @open="onOpenSupProNew"
                    @close="onCloseSupProNew"
                    @search="searchSupProNew"
                    :key="skuDescription"
                    :get-option-label="(option) => option.skuDescription"
                    :options="supplierProductsNew"
                    :reduce="(skuDescription) => skuDescription.skuDescription"
                    label="skuDescription"
                  >
                    <template #list-footer>
                      <li
                        v-show="hasNextPageSupProNew"
                        ref="loadSupProNew"
                        class="loader"
                      >
                        Loading more...
                      </li>
                    </template>
                  </v-select> -->
                  <div class="w-full flex flex-col justify-center items-center space-y-4">
                    <div class="w-full">
                      <Autocomplete ref="orderlinedesc" v-if="renderComponent"
                        v-model:modelValue="formDataOrderLineNew.description" label="Product Description"
                        itemText="skuDescription" itemValue="skuDescription" :resultObj="false"
                        :header="selectionHeadersv2" :infoEnable="true" @load="loadNewSupProV2" refKey="orderlinedesc"
                        :isClicked="isShowDesc" />

                      <!-- <Selection
                        v-model="formDataOrderLineNew.description"
                        key="skuDescription"
                        getOptionLabel="skuDescription"
                        :list="supplierProductsNew"
                        getValue="skuDescription"
                        label="skuDescription"
                        :hasNextPage="hasNextPageSupProNew"
                        :headers="selectionHeaders"
                        :observer="observerSupProNew"
                        :pageNumber="supProNewPageNumber"
                        :isSearchResult="isSupProNewSearchResult"
                        @search="searchSupProNew"
                      /> -->
                    </div>
                    <!-- <div
                      class="w-full grid grid-cols-3 gap-2 p-4 bg-slate-200 text-balck font-semibold"
                      v-if="(formDataOrderLineNew.description !== null || formDataOrderLineNew.description !== undefined) && Object.keys(POObj || {}).length > 0"
                    >
                      <div>
                        {{ POObj.skuDescription === null || POObj.skuDescription === undefined || POObj.skuDescription === "" ? "" : "PLU/SKU DESC  : " + POObj.skuDescription }}
                      </div>
                      <div>
                        {{ POObj.skuName === null || POObj.skuName === undefined || POObj.skuName === "" ? "" : "PLU/SKU  : " + POObj.skuName }}
                      </div>
                      <div>
                        {{
                          POObj.productDescription === null || POObj.productDescription === undefined || POObj.productDescription === ""
                            ? ""
                            : "Product DESC  : " + POObj.productDescription
                        }}
                      </div>
                      <div>
                        {{
                          POObj.supplierCompany === null || POObj.supplierCompany === undefined || POObj.supplierCompany === ""
                            ? ""
                            : "Supplier Company  : " + POObj.supplierCompany
                        }}
                      </div>

                      <div>
                        {{
                          POObj.maxDeliveryTimeInDays === null || POObj.maxDeliveryTimeInDays === undefined || POObj.maxDeliveryTimeInDays === ""
                            ? ""
                            : "Max Delivery Time In Days  : " + POObj.maxDeliveryTimeInDays
                        }}
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="w-full lg:text-sm md:text-sm text-xs">
                <label for="regular-form-1" class="form-label font-semibold"> Quantity </label>

                <div class="flex flex-col w-full space-y-1">
                  <input ref="neworderlineqty" id="vertical-form-1" v-model="formDataOrderLineNew.quantity" type="number"
                    class="form-control" name="quantity" placeholder="0" @keypress.enter="saveFormProductLines()" />
                </div>
              </div>
              <div class="w-full lg:text-sm md:text-sm text-xs col-span-1">
                <label for="regular-form-1" class="form-label font-semibold"> PLU/SKU </label>

                <div class="flex flex-col w-full space-y-1">
                  <div class="flex flex-col w-full space-y-1">
                    <input id="vertical-form-1" disabled v-model="formDataOrderLineNew.skuName" type="text"
                      class="form-control" name="PLU/SKU" placeholder="0" />
                  </div>
                </div>
              </div>

              <div class="w-full lg:text-sm md:text-sm text-xs col-span-2">
                <label for="regular-form-1" class="form-label font-semibold"> Supplier Product Number </label>

                <div class="flex flex-col w-full space-y-1">
                  <input id="vertical-form-1" v-model="formDataOrderLineNew.supplierProductNumber" disabled type="text"
                    class="form-control" name="description" placeholder="Supplier Product Number" />
                </div>
              </div>
            </div>
            <div class="flex justify-end items-end col-span-4">
              <button @click="saveFormProductLines()" type="submit"
                class="btn btn-primary mr-1 px-4 whitespace-nowrap">Add to Cart</button>
            </div>
          </div>
        </div>

        <div class="intro-y col-span-12 overflow-auto p-4" v-if="productLines.length > 0">
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th class="whitespace-nowrap" v-for="(header, i) in productHeaders" :key="i">
                  {{ header.text }}
                </th>
                <th>DESC</th>
                <th>QTY</th>
                <th>ETA</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in productLines" :key="i" class="intro-x">
                <td>
                  {{ i + 1 }}
                </td>
                <td v-for="(header, index) in productHeaders" :key="index">
                  {{ item[header.value] }}
                </td>
                <td>
                  <div class="text-left line-clamp-2">
                    <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description" :options="{
                      zIndex: 99999,
                    }">
                      {{ item.description }}
                    </Tippy>
                  </div>
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  {{ item.eta }}
                </td>

                <td class="table-report__action w-28">
                  <div class="flex justify-center items-center">
                    <a @click="removeProducts(i)" class="flex items-center text-danger" href="javascript:;">
                      <Tippy tag="a" href="javascript:;" class="tooltip" content="Remove" :options="{
                        zIndex: 99999,
                      }">
                        <Trash2Icon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                      </Tippy>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="productLines.length == 0" class="p-5 text-center text-lg">
            <div v-if="productLines.length == 0" class="p-5 text-center text-lg">No Products Added</div>
            <!-- BEGIN: Pagination -->
            <!-- <PaginationBarLocal
            @changePage="changePageProduct($event)"
            @pageSizeChange="pageSizeChangeProduct($event)"
            :itemsData="itemsData"
            :pageCount="pageCountProduct"
            :pageRange="pageRowCountProduct"
            :pageSize="pageSizeProduct"
          /> -->

            <!-- END: Pagination -->
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Product Modal -->

  <!-- BEGIN: Add  Modal For Product-->

  <MoveableModal :show="purchaseOrderInvoiceModal" @hidden="purchaseOrderInvoiceModal = false" backdrop="static"
    size="modal-sl-85" @close="closeFormProduct()">
    <ModalBody class="p-0">
      <div class="flex flex-col space-y-4 w-full p-10 md:p-6">
        <div class="flex justify-between items-start" ref="content" style="letter-spacing: 0.001px">
          <div class="flex flex-col justify-center items-start">
            <!-- <img src="../../../assets/images/pl_logo2.png" alt="logo" class="lg:w-52 lg:h-24 md:w-36 md:h-16 w-28 h-12" /> -->

            <h5 class="mt-3 text-lg font-medium leading-none text-black">
              {{ storeLicensee.companyName || "PHARMATEC PTE LTD" }}
            </h5>
            <h6 class="mt-3 text-sm font-medium leading-none text-black">
              {{ storeLicensee.address || "P O BOX 460 BA" }}
            </h6>

            <h6 class="mt-3 text-sm font-medium leading-none text-black">
              {{ "Ph: " + storeLicensee.mobile || "Ph: 6674933" }}
            </h6>
            <h6 class="mt-3 text-sm font-medium leading-none text-black">
              {{ "Fax: " + storeLicensee.phoneNumber || "Fax: 6670118" }}
            </h6>
            <h6 class="mt-3 text-sm font-medium leading-none text-black">
              {{ "Email: " + storeLicensee.email || "Email: admin@pharmatec.com.fj" }}
            </h6>
          </div>
          <div class="flex flex-col justify-center text-right">
            <!-- <h2 class="lg:text-3xl md:text-2xl text-lg font-medium leading-none mt-3">Purchase Order Number</h2> -->
            <div class="flex flex-col space-y-1">
              <div class="flex items-end justify-end space-x-2">
                <h6 class="text-black font-medium leading-none mt-1 lg:text-2xl md:text-xl text-base"
                  v-if="formDataPurchaseOrder.orderNumber != null || formDataPurchaseOrder.orderNumber != undefined">
                  Purchase Order# : {{ formDataPurchaseOrder.orderNumber }}
                </h6>

                <div v-if="formDataPurchaseOrder.orderNumber != null || formDataPurchaseOrder.orderNumber != undefined"
                  class="self-end mr-8">
                  <qrcode-vue render-as="canvas" :value="formDataPurchaseOrder.orderNumber" size="50" level="H" />
                </div>
              </div>
            </div>

            <div class="grid gap-2 mt-5">
              <div class="flex justify-end items-center lg:text-base text-sm">
                <h6 class="text-sm text-black font-medium leading-none mt-3">
                  <span>
                    {{
                      formDataPurchaseOrder.requestedDate === null || formDataPurchaseOrder.requestedDate === undefined
                      ? ""
                      : ` Requested Date : ` + momentLocal(formDataPurchaseOrder.requestedDate)
                    }}
                  </span>
                </h6>

                <!-- <label for="regular-form-1" class="form-label"
                  >Requested Date :
                  <span>
                    {{ momentLocal(formDataPurchaseOrder.requestedDate) }}
                  </span>
                </label> -->
              </div>

              <div class="flex justify-end items-center lg:text-base text-sm">
                <h6 class="text-sm text-black font-medium leading-none mt-3">
                  <span>
                    {{
                      formDataPurchaseOrder.neededDate === null || formDataPurchaseOrder.neededDate === undefined
                      ? ""
                      : `Require Date : ` + momentLocal(formDataPurchaseOrder.neededDate)
                    }}
                  </span>
                </h6>
                <!-- <label for="regular-form-1" class="form-label"
                  >Needed Date :
                  <span>{{
                    momentLocal(formDataPurchaseOrder.neededDate)
                  }}</span></label
                > -->
              </div>
            </div>

            <h4 class="lg:text-xl md:text-sm text-sm font-medium leading-none mt-3 text-left">To</h4>
            <div class="flex flex-col justify-center text-left">
              <h6 class="text-sm text-black font-medium leading-none"
                v-if="formDataPurchaseOrder.supplier.firstName || formDataPurchaseOrder.supplier.lastName">
                {{ formDataPurchaseOrder.supplier.firstName ? formDataPurchaseOrder.supplier.firstName : "" }}
                {{ formDataPurchaseOrder.supplier.lastName ? formDataPurchaseOrder.supplier.lastName : "" }}
              </h6>
              <h6 class="text-sm text-black font-medium leading-none mt-3" v-if="formDataPurchaseOrder.supplier.company">
                {{ formDataPurchaseOrder.supplier.company }}
              </h6>

              <h6 class="text-sm text-black font-medium leading-none mt-3" v-if="formDataPurchaseOrder.supplier.mobile">
                {{ formDataPurchaseOrder.supplier.mobile }}
              </h6>
              <h6 class="text-sm text-black font-medium leading-none mt-3" v-if="formDataPurchaseOrder.supplier.email">
                {{ formDataPurchaseOrder.supplier.email }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="p-5 text-center text-lg uppercase font-semibold">
        Purchase Order - {{ formDataPurchaseOrder.orderNumber }}
      </div> -->

      <div class="flex pl-6 justify-between items-center">
        <a v-if="!addOrderlineModal && !addSupplierProductModal && formDataPurchaseOrder.status == 1" href="javascript:;"
          @click="showModalProduct()" class="btn btn-primary shadow-md mr-2">
          Add New Product
        </a>

        <div class="flex items-center mb-4">
          <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="newprint">
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>

          <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="newdownload(formDataPurchaseOrder.orderNumber)">
            <FileTextIcon class="w-4 h-4 mr-2" /> Download PDF
          </button>
        </div>
      </div>

      <div class="flex pl-6 justify-end items-center">
        <div class="flex items-center mb-4">
          <div
            class="mr-2 mt-1 text-white rounded-2xl font-medium px-2 py-2 flex items-end justify-center w-full float-right whitespace-nowrap"
            :style="{
              'background-color': getStatusColor(formDataPurchaseOrder.status),
            }">
            Status : {{ getStatus(formDataPurchaseOrder.status) }}
          </div>

          <div class="flex flex-col space-y-1 mr-2">
            <v-select v-model="formDataUpdateStatus.status" placeholder="SELECT" class="style-chooser w-36"
              :options="statusItems" :reduce="(name) => name.number" label="name" />
            <!-- <TomSelect
              v-model="formDataUpdateStatus.status"
              :options="{
                placeholder: 'Search..',
                allowEmptyOption: true,
              }"
              class="w-28"
            >
              <option>{{ statusOption }}</option>
              <option
                :value="item.number"
                v-for="(item, i) in statusItems"
                :key="i"
              >
                {{ item.name }}
              </option>
            </TomSelect> -->
          </div>

          <div @click="updateStatus" class="text-success cursor-pointer">
            <CheckIcon class="w-8 h-8 mr-4 text-success" />
          </div>
        </div>
      </div>

      <div class="w-full sm:w-auto mt-3 sm:mt-0 pl-6 pr-6">
        <div v-if="addOrderlineModal" class="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-4 lg:gap-4 md:gap-4 gap-2">
          <div class="w-full lg:text-sm md:text-sm text-xs col-span-4">
            <!-- <label for="regular-form-1" class="form-label font-semibold"> Product Description </label> -->

            <div class="flex flex-col w-full space-y-1">
              <!-- <TomSelect
                v-model="formDataOrderLine.description"
                :options="{
                  placeholder: 'Search..',
                }"
                class="w-full lg:text-sm md:text-sm text-xs"
              >
                <option>SELECT</option>

                <option
                  :value="item.skuDescription"
                  v-for="(item, i) in supplierProducts"
                  :key="i"
                >
                  {{ item.skuDescription }}
                </option>
              </TomSelect> -->

              <!-- <v-select
                v-model="formDataOrderLine.description"
                placeholder="SELECT"
                class="style-chooser"
                :filterable="false"
                @open="onOpenSupPro"
                @close="onCloseSupPro"
                @search="searchSupPro"
                :key="skuDescription"
                :get-option-label="(option) => option.skuDescription"
                :options="supplierProducts"
                :reduce="(skuDescription) => skuDescription.skuDescription"
                label="skuDescription"
              >
                <template #list-footer>
                  <li
                    v-show="hasNextPageSupPro"
                    ref="loadSupPro"
                    class="loader"
                  >
                    Loading more...
                  </li>
                </template>
              </v-select> -->
              <div class="w-full flex flex-col justify-center items-center space-y-4">
                <div class="w-full">
                  <Autocomplete v-model:modelValue="formDataOrderLine.description" label="Product Description"
                    itemText="skuDescription" itemValue="skuDescription" :resultObj="true" :header="selectionHeadersv2"
                    :infoEnable="true" @load="loadNewSupPro" refKey="desc" :isClicked="isShowDescV2" />

                  <!-- <Selection
                    v-model="formDataOrderLine.description"
                    key="skuDescription"
                    getOptionLabel="skuDescription"
                    :list="supplierProducts"
                    getValue="skuDescription"
                    label="skuDescription"
                    :hasNextPage="hasNextPageSupPro"
                    :headers="selectionHeaders"
                    :observer="observerSupPro"
                    :pageNumber="supProPageNumber"
                    :isSearchResult="isSupProSearchResult"
                    @search="searchSupPro"
                  /> -->
                </div>
                <!-- <div
                  class="w-full grid grid-cols-3 gap-2 p-4 bg-slate-200 text-balck font-semibold"
                  v-if="(formDataOrderLine.description !== null || formDataOrderLine.description !== undefined) && Object.keys(POObj || {}).length > 0"
                >
                  <div>
                    {{ POObj.skuDescription === null || POObj.skuDescription === undefined || POObj.skuDescription === "" ? "" : "PLU/SKU DESC  : " + POObj.skuDescription }}
                  </div>
                  <div>
                    {{ POObj.skuName === null || POObj.skuName === undefined || POObj.skuName === "" ? "" : "PLU/SKU  : " + POObj.skuName }}
                  </div>
                  <div>
                    {{
                      POObj.productDescription === null || POObj.productDescription === undefined || POObj.productDescription === ""
                        ? ""
                        : "Product DESC  : " + POObj.productDescription
                    }}
                  </div>
                  <div>
                    {{
                      POObj.supplierCompany === null || POObj.supplierCompany === undefined || POObj.supplierCompany === "" ? "" : "Supplier Company  : " + POObj.supplierCompany
                    }}
                  </div>

                  <div>
                    {{
                      POObj.maxDeliveryTimeInDays === null || POObj.maxDeliveryTimeInDays === undefined || POObj.maxDeliveryTimeInDays === ""
                        ? ""
                        : "Max Delivery Time In Days  : " + POObj.maxDeliveryTimeInDays
                    }}
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="w-full lg:text-sm md:text-sm text-xs">
            <label for="regular-form-1" class="form-label font-semibold"> Quantity </label>

            <div class="flex flex-col w-full space-y-1">
              <input ref="orderlineqty" id="vertical-form-1" v-model="formDataOrderLine.quantity" type="number"
                class="form-control" name="quantity" placeholder="0" @keypress.enter="saveFormProduct()" />
            </div>
          </div>
          <div class="w-full lg:text-sm md:text-sm text-xs col-span-2">
            <label for="regular-form-1" class="form-label font-semibold"> PLU/SKU </label>

            <div class="flex flex-col w-full space-y-1">
              <div class="flex flex-col w-full space-y-1">
                <input id="vertical-form-1" v-model="formDataOrderLine.skuName" disabled type="text" class="form-control"
                  name="PLU/SKU" placeholder="0" />
              </div>
            </div>
          </div>

          <div class="w-full lg:text-sm md:text-sm text-xs">
            <label for="regular-form-1" class="form-label font-semibold"> Supplier Product Number </label>

            <div class="flex flex-col w-full space-y-1">
              <input id="vertical-form-1" v-model="formDataOrderLine.supplierProductNumber" disabled type="text"
                class="form-control" name="description" placeholder="Supplier Product Number" />
            </div>
          </div>

          <div class="flex justify-end items-end col-span-4">
            <button type="button" @click="closeOrderLineModal()" class="btn btn-outline-secondary w-24 mr-1">
              {{ const_txt.CANCEL_BTN }}
            </button>
            <button @click="saveFormProduct()" type="submit" class="btn btn-primary mr-1 px-8">
              {{ productTitle }}
            </button>
          </div>
        </div>
        <!-- <div
          v-if="addSupplierProductModal"
          class="
            grid
            lg:grid-cols-3
            md:grid-cols-3
            grid-cols-3
            lg:gap-4
            md:gap-4
            gap-2
          "
        >
          <div class="w-full lg:text-sm md:text-sm text-xs">
            <label for="regular-form-1" class="form-label font-semibold">
              Supplier Product Number
            </label>

            <div class="flex flex-col w-full space-y-1">
              <input
                id="vertical-form-1"
                v-model="formDataSupplierProduct.productNumber"
                type="text"
                class="form-control"
                name="productNumber"
                placeholder="Product Number"
              />
            </div>
          </div>

          <div class="w-full lg:text-sm md:text-sm text-xs">
            <label for="regular-form-1" class="form-label font-semibold">
              Quantity
            </label>

            <div class="flex flex-col w-full space-y-1">
              <input
                id="vertical-form-1"
                v-model="formDataOrderLine.quantity"
                type="number"
                class="form-control"
                name="quantity"
                placeholder="0"
              />
            </div>
          </div>

          <div class="w-full lg:text-sm md:text-sm text-xs">
            <label for="regular-form-1" class="form-label font-semibold">
              SKU
            </label>

            <div class="flex flex-col space-y-1">
              <TomSelect
                v-model="formDataSupplierProduct.skuId"
                :options="{
                  placeholder: 'Select..',
                }"
                class="w-full"
                :onChange="addSupplierProductInfo()"
              >
                <option>SELECT</option>
             

                <option :value="item.skuId" v-for="(item, i) in skus" :key="i">
                  {{ item.description }}
                </option>
              </TomSelect>
            </div>
          </div>
 
          <div class="flex justify-end items-end col-span-4">
            <button
              type="button"
              @click="closeAddSupplierProductModal()"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              {{ const_txt.CANCEL_BTN }}
            </button>
            <button
              @click="saveSupplierProducts()"
              type="submit"
              class="btn btn-primary mr-1 px-8"
            >
              Add Supplier Product
            </button>
          </div>
        </div> -->
      </div>
      <hr class="mt-2" />
      <!-- SEARCH -->
      <!-- <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataProduct"
            @change="searchOnchangeProduct"
          />
          <SearchIcon
            @click="searchItemProduct"
            v-if="!searchActiveProduct"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
          />
          <XIcon
            @click="clearSearchProduct"
            v-if="searchActiveProduct"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
          />
        </div>
      </div> -->
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <!-- <div
        class="p-5 text-center text-lg"
        v-if="this.archiveData.totalCount == 0"
      >
        No data
      </div> -->
      <div class="intro-y col-span-12 overflow-auto p-4">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>NO</th>
              <th class="whitespace-nowrap" v-for="(header, i) in productHeaders" :key="i">
                {{ header.text }}
              </th>
              <th>DESC</th>
              <th>QTY</th>
              <th>ETA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in orderLinesLocal" :key="i" class="intro-x">
              <td>
                {{ item.no }}
              </td>
              <td v-for="(header, index) in productHeaders" :key="index">
                {{ item[header.value] }}
              </td>
              <td>
                <div class="text-left line-clamp-2">
                  <Tippy tag="a" href="javascript:;" class="tooltip" :content="item.description" :options="{
                    zIndex: 99999,
                  }">
                    {{ item.description }}
                  </Tippy>
                </div>
              </td>
              <td>
                {{ item.quantity }}
              </td>
              <td>
                {{ item.eta }}
              </td>

              <td class="table-report__action">
                <div class="flex justify-center items-center">
                  <!-- formDataPurchaseOrder.status == 1 -->

                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" v-if="formDataPurchaseOrder.status == 1"
                    @parentMethod="deleteOrdeLine($event)" :item="item" name="Delete" />

                  <!-- <a
                    class="flex items-center text-danger"
                    href="javascript:;"
                    @click="archiveItemOption(item)"
                  >
                    <ArchiveIcon class="w-4 h-4 mr-1" /> Archive
                  </a> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="orderLines.length == 0" class="p-5 text-center text-lg">No Products Added</div>
        <!-- BEGIN: Pagination -->
        <PaginationBarLocal @changePage="changePageProduct($event)" @pageSizeChange="pageSizeChangeProduct($event)"
          :itemsData="itemsData" :pageCount="pageCountProduct" :pageRange="pageRowCountProduct"
          :pageSize="pageSizeProduct" />

        <!-- END: Pagination -->
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <!-- <div
        class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
        style="background-color: #164e63"
      >
        <div
          class="w-full sm:w-auto sm:mr-auto"
          style="
            background-color: #e1e7ef;
            border: 2px solid #164e63;
            border-radius: 10px;
          "
        >
          <paginate
            class="text-white"
            :page-count="pageCount"
            :page-range="5"
            :margin-pages="2"
            :click-handler="changePageProduct"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :first-last-button="true"
            :initial-page="initialPage"
            :prev-text="'<'"
            :next-text="'>'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
          >
          </paginate>
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select
          v-model="pageSizeProduct"
          class="w-20 form-select box cursor-pointer"
          @change="pageSizeChangeProduct"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> -->

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>
  <!-- END: Add Modal For Product-->

  <!-- BEGIN: Archive Modal -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="text-danger float-right lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6 cursor-pointer"
          @click="archiveModal = false" />
      </div>

      <div class="p-5 text-center lg:text-lg text-base uppercase font-semibold">Archived Purchase Orders</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input type="text" class="form-control w-full box pr-10" placeholder="Search..." v-model="searchDataArchive"
            @change="searchOnchangeArchive" clearable />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true"
            class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <hr />
      <!-- BEGIN: Archive Data List -->
      <div class="mb-5 p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
              <th>Order Number</th>
              <th>Requested Date</th>
              <th>REQUIRED DATE</th>
              <th>Supplier</th>
              <th>ETA</th>
              <!-- <th>Picking Type</th> -->
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] }}
              </td>
              <td class="cursor-pointer">
                {{ item.orderNumber }}
              </td>

              <td>
                {{ item.requestedDate === null || item.requestedDate === undefined ? "" : momentLocal(item.requestedDate)
                }}
              </td>
              <td>
                {{ item.neededDate === null || item.neededDate === undefined ? "" : momentLocal(item.neededDate) }}
              </td>
              <td>
                {{ item.eta === null || item.eta === undefined ? "" : momentLocal(item.eta) }}
              </td>

              <td>{{ item.supplier.firstName }} {{ item.supplier.lastName }}</td>
              <!-- <td>
                <div
                  class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center"
                  :style="getPickingTypeColor(item.pickingType)"
                >
                  {{ getPickingType(item.pickingType) }}
                </div>
              </td> -->

              <td>
                <div class="text-white rounded-2xl p-1 pl-2 pr-2 flex items-center justify-center whitespace-nowrap"
                  :style="{ 'background-color': getStatusColor(item.status) }">
                  {{ getStatus(item.status) }}
                </div>
              </td>

              <td class="table-report__action">
                <div class="flex justify-center items-center">
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)"
                    :item="item" name="Restore" />

                  <!-- <a
                    class="flex items-center mr-3 font-bold"
                    href="javascript:;"
                    @click="viewOrderInfo(item)"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="INFO"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <InfoIcon
                        class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1"
                      />
                    </Tippy>
                  </a> -->
                  <!-- <a
                    @click="restoreItem(item)"
                    class="flex items-center mr-3 text-blue-900"
                    href="javascript:;"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Restore"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <RotateCcwIcon class="w-6 h-6 mr-1" />
                    </Tippy>
                  </a> -->

                  <!-- <a
                    class="flex items-center text-danger"
                    href="javascript:;"
                    @click="deleteItem(item)"
                  >
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Delete"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <Trash2Icon class="w-6 h-6 mr-1" />
                    </Tippy>
                  </a> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->

      <PaginationBar @changePage="changePageArchive($event)" @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData" :pageCount="pageCountArchive" :pageSize="pageSizeArchive" />
      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataPurchaseOrder.orderNumber" :modalStatus="archiveConfirmationModal"
    :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataPurchaseOrder.orderNumber" :modalStatus="restoreConfirmationModal"
    :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataOrderLine.description?.skuDescription" :modalStatus="deleteConfirmationModal"
    :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />

  <DeleteConfirmModal :name="formDataPurchaseOrder.orderNumber" :modalStatus="deleteOrderLineModel"
    :modalConfirm="deleteOrderLineConfirm" :modalClose="closeDeleteOrderLine" />
  <!-- END: Confirmation Modals -->

  <!-- BEGIN: Delete Confirmation Modal -->

  <!-- <Modal :show="deleteOrderLineModel" @hidden="deleteOrderLineModel = false">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <Trash2Icon class="w-16 h-16 text-danger mx-auto mt-3" />
        <div class="text-3xl mt-5">Are you sure?</div>
        <div class="text-xl font-bold mt-5">
          {{ formDataOrderLine.description }}
        </div>
        <div class="text-slate-500 mt-2">
          Do you really want to delete these records?
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteOrderLineModel = false"
          class="btn btn-outline-secondary w-24 mr-1"
        >
          {{ const_txt.CANCEL_BTN }}
        </button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="deleteOrderLineConfirm()"
        >
          Delete
        </button>
      </div>
    </ModalBody>
  </Modal> -->

  <!-- END: Delete Confirmation Modal -->

  <!-- BEGIN: View Info Modal -->

  <MoveableModal :show="orderInfoModal" @hidden="orderInfoModal = false" backdrop="static" size="modal-lg" title="INFO"
    @close="orderInfoModal = false">
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-6">
        <div class="p-5 text-center">
          <BoxIcon class="w-8 h-8 text-danger mx-auto mt-2" />

          <div class="text-xl font-bold mt-5">
            {{ formDataPurchaseOrder.orderNumber }}
          </div>
        </div>
        <div class="intro-y box p-5 mt-12 sm:mt-5">
          <div class="flex text-slate-500 border-b border-slate-200 dark:border-darkmode-300 border-dashed pb-3 mb-3">
            <div>Parameters</div>
            <div class="ml-auto">Order Values</div>
          </div>

          <!-- <div class="flex items-center mb-5">
            <div class="flex items-center">
              <div>Picking Type</div>
            </div>
            <div class="ml-auto">{{ formDataPurchaseOrder.pickingType }}</div>
          </div> -->

          <div class="flex items-center mb-5">
            <div class="flex items-center">
              <div>Warehouse</div>
            </div>
            <div class="ml-auto">{{ formDataPurchaseOrder.warehouseId }}</div>
          </div>
          <div class="flex items-center mb-5">
            <div class="flex items-center">
              <div>Status</div>
            </div>
            <div class="ml-auto">{{ formDataPurchaseOrder.status }}</div>
          </div>

          <!-- <button
              class="btn btn-outline-secondary w-full border-slate-300 dark:border-darkmode-300 border-dashed relative justify-start mb-2"
            >
              <span class="truncate mr-5">View Full Report</span>
              <span
                class="w-8 h-8 absolute flex justify-center items-center right-0 top-0 bottom-0 my-auto ml-auto mr-0.5"
              >
                <ArrowRightIcon class="w-4 h-4" />
              </span>
            </button> -->
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
  <!-- END: View Info Modal-->

  <!-- <AddSkuModal addSku="true"/> -->
  <AddConfirmModal name="Do you want to add this item again" :modalStatus="addConfirmationModal"
    :modalConfirm="addItemConfirm" :modalClose="closeAdd" />
  <AddConfirmModal name="Do you want to add this item again" :modalStatus="addConfirmationModalV2"
    :modalConfirm="addItemConfirmV2" :modalClose="closeAddV2" />

  <ConfirmAlert :modalStatus="checkOrderLinesAlert" :modalConfirm="checkOrderLinesAlertConfirm"
    :modalClose="closeCheckOrderLinesAlert" :isClose="true" icon="PackageOpenIcon" color="warning"
    :question="`Order cannot be saved without order lines`" nameBtn="Add Order Line" />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";
import OrderDetails from "./components/OrderDetails.vue";
import { createVNode, render } from 'vue';

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import moment from "moment";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

//import AddSkuModal from "../modals/Add Sku.vue"
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";
import { customerOrderStatus, customerOrderStatusColor, getPickingType, getPickingTypeColor } from "../../../global-functions/status";
import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";
import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Purchase Orders");
    this.observerSup = new IntersectionObserver(this.infiniteScrollSup);
    this.observerSupPro = new IntersectionObserver(this.infiniteScrollSupPro);
    this.observerSupProNew = new IntersectionObserver(this.infiniteScrollSupProNew);
  },
  components: {
    autoTable,
    OrderDetails
    // AddSkuModal
  },
  data() {
    return {
      checkOrderLinesAlert: false,
      storeLicensee: store.state.authUser.licensee,
      POObj: {},
      selectionHeaders: [
        { title: "PRODUCT DESC", width: "w-28", value: "productDescription" },
        { title: "PRODUCT#", width: "w-16", value: "productNumber" },
        { title: "PLU/SKU DESC", width: "w-28", value: "skuDescription" },
        { title: "PLU/SKU", width: "w-16", value: "skuName" },
        { title: "PRICE", width: "w-16", value: "price" },
      ],
      selectionHeadersv2: [
        { text: "PLU/SKU DESC", value: "skuDescription" },
        { text: "Supplier Company", value: "supplierCompany" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "PRICE", value: "price" },
        { text: "Max Delivery Days", value: "maxDeliveryTimeInDays" },
        { text: "PRODUCT DESC", value: "productDescription" },
        { text: "PRODUCT#", value: "productNumber" },
      ],
      tempPurchaseOrderId: 0,
      addConfirmationModal: false,
      addConfirmationModalV2: false,
      supplierProductsNew: [],
      tempSupplierId: 0,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      const_txt: const_txt,
      doc: null,

      const_txt: const_txt,

      isLoading: false,
      searchData: "",

      orderInfoModal: false,

      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addPurchaseOrder: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,

      deleteOrderLineModel: false,

      titleChange: -1,
      onePurchaseOrder: {
        orderNumber: "",
        requestedDate: "",
        neededDate: "",
        supplier: { name: "", address: "" },
      },

      formDataPurchaseOrder: {
        eta: "",
        orderNumber: "",
        requestedDate: "",
        neededDate: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
        status: 1,
        pickingType: 0,
        supplierId: "",
        supplier: { name: "", address: "" },
      },
      formDataPurchaseOrderDefault: {
        eta: "",
        orderNumber: "",
        requestedDate: "",
        neededDate: "",
        warehouseId: store.state.authUser.warehouse.warehouseId,
        status: 1,
        pickingType: 0,
        supplierId: undefined,
        supplier: { name: "", address: "" },
      },

      headers: [
        // { text: "ID", value: "purchaseOrderId" },
        // { text: "Order Number", value: "orderNumber" },
        // { text: "Requested Date", value: "requestedDate" },
        // { text: "Needed Date", value: "neededDate" },
      ],

      headersFilter: [
        { text: "Order Number", value: "orderNumber" },
        { text: "Requested Date", value: "requestedDate" },
        { text: "Needed Date", value: "neededDate" },
        { text: "Supplier", value: "supplier" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,
      productLines: [],
      items: [],
      itemsData: [],
      supplierItems: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      paginationArchiveDefault: {
        pageNumber: 1,
        pageSize: 5,
      },

      pageCountArchive: 20,
      pageSizeArchive: 5,

      //supplier

      supplierList: [],

      //Warehouse
      warehouseList: [],

      //Product
      poId: "",
      productChange: -1,
      searchDataProduct: "",
      purchaseOrderInvoiceModal: false,
      addOrderlineModal: false,
      formDataOrderLine: {
        supplierProductNumber: "",
        description: "",
        skuId: "",
        skuName: "",
        quantity: "",
        purchaseOrderId: "",
        orderNumber: "",
      },
      formDataOrderLineDefault: {
        supplierProductNumber: "",
        description: undefined,
        skuId: "",
        quantity: "",
        purchaseOrderId: "",
      },
      formDataOrderLineNew: {
        supplierProductNumber: "",
        description: "",
        skuId: "",
        skuName: "",
        quantity: "",
        purchaseOrderId: "",
        orderNumber: "",
      },
      formDataOrderLineNewDefault: {
        supplierProductNumber: "",
        description: undefined,
        skuId: "",
        quantity: "",
        purchaseOrderId: "",
      },
      paginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      defaultPaginationProduct: {
        pageNumberProduct: 1,
        pageSizeProduct: 10,
        searchProduct: "",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      productHeaders: [
        // { text: "OL ID", value: "purchaseOrderLineId" },
        // { text: "PO ID", value: "purchaseOrderId" },

        // { text: "Product Id", value: "productId" },
        // { text: "SKU", value: "skuId" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "Supplier Product Number", value: "supplierProductNumber" },
        // { text: "QTY", value: "quantity" },
        // { text: "Description", value: "description" },
      ],
      productItems: [],
      productItemsData: [],

      skus: [],
      supplierProducts: [],
      products: [],
      purchaseOrders: [],
      orderNumber: "",
      orderLines: [],
      serverError: false,

      pageCountProduct: 0,
      pageRowCountProduct: 5,
      productStart: 0,
      productEnd: 5,
      orderLinesLocal: [],

      addSupplierProductModal: false,
      formDataSupplierProduct: {
        productNumber: "",
        supplierId: "",
        skuId: "",
        productDescription: "",
      },

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,

      statusItems: store.state.authUser.PurchaseOrderStatus,
      pickingTypeItems: [],

      formDataUpdateStatus: {
        status: "",
        orderNumber: "",
      },

      formDataUpdateStatusDefault: {
        status: "",
        orderNumber: "",
      },

      statusOption: "CHANGE",
      splitData: false,
      allSelected: false,
      checkedData: [],

      policyAccess: "PurchaseOrders",

      observerSup: null,
      limitSup: 5,
      hasNextPageSup: false,
      totalSupCount: 0,
      isSupSearchResult: false,
      supPageNumber: 1,
      supSearchTimer: undefined,

      observerSupPro: null,
      limitSupPro: 5,
      hasNextPageSupPro: false,
      totalSupProCount: 0,
      isSupProSearchResult: false,
      supProPageNumber: 1,
      supProSearchTimer: undefined,

      observerSupProNew: null,
      limitSupProNew: 5,
      hasNextPageSupProNew: false,
      totalSupProNewCount: 0,
      isSupProNewSearchResult: false,
      supProNewPageNumber: 1,
      supProNewSearchTimer: undefined,

      openGenInvoice: false,
      genOrderLines: [],
      newSupPro: [],
      renderComponent: true,
      isShowDesc: false,
      isShowDescV2: false,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Purchase Order" : "Edit ETA";
    },
    productTitle() {
      return this.productChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },

    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },

    formDataOrderLineDescription() {
      return this.formDataOrderLine.description;
    },
    formDataOrderLineDescriptionNew() {
      return this.formDataOrderLineNew.description;
    },

    checkLimtSup() {
      return this.supPageNumber;
    },
    checkLimtSupPro() {
      return this.supProPageNumber;
    },
    checkLimtSupProNew() {
      return this.supProNewPageNumber;
    },
    viewModalSupPro() {
      return this.formDataOrderLine.description;
    },
    viewModalSupProNew() {
      return this.formDataOrderLineNew.description;
    },
    changeSupplierid() {
      return this.formDataPurchaseOrder.supplierId;
    },
  },
  watch: {
    viewModalSupPro(val) {
      if (!val) {
        this.clearSupPro();
        this.POObj = null;
      } else {
        this.getDetails(val, this.supplierProducts);
        this.formDataOrderLine.quantity = "";
        this.$nextTick(() => this.$refs.orderlineqty.focus());
      }
    },

    viewModalSupProNew(val) {
      if (!val) {
        this.clearSupProNew();
        this.POObj = null;
      } else {
        this.getDetails(val, this.supplierProductsNew);
        this.formDataOrderLineNew.quantity = "";
        this.$nextTick(() => this.$refs.neworderlineqty.focus());
      }
    },
    changeSupplierid(val) {
      this.setDefaultProductNew();
      this.supplierProductsNew = [];
      this.formDataPurchaseOrder.supplierId = val;
      this.forceRerender();
      //this.getsupplierProductData();
      // this.clearSupplier();
      // this.clearSupProNew();

      // this.tempSupplierId = this.formDataPurchaseOrder.supplierId;
    },
    // tempSupplierId(){
    //   this.getsupplierProductData();
    // },
    checkedData() {
      console.log(this.items);
      for (var i = 0; i < this.items.length; i++) {
        let res = this.checkedData.includes(this.items[i]);
        if (res) {
          this.items[i].selected = true;
        } else {
          this.items[i].selected = false;
        }
      }

      // for(var i=0; i< this.checkedData.length; i++){
      //   this.checkedData[i].selected = true
      //   console.log(this.checkedData[i].selected)
      // }
    },
    purchaseOrderInvoiceModal(val) {
      val || this.purchaseOrderInvoiceModalClose();
      this.orderLinesLocal = [];
      this.formDataOrderLine = Object.assign({}, this.formDataOrderLineDefault);
      this.formDataOrderLineNew = Object.assign({}, this.formDataOrderLineNewDefault);
    },
    formDataOrderLineDescription() {
      this.getSupplierProductInfo();
    },
    formDataOrderLineDescriptionNew() {
      this.formDataOrderLineNew.quantity = "";
      this.getSupplierProductInfoNew();
    },
    checkLimtSup() {
      this.getSupplierData();
    },
    checkLimtSupPro() {
      this.getSupplierProducts();
    },
    checkLimtSupProNew() {
      this.getsupplierProductData();
    },
  },
  created() {
    this.getDataFromApi();
    // this.getDataFromPickingType();
  },
  methods: {
    closeCheckOrderLinesAlert() {
      this.checkOrderLinesAlert = false;
    },
    checkOrderLinesAlertConfirm() {
      this.checkOrderLinesAlert = false;
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    genInvoice() {
      if (this.checkedData.length <= 0) {
        failedToast("PLEASE SELECT PURCHASE ORDER");
      } else {
        this.openGenInvoice = true;
      }
    },
    closeGenInvoiceForm() {
      this.checkedData = [];
      this.openGenInvoice = false;
    },
    async getOrderLinesForGen(orderNumber) {
      try {
        let response = await axios.get("PurchaseOrders/" + this.warehouseAbbreviation + "/" + orderNumber);
        return response.data.data.orderLines;
      } catch (error) { }
    },
    saveGenInvoiceForm() {
      if (this.checkedData.length <= 0) {
        failedToast("PLEASE SELECT PURCHASE ORDER");
      } else {
        this.checkedData.forEach((x) => {
          axios
            .get("PurchaseInvoices/" + this.warehouseAbbreviation + "/" + x?.orderNumber)
            .then(async (response) => {
              if (response.data.success) {
                if (response.data.data) {
                  warningToast(`Gen.Invoice : ${x?.orderNumber} is already exists`);
                }
              } else {
                let genOrderLines = await this.getOrderLinesForGen(x?.orderNumber);
                console.log("genOrderLines", genOrderLines);
                var listOrderLines = [];
                let address = x?.supplier.address
                  ? x?.supplier.address + x?.supplier.postcode
                    ? ", " + x?.supplier.postcode
                    : "" + x?.supplier.state
                      ? ", " + x?.supplier.state
                      : "" + x?.supplier.city
                        ? ", " + x?.supplier.city
                        : "" + x?.supplier.country
                          ? ", " + x?.supplier.country
                          : ""
                  : "";

                var formDataInvoice = {
                  invoiceNumber: x?.orderNumber,
                  orderNumber: x?.orderNumber,
                  invoiceDate: x?.requestedDate,
                  receivedDate: "",
                  location: x?.supplier ? address : "",
                  status: x?.status,
                  shipVia: "",
                  salesRep: "",
                  currency: x?.supplier ? x?.supplier?.currency || "" : "",
                  supplierId: x?.supplier.supplierId,
                  warehouseId: this.warehouseStoreId,
                };

                axios
                  .post("PurchaseInvoices", formDataInvoice)
                  .then((response_save) => {
                    if (response_save.data.success === true) {
                      successToast(response_save.data.message);

                      if (genOrderLines && genOrderLines?.length > 0) {
                        genOrderLines.forEach((ol) => {
                          if (ol) {
                            var skuVal;
                            var supplierProductNumberVal;

                            if (ol?.skuName) {
                              skuVal = ol?.skuName;
                            } else {
                              skuVal = null;
                            }

                            if (ol?.supplierProductNumber) {
                              supplierProductNumberVal = ol?.supplierProductNumber;
                            } else {
                              supplierProductNumberVal = null;
                            }

                            if (!ol?.skuName && !ol?.supplierProductNumber) {
                              failedToast("PLU/SKU and Supplier Product Number both are empty");
                            } else {
                              listOrderLines.push({
                                description: ol.description,
                                quantity: ol.quantity,
                                unitPrice: null,
                                discountPercentage: null,
                                vat: null,
                                productionDate: null,
                                expirationDate: null,
                                supplierProductNumber: supplierProductNumberVal,
                                sku: skuVal,
                                productDescription: ol.description,
                                invoiceId: response_save.data.data.invoiceId,
                                warehouseId: this.warehouseStoreId,
                              });
                            }
                          }
                        });

                        axios
                          .post("PurchaseInvoices/Products", listOrderLines)
                          .then((response2) => {
                            if (response2.data.success === true) {
                              //successToast(response2.data.message);
                            } else {
                              failedToast(response2.data.message);
                            }
                            this.isLoading = false;
                          })
                          .catch((error2) => {
                            this.isLoading = false;
                            warningToast(statusMessage(error2.response2.status));
                          });
                      }

                      this.closeGenInvoiceForm();
                    } else {
                      failedToast(response_save.data.message);
                    }
                    this.isLoading = false;
                  })
                  .catch((error_save) => {
                    this.isLoading = false;
                    warningToast(statusMessage(error_save.response_save.status));
                  });
              }
              this.isLoading = false;
              // successToast(response.data.message);
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        });
      }
    },
    async getDetails(val, list) {
      return (this.POObj = await list.find((x) => x.skuDescription === val));
    },
    clearSupProNew() {
      this.supplierProductsNew = [];
      this.supProNewPageNumber = 1;
      this.limitSupProNew = 5;
      this.getsupplierProductData("");
    },
    searchSupProNew(search) {
      clearInterval(this.supProNewSearchTimer);
      this.supProNewSearchTimer = setTimeout(() => {
        if (search) {
          this.supplierProductsNew = [];
          this.supProNewPageNumber = 1;
          this.limitSupProNew = this.totalSupProNewCount;
          this.getsupplierProductData(search.trim());
        }
      }, 2000);
    },

    getsupplierProductData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.supProNewPageNumber,
        pageSize: this.limitSupProNew,
        search: search,
      };

      var supplierId = this.formDataPurchaseOrder.supplierId != null ? this.formDataPurchaseOrder.supplierId : 0;

      axios
        .post("SupplierProducts/" + supplierId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSupProNewSearchResult = !!search;
            this.supplierProductsNew =
              this.isSupProNewSearchResult !== true
                ? Array.from(new Set([...this.supplierProductsNew, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSupProNew = response.data.data.hasNextPage;
            this.totalSupProNewCount = response.data.data.totalCount;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => { });
    },
    async onOpenSup() {
      if (this.isSupSearchResult) {
        this.supPageNumber = 1;
      }
      if (this.hasNextPageSup) {
        await this.$nextTick();
        this.observerSup.observe(this.$refs.loadSup);
      }
    },
    onCloseSup() {
      this.observerSup.disconnect();
    },
    async infiniteScrollSup([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supPageNumber++;
        this.limitSup;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSupPro() {
      if (this.isSupProSearchResult) {
        this.supProPageNumber = 1;
      }
      if (this.hasNextPageSupPro) {
        await this.$nextTick();
        this.observerSupPro.observe(this.$refs.loadSupPro);
      }
    },
    onCloseSupPro() {
      this.observerSupPro.disconnect();
    },
    async infiniteScrollSupPro([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supProPageNumber++;
        this.limitSupPro;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSupProNew() {
      if (this.isSupProNewSearchResult) {
        this.supProNewPageNumber = 1;
      }
      if (this.hasNextPageSupProNew) {
        await this.$nextTick();
        this.observerSupProNew.observe(this.$refs.loadSupProNew);
      }
    },
    onCloseSupProNew() {
      this.observerSupProNew.disconnect();
    },
    async infiniteScrollSupProNew([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supProNewPageNumber++;
        this.limitSupProNew;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    isDisabled(item) {
      if (item.status === 1) {
        return true;
      } else {
        return false;
      }
    },

    getLength(orderLines) {
      return orderLines != null ? orderLines.length : 0;
    },
    updateStatus() {
      if (this.formDataUpdateStatus.status == this.statusOption || this.formDataUpdateStatus.status == "") {
        warningToast("Select a status");
      } else {
        this.formDataPurchaseOrder.status = this.formDataUpdateStatus.status;
        this.formDataUpdateStatus.orderNumber = this.orderNumber;

        this.isLoading = true;
        axios
          .post("PurchaseOrders/" + this.warehouseStoreId + "/Status", this.formDataUpdateStatus)
          .then((response) => {
            if (response.data.success === true) {
              successToast(response.data.message);
              this.getDataFromApi();
              this.isLoading = false;
            } else {
              warningToast(response.data.message);
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
      }
    },
    newprint() {
      const headers = [{
        text: "PLU/SKU", value: "skuId"
      }, {
        text: "DESCRIPTION", value: "description"
      }, {
        text: "QTY", value: "quantity"
      }, {
        text: "ETA", value: "eta"
      },]

      const items = this.orderLines.map((x) => ({
        skuId: x.skuId,
        quantity: x.quantity,
        eta: x.eta,
        description: x.description,
      }));

      const node = createVNode(OrderDetails, {
        headers, items,
        company: this.storeLicensee?.companyName || "PHARMATEC PTE LIMITED",
        companyAddress: this.storeLicensee?.address || "P.O.Box 450,Ba",
        companyMobile: this.storeLicensee?.mobile || "6674933/6674533",
        companyPhone: this.storeLicensee?.phoneNumber || "6670118",
        companyEmail: this.storeLicensee?.email || "admin@pharmatec.com.fj",

        supplier: this.formDataPurchaseOrder.supplier.company,
        orderNo: this.formDataPurchaseOrder.orderNumber,
        requestedDate: this.momentLocal(this.formDataPurchaseOrder.requestedDate),
      });

      const container = document.createElement('div');
      render(node, container);
      const styles = require('!!raw-loader!./components/OrderDetails.css');

      var printWindow = window.open('', '', 'left=0,top=0,width=800,height=1000,toolbar=0,scrollbars=0,status=0');
      printWindow.document.write(
        "<!DOCTYPE html>" +
        "<html>" +
        " <head>" +
        '  <meta charset="utf-8" />' +
        "  <title>Receipt</title>" +
        "  <style> " + styles.default + " @media print { body { - webkit-print-color-adjust: exact !important; color-adjust: exact !important; print-color-adjust: exact !important;}}</style >" +
        " </head>" +
        ' <body>');
      printWindow.document.write(container.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
      return;

      var doc = new jsPDF("p", "pt", "a4");

      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();

      this.$refs.content.style.fontFamily = "Balsamiq-Sans, serif"
      doc.html(this.$refs.content, {
        callback: function (doc) {
          doc.autoPrint();
          doc.output("dataurlnewwindow");
        },
        margin: [5, 5, 5, 5],
        x: 35,
        y: 0,
        html2canvas: {
          scale: 0.6,
          width: docWidth,
        },
      });
      const columns = [
        { title: "PLU/SKU", dataKey: "skuId" },
        { title: "SUPPLIER PRODUCT NO", dataKey: "supplierProductNumber" },
        { title: "QTY", dataKey: "quantity" },
        { title: "ETA", dataKey: "eta" },
        { title: "DESCRIPTION", dataKey: "description" },
      ];

      let data = [];
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          skuId: el.skuId,
          supplierProductNumber: el.supplierProductNumber,
          quantity: el.quantity,
          eta: el.eta,
          description: el.description,
        };
        data.push(obj);
      });

      autoTable(doc, {
        theme: "grid",
        columns,
        body: data,
        headerStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        showHead: "firstPage",
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 200,
        margin: { left: 35 },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        doc.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;
        doc.setFontSize(10);
        doc.text(footer, pageWidth / 2 - doc.getTextWidth(footer) / 2, pageHeight - 5, { baseline: "bottom" });
        this.$refs.content.style.fontFamily = "Roboto"
      }
    },
    newdownload(orderNumber) {
      var generateDate = new Date().toLocaleString();
      var doc = new jsPDF("p", "pt", "a4");

      var docWidth = doc.internal.pageSize.getWidth();
      var docHeight = doc.internal.pageSize.getHeight();

      this.$refs.content.style.fontFamily = "Balsamiq-Sans, serif"
      doc.html(this.$refs.content, {
        callback: function (doc) {
          doc.save(`${orderNumber + "_" + generateDate}.pdf`);
        },
        margin: [5, 5, 5, 5],
        x: 35,
        y: 0,
        html2canvas: {
          scale: 0.6,
          width: docWidth,
        },
      });
      const columns = [
        { title: "PLU/SKU", dataKey: "skuId" },
        { title: "SUPPLIER PRODUCT NO", dataKey: "supplierProductNumber" },
        { title: "QTY", dataKey: "quantity" },
        { title: "ETA", dataKey: "eta" },
        { title: "DESC", dataKey: "description" },
      ];

      let data = [];
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          skuId: el.skuId,
          supplierProductNumber: el.supplierProductNumber,
          quantity: el.quantity,
          eta: el.eta,
          description: el.description,
        };
        data.push(obj);
      });

      autoTable(doc, {
        theme: "grid",
        columns,
        body: data,
        headerStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        showHead: "firstPage",
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 200,
        margin: { left: 40 },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (var z = 1; z <= pageCount; z++) {
        doc.setPage(z);
        // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // const header = 'Report 2014';
        const footer = `Page ${z} of ${pageCount}`;
        doc.setFontSize(10);
        doc.text(footer, pageWidth / 2 - doc.getTextWidth(footer) / 2, pageHeight - 5, { baseline: "bottom" });
        this.$refs.content.style.fontFamily = "Roboto"
      }
    },

    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    getStatusColor(value) {
      try {
        return this.statusItems.find((x) => x.number == value).color;
      } catch {
        return "";
      }
    },
    getStatus(value) {
      try {
        return this.statusItems.find((status) => status.number == parseInt(value)).displayName;
      } catch {
        return "";
      }
    },
    // getDataFromPickingType() {
    //   axios
    //     .get("Enums/PickingTypes")
    //     .then((response) => {
    //       this.pickingTypeItems = response.data;
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //     });
    // },
    // getPickingTypes(value) {
    //   try {
    //     return this.pickingTypeItems.find((x) => x.number == parseInt(value))
    //       .displayName;
    //   } catch {
    //     return "";
    //   }
    // },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Order Number", dataKey: "orderNumber" },
              { title: "Requested Date", dataKey: "requestedDate" },
              { title: "Require Date", dataKey: "neededDate" },
              { title: "Supplier", dataKey: "company" },
              { title: "Status", dataKey: "status" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                orderNumber: el.orderNumber,
                requestedDate: this.momentLocal(el.requestedDate),
                neededDate: this.momentLocal(el.neededDate),
                company: el.supplier.company,
                status: this.getStatus(el.status),
              };

              data.push(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              // const header = 'Report 2014';
              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },

    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post("PurchaseOrders/Paginated" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },

    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Purchase Orders " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Purchase Orders " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Order Number",
          2: "Requested Date",
          3: "Require Data",
          4: "Supplier",
          5: "Status",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.orderNumber,
            2: this.momentLocalCSV(item.requestedDate),
            3: this.momentLocalCSV(item.neededDate),
            4: item.supplier.company,
            5: this.getStatus(item.status),
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("PurchaseOrders/Paginated/" + this.warehouseAbbreviation, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            this.supplierItems = response.data.data.items.supplier;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }

          // this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    // getPickingType(status) {
    //   return getPickingType(status);
    // },
    getPickingTypeColor(status) {
      return getPickingTypeColor(status);
    },
    customerOrderStatus(status) {
      return customerOrderStatus(status);
    },
    customerOrderStatusColor(status) {
      return customerOrderStatusColor(status);
    },
    setReport() {
      const columns = [
        { title: "PLU/SKU", dataKey: "skuId" },
        { title: "SUPPLIER PRODUCT NO", dataKey: "supplierProductNumber" },
        { title: "QTY", dataKey: "quantity" },
        { title: "ETA", dataKey: "eta" },
        { title: "DESC", dataKey: "description" },
      ];
      this.doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "a4",
      });

      let data = [];
      let i = 0;
      let obj;

      this.orderLines.forEach((el) => {
        obj = {
          sku: el.sku,
          supplierProductNumber: el.supplierProductNumber,
          quantity: el.quantity,
          eta: el.eta,
          description: el.description,
        };
        //i++;
        data.push(obj);
        //   console.log(obj);
      });
      //console.log("DATA", data);

      var orderNumber = this.formDataPurchaseOrder.orderNumber;
      var fname = this.formDataPurchaseOrder.supplier.firstName;
      var mobile = this.formDataPurchaseOrder.supplier.mobile;
      var lname = this.formDataPurchaseOrder.supplier.lastName;
      var company = this.formDataPurchaseOrder.supplier.company;
      var email = this.formDataPurchaseOrder.supplier.email;
      var neededDate = this.momentLocal(this.formDataPurchaseOrder.neededDate);
      var requestedDate = this.momentLocal(this.formDataPurchaseOrder.requestedDate);

      if (!fname) {
        fname = "";
      }
      if (!lname) {
        lname = "";
      }
      if (!company) {
        company = "";
      }
      if (!mobile) {
        mobile = "";
      }
      if (!email) {
        email = "";
      }
      if (!neededDate) {
        neededDate = "";
      }
      if (!requestedDate) {
        requestedDate = "";
      }

      var imgData = "/src/assets/images/pl_logo2.png";
      this.doc.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
      this.doc.setFontSize(16).text("Order Number : " + orderNumber, 5, 1.2);

      this.doc.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2);

      this.doc.setFontSize(14).text("Deliver To :", 0.5, 2.5);

      this.doc.setFontSize(12).text("PHARMATEC PTE LTD", 0.7, 2.7);
      this.doc.setFontSize(12).text("62 Bank St,", 0.7, 2.9);
      this.doc.setFontSize(12).text("P.O.Box 450,Ba,", 0.7, 3.1);
      this.doc.setFontSize(12).text("Fiji Islands", 0.7, 3.3);
      this.doc.setFontSize(12).text("Ph: 6674933", 0.7, 3.5);
      this.doc.setFontSize(12).text("Fax: 6670118", 0.7, 3.7);
      this.doc.setFontSize(12).text("Email: admin@pharmatec.com.fj", 0.7, 3.9);

      //trading partner
      this.doc.setFontSize(14).text("To :", 5, 2.5);

      this.doc.setFontSize(12).text(fname + " " + lname, 5.2, 2.7);
      this.doc.setFontSize(12).text(company, 5.2, 2.9);
      this.doc.setFontSize(12).text(mobile, 5.2, 3.1);
      this.doc.setFontSize(12).text(email, 5.2, 3.3);

      this.doc.setFontSize(12).text("Requested Date: " + this.momentLocal(requestedDate), 5, 3.7);
      this.doc.setFontSize(12).text("Require Date: " + this.momentLocal(neededDate), 5, 3.9);

      autoTable(this.doc, {
        theme: "grid",
        columns,
        body: data,
        headerStyles: {
          fontSize: 7,
          fillColor: [22, 78, 99],
          lineWidth: 0.01,
          lineColor: [22, 78, 99],
        },
        styles: {
          fontSize: 7,
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
        },
        showHead: "firstPage",
        margin: { left: 0.5, top: 4.4 },
      });
    },

    onPrint() {
      this.setReport();
      this.doc.autoPrint();
      this.doc.output("dataurlnewwindow");
    },

    generateReport() {
      this.setReport();
      var generateDate = new Date().toLocaleString();
      this.doc.save(`${this.formDataPurchaseOrder.orderNumber + "_" + generateDate}.pdf`);
    },

    closeAddSupplierProductModal() {
      this.addSupplierProductModal = false;
    },

    saveSupplierProducts() {
      this.isLoading = true;

      axios
        .post("SupplierProducts", this.formDataSupplierProduct)
        .then((response) => {
          if (response.data.success === true) {
            this.formDataOrderLine.supplierProductNumber = response.data.data.productNumber;
            this.formDataOrderLine.description = response.data.data.productDescription;
            this.formDataOrderLine.skuId = response.data.data.skuId;

            console.log("response", response);
            console.log("formDataOrderLine", this.formDataOrderLine);

            this.saveFormProduct();
            this.addSupplierProductModal = false;

            this.isLoading = false;
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    addSupplierProduct() {
      var supplierProductNumber = this.formDataOrderLine.supplierProductNumber;

      if (supplierProductNumber === "ADD NEW") {
        var supplierId = this.formDataPurchaseOrder.supplier.supplierId;
        this.formDataSupplierProduct.supplierId = supplierId;
        this.addOrderlineModal = false;
        this.addSupplierProductModal = true;
      }
    },
    addSupplierProductInfo() {
      var skuId = this.formDataSupplierProduct.skuId;
      var skuInfo = this.skus.find((x) => x.skuId == skuId);
      if (skuInfo != undefined) {
        this.formDataSupplierProduct.productDescription = skuInfo.description;
      } else {
        this.formDataSupplierProduct.productDescription = "";
      }
    },
    getSupplierProductInfoNew() {
      var description = this.formDataOrderLineNew.description;
      console.log("NEW PO: get dec", description);
      var supplierProductInfo = this.supplierProductsNew.find((x) => x.skuDescription == description);

      console.log("NEW PO: get dec res", supplierProductInfo);

      if (supplierProductInfo != undefined) {
        // this.formDataOrderLine.description =
        //   supplierProductInfo.productDescription;

        this.formDataOrderLineNew.supplierProductNumber = supplierProductInfo.productNumber;
        this.formDataOrderLineNew.skuId = supplierProductInfo.skuId;
        this.formDataOrderLineNew.skuName = supplierProductInfo.skuName;

        console.log("NEW PO: get sku", this.formDataOrderLineNew.skuName);

        // this.formDataOrderLine.skuId = supplierProductInfo.skuId;
      } else {
        this.formDataOrderLineNew.supplierProductNumber = "";
        this.formDataOrderLineNew.skuId = "";
        // this.formDataOrderLine.skuName = "";
      }

      //this.supplierProducts
    },

    getSupplierProductInfo() {
      var description = this.formDataOrderLine.description?.skuDescription;
      console.log("PO: get dec", description);
      console.log("PO: get supplierProducts", this.supplierProducts);
      var supplierProductInfo = this.supplierProducts.find((x) => x.skuDescription == description);

      console.log("PO: get dec res", supplierProductInfo);

      if (supplierProductInfo) {
        // this.formDataOrderLine.description =
        //   supplierProductInfo.productDescription;

        this.formDataOrderLine.supplierProductNumber = supplierProductInfo.productNumber;
        this.formDataOrderLine.skuId = supplierProductInfo.skuId;
        this.formDataOrderLine.skuName = supplierProductInfo.skuName;

        console.log("PO: get sku", this.formDataOrderLine);

        // this.formDataOrderLine.skuId = supplierProductInfo.skuId;
      } else {
        this.formDataOrderLine.supplierProductNumber = "";
        this.formDataOrderLine.skuId = "";
        // this.formDataOrderLine.skuName = "";
      }

      //this.supplierProducts
    },
    changePageProduct(pageNum) {
      this.pageNumber = pageNum;
      this.productEnd = this.pageRowCountProduct * pageNum;
      this.productStart = this.productEnd - this.pageRowCountProduct;
      this.orderLinesLocal = this.orderLines.slice(this.productStart, this.productEnd);
    },

    getSelectedColor() {
      return "background-color:rgb(22,78,99);color: white";
    },

    purchaseOrderInvoiceModalClose() {
      // this.isLoading = true;

      for (var i = 0; i < this.items.length; i++) {
        this.items[i].selected = false;
        // this.isLoading = false;

        this.purchaseOrderInvoiceModal = false;
        this.POObj = {};
      }
    },
    viewOrderInfo(item) {
      this.formDataPurchaseOrder = Object.assign({}, item);
      this.orderInfoModal = true;
    },
    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalCSV(date) {
      return moment.utc(date).local().format("L");
    },

    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      this.pagination.pageSize = this.pageSize;

      this.getDataFromApi();
    },
    setDefault() {
      this.formDataPurchaseOrder = Object.assign({}, this.formDataPurchaseOrderDefault);

      var today = moment.utc().local(new Date()).format("DD MMM, YYYY");
      this.formDataPurchaseOrder.requestedDate = today;
    },
    async getDataFromApi() {
      this.isLoading = true;
      await axios
        .post("PurchaseOrders/Paginated/" + this.warehouseAbbreviation, this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.supplierItems = response.data.data.items.supplier;
            this.pageCount = this.itemsData.totalPages;
            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            for (let i = 0; i < this.items.length; i++) {
              this.items[i].selected = false;
              // this.isLoading = true;

              // axios
              //   .get("PurchaseOrders/" + this.items[i].orderNumber)
              //   .then((response) => {
              //     this.items[i].orderLines = response.data.data.orderLines;
              //     this.isLoading = false;
              //   })
              //   .catch((error) => {
              //     this.isLoading = false;
              //     warningToast(statusMessage(error.response.status));
              //   });

              // this.isLoading = false;
            }

            this.isLoading = false;
          } else {
            this.isLoading = false;
            warningToast(response.data.message);
          }

          // this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    // getDataFromApiProduct() {
    //   axios
    //     .post("PurchaseOrders/Paginated", this.pagination)
    //     .then((response) => {
    //       this.productItemsData = response.data.data;
    //       var purchaseOrderId = this.formDataOrderLine.purchaseOrderId;
    //       this.productItems = response.data.data.items.filter(
    //         (a) => a.purchaseOrderId === purchaseOrderId
    //       );
    //       console.log("productItems", this.productItems);
    //       this.pageCountProduct = this.itemsData.totalPages;
    //     });
    // },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.activateModal();
    },
    activateModal() {
      //this.getSupplierData();
      // this.getsupplierProductData();

      //this.getWarehouseData();
      this.addPurchaseOrder = true;
    },
    closeForm() {
      this.formDataPurchaseOrder.supplierId = 0;
      // this.getsupplierProductData();
      this.addPurchaseOrder = false;
      this.productLines = [];
      this.setDefault();
      this.setDefaultProductNew();
      this.POObj = {};
      this.supplierProductsNew = [];
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataPurchaseOrder = Object.assign({}, item);
      this.formDataPurchaseOrder.eta = this.customizeDataAndTime(item.eta);
      this.activateModal();
    },
    customizeDataAndTime(date) {
      return moment(date).format("lll");
    },

    connectTimetoDate() {
      var newDate = moment(this.formDataPurchaseOrder.requestedDate).format("YYYY-MM-DD");
      var currentTime = moment(new Date()).format();
      var splitTime = currentTime.split("T");
      newDate = newDate.concat("T").concat(splitTime[1]);
      this.formDataPurchaseOrder.requestedDate = newDate;
    },

    saveForm() {

      if (this.formDataPurchaseOrder.supplierId == "" || this.formDataPurchaseOrder.status == "") {
        if (this.formDataPurchaseOrder.supplierId == "") {
          warningToast("Supplier Required");
        }
        if (this.formDataPurchaseOrder.status == "") {
          warningToast("Status Required");
        }
      } else {

        if (this.titleChange === -1) {
          this.isLoading = true;

          if (this.productLines.length <= 0) {
            this.isLoading = false;
            this.checkOrderLinesAlert = true;
          }

          else {
            this.connectTimetoDate();
            axios
              .post("PurchaseOrders", this.formDataPurchaseOrder)
              .then((response) => {
                console.log(response);
                if (response.data.success === true) {
                  this.formDataPurchaseOrder = response.data.data;
                  console.log("response");
                  console.log(response);
                  this.formDataPurchaseOrder.purchaseOrderId = response.data.data.purchaseOrderId;

                  this.formDataOrderLine.purchaseOrderId = response.data.data.purchaseOrderId;

                  this.addPurchaseOrder = false;
                  this.purchaseOrderInvoiceModal = false;
                  this.POObj = {};

                  this.orderNumber = response.data.data.orderNumber;

                  for (var y = 0; y < this.productLines.length; y++) {
                    this.productLines[y].purchaseOrderId = response.data.data.purchaseOrderId;
                  }

                  for (var i = 0; i < this.productLines.length; i++) {
                    axios
                      .post("PurchaseOrders/OrderLine", this.productLines[i])
                      .then((response2) => {
                        this.isLoading = false;
                      })
                      .catch((error) => {
                        this.isLoading = false;
                        warningToast(statusMessage(error.response2.status));
                      });
                  }

                  this.getDataFromApi();
                  successToast(response.data.message);
                  this.productLines = [];
                } else {
                  this.isLoading = false;
                  failedToast(response.data.message);
                }
              })
              .catch((error) => {
                this.isLoading = false;
                warningToast(statusMessage(error.response.status));
              });
          }

        }
        else {
          this.connectTimetoDate();
          this.isLoading = true;
          var newDate = moment(this.formDataPurchaseOrder.eta).format("YYYY-MM-DD");
          console.log(newDate);
          var currentTime = moment.utc().format();
          console.log(currentTime);
          var splitTime = currentTime.split("T");
          newDate = newDate.concat("T").concat(splitTime[1]);
          this.formDataPurchaseOrder.eta = newDate;
          console.log(this.formDataPurchaseOrder.eta);
          var formData = {
            orderNumber: this.formDataPurchaseOrder.orderNumber,
            eta: this.formDataPurchaseOrder.eta,
          };
          axios
            .post("PurchaseOrders/" + this.warehouseStoreId + "/ETA", formData)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addPurchaseOrder = false;
                this.POObj = {};
                this.getDataFromApi();
                successToast(response.data.message);
                this.productLines = [];
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }


    },
    archiveItem(item) {
      this.formDataPurchaseOrder = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },

    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },

    closeDeleteOrderLine() {
      this.deleteOrderLineModel = false;
    },

    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataPurchaseOrder.purchaseOrderId;
      this.isLoading = true;
      axios
        .get("PurchaseOrders/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("PurchaseOrders/Archived/" + this.warehouseAbbreviation, this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataPurchaseOrder = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataPurchaseOrder.purchaseOrderId;
      this.isLoading = true;
      axios
        .get("PurchaseOrders/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataPurchaseOrder = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      var itemId = this.formDataPurchaseOrder.purchaseOrderId;
      this.isLoading = true;
      axios
        .delete("PurchaseOrders/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //Product
    pageSizeChangeProduct() {
      this.pagination.pageSize = this.pageSize;
      // this.getDataFromApiProduct();
    },
    // changePageProduct(pageNum) {
    // this.pagination.pageNumber = pageNum;
    // this.getDataFromApiProduct();
    //  },
    searchOnchangeProduct() {
      if (this.searchDataProduct != "") {
        this.searchActiveProduct = true;
        this.searchItemProduct();
      } else {
        this.clearSearchProduct();
      }
    },
    clearSearchProduct() {
      this.pagination.pageNumber = 1;
      this.searchDataProduct = "";
      this.pagination.searchProduct = this.searchDataProduct;
      //    this.getDataFromApiProduct();
      this.searchActiveProduct = false;
    },
    searchItemProduct() {
      if (this.searchDataProduct != "") {
        this.searchActiveProduct = true;
        this.pagination.pageNumber = 1;
        this.pagination.searchProduct = this.searchDataProduct;
        //      this.getDataFromApiProduct();
      }
    },

    setDefaultProduct() {
      this.formDataOrderLine = Object.assign({}, this.formDataOrderLineDefault);
      this.formDataOrderLine.purchaseOrderId = this.formDataPurchaseOrder.purchaseOrderId;
    },

    closeOrderLineModal() {
      this.addOrderlineModal = false;
      this.setDefaultProduct();
      this.POObj = {};
      this.supplierProducts = [];
    },

    setDefaultProductNew() {
      console.log("formDataPurchaseOrder", this.formDataPurchaseOrder);
      this.formDataOrderLineNew = Object.assign({}, this.formDataOrderLineNewDefault);
      this.formDataOrderLineNew.purchaseOrderId = this.formDataPurchaseOrder.purchaseOrderId;
    },

    showModalProduct() {
      this.productChange = -1;
      this.setDefaultProductNew();
      this.activateModalProduct();
    },
    activateModalProduct() {
      // this.getSkus();
      // var options = {

      // }
      // this.loadNewSupPro(options);
      //this.getSupplierProducts();
      this.addOrderlineModal = true;
    },

    closeFormProduct() {
      this.purchaseOrderInvoiceModal = false;
      this.tempPurchaseOrderId = 0;
      this.POObj = {};
      this.supplierProducts = [];
      this.formDataOrderLine.description = "";
    },
    saveFormProduct() {
      if (this.formDataOrderLine.description?.skuDescription == "" || this.formDataOrderLine.description?.skuDescription == "SELECT" || this.formDataOrderLine.quantity == "") {
        if (this.formDataOrderLine.description?.skuDescription == "" || this.formDataOrderLine.description?.skuDescription == "SELECT") {
          warningToast("Select Product");
        }
        if (this.formDataOrderLine.quantity == "") {
          warningToast("Quantity Required");
        }
      } else {
        this.isLoading = true;
        var formData = {
          supplierProductNumber: this.formDataOrderLine.supplierProductNumber,
          description: this.formDataOrderLine.description?.skuDescription,
          skuId: this.formDataOrderLine.skuId,
          quantity: this.formDataOrderLine.quantity,
          purchaseOrderId: this.tempPurchaseOrderId,
          // orderNumber: this.formDataOrderLine.orderNumber,
        };

        let isExist = this.orderLines.some((x) => x.skuId === this.formDataOrderLine.skuId);

        if (isExist == true) {
          this.isLoading = false;
          this.addConfirmationModalV2 = true;
        } else {
          this.isLoading = false;
          axios
            .post("PurchaseOrders/OrderLine", formData)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                //this.addOrderlineModal = false;
                this.getOrderLines();
                this.getDataFromApi();
                this.setDefaultProduct();
                this.formDataOrderLine.description = "";
                this.POObj = {};
                this.isShowDescV2 = true;
                this.$nextTick(() => (this.isShowDescV2 = false));
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },

    addItemConfirmV2() {
      // if(!this.formDataOrderLineNew.supplierProductNumber){
      //     this.formDataOrderLineNew.supplierProductNumber = ""
      //   }
      this.isLoading = true;
      var formData = {
        supplierProductNumber: this.formDataOrderLine.supplierProductNumber,
        description: this.formDataOrderLine.description?.skuDescription,
        skuId: this.formDataOrderLine.skuId,
        quantity: this.formDataOrderLine.quantity,
        purchaseOrderId: this.tempPurchaseOrderId,
        // orderNumber: this.formDataOrderLine.orderNumber,
      };

      axios
        .post("PurchaseOrders/OrderLine", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            //this.addOrderlineModal = false;
            this.getOrderLines();
            this.getDataFromApi();
            this.setDefaultProduct();
            this.formDataOrderLine.description = "";
            this.POObj = {};
            this.isShowDescV2 = true;
            this.$nextTick(() => (this.isShowDescV2 = false));
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
      this.closeAddV2();
    },

    closeAddV2() {
      this.addConfirmationModalV2 = false;
      this.setDefaultProduct();
      this.formDataOrderLine.description = "";
      this.POObj = {};
    },

    removeProducts(index) {
      this.productLines.splice(index, 1);
      return this.productLines;
    },

    saveFormProductLines() {
      if (this.formDataOrderLineNew.description == "" || this.formDataOrderLineNew.description == "SELECT" || this.formDataOrderLineNew.quantity == "") {
        if (this.formDataOrderLineNew.description == "" || this.formDataOrderLineNew.description == "SELECT") {
          warningToast("Select Product");
        }
        if (this.formDataOrderLineNew.quantity == "") {
          warningToast("Quantity Required");
        }
      } else {
        this.isLoading = true;

        let isExist = this.productLines.some((x) => x.skuId === this.formDataOrderLineNew.skuId);

        if (isExist == true) {
          this.addConfirmationModal = true;
        } else {
          if (!this.formDataOrderLineNew.supplierProductNumber) {
            this.formDataOrderLineNew.supplierProductNumber = "";
          }
          this.productLines.push({ ...this.formDataOrderLineNew });

          this.formDataOrderLineNew.description = "";
          this.formDataOrderLineNew.supplierProductNumber = "";
          this.formDataOrderLineNew.skuId = "";
          this.formDataOrderLineNew.skuName = "";
          this.formDataOrderLineNew.quantity = "";
          this.formDataOrderLineNew.purchaseOrderId = "";
          this.formDataOrderLineNew.orderNumber = "";

          this.isShowDesc = true;
          this.$nextTick(() => (this.isShowDesc = false));
        }

        this.isLoading = false;
      }
    },
    addItemConfirm() {
      if (!this.formDataOrderLineNew.supplierProductNumber) {
        this.formDataOrderLineNew.supplierProductNumber = "";
      }
      this.productLines.push({ ...this.formDataOrderLineNew });
      this.closeAdd();
      this.isShowDesc = true;
      this.$nextTick(() => (this.isShowDesc = false));
    },
    closeAdd() {
      this.addConfirmationModal = false;
      this.formDataOrderLineNew.description = "";
      this.formDataOrderLineNew.supplierProductNumber = "";
      this.formDataOrderLineNew.skuId = "";
      this.formDataOrderLineNew.skuName = "";
      this.formDataOrderLineNew.quantity = "";
      this.formDataOrderLineNew.purchaseOrderId = "";
      this.formDataOrderLineNew.orderNumber = "";
    },
    //create ordeline
    addItem(item) {
      this.tempPurchaseOrderId = item.purchaseOrderId;
      this.formDataPurchaseOrder = Object.assign({}, item);
      this.purchaseOrderInvoiceModal = true;
      this.addOrderlineModal = false;
      this.orderNumber = item.orderNumber;
      this.formDataUpdateStatus = Object.assign({}, this.formDataUpdateStatusDefault);
      this.getOrderLines();
      //  this.getSkus();
    },
    getOrderLines() {
      axios
        .get("PurchaseOrders/" + this.warehouseAbbreviation + "/" + this.orderNumber)
        .then((response) => {
          this.orderLines = response.data.data.orderLines.reverse();

          for (var i = 0; i < this.orderLines.length; i++) {
            this.orderLines[i].no = i + 1;

            this.isLoading = false;
          }
          var pageCount = this.orderLines.length / this.pageRowCountProduct;
          let pageCountRound = Math.round(pageCount);
          if (pageCount > pageCountRound) {
            pageCountRound += 1;
            this.pageCountProduct = pageCountRound;
          } else {
            this.pageCountProduct = pageCountRound;
          }

          //pageCountProduct
          this.orderLinesLocal = this.orderLines.slice(this.productStart, this.productEnd);
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    //delete orderline
    deleteOrdeLine(item) {
      this.formDataOrderLine = Object.assign({}, item);
      this.deleteOrderLineModel = true;
      this.getOrderLines();
    },
    deleteOrderLineConfirm() {
      var itemId = this.formDataOrderLine.purchaseOrderLineId;
      this.isLoading = true;
      axios
        .delete("PurchaseOrders/OrderLine/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteOrderLineModel = false;
            this.getOrderLines();
            this.isLoading = false;
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //supplier
    clearSupplier() {
      this.supplierList = [];
      this.supPageNumber = 1;
      this.limitSup = 5;
      this.getSupplierData("");
    },

    searchSupplier(search) {
      clearInterval(this.supSearchTimer);
      this.supSearchTimer = setTimeout(() => {
        if (search) {
          this.supplierList = [];
          this.supPageNumber = 1;
          this.limitSup = this.totalSupCount;
          this.getSupplierData(search.trim());
        }
      }, 2000);
    },

    getSupplierData(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.supPageNumber,
        pageSize: this.limitSup,
        search: search,
      };
      axios
        .post("suppliers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSupSearchResult = !!search;
            this.supplierList =
              this.isSupSearchResult !== true
                ? Array.from(new Set([...this.supplierList, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSup = response.data.data.hasNextPage;
            this.totalSupCount = response.data.data.totalCount;
            if (this.supplierList.length == 0) {
              warningToast("Please Add Items in Supplier");
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    loadSupplier(options) {
      this.isLoading = true;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post("suppliers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.supplierList = response.data.data.items;
            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
            this.isLoading = false;

            // if (this.supplierProductsNew == 0) {
            //   warningToast("Please Add Items in Supplier Product");
            // }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    // getSkus() {
    //   this.isLoading = true;
    //   var pagination = { pageNumber: 1, pageSize: 10000 };
    //   axios
    //     .post("Sku/Paginated", pagination)
    //     .then((response) => {
    //       if (response.data.success === true) {
    //         this.isLoading = false;
    //         this.skus = response.data.data.items;

    //         if (this.skus.length == 0) {
    //           warningToast("Please Add Items in SKU");
    //           this.purchaseOrderInvoiceModal = false;
    //         }
    //       } else {
    //         this.isLoading = false;
    //         failedToast(response.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       warningToast(statusMessage(error.response.status));
    //       this.serverError = true;
    //     });
    // },

    // getProducts() {
    //   var pagination = { pageNumber: 1, pageSize: 100 };
    //   axios
    //     .post("Products/Paginated", pagination)
    //     .then((response) => {
    //       this.products = response.data.data.items;

    //       // if (this.skus.length > 0) {
    //       //   this.formDataOrderLine.skuId = this.skus[0].skuId;
    //       // } else
    //       if (this.products.length == 0) {
    //         this.apiMessage = "Please Add Items in Product";
    //         this.warningToast();
    //         this.purchaseOrderInvoiceModal = false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.apiMessage = error;
    //       this.warningToast();
    //       this.serverError = true;
    //     });
    // },

    loadNewSupPro(options) {
      this.isLoading = true;
      var supplierid = this.formDataPurchaseOrder.supplier.supplierId;
      console.log(options, this.formDataPurchaseOrder);
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post(`SupplierProducts/${supplierid}/Paginated`, pagination)
        .then(async (response) => {
          if (response.data.success === true) {
            let list = [...JSON.parse(JSON.stringify(this.supplierProducts)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.supplierProducts = list;
            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
            this.isLoading = false;

            // if (this.supplierProducts == 0) {
            //   warningToast("Please Add Items in Supplier Product");
            // }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    loadNewSupProV2(options) {
      this.isLoading = true;
      var supplierId = this.formDataPurchaseOrder.supplierId;
      var pagination = {
        pageNumber: options?.page || 1,
        pageSize: options?.itemsPerPage || 10,
        search: options?.search || "",
      };
      axios
        .post(`SupplierProducts/${supplierId}/Paginated`, pagination)
        .then(async (response) => {
          if (response.data.success === true) {
            let list = [...JSON.parse(JSON.stringify(this.supplierProductsNew)), ...JSON.parse(JSON.stringify(response?.data?.data.items))];
            this.supplierProductsNew = list;

            options.callback(response?.data?.data?.items, response?.data?.data?.totalCount);
            this.isLoading = false;

            // if (this.supplierProductsNew == 0) {
            //   warningToast("Please Add Items in Supplier Product");
            // }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearSupPro() {
      this.supplierProducts = [];
      this.supProPageNumber = 1;
      this.limitSupPro = 5;
      this.getSupplierProducts("");
    },

    searchSupPro(search) {
      clearInterval(this.supProSearchTimer);
      this.supProSearchTimer = setTimeout(() => {
        if (search) {
          this.supplierProducts = [];
          this.supProPageNumber = 1;
          this.limitSupPro = this.totalSupProCount;
          this.getSupplierProducts(search.trim());
        }
      }, 2000);
    },
    getSupplierProducts(search) {
      this.isLoading = true;
      var supplierid = this.formDataPurchaseOrder.supplier.supplierId;
      var pagination = {
        pageNumber: this.supProPageNumber,
        pageSize: this.limitSupPro,
        search: search,
      };

      axios
        .post(`SupplierProducts/${supplierid}/Paginated`, pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSupProSearchResult = !!search;
            this.supplierProducts =
              this.isSupProSearchResult !== true
                ? Array.from(new Set([...this.supplierProducts, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse)
                : response.data.data.items;
            this.hasNextPageSupPro = response.data.data.hasNextPage;
            this.totalSupProCount = response.data.data.totalCount;
            if (this.supplierProducts.length == 0) {
              warningToast("Please Add Items in Supplier Product");
              // this.purchaseOrderInvoiceModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          warningToast(statusMessage(error.response.status));
          this.serverError = true;
        });
    },
  },
};
</script>
