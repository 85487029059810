<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="flex justify-between items-center bg-gray-200 py-2 px-4 my-4">
    <div class="flex justify-center items-center space-x-2 h-10">
      <div v-if="templateShow === true" class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-auto h-full" @click="downloadTemplate()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="lg:w-6 lg:h-6 w-4 h-4 text-white hover:text-yellow-200 mr-2" viewBox="0 0 24 24">
          <path
            d="M23 1.5q.41 0 .7.3.3.29.3.7v19q0 .41-.3.7-.29.3-.7.3H7q-.41 0-.7-.3-.3-.29-.3-.7V18H1q-.41 0-.7-.3-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87 2.34-3.8H7.46l-1.3 2.4-.05.08-.04.09-.64-1.28-.66-1.29H2.59l2.27 3.82-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3Z"
          />
        </svg>
        Template
      </div>
      <div class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-auto h-full">
        <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
        <input
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @change="handleFileUpload($event)"
          :key="fileInputKey"
        />
      </div>
      <div class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-auto h-full" @click="csvDownload()" v-if="file !== ''">
        <DownloadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
        Download
      </div>

      <div class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-auto h-full" v-if="file !== ''" @click="csvUploadServer()">
        <FolderUpIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
        Upload To Server
      </div>

      <div class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-auto h-full" @click="chooseFiles()">
        <FolderUpIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
        Customize Upload
        <input
          id="fileInputD"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          ref="fileInputD"
          @change="onFilePickedD"
          :key="onFilePickedKeyD"
          hidden
        />
      </div>
      <div class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-auto h-full" v-if="file !== ''" @click="cleanCsv()">
        <Trash2Icon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
        Clear CSV
      </div>
    </div>

    <div class="ml-4">
      <div class="flex justify-center items-center space-x-2 h-full" v-if="isDuplicate === true">
        <div class="w-3 h-3 rounded-full bg-danger"></div>
        <div class="text-base font-semibold">Duplicate Header found</div>
      </div>

      <div class="flex justify-center items-center space-x-2 h-full" v-if="isDuplicate === false">
        <div class="w-3 h-3 rounded-full bg-success"></div>
        <div class="text-base font-semibold">No duplicate Header</div>
      </div>
    </div>
  </div>

  <div class="intro-y col-span-12 overflow-auto" v-if="headerData.length > 0">
    <div class="py-2 flex justify-center items-center text-white space-x-2">
      <div v-for="(item, i) in matchingItem" :key="i">
        <div class="whitespace-nowrap rounded-full bg-success p-2" v-if="item.match === true">
          {{ item.name }}
        </div>

        <div class="whitespace-nowrap rounded-full bg-danger p-2" v-if="item.match === false">
          {{ item.name }}
        </div>
      </div>
    </div>
    <h2 class="font-medium text-lg mr-auto text-center pb-4">Uploaded Results</h2>
    <table class="table table-bordered table-auto">
      <thead>
        <tr class="uppercase whitespace-nowrap">
          <th
            class="whitespace-nowrap"
            v-for="(header, key) in headerData"
            :key="key"
            :style="[header.duplicate == true ? { border: `2px solid ${header.color}` } : { border: '' }]"
            :class="[header.match == false ? 'bg-red-200' : 'bg-transparent']"
          >
            <div class="flex justify-center ites-center space-x-2">
              <Tippy
                tag="a"
                href="javascript:;"
                class="tooltip"
                :content="
                  header.match == false
                    ? 'This column header do not match with the headers on the server. Please rename the header'
                    : header.duplicate == true
                    ? 'Duplicate columns found. Remove duplicate or change column header'
                    : 'This column header matches with the headers'
                "
                :options="{
                  zIndex: 99999,
                }"
              >
                <div>{{ header.header }}</div>
              </Tippy>
              <div v-if="header.match === false || header.duplicate === true">
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Edit Header"
                  :options="{
                    zIndex: 99999,
                  }"
                >
                  <div class="dropdown inline-block" data-tw-placement="top">
                    <EditIcon class="w-[20px] h-[20px] text-primary mr-1" aria-expanded="false" data-tw-toggle="dropdown" />
                    <div class="dropdown-menu">
                      <div class="dropdown-content">
                        <div class="p-2">
                          <div>
                            <div class="text-xs">Change</div>
                            <div class="flex justify-center items-center space-x-2">
                              <div class="w-56">
                                <v-select v-if="!header.switch" v-model="formData" placeholder="SELECT" class="style-chooser" :options="matchList" />
                                <input v-else id="vertical-form-1" v-model="formData" type="text" class="form-control" placeholder="Header" autocomplete="off" />
                              </div>
                              <Tippy
                                v-if="!header.switch"
                                tag="a"
                                href="javascript:;"
                                class="tooltip"
                                content="Switch to Text Field"
                                :options="{
                                  zIndex: 99999,
                                }"
                              >
                                <ToggleRightIcon
                                  class="w-[24px] h-[24px] text-success mr-1"
                                  @click="
                                    () => {
                                      header.switch = true;
                                    }
                                  "
                                />
                              </Tippy>

                              <Tippy
                                v-else
                                tag="a"
                                href="javascript:;"
                                class="tooltip"
                                content="Switch to Selection"
                                :options="{
                                  zIndex: 99999,
                                }"
                              >
                                <ToggleLeftIcon
                                  class="w-[24px] h-[24px] text-success mr-1"
                                  @click="
                                    () => {
                                      header.switch = false;
                                    }
                                  "
                                />
                              </Tippy>

                              <Tippy
                                tag="a"
                                href="javascript:;"
                                class="tooltip"
                                content="Change Header"
                                :options="{
                                  zIndex: 99999,
                                }"
                              >
                                <CheckCircleIcon class="w-[24px] h-[24px] text-success mr-1" @click="editHeader(header.header, formData, key)" />
                              </Tippy>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tippy>
              </div>
              <div>
                <Tippy
                  tag="a"
                  href="javascript:;"
                  class="tooltip"
                  content="Delete Columns"
                  :options="{
                    zIndex: 99999,
                  }"
                  ><Trash2Icon class="w-[20px] h-[20px] text-danger mr-1" @click="deleteHeader(key, header.header)" />
                </Tippy>
              </div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowKey) in rowData" :key="rowKey" class="intro-x">
          <td v-for="(column, columnKey) in headerData" :key="columnKey" :class="[row[column.header] == '' ? 'bg-[#ffc001]' : 'bg-transparent']">
            <div v-if="row.isEdit === false">{{ row[column.header] }}</div>
            <div v-if="row.isEdit === true">
              <input id="vertical-form-1" v-model="row[column.header]" type="text" class="form-control w-auto" autocomplete="off" />
            </div>
          </td>

          <td class="table-report__action w-28">
            <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
              <ActionButton
                v-if="row.isEdit === false"
                :activeAccess="true"
                @parentMethod="editRecords($event)"
                :item="{ item: row, index: rowKey }"
                name="Edit"
                icon="EditIcon"
                color=""
              />

              <ActionButton
                v-if="row.isEdit === true"
                :activeAccess="true"
                @parentMethod="saveRecords($event)"
                :item="{ item: row, index: rowKey }"
                name="Change"
                icon="SaveIcon"
                color="text-success"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Papa from "papaparse";
import { successToast, failedToast, warningToast, statusMessage } from "../../global-functions/toast";

import { exportCSVFile } from "../../global-functions/functions";
import axios from "../../services/http-common";

export default {
  props: {
    item: {},
    name: "",
    activeAccess: false,
    csvName: "",
    fileUploadAPI: "",
    list: [],
    templateAPI: "",
    templateShow: false,
    warehouseId: 0,
    isWarehouse: false,
  },
  name: "ArrangeCsv",
  data() {
    return {
      file: "",
      fileD: "",
      content: [],
      parsed: false,
      headerData: [],
      rowData: [],
      formData: "",
      isDuplicate: false,
      uploadcsvfile: null,
      uploadcsvfileD: null,
      isLoading: false,
      matchList: [],
      matchingItem: [],
      fileInputKey: 0,
      onFilePickedKey: 0,
      onFilePickedKeyD: 0,
      downloadFile: 0,
    };
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    editRecords(obj) {
      this.rowData[obj.index].isEdit = true;
    },
    saveRecords(obj) {
      this.rowData.forEach((x, i) => {
        if (i === obj.index) {
          x.isEdit = false;
          x = Object.assign({}, obj.item);
        }
      });
    },
    handleFileUpload(event) {
      this.matchingItem = [];
      this.content = [];
      this.headerData = [];
      this.rowData = [];
      this.file = event.target.files[0];
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        // header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results;

          let getDataArr = [];
          results.data.filter((x, i) => {
            if (x.length > 1) {
              if (x.filter((y) => y.length === 0).length < Math.round(this.list.length / 2)) {
                getDataArr.push(x);
              }
            }
          });

          let tempHeader = getDataArr[0];
          getDataArr.splice(0, 1);

          let matchArray = this.matchLists(this.list, tempHeader);
          this.matchList = this.notMatchLists(this.list, tempHeader);

          matchArray.forEach((e) => {
            var dt = {
              match: true,
              name: e,
            };
            this.matchingItem.push(dt);
          });

          this.matchList.forEach((e) => {
            var dt = {
              match: false,
              name: e,
            };
            this.matchingItem.push(dt);
          });

          let lowerCaseArry = tempHeader.map((m) => m.toUpperCase());

          lowerCaseArry.forEach((e) => {
            var dt = {
              duplicate: false,
              duplicateValue: "",
              match: false,
              header: e,
            };
            this.headerData.push(dt);
          });

          this.setDuplicate(lowerCaseArry);
          this.setMatching(matchArray);

          const result = [...new Set(getDataArr.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));

          this.duplicateCheck(this.headerData);

          this.rowData = this.rowsToObjects(result, tempHeader).map((item) => {
            for (var key in item) {
              var upper = key.toUpperCase();

              if (upper !== key) {
                item[upper] = item[key];
                delete item[key];
              }
            }
            return item;
          });

          this.rowData.forEach((x) => {
            x.isEdit = false;
          });
          this.parsed = true;
          successToast("File uploaded successfully");
        }.bind(this),
      });
    },
    matchLists(list, currentList) {
      var newList = list.map((m) => m.toUpperCase());
      const output = newList.filter((obj) => {
        return currentList.map((m) => m.toUpperCase()).indexOf(obj) !== -1;
      });
      return output;
    },
    notMatchLists(list, currentList) {
      var newList = list.map((m) => m.toUpperCase());
      const output = newList.filter((obj) => {
        return currentList.map((m) => m.toUpperCase()).indexOf(obj) === -1;
      });
      return output;
    },
    setMatching(matchArray) {
      for (var i = 0; i < this.headerData.length; i++) {
        if (matchArray.includes(this.headerData[i].header.toUpperCase()) === true) {
          this.headerData[i].match = true;
        } else {
          this.headerData[i].match = false;
        }
      }
    },
    rowsToObjects(rows, headers) {
      return rows.map((r) => r.reduce((o, c, i) => Object.assign(o, { [headers[i]]: c }), {}));
    },

    setDuplicate(arr) {
      for (var i = 0; i < this.headerData.length; i++) {
        let randomColor = "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0");

        if (this.tofindDuplicates(arr).includes(this.headerData[i].header.toUpperCase()) === true || this.headerData[i].header === "") {
          if (this.tofindDuplicates(arr).includes(this.headerData[i].header.toUpperCase()) === true) {
            this.headerData[i].duplicate = true;
            this.headerData[i].color = randomColor;

            let count = this.tofindDuplicates(arr).filter((x) => x.toUpperCase() === this.headerData[i].header.toUpperCase()).length;
            if (count < 2) {
              let idx = this.deleteDuplicateHeader(this.headerData[i].header.toUpperCase());
              this.headerData[idx].color = this.headerData[i].color;
            }
          }

          if (this.headerData[i].header === "") {
            this.headerData[i].duplicate = true;

            this.headerData[i].color = randomColor;
          }
        } else {
          this.headerData[i].duplicate = false;
          this.headerData[i].color = "#000000";
        }
      }
    },
    tofindDuplicates(arr) {
      let newArr = arr.filter((item, index) => arr.indexOf(item) !== index);
      return newArr.map((m) => m.toUpperCase());
    },

    duplicateCheck(arr) {
      var newArr = [];
      arr.forEach((x) => newArr.push(x.header));
      let arrcheck = this.tofindDuplicates(newArr);
      if (arrcheck.length > 0) {
        this.isDuplicate = true;
      } else {
        this.isDuplicate = false;
      }
      newArr = [];
      return this.isDuplicate;
    },

    editHeader(oldName, newn, index) {
      if (this.formData == "") {
        if (this.formData == "") {
          warningToast("Header Required");
        }
      } else {
        let newName = newn.toUpperCase();
        this.headerData.find((x) => {
          if (x.header === oldName) {
            this.headerData[index].header = newName;
          }
        });

        this.rowData.forEach((x) => {
          x[newName] = x[oldName];
          // delete x[oldName];
        });

        var newArr = [];
        this.headerData.forEach((x) => newArr.push(x.header));

        let matchArray = this.matchLists(this.list, newArr);
        this.matchList = this.notMatchLists(this.list, newArr);

        this.matchingItem = [];
        matchArray.forEach((e) => {
          var dt = {
            match: true,
            name: e,
          };
          this.matchingItem.push(dt);
        });

        this.matchList.forEach((e) => {
          var dt = {
            match: false,
            name: e,
          };
          this.matchingItem.push(dt);
        });

        this.setDuplicate(newArr);
        this.setMatching(matchArray);
        this.duplicateCheck(this.headerData);
        this.formData = "";
        newArr = [];
        successToast("Header name changed successfully");
      }
    },

    editWOHeader(oldName, newn, index) {
      let newName = newn.toUpperCase();
      this.headerData.find((x) => {
        if (x.header === oldName) {
          this.headerData[index].header = newName;
        }
      });

      this.rowData.forEach((x) => {
        x[newName] = x[oldName];
        // delete x[oldName];
      });

      var newArr = [];
      this.headerData.forEach((x) => newArr.push(x.header));

      let matchArray = this.matchLists(this.list, newArr);
      this.matchList = this.notMatchLists(this.list, newArr);

      this.matchingItem = [];
      matchArray.forEach((e) => {
        var dt = {
          match: true,
          name: e,
        };
        this.matchingItem.push(dt);
      });

      this.matchList.forEach((e) => {
        var dt = {
          match: false,
          name: e,
        };
        this.matchingItem.push(dt);
      });

      this.setDuplicate(newArr);
      this.setMatching(matchArray);
      this.duplicateCheck(this.headerData);
      this.formData = "";
      newArr = [];
    },
    deleteHeader(index, header) {
      let count = this.headerData.filter((x) => x.header === header).length;
      if (count < 2) {
        this.headerData.splice(index, 1);

        this.rowData.forEach((x) => {
          delete x[header];
        });

        var newArr = [];
        this.headerData.forEach((x) => newArr.push(x.header));
        this.setDuplicate(newArr);
        this.duplicateCheck(this.headerData);
        newArr = [];
        successToast("Column removed successfully");
      } else {
        // failedToast(
        //   "Cannot delete column, Please change header name and try it"
        // );
        let newName = "DEL";
        this.editWOHeader(header, newName, index);
        this.headerData.splice(index, 1);
        this.rowData.forEach((x) => {
          delete x[newName];
        });

        var newArr = [];
        this.headerData.forEach((x) => newArr.push(x.header));
        this.setDuplicate(newArr);
        this.duplicateCheck(this.headerData);
        newArr = [];
        successToast("Column removed successfully");
      }
    },
    deleteDuplicateHeader(header) {
      return this.headerData.findIndex((f) => f.header === header);
    },
    csvDownload() {
      this.downloadFile = 1;
      this.getCSVFile();
    },
    csvUploadServer() {
      this.downloadFile = 2;
      this.getCSVFile();
      if (this.file !== "") {
        this.onFilePicked();
      }
    },

    finalizeData(row, header) {
      return row.map((item) => {
        for (var key in item) {
          header.forEach((x) => {
            if (x.toLowerCase() === key.toLowerCase()) {
              item[x] = item[key];
              if (x !== key) {
                delete item[key];
              }
            }
          });
        }
        return item;
      });
    },

    getCSVFile() {
      if (this.headerData.length <= 0) {
        failedToast("No data uploaded");
      } else {
        var generateDate = new Date().toJSON().slice(0, 10);
        var csvTitle = this.csvName + "_" + generateDate;
        let rowData = JSON.parse(JSON.stringify(this.rowData));
        if (this.downloadFile === 1) {
          var csv = Papa.unparse(this.finalizeData(rowData, this.list));
          var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          var csvURL = window.URL.createObjectURL(csvData);

          var testLink = document.createElement("a");
          testLink.href = csvURL;
          testLink.setAttribute("download", `${csvTitle}.csv`);
          testLink.click();
        }
        if (this.downloadFile === 2) {
          this.getUploadfiles(this.finalizeData(rowData, this.list));
        }
        this.downloadFile = 0;
        successToast("File downloading is started");
      }
    },

    getUploadfiles(array) {
      var csv = Papa.unparse(array);
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      var fileblob = new File([blob], `fileupload.csv`, {
        lastModified: new Date().getTime(),
        type: "text/csv",
      });
      this.file = fileblob;
    },
    chooseFiles() {
      const fileInput = document.getElementById("fileInputD");
      fileInput.value = "";
      this.$refs.fileInputD.click();
    },

    onFilePickedD(event) {
      this.uploadcsvfileD = event.target.files[0];
      if (this.isWarehouse === true) {
        if (this.warehouseId == "" || this.warehouseId == null || this.uploadcsvfile == null) {
          if (this.warehouseId == "") {
            warningToast("Warehouse Required");
            this.onFilePickedKey++;
            this.uploadcsvfile == null;
          }
          if (this.uploadcsvfile == null) {
            warningToast("Csv file Required");
          }
        } else {
          let formData = new FormData();

          formData.append("File", this.uploadcsvfileD);
          formData.append("Name", this.uploadcsvfileD.name);

          this.isLoading = true;
          axios
            .post(`${this.fileUploadAPI}`, formData)
            .then((response) => {
              if (response.data.success === true) {
                this.$emit("getDataFromApi");
                successToast(response.data.message);
                setTimeout(() => {
                  location.reload();
                }, 1000);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              warningToast(statusMessage(error.response.status));
              this.isLoading = false;
            });
          this.warehouseId = 0;
          // this.onFilePickedKey++;
          this.uploadcsvfile = null;
          this.file = null;
        }
      } else {
        let formData = new FormData();

        formData.append("File", this.uploadcsvfileD);
        formData.append("Name", this.uploadcsvfileD.name);

        this.isLoading = true;
        axios
          .post(`${this.fileUploadAPI}`, formData)
          .then((response) => {
            if (response.data.success === true) {
              this.$emit("getDataFromApi");
              setTimeout(() => {
                location.reload();
              }, 1000);
              successToast(response.data.message);
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            warningToast(statusMessage(error.response.status));
            this.isLoading = false;
          });
        this.uploadcsvfileD = null;
        this.fileD = null;
        this.onFilePickedKeyD++;
      }
    },

    onFilePicked() {
      this.uploadcsvfile = this.file;
      if (this.isWarehouse === true) {
        if (this.warehouseId == "" || this.warehouseId == null || this.uploadcsvfile == null) {
          if (this.warehouseId == "") {
            warningToast("Warehouse Required");
            this.onFilePickedKey++;
            this.uploadcsvfile == null;
          }
          if (this.uploadcsvfile == null) {
            warningToast("Csv file Required");
          }
        } else {
          let formData = new FormData();

          formData.append("File", this.uploadcsvfile);
          formData.append("Name", this.uploadcsvfile.name);

          this.isLoading = true;
          axios
            .post(`${this.fileUploadAPI}`, formData)
            .then((response) => {
              if (response.data.success === true) {
                this.$emit("getDataFromApi");
                setTimeout(() => {
                  location.reload();
                }, 1000);
                successToast(response.data.message);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              warningToast(statusMessage(error.response.status));
              this.isLoading = false;
            });
          this.warehouseId = 0;
          // this.onFilePickedKey++;
          this.uploadcsvfile = null;
          this.file = null;
        }
      } else {
        let formData = new FormData();

        formData.append("File", this.uploadcsvfile);
        formData.append("Name", this.uploadcsvfile.name);

        this.isLoading = true;
        axios
          .post(`${this.fileUploadAPI}`, formData)
          .then((response) => {
            if (response.data.success === true) {
              this.$emit("getDataFromApi");
              setTimeout(() => {
                location.reload();
              }, 1000);
              successToast(response.data.message);
              this.isLoading = false;
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            warningToast(statusMessage(error.response.status));
            this.isLoading = false;
          });
        this.uploadcsvfile = null;
        this.file = null;
        // this.onFilePickedKey++;
      }
    },

    cleanCsv() {
      this.content = [];
      this.headerData = [];
      this.rowData = [];
      this.file = "";
      this.fileInputKey++;
      this.matchingItem = [];
      successToast("File cleared successfully");
    },

    downloadTemplate() {
  this.isLoading = true;
  axios
    .get(this.templateAPI, { responseType: 'blob' })  // Ensure the response is a blob
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Set the download attribute with a desired filename
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'template.xlsx';  // Default filename

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.isLoading = false;
    })
    .catch((error) => {
      warningToast(statusMessage(error.response.status));
      this.isLoading = false;
    });
}

  },
};
</script>

<style scoped>
input::file-selector-button {
  font-weight: bold;
  color: white;
  padding: 0.5em;
  border: thin solid white;
  border-radius: 3px;
  background: none;
}
</style>
