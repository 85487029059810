<template>
  <LoadingScreen :isLoading="isLoading" />

  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-center lg:space-x-1 lg:justify-between md:flex md:flex-row md:items-center md:space-x-1 md:justify-between flex flex-wrap flex-col justify-end items-end mt-2 w-full"
    >
      <div class="flex justify-center items-center">
        <AddItem :activeAccess="$h.addItemAccess(policyAccess)" @parentMethod="showModal($event)" buttonName="Add New Supplier" />

        <div v-if="$h.addItemAccess(policyAccess) == true">
          <a href="javascript:;" class="mr-2 btn btn-primary shadow-md text-xs mt-1 mb-1" @click="openUploadModal()">
            Upload File
            <span class="ml-2">
              <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
            </span>
          </a>
        </div>

        <!-- <UploadFile
          :activeAccess="$h.addItemAccess(policyAccess)"
          buttonName="File Upload"
          @chooseFiles="chooseFiles($event)"
          @onFilePicked="onFilePicked($event)"
          @downloadTemplate="downloadTemplate($event)"
        /> -->
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pagination.pageNumber"
          :selected="checkedData.length"
          @printSelected="printSelected"
          @csvExportSelected="csvExportSelected"
          @pdfExportSelected="pdfExportSelected"
          @printAllPages="printAllPages"
        />

        <FilterItems :headersFilter="headersFilter" @parentMethod="sorting($event)" />
        <SearchBar :searchActive="searchActive" @searchOnchange="searchOnchange($event)" @searchItem="searchItem($event)" @clearSearch="clearSearch($event)" />
      </div>

      <ViewArchiveItem @parentMethod="viewArchives($event)" />
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto w-full xl:h-[50vh] lg:h-[50vh] md:h-[50vh] h-[50vh]" ref="scroll" @scroll="handleScroll">
      <table class="table table-report -mt-2 table-auto">
        <thead class="sticky top-0 z-50 bg-[#f1f5f9]">
          <tr class="uppercase whitespace-nowrap">
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
            <th>On Hold</th>
            <!-- <th>Individual</th> -->
            <th class="flex justify-start">
              <SelectLabels :allSelected="allSelected" @selectAll="selectAll($event)" @unSelectAll="unSelectAll($event)" />
              <!-- <ArchiveItem
                class="ml-3 -mt-1"
                :activeAccess="$h.archiveItemAccess(policyAccess)"
                v-if="checkedData.length > 0"
                @parentMethod="archiveMultipleItem($event)"
                name="Archive Selected"
              /> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] || "" }}
            </td>
            <td>
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="item.isBlackListed" class="form-check-input" disabled />
              </div>
            </td>

            <td class="table-report__action w-28">
              <div class="lg:grid lg:grid-cols-3 gap-x-2 gap-y-2 p-2 flex flex-wrap">
                <CheckItem :item="item" :checkedData="checkedData" @parentMethod="checkItem($event)" />
                <InfoItem @parentMethod="viewItemInfo($event)" :item="item" name="INFO" />

                <AddSubItem
                  :activeAccess="$h.addSupplierProductAccess(policyAccess)"
                  @parentMethod="viewSupplierProduct($event)"
                  :item="item"
                  name="Add Supplier Product (PLU/SKU)"
                />
                <ViewContacts :activeAccess="$h.addSupplierContactAccess(policyAccess)" @parentMethod="viewContactItemInfo($event)" :item="item" name="Add Supplier Contact" />

                <EditItem :activeAccess="$h.editItemAccess(policyAccess)" @parentMethod="editItem($event)" :item="item" name="Edit" />
                <ArchiveItem :activeAccess="$h.archiveItemAccess(policyAccess)" @parentMethod="archiveItem($event)" :item="item" name="Archive" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <NoData :itemsData="itemsData" />
    </div>
    <!-- END: Data List -->
    <!-- <LoadMoreData :itemsData="itemsData" :loadMoreData="loadMoreData" /> -->

    <!-- BEGIN: Pagination -->
    <PaginationBar
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageSize="pageSizeView"
      :pageNumber="pagination.pageNumber"
    />
  </div>

  <!-- BEGIN: Add  Modal -->

  <MoveableModal :show="addSupplier" @hidden="addSupplier = false" backdrop="static" size="modal-lg" :title="formTitle" @close="addSupplier = false">
    <ModalBody class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
      <!-- <div>
        <label for="regular-form-1" class="form-label">First Name</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataSupplier.firstName"
            type="text"
            class="form-control"
            name="firstName"
            placeholder="First Name"
            autocomplete="off"
          />
        </div>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Last Name</label>

        <div class="flex flex-col space-y-1">
          <input
            id="vertical-form-1"
            v-model="formDataSupplier.lastName"
            type="text"
            class="form-control"
            name="lastName"
            placeholder="Last Name"
            autocomplete="off"
          />
        </div>
      </div> -->

      <div>
        <label for="regular-form-1" class="form-label">Company</label>

        <div class="flex flex-col space-y-1">
          <input id="vertical-form-1" v-model="formDataSupplier.company" type="text" class="form-control" name="company" placeholder="Company" autocomplete="off" />
        </div>
      </div>

      <div class="relative col-span-3 pt-2">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Supplier Contact Detail</h3>
        <div class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
          <div>
            <label for="regular-form-1" class="form-label">Email</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validateEmail(formDataSupplier.email) == true && formDataSupplier.email != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1"
                v-model="formDataSupplier.email"
                type="email"
                class="form-control"
                name="email"
                placeholder="Email"
                autocomplete="off"
              />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Mobile</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validatePhoneNo(formDataSupplier.mobile) == true && formDataSupplier.mobile != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1"
                v-model="formDataSupplier.mobile"
                type="text"
                class="form-control"
                name="mobile"
                placeholder="Mobile"
                autocomplete="off"
              />
            </div>
          </div>

          <div>
            <label for="regular-form-1" class="form-label">Telephone</label>

            <div class="flex flex-col space-y-1">
              <input
                :style="validatePhoneNo(formDataSupplier.telephone) == true && formDataSupplier.telephone != '' ? 'border-color: red' : 'border-color: none'"
                id="vertical-form-1"
                v-model="formDataSupplier.telephone"
                type="text"
                class="form-control"
                name="telephone"
                placeholder="Telephone"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <label for="regular-form-1" class="form-label">On Hold?</label>
        <div class="form-switch ml-3">
          <input type="checkbox" v-model="formDataSupplier.isBlackListed" class="form-check-input" />
        </div>
      </div>

      <div class="flex flex-col">
        <label for="regular-form-1" class="form-label">Individual?</label>
        <div class="form-switch ml-3">
          <input type="checkbox" v-model="formDataSupplier.isIndividualPersonal" class="form-check-input" />
        </div>
      </div>

      <div>
        <label for="regular-form-1" class="form-label">Currency</label>
        <div>
          <v-select
            v-model="formDataSupplier.currency"
            placeholder="SELECT"
            class="style-chooser"
            :options="paginatedCurrency"
            :filterable="false"
            @open="onOpenCurrency"
            @close="onCloseCurrency"
            @search="
              (query) => {
                onCurrencySearch(query);
              }
            "
            :reduce="(currencyCode) => currencyCode.currencyCode"
            label="currencyCode"
          >
            <template #list-footer>
              <li v-show="hasNextPageCurrency" ref="loadCurrency" class="loader">Loading more...</li>
            </template>
          </v-select>
        </div>
      </div>

      <div class="relative col-span-3 pt-2">
        <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Supplier Address</h3>
        <div class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
          <div class="col-span-3">
            <label for="regular-form-1" class="form-label">Address</label>
            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataSupplier.address" type="text" class="form-control" name="address" placeholder="Type here address" autocomplete="off" />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">Postcode</label>
            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataSupplier.postcode" type="text" class="form-control" name="postcode" placeholder="Postcode" autocomplete="off" />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">State</label>
            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataSupplier.state" type="text" class="form-control" name="state" placeholder="State" autocomplete="off" />
            </div>
          </div>
          <div>
            <label for="regular-form-1" class="form-label">City</label>
            <div class="flex flex-col space-y-1">
              <input id="vertical-form-1" v-model="formDataSupplier.city" type="text" class="form-control" name="city" placeholder="City" autocomplete="off" />
            </div>
          </div>
          <div class="col-span-2">
            <label for="regular-form-1" class="form-label">Country</label>
            <div>
              <v-select
                v-model="formDataSupplier.country"
                placeholder="SELECT"
                class="style-chooser"
                :options="paginatedCountry"
                :filterable="false"
                @open="onOpenCountry"
                @close="onCloseCountry"
                @search="
                  (query) => {
                    onCountrySearch(query);
                  }
                "
                :reduce="(countryName) => countryName.countryName"
                label="countryName"
              >
                <template #list-footer>
                  <li v-show="hasNextPageCountry" ref="loadCountry" class="loader">Loading more...</li>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeForm()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveForm()" type="submit" class="btn btn-primary w-24">
        {{ btnName }}
      </button>
    </ModalFooter>
  </MoveableModal>
  <!-- END: Add Modal -->

  <MoveableModal :show="addContactModal" @hidden="addContactModal = false" backdrop="static" size="modal-sl-80" :title="formContactTitle" @close="closeContact()">
    <ModalBody class="p-0">
      <div v-for="(input, index) in formDataContact" :key="`phoneInput-${index}`" class="flex flex-col justify-center items-center space-y-4">
        <h2 class="text-success py-4 text-xl w-full font-semibold bg-gray-200 text-center">Supplier Contact Person - {{ index + 1 }}</h2>
        <div class="flex justify-center items-center space-x-4 w-full p-6">
          <div class="grid lg:grid-cols-11 md:grid-cols-11 grid-cols-2 lg:gap-4 md:gap-4 gap-2 pb-2">
            <div class="col-span-2">
              <label for="regular-form-1" class="form-label">Salutation</label>
              <div class="flex flex-col space-y-1">
                <v-select v-model="input.salutation" placeholder="SELECT" class="style-chooser" :options="['Ms.', 'Mrs.', 'Mr.']" />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Supplier First Name</label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="input.firstName" type="text" class="form-control" placeholder="Supplier First Name" autocomplete="off" />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Supplier Last Name </label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="input.lastName" type="text" class="form-control" placeholder="Supplier Last Name" autocomplete="off" />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Supplier User Name </label>

              <div class="flex flex-col space-y-1">
                <input id="vertical-form-1" v-model="input.displayName" type="text" class="form-control" placeholder="Supplier User Name" autocomplete="off" />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Email </label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="validateEmail(input.email) == true && input.email != '' && input.email != null ? 'border-color: red' : 'border-color: none'"
                  id="vertical-form-1"
                  v-model="input.email"
                  type="email"
                  class="form-control"
                  placeholder="Supplier Email"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Telephone No </label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="validatePhoneNo(input.telephone) == true && input.telephone != '' && input.telephone != null ? 'border-color: red' : 'border-color: none'"
                  id="vertical-form-1"
                  v-model="input.telephone"
                  type="text"
                  class="form-control"
                  placeholder="Supplier Telephone"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="col-span-3">
              <label for="regular-form-1" class="form-label">Mobile No </label>

              <div class="flex flex-col space-y-1">
                <input
                  :style="validatePhoneNo(input.mobile) == true && input.mobile != '' && input.mobile != null ? 'border-color: red' : 'border-color: none'"
                  id="vertical-form-1"
                  v-model="input.mobile"
                  type="text"
                  class="form-control"
                  placeholder="Supplier Mobile"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="col-span-2 flex flex-col justify-center items-center">
              <label>On Hold</label>
              <div class="form-switch mt-3">
                <input type="checkbox" v-model="input.isBlackListed" class="form-check-input" />
              </div>
            </div>

            <div class="col-span-5">
              <label for="regular-form-1" class="form-label">Other Details</label>

              <div class="flex flex-col space-y-1">
                <textarea id="vertical-form-1" v-model="input.otherDetails" type="text" class="form-control" name="description" placeholder="Type here..."></textarea>
              </div>
            </div>

            <div class="col-span-6">
              <label for="regular-form-1" class="form-label">Remarks</label>

              <div class="flex flex-col space-y-1">
                <textarea id="vertical-form-1" v-model="input.remarks" type="text" class="form-control" name="description" placeholder="Type here..."></textarea>
              </div>
            </div>

            <div class="relative col-span-11 pt-2">
              <h3 class="absolute -top-2.5 left-4 bg-white pt-2">Supplier Address</h3>
              <div class="border rounded-md border-slate-200 border-solid p-4 h-full grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 lg:gap-4 md:gap-4 gap-2">
                <div class="col-span-3">
                  <label for="regular-form-1" class="form-label">Address</label>
                  <div class="flex flex-col space-y-1">
                    <input id="vertical-form-1" v-model="input.addressLine" type="text" class="form-control" placeholder="Address Line" autocomplete="off" />
                  </div>
                </div>
                <div>
                  <label for="regular-form-1" class="form-label">Postcode</label>
                  <div class="flex flex-col space-y-1">
                    <input id="vertical-form-1" v-model="input.postcode" type="text" class="form-control" placeholder="Post Code" autocomplete="off" />
                  </div>
                </div>
                <div>
                  <label for="regular-form-1" class="form-label">State</label>
                  <div class="flex flex-col space-y-1">
                    <input id="vertical-form-1" v-model="input.state" type="text" class="form-control" placeholder="State" autocomplete="off" />
                  </div>
                </div>
                <div>
                  <label for="regular-form-1" class="form-label">City</label>
                  <div class="flex flex-col space-y-1">
                    <input id="vertical-form-1" v-model="input.city" type="text" class="form-control" placeholder="City" autocomplete="off" />
                  </div>
                </div>
                <div class="col-span-2">
                  <label for="regular-form-1" class="form-label">Country</label>
                  <div>
                    <v-select
                      v-model="input.country"
                      placeholder="SELECT"
                      class="style-chooser"
                      :options="paginatedCountry"
                      :filterable="false"
                      @open="onOpenCountry"
                      @close="onCloseCountry"
                      @search="
                        (query) => {
                          onCountrySearch(query);
                        }
                      "
                      :reduce="(countryName) => countryName.countryName"
                      label="countryName"
                    >
                      <template #list-footer>
                        <li v-show="hasNextPageCountry" ref="loadCountry" class="loader">Loading more...</li>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center space-x-4" v-if="titleContactChange != 0">
            <Tippy
              tag="a"
              href="javascript:;"
              class="tooltip"
              content="Add More"
              :options="{
                zIndex: 99999,
              }"
            >
              <PlusIcon class="w-12 h-12 text-success" @click="addField(input, formDataContact)" />
            </Tippy>

            <Tippy
              tag="a"
              href="javascript:;"
              class="tooltip"
              content="Remove"
              :options="{
                zIndex: 99999,
              }"
            >
              <XCircleIcon class="w-12 h-12 text-danger" v-show="formDataContact.length > 1" @click="removeField(index, formDataContact)" />
            </Tippy>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <button type="button" @click="closeContact()" class="btn btn-outline-secondary w-24 mr-1">
        {{ const_txt.CANCEL_BTN }}
      </button>
      <button @click="saveContact()" type="submit" class="btn btn-primary w-24">
        {{ btnContactName }}
      </button>
    </ModalFooter>
  </MoveableModal>

  <!-- BEGIN: Archive Modal -->
  <Modal :show="archiveModal" @hidden="archiveModal = false" size="modal-sl-80" backdrop="static">
    <ModalBody class="p-0">
      <div class="p-2">
        <XCircleIcon class="text-danger float-right lg:w-8 lg:h-8 md:w-7 md:h-7 w-6 h-6 cursor-pointer" @click="archiveModal = false" />
      </div>

      <div class="p-5 text-center lg:text-lg text-base uppercase font-semibold">Archived Supplier</div>
      <hr />
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataArchive"
            @change="searchOnchangeArchive"
            autocomplete="off"
            clearable
          />
          <SearchIcon @click="searchItemArchive" v-if="searchActiveArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchArchive" v-if="searchActiveArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveItems" :key="i" class="intro-x">
              <td v-for="(header, index) in headers" :key="index">
                {{ item[header.value] || "" }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <!-- <InfoItem
                    @parentMethod="viewItemInfo($event)"
                    :item="item"
                    name="INFO"
                  /> -->
                  <RestoreItem :activeAccess="$h.restoreItemAccess(policyAccess)" @parentMethod="restoreItem($event)" :item="item" name="Restore" />
                  <DeleteItem :activeAccess="$h.deleteItemAccess(policyAccess)" @parentMethod="deleteItem($event)" :item="item" name="Delete" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePageArchive($event)"
        @pageSizeChange="pageSizeChangeArchive($event)"
        :itemsData="archiveData"
        :pageCount="pageCountArchive"
        :pageSize="pageSizeArchive"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </Modal>
  <!-- END: Archive Modal -->

  <!-- BEGIN: Confirmation Modals -->
  <ArchiveConfirmModal :name="formDataSupplier.name" :modalStatus="archiveConfirmationModal" :modalConfirm="archiveItemConfirm" :modalClose="closeArchive" />
  <RestoreConfirmModal :name="formDataSupplier.name" :modalStatus="restoreConfirmationModal" :modalConfirm="restoreItemConfirm" :modalClose="closeRestore" />
  <DeleteConfirmModal :name="formDataSupplier.name" :modalStatus="deleteConfirmationModal" :modalConfirm="deleteItemConfirm" :modalClose="closeDelete" />
  <!-- END: Confirmation Modals -->

  <!-- BEGIN: View Supplier Product Modal -->

  <MoveableModal
    :show="viewSupplierProductModal"
    @hidden="viewSupplierProductModal = false"
    backdrop="static"
    :title="formDataSupplier.company + ' - Supplier Product'"
    size="modal-sl-85"
    @close="viewSupplierProductModal = false"
  >
    <ModalBody class="p-0">
      <div class="w-full sm:w-auto mt-3 sm:mt-0 p-3">
        <div class="flex item-center justify-center">
          <a v-if="!addSupplierProductModal" href="javascript:;" @click="addSupplierProducts()" class="btn btn-primary shadow-md mr-2 text-xs mt-1 mb-1"> Add New Product </a>

          <div v-if="!addSupplierProductModal">
            <a href="javascript:;" class="mr-2 btn btn-primary shadow-md text-xs mt-1 mb-1" @click="openUploadCsvModal()">
              Upload File
              <span class="ml-2">
                <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
              </span>
            </a>
          </div>
          <div v-if="addSupplierProductModal" class="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-4 gap-2 w-full">
            <div>
              <label for="regular-form-1" class="form-label font-semibold"> Product Number </label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataSupplierProduct.productNumber"
                  type="text"
                  class="form-control"
                  name="productNumber"
                  placeholder="Product Number"
                  autocomplete="off"
                />
              </div>
            </div>

            <div>
              <label for="regular-form-1" class="form-label">PLU/SKU</label>

              <div class="flex flex-col space-y-1">
                <v-select
                  v-model="formDataSupplierProduct.skuId"
                  @change="addSupplierProductInfo"
                  placeholder="SELECT"
                  :filterable="false"
                  @open="onOpenSku"
                  @close="onCloseSku"
                  class="style-chooser"
                  @search="searchSKU"
                  :key="skuName"
                  :get-option-label="(option) => option.skuName"
                  :options="skus"
                  :reduce="(skuName) => skuName.skuId"
                  label="description"
                >
                  <template #list-footer>
                    <li v-show="hasNextPageSku" ref="loadSku" class="loader">Loading more...</li>
                  </template>
                </v-select>
              </div>
            </div>

            <div class="">
              <label for="regular-form-1" class="form-label">Supplier Product Description</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataSupplierProduct.productDescription"
                  type="text"
                  class="form-control h-10"
                  name="description"
                  placeholder="Supplier Product Description"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="">
              <label for="regular-form-1" class="form-label">Price</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataSupplierProduct.price"
                  type="text"
                  class="form-control h-10"
                  name="description"
                  placeholder="Price"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="">
              <label for="regular-form-1" class="form-label">Delivery Days</label>

              <div class="flex flex-col space-y-1">
                <input
                  id="vertical-form-1"
                  v-model="formDataSupplierProduct.maxDeliveryTimeInDays"
                  type="text"
                  class="form-control h-10"
                  name="description"
                  placeholder="Delivery Days"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="lg:col-span-3 md:col-span-3 col-span-0 flex justify-end">
              <button type="button" @click="addSupplierProductModal = false" class="btn btn-outline-secondary w-24 mr-1">
                {{ const_txt.CANCEL_BTN }}
              </button>
              <button @click="saveSupplierProducts()" type="submit" class="btn btn-primary w-24 mr-1">
                {{ supplierProductTitle }}
              </button>
            </div>
          </div>
          <div class="hidden md:block mx-auto text-slate-500">
            <!-- Showing 1 to 10 of 150 entries -->
          </div>
          <!-- <div class="justify-end">
            <a
              href="javascript:;"
              @click="viewArchivesOption()"
              class="btn btn-primary shadow-md"
            >
              ARCHIVE
              <span class="ml-2 w-5 h-5 flex items-center justify-center">
                <ArchiveIcon class="w-6 h-6 -mt-1" />
              </span>
            </a>
          </div> -->
        </div>
      </div>
      <!-- SEARCH -->
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataSupplierProduct"
            @change="searchOnchangeSupplierProduct"
            autocomplete="off"
          />
          <SearchIcon @click="searchItemSupplierProduct" v-if="!searchActiveSupplierProduct" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchSupplierProduct" v-if="searchActiveSupplierProduct" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <!-- <div
        class="p-5 text-center text-lg"
        v-if="this.archiveData.totalCount == 0"
      >
        No data
      </div> -->
      <div class="intro-y col-span-12 overflow-auto p-4">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th class="whitespace-nowrap" v-for="(header, i) in supplierProductsHeaders" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in supplierProductItems" :key="i" class="intro-x">
              <td v-for="(header, index) in supplierProductsHeaders" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a @click="editItemSupplierProduct(item)" class="flex items-center mr-3 text-blue-900" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Edit Option"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <EditIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                    </Tippy>
                  </a>
                  <a @click="deleteItemSupplierProduct(item)" class="flex items-center mr-3 text-danger" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Delete"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <Trash2Icon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                    </Tippy>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePageSupplierProduct($event)"
        @pageSizeChange="pageSizeChangeSupplierProduct($event)"
        :itemsData="itemsData"
        :pageCount="pageCountSupplierProduct"
        :pageSize="pageSizeSupplierProduct"
      />

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>

  <!-- BEGIN: Delete Confirmation Modal For Supplier Product-->
  <Modal :show="deleteSupplierProductConfirmationModal" @hidden="deleteSupplierProductConfirmationModal = false">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <Trash2Icon class="w-16 h-16 text-danger mx-auto mt-3" />
        <div class="text-3xl mt-5">Are you sure?</div>
        <div class="text-xl font-bold mt-5">
          {{ formDataSupplierProduct.productNumber }}
        </div>
        <div class="text-slate-500 mt-2">Do you really want to delete these records? <br />This process cannot be undone.</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button type="button" @click="deleteSupplierProductConfirmationModal = false" class="btn btn-outline-secondary w-24 mr-1">
          {{ const_txt.CANCEL_BTN }}
        </button>
        <button type="button" class="btn btn-danger w-24" @click="deleteSupplierProductItemConfirm()">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->

  <!-- BEGIN: View Bin Info Modal -->

  <MoveableModal :show="viewInfoModal" @hidden="viewInfoModal = false" backdrop="static" size="modal-sl-60" title="INFO" @close="viewInfoModal = false">
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-6">
        <div class="p-5 text-center">
          <BoxIcon class="w-8 h-8 text-danger mx-auto mt-2" />

          <div class="text-xl font-bold mt-5">
            <!-- {{ formDataSupplier.firstName }} {{ formDataSupplier.lastName }}  -->
            {{ formDataSupplier.company }}
          </div>
        </div>
        <div class="intro-y box p-5 mt-12 sm:mt-5">
          <div class="pt-4 intro-y box grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-4 gap-2">
            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Company</div>
              </div>
              <div>
                {{ formDataSupplier.company != null ? formDataSupplier.company : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Address</div>
              </div>
              <div>
                {{ formDataSupplier.address != null ? formDataSupplier.address : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>On Hold</div>
              </div>
              <div class="form-switch ml-3">
                <input id="booleanSwitch" type="checkbox" v-model="formDataSupplier.isBlackListed" class="form-check-input" disabled />
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Email</div>
              </div>
              <div>
                {{ formDataSupplier.email != null ? formDataSupplier.email : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Telephone</div>
              </div>
              <div>
                {{ formDataSupplier.telephone != null ? formDataSupplier.telephone : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Mobile</div>
              </div>
              <div>
                {{ formDataSupplier.mobile != null ? formDataSupplier.mobile : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Postcode</div>
              </div>
              <div>
                {{ formDataSupplier.postcode != null ? formDataSupplier.postcode : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>State</div>
              </div>
              <div>
                {{ formDataSupplier.state != null ? formDataSupplier.state : "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>City</div>
              </div>
              <div>
                {{ formDataSupplier.city || "" }}
              </div>
            </div>

            <div class="flex flex-col items-center mb-5">
              <div class="flex items-center font-bold uppercase">
                <div>Country</div>
              </div>
              <div>
                {{ formDataSupplier.country != null ? formDataSupplier.country : "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>

  <MoveableModal
    :show="addContactInfoModal"
    @hidden="addContactInfoModal = false"
    backdrop="static"
    size="modal-sl-60"
    :title="'Supplier ' + formDataSupplier.company + ' Contacts'"
    @close="addContactInfoModal = false"
  >
    <ModalBody class="p-0">
      <!-- BEGIN: INFO -->
      <div class="col-span-12 md:col-span-6 lg:col-span-4">
        <div class="intro-y col-span-12 overflow-auto p-4">
          <div class="flex items-center justify-between w-full space-x-4">
            <button @click="addContact()" class="btn btn-primary h-10">
              <UserIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-white mr-2" />
              <span class="text-white uppercase">Add New Supplier Contact</span>
            </button>

            <button @click="viewContactArchives()" class="btn btn-primary h-10 w-28">
              <ArchiveIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 text-white mr-2" />
              <span class="text-white uppercase"> ARCHIVE</span>
            </button>
          </div>
          <table class="table table-report -mt-2 table-auto">
            <thead>
              <tr class="uppercase whitespace-nowrap">
                <th>NO</th>
                <th>Supplier Name</th>
                <th class="whitespace-nowrap" v-for="(header, i) in contactHeaders" :key="i">
                  {{ header.text }}
                </th>
                <th>Address</th>
                <th>On Hold</th>

                <!-- <th>Address</th>
              <th>Date</th>
              <th>Time</th>
              <th>Booking#</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in sortedContacts" :key="i" class="intro-x">
                <td :style="item.default == true ? getSelectedColor() : ''">
                  <div :class="item.default == true ? '' : ''">
                    <span v-if="item.default == true" class="font-black text-xl text-center textStroke">DFLT</span>
                  </div>
                  <div :class="item.default == true ? 'hidden' : ''">
                    {{ i + 1 }}
                  </div>
                </td>
                <td :style="item.default == true ? getSelectedColor() : ''">
                  {{ item.salutation || "" }} {{ item.firstName || "" }}
                  {{ item.lastName || "" }}
                </td>
                <td v-for="(header, index) in contactHeaders" :key="index" :style="item.default == true ? getSelectedColor() : ''">
                  {{ item[header.value] || "" }}
                </td>
                <td :style="item.default == true ? getSelectedColor() : ''">
                  <div class="flex flex-col justify-center items-start whitespace-nowrap">
                    <span>{{ item.address ? item.address.line : "" }}</span>
                    <span>{{ item.address ? item.address.state : "" }}</span>
                    <span>{{ item.address ? item.address.city : "" }}</span>
                    <span>{{ item.address ? item.address.country : "" }}</span>
                    <span>{{ item.address ? item.address.postcode : "" }}</span>
                  </div>
                </td>
                <td :style="item.default == true ? getSelectedColor() : ''">
                  <div class="form-switch ml-3">
                    <input id="booleanSwitch" type="checkbox" v-model="item.isBlackListed" class="form-check-input" disabled />
                  </div>
                </td>

                <td class="table-report__action w-20">
                  <div class="flex justify-center items-center">
                    <a @click="defaultContactItem(item)" class="flex items-center mr-3" href="javascript:;" v-if="item.default == false">
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Set as Default Contact"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <AlbumIcon class="w-6 h-6 mr-1 text-primary" />
                      </Tippy>
                    </a>

                    <a class="flex items-center mr-3" href="javascript:;" v-if="item.default == true">
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Default Contact"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <AlbumIcon class="w-6 h-6 mr-1 text-success" />
                      </Tippy>
                    </a>

                    <a @click="editContact(item)" class="flex items-center mr-3" href="javascript:;">
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Edit Contact"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <EditIcon class="w-6 h-6 mr-1" />
                      </Tippy>
                    </a>
                    <a class="flex items-center text-danger" href="javascript:;" @click="archiveContactItem(item)">
                      <Tippy
                        tag="a"
                        href="javascript:;"
                        class="tooltip"
                        content="Archive"
                        :options="{
                          zIndex: 99999,
                        }"
                      >
                        <ArchiveIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                      </Tippy>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <NoDataCus :items="sortedContacts" />
          <!-- BEGIN: Pagination -->

          <!-- <div
          v-if="this.orderLines.length > 0"
          class="
            intro-y
            rounded-lg
            lg:flex lg:flex-row lg:flex-wrap lg:items-center
            md:flex md:flex-row md:flex-wrap md:items-center
            flex flex-col
            items-center
            justify-center
            p-2
          "
          style="background-color: #164e63"
        >
          <div
            class="w-full sm:w-auto sm:mr-auto"
            style="
              background-color: #e1e7ef;
              border: 2px solid #164e63;
              border-radius: 10px;
            "
          >
            <paginate
              class="text-white"
              :page-count="pageCountProduct"
              :page-range="5"
              :margin-pages="2"
              :click-handler="changePageProduct"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :first-last-button="true"
              :initial-page="1"
              :prev-text="'<'"
              :next-text="'>'"
              :first-button-text="'<<'"
              :last-button-text="'>>'"
            >
            </paginate>
          </div>
           <div class="mr-2 text-white">Page Rows :</div>
        <select
          v-model="pageSizeProduct"
          class="w-20 form-select box cursor-pointer"
          @change="pageSizeChangeProduct"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select> -->
        </div>

        <!-- END: Pagination -->
      </div>

      <!-- END: INFO -->
    </ModalBody>
  </MoveableModal>
  <!-- END: View Bin Info Modal-->

  <MoveableModal
    :show="archiveContactModal"
    @hidden="archiveContactModal = false"
    backdrop="static"
    size="modal-sl-80"
    title="Archived Supplier Contact Person"
    @close="archiveContactModal = false"
  >
    <ModalBody class="p-0">
      <div class="w-full sm:w-auto mt-3 sm:mt-0">
        <div class="w-full relative text-slate-500">
          <input
            type="text"
            class="form-control w-full box pr-10"
            placeholder="Search..."
            v-model="searchDataContactArchive"
            @change="searchOnchangeContactArchive"
            autocomplete="off"
            clearable
          />
          <SearchIcon @click="searchItemContactArchive" v-if="searchActiveContactArchive == false" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
          <XIcon @click="clearSearchContactArchive" v-if="searchActiveContactArchive == true" class="w-6 h-6 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer" />
        </div>
      </div>
      <!-- SEARCH END-->
      <!-- BEGIN: Archive Data List -->
      <div class="p-5 text-center text-lg" v-if="this.archiveContactData.totalCount == 0">No data</div>
      <div class="intro-y col-span-12 overflow-auto p-4" v-if="this.archiveContactData.totalCount > 0">
        <table class="table table-report -mt-2 table-auto">
          <thead>
            <tr class="uppercase whitespace-nowrap">
              <th>No</th>
              <th>Supplier Name</th>
              <th class="whitespace-nowrap" v-for="(header, i) in contactHeaders" :key="i">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in archiveContactItems" :key="i" class="intro-x">
              <td>{{ i + 1 }}</td>
              <td>
                {{ item.saluatation }}.{{ item.firstName }}
                {{ item.lastName }}
              </td>
              <td v-for="(header, index) in contactHeaders" :key="index">
                {{ item[header.value] }}
              </td>

              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a @click="restoreContactItem(item)" class="flex items-center mr-3 text-blue-900" href="javascript:;">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Restore"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <RotateCcwIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                    </Tippy>
                  </a>

                  <a class="flex items-center text-danger" href="javascript:;" @click="deleteContactItem(item)">
                    <Tippy
                      tag="a"
                      href="javascript:;"
                      class="tooltip"
                      content="Delete"
                      :options="{
                        zIndex: 99999,
                      }"
                    >
                      <Trash2Icon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1" />
                    </Tippy>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Archive Data List -->

      <!-- BEGIN: Pagination -->
      <PaginationBar
        @changePage="changePageContactArchive($event)"
        @pageSizeChange="pageContactSizeChange($event)"
        :itemsData="archiveData"
        :pageCount="pageCountContactArchive"
        :pageSize="pageContactSize"
      />

      <!-- <div
        class="intro-y lg:flex lg:flex-row lg:flex-wrap lg:items-center md:flex md:flex-row md:flex-wrap md:items-center flex flex-col items-center justify-center p-2"
        style="background-color: #164e63"
        v-if="this.archiveData.totalCount > 0"
      >
        <div
          class="w-full sm:w-auto sm:mr-auto"
          style="
            background-color: #e1e7ef;
            border: 2px solid #164e63;
            border-radius: 10px;
          "
        >
          <paginate
            class="text-white"
            :page-count="pageCountContactArchive"
            :page-range="5"
            :margin-pages="2"
            :click-handler="changePageContactArchive"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :first-last-button="true"
            :initial-page="1"
            :prev-text="'<'"
            :next-text="'>'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
          >
          </paginate>
        </div>
        <div class="mr-2 text-white">Page Rows :</div>
        <select
          v-model="pageContactSize"
          class="w-20 form-select box cursor-pointer"
          @change="pageContactSizeChange"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> -->

      <!-- END: Pagination -->
    </ModalBody>
  </MoveableModal>

  <MoveableModal :show="uploadModal" @hidden="uploadModal = false" size="modal-sl-80" backdrop="static" title="Upload CSV" @close="closeUploadModal()">
    <ModalBody class="flex justify-center space-y-4 w-ful">
      <div class="flex flex-col space-y-4 w-full">
        <ArrangeCsv
          csvName="Suppliers"
          :fileUploadAPI="`Suppliers/${this.storeWarehouseId}/Upload/Excel`"
          :list="SuppliersHeaderList"
          templateAPI="Templates/SupplierContacts"
          :warehouseId="0"
          @getDataFromApi="getDataFromApi($event)"
          :templateShow="true"
          :isWarehouse="false"
        />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <div class="flex space-x-2 justify-center items-center">
        <button type="button" @click="closeUploadModal()" class="btn btn-outline-secondary w-24 h-11">
          {{ const_txt.CANCEL_BTN }}
        </button>

        <!-- <UploadFile
            buttonName = "Upload Bin as a CSV file"
            :activeAccess="$h.addItemAccess(policyAccess)"
            @chooseFiles="chooseFiles($event)"
            @onFilePicked="onFilePicked($event)"
            @downloadTemplate="downloadTemplate($event)"
          /> -->
      </div>
    </ModalFooter>
  </MoveableModal>

  <MoveableModal :show="uploadCsvModal" @hidden="uploadCsvModal = false" size="modal-sl-80" backdrop="static" title="Upload CSV" @close="closeUploadCsvModal()">
    <ModalBody class="flex justify-center space-y-4 w-full">
      <div class="flex flex-col space-y-4 w-full">
        <ArrangeCsv
          csvName="SupplierProducts"
          fileUploadAPI="`SupplierProducts/${formDataUploadCsvWarehouseId}/Upload/Csv`"
          :list="SupplierProductsHeaderList"
          templateAPI="Templates/SupplierProducts"
          :warehouseId="0"
          @getDataFromApi="getDataFromApi($event)"
          :templateShow="true"
          :isWarehouse="false"
        />
      </div>
    </ModalBody>
    <ModalFooter class="text-right">
      <div class="flex space-x-2 justify-center items-center">
        <button type="button" @click="closeUploadCsvModal()" class="btn btn-outline-secondary w-24 h-11">
          {{ const_txt.CANCEL_BTN }}
        </button>

        <!-- <UploadFile
            buttonName = "Upload Bin as a CSV file"
            :activeAccess="$h.addItemAccess(policyAccess)"
            @chooseFiles="chooseFiles($event)"
            @onFilePicked="onFilePicked($event)"
            @downloadTemplate="downloadTemplate($event)"
          /> -->

        <a v-if="$h.addItemAccess(policyAccess) == true" href="javascript:;" @click="chooseFiles()" class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-full"
          >Upload File
          <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />

          <input
            id="fileInput"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            ref="fileInput"
            @change="onFilePickedCsv"
            hidden
          />
        </a>

        <a
          v-if="$h.addItemAccess(policyAccess) == false"
          href="javascript:;"
          @click="chooseFiles()"
          class="btn btn-primary shadow-md text-xs hover:text-yellow-200 w-full cursor-not-allowed"
          >Upload File
          <UploadIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />

          <input
            id="fileInput"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            ref="fileInput"
            disabled
            @change="onFilePicked"
            hidden
          />
        </a>

        <!-- <a href="javascript:;" @click="downloadTemplate()" class="btn btn-primary shadow-md text-xs lg:text-sm">
          <svg
            @click="downloadTemplate()"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="lg:w-6 lg:h-6 w-4 h-4 text-white hover:text-yellow-200"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 1.5q.41 0 .7.3.3.29.3.7v19q0 .41-.3.7-.29.3-.7.3H7q-.41 0-.7-.3-.3-.29-.3-.7V18H1q-.41 0-.7-.3-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87 2.34-3.8H7.46l-1.3 2.4-.05.08-.04.09-.64-1.28-.66-1.29H2.59l2.27 3.82-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3Z"
            />
          </svg>
        </a> -->
      </div>
    </ModalFooter>
  </MoveableModal>

  <ArchiveConfirmModal
    :name="this.formDataContact[0].firstName + ' ' + this.formDataContact[0].lastName"
    :modalStatus="archiveContactConfirmationModal"
    :modalConfirm="archiveContactItemConfirm"
    :modalClose="closeContactArchive"
  />
  <RestoreConfirmModal
    :name="this.formDataContact[0].firstName + ' ' + this.formDataContact[0].lastName"
    :modalStatus="restoreContactConfirmationModal"
    :modalConfirm="restoreItemContactConfirm"
    :modalClose="closeContactRestores"
  />
  <DeleteConfirmModal
    :name="this.formDataContact[0].firstName + ' ' + this.formDataContact[0].lastName"
    :modalStatus="deleteContactConfirmationModal"
    :modalConfirm="deleteItemContactConfirm"
    :modalClose="closeContactDeletes"
  />

  <DefaultContactConfirmModal
    :name="this.formDataContact[0].firstName + ' ' + this.formDataContact[0].lastName"
    :modalStatus="defaultContactConfirmationModal"
    :modalConfirm="defaultItemContactConfirm"
    :modalClose="closeContactDefault"
  />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";

import countryJson from "@/assets/json/countryAllData.json";

import router from "../../../router";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { successToast, failedToast, warningToast, statusMessage } from "../../../global-functions/toast";

import { currencyFormat, percentageFormat, temperatureFormat, weightFormat, removeNullValue, removeNullValueObjectArray } from "../../../global-functions/functions";

import { exportCSVFile } from "../../../global-functions/functions";

import store from "../../../store";
import MyAutocomplete from "@/simple-components/Auto Complete/MyAutocomplete.vue";
import validationMixins from "../../../mixins/validation";
export default {
  mixins: [validationMixins],
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Suppliers");
    this.observerSku = new IntersectionObserver(this.infiniteScrollSku);
    this.observerCurrency = new IntersectionObserver(this.infiniteScrollCurrency);
    this.observerCountry = new IntersectionObserver(this.infiniteScrollCountry);
    this.observerCountry2 = new IntersectionObserver(this.infiniteScrollCountry2);
  },
  components: { MyAutocomplete },
  data() {
    return {
      SupplierProductsHeaderList: ["Sku", "ItemCode", "Supplier", "ItemName"],
      SuppliersHeaderList: [],
      uploadCsvModal: false,
      formDataUploadCsvWarehouseId: "",
      uploadModal: false,
      formDataUploadWarehouseId: "",
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      searchData: "",
      const_txt: const_txt,
      isLoading: false,

      addSupplierProductModal: false,
      viewSupplierProductModal: false,
      addContactInfoModal: false,
      viewInfoModal: false,

      searchData: "",
      searchActive: false,
      searchDataArchive: "",
      searchActiveArchive: false,
      addSupplier: false,
      archiveConfirmationModal: false,
      archiveModal: false,
      restoreConfirmationModal: false,
      deleteConfirmationModal: false,
      const_txt: const_txt,

      titleChange: -1,
      formDataSupplier: {
        firstName: null,
        lastName: null,
        email: "",
        telephone: "",
        mobile: "",
        isBlackListed: false,
        isIndividualPersonal: false,
        address: "",
        postcode: "",
        state: "",
        city: "",
        currency: "FJD",
        country: "",
        company: "Fiji",
        warehouseId: 0,
      },
      formDataSupplierDefault: {
        firstName: null,
        lastName: null,
        email: "",
        telephone: "",
        mobile: "",
        isBlackListed: false,
        isIndividualPersonal: false,
        address: "",
        postcode: "",
        state: "",
        city: "",
        currency: "FJD",
        country: "Fiji",
        company: "",
        warehouseId: 0,
      },

      rules: {
        name: {
          required,
          minLength: minLength(2),
        },
        address: {
          required,
          minLength: minLength(2),
        },
      },

      headers: [
        { text: "Company", value: "company" },
        // { text: "On Hold", value: "isBlackListed" },
        { text: "Address", value: "address" },
        // { text: "ID", value: "supplierId" },
        // { text: "First Name", value: "firstName" },
        // { text: "Last Name", value: "lastName" },
      ],
      headersFilter: [
        { text: "Company", value: "company" },
        { text: "Address", value: "address" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
      ],

      contactHeaders: [
        // { text: "First Name", value: "firstName" },
        // { text: "Last Name", value: "lastName" },
        { text: "Display Name", value: "displayName" },
        { text: "Email", value: "email" },
        { text: "Telephone", value: "telephone" },
        { text: "Mobile", value: "mobile" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "company",
        sortDirection: "ASC",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
        sortColumnName: "company",
        sortDirection: "ASC",
      },
      pageCount: 0,
      initialPage: 1,
      pageSize: 10,

      items: [],
      itemsData: [],
      archiveItems: [],
      archiveData: [],
      paginationArchive: {
        pageNumber: 1,
        pageSize: 5,
      },
      pageCountContactArchive: 20,
      pageContactSize: 5,

      pageCountArchive: 20,
      pageSizeArchive: 5,

      //Supplier Product
      searchDataSupplierProduct: "",
      searchActiveSupplierProduct: false,
      supplierProductChange: -1,
      archiveConfirmationModalSupplierProduct: false,
      archiveModalSupplierProduct: false,
      deleteSupplierProductConfirmationModal: false,
      formDataSupplierProduct: {
        productDescription: "",
        productNumber: "",
        price: "",
        maxDeliveryTimeInDays: "",
        supplierId: "",
        skuId: "",
      },
      formDataSupplierProductDefault: {
        productDescription: "",
        productNumber: "",
        price: "",
        maxDeliveryTimeInDays: "",
        supplierId: "",
        skuId: undefined,
      },
      supplierProductsHeaders: [
        { text: "Product Code", value: "productNumber" },
        { text: "Supplier DESC", value: "productDescription" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "DESC", value: "skuDescription" },
        { text: "Price", value: "price" },
        { text: "Delivery Days", value: "maxDeliveryTimeInDays" },
      ],
      supplierProductItems: [],
      supplierProductItemsData: [],
      skus: [],

      pageCountSupplierProduct: 20,
      pageSizeSupplierProduct: 5,
      paginationSupplierProduct: {
        pageNumber: 1,
        pageSize: 5,
        search: "",
      },
      defaultPaginationSupplierProduct: {
        pageNumber: 1,
        pageSize: 5,
        search: "",
      },

      countryList: countryJson,
      uploadedfile: null,
      fileName: " ",

      allItems: [],
      allItemsData: [],
      isActive: 0,
      docs: null,
      formDataContact: [
        {
          supplierId: 0,
          firstName: "",
          lastName: "",
          email: "",
          telephone: "",
          mobile: "",
          isBlackListed: false,
          displayName: "",
          otherDetails: "",
          salutation: "",
          remarks: "",

          addressLine: "",
          postcode: "",
          state: "",
          city: "",
          country: "Fiji",
        },
      ],

      formDataContactDefault: [
        {
          supplierId: 0,
          firstName: "",
          lastName: "",
          email: "",
          telephone: "",
          mobile: "",
          isBlackListed: false,
          displayName: "",
          otherDetails: "",
          salutation: "",
          remarks: "",

          addressLine: "",
          postcode: "",
          state: "",
          city: "",
          country: "Fiji",
        },
      ],

      contactItems: [],

      addContactModal: false,
      titleContactChange: -1,

      archiveContactConfirmationModal: false,
      restoreContactConfirmationModal: false,
      deleteContactConfirmationModal: false,
      defaultContactConfirmationModal: false,

      archiveContactItems: [],
      archiveContactData: [],
      paginationContactArchive: {
        pageNumber: 1,
        pageSize: 5,
        // sortColumnName: "displayName",
        // sortDirection: "asc",
      },
      pageCountContactArchive: 20,
      archiveContactModal: false,
      searchDataContactArchive: "",
      searchActiveContactArchive: false,
      addressItems: [],
      newAddressItem: [],

      splitData: false,
      allSelected: false,
      checkedData: [],
      policyAccess: "Suppliers",

      currencysearch: "",
      countrysearch: "",
      storeWarehouseId: store.state.authUser.warehouse.warehouseId,

      observerSku: null,
      limitSku: 5,
      hasNextPageSku: false,
      totalSkuCount: 0,
      isSkuSearchResult: false,
      skuPageNumber: 1,
      skuSearchTimer: undefined,

      observerCurrency: null,
      limitCurrency: 5,
      // hasNextPageCurrency : false,

      observerCountry: null,
      limitCountry: 5,

      observerCountry2: null,
      limitCountry2: 5,

      hasScrolledToBottom: false,
    };
  },
  watch: {
    viewModalSku() {
      if (this.formDataSupplierProduct.skuId === null) {
        this.clearSku();
      }
    },
    formDataSupplierProductSkuId() {
      this.addSupplierProductInfo();
    },
    checkLimtSku() {
      this.getSkus();
    },
  },
  computed: {
    checkLimtSku() {
      return this.skuPageNumber;
    },
    viewModalSku() {
      return this.formDataSupplierProduct.skuId;
    },
    currencyFiltered() {
      return this.countryList.filter((x) => x.currencyCode.toLowerCase().includes(this.currencysearch.toLowerCase()));
    },
    paginatedCurrency() {
      return this.currencyFiltered.slice(0, this.limitCurrency);
    },
    hasNextPageCurrency() {
      return this.paginatedCurrency.length < this.currencyFiltered.length;
    },
    countryFiltered() {
      return this.countryList.filter((x) => x.countryName.toLowerCase().includes(this.countrysearch.toLowerCase()));
    },
    paginatedCountry() {
      return this.countryFiltered.slice(0, this.limitCountry);
    },
    hasNextPageCountry() {
      return this.paginatedCountry.length < this.countryFiltered.length;
    },

    // countryFiltered2() {
    //   return this.countryList
    //     .filter((x) =>
    //       x.countryName.toLowerCase().includes(this.countrysearch2.toLowerCase())
    //     )
    // },
    // paginatedCountry2() {
    //   return this.countryFiltered2.slice(0, this.limitCountry2);
    // },
    // hasNextPageCountry2() {
    //   return this.paginatedCountry2.length < this.countryFiltered2.length
    // },
    formDataSupplierProductSkuId() {
      return this.formDataSupplierProduct.skuId;
    },
    formTitle() {
      return this.titleChange === -1 ? "Add New Supplier" : this.formDataSupplier?.company ? `Edit Supplier - ${this.formDataSupplier?.company}` : "Edit Supplier";
    },
    supplierProductTitle() {
      return this.supplierProductChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },
    btnName() {
      return this.titleChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },

    formContactTitle() {
      return this.titleContactChange === -1 ? "Add New Supplier Contact Person" : "Edit Supplier Contact Person";
    },
    btnContactName() {
      return this.titleContactChange === -1 ? const_txt.SAVE_BTN : const_txt.UPDATE_BTN;
    },

    sortedContacts() {
      return this.contactItems.sort(({ default: a }, { default: b }) => b - a);
    },
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    handleScroll(el) {
      if (el.target.offsetHeight + el.target.scrollTop >= el.target.scrollHeight) {
        if (this.itemsData.totalPages > this.pagination.pageNumber) {
          this.hasScrolledToBottom = true;
          this.pagination.pageNumber = this.pagination.pageNumber + 1;
          this.changePage(this.pagination.pageNumber);
          this.$refs.scroll.scroll(0, 0);
        }
      }
    },
    onFilePickedCsv(event) {
      this.uploadcsvfile = event.target.files[0];
      if (this.formDataUploadCsvWarehouseId == "" || this.formDataUploadCsvWarehouseId == null || this.uploadcsvfile == null) {
        if (this.formDataUploadCsvWarehouseId == "") {
          warningToast("Warehouse Required");
        }
        if (this.uploadcsvfile == null) {
          warningToast("Csv file Required");
        }
      } else {
        let formData = new FormData();

        formData.append("File", this.uploadcsvfile);
        formData.append("Name", this.uploadcsvfile.name);

        this.isLoading = true;
        axios
          .post("SupplierProducts/" + this.formDataUploadCsvWarehouseId + "/Upload/Csv", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
        this.formDataUploadCsvWarehouseId = "";
        this.uploadcsvfile = null;
      }
    },
    openUploadCsvModal() {
      this.uploadCsvModal = true;
    },
    closeUploadCsvModal() {
      this.uploadCsvModal = false;
      this.formDataUploadCsvWarehouseId = "";
      this.uploadcsvfile = null;
    },
    onFilePicked(event) {
      this.uploadcsvfile = event.target.files[0];
      if (this.formDataUploadWarehouseId == "" || this.formDataUploadWarehouseId == null || this.uploadcsvfile == null) {
        if (this.formDataUploadWarehouseId == "") {
          warningToast("Warehouse Required");
        }
        if (this.uploadcsvfile == null) {
          warningToast("Csv file Required");
        }
      } else {
        let formData = new FormData();

        formData.append("File", this.uploadcsvfile);
        formData.append("Name", this.uploadcsvfile.name);

        this.isLoading = true;
        axios
          .post("Suppliers/Upload/Excel", formData)
          .then((response) => {
            if (response.data.success === true) {
              this.getDataFromApi();
              successToast(response.data.message);
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          });
        this.formDataUploadWarehouseId = "";
        this.uploadcsvfile = null;
      }
    },
    openUploadModal() {
      this.uploadModal = true;
    },
    closeUploadModal() {
      this.uploadModal = false;
      this.uploadcsvfile = null;
    },
    async onOpenSku() {
      if (this.isSkuSearchResult) {
        this.skuPageNumber = 1;
      }
      if (this.hasNextPageSku) {
        await this.$nextTick();
        this.observerSku.observe(this.$refs.loadSku);
      }
    },
    onCloseSku() {
      this.observerSku.disconnect();
    },
    async infiniteScrollSku([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.skuPageNumber++;
        this.limitSku;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenCurrency() {
      if (this.hasNextPageCurrency) {
        await this.$nextTick();
        this.observerCurrency.observe(this.$refs.loadCurrency);
      }
    },
    onCloseCurrency() {
      this.observerCurrency.disconnect();
      this.limitCurrency = 5;
    },
    async infiniteScrollCurrency([{ isIntersecting, target }]) {
      console.log(isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitCurrency = this.limitCurrency + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenCountry() {
      if (this.hasNextPageCountry) {
        await this.$nextTick();
        this.observerCountry.observe(this.$refs.loadCountry);
      }
    },
    onCloseCountry() {
      this.observerCountry.disconnect();
      this.limitCountry = 5;
    },
    async infiniteScrollCountry([{ isIntersecting, target }]) {
      console.log(isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitCountry = this.limitCountry + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenCountry2() {
      console.log(this.hasNextPageCountry2);
      if (this.hasNextPageCountry2) {
        await this.$nextTick();
        this.observerCountry2.observe(this.$refs.loadCountry2);
      }
    },
    onCloseCountry2() {
      this.observerCountry2.disconnect();
      this.limitCountry2 = 5;
    },
    async infiniteScrollCountry2([{ isIntersecting, target }]) {
      console.log(isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limitCountry2 = this.limitCountry2 + 5;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    onCurrencySearch(query) {
      this.currencysearch = query;
    },
    onCountrySearch(query) {
      this.countrysearch = query;
    },
    checkItem(item) {
      var include = this.checkedData.includes(item);
      if (include) {
        const index = this.checkedData.indexOf(item);
        if (index > -1) {
          this.checkedData.splice(index, 1);
        }
      } else {
        this.checkedData.push(item);
      }
    },
    sorting(data) {
      this.pagination.sortColumnName = data.sortColumnName;
      this.pagination.sortDirection = data.sortDirection;
      this.getDataFromApi();
    },
    getAddressFromApi(id) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await axios.get("Addresses/" + id);
          resolve(response.data.data);
        } catch {
          resolve({
            addressId: id,
            line: "",
            postcode: "",
            state: "",
            city: "",
            country: "",
          });
        }
      });
    },

    addField(value, fieldType) {
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    closeContact() {
      this.addContactModal = false;
      this.setContactDefault();
    },
    addContact() {
      this.setContactDefault();
      this.addContactModal = true;
      this.titleContactChange = -1;
    },
    editContact(item) {
      this.titleContactChange = 0;
      // var formDataContact = [Object.assign({}, item)];
      // console.log(formDataContact)
      this.formDataContact = [
        {
          supplierContactPersonId: item.supplierContactPersonId,
          supplierId: item.supplierId,
          firstName: null,
          lastName: null,
          email: item.email,
          telephone: item.telephone,
          mobile: item.mobile,
          isBlackListed: item.isBlackListed,
          displayName: item.displayName,
          otherDetails: item.otherDetails,
          salutation: item.salutation,
          remarks: item.remarks,
          default: item.default,
          addressId: item.addressId,
          supplier: item.supplier,
          addressLine: item.address.line,
          postcode: item.address.postcode,
          state: item.address.state,
          city: item.address.city,
          country: item.address.country,
        },
      ];

      //       this.getAddressFromApi(item.addressId).then(function(result) {

      // });
      this.addContactModal = true;
    },
    viewContactItemInfo(item) {
      this.formDataSupplier = Object.assign({}, item);
      this.addContactInfoModal = true;
      this.getSupplierContactPersons();
      this.isLoading = true;
    },

    defaultContactItem(item) {
      this.formDataContact = [Object.assign({}, item)];
      this.defaultContactConfirmationModal = true;
    },
    defaultItemContactConfirm() {
      var itemId = this.formDataContact[0].supplierContactPersonId;
      this.isLoading = true;
      axios
        .put("SupplierContactPersons/" + itemId + "/UpdateDefaultContactPerson")
        .then((response) => {
          if (response.data.success === true) {
            this.defaultContactConfirmationModal = false;
            this.getSupplierContactPersons();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSupplierContactPersons() {
      this.isLoading = true;
      var supplierId = this.formDataSupplier.supplierId;
      axios
        .get("SupplierContactPersons/" + supplierId + "/SupplierContactPersons")
        .then((response) => {
          if (response.data.success === true) {
            this.contactItems = response.data.data;
            this.contactItems.forEach((x) => {
              this.getAddressFromApi(x.addressId).then((address) => {
                x.address = address;
              });
            });
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    viewAddress(item) {
      this.formDataContact = [Object.assign({}, item)];
    },

    saveContact() {
      if (this.formDataContact.displayName == "" || this.formDataContact.email == "" || this.formDataContact.mobile == "" || this.formDataContact.city == "") {
        if (this.formDataContact.displayName == "") {
          warningToast("User Name is Required");
        }
        if (this.formDataContact.email == "") {
          warningToast("Email is Required");
        }
        if (this.formDataContact.mobile == "") {
          warningToast("Mobile is Required");
        }
        if (this.formDataContact.city == "") {
          warningToast("City is Required");
        }
      } else {
        if (this.titleContactChange === -1) {
          this.isLoading = true;
          var listArray = [];
          var defaultCountry;
          for (var i = 0; i < this.formDataContact.length; i++) {
            this.formDataContact[i].supplierId = this.formDataSupplier.supplierId;
            if (this.formDataContact[i].country === undefined || this.formDataContact[i].country === null) {
              defaultCountry = "fiji";
            } else {
              defaultCountry = this.formDataContact[i].country;
            }
            var contactObj = {
              supplierId: this.formDataContact[i].supplierId,
              firstName: this.formDataContact[i].firstName,
              lastName: this.formDataContact[i].lastName,
              email: this.formDataContact[i].email,
              telephone: this.formDataContact[i].telephone,
              mobile: this.formDataContact[i].mobile,
              isBlackListed: this.formDataContact[i].isBlackListed,
              displayName: this.formDataContact[i].displayName,
              otherDetails: this.formDataContact[i].otherDetails,
              salutation: this.formDataContact[i].salutation,
              remarks: this.formDataContact[i].remarks,
              address: {
                addressLine: this.formDataContact[i].addressLine,
                postcode: this.formDataContact[i].postcode,
                state: this.formDataContact[i].state,
                city: this.formDataContact[i].city,
                country: defaultCountry,
              },
            };

            // listArray.push(contactObj);

            axios
              .post("SupplierContactPersons", contactObj)
              .then((response) => {
                console.log(this.formDataContact);
                if (response.data.success === true) {
                  this.addContactModal = false;
                  this.isLoading = false;
                  this.getSupplierContactPersons();
                  successToast(response.data.message);
                } else {
                  this.isLoading = false;
                  failedToast(response.data.message);
                }
              })
              .catch((error) => {
                this.isLoading = false;
                warningToast(statusMessage(error.response.status));
              });
          }
        } else {
          var listArray = [];
          this.isLoading = true;
          for (var i = 0; i < this.formDataContact.length; i++) {
            this.formDataContact[i].supplierId = this.formDataSupplier.supplierId;

            var contactObj = {
              supplierId: this.formDataContact[i].supplierId,
              firstName: this.formDataContact[i].firstName,
              lastName: this.formDataContact[i].lastName,
              email: this.formDataContact[i].email,
              telephone: this.formDataContact[i].telephone,
              mobile: this.formDataContact[i].mobile,
              isBlackListed: this.formDataContact[i].isBlackListed,
              displayName: this.formDataContact[i].displayName,
              otherDetails: this.formDataContact[i].otherDetails,
              salutation: this.formDataContact[i].salutation,
              remarks: this.formDataContact[i].remarks,
              address: {
                addressLine: this.formDataContact[i].addressLine,
                postcode: this.formDataContact[i].postcode,
                state: this.formDataContact[i].state,
                city: this.formDataContact[i].city,
                country: this.formDataContact[i].country,
              },
            };

            // listArray.push(contactObj);
            var supplierContactPersonId = this.formDataContact[0].supplierContactPersonId;
            console.log(supplierContactPersonId);
            axios
              .put("SupplierContactPersons/" + supplierContactPersonId, contactObj)
              .then((response) => {
                if (response.data.success === true) {
                  this.isLoading = false;
                  this.addContactModal = false;
                  this.getSupplierContactPersons();
                  successToast(response.data.message);
                } else {
                  this.isLoading = false;
                  failedToast(response.data.message);
                }
              })
              .catch((error) => {
                this.isLoading = false;
                warningToast(statusMessage(error.response.status));
              });
          }
        }
      }
    },

    archiveContactItem(item) {
      this.formDataContact = [Object.assign({}, item)];
      this.archiveContactConfirmationModal = true;
    },

    archiveContactItemConfirm() {
      this.isLoading = true;
      var supplierContactPersonId = this.formDataContact[0].supplierContactPersonId;
      axios
        .get("SupplierContactPersons/" + supplierContactPersonId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveContactConfirmationModal = false;
            this.isLoading = false;
            this.getSupplierContactPersons();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    closeContactArchive() {
      this.archiveContactConfirmationModal = false;
      // this.setDefault();
    },

    closeContactRestores() {
      this.restoreContactConfirmationModal = false;
      // this.setDefault();
    },

    closeContactDeletes() {
      this.deleteContactConfirmationModal = false;
      // this.setDefault();
    },
    closeContactDefault() {
      this.defaultContactConfirmationModal = false;
    },

    viewContactArchives() {
      this.isLoading = true;
      axios
        .post("SupplierContactPersons/Archived", this.paginationContactArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveContactData = response.data.data;
            this.archiveContactItems = response.data.data.items;
            this.pageCountContactArchive = this.archiveContactData.totalPages;
            this.isLoading = false;
            this.archiveContactModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    searchOnchangeContactArchive() {
      if (this.searchDataContactArchive != "") {
        this.searchActiveContactArchive = true;
        this.searchItemContactArchive();
      } else {
        this.clearSearchContactArchive();
      }
    },
    clearSearchContactArchive() {
      this.paginationContactArchive.pageNumber = 1;
      this.searchDataContactArchive = "";
      this.paginationContactArchive.search = this.searchDataContactArchive;
      this.viewContactArchives();
      this.searchActiveContactArchive = false;
    },
    searchItemContactArchive() {
      if (this.searchDataContactArchive != "") {
        this.searchActiveContactArchive = true;
        this.paginationContactArchive.pageNumber = 1;
        this.paginationContactArchive.search = this.searchDataContactArchive;
        this.viewContactArchives();
      }
    },

    changePageContactArchive(pageNum) {
      this.paginationContactArchive.pageNumber = pageNum;
      this.viewContactArchives();
    },
    pageContactSizeChange(pageSize) {
      this.pageContactSize = pageSize;
      this.paginationArchive.pageSize = this.pageContactSize;
      this.viewArchives();
    },
    restoreContactItem(item) {
      this.formDataContact = [Object.assign({}, item)];
      this.restoreContactConfirmationModal = true;
    },
    restoreItemContactConfirm() {
      var itemId = this.formDataContact[0].supplierContactPersonId;
      this.isLoading = true;
      axios
        .get("SupplierContactPersons/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreContactConfirmationModal = false;
            this.viewContactArchives();
            this.getSupplierContactPersons();
            successToast(response.data.message);
            if (this.archiveContactData.totalCount == 0) {
              this.archiveContactModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteContactItem(item) {
      this.formDataContact = [Object.assign({}, item)];
      this.deleteContactConfirmationModal = true;
    },
    deleteItemContactConfirm() {
      var itemId = this.formDataContact[0].supplierContactPersonId;
      this.isLoading = true;
      axios
        .delete("SupplierContactPersons/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteContactConfirmationModal = false;
            this.viewContactArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    setReports(data) {
      return new Promise(async (resolve, reject) => {
        if (this.isActive == 4) {
          var items = this.checkedData;
        } else if (this.isActive == 0) {
          var items = this.items;
        } else if (this.splitData) {
          var items = data;
        } else {
          var items = this.allItems;
        }
        try {
          if (items <= 0) {
            warningToast(const_txt.NO_DATA);
            this.isLoading = false;
          } else {
            const columns = [
              { title: "No.", dataKey: "no" },
              { title: "Company", dataKey: "company" },
              { title: "Address", dataKey: "address" },
              // { title: "First Name", dataKey: "firstName" },
              // { title: "Last Name", dataKey: "lastName" },
              { title: "On Hold", dataKey: "isBlackListed" },
              { title: "Individual", dataKey: "isIndividualPersonal" },
              { title: "Email", dataKey: "email" },
              { title: "Telephone", dataKey: "telephone" },
              { title: "Mobile", dataKey: "mobile" },
              { title: "Postcode", dataKey: "postcode" },
              { title: "State", dataKey: "state" },
              { title: "City", dataKey: "city" },
              { title: "Country", dataKey: "country" },
            ];
            this.docs = new jsPDF({
              orientation: "landscape",
              unit: "in",
              format: "a4",
            });

            let data = [];
            let i = 0;
            let obj;

            items.forEach((el) => {
              i += 1;
              obj = {
                no: i,
                company: el.company,
                address: el.address,
                // firstName: el.firstName,
                // lastName: el.lastName,
                isBlackListed: el.isBlackListed,
                isIndividualPersonal: el.isIndividualPersonal,
                email: el.email,
                telephone: el.telephone,
                mobile: el.mobile,
                postcode: el.postcode,
                state: el.state,
                city: el.city,
                country: el.country,
              };
              //i++;
              data.push(obj);
              //   console.log(obj);
            });

            autoTable(this.docs, {
              theme: "grid",
              columns,
              body: data,
              headerStyles: {
                fontSize: 7,
                fillColor: [22, 78, 99],
                lineWidth: 0.01,
                lineColor: [22, 78, 99],
              },
              styles: {
                fontSize: 7,
                lineWidth: 0.01,
                lineColor: [0, 0, 0],
              },
              showHead: "firstPage",
              margin: { left: 0.5, top: 0.5 },
            });

            const pageCount = this.docs.internal.getNumberOfPages();
            for (var z = 1; z <= pageCount; z++) {
              this.docs.setPage(z);
              const pageSize = this.docs.internal.pageSize;
              const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

              const footer = `Page ${z} of ${pageCount}`;
              this.docs.setFontSize(10);
              this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
            }
          }
          resolve();
        } catch (error) {
          reject();
        }
      });
    },
    async printAllPages() {
      var printLimit = const_txt.DFLT_PRINT_LIMIT;
      var totalCount = this.itemsData.totalCount;
      this.isActive = 1;
      if (totalCount > printLimit) {
        this.splitData = true;
        var pageCount = totalCount / printLimit;
        let pageCountRound = Math.round(pageCount);
        if (pageCount > pageCountRound) {
          pageCountRound += 1;
          pageCount = pageCountRound;
        } else {
          pageCount = pageCountRound;
        }

        for (var i = 1; i <= pageCount; i++) {
          await this.getDataForEachPrint(i);
        }
      } else {
        this.getAllDataFromApi();
      }
    },
    async getDataForEachPrint(pageNum) {
      var pagination = {
        pageNumber: pageNum,
        pageSize: const_txt.DFLT_PRINT_LIMIT,
      };
      //  this.isLoading = true;
      await axios
        .post("Suppliers/" + this.warehouseId + "/Paginated", pagination)
        .then((response) => {
          this.isLoading = false;
          if (response.data.success === true) {
            var items = response.data.data.items;
            this.pdfPrintEach(items);
          } else {
            warningToast(response.data.message);
          }
        })
        .catch((error) => {
          failedToast(error);
          this.isLoading = false;
        });
    },
    pdfPrintEach(items) {
      this.setReports(items).then((res) => {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
      });
    },

    printSelected() {
      this.isActive = 4;
      this.pdfPrint();
    },
    pdfExportSelected() {
      this.isActive = 4;
      this.pdfDownload();
    },
    csvExportSelected() {
      this.isActive = 4;
      this.csvDownload();
    },
    unSelectAll() {
      this.allSelected = false;
      this.checkedData = [];
    },
    selectAll() {
      this.allSelected = true;
      this.checkedData = [];
      for (var i = 0; i < this.items.length; i++) {
        this.checkedData[i] = this.items[i];
      }
    },
    printCurrentPage() {
      this.isActive = 0;
      this.pdfPrint();
    },

    pdfPrint() {
      this.setReports();
      this.docs.autoPrint();
      this.docs.output("dataurlnewwindow");
    },

    pdfExport() {
      this.getAllDataFromApi();
      this.isActive = 3;
    },

    pdfDownload() {
      this.setReports();
      var generateDate = new Date().toLocaleString();
      this.docs.save(`${"Suppliers " + generateDate}.pdf`);
    },

    csvExport() {
      this.getAllDataFromApi();
      this.isActive = 2;
    },

    csvDownload() {
      if (this.allItems <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Suppliers " + generateDate;
        var items = this.allItems;
        var csvItems = [];

        var csvHeaders = {
          1: "Company",
          2: "Address",
          // 3: "First Name",
          // 4: "Last Name",
          3: "On Hold",
          4: "Individual",
          5: "Email",
          6: "Telephone",
          7: "Mobile",
          8: "Postcode",
          9: "State",
          10: "City",
          11: "Country",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.company,
            2: item.address,
            // 3: item.firstName,
            // 4: item.lastName,
            3: item.isBlackListed,
            4: item.isIndividualPersonal,
            5: item.email,
            6: item.telephone,
            7: item.mobile,
            8: item.postcode,
            9: item.state,
            10: item.city,
            11: item.country,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
      }
    },

    getAllDataFromApi() {
      this.isLoading = true;
      var totalCount = this.itemsData.totalCount;
      var pagination = { pageNumber: 1, pageSize: totalCount };
      axios
        .post("Suppliers/" + this.storeWarehouseId + "/Paginated", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.allItemsData = response.data.data;
            this.allItems = response.data.data.items;
            removeNullValueObjectArray(this.allItems);
            if (this.isActive == 1) {
              this.pdfPrint();
            } else if (this.isActive == 2) {
              this.csvDownload();
            } else if (this.isActive == 3) {
              this.pdfDownload();
            }

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    chooseFiles() {
      this.$refs.fileInput.click();
    },
    onFilePicked(uploadedfile) {
      //this.uploadedfile = document.getElementById("fileInput").files[0];
      this.fileName = uploadedfile.name;
      let formData = new FormData();

      formData.append("file", uploadedfile);

      this.isLoading = true;
      axios
        .post("SupplierProducts/Upload/Csv", formData)
        .then((response) => {
          if (response.data.success === true) {
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;

            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    downloadTemplate() {
  this.isLoading = true;
  axios
    .get("Templates/SupplierProducts", { responseType: 'blob' })  
    .then((response) => {
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'templateSupplier.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();  
      this.isLoading = false;
    })
    .catch((error) => {
      warningToast(statusMessage(error.response.status));
      this.isLoading = false;
    });
},
    currencyFormat(price) {
      return currencyFormat(price);
    },
    percentageFormat(val) {
      return percentageFormat(val);
    },
    temperatureFormat(val) {
      return temperatureFormat(val);
    },
    weightFormat(val) {
      return weightFormat(val);
    },

    viewItemInfo(item) {
      removeNullValue(item);
      this.formDataSupplier = Object.assign({}, item);
      this.viewInfoModal = true;
    },
    searchOnchange(searchData) {
      this.searchData = searchData;
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },

    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1;
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    clearSearch() {
      this.pagination.pageNumber = 1;
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },

    searchOnchangeArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.searchItemArchive();
      } else {
        this.clearSearchArchive();
      }
    },
    clearSearchArchive() {
      this.paginationArchive.pageNumber = 1;
      this.searchDataArchive = "";
      this.paginationArchive.search = this.searchDataArchive;
      this.viewArchives();
      this.searchActiveArchive = false;
    },
    searchItemArchive() {
      if (this.searchDataArchive != "") {
        this.searchActiveArchive = true;
        this.paginationArchive.pageNumber = 1;
        this.paginationArchive.search = this.searchDataArchive;
        this.viewArchives();
      }
    },
    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;

      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pagination.pageNumber = pageNum;
      //this.initialPage = pageNum;
      // this.pagination.pageSize = 10;
      this.getDataFromApi();
    },
    setDefault() {
      this.formDataSupplier = Object.assign({}, this.formDataSupplierDefault);
    },
    setContactDefault() {
      this.formDataContact = [Object.assign({}, this.formDataContactDefault)];
    },
    getDataFromApi() {
      this.isLoading = true;
      axios
        .post("Suppliers/" + this.storeWarehouseId + "/Paginated", this.pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.itemsData = response.data.data;
            this.items = response.data.data.items;
            this.pageCount = this.itemsData.totalPages;

            if (this.pagination.pageNumber == this.itemsData.totalPages) {
              this.pageSizeView = this.itemsData.totalCount - (this.pageCount - 1) * this.pagination.pageSize;
            } else {
              this.pageSizeView = this.pagination.pageSize;
            }

            this.pageRowSet();

            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    pageRowSet() {
      if (this.pageCount == 1) {
        this.pageSize = this.itemsData.totalCount;
      } else {
        this.pageSize = this.pagination.pageSize;
      }
    },
    showModal() {
      this.setDefault();
      this.titleChange = -1;
      this.addSupplier = true;
    },
    closeForm() {
      this.addSupplier = false;
    },
    editItem(item) {
      this.titleChange = 0;
      this.formDataSupplier = Object.assign({}, item);
      this.addSupplier = true;
    },

    addSupplierProductInfo() {
      this.isLoading = true;
      var skuId = this.formDataSupplierProduct.skuId;
      var skuInfo = this.skus.find((x) => x.skuId == skuId);
      if (skuInfo != undefined) {
        this.isLoading = false;
        this.formDataSupplierProduct.productDescription = skuInfo.description;
      } else {
        this.isLoading = false;
        this.formDataSupplierProduct.productDescription = "";
      }
    },

    saveForm() {
      if (
        this.formDataSupplier.email == "" ||
        // this.formDataSupplier.mobile == "" ||
        this.formDataSupplier.company == ""
      ) {
        if (this.formDataSupplier.email == "") {
          warningToast("Email Required");
        }
        // if (this.formDataSupplier.mobile == "") {
        //   warningToast("Mobile No Required");
        // }
        if (this.formDataSupplier.company == "") {
          warningToast("Company Name Required");
        }
      } else {
        if (this.titleChange === -1) {
          this.isLoading = true;
          this.formDataSupplier.warehouseId = this.storeWarehouseId;
          axios
            .post("Suppliers", this.formDataSupplier)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addSupplier = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataSupplier.supplierId;
          this.isLoading = true;
          axios
            .put("Suppliers/" + itemId, this.formDataSupplier)
            .then((response) => {
              if (response.data.success === true) {
                this.itemsData = response.data.data;
                this.items = response.data.data.items;
                this.addSupplier = false;
                this.getDataFromApi();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },
    archiveItem(item) {
      this.formDataSupplier = Object.assign({}, item);
      this.archiveConfirmationModal = true;
    },
    closeRestore() {
      this.restoreConfirmationModal = false;
      this.setDefault();
    },

    closeDelete() {
      this.deleteConfirmationModal = false;
      this.setDefault();
    },
    closeArchive() {
      this.archiveConfirmationModal = false;
      this.setDefault();
    },
    archiveItemConfirm() {
      var itemId = this.formDataSupplier.supplierId;
      this.isLoading = true;
      axios
        .get("Suppliers/" + itemId + "/Archive")
        .then((response) => {
          if (response.data.success === true) {
            this.archiveConfirmationModal = false;
            this.getDataFromApi();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    viewArchives() {
      this.isLoading = true;
      axios
        .post("Suppliers/" + this.storeWarehouseId + "/Archived", this.paginationArchive)
        .then((response) => {
          if (response.data.success === true) {
            this.archiveData = response.data.data;
            this.archiveItems = response.data.data.items;
            this.pageCountArchive = this.archiveData.totalPages;
            this.isLoading = false;
            this.archiveModal = true;
          } else {
            this.isLoading = false;
            warningToast(statusMessage(error.response.status));
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    changePageArchive(pageNum) {
      this.paginationArchive.pageNumber = pageNum;
      this.viewArchives();
    },
    pageSizeChangeArchive(pageSize) {
      this.pageSizeArchive = pageSize;
      this.paginationArchive.pageSize = this.pageSizeArchive;
      this.viewArchives();
    },
    restoreItem(item) {
      this.formDataSupplier = Object.assign({}, item);
      this.restoreConfirmationModal = true;
    },
    restoreItemConfirm() {
      var itemId = this.formDataSupplier.supplierId;
      this.isLoading = true;
      axios
        .get("Suppliers/" + itemId + "/Restore")
        .then((response) => {
          if (response.data.success === true) {
            this.restoreConfirmationModal = false;
            this.viewArchives();
            this.getDataFromApi();
            successToast(response.data.message);
            if (this.archiveData.totalCount == 0) {
              this.archiveModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    deleteItem(item) {
      this.formDataSupplier = Object.assign({}, item);
      this.deleteConfirmationModal = true;
    },
    deleteItemConfirm() {
      this.isLoading = true;
      var itemId = this.formDataSupplier.supplierId;
      axios
        .delete("Suppliers/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteConfirmationModal = false;
            this.viewArchives();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    //Supplier Product
    searchOnchangeSupplierProduct() {
      if (this.searchDataSupplierProduct != "") {
        this.searchActiveSupplierProduct = true;
        this.searchItemSupplierProduct();
      } else {
        this.clearSearchSupplierProduct();
      }
    },
    clearSearchSupplierProduct() {
      this.paginationSupplierProduct.pageNumber = 1;
      this.searchDataSupplierProduct = "";
      this.paginationSupplierProduct.search = this.searchDataSupplierProduct;
      this.getDataFromApiSupplierProducts();
      this.searchActiveSupplierProduct = false;
    },
    searchItemSupplierProduct() {
      if (this.searchDataSupplierProduct != "") {
        this.searchActiveSupplierProduct = true;
        this.paginationSupplierProduct.pageNumber = 1;
        this.paginationSupplierProduct.search = this.searchDataSupplierProduct;
        this.getDataFromApiSupplierProducts();
      }
    },

    viewSupplierProduct(item) {
      // this.formDataSupplierProduct = Object.assign(
      //   {},
      //   this.formDataSupplierProductDefault
      // );

      this.formDataSupplier = Object.assign({}, item);
      this.getSkus();
      // this.formDataSupplierProduct.supplierId = this.formDataSupplier.supplierId;

      this.getDataFromApiSupplierProducts();

      this.viewSupplierProductModal = true;
      this.addSupplierProductModal = false;
      this.supplierProductChange = -1;
    },
    // setDefaultOption() {
    //   this.formDataSupplierProduct = Object.assign(
    //     {},
    //     this.formDataSupplierProductDefault
    //   );
    // },

    clearSku() {
      this.skus = [];
      this.skuPageNumber = 1;
      this.limitSku = 5;
      this.getSkus("");
    },
    searchSKU(search) {
      clearInterval(this.skuSearchTimer);
      this.skuSearchTimer = setTimeout(() => {
        if (search) {
          this.skus = [];
          this.skuPageNumber = 1;
          this.limitSku = this.totalSkuCount;
          this.getSkus(search.trim());
        }
      }, 2000);
    },

    getSkus(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.skuPageNumber,
        pageSize: this.limitSku,
        search: search,
      };
      axios
        .post("MemorySkus/" + this.warehouseAbbreviation + "/Stocks", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;
            this.isSkuSearchResult = !!search;
            this.skus =
              this.isSkuSearchResult !== true ? Array.from(new Set([...this.skus, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse) : response.data.data.items;
            this.hasNextPageSku = response.data.data.hasNextPage;
            this.totalSkuCount = response.data.data.totalCount;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in PLU/SKU");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getDataFromApiSupplierProducts() {
      this.isLoading = true;
      var itemId = this.formDataSupplier.supplierId;

      axios
        .post("SupplierProducts/" + itemId + "/Paginated", this.paginationSupplierProduct)
        .then((response) => {
          if (response.data.success === true) {
            this.supplierProductItemsData = response.data.data;
            this.supplierProductItems = response.data.data.items;

            this.pageCountSupplierProduct = this.supplierProductItemsData.totalPages;
            this.isLoading = false;
          } else {
            warningToast(response.data.message);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    pageSizeChangeSupplierProduct(pageSize) {
      this.pageSizeSupplierProduct = pageSize;
      this.paginationSupplierProduct.pageSize = this.pageSizeSupplierProduct;
      this.getDataFromApiSupplierProducts();
    },
    changePageSupplierProduct(pageNum) {
      this.paginationSupplierProduct.pageNumber = pageNum;
      this.getDataFromApiSupplierProducts();
    },

    addSupplierProducts() {
      this.setDefaultSupplierProduct();
      this.supplierProductChange = -1;
      this.activateModalSupplierProducts();
    },

    setDefaultSupplierProduct() {
      this.formDataSupplierProduct = Object.assign({}, this.formDataSupplierProductDefault);
      this.formDataSupplierProduct.supplierId = this.formDataSupplier.supplierId;

      this.formDataSupplierProduct.supplierId = this.formDataContact.supplierId;
    },

    activateModalSupplierProducts() {
      this.addSupplierProductModal = true;
    },

    saveSupplierProducts() {
      this.formDataSupplierProduct.supplierId = this.formDataSupplier.supplierId;
      this.isLoading = true;
      if (this.formDataSupplierProduct.productNumber == "" || this.formDataSupplierProduct.skuId == "") {
        if (this.formDataSupplierProduct.productNumber == "") {
          warningToast("Supplier Product No Required");
          this.isLoading = false;
        }
        if (this.formDataSupplierProduct.skuId == "") {
          warningToast("PLU/SKU Required");
          this.isLoading = false;
        }
      } else {
        if (this.supplierProductChange === -1) {
          axios
            .post("SupplierProducts", this.formDataSupplierProduct)
            .then((response) => {
              if (response.data.success === true) {
                this.addSupplierProductModal = false;
                this.getDataFromApiSupplierProducts();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        } else {
          var itemId = this.formDataSupplierProduct.supplierProductId;
          this.isLoading = true;

          axios
            .put("SupplierProducts/" + itemId, this.formDataSupplierProduct)
            .then((response) => {
              if (response.data.success === true) {
                this.addSupplierProductModal = false;
                this.getDataFromApiSupplierProducts();
                successToast(response.data.message);
              } else {
                this.isLoading = false;
                failedToast(response.data.message);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              warningToast(statusMessage(error.response.status));
            });
        }
      }
    },

    editItemSupplierProduct(item) {
      this.supplierProductChange = 0;
      this.formDataSupplierProduct = Object.assign({}, item);
      this.formDataSupplierProduct.supplierId = this.formDataSupplier.supplierId;

      this.activateModalSupplierProducts();
      // this.addSupplierProductModal = true;
    },

    deleteItemSupplierProduct(item) {
      this.formDataSupplierProduct = Object.assign({}, item);
      this.deleteSupplierProductConfirmationModal = true;
    },
    deleteSupplierProductItemConfirm() {
      var itemId = this.formDataSupplierProduct.supplierProductId;
      this.isLoading = true;
      axios
        .delete("SupplierProducts/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.deleteSupplierProductConfirmationModal = false;
            this.getDataFromApiSupplierProducts();
            successToast(response.data.message);
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSelectedColor() {
      return "background-color:rgb(23,79,100);color: white";
    },
  },
};
</script>
