<template>
  <a
    v-if="activeAccess"
    class="flex items-center font-bold"
    href="javascript:;"
    @click="buttonClick()"
  >
    <Tippy
      tag="a"
      href="javascript:;"
      class="tooltip"
      :content="name"
      :options="{
        zIndex: 99999,
      }"
    >
      <UsersIcon
        class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1 text-green-600"
      />
    </Tippy>
  </a>
  <a
    v-else
    class="flex items-center font-bold cursor-not-allowed"
    href="javascript:;"
  >
    <UserIcon class="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 mr-1 text-green-600" />
  </a>
</template>
<script>
export default {
  props: { item: {}, name: "", activeAccess: false },
  name: "ViewWarehouse",
  methods: {
    buttonClick() {
      this.$emit("parentMethod", this.item);
    },
  },
};
</script>
