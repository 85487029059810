<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label">Section Name</label>
          <div class="w-72">
            <!-- <v-select
              v-model="reportData.sectionName"
              placeholder="SELECT"
              class="style-chooser"
              :options="storageSections"
              @search="
                (search) => {
                  getSections(search);
                }
              "
              :reduce="(name) => name.name"
              label="name"
            /> -->

            <v-select
              v-model="reportData.sectionName"
              placeholder="SELECT"
              :filterable="false"
              @open="onOpenSS"
              @close="onCloseSS"
              class="style-chooser cursor-pointer"
              @search="searchSS"
              :key="abbreviation"
              :get-option-label="(option) => option.abbreviation"
              :options="storageSections"
              :reduce="(abbreviation) => abbreviation.abbreviation"
              label="abbreviation"
            >
              <template #list-footer>
                <li v-show="hasNextPageSS" ref="loadSS" class="loader">
                  Loading more...
                </li>
              </template>
            </v-select>
          </div>
        </div>

        <!-- <div>
          <a
            v-if="reportData.sectionName != ''"
            href="javascript:;"
            @click="showReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            GET REPORT
          </a>
        </div> -->
        <!-- <div>
          <a
            v-if="reportData.sectionName != ''"
            href="javascript:;"
            @click="clearReports()"
            class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"
          >
            CLEAR
          </a>
        </div> -->

        <!-- <div class="w-56">
          <div class="relative text-slate-500">
            <input
              type="text"
              class="form-control box pr-10"
              placeholder="Search..."
              v-model="searchData"
              @change="searchOnchange"
            />
            <SearchIcon
              @click="searchItem"
              v-if="!searchActive"
              class="lg:w-6 lg:h-6 w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
            />
            <XIcon
              @click="clearSearch"
              v-if="searchActive"
              class="lg:w-6 lg:h-6 w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 cursor-pointer"
            />
          </div>
        </div> -->
        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export
          @printCurrentPage="printCurrentPage"
          @csvExport="csvExport"
          @pdfExport="pdfExport"
          :page="pageNumber"
        />
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th
              class="whitespace-nowrap"
              v-for="(header, i) in headers"
              :key="i"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div
      v-if="reports.length == 0"
      class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center"
    >
      No Data
    </div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal
      @changePage="changePage($event)"
      @pageSizeChange="pageSizeChange($event)"
      :itemsData="itemsData"
      :pageCount="pageCount"
      :pageRange="pageRowCountReport"
      :pageSize="pageSize"
    />

    <!-- END: Pagination -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import monthsJSON from "@/assets/json/months.json";

import {
  successToast,
  failedToast,
  warningToast,
  statusMessage,
} from "../../../global-functions/toast";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import { currencyFormat } from "../../../global-functions/functions";
import { exportCSVFile } from "../../../global-functions/functions";
import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Section Stock Reports");
    this.observerSS = new IntersectionObserver(this.infiniteScrollSS);
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      pageNumber: 1,
      searchData: "",
      searchActive: false,

      headers: [
        { text: "Product Name", value: "productName" },
        { text: "Product Type", value: "productType" },
        { text: "Category", value: "categoryName" },
        { text: "PLU/SKU", value: "skuName" },
        { text: "Brand Name", value: "brandName" },
        { text: "BARCODE/EAN", value: "ean" },
        { text: "Bin", value: "storageBinName" },
        { text: "Bin Abbreviation", value: "abbreviation" },
        { text: "User", value: "user" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCountReport: 10,
      reportsLocal: [],
      pageSize: 10,

      storageSections: [],
      itemsData: {},
      reportData: {
        sectionName: "",
      },

      reports: [
        // {
        //   orderNumber: "PO000001",
        //   pickingType: 1,
        //   status: 1,
        //   warehouseId: "1",
        //   supplierInvoice: "2",
        //   invoiceDate: "2022-05-18T19:58:27.41",
        //   receivedDate: "2022-05-19T19:58:27.41",
        //   userName: "SuperUser",
        //   supplierName: "Alex ",
        //   skuDescription: "Aclor 125mg/5ml Susp 100ml (Cefaclor) (L)",
        //   skuName: "SKU001",
        //   brandName: "Default",
        //   supplierProductNumber: "SC001",
        //   expirationDate: "2022-05-19T19:58:27.41",
        //   manufactureDate: "2022-05-19T19:58:27.41",
        //   receivedQuantity: 100,
        // },
      ],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },

      isActive: 0,
      docs: null,

      observerSS: null,
      limitSS: 5,
      hasNextPageSS: false,
      totalSSCount: 0,
      isSSSearchResult: false,
      ssPageNumber: 1,
      ssSearchTimer: undefined,
    };
  },
  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },
    getReportData() {
      return this.reportData.sectionName;
    },
    checkLimtSS() {
      return this.ssPageNumber;
    },
  },
  created() {
    this.getSections();
    //this.test();
  },
  watch: {
    getReportData() {
      if (this.reportData.sectionName != "") {
        this.showReports();
      } else {
        warningToast(const_txt.NO_DATA);
      }
    },
    checkLimtSS() {
      this.getSections();
    },
  },
  methods: {
    async onOpenSS() {
      if (this.isSSSearchResult) {
        this.ssPageNumber = 1;
      }
      if (this.hasNextPageSS) {
        await this.$nextTick();
        this.observerSS.observe(this.$refs.loadSS);
      }
    },
    onCloseSS() {
      this.observerSS.disconnect();
    },
    async infiniteScrollSS([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.ssPageNumber++;
        this.limitSS;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "Product Name", dataKey: "productName" },
          { title: "Product Type", dataKey: "productType" },
          { title: "Category", dataKey: "categoryName" },
          { title: "PLU/SKU", dataKey: "skuName" },
          { title: "Brand Name", dataKey: "brandName" },
          { title: "BARCODE/EAN", dataKey: "ean" },
          { title: "Bin", dataKey: "storageBinName" },
          { title: "Bin Abbreviation", dataKey: "abbreviation" },
          { title: "User", dataKey: "user" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            productName: el.productName,
            productType: el.productType,
            categoryName: el.categoryName,
            skuName: el.skuName,
            brandName: el.brandName,
            ean: el.ean,
            storageBinName: el.storageBinName,
            abbreviation: el.abbreviation,
            user: el.user,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(
            footer,
            pageWidth / 2 - this.docs.getTextWidth(footer) / 2,
            pageHeight - 0.2,
            { baseline: "bottom" }
          );
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(
          `${"Product Select Location Report " + generateDate}.pdf`
        );
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Product Select Location Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "Product Name",
          2: "Product Type",
          3: "Category",
          4: "PLU/SKU",
          5: "Brand Name",
          6: "BARCODE/EAN",
          7: "Bin",
          8: "Bin Abbreviation",
          9: "User",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.productName,
            2: item.productType,
            3: item.categoryName,
            4: item.skuName,
            5: item.brandName,
            6: item.ean,
            7: item.storageBinName,
            8: item.abbreviation,
            9: item.user,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },

    clearSS() {
      this.storageSections = [];
      this.ssPageNumber = 1;
      this.limitSS = 5;
      this.getSections("");
    },

    searchSS(search) {
      clearInterval(this.ssSearchTimer);
      this.ssSearchTimer = setTimeout(() => {
        if (search) {
          this.storageSections = [];
          this.ssPageNumber = 1;
          this.limitSS = this.totalSSCount;
          this.getSections(search.trim());
        }
      }, 2000);
    },

    async getSections(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.ssPageNumber,
        pageSize: this.limitSS,
        search: search,
      };
      await axios
        .post(
          "StorageSections/" + this.warehouseAbbreviation + "/Paginated",
          pagination
        )
        .then((response) => {
          if (response.data.success === true) {
            this.isSSSearchResult = !!search;

            this.storageSections =
            this.isSSSearchResult !== true
              ? Array.from(
                  new Set(
                    [...this.storageSections, ...response.data.data.items].map(
                      JSON.stringify
                    )
                  )
                ).map(JSON.parse)
              : response.data.data.items;

          this.hasNextPageSS = response.data.data.hasNextPage;
          this.totalSSCount = response.data.data.totalCount;


            if (this.storageSections.length == 0) {
              warningToast("Please Add Items in Storage Section");
            } else {
              //console.log("WH", store.state.authUser.warehouse.warehouseId);
              this.storageSections = this.storageSections.filter(function (x) {
                return (
                  x.warehouseId == store.state.authUser.warehouse.warehouseId
                );
              });
            }
            this.isLoading = false;
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    clearReports() {
      this.reportData.sectionName = "";
      this.reports = [];
      this.reportsLocal = [];
    },
    showReports() {
      var sectionName = this.reportData.sectionName;
      if (sectionName == null) {
        failedToast(const_txt.SELECT_SECTION);

        this.reports = [];
        this.setLocalReport();
      } else {
        this.getReports();
      }
    },

    getReports() {
      this.isLoading = true;
      var item = Object.assign({}, this.reportData);

      if (item.sectionName != "") {
        axios
          .get(`Report/${item.sectionName}/SectionProductLocationReport`)
          .then((response) => {
            console.log(response.data.data);
            if (response.data.success === true) {
              this.isLoading = false;
              if (response.data.data.length == 0) {
                successToast("NO DATA ", response.data.message);
              }
              this.itemsData = response.data;
              if (response.data) {
                this.itemsData.totalCount = this.itemsData.data.length;
              }
              this.reports = response.data.data;
              this.setLocalReport();
              if (this.reports.length == 0) {
                warningToast("NO DATA ", response.data.message);
              }
            } else {
              this.isLoading = false;
              failedToast(response.data.message);
            }
          });
      } else {
        this.isLoading = false;
        // axios
        //   .get(
        //     `Report/MonthlySalesReportwoCustomer?FromDate=${item.fromdate}&ToDate=${item.todate}`
        //   )
        //   .then((response) => {

        //     this.reports = response.data.data;
        //     if (this.reports.length == 0) {
        //       failedToast("NO DATA ", response.message);
        //     }
        //   });
      }
    },

    searchOnchange() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.searchItem();
      } else {
        this.clearSearch();
      }
    },
    clearSearch() {
      this.pagination.pageNumber = 1
      this.searchData = "";
      this.pagination.search = this.searchData;
      this.getDataFromApi();
      this.searchActive = false;
    },
    searchItem() {
      if (this.searchData != "") {
        this.searchActive = true;
        this.pagination.pageNumber = 1
        this.pagination.search = this.searchData;
        this.getDataFromApi();
      }
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getDataFromApi();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getDataFromApi();
    },
    changePage(pageNum) {
      this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCountReport * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCountReport;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      var pageCount = this.itemsData.totalCount / this.pageRowCountReport;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
