<template>
  <Tippy
    tag="a"
    href="javascript:;"
    class="tooltip"
    content="View Bins in 3D Model"
    :options="{
      zIndex: 99999,
    }"
  >
    <a
      href="javascript:;"
      @click="buttonClick()"
      class="mr-2 btn btn-primary shadow-md text-xs hover:text-yellow-200 mt-1 mb-1"
    >
      3D View
      <span class="ml-2">
        <Scale3dIcon class="lg:w-6 lg:h-6 w-4 h-4 ml-2 mr-2" />
      </span>
    </a>
  </Tippy>
</template>
<script>
export default {
  name: "View3D",
  methods: {
    buttonClick() {
      this.$emit("parentMethod");
    },
  },
};
</script>
