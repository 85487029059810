<template>
    <v-select class="style-chooser" v-model="_vModel" :placeholder="placeholder" :filterable="filterable" :options="items"
        :label="itemText" :get-option-label="(option) => option[itemText]" :reduce="(option) => option[itemValue]"
        @open="onOpen" @close="onClose" @search="searchData">
        <template #list-footer>

            <li v-if="hasNextPage" ref="loadMoreRef" class="loader">
                Loading more...
            </li>
            <li v-else-if="loading" ref="loadMoreRef" class="loader">
                Loading...
            </li>
        </template>
    </v-select>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';

const emit = defineEmits(['load']);
const props = defineProps({
    modelValue: {},
    placeholder: { type: String, default: "SELECT" },
    filterable: { type: Boolean, default: false },
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: "value" }
});

const _vModel = computed({
    get() {
        return props.modelValue;
    }, set(val) {
        emit('update:modelValue', val)
    }
})

const loadMoreRef = ref();

const search = ref("");

const items = ref([]);
const totalItems = ref(0);

const page = ref(1)
const itemsPerPage = ref(10)
const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage.value));
const hasNextPage = computed(() => page.value < totalPages.value);

const loading = ref(false);
const load = (done) => {
    loading.value = true;

    emit('load', {
        page: page.value++,
        itemsPerPage: itemsPerPage.value,
        search: search.value,
        callback: (_items, _totalItems) => {
            items.value = [...items.value, ..._items];
            totalItems.value = _totalItems;
            loading.value = false;
            done();
        }
    })
}

let searchTimer;
const searchData = (_search) => {
    if (_search?.trim()?.length == 0) {
        return;
    }

    loading.value = true;
    search.value = _search;
    page.value = 1;
    items.value = [];
    totalItems.value = 0;

    clearInterval(searchTimer);

    searchTimer = setTimeout(() => {
        load();
    }, 1500);
}


const loadMore = (entries) => {
    if (entries.some(x => x.isIntersecting)) {
        load();
    }
}

let observer;
onMounted(() => {
    observer = new IntersectionObserver(loadMore);
    load();
})

const onOpen = async () => {
    const onCompleted = async () => {
        if (hasNextPage.value) {
            await nextTick();
            observer.observe(loadMoreRef.value);
        }
    }

    if (search.value?.trim()?.length > 0) {
        search.value = "";
        page.value = 1;
        items.value = [];
        totalItems.value = 0;
        load(onCompleted)
    } else {
        onCompleted();
    }
}

const onClose = () => {
    observer.disconnect();
}
</script>