import dom from "@left4code/tw-starter/dist/js/dom";
import { mapState, mapMutations } from "vuex";
import store from "../../store";

// Setup side menu
const findActiveMenu = (subMenu, route) => {
  let match = false;
  subMenu.forEach((item) => {
    if (
      ((route.forceActiveMenu !== undefined &&
        item.pageName === route.forceActiveMenu) ||
        (route.forceActiveMenu === undefined &&
          item.pageName === route.name)) &&
      !item.ignore
    ) {
      match = true;
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu, route);
    }
  });
  return match;
};

const nestedMenu = (menu, route) => {
  menu.forEach((item, key) => {
    if (typeof item !== "string") {
      let menuItem = menu[key];
      menuItem.active =
        ((route.forceActiveMenu !== undefined &&
          item.pageName === route.forceActiveMenu) ||
          (route.forceActiveMenu === undefined &&
            item.pageName === route.name) ||
          (item.subMenu && findActiveMenu(item.subMenu, route))) &&
        !item.ignore;

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu, route);
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu, route),
        };
      }
    }
  });

  return menu;
};

 
 
const linkTo = (menu, router, event) => {
   
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;  
  } else {
    
    event.preventDefault(); 
    router.push({
      name: menu.pageName,
    });  
    //  store.dispatch("menuTitle/setSubMenuTitle", menu.title); 
  }
};

const enter = (el, done) => {
  dom(el).slideDown(300);
};

const leave = (el, done) => {
  dom(el).slideUp(300);
};

export { nestedMenu, linkTo, enter, leave };
