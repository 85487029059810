<template>
  <component
    :is="lIcon || 'Info'"
    class="lucide"
    :class="[`text-${color} w-${size} h-${size}`]"
    v-if="provider === 'lucide'"
  />
</template>
<script>
import LucideIcons from "@/utils/lucide-icons.js";

export default {
  name: "Icon",
  components: { ...LucideIcons },
  props: {
    name: { type: String, default: "Info" },
    size: { type: String, default: "12" },
    color: { type: String, default: "" },
    provider: {
      type: String,
      default: "lucide",
    },
  },
  computed: {
    lIcon() {
      return LucideIcons[this.name];
    },
  },
};
</script>