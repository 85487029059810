<template>
  <LoadingScreen :isLoading="isLoading" />
  <div class="mt-5 w-full">
    <div
      class="intro-y lg:flex lg:flex-row lg:items-end lg:space-x-2 lg:justify-between md:flex md:flex-row md:items-end md:space-x-1 md:justify-between flex flex-wrap flex-col mt-2 w-full"
    >
      <div class="lg:flex space-x-1 sm:grid-col-12">
        <div>
          <label for="regular-form-1" class="form-label">Customer</label>
          <div class="w-72">
            <v-select
              v-model="reportData.customerId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              :options="customers"
              @open="onOpen"
              @close="onClose"
              @search="searchCus"
              :key="company"
              :get-option-label="(option) => option.company"
              :reduce="(name) => name.customerId"
              label="company"
            >
              <template v-slot:option="option">
                <span :class="option.icon"></span>
                <div v-if="option.isIndividualPersonal">{{ option.firstName || "" }} {{ option.lastName || "" }}</div>
                <div v-else>
                  {{ option.company }}
                </div>
              </template>
              <template #list-footer>
                <li v-show="hasNextPageCus" ref="load" class="loader">Loading more...</li>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">PLU/SKU</label>
          <div class="w-72">
            <v-select
              v-model="reportData.skuId"
              placeholder="SELECT"
              class="style-chooser"
              :filterable="false"
              @open="onOpenSku"
              @close="onCloseSku"
              @search="searchSKU"
              :key="skuName"
              :get-option-label="(option) => option.skuName"
              :options="skus"
              :reduce="(skuName) => skuName.skuId"
              label="skuName"
            >
              <template #list-footer>
                <li v-show="hasNextPageSku" ref="loadSku" class="loader">Loading more...</li>
              </template>
            </v-select>
          </div>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">From Date</label>
          <PreviewComponent class="intro-y">
            <div class="w-40">
              <Preview>
                <Litepicker
                  v-model="reportData.fromDate"
                  :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }"
                  class="form-control"
                />
              </Preview>
            </div>
          </PreviewComponent>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">To Date</label>
          <PreviewComponent class="intro-y">
            <div class="w-40">
              <Preview>
                <Litepicker
                  v-model="reportData.toDate"
                  :options="{
                    autoApply: true,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: 2050,
                      months: true,
                      years: true,
                    },
                  }"
                  class="form-control"
                />
              </Preview>
            </div>
          </PreviewComponent>
        </div>
        <div>
          <label for="regular-form-1" class="form-label">Filter </label>

          <Dropdown>
            <DropdownToggle class="btn px-2 box">
              <span class="w-5 h-5 flex items-center justify-center">
                <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4" />
              </span>
            </DropdownToggle>
            <DropdownMenu class="w-40">
              <DropdownContent>
                <DropdownItem @click="setMonth(item)" v-for="(item, i) in monthsList" :key="i">
                  <CalendarIcon class="lg:w-6 lg:h-6 w-4 h-4 mr-2" />
                  {{ item.name }}
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          <a href="javascript:;" @click="getReports()" class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"> GET REPORT </a>
        </div>
        <div>
          <a href="javascript:;" @click="clearReports()" class="btn btn-primary shadow-md lg:w-auto w-full text-xs lg:text-sm mt-7"> CLEAR </a>
        </div>

        <div class="hidden md:block mx-auto text-slate-500">
          <!-- Showing 1 to 10 of 150 entries -->
        </div>
      </div>
      <div v-if="reports.length > 0">
        <Export @printCurrentPage="printCurrentPage" @csvExport="csvExport" @pdfExport="pdfExport" :page="pageNumber" />
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto">
      <table class="table table-report -mt-2 table-auto">
        <thead>
          <tr class="uppercase whitespace-nowrap">
            <th class="whitespace-nowrap" v-for="(header, i) in headers" :key="i">
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in reportsLocal" :key="i" class="intro-x">
            <td v-for="(header, index) in headers" :key="index">
              {{ item[header.value] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->

    <div v-if="reports.length == 0" class="mb-5 border-2 shadow-md p-2 font-semibold w-full col-span-12 text-center">No Data</div>

    <!-- BEGIN: Pagination -->
    <PaginationBarLocal @changePage="changePage($event)" :itemsData="itemsData" :pageCount="pageCount" :pageRange="pageRowCount" :pageSize="pageSize" />

    <!-- END: Pagination -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Toastify from "toastify-js";
import dom from "@left4code/tw-starter/dist/js/dom";

import { required, minLength, maxLength, integer } from "@vuelidate/validators";
import axios from "../../../services/http-common";
import Paginate from "vuejs-paginate-next";
import moment from "moment";

import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { const_txt } from "../../../global-functions/const";

import monthsJSON from "@/assets/json/months.json";
import { exportCSVFile } from "../../../global-functions/functions";
import { successToast, failedToast, warningToast, statusMessage } from "../../../global-components/toast/toast";

import store from "../../../store";
export default {
  mounted() {
    store.dispatch("menuTitle/setSubMenuTitle", "Product Sales Reports");
    this.observerSku = new IntersectionObserver(this.infiniteScrollSku);
    this.observerCus = new IntersectionObserver(this.infiniteScroll);
  },
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      warehouseAbbreviation: store.state.authUser.warehouse.abbreviation,
      warehouseStoreId: store.state.authUser.warehouse.warehouseId,
      pageNumber: 1,
      searchData: "",
      searchActive: false,

      headers: [
        {
          text: "PLU/SKU",
          value: "skuName",
        },
        { text: "DESC", value: "description" },
        { text: "BARCODE/EAN", value: "ean" },
        { text: "Unit Price", value: "unitPrice" },

        { text: "Total QTY", value: "totalQuantity" },
        { text: "Total Price", value: "totalPrice" },
        { text: "Total Discount", value: "totalDiscount" },
      ],
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },
      defaultPagination: {
        pageNumber: 1,
        pageSize: 10,
        search: "",
      },

      observerSku: null,
      limitSku: 5,
      hasNextPageSku: false,
      totalSkuCount: 0,
      isSkuSearchResult: false,
      skuPageNumber: 1,
      skuSearchTimer: undefined,

      reportStart: 0,
      reportEnd: 10,
      pageCount: 0,
      pageRowCount: 10,
      reportsLocal: [],
      pageSize: 10,

      customers: [],
      itemsData: {},
      skus: [],
      reportData: {
        customerId: "",
        skuId: "",
        fromDate: "",
        toDate: "",
      },

      observerCus: null,
      limitCus: 5,
      hasNextPageCus: false,
      totalCusCount: 0,
      isCusSearchResult: false,
      cusPageNumber: 1,
      cusSearchTimer: undefined,

      reports: [],
      monthsList: monthsJSON,

      selectedMonth: "",
      selectedCustomer: {
        firstName: "",
        lastName: "",
      },
      isActive: 0,
      docs: null,
    };
  },
  computed: {
    checkLimt() {
      return this.limitCus;
    },
    checkLimtSku() {
      return this.limitSku;
    },
    formTitle() {
      return this.titleChange === -1 ? "Add New Customer" : "Edit Customer";
    },
    // getReportData() {
    //   return this.reportData.customerId + this.reportData.skuId;
    // },
  },
  viewModalSku() {
    return this.reportData.skuId;
  },
  viewModalCus() {
    return this.reportData.customerId;
  },
  created() {
    this.getCurrentMonth();
    this.getCustomers("");
    this.getSkus("");
    //this.test();
  },
  watch: {
    viewModalSku() {
      if (this.reportData.skuId === null) {
        this.clearSku();
      }
    },
    viewModalCus() {
      if (this.reportData.customerId === null) {
        this.clearCus();
      }
    },
    clearSku() {
      this.skus = [];
      this.skuPageNumber = 1;
      this.limitSku = 5;
      this.getSkus("");
    },
    searchSKU(search) {
      clearInterval(this.skuSearchTimer);
      this.skuSearchTimer = setTimeout(() => {
        if (search) {
          this.skus = [];
          this.skuPageNumber = 1;
          this.limitSku = this.totalSkuCount;
          this.getSkus(search.trim());
        }
      }, 2000);
    },
    clearCus() {
      this.customers = [];
      this.CusPageNumber = 1;
      this.limitCus = 5;
      this.hasNextPageCus("");
    },
    searchCus(search) {
      clearInterval(this.cusSearchTimer);
      this.cusSearchTimer = setTimeout(() => {
        if (search) {
          this.customers = [];
          this.cusPageNumber = 1;
          this.limitCus = this.totalCusCount;
          this.hasNextPageCus(search.trim());
        }
      }, 2000);
    },
    checkLimt() {
      this.hasNextPageCus();
    },
    checkLimtSku() {
      this.getSkus();
    },
    // getReportData() {
    //   if (this.reportData.customerId != "" || this.reportData.skuId != "") {
    //     this.showReports();
    //   } else {
    //     warningToast(const_txt.NO_DATA);
    //   }
    // },
  },
  methods: {
    async onOpen() {
      if (this.isCusSearchResult) {
        this.cusPageNumber = 1;
      }
      if (this.hasNextPageCus) {
        await this.$nextTick();
        this.observerCus.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observerCus.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      console.log("isIntersecting " + isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.cusPageNumber++;
        this.limitCus;
        console.log("limitCus " + this.limitCus);
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    async onOpenSku() {
      if (this.isSkuSearchResult) {
        this.skuPageNumber = 1;
      }
      if (this.hasNextPageSku) {
        await this.$nextTick();
        this.observerSku.observe(this.$refs.loadSku);
      }
    },
    onCloseSku() {
      this.observerSku.disconnect();
    },
    async infiniteScrollSku([{ isIntersecting, target }]) {
      console.log("isIntersectingSku " + isIntersecting);
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.skuPageNumber++;
        this.limitSku;
        console.log("limitSku " + this.limitSku);
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    setReports() {
      if (this.isActive == 1) {
        var items = this.reportsLocal;
      } else {
        var items = this.reports;
      }
      if (items <= 0) {
        this.isLoading = false;
      } else {
        const columns = [
          { title: "PLU/SKU", dataKey: "skuName" },
          { title: "Description", dataKey: "description" },
          { title: "BARCODE/EAN", dataKey: "ean" },
          { title: "Unit Price", dataKey: "unitPrice" },
          { title: "Total QTY", dataKey: "totalQuantity" },
          { title: "Total Price", dataKey: "totalPrice" },
          { title: "Total Discount", dataKey: "totalDiscount" },
        ];
        this.docs = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: "a4",
        });

        let data = [];
        let i = 0;
        let obj;

        items.forEach((el) => {
          obj = {
            skuName: el.skuName,
            description: el.description,
            ean: el.ean,
            unitPrice: el.unitPrice,
            totalQuantity: el.totalQuantity,
            totalPrice: el.totalPrice,
            totalDiscount: el.totalDiscount,
          };
          //i++;
          data.push(obj);
          //   console.log(obj);
        });
        //console.log("DATA", data);

        // var imgData = "/src/assets/images/pl_logo2.png";
        // this.docs.addImage(imgData, "JPEG", 0.5, 0.5, 2.5, 1.5);
        // this.docs
        //   .setFontSize(16)
        //   .text("Storage Bin", 5, 1.2);

        autoTable(this.docs, {
          theme: "grid",
          columns,
          body: data,
          headerStyles: {
            fontSize: 7,
            fillColor: [22, 78, 99],
            lineWidth: 0.01,
            lineColor: [22, 78, 99],
          },
          styles: {
            fontSize: 7,
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
          },
          showHead: "firstPage",
          margin: { left: 0.5, top: 0.5 },
        });
        const pageCount = this.docs.internal.getNumberOfPages();
        for (var z = 1; z <= pageCount; z++) {
          this.docs.setPage(z);
          // this.docs.text("Page "+String(i)+" of "+String(pageCount), 0.5,0.5);

          const pageSize = this.docs.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          // const header = 'Report 2014';
          const footer = `Page ${z} of ${pageCount}`;
          this.docs.setFontSize(10);
          this.docs.text(footer, pageWidth / 2 - this.docs.getTextWidth(footer) / 2, pageHeight - 0.2, { baseline: "bottom" });
        }
      }
    },
    activatePrint() {
      if (this.isActive == 1) {
        this.pdfPrint();
      } else if (this.isActive == 2) {
        this.csvDownload();
      } else if (this.isActive == 3) {
        this.pdfDownload();
      }
    },
    printCurrentPage() {
      this.isActive = 1;
      this.activatePrint();
    },

    pdfPrint() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        this.docs.autoPrint();
        this.docs.output("dataurlnewwindow");
        this.isActive = 0;
      }
    },

    pdfExport() {
      this.isActive = 3;
      this.activatePrint();
    },

    pdfDownload() {
      this.setReports();
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        this.docs.save(`${"Product Sales Report " + generateDate}.pdf`);
        this.isActive = 0;
      }
    },

    csvExport() {
      this.isActive = 2;
      this.activatePrint();
    },

    csvDownload() {
      if (this.reports <= 0) {
        warningToast(const_txt.NO_DATA);
        this.isLoading = false;
      } else {
        var generateDate = new Date().toLocaleString();
        var csvTitle = "Product Sales Report " + generateDate;
        var items = this.reports;
        var csvItems = [];

        var csvHeaders = {
          1: "PLU/SKU",
          2: "Description",
          3: "BARCODE/EAN",
          4: "Unit Price",
          5: "Total QTY",
          6: "Total Price",
          7: "Total Discount",
        };

        items.forEach((item) => {
          csvItems.push({
            1: item.skuName,
            2: item.description,
            3: item.ean,
            4: item.unitPrice,
            5: item.totalQuantity,
            6: item.totalPrice,
            7: item.totalDiscount,
          });
        });
        exportCSVFile(csvHeaders, csvItems, csvTitle);
        this.isActive = 0;
      }
    },

    momentLocal(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY");
    },
    momentLocalWithTime(date) {
      return moment.utc(date).local().format("DD MMMM, YYYY, hh:mm a");
    },
    getCurrentMonth() {
      const today = new Date();
      this.selectedMonth = today.getMonth();
      this.getMonthRange();
    },

    setMonth(item) {
      this.selectedMonth = item.value - 1;
      this.getMonthRange();
    },
    getMonthRange() {
      var d = new Date();
      var start = new Date(d.getFullYear(), this.selectedMonth, 1);
      var end = new Date(d.getFullYear(), this.selectedMonth + 1, 0);

      var startdate = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate();
      var enddate = end.getFullYear() + "-" + (end.getMonth() + 1) + "-" + end.getDate();

      this.reportData.fromDate = moment(startdate).format("DD MMMM, YYYY");
      this.reportData.toDate = moment(enddate).format("DD MMMM, YYYY");
    },
    async getCustomers(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: 1,
        pageSize: this.limitCus,
        search: search,
      };
      await axios
        .post("Customers/" + this.warehouseStoreId + "/Paginated", pagination)
        .then((response) => {
          this.isCusSearchResult = !!search;
          this.customers.unshift({
            customerId: null,
            firstName: "ALL",
            lastName: "",
          });
          this.customers =
            this.isCusSearchResult !== true ? Array.from(new Set([...this.customers, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse) : response.data.data.items;
          this.hasNextPageCus = response.data.data.hasNextPageCus;
          this.totalCusCount = response.data.data.totalCount;
          console.log("hasnext " + this.hasNextPageCus);
          this.isLoading = false;
          if (this.customers.length == 0) {
            warningToast("Please Add Customers First");
            // this.addSection = false;
          }
          //
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },

    getSkus(search) {
      this.isLoading = true;
      var pagination = {
        pageNumber: this.skuPageNumber,
        pageSize: this.limitSku,
        search: search,
      };
      axios
        .post("MemorySkus/" + this.warehouseAbbreviation + "/Stocks", pagination)
        .then((response) => {
          if (response.data.success === true) {
            this.isLoading = false;

            this.skus.unshift({
              skuId: null,
              skuName: "ALL",
            });
            this.isSkuSearchResult = !!search;
            this.skus =
              this.isSkuSearchResult !== true ? Array.from(new Set([...this.skus, ...response.data.data.items].map(JSON.stringify))).map(JSON.parse) : response.data.data.items;

            this.hasNextPageSku = response.data.data.hasNextPageCus;
            this.totalSkuCount = response.data.data.totalCount;
            // this.addProductModalForm = true;

            if (this.skus.length == 0) {
              warningToast("Please Add Items in PLU/SKU");
              // this.addProductModal = false;
            }
          } else {
            this.isLoading = false;
            failedToast(response.data.message);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          warningToast(statusMessage(error.response.status));
        });
    },
    clearReports() {
      this.reports = [];
      this.reportsLocal = [];
      this.reportData.customerId = "";
      this.reportData.skuId = "";
      this.getCurrentMonth();
    },
    // showReports() {
    //   var customerId = this.reportData.customerId;
    //   var skuId = this.reportData.skuId;
    //   if (customerId == null || skuId == null) {
    //     if (customerId == null) {
    //       failedToast(const_txt.SELECT_CUSTOMER);

    //       this.reports = [];
    //       this.setLocalReport();
    //     }
    //     if (skuId == null) {
    //       failedToast(const_txt.SELECT_SKU_DESC);
    //       this.reports = [];
    //       this.setLocalReport();
    //     }
    //   } else {
    //     this.getReports();
    //   }
    // },

    getReports() {
      // this.isLoading = true;
      var item = Object.assign({}, this.reportData);
      item.fromDate = moment.utc(item.fromDate).format();
      //  item.fromDate = item.fromDate.concat("T").concat("00:00:00.000Z");
      item.toDate = moment.utc(item.toDate).format();
      // item.toDate = item.toDate.concat("T").concat("23:59:59.000Z");

      console.log(moment.utc(item.fromDate).format());

      axios.post("Reports/" + this.warehouseStoreId + "/Products/Sales", this.reportData).then((response) => {
        if (response.data.success === true) {
          this.isLoading = false;
          this.itemsData = response.data;
          if (response.data) {
            this.itemsData.totalCount = this.itemsData.data.length;
          }
          console.log(this.itemsData.totalCount);
          this.reports = response.data.data;
          this.setLocalReport();
          if (this.reports.length == 0) {
            this.isLoading = false;
            warningToast("NO DATA ", response.data.message);
          }
        } else {
          this.isLoading = false;
          failedToast(response.data.message);
        }
      });
    },

    loadMoreData() {
      this.pagination.pageSize = parseInt(this.pagination.pageSize) + 10;
      this.pageSize = this.pagination.pageSize;
      this.getReports();
    },
    pageSizeChange() {
      this.pagination.pageSize = this.pageSize;
      this.getReports();
    },
    changePage(pageNum) {
      //this.pageNumber = pageNum;
      this.reportEnd = this.pageRowCount * pageNum;
      this.reportStart = this.reportEnd - this.pageRowCount;
      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
      console.log("this.reportsLocal", this.reportsLocal);
    },
    setLocalReport() {
      if (this.itemsData) {
        this.itemsData.totalCount = this.items.length;
      }
      var pageCount = this.items.length / this.pageRowCount;
      let pageCountRound = Math.round(pageCount);
      if (pageCount > pageCountRound) {
        pageCountRound += 1;
        this.pageCount = pageCountRound;
      } else {
        this.pageCount = pageCountRound;
      }
      if (this.pageRowCount > this.items.totalCount) {
        this.pageSizeLpns = this.items.totalCount;
      } else {
        this.pageSizeLpns = this.pageRowCount;
      }

      this.reportsLocal = this.reports.slice(this.reportStart, this.reportEnd);
    },
  },
};
</script>
